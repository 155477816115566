export const sortByHandler = (arr) => {
  if (arr.length === 0) return;

  let sortArr = [];

  for (let i = 0; i < arr.length; i++) {
    const currentItem = arr[i];
    const previousItem = arr[i - 1];
    sortArr.push({
      showArrow:
        i === 0 ? true : currentItem.IsAscending !== previousItem.IsAscending,
      name: currentItem.Name,
      isAscending: currentItem.IsAscending,
    });
  }

  return sortArr;
};

export const stringToArray = (string, splitter = /,[ ]*/) => {
  if (!string || string === "") return [];

  const convertedArr = string.split(splitter);

  return convertedArr.map((item) => item.trim());
};

export const parseArrayStringUrlParam = (param) => {
  if (typeof param == "string" || typeof param == "number") {
    return `["${param}"]`;
  } else if (typeof param == "object") {
    let newParam = "[";
    for (let i = 0; i < param.length; i++) {
      let lastItemSeparator = i + 1 < param.length ? "," : "";
      newParam += `"${param[i]}"` + lastItemSeparator;
    }
    newParam += "]";
    return newParam;
  } else return "";
};
