import React, { useState, useEffect } from "react";
import Modal from "./Modal";
import DialogModal from "./Components/Modal/DialogModal/DialogModal";
import { Box } from "@material-ui/core";
import { Form, Field } from "react-final-form";
import ButtonOutlined from "./Components/ButtonOutlined/ButtonOutlined";
import TextFieldMinimal from "./Components/TextField/TextFieldMinimal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LOGIN } from "./store/types";
import variables from "./assets/sass/_variables.scss";
import { connect } from "react-redux";

function Login({ user, login }) {
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState(user?.User?.Email || "");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (user?.User?.Email) {
      setEmail(user?.User?.Email);
    }
  }, [user]);

  const handleClose = () => {
    window.location.reload();
    setOpen(false);
  };

  const actionsOnCallBack = (err) => {
    setLoading(false);
    if (err) {
      setError(err);
    } else {
      handleClose();
    }
  };

  return (
    <Modal>
      <DialogModal
        fullScreen={false}
        open={open}
        titleItems={[{ label: "Sign In" }]}
        icon={
          <FontAwesomeIcon
            size="lg"
            icon={["fad", "lock-alt"]}
            color={variables["color-spacecadet"]}
          />
        }
        actionsChildren={
          <Box display="flex">
            {loading ? (
              <ButtonOutlined
                type="primary"
                label="Login"
                startIcon={
                  <FontAwesomeIcon
                    size="lg"
                    icon={["fad", "spinner-third"]}
                    color={variables["color-mozorange"]}
                  />
                }
              />
            ) : (
              <ButtonOutlined
                type="primary"
                label="Login"
                onClick={() => {
                  login(email, password, actionsOnCallBack);
                  setLoading(true);
                }}
              />
            )}

            <Box pr={1} />
            <ButtonOutlined
              type="secondary"
              label="Cancel"
              onClick={handleClose}
            />
            <Box pr={1} />
          </Box>
        }
        onClose={handleClose}
      >
        <Box mb={4} />
        <Box width={500}>
          <Form
            onSubmit={(e) => e.preventDefault()}
            render={() => {
              return (
                <Box py={2}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    px={3}
                    mb={3}
                    py={2}
                  >
                    <Field name="email">
                      {() => (
                        <TextFieldMinimal
                          label="Email"
                          name={"Email"}
                          value={email}
                          width={450}
                        />
                      )}
                    </Field>

                    <Field name="password">
                      {() => (
                        <TextFieldMinimal
                          label="Password"
                          name={password}
                          type={"password"}
                          onChange={(e) => setPassword(e.target.value)}
                          helperText={error || null}
                          width={450}
                          autoFocus={true}
                          onKeyUp={(event) => {
                            if (event.key === "Enter") {
                              event.preventDefault();
                              login(email, password, actionsOnCallBack);
                              setLoading(true);
                            }
                          }}
                        />
                      )}
                    </Field>
                  </Box>
                </Box>
              );
            }}
          />
        </Box>
      </DialogModal>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  user: state.user?.user,
});

const mapDispatchToProps = (dispatch) => ({
  login: (email, password, errorCallback) =>
    dispatch({ type: LOGIN, email, password, errorCallback }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
