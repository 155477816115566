const Ext = window["Ext"];
if (!Ext.ClassManager.isCreated("ViewRssFieldModel")) {
  Ext.define("ViewRssFieldModel", {
    extend: "Ext.data.Model",
    fields: [
      { name: "FieldID", type: "int" },
      { name: "Name", type: "string" },
      { name: "Included", type: "bool" },
      { name: "IsSystem", type: "bool" },
      { name: "Description", type: "string" },
    ],
  });
}

if (!Ext.ClassManager.isCreated("StorageDetailsModel")) {
  Ext.define("StorageDetailsModel", {
    extend: "Ext.data.Model",
    idProperty: "CollectionID",
    fields: [
      { name: "CollectionID", type: "int" },
      { name: "Name", type: "string" },
      { name: "Database", type: "float" },
      { name: "AgentDefinitions", type: "float" },
      { name: "Files", type: "float" },
      { name: "FilePackages", type: "float" },
      { name: "Total", type: "float" },
      { name: "Rows", type: "float" },
    ],
  });
}

if (!Ext.ClassManager.isCreated("PackagesFilesModel")) {
  Ext.define("PackagesFilesModel", {
    extend: "Ext.data.Model",
    idProperty: "PackageID",
    fields: [
      { name: "PackageID", type: "string" },
      { name: "FieldID", type: "string" },
      { name: "Name", type: "string" },
      { name: "SequenceNumber", type: "string" },
      { name: "NumberOfFiles", type: "string" },
      { name: "FileSize", type: "string" },
      { name: "DownloadDate", type: "string" },
      { name: "BaseFileName", type: "string" },
      { name: "DownloadFileLocation", type: "string" },
      { name: "SavedFileName", type: "string" },
      { name: "Status", type: "string" },
      { name: "Template", type: "string" },
      { name: "UserFileName", type: "string" },
      { name: "ViewStatus", type: "string" },
    ],
  });
}

if (!Ext.ClassManager.isCreated("AgentJobListModel")) {
  Ext.define("AgentJobListModel", {
    extend: "Ext.data.Model",
    idProperty: "PackageID",
    fields: [
      { name: "PackageID", type: "string" },
      { name: "FieldID", type: "string" },
      { name: "Name", type: "string" },
      { name: "SequenceNumber", type: "string" },
      { name: "NumberOfFiles", type: "string" },
      { name: "FileSize", type: "string" },
      { name: "DownloadDate", type: "string" },
      { name: "BaseFileName", type: "string" },
      { name: "DownloadFileLocation", type: "string" },
      { name: "SavedFileName", type: "string" },
      { name: "Status", type: "string" },
      { name: "Template", type: "string" },
      { name: "UserFileName", type: "string" },
      { name: "ViewStatus", type: "string" },
    ],
  });
}

if (!Ext.ClassManager.isCreated("SequenceErrorCodesModel")) {
  Ext.define("SequenceErrorCodesModel", {
    extend: "Ext.data.Model",
    idProperty: "Name",
    fields: [
      { name: "Name", type: "string" },
      { name: "Description", type: "string" },
    ],
  });
}
if (!Ext.ClassManager.isCreated("GetColumnHeadingFileModel")) {
  Ext.define("GetColumnHeadingFileModel", {
    extend: "Ext.data.Model",
    idProperty: "Name",
    fields: [
      { name: "Included", type: "bool" },
      { name: "FileColumnName", type: "string" },
      { name: "Name", type: "string" },
      { name: "IsUnique", type: "bool" },
      { name: "OriginalIndex", type: "int" },
      { name: "SampleData", type: "string" },
    ],
  });
}
if (!Ext.ClassManager.isCreated("GetFileColumnModel")) {
  Ext.define("GetFileColumnModel", {
    extend: "Ext.data.Model",
    idProperty: "FileColumn",
    fields: [{ name: "FileColumn", type: "string" }],
  });
}
if (!Ext.ClassManager.isCreated("GetImportColumnModel")) {
  Ext.define("GetImportColumnModel", {
    extend: "Ext.data.Model",
    idProperty: "FieldID",
    fields: [
      { name: "FileColumn", type: "string" },
      { name: "IsUnique", type: "bool" },
      { name: "Name", type: "string" },
      { name: "Description", type: "string" },
      { name: "FieldID", type: "int" },
      { name: "ImportColumn", type: "string" },
    ],
  });
}
if (!Ext.ClassManager.isCreated("ViewNotificationsModel")) {
  Ext.define("ViewNotificationsModel", {
    extend: "Ext.data.Model",
    idProperty: "ItemID",
    setFields: function (data) {
      const modelFields = data.ViewFields.map((field) => ({
        id: field.ViewFieldID,
        name: field.Name,
      }));

      if (data.ViewFieldStates.length === 0) {
        this.fields = modelFields;
      } else {
        const filteredModelFields = modelFields.filter((item) =>
          data.ViewFieldStates.find((i) => i.ViewFieldID === item.id)
        );
        this.fields = filteredModelFields;
      }
    },
    fields: [
      { name: "Created", type: "string" },
      { name: "TemplateName", type: "string" },
      { name: "Email", type: "string" },
      { name: "Subject", type: "string" },
      { name: "Body", type: "string" },
    ],
  });
}
