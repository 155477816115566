import {
  GET_DEPARTMENT_BILLING_DATA,
  SET_DEPARTMENT_BILLING_DATA,
  SET_DEPARTMENT_BILLING_DOWNLOAD_INVOICE_LIST,
  GET_DEPARTMENT_BILLING_DOWNLOAD_INVOICE_LIST,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import { takeLatest, put, select, cancel } from "redux-saga/effects";
import { renderBillingInvoiceAmountCell } from "../../Components/Grid/SenchaGrid/renderers";
import { mapBillingInvoiceAmountCell } from "../../Components/Grid/SenchaGrid/mappers";
import moment from "moment";
import axios from "axios";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";

const Ext = window["Ext"];

function prepareGridColumns() {
  return [
    {
      text: "<span title='Invoice #'>Invoice #</span>",
      sortable: true,
      dataIndex: "InvoiceNumber",
      width: 95,
      hidden: false,
      cell: {
        height: 50,
      },
    },
    {
      text: "<span title='Date'>Date</span>",
      sortable: true,
      dataIndex: "InvoiceDateTime",
      width: 215,
      renderer: (value) => moment(value).format("MMMM Do YYYY, h:mm A"),
      flex: 3,
      cell: {
        height: 50,
      },
    },
    {
      text: "<span title='Invoice Amount'>Invoice Amount</span>",
      align: "right",
      sortDir: "ASC",
      width: 128,
      sortType: "asInt",
      sortable: true,
      dataIndex: "InvoiceAmount",
      cell: {
        height: 50,
        encodeHtml: true,
        xtype: "reactcell",
      },
      renderer: (value, record) =>
        renderBillingInvoiceAmountCell(
          mapBillingInvoiceAmountCell,
          value,
          record
        ),
      flex: 2,
    },
  ];
}

function* fetchDepartmentBillingData({ storeId, formParams = {} }) {
  const store = Ext.getStore(storeId);

  if (store) {
    store.removeAll(true);
    store.clearFilter(true);

    store.load({
      params: {
        Command: CONSTANTS.COMMANDS.BILLING_GETINVOICELIST,
        ...formParams,
      },
    });

    const columns = prepareGridColumns();

    yield put({
      type: SET_DEPARTMENT_BILLING_DATA,
      payload: {
        columns,
      },
    });
  }
}

function* fetchDepartmentBillingDownloadInvoiceList({ type: action }) {
  const reduxState = yield select();
  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETVIEWS,
    AccountKey: reduxState.account.accountInfo.Account.AccountKey,
    Protocol: `https:`,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETVIEWS,
      new URLSearchParams({
        Command: CONSTANTS.COMMANDS.BILLING_DOWNLOADINVOICELIST,
        AccountKey: reduxState.account.accountInfo.Account.AccountKey,
        CameFromPublisher: false,
      })
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    const billingData = yield axios.post(
      CONSTANTS.ROUTES.BILLING_DOWNLOADINVOICELIST,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    yield put({
      type: SET_DEPARTMENT_BILLING_DOWNLOAD_INVOICE_LIST,
      payload: {
        data,
        billingData,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

export default function* departmentBillingWatcher() {
  yield takeLatest(GET_DEPARTMENT_BILLING_DATA, fetchDepartmentBillingData);
  yield takeLatest(
    GET_DEPARTMENT_BILLING_DOWNLOAD_INVOICE_LIST,
    fetchDepartmentBillingDownloadInvoiceList
  );
}
