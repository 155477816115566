import {
  GET_DEPARTMENT_INSIGHTS,
  SET_DEPARTMENT_INSIGHTS,
  GET_DEPARTMENT_INSIGHTS_SAVED_VIEWS,
  SET_DEPARTMENT_INSIGHTS_SAVED_VIEWS,
  DEPARTMENT_INSIGHTS_UPDATE_STATEVIEW,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import { takeLatest, put, call, cancel } from "redux-saga/effects";
import { renderNameCell } from "../../Components/Grid/SenchaVerticalHeaderGrid/renderers";
import axios from "axios";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";

const Ext = window["Ext"];

function prepareColumns(data) {
  const blankCategoryDelimeter = "<blank-";
  const blankCategoryText = "&zwnj;";
  let columnsToDisplay = [];
  const renderers = {
    Name: {
      renderer: renderNameCell,
    },
  };

  for (let i = 0; i < data.ViewFields.length; i++) {
    let column = data.ViewFields[i];

    if (column.IsSticky && column.IsSticky === true) {
      if (renderers[column.Name]) {
        columnsToDisplay.push({
          dataIndex: column.HeaderText ? column.HeaderText : column.Name,
          text: column.HeaderText
            ? `<span title='${column.HeaderText}'>${column.HeaderText}</span>`
            : `<span title='${column.Name}'>${column.Name}</span>`,
          locked: column.IsSticky,
          hidden: column.IsHidden,
          width: column.ColumnWidth,
          menuDisabled: true,
          cell: {
            encodeHtml: true,
            xtype: "reactcell",
          },
          renderer: (value, record) =>
            renderers[column.Name].renderer(
              renderers[column.Name].mapper,
              value,
              record,
              renderers[column.Name].callback
            ),
        });
      }
      continue;
    }

    if (column.Category && column.Category.split("|").length === 0) {
      columnsToDisplay.push(column);
      continue;
    }

    let cellClasses = "";
    let columnArrayContext = columnsToDisplay;

    for (let categoryName of column.Category.split("|")) {
      let categoryPosition = columnArrayContext.findIndex(
        (column) =>
          column.text === categoryName ||
          (column.categoryName && column.categoryName === categoryName)
      );

      if (categoryPosition < 0) {
        const disabled = categoryName.startsWith(blankCategoryDelimeter);

        columnArrayContext.push({
          text: !disabled ? categoryName : blankCategoryText,
          dataIndex: column.Name,
          isCategory: true,
          categoryName: categoryName,
          categoryDisabled: disabled,
          menuDisabled: disabled || true,
          cls: !disabled ? "" : "m-grid-category-disabled",
          defaults: {
            cls: "rotate",
            menuDisabled: true,
            sortable: false,
          },
          componentCls: "",
          tdCls: "",
          columns: [],
        });

        categoryPosition = columnArrayContext.length - 1;
      }

      cellClasses = columnArrayContext[categoryPosition].tdCls || cellClasses;
      columnArrayContext = columnArrayContext[categoryPosition].columns;
    }

    column.tdCls = cellClasses;

    columnArrayContext.push({
      text: `<span title='${column.Name}'>${column.Name}</span>`,
      dataIndex: column.Name,
      width: column.ColumnWidth,
    });
  }

  return columnsToDisplay;
}

function prepareModel(data, store) {
  const model = store.config.model;

  model.setFields(data);

  return model;
}

function* createStore(storeId, data, extraParams = {}) {
  const store = Ext.getStore(storeId);
  if (store) {
    const model = prepareModel(data, store);
    const CollectionID = data.Collection.CollectionID;
    const ViewID = data.ViewRow.ViewID;
    const AgentID = data.AgentID;
    const BookmarkID = data.Collection.BookmarkID;
    const FolderID = data.CollectionState.FolderID;

    store.removeAll(true);
    store.setModel(model);
    store.baseParams = {
      CollectionID,
      ViewID,
      AgentID,
      AccountKey: extraParams.AccountKey,
      BookmarkID,
      FolderID,
      ...extraParams,
      Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
    };
    store.getProxy().extraParams = store.baseParams;
    store.loadPage(1, { params: store.baseParams });
  }

  const columns = prepareColumns(data);

  yield put({
    type: SET_DEPARTMENT_INSIGHTS,
    payload: {
      columns,
      collectionState: data.CollectionState,
      viewFields: data.ViewFields,
      viewRow: data.ViewRow,
    },
  });
}

function* fetchDepartmentInsights({ type: action, storeId, formParams = {} }) {
  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETCOMPLETE,
    CollectionID: CONSTANTS.COLLECTIONS.INSIGHTS.ACCOUNT_INSIGHTS,
    OverrideViewName: "Last 30 Days",
    ForceViewSortable: "",
    AlwaysIncludeCustomFields: true,
    ColumnsToAlwaysInclude: "Name,AgentID",
    HideSystemViewNames: "Default",
    IncludeAdditionalCustomInterfaceColumns: true,
    ...formParams,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    yield call(createStore, storeId, data, params);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}
function* getViews({ type: action, formParams = {} }) {
  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETVIEWS,
    CollectionID: CONSTANTS.COLLECTIONS.INSIGHTS.ACCOUNT_INSIGHTS,
    AccountKey: formParams.AccountKey,
    ...formParams,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETVIEWS,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    yield put({
      type: SET_DEPARTMENT_INSIGHTS_SAVED_VIEWS,
      payload: {
        departmentInsightsSavedViews:
          data.ViewTable.filter((item) => item.Name !== "Default") || [],
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* updateStateView({ type: action, storeId, formParams = {} }) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_UPDATESTATEVIEW,
      new URLSearchParams({
        CollectionID: CONSTANTS.COLLECTIONS.INSIGHTS.ACCOUNT_INSIGHTS,
        FolderID: formParams.FolderID || "",
        AccountKey: formParams.AccountKey,
        ViewID: formParams.ViewID,
        Command: CONSTANTS.COMMANDS.COLLECTION_UPDATESTATEVIEW,
      }),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    yield fetchDepartmentInsights({ type: action, storeId, formParams });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

export default function* departmentInsightsWatcher() {
  yield takeLatest(GET_DEPARTMENT_INSIGHTS, fetchDepartmentInsights);
  yield takeLatest(GET_DEPARTMENT_INSIGHTS_SAVED_VIEWS, getViews);
  yield takeLatest(DEPARTMENT_INSIGHTS_UPDATE_STATEVIEW, updateStateView);
}
