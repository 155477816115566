import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import variables from "../../assets/sass/_variables.scss";

const EmptyIcon = withStyles({
  root: {
    display: "inline-block",
    width: variables["size-lg"],
  },
})(({ classes, key }) => <div className={classes.root} key={key} />);

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    "& svg": { margin: "0 5px" },
    "& svg:first-of-type": { marginLeft: 0 },
    "& svg:last-of-type": { marginRight: 0 },
  },
});

const IconGroup = ({ icons }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      {icons &&
        icons.map(
          (icon, index) =>
            icon || <EmptyIcon key={`grid-fields-render-empty-icon-${index}`} />
        )}
    </div>
  );
};

IconGroup.propTypes = {
  icons: PropTypes.array,
};

export default IconGroup;
