import {
  SET_EMPTY_PUBLISH_STATE,
  EMPTY_PUBLISH_STATE,
  SET_INITIALIZE_STATE,
  GET_PUBLISHERLIST,
  SET_PUBLISHERLIST,
  INITIALIZE_STATE,
  SET_PUBLISH_INFO,
  PUBLISH_INFO,
  PUBLISHER_ADD,
  PUBLISHER_UPDATE,
  PUBLISH,
  GET_PUBLISH_VIEW_STORE,
  SET_PUBLISH_VIEW_STORE,
  GET_PUBLISHING_SCHEDULE,
  SET_PUBLISHING_SCHEDULE,
  EVENT_GETBYACTION,
  SET_EVENT_GETBYACTION,
  PUBLISHING_PARAMETERS_LOAD,
  SET_AGENT_INFO,
  SET_COLLECTION,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import axios from "axios";
import { takeLatest, put, select, call, cancel } from "redux-saga/effects";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";

function* emptyState() {
  yield put({
    type: EMPTY_PUBLISH_STATE,
  });
}

function* initializeState({ object }) {
  yield put({
    type: INITIALIZE_STATE,
    payload: {
      ...object,
    },
  });
}

function* setInfoPublish({ payload }) {
  yield put({
    type: PUBLISH_INFO,
    payload: {
      ...payload,
    },
  });
}

function* publisherAdd({ type: action, Info = {} }) {
  try {
    const requestParams =
      typeof Info.AgentID === "object"
        ? {
            ...params(Info),
            ...[Info.AgentID],
          }
        : {
            ...params(Info),
          };
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.PUBLISHER_ADD,
      new URLSearchParams({
        Command: CONSTANTS.COMMANDS.PUBLISHER_ADD,
        ...requestParams,
      }),
      {
        headers: {
          action,
        },
      }
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* publisherUpdate({ type: action, Info = {} }) {
  try {
    const requestParams =
      typeof Info.AgentID === "object"
        ? {
            ...params(Info),
            ...[Info.AgentID],
          }
        : {
            ...params(Info),
          };
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.PUBLISHER_UPDATE,
      new URLSearchParams({
        Command: CONSTANTS.COMMANDS.PUBLISHER_UPDATE,
        ...requestParams,
      }),
      {
        headers: {
          action,
        },
      }
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* publisherPublish({ type: action, formParams = {} }) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.PUBLISH,
      new URLSearchParams({
        ...formParams,
        AllowMultipleJobs: true,
        Command: CONSTANTS.COMMANDS.PUBLISH,
      }),
      {
        headers: {
          action,
        },
      }
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getPublisherList(params, callCollectionID) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.GET_PUBLISHERLIST,
      new URLSearchParams({
        [params.ItemType]: params.ItemID,
        Command: CONSTANTS.COMMANDS.GET_PUBLISHERLIST,
      }),
      {
        headers: {
          action: CONSTANTS.COMMANDS.GET_PUBLISHERLIST,
        },
      }
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    yield put({
      type: SET_PUBLISHERLIST,
      payload: {
        ...data?.[0],
        HasFileFields: data.HasFileFields,
      },
    });

    if (callCollectionID) {
      const { data: collectionInfo } = yield axios.post(
        CONSTANTS.ROUTES.COLLECTION_GET,
        new URLSearchParams({
          CollectionID: data?.[0]?.NetItem?.Values?.CollectionID,
          Command: CONSTANTS.COMMANDS.COLLECTION_GET,
        }),
        {
          headers: {
            action: "action",
          },
        }
      );
      if (collectionInfo.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
        yield cancel();
      }

      yield put({
        type: SET_COLLECTION,
        collection: {
          ...collectionInfo.Collection,
          agentId: collectionInfo.AgentID,
        },
      });
    }
    if (typeof params.callbackFn === "function") {
      yield params.callbackFn();
    }
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getPublishViewStore({ type: action, formParams = {} }) {
  const params = {
    CollectionID: formParams.collectionID,
    CameFromPublisher: formParams.cameFromPublisher,
    Command: CONSTANTS.COMMANDS.COLLECTION_GETVIEWS,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETVIEWS,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    const views = data.ViewTable;

    yield put({
      type: SET_PUBLISH_VIEW_STORE,
      payload: {
        views,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getPublishSchedule(formParams) {
  const params = {
    ...formParams,
    Command: CONSTANTS.COMMANDS.SCHEDULE_GET,
  };
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.SCHEDULE_GET,
      new URLSearchParams(params),
      {
        headers: {
          action: CONSTANTS.COMMANDS.SCHEDULE_GET,
        },
      }
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    yield put({
      type: SET_PUBLISHING_SCHEDULE,
      payload: {
        ...data,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* eventGetByAction(formParams) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.EVENT_GETBYACTION,
      new URLSearchParams({
        ...formParams,
        Command: CONSTANTS.COMMANDS.EVENT_GETBYACTION,
      }),
      {
        headers: {
          action: SET_EVENT_GETBYACTION,
        },
      }
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    yield put({
      type: SET_EVENT_GETBYACTION,
      payload: {
        ...data,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* loadPublishParams({ type: action, formParams = {}, callback }) {
  const AgentID = formParams.AgentID;
  const CollectionID = formParams.CollectionID;
  const PublisherID = formParams.PublisherID;
  try {
    if (AgentID) {
      const { data: agentInfo } = yield axios.post(
        CONSTANTS.ROUTES.AGENT_GET,
        new URLSearchParams({ AgentID, Command: CONSTANTS.COMMANDS.AGENT_GET }),
        {
          headers: {
            action,
          },
        }
      );
      if (agentInfo.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
        yield cancel();
      }
      if (
        !handleJsonResultError(
          agentInfo.JsonResult.Result,
          agentInfo.JsonResult.ErrorDescription,
          agentInfo.JsonResult.ErrorList,
          CONSTANTS.NOTIFICATIONS.SUCCESS
        )
      )
        return;

      yield put({
        type: SET_AGENT_INFO,
        payload: {
          agentInfo: agentInfo?.Agent,
          trackHistory: agentInfo.CollectionItem?.TrackHistory,
          CollectionItem: agentInfo.CollectionItem,
        },
      });

      let formParams = { ItemType: "AgentID", ItemID: AgentID[0] };
      yield call(getPublisherList, formParams);
    }

    if (CollectionID && !PublisherID) {
      const { data: collectionInfo } = yield axios.post(
        CONSTANTS.ROUTES.COLLECTION_GET,
        new URLSearchParams({
          CollectionID,
          Command: CONSTANTS.COMMANDS.COLLECTION_GET,
        }),
        {
          headers: {
            action,
          },
        }
      );
      if (collectionInfo.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
        yield cancel();
      }

      yield put({
        type: SET_COLLECTION,
        collection: {
          ...collectionInfo.Collection,
          agentId: collectionInfo.AgentID,
        },
      });
      let formParams = { ItemType: "CollectionID", ItemID: CollectionID[0] };
      yield call(getPublisherList, formParams);
    }

    if (PublisherID) {
      let formParams = { ItemType: "PublisherID", ItemID: PublisherID };
      if (CollectionID) {
        const { data: collectionInfo } = yield axios.post(
          CONSTANTS.ROUTES.COLLECTION_GET,
          new URLSearchParams({
            CollectionID,
            Command: CONSTANTS.COMMANDS.COLLECTION_GET,
          }),
          {
            headers: {
              action,
            },
          }
        );
        if (
          collectionInfo.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG
        ) {
          yield cancel();
        }

        yield put({
          type: SET_COLLECTION,
          collection: {
            ...collectionInfo.Collection,
            agentId: collectionInfo.AgentID,
          },
        });
        let formParams = { ItemType: "CollectionID", ItemID: CollectionID[0] };
        yield call(getPublisherList, formParams, false);
      } else {
        yield call(getPublisherList, formParams, true);
      }
    }

    let state = yield select();
    let publisherList = state.publishing.publisherList;

    if (publisherList?.NetItem?.Values?.ItemID) {
      yield call(getPublishSchedule, {
        ScheduleItemID: publisherList?.NetItem?.Values?.ItemID,
        ScheduleItemType: Collections_Publish_GetScheduleItemType(
          publisherList?.NetItem?.Values?.Method
        ),
      });

      yield call(eventGetByAction, {
        ActionType: "Publish",
        ActionItemID: publisherList?.NetItem?.Values?.ItemID,
      });
    }
    let stateParam = yield select();
    callback(stateParam);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

export default function* publishWatcher() {
  yield takeLatest(SET_EMPTY_PUBLISH_STATE, emptyState);
  yield takeLatest(SET_INITIALIZE_STATE, initializeState);
  yield takeLatest(SET_PUBLISH_INFO, setInfoPublish);
  yield takeLatest(PUBLISHER_ADD, publisherAdd);
  yield takeLatest(PUBLISHER_UPDATE, publisherUpdate);
  yield takeLatest(PUBLISH, publisherPublish);
  yield takeLatest(GET_PUBLISHERLIST, getPublisherList);
  yield takeLatest(GET_PUBLISH_VIEW_STORE, getPublishViewStore);
  yield takeLatest(GET_PUBLISHING_SCHEDULE, getPublishSchedule);
  yield takeLatest(PUBLISHING_PARAMETERS_LOAD, loadPublishParams);
  yield takeLatest(EVENT_GETBYACTION, eventGetByAction);
}

function params(publishSettings = {}) {
  return {
    CollectionsData: {},
    CurrentViewID: publishSettings.CurrentViewID || "",
    CollectionID: publishSettings.CollectionID || "",
    CollectionIsTrackingHistory:
      publishSettings.CollectionIsTrackingHistory || "",
    AgentID: publishSettings.AgentID || "",
    Name: publishSettings.Name || "",
    HasFileFields: publishSettings.HasFileFields || "",
    PublisherDialog: publishSettings.PublisherDialog || "",
    AgentSettings: publishSettings.AgentSettings || "",
    ViewID: publishSettings.ViewID || "",
    Method: publishSettings.Method || "",
    FileFormat: publishSettings.FileFormat || "",
    PublishingTrigger: publishSettings.PublishingTrigger || "",
    EmailAddress: publishSettings.EmailAddress || "",
    EmailSubject: publishSettings.EmailSubject || "",
    FileName: publishSettings.FileName || "",
    FileIncludeHeader: publishSettings.FileIncludeHeader || "",
    FtpDirectory: publishSettings.FtpDirectory || "",
    FtpPort: publishSettings.FtpPort || "",
    FtpUsePassive: publishSettings.FtpUsePassive || "",
    FtpUseSFTP: publishSettings.FtpUseSFTP || "",
    FtpUser: publishSettings.FtpUser || "",
    FtpProtocol: publishSettings.FtpProtocol || "",
    FtpSSLProtocol: publishSettings.FtpSSLProtocol || "",
    FtpPassword: publishSettings.FtpPassword || "",
    Settings: publishSettings.Settings || "",
    Created: publishSettings.Created || "",
    CreatedBy: publishSettings.CreatedBy || "",
    Modified: publishSettings.Modified || "",
    ModifiedBy: publishSettings.ModifiedBy || "",
    Refreshed: publishSettings.Refreshed || "",
    RefreshedBy: publishSettings.RefreshedBy || "",
    DeletedBy: publishSettings.DeletedBy || "",
    ItemSourceType: publishSettings.ItemSourceType || "",
    ItemSourceName: publishSettings.ItemSourceName || "",
    ItemID: publishSettings.ItemID || "",
    Description: publishSettings.Description || "",
    UserID: publishSettings.UserID || "",
    ShareKey: publishSettings.ShareKey || "",
    JobComputerName: publishSettings.JobComputerName || "",
    SearchField: publishSettings.SearchField || "",
    SearchText: publishSettings.SearchText || "",
    EmptyRerun: publishSettings.EmptyRerun || "",
    ErrorRetry: publishSettings.ErrorRetry || "",
    FileLocation: publishSettings.FileLocation || "",
    FtpServer: publishSettings.FtpServer || "",
    RssEnabled: publishSettings.RssEnabled || "",
    RssTitle: publishSettings.RssTitle || "",
    RssPublished: publishSettings.RssPublished || "",
    RssLink: publishSettings.RssLink || "",
    RssContent: publishSettings.RssContent || "",
    AmazonS3AuthenticationToken:
      publishSettings.AmazonS3AuthenticationToken || "",
    AmazonS3SignRequestToken: publishSettings.AmazonS3SignRequestToken || "",
    AmazonS3BucketPath: publishSettings.AmazonS3BucketPath || "",
    AmazonS3BucketRegion: publishSettings.AmazonS3BucketRegion || "",
    MicrosoftAzureStorageAccountName:
      publishSettings.MicrosoftAzureStorageAccountName || "",
    MicrosoftAzureStorageAccountKey:
      publishSettings.MicrosoftAzureStorageAccountKey || "",
    MicrosoftAzureStorageContainerName:
      publishSettings.MicrosoftAzureStorageContainerName || "",
    DropboxFolder: publishSettings.DropboxFolder || "",
    GoogleFolder: publishSettings.GoogleFolder || "",
    UnifydDatastore: publishSettings.UnifydDatastore || "",
    BookmarkID: publishSettings.BookmarkID || "",
    SequenceID: publishSettings.SequenceID || "",
    PublisherID: publishSettings.PublisherID || "",
    ItemStatusIncludeColumn: publishSettings.ItemStatusIncludeColumn || "",
    ItemStatusIncludeAdded: publishSettings.ItemStatusIncludeAdded || "",
    ItemStatusIncludeChanged: publishSettings.ItemStatusIncludeChanged || "",
    ItemStatusIncludeChangeMarkup:
      publishSettings.ItemStatusIncludeChangeMarkup || "",
    ItemStatusIncludeUnchanged:
      publishSettings.ItemStatusIncludeUnchanged || "",
    ItemStatusIncludeRemoved: publishSettings.ItemStatusIncludeRemoved || "",
    DoNotPublishEmptyFile: publishSettings.DoNotPublishEmptyFile || "",
    IncludePackages: publishSettings.IncludePackages || "",
    SavedSettings: publishSettings.SavedSettings || "",
    InputError: publishSettings.InputError || "",
  };
}

function Collections_Publish_GetScheduleItemType(method) {
  var scheduleMethod = "";
  switch (method) {
    case "Ftp":
      scheduleMethod = "FtpPublish";
      break;
    case "Email":
      scheduleMethod = "EmailPublish";
      break;
    case "AmazonS3":
      scheduleMethod = "AmazonS3Publish";
      break;
    case "AzureStorage":
      scheduleMethod = "AzureStoragePublish";
      break;
    case "Dropbox":
      scheduleMethod = "DropboxPublish";
      break;
    case "Google":
      scheduleMethod = "GooglePublish";
      break;
    default:
      return "None";
  }

  return scheduleMethod;
}
