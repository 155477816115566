import {
  GET_AGENTGROUPS_FIELDS,
  SET_AGENTGROUPS_FIELDS,
  AGENTGROUPS_AGENT_INFO_LOADED,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import { takeLatest, put, take, select } from "redux-saga/effects";
import {
  mapAgentGroupFieldConfigCell,
  mapAgentGroupFieldDescriptionCell,
} from "../../Components/Grid/SenchaGrid/mappers";
import {
  renderAgentGroupFieldNameCell,
  renderAgentGroupFieldConfigCell,
  renderAgentGroupFieldDescriptionCell,
} from "../../Components/Grid/SenchaGrid/renderers";

const Ext = window["Ext"];

function getGridColumns(nameCellCallback) {
  return [
    {
      dataIndex: "FieldID",
      text: "<span title='FieldID'>FieldID</span>",
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 75,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "Name",
      text: "<span title='Field Name'>Field Name</span>",
      menuDisabled: true,
      resizable: true,
      searchable: true,
      width: 220,
      cell: {
        height: 50,
        encodeHtml: true,
        xtype: "reactcell",
      },
      renderer: (value, record) =>
        renderAgentGroupFieldNameCell(null, value, record, nameCellCallback),
    },
    {
      dataIndex: "Format",
      text: "<span title='Format'>Format</span>",
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 125,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "Config",
      text: "<span title='Config'>Config</span>",
      menuDisabled: true,
      resizable: true,
      searchable: true,
      width: 116,
      cell: {
        height: 50,
        encodeHtml: true,
        xtype: "reactcell",
      },
      renderer: (value, record) =>
        renderAgentGroupFieldConfigCell(
          mapAgentGroupFieldConfigCell,
          value,
          record
        ),
    },
    {
      dataIndex: "Description",
      text: "<span title='Description'>Description</span>",
      menuDisabled: true,
      resizable: true,
      searchable: true,
      width: 387,
      cell: {
        height: 50,
        encodeHtml: true,
        xtype: "reactcell",
      },
      renderer: (value, record) =>
        renderAgentGroupFieldDescriptionCell(
          mapAgentGroupFieldDescriptionCell,
          value,
          record
        ),
    },
  ];
}

function* fetchAgentGroupsFields({
  storeId,
  nameCellCallback,
  agentGroupId,
  formParams = {},
}) {
  const agentInfo = yield select((state) => state.agentGroups.agentInfo);
  if (!agentInfo.ItemID || agentInfo.ItemID !== Number(agentGroupId)) {
    yield take(AGENTGROUPS_AGENT_INFO_LOADED);
  }
  const { CollectionID } = yield select((state) => state.agentGroups.agentInfo);

  const store = Ext.getStore(storeId);
  if (store) {
    const params = {
      Command: CONSTANTS.COMMANDS.COLLECTION_GETFIELDS,
      CollectionID,
      IncludeAgentFields: true,
      ExcludeSystemFields: true,
      ExcludeSeparators: true,
      ...formParams,
    };

    store.baseParams = {
      ...params,
    };

    store.loadPage(1, { params });
  }

  const columns = getGridColumns(nameCellCallback);

  yield put({
    type: SET_AGENTGROUPS_FIELDS,
    payload: {
      columns,
    },
  });
}

export default function* agentGroupsFieldsWatcher() {
  yield takeLatest(GET_AGENTGROUPS_FIELDS, fetchAgentGroupsFields);
}
