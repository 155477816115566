import * as types from "../types";
import { put, takeLatest, call, cancel } from "redux-saga/effects";
import { CONSTANTS } from "../../constants/constants";
import { COMMANDS } from "../../constants/commands";
import axios from "axios";
import { GROUP_COLLECTIONS } from "../../constants/collections";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";
const Ext = window["Ext"];

function prepareModel(data, store) {
  const model = store.config.model;

  model.setFields(data);

  return model;
}

function* getViews({ type: action, formParams = {} }) {
  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETVIEWS,
    CollectionID: CONSTANTS.COLLECTIONS.MASTER.SETTINGS,
    ...formParams,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETVIEWS,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    yield put({
      type: types.SET_SYSTEM_SETTINGS_SAVED_VIEWS,
      payload: {
        agentsSavedViews: data.ViewTable || [],
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function prepareGridColumns(data) {
  return data.ViewFields.map((field) => {
    return {
      dataIndex: field.Name,
      text: `<span title='${field.Name}'>${field.Name}</span>`,
      locked: field.IsSticky,
      hidden: field.IsHidden,
      width: field.ColumnWidth,
      cell: {
        height: 50,
      },
    };
  });
}

function* createStore(storeId, data, filteredData = {}) {
  const store = Ext.getStore(storeId);
  if (store) {
    const model = prepareModel(data, store);
    const { SearchText, SearchField } = data.CollectionState;
    const viewId = filteredData.ViewID || data.ViewRow.ViewID;

    store.removeAll(true);
    store.setModel(model);
    store.baseParams = {
      CollectionID: CONSTANTS.COLLECTIONS.MASTER.SETTINGS,
      SearchText,
      SearchField,
      AccountKey: filteredData.AccountKey,
      group: { property: "Group", direction: "ASC" },
      ...filteredData,
      Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
      ViewID: viewId,
    };
    store.getProxy().extraParams = store.baseParams;

    store.loadPage(1, { params: store.baseParams });
  }

  const columns = prepareGridColumns(data);

  yield put({
    type: types.SET_SYSTEM_SETTINGS,
    payload: {
      columns,
      collectionState: data.CollectionState,
      collectionInfo: data.CollectionInfo,
      viewRow: data.ViewRow,
      sortedBy: data.ViewSorting,
      viewFields: data.ViewFields,
    },
  });
}

function* getSystemSettings({ type: action, storeId, formParams }) {
  const params = {
    Command: COMMANDS.COLLECTION_GETCOMPLETE,
    CollectionID: CONSTANTS.COLLECTIONS.MASTER.SETTINGS,
    AccountKey: formParams.AccountKey,
    ViewID: formParams.ViewID || "",
    ...formParams,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    yield call(createStore, storeId, data, params);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* deleteSettings({ params, accountKey, viewId }) {
  try {
    const { data } = yield axios.post(CONSTANTS.ROUTES.SETTING_DELETE, params);
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    window["Ext"].getStore(CONSTANTS.STORES.IDS.SYSTEM_SETTINGS).load({
      params: {
        Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
        CollectionID: GROUP_COLLECTIONS.SETTINGS,
        AccountKey: accountKey,
        ViewID: viewId,
        BookmarkID: 0,
        JobID: -1,
        ColumnsToAlwaysInclude: "SettingName",
        AlwaysIncludeCustomFields: false,
        ShowHistoryColumn: false,
        ShowSelected: false,
        TemplateCollectionID: 0,
        ShowMarkup: false,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}
function* copySettings({ params, accountKey, viewId }) {
  try {
    const { data } = yield axios.post(CONSTANTS.ROUTES.SETTING_COPY, params);
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    window["Ext"].getStore(CONSTANTS.STORES.IDS.SYSTEM_SETTINGS).load({
      params: {
        Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
        CollectionID: GROUP_COLLECTIONS.SETTINGS,
        AccountKey: accountKey,
        ViewID: viewId,
        BookmarkID: 0,
        JobID: -1,
        ColumnsToAlwaysInclude: "SettingName",
        AlwaysIncludeCustomFields: false,
        ShowHistoryColumn: false,
        ShowSelected: false,
        TemplateCollectionID: 0,
        ShowMarkup: false,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}
function* newSetting({ params, accountKey, viewId }) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.SETTING_UPDATESINGLE,
      params
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    window["Ext"].getStore(CONSTANTS.STORES.IDS.SYSTEM_SETTINGS).load({
      params: {
        Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
        CollectionID: GROUP_COLLECTIONS.SETTINGS,
        AccountKey: accountKey,
        ViewID: viewId,
        BookmarkID: 0,
        JobID: -1,
        ColumnsToAlwaysInclude: "SettingName",
        AlwaysIncludeCustomFields: false,
        ShowHistoryColumn: false,
        ShowSelected: false,
        TemplateCollectionID: 0,
        ShowMarkup: false,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* deleteSystemSettingsView({ params, viewId }) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.VIEW_DELETE,
      new URLSearchParams(params)
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

export default function* systemSettingsWatcher() {
  yield takeLatest(types.GET_SYSTEM_SETTINGS, getSystemSettings);
  yield takeLatest(types.GET_SYSTEM_SETTINGS_SAVED_VIEWS, getViews);
  yield takeLatest(types.SETTINGS_DELETE, deleteSettings);
  yield takeLatest(types.SETTINGS_COPY, copySettings);
  yield takeLatest(types.NEW_SETTING, newSetting);
  yield takeLatest(types.DELETE_SETTINGS_SAVED_VIEW, deleteSystemSettingsView);
}
