import {
  GET_COLLECTIONS,
  SET_COLLECTIONS,
  GET_COLLECTIONS_SAVED_VIEWS,
  SET_COLLECTIONS_SAVED_VIEWS,
  GET_COLLECTION,
  SET_COLLECTION,
  CLEAR_COLLECTION,
  DELETE_COLLECTION,
  DELETE_COLLECTION_DETAILS,
  CLEAR_NONAGENT_COLLECTION,
  CLEAR_AGENT_STANDARD_COLLECTION,
  SET_DELETE_COLLECTION_ERROR,
  GET_COMPLETE_COLLECTION,
  SET_COMPLETE_COLLECTION,
  GET_COLLECTIONS_FIELDS,
  SET_COLLECTIONS_FIELDS,
  COLLECTION_DELETEFIELDS,
  CREATE_COLLECTION_FROM_SCRATCH,
  GET_ADD_FROM_EXISTING_COLLECTIONS,
  SET_ADD_FROM_EXISTING_COLLECTIONS,
  SET_SAVED_VIEWS_FROM_EXISTING_COLLECTIONS,
  GET_SAVED_VIEWS_FROM_EXISTING_COLLECTIONS,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import { takeLatest, put, call, cancel } from "redux-saga/effects";
import { mapCollectionNameCell } from "../../Components/Grid/SenchaLockedGrid/mappers";
import { renderCollectionNameCell } from "../../Components/Grid/SenchaLockedGrid/renderers";
import axios from "axios";
import { SYSTEM_COLLECTIONS } from "../../constants/collections";
import {
  mapFieldConfigCell,
  mapFieldDescriptionCell,
} from "../../Components/Grid/SenchaLockedGrid/mappers";
import {
  renderFieldConfigCell,
  renderFieldDescriptionCell,
} from "../../Components/Grid/SenchaLockedGrid/renderers";
import { GROUP_COLLECTIONS } from "../../constants/collections";
import { COMMANDS } from "../../constants/commands";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";

const COLLECTION_LIST_STORE_ID = CONSTANTS.STORES.IDS.COLLECTION_LIST;
const Ext = window["Ext"];

function prepareModel(data, store) {
  const model = store.config.model;

  model.setFields(data);

  return model;
}

function createStore(storeId, data, filteredData = {}) {
  const store = Ext.getStore(storeId);
  if (store) {
    const model = prepareModel(data, store);

    const { SearchText, SearchField } = data.CollectionState;

    store.removeAll(true);
    store.setModel(model);

    const params = {
      Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
      CollectionID: CONSTANTS.COLLECTIONS.SYSTEM.COLLECTIONS,
      ViewID: data.ViewRow.ViewID,
      AgentID: data.AgentID,
      SearchText,
      SearchField,
      ...filteredData,
    };

    store.baseParams = params;
    store.getProxy().extraParams = store.baseParams;

    store.loadPage(1, {
      params: {
        Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
        CollectionID: CONSTANTS.COLLECTIONS.SYSTEM.COLLECTIONS,
        ViewID: data.ViewRow.ViewID,
        AgentID: data.AgentID,
        SearchText,
        SearchField,
        ...filteredData,
      },
    });
  }
}

function prepareGridColumns(data, nameCellCallback) {
  const renderers = {
    Name: {
      mapper: mapCollectionNameCell,
      renderer: renderCollectionNameCell,
      callback: nameCellCallback,
    },
  };

  return data.ViewFields.map((field) => {
    if (renderers[field.Name]) {
      return {
        ...field,
        dataIndex: field.Name,
        text: `<span title='${field.Name}'>${field.Name}</span>`,
        locked: field.IsSticky,
        hidden: field.IsHidden,
        width: field.ColumnWidth,
        cell: {
          height: 50,
          encodeHtml: true,
          xtype: "reactcell",
        },
        renderer: (value, record) =>
          renderers[field.Name].renderer(
            renderers[field.Name].mapper,
            value,
            record,
            renderers[field.Name].callback
          ),
      };
    }
    return {
      ...field,
      dataIndex: field.Name,
      text: `<span title='${field.Name}'>${field.Name}</span>`,
      locked: field.IsSticky,
      hidden: field.IsHidden,
      width: field.ColumnWidth,
      cell: {
        height: 50,
      },
    };
  });
}

function* fetchCollections({
  type: action,
  nameCellCallback,
  storeId,
  formParams = {},
}) {
  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETCOMPLETE,
    CollectionID: CONSTANTS.COLLECTIONS.SYSTEM.COLLECTIONS,
    ...formParams,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    const columns = prepareGridColumns(data, nameCellCallback);

    yield put({
      type: SET_COLLECTIONS,
      payload: {
        columns,
        sortedBy: data.ViewSorting,
        viewRow: data.ViewRow,
        collectionState: data.CollectionState,
        viewFields: data.ViewFields,
        refreshed: data.Collection.Refreshed,
      },
    });

    yield call(createStore, storeId, data);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getSavedViews({ type: action }) {
  const params = {
    AccountKey: "",
    CollectionID: SYSTEM_COLLECTIONS.COLLECTIONS,
    Command: CONSTANTS.COMMANDS.COLLECTION_GETVIEWS,
  };

  try {
    const result = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETVIEWS,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (result.data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        result.data.JsonResult.Result,
        result.data.JsonResult.ErrorDescription,
        result.data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    const savedViews = [];

    result.data.ViewTable.forEach((view) => {
      let savedView = {};
      for (let key in view) {
        if (CONSTANTS.RESPONSE_CONTEXT.SAVED_VIEWS_CONTEXT.includes(key)) {
          savedView[key] = view[key];
        }
      }
      savedViews.push(savedView);
    });

    yield put({
      type: SET_COLLECTIONS_SAVED_VIEWS,
      payload: {
        savedViews,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getCollection({ type: action, formParams }) {
  try {
    const params = {
      Command: CONSTANTS.COMMANDS.COLLECTION_GET,
      ...formParams,
    };
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GET,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    yield put({
      type: SET_COLLECTION,
      collection: { ...data.Collection, agentId: data.AgentID },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}
function* getCompleteCollection({ formParams }) {
  const { selectedItems } = formParams;
  try {
    const selectedItemsRequests = selectedItems.map((collection) => {
      return axios
        .post(
          CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
          new URLSearchParams({
            CollectionID: collection.id,
            Command: CONSTANTS.COMMANDS.COLLECTION_GETCOMPLETE,
          })
        )
        .then(({ data }) => {
          return {
            collection: data.Collection,
            collectionItem: data.CollectionItem,
          };
        });
    });

    const mappedSelectedItems = yield Promise.all(selectedItemsRequests);
    yield put({
      type: SET_COMPLETE_COLLECTION,
      payload: mappedSelectedItems,
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}
function* clearCollection({ formParams }) {
  const { storeId, selectedAgents, urlSearchParams, viewId } = formParams;

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_EMPTY,
      urlSearchParams
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    selectedAgents.map(
      yield (agent) => {
        axios.post(
          CONSTANTS.ROUTES.AGENT_GETBOOKMARKS_COMBOBOX_OPTIONS,
          new URLSearchParams({
            MaxBookmarks: 1000,
            Command: CONSTANTS.COMMANDS.AGENT_GETBOOKMARKS_COMBOBOX_OPTIONS,
            AgentID: agent.id,
          })
        );
      }
    );
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    window["Ext"].getStore(storeId).load({
      params: {
        Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
        CollectionID: GROUP_COLLECTIONS.AGENTS,
        ViewID: viewId,
        BookmarkID: 0,
        JobID: -1,
        ColumnsToAlwaysInclude:
          "Config,Name,Description,Settings,Status,ErrorPostpone,CollectionID,LimitWebPageCredits,AgentGroup,Geolocation,BrowserType",
        AlwaysIncludeCustomFields: false,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}
function* clearAgentAndStandardCollection({ formParams }) {
  const { selectedItems, urlSearchParams, viewId } = formParams;
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_EMPTY,
      urlSearchParams
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    window["Ext"].getStore(COLLECTION_LIST_STORE_ID).load({
      params: {
        Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
        CollectionID: GROUP_COLLECTIONS.COLLECTIONS,
        ViewID: viewId,
        BookmarkID: 0,
        JobID: -1,
        ColumnsToAlwaysInclude:
          "Name,CollectionID,BookmarkID,CollectionType,Publisher",
        AlwaysIncludeCustomFields: true,
        SelectedIDs: selectedItems.map((data) => data.id).join(","),
      },
    });
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}
function* clearStandardCollection({ formParams }) {
  const { storeId, collectionID, urlSearchParams, viewId } = formParams;
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_EMPTY,
      urlSearchParams
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    window["Ext"].getStore(storeId).load({
      params: {
        Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
        CollectionID: collectionID,
        ViewID: viewId,
        BookmarkID: 0,
        JobID: -1,
        ColumnsToAlwaysInclude:
          "Name,CollectionID,BookmarkID,CollectionType,Publisher",
        ItemStatusesToInclude: "Added,Changed,Unchanged",
        AlwaysIncludeCustomFields: false,
      },
    });
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}
function* deleteCollection({ selectedItems }) {
  try {
    const id = selectedItems.map((item) => item.id).join(",");
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_DELETE,
      new URLSearchParams({
        CollectionID: id,
        Command: CONSTANTS.COMMANDS.COLLECTION_DELETE,
      })
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    window["Ext"].getStore(COLLECTION_LIST_STORE_ID).load({
      params: {
        Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
        CollectionID: GROUP_COLLECTIONS.COLLECTIONS,
        ViewID: GROUP_COLLECTIONS.COLLECTIONS,
        BookmarkID: 0,
        JobID: -1,
        ColumnsToAlwaysInclude:
          "Name,CollectionID,BookmarkID,CollectionType,Publisher",
        AlwaysIncludeCustomFields: true,
      },
    });
    if (data.JsonResult.Result !== "Success" && data.JsonResult) {
      yield put({
        type: SET_DELETE_COLLECTION_ERROR,
        payload: { error: data.errorMessages },
      });
    }
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}
function* deleteCollectionDetails({
  collectionID,
  navigate,
  onModalStateChanged,
  storeId,
}) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_DELETE,
      new URLSearchParams({
        CollectionID: collectionID,
        Command: CONSTANTS.COMMANDS.COLLECTION_DELETE,
      })
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    window["Ext"].getStore(storeId).load({
      params: {
        Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
        CollectionID: GROUP_COLLECTIONS.COLLECTIONS,
        ViewID: GROUP_COLLECTIONS.COLLECTIONS,
        BookmarkID: 0,
        JobID: -1,
        ColumnsToAlwaysInclude:
          "Name,CollectionID,BookmarkID,CollectionType,Publisher",
        AlwaysIncludeCustomFields: true,
      },
    });

    if (
      data.JsonResult.Result !== "Success" &&
      data.JsonResult.ErrorDescription
    ) {
      yield put({
        type: SET_DELETE_COLLECTION_ERROR,
        payload: {
          error: data.errorMessages,
        },
      });
    } else {
      navigate("/collections");
      onModalStateChanged(false);
    }
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}
function getAgentFieldsGridColumns() {
  return [
    {
      dataIndex: "FieldID",
      text: "<span title='FieldID'>FieldID</span>",
      locked: true,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 75,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "Name",
      text: "<span title='Field Name'>Field Name</span>",
      locked: true,
      menuDisabled: true,
      resizable: true,
      searchable: true,
      width: 220,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "Format",
      text: "<span title='Format'>Format</span>",
      locked: false,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 125,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "Config",
      text: "<span title='Config'>Config</span>",
      locked: false,
      menuDisabled: true,
      resizable: true,
      searchable: true,
      width: 116,
      cell: {
        height: 50,
        encodeHtml: true,
        xtype: "reactcell",
      },
      renderer: (value, record) =>
        renderFieldConfigCell(mapFieldConfigCell, value, record),
    },
    {
      dataIndex: "Description",
      text: "<span title='Description'>Description</span>",
      locked: false,
      menuDisabled: true,
      resizable: true,
      searchable: true,
      width: 387,
      cell: {
        height: 50,
        encodeHtml: true,
        xtype: "reactcell",
      },
      renderer: (value, record) =>
        renderFieldDescriptionCell(mapFieldDescriptionCell, value, record),
    },
  ];
}

function* getCollectionsCustomFields({ storeId }) {
  const columns = getAgentFieldsGridColumns();
  const store = Ext.getStore(storeId);

  if (store) {
    const params = {
      CollectionID: SYSTEM_COLLECTIONS.COLLECTIONS,
      Command: CONSTANTS.COMMANDS.COLLECTION_GETFIELDS,
      IncludeAgentFields: "",
      IncludeFieldMap: true,
      ExcludeSystemFields: true,
      ExcludeSeparators: true,
    };

    store.load({ params });

    yield put({
      type: SET_COLLECTIONS_FIELDS,
      columns,
    });
  }
}

function* deleteCollectionFields({ urlSearchParams, storeId }) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_DELETEFIELDS,
      urlSearchParams
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    yield Ext.getStore(storeId).load({
      params: {
        CollectionID: SYSTEM_COLLECTIONS.COLLECTIONS,
        IncludeAgentFields: "",
        ExcludeSystemFields: true,
        ExcludeSeparators: true,
        Command: CONSTANTS.COMMANDS.COLLECTION_GETFIELDS,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* createCollectionFromScratch({ params, history, closeModal }) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_CREATE_NEW_FROM_SCRATCH,
      new URLSearchParams(params)
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    closeModal();
    history(`/collections/standard/${data.CollectionID}/data`);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function prepareExistingCollectionsGridColumns(data) {
  return data.ViewFields.map((field) => {
    return {
      dataIndex: field.Name,
      text: `<span title='${field.Name}'>${field.Name}</span>`,
      locked: field.IsSticky,
      hidden: field.IsHidden,
      width: field.ColumnWidth,
      cell: {
        height: 50,
      },
    };
  });
}

function* getFromExistingCollections({ storeId }) {
  const params = {
    CollectionID: SYSTEM_COLLECTIONS.COLLECTIONS,
    FolderID: "",
    AccountKey: "",
    ViewID: "",
    OverrideViewName: "",
    HideSystemViewNames: "Combined Collections",
    Command: COMMANDS.COLLECTION_GETCOMPLETE,
  };
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams(params)
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    const columns = prepareExistingCollectionsGridColumns(data);

    yield put({
      type: SET_ADD_FROM_EXISTING_COLLECTIONS,
      payload: {
        columns,
        sortedBy: data.ViewSorting,
        viewRow: data.ViewRow,
        collectionState: data.CollectionState,
        viewFields: data.ViewFields,
        refreshed: data.Collection.Refreshed,
      },
    });

    yield call(createStore, storeId, data);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getSavedViewsFromExistingCollections() {
  const params = {
    AccountKey: "",
    CollectionID: SYSTEM_COLLECTIONS.COLLECTIONS,
    Command: CONSTANTS.COMMANDS.COLLECTION_GETVIEWS,
  };

  try {
    const result = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETVIEWS,
      new URLSearchParams(params)
    );

    if (result.data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        result.data.JsonResult.Result,
        result.data.JsonResult.ErrorDescription,
        result.data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    const savedViews = [];

    result.data.ViewTable.forEach((view) => {
      let savedView = {};
      for (let key in view) {
        if (CONSTANTS.RESPONSE_CONTEXT.SAVED_VIEWS_CONTEXT.includes(key)) {
          savedView[key] = view[key];
        }
      }
      savedViews.push(savedView);
    });

    yield put({
      type: SET_SAVED_VIEWS_FROM_EXISTING_COLLECTIONS,
      payload: {
        savedViews,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

export default function* collectionWatcher() {
  yield takeLatest(GET_COLLECTIONS, fetchCollections);
  yield takeLatest(GET_COLLECTIONS_SAVED_VIEWS, getSavedViews);
  yield takeLatest(GET_COLLECTION, getCollection);
  yield takeLatest(GET_COMPLETE_COLLECTION, getCompleteCollection);
  yield takeLatest(CLEAR_COLLECTION, clearCollection);
  yield takeLatest(DELETE_COLLECTION, deleteCollection);
  yield takeLatest(DELETE_COLLECTION_DETAILS, deleteCollectionDetails);
  yield takeLatest(CLEAR_NONAGENT_COLLECTION, clearStandardCollection);
  yield takeLatest(
    CLEAR_AGENT_STANDARD_COLLECTION,
    clearAgentAndStandardCollection
  );
  yield takeLatest(GET_COLLECTIONS_FIELDS, getCollectionsCustomFields);
  yield takeLatest(COLLECTION_DELETEFIELDS, deleteCollectionFields);
  yield takeLatest(CREATE_COLLECTION_FROM_SCRATCH, createCollectionFromScratch);
  yield takeLatest(
    GET_ADD_FROM_EXISTING_COLLECTIONS,
    getFromExistingCollections
  );
  yield takeLatest(
    GET_SAVED_VIEWS_FROM_EXISTING_COLLECTIONS,
    getSavedViewsFromExistingCollections
  );
}
