export const TABS = {
  AGENTS: "agents",
  COLLECTIONS: "collections",
  SUMMARY: "summary",
  BILLING: "billing",
  INSIGHTS: "insights",
  JOBS: "jobs",
  IMAGES_AND_FILES: "Images & Files",
  STORAGE: "storage",
  USERS: "users",
  UTILIZATION: "utilization",
  DATA: "data",
  FIELDS: "fields",
  HISTORY: "history",
  METHOD: "Method",
  BEHAVIOR: "Behavior",
  ERROR_HANDLING: "Error Handling",
  ADVANCED: "Advanced",
  LISTS: "Lists",
  PARAMETERS: "Parameters",
  LIMITS: "Limits",
  PROCESSING_CREDITS: "Processing Credits",
  CONTACT: "Contact",
  PERMISSIONS: "Permissions",
  USER: "User",
  API: "Api",
  RATEPLANS: "rate plans",
  SETTINGS: "settings",
  SYSTEM_INSIGHTS: "system insights",
  ADDITIONAL: "additional",
  LICENSE: "license",
  BILL_ELEMENTS: "Bill Elements",
  USAGE_LIMITS: "Usage Limits",
  CUSTOMIZATION: "Customization",
  RATE_PLAN_OVERRIDES: "Rate Plan Overrides",
};
