import {
  GET_COMBINED_COLLECTIONS,
  SET_COMBINED_COLLECTIONS,
  GET_COMBINED_COLLECTIONS_SAVED_VIEWS,
  SET_COMBINED_COLLECTIONS_SAVED_VIEWS,
  GET_COMBINED_COLLECTIONS_STATE,
  SET_COMBINED_COLLECTIONS_STATE,
  COLLECTION_CREATE_NEW_FROM_EXISTING,
  GET_COMBINED_DATA_EXISTING_FIELDS,
  SET_COLLECTION_DATA_FROM_EXISTING,
  SET_COMBINED_DATA_EXISTING_FIELDS,
  SET_COMBINED_DATA_SOURCE_COLLECTIONS_INFO,
  SET_COMBINED_DATA_SOURCE_FIELDS,
  SET_COMBINED_DATA_SHARED_FIELDS,
  SET_COMBINED_DATA_SOURCE_FIELDS_LOADED,
  FILTER_USED_COMBINED_DATA_SOURCE_FIELDS,
  FILTER_COMBINED_DATA_SAME_COLLECTION_SOURCE_FIELDS,
  FILTER_COMBINED_DATA_BY_FIELD_NAME,
  GET_COMBINED_DATA_SOURCE_FIELDS,
  REBUILD_COMBINE_COLLECTION,
  COLLECTION_REBUILD,
  SET_ALL_COMBINED_COLLECTIONS,
  GET_ALL_COMBINED_COLLECTIONS,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import { takeLatest, put, call, select, cancel } from "redux-saga/effects";
import axios from "axios";
import {
  mapFieldConfigCell,
  mapFieldDescriptionCell,
} from "../../Components/Grid/SenchaLockedGrid/mappers";
import {
  renderFieldConfigCell,
  renderFieldDescriptionCell,
} from "../../Components/Grid/SenchaLockedGrid/renderers";
import { COMMANDS } from "../../constants/commands";

import {
  mapSourceFieldsGridNameCell,
  mapSourceFieldsGridCollectionsCell,
} from "../../Components/Grid/SenchaGrid/mappers";
import {
  renderSourceFieldsGridNameCell,
  renderSourceFieldsGridCollectionsCell,
} from "../../Components/Grid/SenchaGrid/renderers";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";
import { SYSTEM_COLLECTIONS } from "../../constants/collections";

const Ext = window["Ext"];

function prepareModel(data, store) {
  const model = store.config.model;

  model.setFields(data);

  return model;
}

function prepareGridColumns(data) {
  return data.ViewFields.map((field) => ({
    dataIndex: field.Name,
    text: `<span title='${field.Name}'>${field.Name}</span>`,
    locked: field.IsSticky,
    hidden: field.IsHidden,
    width: field.ColumnWidth,
    cell: {
      height: 50,
      encodeHtml: true,
    },
  }));
}

function createStore(storeId, data) {
  const store = Ext.getStore(storeId);
  if (store) {
    const model = prepareModel(data, store);

    const { SearchText, SearchField, CollectionID } = data.CollectionState;

    store.removeAll(true);
    store.setModel(model);

    store.baseParams = {
      Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
      CollectionID,
      ViewID: data.ViewRow.ViewID,
      AgentID: data.AgentID,
      SearchText,
      SearchField,
    };

    store.getProxy().extraParams = store.baseParams;

    store.loadPage(1, {
      params: {
        Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
        CollectionID,
        ViewID: data.ViewRow.ViewID,
        AgentID: data.AgentID,
        SearchText,
        SearchField,
      },
    });
  }
}

function* fetchCombinedCollections({ type: action, storeId, formParams = {} }) {
  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETCOMPLETE,
    ...formParams,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    let collectionInfo = {};

    for (let key in data.Collection) {
      if (CONSTANTS.RESPONSE_CONTEXT.COLLECTION_INFO_CONTEXT.includes(key)) {
        collectionInfo[key] = data.Collection[key];
      }
    }

    const columns = prepareGridColumns(data);
    yield put({
      type: SET_COMBINED_COLLECTIONS,
      payload: {
        columns,
        collectionInfo,
        collectionState: data.CollectionState,
        viewRow: data.ViewRow,
        viewFields: data.ViewFields,
      },
    });

    yield call(createStore, storeId, data);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getAllCombinedCollections() {
  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETCOMPLETE,
    CollectionID: SYSTEM_COLLECTIONS.COLLECTIONS,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams(params)
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    handleJsonResultError(
      data.JsonResult.Result,
      data.JsonResult.ErrorDescription,
      data.JsonResult.ErrorList,
      CONSTANTS.NOTIFICATIONS.SUCCESS
    );

    const { SearchText, SearchField, CollectionID } = data.CollectionState;
    const standardCollectionsResult = yield axios.post(
      CONSTANTS.ROUTES.VIEW_GETITEMS,
      new URLSearchParams({
        Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
        CollectionID,
        ViewID: data.ViewRow.ViewID,
        AgentID: data.AgentID,
        SearchText,
        SearchField,
      })
    );

    const allCombinedCollections = standardCollectionsResult.data.Rows.filter(
      (el) => el.CollectionType === "Combined"
    );

    yield put({
      type: SET_ALL_COMBINED_COLLECTIONS,
      payload: allCombinedCollections,
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* fetchCombinedCollectionState({ type: action, formParams = {} }) {
  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETCOMPLETE,
    ...formParams,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    let collectionInfo = {};

    for (let key in data.Collection) {
      if (CONSTANTS.RESPONSE_CONTEXT.COLLECTION_INFO_CONTEXT.includes(key)) {
        collectionInfo[key] = data.Collection[key];
      }
    }

    const columns = prepareGridColumns(data);
    yield put({
      type: SET_COMBINED_COLLECTIONS_STATE,
      payload: {
        columns,
        collectionInfo,
        collectionState: data.CollectionState,
        viewRow: data.ViewRow,
        viewFields: data.ViewFields,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getCombinedCollectionsSavedViews({ type: action, collectionId }) {
  const params = {
    AccountKey: "",
    CollectionID: collectionId,
    Command: CONSTANTS.COMMANDS.COLLECTION_GETVIEWS,
  };

  try {
    const result = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETVIEWS,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (result.data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        result.data.JsonResult.Result,
        result.data.JsonResult.ErrorDescription,
        result.data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    const savedViews = [];

    result.data.ViewTable.forEach((view) => {
      let savedView = {};
      for (let key in view) {
        if (CONSTANTS.RESPONSE_CONTEXT.SAVED_VIEWS_CONTEXT.includes(key)) {
          savedView[key] = view[key];
        }
      }
      savedViews.push(savedView);
    });

    yield put({
      type: SET_COMBINED_COLLECTIONS_SAVED_VIEWS,
      payload: {
        savedViews,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* rebuildCombineCollection(action) {
  const { collectionId, closeModal, viewId } = action;
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_REBUILD,
      new URLSearchParams({
        CollectionID: collectionId,
        Command: CONSTANTS.COMMANDS.COLLECTION_REBUILD,
      })
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    const STORE_ID = CONSTANTS.STORES.IDS.COLLECTION_COMBINED_DATA;
    yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams({
        CollectionID: collectionId,
        FolderID: "",
        AccountKey: "",
        ViewID: viewId,
        Command: COMMANDS.COLLECTION_GETCOMPLETE,
        OverrideViewName: "",
        HideSystemViewNames: "",
      })
    );
    window["Ext"].getStore(STORE_ID).load({
      params: {
        Command: COMMANDS.VIEW_GETITEMS,
        CollectionID: data.CollectionState.CollectionID,
        ViewID: data.CollectionState.ViewID,
        AgentID: data.AgentID,
        BookmarkID: data.Collection.BookmarkID,
        JobID: -1,
        ItemStatusesToInclude: `Added,
      Changed,
      Unchanged`,
        ColumnsToAlwaysInclude: "Name",
        ForceViewSortable: "",
        AlwaysIncludeCustomFields: false,
        IncludeAdditionalCustomInterfaceColumns: "",
        SelectedIDs: "",
        ShowHistoryColumn: false,
        ShowSelected: false,
        TemplateCollectionID: 0,
        SearchText: "",
        SearchField: "",
        ShowMarkup: false,
        page: data.CollectionState.PageIndex,
        start: 0,
        limit: data.CollectionState.PageItemCount,
      },
    });
    closeModal();
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* createCollectionFromExisting({
  params,
  setOpenedSourceFieldsToCombinedCollectionModal,
}) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_CREATE_NEW_FROM_EXISTING,
      params
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    const collectionGetFieldsParams = {
      CollectionID: data.CollectionID,
      IncludeFieldMap: true,
      ExcludeSystemFields: "Yes",
      Command: CONSTANTS.COMMANDS.COLLECTION_GETFIELDS,
    };

    yield put({
      type: SET_COLLECTION_DATA_FROM_EXISTING,
      payload: {
        data,
        collectionGetFieldsParams,
      },
    });

    setOpenedSourceFieldsToCombinedCollectionModal(true);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function getExistingFieldsGridColumns() {
  return [
    {
      dataIndex: "FieldID",
      text: "<span title='FieldID'>FieldID</span>",
      locked: true,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 75,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "Name",
      text: "<span title='Field Name'>Field Name</span>",
      locked: true,
      menuDisabled: true,
      resizable: true,
      searchable: true,
      width: 220,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "Format",
      text: "<span title='Format'>Format</span>",
      locked: false,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 125,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "Config",
      text: "<span title='Config'>Config</span>",
      locked: false,
      menuDisabled: true,
      resizable: true,
      searchable: true,
      width: 116,
      cell: {
        height: 50,
        encodeHtml: true,
        xtype: "reactcell",
      },
      renderer: (value, record) =>
        renderFieldConfigCell(mapFieldConfigCell, value, record),
    },
    {
      dataIndex: "Description",
      text: "<span title='Description'>Description</span>",
      locked: false,
      menuDisabled: true,
      resizable: true,
      searchable: true,
      width: 387,
      cell: {
        height: 50,
        encodeHtml: true,
        xtype: "reactcell",
      },
      renderer: (value, record) =>
        renderFieldDescriptionCell(mapFieldDescriptionCell, value, record),
    },
  ];
}

function* getCombinedDataExistingFields() {
  const reduxState = yield select();
  function getStoreData(store, params) {
    return new Promise((resolve) => {
      store.baseParams = params;
      store.getProxy().extraParams = store.baseParams;
      store.load({
        params,
        callback: (records) => resolve(records),
      });
    });
  }
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETFIELDS,
      new URLSearchParams(
        reduxState.collections.createdCollectionDataFromExisting.collectionGetFieldsParams
      )
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    const columns = getExistingFieldsGridColumns();

    yield put({
      type: SET_COMBINED_DATA_EXISTING_FIELDS,
      payload: {
        columns,
      },
    });
    const EXISTING_DATA_FIELDS_STORE_ID =
      CONSTANTS.STORES.IDS.COLLECTION_COMBINED_DATA_EXISTING_FIELDS;

    const store = Ext.getStore(EXISTING_DATA_FIELDS_STORE_ID);

    const storeData = yield getStoreData(
      store,
      reduxState.collections.createdCollectionDataFromExisting
        .collectionGetFieldsParams
    );

    yield call(setSourceCollectionsInfo, storeData);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* setSourceCollectionsInfo(records) {
  const { ids, existingFields } = getSourceCollectionInfo(records);
  yield put({
    type: SET_COMBINED_DATA_SOURCE_COLLECTIONS_INFO,
    payload: {
      ids,
      fields: existingFields,
      selectedIds: ids,
    },
  });
}

function getSourceCollectionInfo(records) {
  let existingFieldsMap = [];
  records.forEach((record) => {
    const fieldMaps = "data" in record ? record.data.FieldMap : record.FieldMap;

    existingFieldsMap = [...existingFieldsMap, ...fieldMaps];
  });

  const collectionIds = existingFieldsMap.map(
    (fieldMap) => fieldMap.CollectionCollectionID
  );

  return {
    ids: [...new Set(collectionIds)],
    existingFields: existingFieldsMap,
  };
}

function getSourceFieldsGridColumns() {
  return [
    {
      dataIndex: "Name",
      text: "<span title='Field Name'>Field Name</span>",
      locked: true,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 150,
      cell: {
        height: 50,
        encodeHtml: true,
        xtype: "reactcell",
      },
      renderer: (value, record) =>
        renderSourceFieldsGridNameCell(
          mapSourceFieldsGridNameCell,
          value,
          record
        ),
    },
    {
      dataIndex: "CollectionCount",
      text: "<span title='Collections'>Collections</span>",
      minWidth: 50,
      flex: 1,
      cell: {
        height: 50,
        encodeHtml: true,
        xtype: "reactcell",
      },
      renderer: (value, record) =>
        renderSourceFieldsGridCollectionsCell(
          mapSourceFieldsGridCollectionsCell,
          value,
          record
        ),
    },
  ];
}

function* getCombinedDataSourceFields({
  type: action,
  storeId,
  selectedSourceCollections,
}) {
  const reduxState = yield select();

  const sourceCollections = {
    ids: [],
    selectedIds: [],
    fields: [],
  };

  const collectionIds = [];

  selectedSourceCollections.forEach((sourceCollection) => {
    sourceCollections.selectedIds.push(Number(sourceCollection.id));
    sourceCollections.ids.push(Number(sourceCollection.id));
    collectionIds.push(Number(sourceCollection.data.CollectionID));
  });
  sourceCollections.fields = selectedSourceCollections;

  const params = new URLSearchParams({
    Command: CONSTANTS.COMMANDS.COLLECTION_GETFIELDS,
    ExcludeSystemFields: "Yes",
  });
  sourceCollections.ids.forEach((id) => {
    params.append("CollectionIDs", id);
  });

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETFIELDS,
      params,
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    const getFieldsData = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETFIELDS,
      new URLSearchParams(
        reduxState.collections.createdCollectionDataFromExisting.collectionGetFieldsParams
      )
    );

    const columns = getSourceFieldsGridColumns();

    yield put({
      type: SET_COMBINED_DATA_SOURCE_FIELDS,
      payload: {
        columns,
      },
    });

    yield call(
      loadSourceFieldsStore,
      storeId,
      data.Fields,
      getFieldsData.data.Fields,
      sourceCollections.selectedIds
    );
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* loadSourceFieldsStore(storeId, result, existingFields, selectedIds) {
  function createEmptyRecord(collectionField) {
    const isUsed =
      existingFields.find(
        (field) => field.CollectionFieldID === collectionField.FieldID
      ) !== undefined;

    return Ext.create("SourceFieldsModel", {
      Name: collectionField.Name,
      Format: collectionField.Format,
      Description: collectionField.Description,
      CollectionCount: 1,
      Fields: [collectionField],
      CollectionIDs: [collectionField.CollectionID],
      CollectionNames: {
        [collectionField.CollectionID]: collectionField.CollectionName,
      },
      isUsed,
      isUsedInitialState: isUsed,
    });
  }

  function updateRecord(consolidatedSourceFieldRecord, collectionField) {
    let collectionCount = consolidatedSourceFieldRecord.get("CollectionCount");
    let fields = consolidatedSourceFieldRecord.get("Fields");
    let collectionIDs = consolidatedSourceFieldRecord.get("CollectionIDs");
    let collectionNames = consolidatedSourceFieldRecord.get("CollectionNames");
    let isUsed = consolidatedSourceFieldRecord.get("isUsed");

    // Update the current values.
    collectionCount++;
    fields.push(collectionField);
    collectionIDs.push(collectionField.CollectionID);
    collectionNames[collectionField.CollectionID] =
      collectionField.CollectionName;

    // Set these values back on the record.
    consolidatedSourceFieldRecord.set("CollectionCount", collectionCount);
    consolidatedSourceFieldRecord.set("Fields", fields);
    consolidatedSourceFieldRecord.set("CollectionIDs", collectionIDs);
    consolidatedSourceFieldRecord.set("CollectionNames", collectionNames);
    if (
      !isUsed &&
      existingFields.find(
        (field) => field.CollectionFieldID === collectionField.FieldID
      ) !== undefined
    ) {
      consolidatedSourceFieldRecord.set("isUsed", true);
      consolidatedSourceFieldRecord.set("isUsedInitialState", true);
    }
  }

  function loadStoreWithData(store, data) {
    return new Promise((resolve) => {
      if (store.isLoaded()) {
        store.loadData(data);
        resolve(data);
      } else {
        store.load({
          callback: () => {
            store.loadData(data);
            resolve(data);
          },
        });
      }
    });
  }

  const store = Ext.getStore("CombinedDataSourceFieldsStore");
  store.removeAll(true);

  let data = [];
  for (let index = 0; index < result.length; index++) {
    const field = result[index];
    let consolidatedSourceField = data.find(
      (row) => row.data.Name === field.Name
    );
    if (!consolidatedSourceField) {
      data.push(createEmptyRecord(field));
    } else {
      updateRecord(consolidatedSourceField, field);
    }
  }

  const sharedFields = data.filter(
    (record) => record.get("CollectionIDs").length === selectedIds.length
  );
  yield put({
    type: SET_COMBINED_DATA_SHARED_FIELDS,
    payload: {
      sharedFields,
    },
  });

  const dataRes = yield call(loadStoreWithData, store, data);
  store.removeAll(true);
  store.loadData(dataRes);

  yield put({
    type: SET_COMBINED_DATA_SOURCE_FIELDS_LOADED,
    payload: {
      loaded: true,
    },
  });
}

function getSameCollectionFieldsFilter(selectedItems) {
  function isSameCollection(item, selectedItemsCollectionIds) {
    const itemCollectionsIDs = item.get("CollectionIDs");
    return itemCollectionsIDs.find((collectionId) =>
      selectedItemsCollectionIds.includes(collectionId)
    );
  }

  let selectedItemsCollectionIds = [];
  selectedItems.forEach((item) => {
    selectedItemsCollectionIds = [
      ...selectedItemsCollectionIds,
      ...item.data.CollectionIDs,
    ];
  });

  return new Ext.util.Filter({
    id: CONSTANTS.STORES.FILTERS.SAME_COLLECTION_FIELDS,
    filterFn: (item) => {
      const itemName = item.get("Name");
      const isSelectedItem = selectedItems.find(
        (selectedItem) => selectedItem.data.Name === itemName
      );
      if (isSelectedItem) {
        return true;
      }

      return !isSameCollection(item, selectedItemsCollectionIds);
    },
  });
}

function getUsedFieldsFilter() {
  return new Ext.util.Filter({
    id: CONSTANTS.STORES.FILTERS.USED_FIELDS,
    filterFn: (item) => !item.get("isUsed"),
  });
}

function isFilterApplied(filters, id) {
  return filters.map && filters.map[id] ? true : false;
}

function applyUsedFieldsFilter(filters) {
  const usedFieldsFilter = getUsedFieldsFilter();

  filters.add(usedFieldsFilter);
}
function applySameCollectionFieldsFilter(filters, selectedItems) {
  const sameCollectionFieldsFilter = getSameCollectionFieldsFilter(
    selectedItems
  );

  filters.add(sameCollectionFieldsFilter);
}

function filterUsedFields({ storeId, showAllFields, selectedItems }) {
  const store = Ext.getStore(storeId);

  const filters = store.getFilters();

  const isSameCollectionFieldsFilterApplied = isFilterApplied(
    filters,
    CONSTANTS.STORES.FILTERS.SAME_COLLECTION_FIELDS
  );

  if (showAllFields) {
    if (isSameCollectionFieldsFilterApplied) {
      store.removeFilter(CONSTANTS.STORES.FILTERS.SAME_COLLECTION_FIELDS);
    }

    store.removeFilter(CONSTANTS.STORES.FILTERS.USED_FIELDS);
  } else {
    applyUsedFieldsFilter(filters);

    if (!isSameCollectionFieldsFilterApplied) {
      const unUsedSelectedItems = selectedItems.filter(
        (item) => !item.get("isUsed")
      );

      if (unUsedSelectedItems.length > 0) {
        applySameCollectionFieldsFilter(filters, unUsedSelectedItems);
      }
    }
  }
}

function filterSameCollectionItems({ storeId, selectedItems }) {
  const store = Ext.getStore(storeId);
  const filters = store.getFilters();

  const hideUsedFields = isFilterApplied(
    filters,
    CONSTANTS.STORES.FILTERS.USED_FIELDS
  );

  if (selectedItems.length === 0) {
    store.removeFilter(CONSTANTS.STORES.FILTERS.SAME_COLLECTION_FIELDS);
  } else if (hideUsedFields) {
    applySameCollectionFieldsFilter(filters, selectedItems);
  }
}
function getFilterByFieldName(searchText) {
  return new Ext.util.Filter({
    id: CONSTANTS.STORES.FILTERS.FILTER_FIELDS_BY_FIELD_NAME,
    filterFn: (item) =>
      item
        .get("Name")
        .toString()
        .toLowerCase()
        .includes(searchText.toString().toLowerCase()),
  });
}

function applyFilterByFieldName(filters, searchText) {
  const filterByFieldName = getFilterByFieldName(searchText);

  filters.add(filterByFieldName);
}

function filterByFieldName({ storeId, searchText }) {
  const store = Ext.getStore(storeId);
  if (store) {
    const filters = store.getFilters();
    store.removeFilter(CONSTANTS.STORES.FILTERS.SAME_COLLECTION_FIELDS);
    applyFilterByFieldName(filters, searchText);
  }
}

function* rebuild({ closeModal, navigate }) {
  const reduxState = yield select();
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_REBUILD,
      new URLSearchParams({
        CollectionID:
          reduxState.collections.createdCollectionDataFromExisting.data
            .CollectionID,
        Command: COMMANDS.COLLECTION_REBUILD,
      })
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    closeModal();
    navigate(
      `/collections/standard/${reduxState.collections.createdCollectionDataFromExisting.data.CollectionID}/data`
    );
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

export default function* combinedCollectionWatcher() {
  yield takeLatest(GET_COMBINED_COLLECTIONS, fetchCombinedCollections);
  yield takeLatest(
    GET_COMBINED_COLLECTIONS_STATE,
    fetchCombinedCollectionState
  );
  yield takeLatest(REBUILD_COMBINE_COLLECTION, rebuildCombineCollection);
  yield takeLatest(
    GET_COMBINED_COLLECTIONS_SAVED_VIEWS,
    getCombinedCollectionsSavedViews
  );

  yield takeLatest(
    COLLECTION_CREATE_NEW_FROM_EXISTING,
    createCollectionFromExisting
  );
  yield takeLatest(
    GET_COMBINED_DATA_EXISTING_FIELDS,
    getCombinedDataExistingFields
  );
  yield takeLatest(
    GET_COMBINED_DATA_SOURCE_FIELDS,
    getCombinedDataSourceFields
  );
  yield takeLatest(FILTER_USED_COMBINED_DATA_SOURCE_FIELDS, filterUsedFields);
  yield takeLatest(
    FILTER_COMBINED_DATA_SAME_COLLECTION_SOURCE_FIELDS,
    filterSameCollectionItems
  );
  yield takeLatest(FILTER_COMBINED_DATA_BY_FIELD_NAME, filterByFieldName);
  yield takeLatest(COLLECTION_REBUILD, rebuild);
  yield takeLatest(GET_ALL_COMBINED_COLLECTIONS, getAllCombinedCollections);
}
