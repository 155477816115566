import { CONSTANTS } from "../../../constants/constants";
import { SEQUENCE_STEPS } from "../../../constants/sequenceSteps";
import { capitalizeFirstLetter } from "../../../utils/string";
import { getDateFromString, getDateInMsFromFormat } from "../../../utils/date";
import variables from "../../../assets/sass/_variables.scss";

const Ext = window["Ext"];

function _getPublishIcon(config) {
  const noTemplatePublisher = config.indexOf("outofsync-publisher-none") > -1;
  const outOfSync = config.indexOf("outofsync-publisher") > -1;

  function _getIconFromGroup(iconGroup) {
    const icon = noTemplatePublisher
      ? "_OUTOFSYNC_NONE"
      : outOfSync
      ? "_OUTOFSYNC"
      : "";

    return CONSTANTS.CONFIG_CELL_ICONS.DEFAULT[`${iconGroup}${icon}`];
  }

  if (config.indexOf("publishftp") > -1) {
    return _getIconFromGroup("PUBLISHFTP");
  } else if (config.indexOf("publishemail") > -1) {
    return _getIconFromGroup("PUBLISHEMAIL");
  } else if (config.indexOf("publishamazon") > -1) {
    return _getIconFromGroup("PUBLISHAMAZON");
  } else if (config.indexOf("publishazurestorage") > -1) {
    return _getIconFromGroup("PUBLISHAZURESTORAGE");
  } else if (config.indexOf("publishdropbox") > -1) {
    return _getIconFromGroup("PUBLISHDROPBOX");
  } else if (config.indexOf("publishgoogle") > -1) {
    return _getIconFromGroup("PUBLISHGOOGLE");
  } else if (config.indexOf("publishwrangle") > -1) {
    return _getIconFromGroup("PUBLISHWRANGLE");
  } else if (config.indexOf("publish ") > -1) {
    return _getIconFromGroup("PUBLISH");
  } else {
    return {
      ...CONSTANTS.CONFIG_CELL_ICONS.DEFAULT.PUBLISH_OUTOFSYNC,
      visible: outOfSync,
    };
  }
}

function _getBrowserType(record) {
  const browserType = record.get("BrowserType");
  if (!browserType) return null;
  return browserType.toLowerCase().indexOf("chromium") > -1
    ? CONSTANTS.CONFIG_CELL_ICONS.DEFAULT.CHROMIUM
    : CONSTANTS.CONFIG_CELL_ICONS.DEFAULT.IE;
}

export const mapAgentConfigCell = (value, record) => {
  const config = record.get("Config")?.toLowerCase();
  let settings = record.get("Settings");
  if (!settings || typeof settings !== "string") settings = "";
  settings = settings.toLowerCase();
  const { DEFAULT: CONFIG_ICONS } = CONSTANTS.CONFIG_CELL_ICONS;
  const icons = [];

  const browserIcon = _getBrowserType(record);
  if (browserIcon) icons.push(browserIcon);

  // Anonymous (Required/Configured)
  icons.push({
    ...CONFIG_ICONS.PREMIUMHARVESTING,
    visible:
      config.indexOf("anonymousrequired") > -1 ||
      config.indexOf("anonymousconfigured") > -1,
  });

  // Schedule
  if (config.indexOf("outofsync-schedule") > -1) {
    icons.push(CONFIG_ICONS.OUTOFSYNC_SCHEDULE);
  } else {
    icons.push({
      ...CONFIG_ICONS.SCHEDULE,
      visible: config.indexOf("schedule") > -1,
    });
  }

  icons.push(_getPublishIcon(config));

  if (config.indexOf("outofsync-notification") > -1) {
    icons.push(CONFIG_ICONS.NOTIFICATION_OUTOFSYNC);
  } else {
    icons.push({
      ...CONFIG_ICONS.NOTIFICATION,
      visible: config.indexOf("notification") > -1,
    });
  }
  // Group settings out of Sync
  icons.push({
    ...CONFIG_ICONS.SETTINGS_OUTOFSYNC,
    visible: config.indexOf("outofsync-settings") > -1,
  });

  // Sources Combined
  icons.push({
    ...CONFIG_ICONS.SOURCESCOMBINED,
    visible: config.indexOf("sourcescombined") > -1,
  });

  // Track History
  icons.push({
    ...CONFIG_ICONS.TRACKHISTORY,
    visible: config.indexOf("trackhistory") > -1, // && AccountHasFeature(Zen.Constants.Features.ItemHistory)
  });

  // AgentBuilderCompatibility
  icons.push({
    ...CONFIG_ICONS.AGENTBUILDERCOMPATIBILITY,
    visible: config.indexOf("agentbuildercompatibility") > -1,
  });

  // AutoBlock
  icons.push({
    ...CONFIG_ICONS.AUTOBLOCKDOMAINLIST,
    visible:
      config.indexOf("autoblockdomainlist") > -1 ||
      settings.indexOf("autoblockthirdpartydomains") > -1,
  });

  // RSS Publishing
  icons.push({
    ...CONFIG_ICONS.PUBLISHRSS,
    visible: config.indexOf("publishrss") > -1,
  });

  // Sources Distributed
  icons.push({
    ...CONFIG_ICONS.SOURCESDISTRIBUTED,
    visible: config.indexOf("sourcesdistributed") > -1,
  });

  return icons;
};

export const mapAgentListStatusCell = (value, record, accountInfo) => {
  let status = value;

  if (record.data.CollectionIsUnderMaintenance) {
    status = record.data.IsRestarting ? "Restarting" : "Unavailable";
  } else if (record.data.HasMultipleJobsInQueue) {
    status = "Multiple";
  } else if (record.data.HasReportRefreshDataJob) {
    status = "RefreshingData";
  }

  if (status === "Postponed") {
    const now = new Date();
    let formattedDateTime = getDateInMsFromFormat(
      record.data.Start,
      "yyyy-MM-dd HH:mm:ss"
    );

    const client_utc_offset = now.getTimezoneOffset();
    const account_utc_offset = accountInfo?.Account.TimeZoneUTCOffset;
    formattedDateTime =
      formattedDateTime - (client_utc_offset - account_utc_offset) * 60 * 1000;

    if (formattedDateTime) {
      const dateTime = new Date(formattedDateTime);
      if (record.data.Start !== undefined && dateTime <= now) status = "Queued";
    }
  }

  switch (status) {
    case "Multiple":
      return {
        label: {
          value: "Multiple Jobs",
          color: variables["color-midgray"],
        },
        icon: {
          icon: ["fad", "pause-circle"],
          color: variables["color-midgray"],
        },
      };
    case "LimitReached":
      return {
        label: {
          value: "Limit Reached",
          color: variables["color-warning"],
        },
        icon: {
          icon: ["fad", "exclamation-triangle"],
          color: variables["color-warning"],
        },
      };
    case "RefreshingData":
      return {
        label: {
          value: "Refreshing",
          color: variables["color-fadedjade"],
        },
        icon: {
          icon: ["fad", "circle-notch"],
          color: variables["color-fadedjade"],
          spin: true,
        },
      };
    case "Cycled":
    case "Cycling":
    case "Recovered":
    case "Rejected":
    case "Restarted":
    case "Restarting":
    case "Running":
    case "Starting":
    case "Warning":
      return {
        label: {
          value: "Running",
        },
        icon: {
          icon: ["fad", "circle-notch"],
          color: variables["color-spacecadet"],
          spin: true,
        },
      };
    case "Queued":
      return {
        label: {
          value: "Queued",
          color: variables["color-success"],
        },
        icon: {
          icon: ["fa", "check-circle"],
          color: variables["color-success"],
        },
      };
    case "Postponed":
      return {
        label: {
          value: "Postponed",
          color: variables["color-warning"],
        },
        icon: {
          icon: ["far", "history"],
          color: variables["color-warning"],
        },
      };
    case "None":
    case "Ready":
      return {
        label: {
          value: "Ready",
        },
      };
    case "Paused":
      return {
        label: {
          value: "Paused",
          color: variables["color-warning"],
        },
        icon: {
          icon: ["fad", "pause-circle"],
          color: variables["color-warning"],
        },
      };
    case "Error":
      return {
        label: {
          value: "Error",
          color: variables["color-danger"],
        },
        icon: {
          icon: ["fad", "exclamation-triangle"],
          color: variables["color-danger"],
        },
      };
    case "Pausing":
    case "Canceling":
      return {
        label: {
          value: "Pausing",
          color: variables["color-midgray"],
        },
        icon: {
          icon: ["fad", "pause-circle"],
          color: variables["color-midgray"],
        },
      };
    default:
      return {
        label: {
          value: "Ready",
        },
      };
  }
};

export const mapLastRunResultCell = (value) => {
  const jobStatus = CONSTANTS.JOB_STATUS[value.toUpperCase()];

  return {
    icon: {
      TYPE: jobStatus.TYPE,
      ICON: jobStatus.ICON,
      COLOR: jobStatus.COLOR,
      DESCRIPTION: jobStatus.STATUS,
    },
    label: {
      value: jobStatus.STATUS,
    },
  };
};

export const mapCollectionNameCell = (value, record) => {
  const collectionType = record.get("CollectionType");

  return {
    id: record.id,
    name: value,
    type: CONSTANTS.COLLECTIONS.TYPE[collectionType.toUpperCase()],
    collectionType: collectionType,
  };
};

export const mapSequencesConfigCell = (value, record) => {
  const config = record.get("Config").toLowerCase();

  const iconNames = [
    "schedule",
    "agentrun",
    "publishdata",
    "collectionclear",
    "updatefieldvalue",
    "viewdeleteitems",
    "sequencerun",
    "trackhistory",
    "sourcescombined",
    "notifications",
    "publishemail",
  ];

  return iconNames.map((name) => {
    return {
      ...CONSTANTS.CONFIG_CELL_ICONS.SEQUENCE[name.toUpperCase()],
      visible: config.indexOf(name) > -1,
    };
  });
};

export const mapSequenceStepCell = (value, record) => {
  const stepType = record.get("StepType").toUpperCase();
  return {
    ...SEQUENCE_STEPS[stepType],
    value,
  };
};

export const mapSequenceStepConfigCell = (value, record) => {
  const stepType = record.get("StepType");
  let config = record.get("Config");

  if (config) config = config.toLowerCase();

  if (stepType === "AgentRun") return _generateAgentRunIcons(config);

  if (stepType === "PublishData") return _generatePublishDataIcons(config);

  if (stepType === "UpdateFieldValue")
    return _generateUpdateFieldValueIcons(config);

  return [];
};

function _generateAgentRunIcons(config) {
  const { AGENT_RUN } = CONSTANTS.CONFIG_CELL_ICONS.SEQUENCE_STEPS;
  let icons = [];
  if (config.indexOf("resumeerrors") > -1) {
    icons.push(AGENT_RUN.RESUMEERRORS);
  }

  if (config.indexOf("concurrentjobs") > -1) {
    icons.push(AGENT_RUN.CONCURRENTJOBS);
  }

  if (config.indexOf("errorcodes") > -1) {
    icons.push(AGENT_RUN.ERRORCODES);
  }

  if (config.indexOf("parameters") > -1) {
    icons.push(AGENT_RUN.PARAMETERS);
  }

  return icons;
}

function _generatePublishDataIcons(config) {
  const { PUBLISHDATA } = CONSTANTS.CONFIG_CELL_ICONS.SEQUENCE_STEPS;
  if (config.indexOf("publishftp") > -1) {
    return [
      {
        ...PUBLISHDATA.PUBLISHFTP,
      },
    ];
  } else if (config.indexOf("publishemail") > -1) {
    return [PUBLISHDATA.PUBLISHEMAIL];
  } else if (config.indexOf("publishamazon") > -1) {
    return [PUBLISHDATA.PUBLISHAMAZON];
  } else if (config.indexOf("publishazurestorage") > -1) {
    return [PUBLISHDATA.PUBLISHAZURESTORAGE];
  } else if (config.indexOf("publishdropbox") > -1) {
    return [PUBLISHDATA.PUBLISHDROPBOX];
  } else if (config.indexOf("publishgoogle") > -1) {
    return [PUBLISHDATA.PUBLISHGOOGLE];
  } else if (config.indexOf("publishwrangle") > -1) {
    return [PUBLISHDATA.PUBLISHWRANGLE];
  } else {
    return [
      {
        ...PUBLISHDATA.PUBLISH,
        visible: config.indexOf("publish ") > -1,
      },
    ];
  }
}

function _generateUpdateFieldValueIcons(config) {
  return [
    {
      ...CONSTANTS.CONFIG_CELL_ICONS.SEQUENCE_STEPS.UPDATEFIELDVALUE.LIMIT,
      visible: config.indexOf("limit") > -1,
    },
  ];
}

export const mapSequenceStepStatusCell = (value) => {
  if (value === "Ready") {
    return {
      STATUS: "Ready",
    };
  }
  return CONSTANTS.JOB_STATUS[value.toUpperCase()];
};

export const mapSequenceStepProgressCell = (value, record) => {
  if (value !== "") return value;

  const stepType = record.get("StepType");
  const status = record.get("Status");

  if (status !== "Running") return value;

  let result = "";
  switch (stepType) {
    case "AgentRun":
      result = "Executing agent actions";
      break;

    case "CollectionClear":
      result = "Clearing collection data";
      break;

    case "PublishData":
      result = result = "Publishing collection data";
      break;

    default:
      break;
  }
  return result;
};

export const mapFieldConfigCell = (value, record) => {
  const iconNames = [
    "IsUnique",
    "IsRequired",
    "IsWatched",
    "IsConfidential",
    "DefaultValue",
  ];

  const { FIELDS: FIELDS_CONFIG_ICONS } = CONSTANTS.CONFIG_CELL_ICONS;

  return iconNames.map((name) => {
    if (name === "DefaultValue") {
      const defaultValue = record.get("DefaultValue");
      return {
        ...FIELDS_CONFIG_ICONS[name.toUpperCase()],
        DESCRIPTION: `${
          FIELDS_CONFIG_ICONS[name.toUpperCase()].DESCRIPTION
        } ${defaultValue}`,
        visible: defaultValue !== "",
      };
    }
    return {
      ...FIELDS_CONFIG_ICONS[name.toUpperCase()],
      visible: record.data[name],
    };
  });
};

export const mapFieldDescriptionCell = (value, record) => {
  const isTemplateField = record.get("IsTemplate");
  if (isTemplateField) return value;

  const agentIDs = record.get("AgentIDs");
  if (!agentIDs) return value;

  const agentNames = record.get("AgentNames");

  if (agentIDs.length === 1) {
    return agentNames[0];
  }

  return `${agentIDs.length} agents`;
};

export const mapDepartmentUsersStatusCell = (value, record) => {
  const { USERS_STATUS } = CONSTANTS.ACCOUNTS;
  const statusOptions = record.data.Status.toString();
  const splitter = /,[ ]*/;
  const statusArray = statusOptions.split(splitter);

  let icons = [];

  if (statusArray.includes("Disabled")) {
    icons.push(USERS_STATUS.DISABLED);
  }

  if (statusArray.includes("Mozenda")) {
    icons.push(USERS_STATUS.MOZENDA);
  } else if (statusArray.includes("Invited")) {
    icons.push(USERS_STATUS.INVITED);
  } else if (
    statusArray.includes(
      "MasterAccountAdministrator"
    ) /* && Zen.AccountDashboard.Account.BillParentAccountID > 0 */
  ) {
    icons.push(USERS_STATUS.MASTERACCOUNTADMINISTRATOR, {
      ...USERS_STATUS.ACCOUNTADMINISTRATOR,
      // DESCRIPTION: `${Zen.Account.Info.Account.AccountTermName.capitalizeFirstLetter()}administrator`,
      DESCRIPTION: `administrator`,
    });
  } else if (
    statusArray.includes(
      "Administrator"
    ) /* && FieldFlag(Zen.AccountDashboard.Account.Features, Zen.Constants.Features.Master) */
  ) {
    icons.push(USERS_STATUS.MASTERACCOUNTADMINISTRATOR);
  } else if (
    statusArray.includes(
      "Administrator"
    ) /* && Zen.AccountDashboard.Account.BillParentAccountID > 0 */
  ) {
    icons.push({
      ...USERS_STATUS.ACCOUNTADMINISTRATOR,
      // DESCRIPTION: `${Zen.Account.Info.Account.AccountTermName.capitalizeFirstLetter()}administrator`,
      DESCRIPTION: `administrator`,
    });
  } else if (statusArray.includes("Administrator")) {
    icons.push({
      ...USERS_STATUS.ACCOUNTADMINISTRATOR,
      DESCRIPTION: "Account administrator",
    });
  } else if (statusArray.includes("User")) {
    icons.push({
      ...USERS_STATUS.ACCOUNTADMINISTRATOR,
      DESCRIPTION: "Account administrator",
    });
  }

  return icons;
};

export const mapDepartmentUsersAgentsCell = (value, record) => {
  const agents = record.data.Agents.toString();
  const { USERS_AGENTS } = CONSTANTS.ACCOUNTS;
  const splitter = /,[ ]*/;
  const agentsArray = agents.split(splitter);

  return [
    {
      ...USERS_AGENTS.RUN,
      visible: agentsArray.includes("Run"),
    },
    {
      ...USERS_AGENTS.CONFIGURE,
      visible: agentsArray.includes("Configure"),
    },
    {
      ...USERS_AGENTS.AGENTBUILD,
      visible: agentsArray.includes("AgentBuild"),
    },
  ];
};

export const mapDepartmentUsersCollectionsCell = (value, record) => {
  const collectionOptions = record.data.Collections.toString();
  const splitter = /,[ ]*/;
  const collectionsArray = collectionOptions.split(splitter);

  const {
    VIEW,
    CONFIDENTIAL,
    EXPORT,
    IMPORT,
    CLEAR,
    COLLECTIONBUILD,
  } = CONSTANTS.ACCOUNTS.USERS_ACCOUNT_PERMISSIONS;

  return [
    {
      ...VIEW,
      visible: collectionsArray.includes("View"),
    },
    {
      ...CONFIDENTIAL,
      visible: collectionsArray.includes("Confidential"),
    },
    {
      ...EXPORT,
      visible: collectionsArray.includes("Export"),
    },
    {
      ...IMPORT,
      visible: collectionsArray.includes("Import"),
    },
    {
      ...CLEAR,
      visible: collectionsArray.includes("Clear"),
    },
    {
      ...COLLECTIONBUILD,
      visible: collectionsArray.includes("CollectionBuild"),
    },
  ];
};

export const mapDepartmentUsersSequencesCell = (value, record) => {
  const sequenceField = record.data.Sequences;
  const splitter = /,[ ]*/;
  const sequenceSettingsArray = sequenceField.split(splitter);

  return [
    {
      ...CONSTANTS.ACCOUNTS.USERS_ACCOUNT_PERMISSIONS.SEQUENCES,
      visible: sequenceSettingsArray.includes("Sequences"),
    },
  ];
};

export const mapDepartmentUsersAccountCell = (value, record, accountInfo) => {
  // const isChildAccount =
  //   Zen.AccountDashboard.Account.MasterAccountName &&
  //   Zen.AccountDashboard.Account.MasterAccountName != "";
  // const accountTermName = isChildAccount
  //   ? Zen.Account.Info.Account.AccountTermName
  //   : "account";

  try {
    const accountTermName = "account";
    const account = record.data[capitalizeFirstLetter(accountTermName)];

    const splitter = /,[ ]*/;
    const accountsArray = account.split(splitter);

    return [
      {
        ...CONSTANTS.ACCOUNTS.USERS_ACCOUNT_PERMISSIONS.USERS,
        DESCRIPTION: `Users - Add, edit, and delete users in this ${accountTermName}`,
        visible: accountsArray.includes("Users"),
      },
      {
        ...CONSTANTS.ACCOUNTS.USERS_ACCOUNT_PERMISSIONS.BILLING,
        DESCRIPTION: `Billing - View ${accountTermName} billing information`,
        visible: accountsArray.includes("Billing"),
      },
    ];
  } catch {
    return [];
  }
};

export const mapDepartmentUsersNameFullCell = (value, record) => {
  let newValue = `${record.data.NameFirst} ${record.data.NameLast}`;
  if (newValue === " ") newValue = ""; // Use to set name to "Invitation sent"
  return newValue;
};

export const mapDepartmentUsersLastSeenCell = (value, record) => {
  // if (UserHasSetting(Zen.Constants.UserSettings.Invited, record.data)) return '';

  const date = getDateFromString(record.data.WebConsoleOnlineLast);
  if (!date) return "";

  let newValue = {};
  newValue = Ext.util.Format.date(date, "M j, Y");
  return newValue;
};

export const mapDepartmentUsersInvitedLastCell = (value, record) => {
  const date = getDateFromString(record.data.InvitedLast);
  if (!date) return "";

  let newValue = {};
  newValue = Ext.util.Format.date(date, "M j, Y");
  return newValue;
};
