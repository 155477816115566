import {
  GET_RECENT_ITEMS,
  SET_RECENT_ITEMS,
  UPDATE_RECENT_ITEMS,
  ADD_RECENT_ITEMS,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import { takeLatest, put, cancel } from "redux-saga/effects";
import axios from "axios";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";

function* getRecentItems() {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.USER_GETRECENTITEMS,
      new URLSearchParams({
        Command: CONSTANTS.COMMANDS.USER_GETRECENTITEMS,
      })
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    yield put({
      type: SET_RECENT_ITEMS,
      payload: data.Rows,
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* addRecentItems({ params }) {
  const { data } = yield axios.post(
    CONSTANTS.ROUTES.USER_ADD_RECENT_ITEM,
    new URLSearchParams(params)
  );
  if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
    yield cancel();
  }

  yield put({
    type: UPDATE_RECENT_ITEMS,
    payload: data.Rows,
  });
}
export default function* recentItemsWatcher() {
  yield takeLatest(GET_RECENT_ITEMS, getRecentItems);
  yield takeLatest(ADD_RECENT_ITEMS, addRecentItems);
}
