import { SET_BETA_SETTINGS } from "../types";
import createReducer from "../createReducer";

const initialState = {
  betaSettings: {
    betaWebConsoleUrl: false,
    betaWebConsoleEnabled: false,
    betaEnabled: false,
    betaHarvestingEnabled: false,
    betaWebConsoleRequireBetaHarvesting: "",
  },
};

export const common = createReducer(initialState, {
  [SET_BETA_SETTINGS](state, { payload }) {
    return {
      ...state,
      betaSettings: payload.betaSettings,
    };
  },
});
