import {
  GET_ACCOUNT_UTILIZATION_USAGE,
  SET_ACCOUNT_UTILIZATION_USAGE,
  GET_ACCOUNT_UTILIZATION_DOWNLOAD_AGENT_PAGES,
  SET_ACCOUNT_UTILIZATION_DOWNLOAD_AGENT_PAGES,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import { takeLatest, put, select, cancel } from "redux-saga/effects";
import axios from "axios";
import fileDownload from "../../utils/js-file-download/fileDownload";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";
const Ext = window["Ext"];

function prepareGridColumns() {
  return [
    {
      header: "Description",
      text: `<span title='Description'>Description</span>`,
      sortable: true,
      searchable: true,
      hideable: false,
      dataIndex: "Description",
      width: 330,
    },
    {
      header: "Total",
      text: `<span title='Total'>Total</span>`,
      sortDir: "ASC",
      sortType: "asInt",
      sortable: true,
      type: "float",
      searchable: true,
      hideable: false,
      align: "right",
      dataIndex: "TotalPages",
      width: 85,
      renderer: Ext.util.Format.numberRenderer("0,000"),
    },
    {
      header: "Standard",
      text: `<span title='Standard'>Standard</span>`,
      sortDir: "ASC",
      sortType: "asInt",
      sortable: true,
      type: "float",
      searchable: true,
      hideable: false,
      align: "right",
      dataIndex: "WebPageStandard",
      width: 100,
      renderer: Ext.util.Format.numberRenderer("0,000"),
    },
    {
      header: "Premium",
      text: `<span title='Premium'>Premium</span>`,
      sortable: true,
      searchable: true,
      hideable: false,
      align: "right",
      dataIndex: "WebPagePremium",
      width: 100,
      renderer: Ext.util.Format.numberRenderer("0,000"),
    },
    {
      header: "Standard Pages",
      text: `<span title='Standard Pages'>Standard Pages</span>`,
      sortDir: "ASC",
      sortType: "asInt",
      sortable: true,
      type: "float",
      searchable: true,
      hideable: false,
      align: "right",
      dataIndex: "WebPageCredit",
      width: 145,
      renderer: Ext.util.Format.numberRenderer("0,000"),
    },
    {
      header: "Anonymous Pages",
      text: `<span title='Anonymous Pages'>Anonymous Pages</span>`,
      sortable: true,
      searchable: true,
      hideable: false,
      align: "right",
      dataIndex: "WebPageAnonymous",
      width: 170,
      renderer: Ext.util.Format.numberRenderer("0,000"),
    },
    {
      header: "Images",
      text: `<span title='Images'>Images</span>`,
      sortable: true,
      searchable: true,
      hideable: false,
      align: "right",
      dataIndex: "WebImageDownload",
      width: 85,
      renderer: Ext.util.Format.numberRenderer("0,000"),
    },
    {
      header: "Files",
      text: `<span title='Files'>Files</span>`,
      sortable: true,
      searchable: true,
      hideable: false,
      align: "right",
      dataIndex: "WebFileDownload",
      width: 70,
      renderer: Ext.util.Format.numberRenderer("0,000"),
    },
    {
      header: "Notify Email",
      text: `<span title='Notify Email'>Notify Email</span>`,
      sortable: true,
      searchable: true,
      hideable: false,
      align: "right",
      dataIndex: "WebNotifyEmail",
      width: 125,
      renderer: Ext.util.Format.numberRenderer("0,000"),
    },
    {
      header: "Notify URL",
      text: `<span title='Notify URL'>Notify URL</span>`,
      sortable: true,
      searchable: true,
      hideable: false,
      align: "right",
      dataIndex: "WebNotifyUrl",
      width: 125,
      renderer: Ext.util.Format.numberRenderer("0,000"),
    },
  ];
}

function* fetchAccountUtilizationUsage({ storeId, ItemID }) {
  const store = Ext.getStore(storeId);

  const reduxState = yield select();
  if (store) {
    store.removeAll(true);

    store.load({
      params: {
        ItemID,
        AccountKey: reduxState.account.accountInfo.Account.AccountKey,
        Command: CONSTANTS.COMMANDS.UTILIZATION_GETUSAGE,
      },
    });
  }

  const columns = prepareGridColumns();

  yield put({
    type: SET_ACCOUNT_UTILIZATION_USAGE,
    payload: {
      columns,
    },
  });
}
function* fetchAccountUtilizationDownloadAgentPages({
  type: action,
  formParams,
  fileFormat,
}) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETVIEWS,
      new URLSearchParams({
        Command: CONSTANTS.COMMANDS.COLLECTION_GETVIEWS,
        CameFromPublisher: false,
      })
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    const accountDownloadStorage = yield axios.post(
      CONSTANTS.ROUTES.UTILIZATION_DOWNLOADAGENTPAGES,
      new URLSearchParams(formParams),
      {
        headers: {
          action,
        },
      }
    );

    const exportResponse = yield axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/export`,
      params: {
        BaseFileName: accountDownloadStorage.data.TempFile,
        UserFileName: `${accountDownloadStorage.data.FileName}.${fileFormat}`,
        Method: "SpecificFile",
      },
      responseType: "blob",
    });

    const filename = `${accountDownloadStorage.data.FileName}.${fileFormat}`;
    fileDownload(exportResponse.data, filename);

    yield put({
      type: SET_ACCOUNT_UTILIZATION_DOWNLOAD_AGENT_PAGES,
      payload: {
        data,
        accountDownloadStorage,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

export default function* accountUtilizationUsageWatcher() {
  yield takeLatest(GET_ACCOUNT_UTILIZATION_USAGE, fetchAccountUtilizationUsage);
  yield takeLatest(
    GET_ACCOUNT_UTILIZATION_DOWNLOAD_AGENT_PAGES,
    fetchAccountUtilizationDownloadAgentPages
  );
}
