import {
  GET_AGENTGROUPS_SUMMARY,
  SET_AGENTGROUPS_SUMMARY,
  AGENTGROUPS_AGENT_INFO_LOADED,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import { takeLatest, put, take, select, cancel } from "redux-saga/effects";
import axios from "axios";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";

function* fetchAgentGroupsSummary({
  type: action,
  agentGroupId,
  formParams = {},
}) {
  const agentInfo = yield select((state) => state.agentGroups.agentInfo);
  if (!agentInfo.ItemID || agentInfo.ItemID !== Number(agentGroupId)) {
    yield take(AGENTGROUPS_AGENT_INFO_LOADED);
  }

  const params = {
    Command: CONSTANTS.COMMANDS.TEMPLATE_GETSUMMARYSTATS,
    ...formParams,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.TEMPLATE_GETSUMMARYSTATS,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    let stats = data.AgentListStatistics.reduce(
      (acc, stat) => ({
        ...acc,
        [stat["Status"]]: stat["Value"],
      }),
      {}
    );

    if (data.JobStatistics && data.JobStatistics.length > 0) {
      for (let index = 0; index < data.JobStatistics.length; index++) {
        var item = data.JobStatistics[index];
        switch (item.Name.toLowerCase()) {
          case "items.added":
            stats["Added"] = item.Value;
            break;
          case "items.changed":
            stats["Changed"] = item.Value;
            break;
          case "items.refreshed":
            stats["Unchanged"] = item.Value;
            break;
          case "items.deleted":
          case "items.removed":
            stats["Removed"] = item.Value;
            break;

          case "files.totaldownlods":
            stats["Downloads"] = item.Value;
            break;
          case "downloaded.images":
            stats["Images"] = isNaN(parseInt(item.Value))
              ? 0
              : parseInt(item.Value);
            break;
          case "downloaded.files":
            stats["Files"] = isNaN(parseInt(item.Value))
              ? 0
              : parseInt(item.Value);
            break;
          case "files.added":
            stats["New"] = isNaN(parseInt(item.Value))
              ? 0
              : parseInt(item.Value);
            break;
          case "files.duplicates":
            stats["Duplicates"] = item.Value;
            break;
          default:
            break;
        }
      }

      if (stats["Images"] && stats["Files"]) {
        const total = stats["Images"] + stats["Files"];

        if (isNaN(stats["Downloads"])) stats["Downloads"] = total;

        if (isNaN(stats["Duplicates"]) && stats["New"])
          stats["Duplicates"] = total - stats["New"];
      }
    }

    yield put({
      type: SET_AGENTGROUPS_SUMMARY,
      payload: {
        stats,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

export default function* agentGroupsSummaryWatcher() {
  yield takeLatest(GET_AGENTGROUPS_SUMMARY, fetchAgentGroupsSummary);
}
