const Ext = window["Ext"];

Ext.define(null, {
  override: "Ext.grid.PagingToolbar",

  beforePageText: "Page",

  afterPageText: "of {0}",

  firstText: "First Page",

  prevText: "Previous Page",

  nextText: "Next Page",

  lastText: "Last Page",

  /*
   * @cfg {Boolean} displayInfo
   * True to display the displayMsg
   */
  displayInfo: true,

  /*
   * @cfg {String} displayMsg
   * The paging status message to display. Note that this string is
   * formatted using the braced numbers {0}-{2} as tokens that are replaced by the values
   * for start, end and total respectively. These tokens should be preserved when overriding
   * this string if showing those values is desired.
   * @locale
   */
  displayMsg: "{0} - {1} of {2} total",

  config: {
    firstButton: {
      xtype: "button",
      iconCls: Ext.baseCSSPrefix + "pagingtoolbar-first",
      tooltip: this.beforePageText,
    },

    lastButton: {
      xtype: "button",
      iconCls: Ext.baseCSSPrefix + "pagingtoolbar-last",
    },

    pageCount: {
      xtype: "displayfield",
    },

    infoComponent: {
      xtype: "displayfield",
    },

    inputItem: {
      xtype: "numberfield",
      width: 90,
      cls: "paging-toolbar-page-input",
      labelWidth: 50,
      labelAlign: "left",
      allowDecimals: false,
      focusable: "true",
      keyMapEnabled: true,
      minValue: 1,
      hideTrigger: false,
      keyNavEnabled: false,
      clearable: false,
      selectOnFocus: true,
      submitValue: false,
      isFormField: false,
    },
  },

  initialize: function () {
    var me = this;

    const pageSizeSelector = Ext.create("Ext.field.ComboBox", {
      itemId: "pageSizeSelector",
      queryMode: "local",
      displayField: "name",
      cls: "paging-toolbar-page-size-select",
      valueField: "value",
      editable: false,
      allowBlank: false,
      triggerAction: "all",
      width: 65,
      store: [
        {
          name: 10,
          value: 10,
        },
        {
          name: 25,
          value: 25,
        },
        {
          name: 50,
          value: 50,
        },
        {
          name: 100,
          value: 100,
        },
        {
          name: 250,
          value: 250,
        },
        {
          name: 500,
          value: 500,
        },
      ],
      value: 10,
    });

    me.callSuper();

    me.add([
      {
        xtype: "label",
        html: "Rows per page",
      },
      {
        xtype: "spacer",
        width: 14,
      },
      pageSizeSelector,
      "->",
      me.getFirstButton(),
      me.getPrevButton(),
      me.getInputItem(),
      me.getPageCount(),
      me.getNextButton(),
      me.getLastButton(),
    ]);

    if (me.displayInfo) {
      me.add(["->", me.getInfoComponent()]);
    }

    me.on("destroy", () => {
      pageSizeSelector.destroy();
    });
  },

  applyFirstButton: function (config, cmp) {
    return Ext.Factory.widget.update(
      cmp,
      Ext.applyIf(config, {
        tooltip: this.firstText,
      })
    );
  },

  applyPrevButton: function (config, cmp) {
    return Ext.Factory.widget.update(
      cmp,
      Ext.applyIf(config, {
        tooltip: this.prevText,
      })
    );
  },

  applyNextButton: function (config, cmp) {
    return Ext.Factory.widget.update(
      cmp,
      Ext.applyIf(config, {
        tooltip: this.nextText,
      })
    );
  },

  applyLastButton: function (config, cmp) {
    return Ext.Factory.widget.update(
      cmp,
      Ext.applyIf(config, {
        tooltip: this.lastText,
      })
    );
  },

  applyInputItem: function (config, cmp) {
    return Ext.Factory.widget.update(
      cmp,
      Ext.applyIf(config, {
        label: this.beforePageText,
      })
    );
  },

  applyPageCount: function (config, cmp) {
    const me = this;

    return Ext.Factory.widget.update(
      cmp,
      Ext.applyIf(config, {
        renderer: function (v) {
          return Ext.String.format(me.afterPageText, v);
        },
      })
    );
  },

  applyInfoComponent: function (config, cmp) {
    return Ext.Factory.widget.update(cmp, config);
  },
});

Ext.define(null, {
  override: "Ext.grid.plugin.PagingToolbar",

  updateToolbar: function (toolbar) {
    var me = this;

    if (toolbar) {
      const pageSizeSelector = toolbar.getComponent("pageSizeSelector");
      pageSizeSelector.on("change", (x, value) => {
        const grid = me.getGrid();
        const store = grid.getStore();

        store.setPageSize(value);
        store.loadPage(1);
      });

      toolbar.getNextButton().on({
        tap: "onNextPageTap",
        scope: me,
      });

      toolbar.getPrevButton().on({
        tap: "onPreviousPageTap",
        scope: me,
      });

      toolbar.getFirstButton().on({
        tap: "onFirstPageTap",
        scope: me,
      });

      toolbar.getLastButton().on({
        tap: "onLastPageTap",
        scope: me,
      });

      toolbar.getInputItem().on({
        specialkey: "processKeyEvent",
        blur: "onPagingBlur",
        scope: me,
      });
    }
  },

  onFirstPageTap: function () {
    this.setCurrentPage(1);

    const me = this,
      grid = me.getGrid();
    if (grid.config.plugins.pagingtoolbar.onPageChangeCallback)
      grid.config.plugins.pagingtoolbar.onPageChangeCallback(1);
  },

  onLastPageTap: function () {
    this.setCurrentPage(this.getTotalPages());
    const me = this,
      grid = me.getGrid();
    if (grid.config.plugins.pagingtoolbar.onPageChangeCallback)
      grid.config.plugins.pagingtoolbar.onPageChangeCallback(
        this.getTotalPages()
      );
  },

  /**
   * @private
   */
  onPagingBlur: function () {
    var inputItem = this.getToolbar().getInputItem();

    if (inputItem) {
      inputItem.setValue(
        this.getTotalPages() === 0 ? 0 : this.getCurrentPage()
      );
    }
  },

  processKeyEvent: function (field, e) {
    var me = this,
      grid = me.getGrid(),
      key = e.getKey(),
      currentPage = me.getCurrentPage(),
      inputItem = me.getToolbar().getInputItem(),
      pageData = me.getPageData(),
      increment = e.shiftKey ? 10 : 1,
      pageNum = me.getCurrentPage();

    if (key === e.RETURN) {
      e.stopEvent();
      pageNum = me.readPageFromInput();

      if (pageNum !== false) {
        pageNum = Math.min(Math.max(1, pageNum), me.getTotalPages());

        if (pageNum !== currentPage) {
          me.setCurrentPage(pageNum);
          if (grid.config.plugins.pagingtoolbar.onPageChangeCallback)
            grid.config.plugins.pagingtoolbar.onPageChangeCallback(pageNum);
        }
      }
    } else if (key === e.HOME || key === e.END) {
      e.stopEvent();
      pageNum = key === e.HOME ? 1 : pageData.pageCount;
      field.setValue(pageNum);
    } else if (
      key === e.UP ||
      key === e.PAGE_UP ||
      key === e.DOWN ||
      key === e.PAGE_DOWN
    ) {
      e.stopEvent();
      pageNum = me.readPageFromInput();
      if (!pageNum) {
        inputItem.forceInputChange = true;
        inputItem.setValue(currentPage);
        inputItem.forceInputChange = false;
        pageNum = currentPage;
      }

      if (pageNum) {
        if (key === e.DOWN || key === e.PAGE_DOWN) {
          increment *= -1;
        }

        pageNum += increment;

        if (pageNum >= 1 && pageNum <= pageData.totalPages) {
          me.setCurrentPage(pageNum);
          if (grid.config.plugins.pagingtoolbar.onPageChangeCallback)
            grid.config.plugins.pagingtoolbar.onPageChangeCallback(pageNum);
        }
      }
    }
  },

  /**
   * @private
   */
  readPageFromInput: function () {
    var me = this,
      inputItem = this.getToolbar().getInputItem(),
      pageNum = false,
      v;

    if (inputItem) {
      v = inputItem.getValue();
      pageNum = parseInt(v, 10);

      if (!v || isNaN(pageNum)) {
        inputItem.setValue(me.getCurrentPage());

        return false;
      }
    }

    return pageNum;
  },

  privates: {
    syncSummary: function () {
      var me = this,
        grid = me.getGrid(),
        currentPage = me.getCurrentPage(),
        pageSize = me.getPageSize(),
        totalCount = me.getTotalCount(),
        totalPages = me.getTotalPages(),
        toolbar = me.getToolbar(),
        inputItem = toolbar.getInputItem(),
        toRecord = Math.min(currentPage * pageSize, totalCount),
        fromRecord = (currentPage - 1) * pageSize + 1,
        atStart = currentPage === 1,
        atEnd = currentPage === totalPages,
        pageTopRecord,
        pageSizeSelector = toolbar.getComponent("pageSizeSelector");

      if (me.bulkConfigs) {
        return;
      }

      pageTopRecord = me.getPageTopRecord(currentPage);

      if (inputItem) {
        if (toRecord === 0 && totalCount === 0) currentPage = 0;
        inputItem.forceInputChange = true;
        inputItem.setValue(currentPage);

        inputItem.forceInputChange = false;
        if (grid.config.plugins.pagingtoolbar.onPageChangeCallback)
          grid.config.plugins.pagingtoolbar.onPageChangeCallback(currentPage);
      }

      if (grid && !me.preventGridScroll && pageTopRecord) {
        grid.scrollToRecord(pageTopRecord);
      }

      if (currentPage === 0) {
        atEnd = true;
        atStart = true;
      }
      toolbar.getNextButton().setDisabled(atEnd);
      toolbar.getLastButton().setDisabled(atEnd);
      toolbar.getPrevButton().setDisabled(atStart);
      toolbar.getFirstButton().setDisabled(atStart);
      toolbar.getPageCount().setValue(totalPages);

      pageSizeSelector.setValue(pageSize);

      if (toolbar.displayInfo) {
        if (toRecord === 0 && totalCount === 0) fromRecord = 0;

        toolbar
          .getInfoComponent()
          .setValue(
            Ext.String.format(
              toolbar.displayMsg,
              fromRecord,
              toRecord,
              totalCount
            )
          );
      }
    },
  },
});
