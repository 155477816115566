import React from "react";
import { Link } from "@reach/router";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  link: {
    display: "flex",
    alignItems: "center",

    "& span": {
      textOverflow: "ellipsis",

      whiteSpace: "nowrap",
      alignItems: "right",
    },
  },
  horizontalAlign: {
    textAlign: "center",
    display: "block",
  },
});

export default function LinkWithIcon({ path, icon, text }) {
  const classes = useStyles();

  return (
    <Link to={path} className={classes.link}>
      <Box
        component="span"
        width="60px"
        mr={1}
        display="flex"
        alignItems="center"
        className={classes.horizontalAlign}
      >
        {icon}
      </Box>
      <Box component="span">{text}</Box>
    </Link>
  );
}

LinkWithIcon.propTypes = {
  icon: PropTypes.object,
  path: PropTypes.string.isRequired,
  text: PropTypes.string,
};
