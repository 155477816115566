import {
  SET_NEW_VIEW_FIELDS,
  SET_NEW_VIEW_FILTERS,
  SET_SHARE_KEY,
} from "../types";
import createReducer from "../createReducer";

const initialState = {
  rssFeedFieldsStore: null,
  rssFeedFieldsColumns: [],
  rssFeedSelectInputs: {
    items: [],
    publishDate: {
      name: "",
      value: null,
    },
    websiteUrl: {
      name: "",
      value: null,
    },
    title: {
      name: "",
      value: null,
    },
  },

  filterFieldOperators: null,
  inRssStoreData: [],
};

export const views = createReducer(initialState, {
  [SET_NEW_VIEW_FIELDS](state, { payload }) {
    return {
      ...state,
      rssFeedFieldsStore: payload.store,
      rssFeedFieldsColumns: payload.columns,
      rssFeedSelectInputs: {
        items: payload.selectInputsData.items,
        publishDate: payload.selectInputsData.rssPublished,
        websiteUrl: payload.selectInputsData.rssLink,
        title: payload.selectInputsData.rssTitle,
      },
      publisher: payload.publisher,
      inRssStoreData: payload.inRssStoreData,
    };
  },

  [SET_NEW_VIEW_FILTERS](state, { payload }) {
    return {
      ...state,
      filterFieldOperators: payload.fieldOperators,
    };
  },
  [SET_SHARE_KEY](state, { payload }) {
    return {
      ...state,
      shareKey: payload,
    };
  },
});
