import React from "react";
import { Link } from "@reach/router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import variables from "../../../assets/sass/_variables.scss";

const _generateIcon = ({ TYPE, ICON, DESCRIPTION, COLOR, visible }) => {
  if (TYPE === "fa") {
    return (
      <FontAwesomeIcon
        size="lg"
        icon={ICON}
        title={visible !== false ? DESCRIPTION : ""}
        opacity={visible === false ? 0 : 1}
        key={`grid-render-icon-${DESCRIPTION || TYPE}`}
        color={COLOR || variables["color-spacecadet"]}
      />
    );
  }

  return (
    <ICON
      key={`grid-render-icon-${DESCRIPTION || TYPE}`}
      title={visible !== false ? DESCRIPTION : ""}
      opacity={visible === false ? 0 : 1}
      width={variables["size-lg"]}
      fill={COLOR || variables["color-spacecadet"]}
    />
  );
};

export const renderDepartmentNameCell = (mapper, value, record) => {
  return <Link to={`/departments/${record.data.AccountKey}`}>{value}</Link>;
};

export const renderConfigCell = (mapper, value, record) => {
  if (value && value.length > 0) {
    const data = mapper(value, record);
    return _generateIcon(data);
  }
  return null;
};
