import {
  GET_DEPARTMENT_JOBS,
  SET_DEPARTMENT_JOBS,
  GET_DEPARTMENT_JOB_DETAILS,
  SET_DEPARTMENT_JOB_DETAILS,
  GET_DEPARTMENT_JOB_LIST,
  SET_DEPARTMENT_JOB_LIST,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import { GROUP_COLLECTIONS } from "../../constants/collections";
import { takeLatest, put, call, select, cancel } from "redux-saga/effects";
import axios from "axios";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";

const Ext = window["Ext"];

function prepareModel(data, store) {
  const model = store.config.model;

  model.setFields(data);

  return model;
}

function prepareGridColumns(data) {
  return data.ViewFields.map((field) => ({
    dataIndex: field.Name,
    text: `<span title='${field.Name}'>${field.Name}</span>`,
    locked: field.IsSticky,
    hidden: field.IsHidden,
    width: field.ColumnWidth,
    cell: {
      height: 50,
    },
  }));
}

function* createStore(storeId, data, filteredData = {}) {
  const store = Ext.getStore(storeId);
  if (store) {
    const model = prepareModel(data, store);

    const { SearchText, SearchField } = data.CollectionState;

    store.removeAll(true);
    store.setModel(model);

    store.baseParams = {
      Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
      CollectionID: CONSTANTS.COLLECTIONS.GROUP.JOBS,
      ViewID: data.ViewRow.ViewID,
      AgentID: data.AgentID,
      SearchText,
      SearchField,
      ...filteredData,
    };
    store.getProxy().extraParams = store.baseParams;

    store.loadPage(1, {
      params: {
        Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
        CollectionID: CONSTANTS.COLLECTIONS.GROUP.JOBS,
        ViewID: data.ViewRow.ViewID,
        AgentID: data.AgentID,
        SearchText,
        SearchField,
        ...filteredData,
      },
    });
  }

  const columns = prepareGridColumns(data);
  yield put({
    type: SET_DEPARTMENT_JOBS,
    payload: {
      columns,
      sortedBy: data.ViewSorting,
      viewRow: data.ViewRow,
      collectionState: data.CollectionState,
      viewFields: data.ViewFields,
      refreshed: data.Collection.Refreshed,
    },
  });
}

function* fetchDepartmentJobs({ type: action, storeId, formParams = {} }) {
  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETCOMPLETE,
    CollectionID: CONSTANTS.COLLECTIONS.GROUP.JOBS,
    ...formParams,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    yield call(createStore, storeId, data);

    const columns = prepareGridColumns(data);
    yield put({
      type: SET_DEPARTMENT_JOBS,
      payload: {
        columns,
        sortedBy: data.ViewSorting,
        viewRow: data.ViewRow,
        collectionState: data.CollectionState,
        viewFields: data.ViewFields,
        refreshed: data.Collection.Refreshed,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

const reducer = (accumulator, value) => {
  const groupIndex = accumulator.findIndex(
    (item) => item.Group === value.Group
  );

  if (groupIndex !== -1) {
    accumulator[groupIndex].items.push(value);
  } else {
    accumulator.push({
      Group: value.Group,
      items: [value],
    });
  }
  return accumulator;
};

function* fetchJobDetails({ type: action, formParams = {} }) {
  const params = {
    Command: CONSTANTS.COMMANDS.JOB_GETDETAILS,
    ...formParams,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.JOB_GETDETAILS,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    const reducedData = data.Rows.reduce(reducer, []);
    let transformedData = JSON.parse(
      JSON.stringify(reducedData).replace("DetailName", "Name")
    );

    yield put({
      type: SET_DEPARTMENT_JOB_DETAILS,
      payload: {
        data: transformedData,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* fetchDepartmentJobsList({ type: action, AccountKey }) {
  const reduxState = yield select();
  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETVIEWS,
    AccountKey: reduxState.account.accountInfo.Account.AccountKey,
    CollectionId: GROUP_COLLECTIONS.JOBS,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETVIEWS,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    yield put({
      type: SET_DEPARTMENT_JOB_LIST,
      payload: {
        data,
        AccountKey,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

export default function* departmentJobsWatcher() {
  yield takeLatest(GET_DEPARTMENT_JOBS, fetchDepartmentJobs);
  yield takeLatest(GET_DEPARTMENT_JOB_DETAILS, fetchJobDetails);
  yield takeLatest(GET_DEPARTMENT_JOB_LIST, fetchDepartmentJobsList);
}
