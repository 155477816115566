const Ext = window["Ext"];

Ext.define(null, {
  override: "Ext.grid.selection.Model",
  privates: {
    toggleAll: function (header, e) {
      var me = this,
        view = me.getView(),
        sel = me.getSelection();

      e.stopEvent();

      // Not all selected, select all
      if (!sel || !sel.isAllSelected()) {
        view.fireEvent("headerselect", view);
        me.selectAll(true);
      } else {
        view.fireEvent("headerdeselect", view);
        me.deselectAll(true);
      }

      me.updateHeaderState();
      me.lastColumnSelected = null;
    },
    onNavigate: function (navigateEvent) {
      var me = this,
        view = me.getView(),
        store = me.getStore(),
        selectingRows = me.getRows(),
        selectingCells = me.getCells(),
        selectingColumns = me.getColumns(),
        checkbox = me.getCheckbox(),
        checkboxOnly = me.checkboxOnly,
        mode = me.getMode(),
        location = navigateEvent.to,
        toColumn = location.column,
        record = location.record,
        sel = me.getSelection(),
        ctrlKey = navigateEvent.ctrlKey,
        shiftKey = navigateEvent.shiftKey,
        adding = true,
        isSpace = navigateEvent.getKey() === navigateEvent.SPACE,
        count,
        changedRow,
        selectionChanged,
        selected,
        continueLocation;

      if (navigateEvent.stopSelection || toColumn === me.checkboxColumn) {
        return;
      }

      if (!navigateEvent.pointerType && !isSpace) {
        if (ctrlKey) {
          return;
        }

        changedRow =
          !navigateEvent.from ||
          location.recordIndex !== navigateEvent.from.recordIndex;
        if (!changedRow && !(selectingCells || selectingColumns)) {
          return;
        }
      }

      if (
        sel &&
        (sel.isCells ||
          (sel.isColumns && selectingRows && !(ctrlKey || shiftKey))) &&
        sel.getCount() > 1 &&
        !shiftKey &&
        navigateEvent.type === "click"
      ) {
        return;
      }

      if (
        !(selectingCells || selectingColumns || selectingRows) ||
        !record ||
        navigateEvent.type === "mousedown"
      ) {
        return;
      }

      if (
        ctrlKey &&
        navigateEvent.keyCode === navigateEvent.A &&
        mode === "multi"
      ) {
        if (!sel || sel.getCount() < 2) {
          me.selectAll();
        } else {
          me.deselectAll();
        }
        me.updateHeaderState();
        return;
      }

      if (shiftKey && mode === "multi") {
        if (
          toColumn === me.numbererColumn ||
          toColumn === me.checkColumn ||
          !(selectingCells || selectingColumns) ||
          (sel && (sel.isRows || sel.isRecords))
        ) {
          if (selectingRows) {
            if (toColumn !== checkbox && checkboxOnly) {
              return;
            }

            sel = me.getSelection("records");

            if (!sel.getRangeSize()) {
              if (me.selectionStart == null) {
                me.selectionStart = location.recordIndex;
              }
              sel.setRangeStart(me.selectionStart);
            } else {
              continueLocation = new Ext.grid.Location(
                view,
                me.getLastSelected()
              );
              sel.setRangeStart(continueLocation.recordIndex);
            }

            sel.setRangeEnd(location.recordIndex);
            selectionChanged = true;
          }
        } else {
          if (selectingCells) {
            sel = me.getSelection("cells");
            count = sel.getCount();

            if (!sel.getRangeSize()) {
              sel.setRangeStart(
                navigateEvent.from ||
                  new Ext.grid.Location(me.getView(), {
                    record: 0,
                    column: 0,
                  })
              );
            }
            sel.setRangeEnd(location);
            adding = count < sel.getCount();
            selectionChanged = true;
          } else if (selectingColumns) {
            sel = me.getSelection("columns");
            if (!sel.getCount()) {
              sel.setRangeStart(toColumn);
            }
            sel.setRangeEnd(toColumn);
            selectionChanged = true;
          }
        }
      } else {
        me.selectionStart = null;
        // if (sel && (mode !== "multi" || !ctrlKey) && !isSpace) {
        //   sel.clear(true);
        // }

        if (
          selectingRows &&
          (toColumn === me.numbererColumn ||
            toColumn === checkbox ||
            !selectingCells)
        ) {
          if (
            (toColumn !== checkbox && checkboxOnly) ||
            (navigateEvent.keyCode &&
              navigateEvent.from &&
              record === navigateEvent.from.record)
          ) {
            return;
          }

          sel = me.getSelection("records");
          if (sel.isSelected(record)) {
            if (ctrlKey || toColumn === checkbox || me.getDeselectable()) {
              sel.remove(record);
              selectionChanged = true;
            }
          } else {
            sel.add(record, ctrlKey || toColumn === checkbox);
            selectionChanged = true;
          }
          if (
            selectionChanged &&
            (selected = sel.getSelected()) &&
            selected.length
          ) {
            me.selectionStart = store.indexOf(selected.first());
            sel.setRangeStart(me.selectionStart);
          }
        } else {
          if (selectingCells) {
            sel = me.getSelection("cells", true);
            sel.setRangeStart(location);
            selectionChanged = true;
          } else if (selectingColumns) {
            sel = me.getSelection("columns");
            if (ctrlKey) {
              if (sel.isSelected(toColumn)) {
                sel.remove(toColumn);
              } else {
                sel.add(toColumn);
              }
            } else {
              sel.setRangeStart(toColumn);
            }
            selectionChanged = true;
          }
        }
      }

      if (selectionChanged) {
        if (!sel.isRecords) {
          me.fireSelectionChange(null, adding);
        }
      }
      me.lastDragLocation = location;
    },
  },
});
