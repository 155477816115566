import React from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import theme from "./AppTheme";
import CssBaseline from "@material-ui/core/CssBaseline";
import ExtReactDOM from "@sencha/ext-react-modern";
import App from "./App";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { fad } from "@fortawesome/pro-duotone-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import "./Overrides/axios.js";
import RequestInterceptor from "./Overrides/axios.js";

import { Provider } from "react-redux";
import storeConfig from "./store";

const store = storeConfig();
RequestInterceptor.interceptor(store);

library.add(fas, far, fal, fad, fab);

if (process.env.REACT_APP_ENV === "mock") {
  require("./Data/Mocks/mockSetup");
}

ExtReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </MuiThemeProvider>
  </Provider>,
  document.getElementById("root")
);
