import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import variables from "../../../assets/sass/_variables.scss";
import PropTypes from "prop-types";

const StyledTextField = withStyles({
  root: {
    width: (props) => props.width || 250,
    "& .MuiInputBase-root": {
      color: variables["color-spacecadet-70"],
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
    "& textarea": {
      fontFamily: variables["fontfamily-default"],
      color: variables["color-spacecadet-70"],
    },
    "& .MuiFormHelperText-root": {
      fontSize: ".75rem",
      color: variables["color-danger"],
      fontFamily: variables["fontfamily-default"],
    },
  },
})(TextField);

const useStyles = makeStyles({
  root: {
    backgroundColor: variables["color-lightgray-15"],
    color: variables["color-spacecadet-70"],
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&.MuiFormLabel-root": {
      color: (props) => props.color || variables["color-midgray"],
    },
    "&$disabled": {
      color: variables["color-spacecadet-50"],
      borderColor: variables["color-lightgray-15"],
      backgroundColor: variables["color-lightgray-50"],
      fontSize: "1rem",
    },
    "&$error": {
      "&.MuiFormLabel-root": { color: "red" },
      "&$underline": {
        "&:after": {
          borderBottomColor: variables["color-danger"],
        },
      },
    },
  },
  error: {},
  disabled: {},
  focused: {
    backgroundColor: "transparent",
    "&.MuiFormLabel-root": {
      color: (props) => props.color || variables["color-midgray"],
    },
    "&$underline": {
      "&:after": {
        display: "none",
      },
    },
  },
  underline: {},
});

export default function TextFieldMinimal(props) {
  const classes = useStyles(props);
  const rows =
    props.multiline && props.rows ? props.rows : props.multiline ? 2 : 1;

  return (
    <StyledTextField
      defaultValue={props.defaultValue}
      InputProps={{
        classes: {
          root: classes.root,
          focused: classes.focused,
          underline: classes.underline,
          disabled: classes.disabled,
          error: classes.error,
        },
        disableUnderline: false,
      }}
      InputLabelProps={{
        classes: {
          root: classes.root,
          focused: classes.focused,
          disabled: classes.disabled,
          error: classes.error,
        },
        shrink: true,
      }}
      placeholder={props.placeholder}
      variant="filled"
      value={props.value}
      onChange={props.onChange}
      rows={rows}
      multiline={props.multiline}
      rowsMax={rows}
      label={props.label}
      error={props.error}
      helperText={props.helperText}
      disabled={props.disabled}
      width={props.width}
      name={props.name}
      type={props.type}
      required={props.required || false}
      autoFocus={props.autoFocus}
      onKeyUp={props.onKeyUp}
    />
  );
}

TextFieldMinimal.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  multiline: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
  name: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
};
