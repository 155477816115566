import * as types from "../types";
import createReducer from "../createReducer";

const initialState = {
  folderInfo: {},
  folderItems: [],
  foldersListColumns: [],
  foldersListColumnsModal: [],
  folderAgentsListColumns: [],
  folderAgentsSortedBy: [],
  folderAgentsViewRow: {},
  folderCollectionsListColumns: [],
  folderCollectionsSortedBy: [],
  folderCollectionsViewRow: {},
  folderAgentsCollectionState: {},
  folderAgentsViewFields: null,
  folderCollectionsCollectionState: {},
  folderCollectionsViewFields: null,
  currentFolder: null,
  allFolders: null,
};

export const folders = createReducer(initialState, {
  [types.SET_FOLDER](state, { payload }) {
    return {
      ...state,
      folderInfo: payload,
    };
  },
  [types.SET_FOLDERITEMS](state, { payload }) {
    return {
      ...state,
      folderItems: payload,
    };
  },
  [types.SET_FOLDERS](state, { payload }) {
    return {
      ...state,
      foldersListColumns: payload.columns,
    };
  },
  [types.SET_FOLDERS_MODAL](state, { payload }) {
    return {
      ...state,
      foldersListColumnsModal: payload.columns,
    };
  },
  [types.FILTER_FOLDERS](state, { filter }) {
    state.foldersListStore.filter("Name", filter);
    return {
      ...state,
    };
  },
  [types.FILTER_FOLDER_AGENTS](state, { filter }) {
    state.folderAgentsListStore.filter("Name", filter);
    return {
      ...state,
    };
  },
  [types.SET_CURRENT_FOLDER](state, payload) {
    return {
      ...state,
      currentFolder: payload.currentFolder,
    };
  },
  [types.SET_VIEWS_FOLDER](state, payload) {
    return {
      ...state,
      viewsFolder: payload.viewsFolder,
    };
  },

  [types.FILTER_FOLDER_COLLECTIONS](state, { filter }) {
    state.folderCollectionsListStore.filter("Name", filter);
    return {
      ...state,
    };
  },
  [types.SET_FOLDER_AGENTS](state, { payload }) {
    return {
      ...state,
      folderAgentsListColumns: payload.columns,
      folderAgentsSortedBy: payload.sortedBy,
      folderAgentsViewRow: payload.viewRow,
      folderAgentsCollectionState: payload.collectionState,
      folderAgentsViewFields: payload.viewFields.map((viewField) => {
        return {
          name: viewField.Name,
          value: viewField.Name,
          fieldId: viewField.FieldID,
        };
      }),
    };
  },
  [types.SET_FOLDER_COLLECTIONS](state, { payload }) {
    return {
      ...state,
      folderCollectionsListColumns: payload.columns,
      folderCollectionsSortedBy: payload.sortedBy,
      folderCollectionsViewRow: payload.viewRow,
      folderCollectionsCollectionState: payload.collectionState,
      folderCollectionsViewFields: payload.viewFields.map((viewField) => {
        return {
          name: viewField.Name,
          value: viewField.Name,
          fieldId: viewField.FieldID,
        };
      }),
    };
  },

  [types.UPDATE_FOLDER_AGENTS_STATE_SEARCH](state, { searchFilterValues }) {
    return {
      ...state,
      folderAgentsCollectionState: {
        ...state.folderAgentsCollectionState,
        SearchText: searchFilterValues.searchText,
        SearchField:
          searchFilterValues.searchField !== "View Fields"
            ? searchFilterValues.searchField
            : "AllViewFields",
      },
    };
  },
  [types.UPDATE_FOLDER_COLLECTIONS_STATE_SEARCH](
    state,
    { searchFilterValues }
  ) {
    return {
      ...state,
      folderCollectionsCollectionState: {
        ...state.folderCollectionsCollectionState,
        SearchText: searchFilterValues.searchText,
        SearchField:
          searchFilterValues.searchField !== "View Fields"
            ? searchFilterValues.searchField
            : "AllViewFields",
      },
    };
  },
  [types.SET_ALL_FOLDERS](state, { payload }) {
    return {
      ...state,
      allFolders: payload,
    };
  },
});
