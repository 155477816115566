import React, { useMemo } from "react";

const authMatrixBuilder = (account, user) => {
  const userSettings =
    user?.User?.Settings?.split(",")?.map(function (item) {
      return item.trim();
    }) || [];
  const accountPermissions =
    user?.User?.AccountPermissions?.split(",")?.map(function (item) {
      return item.trim();
    }) || [];
  const accountFeatures =
    account?.Account?.Features?.split(",")?.map(function (item) {
      return item.trim();
    }) || [];

  const authorizations = {};

  if (
    (userSettings.includes("AccountAdministrator") ||
      userSettings.includes("SystemAdministrator") ||
      accountPermissions.includes("Run") ||
      accountPermissions.includes("Configure") ||
      accountPermissions.includes("AgentBuild")) &&
    accountFeatures.includes("AgentGroups")
  ) {
    authorizations.NAVBAR_AGENTS = true;
  } else {
    authorizations.NAVBAR_AGENTS = false;
  }

  if (
    userSettings.includes("AccountAdministrator") ||
    userSettings.includes("SystemAdministrator") ||
    accountPermissions.includes("View") ||
    accountPermissions.includes("CollectionBuild") ||
    accountPermissions.includes("Clear")
  ) {
    authorizations.NAVBAR_COLLECTIONS = true;
  } else {
    authorizations.NAVBAR_COLLECTIONS = false;
  }

  if (
    (userSettings.includes("AccountAdministrator") ||
      userSettings.includes("SystemAdministrator") ||
      accountPermissions.includes("Build")) &&
    accountFeatures.includes("Sequences")
  ) {
    authorizations.NAVBAR_SEQUENCES = true;
  } else {
    authorizations.NAVBAR_SEQUENCES = false;
  }

  if (
    (userSettings.includes("AccountAdministrator") ||
      userSettings.includes("SystemAdministrator") ||
      accountPermissions.includes("Run") ||
      accountPermissions.includes("Configure") ||
      accountPermissions.includes("AgentBuild") ||
      accountPermissions.includes("View") ||
      accountPermissions.includes("CollectionBuild")) &&
    accountFeatures.includes("Folders")
  ) {
    authorizations.NAVBAR_FOLDERS = true;
  } else {
    authorizations.NAVBAR_FOLDERS = false;
  }

  if (
    (userSettings.includes("AccountAdministrator") ||
      accountPermissions.includes("Billing")) &&
    accountFeatures.includes("Master")
  ) {
    authorizations.NAVBAR_DEPARTMENTS = true;
  } else {
    authorizations.NAVBAR_DEPARTMENTS = false;
  }

  return authorizations;
};

export function useAuthTable(account, user) {
  const authTable = useMemo(() => authMatrixBuilder(account, user), [
    account,
    user,
  ]);

  return authTable;
}

const AuthorizationContext = React.createContext();

export default AuthorizationContext;
