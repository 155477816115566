import {
  GET_SEQUENCES_LIST,
  SET_SEQUENCES_LIST,
  GET_SEQUENCES_VIEWS,
  SET_SEQUENCES_VIEWS,
  SEQUENCE_GET,
  SET_SEQUENCE_INFO,
  SEQUENCE_UPDATE,
  GET_SEQUENCE_ERROR_CODES,
  SET_SEQUENCE_ERROR_CODES,
  SEQUENCE_UPDATE_ADD_STEP,
  SET_SEQUENCE_AGENT_INFO,
  GET_SEQUENCE_AGENT_INFO,
  GET_SEQUENCE_CLEAR_COLLECTION,
  SET_SEQUENCE_CLEAR_COLLECTION,
  GET_SEQUENCE_COLLECTION_VIEWS,
  SET_SEQUENCE_COLLECTIONS_SAVED_VIEWS,
  GET_SEQUENCE_COLLECTION_INFO,
  SET_SEQUENCE_COLLECTION_INFO,
  SEQUENCE_UPDATE_ADD_STEP_CLEAR_COLLECTION,
  GET_SEQUENCE_STATUS,
  SET_SEQUENCE_STATUS,
  RUN_SEQUENCE,
  SEQUENCE_RUN_ERROR,
  PAUSE_SEQUENCE,
  RESUME_SEQUENCE,
  CANCEL_SEQUENCE,
  CREATE_NEW_SEQUENCE,
  SEQUENCE_RUN_LOADING,
  SEQUENCE_RUN_FINISHED_LOADING,
  REMOVE_SEQUENCE_LOADING,
  GET_ALL_SEQUENCE_STEPS,
  ALL_SEQUENCES,
  AGENT_SET_JOB_STATUS_SESSION_EXPIRED,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import { takeLatest, put, call, cancel } from "redux-saga/effects";
import { mapSequencesConfigCell } from "../../Components/Grid/SenchaLockedGrid/mappers";
import {
  renderSequencesNameCell,
  renderConfigCell,
} from "../../Components/Grid/SenchaLockedGrid/renderers";
import axios from "axios";
import { COMMANDS } from "../../constants/commands";
import { SYSTEM_COLLECTIONS } from "../../constants/collections";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";

const Ext = window["Ext"];

function prepareModel(data, store) {
  const model = store.config.model;

  model.setFields(data);

  return model;
}

function prepareGridColumns(data) {
  const renderers = {
    Name: {
      renderer: renderSequencesNameCell,
    },
    Config: {
      mapper: mapSequencesConfigCell,
      renderer: renderConfigCell,
    },
  };

  return data.ViewFields.map((field) => {
    if (renderers[field.Name]) {
      return {
        dataIndex: field.Name,
        text: `<span title='${field.Name}'>${field.Name}</span>`,
        locked: field.IsSticky,
        hidden: field.IsHidden,
        width: field.ColumnWidth,
        cell: {
          height: 50,
          encodeHtml: true,
          xtype: "reactcell",
        },
        renderer: (value, record) =>
          renderers[field.Name].renderer(
            renderers[field.Name].mapper,
            value,
            record
          ),
      };
    }

    return {
      dataIndex: field.Name,
      text: `<span title='${field.Name}'>${field.Name}</span>`,
      locked: field.IsSticky,
      hidden: field.IsHidden,
      width: field.ColumnWidth,
      cell: {
        height: 50,
      },
    };
  });
}

function* createStore(storeId, data, filteredData = {}) {
  const store = Ext.getStore(storeId);
  if (store) {
    const model = prepareModel(data, store);

    const { SearchText, SearchField } = data.CollectionState;

    store.removeAll(true);
    store.setModel(model);
    store.load({
      params: {
        CollectionID: CONSTANTS.COLLECTIONS.GROUP.SEQUENCES,
        ColumnsToAlwaysInclude: "Name, Status",
        ViewID: data.ViewRow.ViewID,
        AgentID: data.AgentID,
        SearchText,
        SearchField,
        ...filteredData,
      },
    });
  }

  const columns = prepareGridColumns(data);

  const fieldsFilterItems = data.ViewFields.map((item) => ({
    name: item.Name,
    value: item.Name,
  }));

  yield put({
    type: SET_SEQUENCES_LIST,
    payload: {
      columns,
      sortedBy: data.ViewSorting,
      fieldsFilterItems,
      viewRow: data.ViewRow,
      collectionState: data.CollectionState,
      viewFields: data.ViewFields,
    },
  });
}

function* fetchSequences({ type: action, storeId, formParams = {} }) {
  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETCOMPLETE,
    CollectionID: CONSTANTS.COLLECTIONS.GROUP.SEQUENCES,
    ...formParams,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    yield call(createStore, storeId, data);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getAllSequences() {
  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETCOMPLETE,
    CollectionID: CONSTANTS.COLLECTIONS.GROUP.SEQUENCES,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams(params)
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    handleJsonResultError(
      data.JsonResult.Result,
      data.JsonResult.ErrorDescription,
      data.JsonResult.ErrorList,
      CONSTANTS.NOTIFICATIONS.SUCCESS
    );

    const sequenceResult = yield axios.post(
      CONSTANTS.ROUTES.VIEW_GETITEMS,
      new URLSearchParams({
        CollectionID: CONSTANTS.COLLECTIONS.GROUP.SEQUENCES,
        ColumnsToAlwaysInclude: "Name, Status",
        ViewID: data.ViewRow.ViewID,
        Command: COMMANDS.VIEW_GETITEMS,
      })
    );

    yield put({
      type: ALL_SEQUENCES,
      payload: sequenceResult.data.Rows,
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* fetchSequenceViews({ type: action }) {
  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETVIEWS,
    CollectionID: CONSTANTS.COLLECTIONS.GROUP.SEQUENCES,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETVIEWS,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    let sequenceListViews = [];

    data.ViewTable.forEach((view) => {
      let savedView = {};
      for (let key in view) {
        if (CONSTANTS.RESPONSE_CONTEXT.SAVED_VIEWS_CONTEXT.includes(key)) {
          savedView[key] = view[key];
        }
      }
      sequenceListViews.push(savedView);
    });

    yield put({
      type: SET_SEQUENCES_VIEWS,
      payload: {
        sequenceListViews,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getSequence({ type: action, SequenceID }) {
  const params = {
    Command: CONSTANTS.COMMANDS.SEQUENCE_GET,
    SequenceID,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.SEQUENCE_GET,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    yield put({
      type: SET_SEQUENCE_INFO,
      payload: {
        info: data.Sequence || {},
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* updateSequence({ type: action, formParams = {} }) {
  const params = {
    Command: CONSTANTS.COMMANDS.SEQUENCE_UPDATE,
    ...formParams,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.SEQUENCE_UPDATE,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function getSequenceErrorCodesColumns() {
  return [
    {
      dataIndex: "Name",
      text: "Field",
      locked: true,
      menuDisabled: true,
      resizable: true,
      searchable: true,
      width: 220,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "Description",
      text: "Field",
      locked: true,
      menuDisabled: true,
      resizable: true,
      searchable: true,
      width: 220,
      cell: {
        height: 50,
      },
    },
  ];
}

function* getSequenceErrorCodes(action) {
  const { storeId } = action;

  const params = {
    Command: COMMANDS.SEQUENCE_GET_ERROR_CODES,
  };

  let store = window["Ext"].getStore(storeId);

  if (store) {
    store.load({ params });
  }

  const columns = getSequenceErrorCodesColumns();

  yield put({
    type: SET_SEQUENCE_ERROR_CODES,
    columns,
  });
}

function* updateAddStep({ urlParams }) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.SEQUENCE_UPDATE_ADD_STEP,
      new URLSearchParams(urlParams)
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getSequenceAgentInfo({ params }) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.SEQUENCE_GET_AGENT_INFO,
      new URLSearchParams(params)
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    yield put({
      type: SET_SEQUENCE_AGENT_INFO,
      payload: data,
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function prepareSequenceClearCollectionColumns(data) {
  return data.ViewFields.map((field) => {
    return {
      dataIndex: field.Name,
      text: `<span title='${field.Name}'>${field.Name}</span>`,
      locked: field.IsSticky,
      hidden: field.IsHidden,
      width: field.ColumnWidth,
      cell: {
        height: 50,
      },
    };
  });
}

function createSequenceCollectionsStore(storeId, data, filteredData = {}) {
  const store = Ext.getStore(storeId);

  if (store) {
    const model = prepareModel(data, store);

    const { SearchText, SearchField } = data.CollectionState;

    store.removeAll(true);
    store.setModel(model);

    const params = {
      Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
      CollectionID: CONSTANTS.COLLECTIONS.SYSTEM.COLLECTIONS,
      ViewID: data.ViewRow.ViewID,
      AgentID: data.AgentID,
      SearchText,
      SearchField,
      ...filteredData,
    };
    store.baseParams = params;
    store.getProxy().extraParams = store.baseParams;

    store.loadPage(1, {
      params,
    });
  }
}

function* getSelectedFromClearCollection({ storeId }) {
  try {
    const params = {
      CollectionID: SYSTEM_COLLECTIONS.COLLECTIONS,
      FolderID: "",
      AccountKey: "",
      ViewID: "",
      OverrideViewName: "",
      HideSystemViewNames: "",
      Command: COMMANDS.COLLECTION_GETCOMPLETE,
    };

    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams(params)
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    const columns = prepareSequenceClearCollectionColumns(data);

    yield put({
      type: SET_SEQUENCE_CLEAR_COLLECTION,
      payload: {
        columns,
        sortedBy: data.ViewSorting,
        viewRow: data.ViewRow,
        collectionState: data.CollectionState,
        viewFields: data.ViewFields,
        refreshed: data.Collection.Refreshed,
      },
    });

    yield call(createSequenceCollectionsStore, storeId, data);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getSequenceCollectionViews({ type: action }) {
  const params = {
    AccountKey: "",
    CollectionID: SYSTEM_COLLECTIONS.COLLECTIONS,
    Command: CONSTANTS.COMMANDS.COLLECTION_GETVIEWS,
  };

  try {
    const result = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETVIEWS,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );
    if (result.data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        result.data.JsonResult.Result,
        result.data.JsonResult.ErrorDescription,
        result.data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    const savedViews = [];

    result.data.ViewTable.forEach((view) => {
      let savedView = {};
      for (let key in view) {
        if (CONSTANTS.RESPONSE_CONTEXT.SAVED_VIEWS_CONTEXT.includes(key)) {
          savedView[key] = view[key];
        }
      }
      savedViews.push(savedView);
    });

    yield put({
      type: SET_SEQUENCE_COLLECTIONS_SAVED_VIEWS,
      payload: {
        savedViews,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getSequenceCollectionInfo({ itemId }) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GET,
      new URLSearchParams({
        CollectionID: itemId,
        Command: COMMANDS.COLLECTION_GET,
      })
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    yield put({
      type: SET_SEQUENCE_COLLECTION_INFO,
      payload: data,
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* sequenceUpdateAddStepClearCollection({
  clearItems,
  stepItemId,
  sequenceId,
}) {
  const mapSettings = {
    "File packages": "EmptyFilePackages",
    "Images and files": "EmptyFiles",
    "Item history and bookmarks": "EmptyItemHistory",
    "Job history": "EmptyJobHistory",
    Items: "EmptyItems",
  };

  const settings = ["EmptyItemHistory"];
  for (let key in clearItems) {
    if (clearItems[key]) {
      settings.push(mapSettings[key]);
    }
  }

  const params = {
    Command: COMMANDS.SEQUENCE_UPDATE_ADD_STEP,
    StepType: "CollectionClear",
    SequenceID: sequenceId,
    StepItemID: stepItemId,
    Settings: settings.join(","),
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.SEQUENCE_UPDATE_ADD_STEP,
      new URLSearchParams(params)
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getSequenceStatus({ sequenceId }) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.SEQUENCE_GET_STATUS,
      new URLSearchParams({
        SequenceID: sequenceId,
        Command: COMMANDS.SEQUENCE_GET_STATUS,
      })
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield put({
        type: AGENT_SET_JOB_STATUS_SESSION_EXPIRED,
        payload: {
          agentJobStatusSessionExpired: true,
        },
      });
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    yield put({
      type: SET_SEQUENCE_STATUS,
      payload: data,
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}
function* updateSequenceGrid(id) {
  const urlParams = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETCOMPLETE,
    CollectionID: CONSTANTS.COLLECTIONS.GROUP.SEQUENCE_DETAILS,
  };
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams(urlParams)
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    window["Ext"].getStore(CONSTANTS.STORES.IDS.SEQUENCES_STEPS).load({
      params: {
        Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
        CollectionID: CONSTANTS.COLLECTIONS.GROUP.SEQUENCE_DETAILS,
        ViewID: data.ViewRow.ViewID,
        AgentID: data.AgentID,
        ContextRestrictionFieldNames: "SequenceID",
        ContextRestrictionFieldValues: id,
        ColumnsToAlwaysInclude:
          "Settings,ResumeErrorCodes,ResumeErrorCount,ConcurrentJobs,Group,StepType,StepItemID,Step,StepNumberID,AdditionalStepItemID,Limit",
        SearchText: data.CollectionState.SearchText,
        SearchField: data.CollectionState.SearchField,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}
function* runSequence({ id }) {
  try {
    const params = {
      SequenceID: id,
      Command: COMMANDS.SEQUENCE_RUN,
    };
    yield put({
      type: SEQUENCE_RUN_LOADING,
    });
    try {
      const { data } = yield axios.post(
        CONSTANTS.ROUTES.SEQUENCE_RUN,
        new URLSearchParams(params)
      );
      if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
        yield cancel();
      }
      if (
        !handleJsonResultError(
          data.JsonResult.Result,
          data.JsonResult.ErrorDescription,
          data.JsonResult.ErrorList,
          CONSTANTS.NOTIFICATIONS.SUCCESS
        )
      )
        return;

      yield call(updateSequenceGrid, id);
      yield call(removeSequenceLoading);
    } catch (error) {
      yield put({
        type: SEQUENCE_RUN_ERROR,
        payload: error.message,
      });
    }
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* pauseSequence({ id }) {
  try {
    const params = {
      SequenceID: id,
      Command: COMMANDS.SEQUENCE_PAUSE,
    };
    yield put({
      type: SEQUENCE_RUN_LOADING,
    });
    try {
      const { data } = yield axios.post(
        CONSTANTS.ROUTES.SEQUENCE_PAUSE,
        new URLSearchParams(params)
      );
      if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
        yield cancel();
      }
      if (
        !handleJsonResultError(
          data.JsonResult.Result,
          data.JsonResult.ErrorDescription,
          data.JsonResult.ErrorList,
          CONSTANTS.NOTIFICATIONS.SUCCESS
        )
      )
        return;

      yield call(updateSequenceGrid, id);
      yield call(removeSequenceLoading);
    } catch (error) {
      yield put({
        type: SEQUENCE_RUN_ERROR,
        payload: error.message,
      });
    }
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* createNewSequence({ params, history, closeModal }) {
  const { data } = yield axios.post(
    CONSTANTS.ROUTES.SEQUENCE_ADD,
    new URLSearchParams(params)
  );
  if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
    yield cancel();
  }
  closeModal();
  history(`/sequences/${data.SequenceID}`);
}

function* resumeSequence({ id }) {
  try {
    const params = {
      SequenceID: id,
      Command: COMMANDS.SEQUENCE_RESUME,
    };
    yield put({
      type: SEQUENCE_RUN_LOADING,
    });
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.SEQUENCE_RESUME,
      new URLSearchParams(params)
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    yield call(updateSequenceGrid, id);
    yield call(removeSequenceLoading);
  } catch (error) {
    yield put({
      type: SEQUENCE_RUN_ERROR,
      payload: error.message,
    });
  }
}

function* cancelSequence({ id }) {
  try {
    const params = {
      SequenceID: id,
      Command: COMMANDS.SEQUENCE_CANCEL,
    };
    yield put({
      type: SEQUENCE_RUN_LOADING,
    });

    const { data } = yield axios.post(
      CONSTANTS.ROUTES.SEQUENCE_CANCEL,
      new URLSearchParams(params)
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    yield call(updateSequenceGrid, id);

    yield call(removeSequenceLoading);
  } catch (error) {
    yield put({
      type: SEQUENCE_RUN_ERROR,
      payload: error.message,
    });
  }
}

function* removeSequenceLoading() {
  yield put({
    type: SEQUENCE_RUN_FINISHED_LOADING,
  });
}

export default function* sequencesWatcher() {
  yield takeLatest(GET_SEQUENCES_LIST, fetchSequences);
  yield takeLatest(GET_SEQUENCES_VIEWS, fetchSequenceViews);
  yield takeLatest(GET_SEQUENCE_ERROR_CODES, getSequenceErrorCodes);
  yield takeLatest(SEQUENCE_UPDATE_ADD_STEP, updateAddStep);
  yield takeLatest(GET_SEQUENCE_AGENT_INFO, getSequenceAgentInfo);
  yield takeLatest(SEQUENCE_GET, getSequence);
  yield takeLatest(SEQUENCE_UPDATE, updateSequence);
  yield takeLatest(
    GET_SEQUENCE_CLEAR_COLLECTION,
    getSelectedFromClearCollection
  );
  yield takeLatest(GET_SEQUENCE_COLLECTION_VIEWS, getSequenceCollectionViews);
  yield takeLatest(GET_SEQUENCE_COLLECTION_INFO, getSequenceCollectionInfo);
  yield takeLatest(
    SEQUENCE_UPDATE_ADD_STEP_CLEAR_COLLECTION,
    sequenceUpdateAddStepClearCollection
  );
  yield takeLatest(GET_SEQUENCE_STATUS, getSequenceStatus);
  yield takeLatest(RUN_SEQUENCE, runSequence);
  yield takeLatest(PAUSE_SEQUENCE, pauseSequence);
  yield takeLatest(RESUME_SEQUENCE, resumeSequence);
  yield takeLatest(CANCEL_SEQUENCE, cancelSequence);
  yield takeLatest(REMOVE_SEQUENCE_LOADING, removeSequenceLoading);
  yield takeLatest(CREATE_NEW_SEQUENCE, createNewSequence);
  yield takeLatest(GET_ALL_SEQUENCE_STEPS, getAllSequences);
}
