import {
  GET_FOLDERS,
  SET_FOLDERS,
  GET_CURRENT_FOLDER,
  SET_CURRENT_FOLDER,
  GET_VIEWS_FOLDER,
  SET_VIEWS_FOLDER,
  ADD_FOLDER,
  DELETE_FOLDER,
  GET_FOLDER,
  SET_FOLDER,
  UPDATE_FOLDER,
  UPDATE_FOLDER_ITEMS,
  GET_FOLDERITEMS,
  SET_FOLDERITEMS,
  GET_ALL_FOLDERS,
  SET_ALL_FOLDERS,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import { takeLatest, put, cancel } from "redux-saga/effects";
import { renderFolderListNameCell } from "../../Components/Grid/SenchaGrid/renderers";
import axios from "axios";

import {
  GROUP_COLLECTIONS,
  SYSTEM_COLLECTIONS,
} from "../../constants/collections";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";
const Ext = window["Ext"];

const FOLDERS_LIST_STORE_ID = CONSTANTS.STORES.IDS.FOLDERS_LIST;

function getGridColumns() {
  return [
    {
      dataIndex: "Name",
      text: "<span title='Name'>Name</span>",
      menuDisabled: true,
      resizable: true,
      searchable: true,
      width: 220,
      cell: {
        height: 50,
        encodeHtml: true,
        xtype: "reactcell",
      },
      renderer: (value, record) =>
        renderFolderListNameCell(null, value, record),
    },
    {
      dataIndex: "Description",
      text: "<span title='Description'>Description</span>",
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 125,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "Created",
      text: "<span title='Created'>Created</span>",
      menuDisabled: true,
      resizable: true,
      searchable: true,
      width: 116,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "CreatedBy",
      text: "<span title='Created By'>Created By</span>",
      menuDisabled: true,
      resizable: true,
      searchable: true,
      width: 116,
      cell: {
        height: 50,
      },
    },
  ];
}

function* fetchFolders({ storeId }) {
  const store = Ext.getStore(storeId);
  if (store) {
    store.load({
      params: {
        Command: CONSTANTS.COMMANDS.FOLDER_GETLIST,
      },
    });
  }

  const columns = getGridColumns();

  yield put({
    type: SET_FOLDERS,
    payload: {
      columns,
    },
  });
}

function* getAllFolders() {
  const { data } = yield axios.post(
    CONSTANTS.ROUTES.FOLDER_GETLIST,
    new URLSearchParams({
      Command: CONSTANTS.COMMANDS.FOLDER_GETLIST,
    })
  );
  if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
    yield cancel();
  }

  yield put({
    type: SET_ALL_FOLDERS,
    payload: data.Rows,
  });
}

function* addFolder({ type: action, formParams = {}, storeId }) {
  let params = {
    Name: formParams.Name,
    Description: formParams.Description,
    Command: CONSTANTS.COMMANDS.FOLDER_ADD,
  };
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.FOLDER_ADD,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    const store = Ext.getStore(storeId);
    if (store) {
      store.load({
        params: {
          Command: CONSTANTS.COMMANDS.FOLDER_GETLIST,
        },
      });
    }

    const columns = getGridColumns();

    yield put({
      type: GET_FOLDERS,
      payload: {
        columns,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getCurrentFolder({ folderId }) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.FOLDER_GETCOMPLETE,
      new URLSearchParams({
        FolderID: folderId,
        Command: "Folder.GetComplete",
      })
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    yield put({
      type: SET_CURRENT_FOLDER,
      currentFolder: data.Rows,
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* fetchFolder({ FolderID }) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.FOLDER_GET,
      new URLSearchParams({
        FolderID,
        Command: CONSTANTS.COMMANDS.FOLDER_GET,
      })
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    yield put({
      type: SET_FOLDER,
      payload: data.FolderRow,
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* updateFolder({ type: action, params = {}, STORE_ID }) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.UPDATE_FOLDER,
      new URLSearchParams({
        ...params,
        Command: CONSTANTS.COMMANDS.UPDATE_FOLDER,
      }),
      {
        headers: {
          action,
        },
      }
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    const store = Ext.getStore(STORE_ID);
    if (store) {
      store.load({
        params: {
          Command: CONSTANTS.COMMANDS.FOLDER_GETLIST,
        },
      });
    }
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* updateFolderItems({ type: action, FolderID, Items }) {
  const params = {
    FolderID,
    Items: JSON.stringify(Items),
    Command: CONSTANTS.COMMANDS.UPDATE_FOLDER_ITEMS,
  };
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.UPDATE_FOLDER_ITEMS,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getFolderItems({ type: action, id }) {
  const params = {
    FolderID: id,
    Command: CONSTANTS.COMMANDS.FOLDER_GETITEMS,
  };
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.FOLDER_GETITEMS,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    yield put({
      type: SET_FOLDERITEMS,
      payload: data.Rows,
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* deleteFolder({ params, navigate, shouldNavigate }) {
  try {
    const { data } = yield axios.post(CONSTANTS.ROUTES.FOLDER_DELETE, params);
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    if (shouldNavigate) {
      navigate("/folders");
    } else {
      const store = Ext.getStore(FOLDERS_LIST_STORE_ID);
      if (store) {
        store.load({
          params: {
            Command: CONSTANTS.COMMANDS.FOLDER_GETLIST,
          },
        });
      }
    }
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}
function* getViewsFolder({ tab }) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETVIEWS,
      new URLSearchParams({
        CollectionID:
          tab === "agents"
            ? GROUP_COLLECTIONS.AGENTS
            : SYSTEM_COLLECTIONS.COLLECTIONS,
        Command: "Collection.GetViews",
      })
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    yield put({
      type: SET_VIEWS_FOLDER,
      viewsFolder: data.ViewTable,
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

export default function* folderDataWatcher() {
  yield takeLatest(GET_FOLDER, fetchFolder);
  yield takeLatest(GET_FOLDERS, fetchFolders);
  yield takeLatest(GET_CURRENT_FOLDER, getCurrentFolder);
  yield takeLatest(ADD_FOLDER, addFolder);
  yield takeLatest(DELETE_FOLDER, deleteFolder);
  yield takeLatest(UPDATE_FOLDER, updateFolder);
  yield takeLatest(UPDATE_FOLDER_ITEMS, updateFolderItems);
  yield takeLatest(GET_FOLDERITEMS, getFolderItems);
  yield takeLatest(GET_VIEWS_FOLDER, getViewsFolder);
  yield takeLatest(GET_ALL_FOLDERS, getAllFolders);
}
