export const GRID_TYPES = {
  SETTINGS: "settings",
  INVOICE: "invoice",
  JOBS: "jobs",
  USERS: "users",
  AGENTS: "agents",
  FIELDS: "fields",
  ITEMS: "items",
  COLLECTIONS: "collections",
  COOKIES: "cookies",
  SOURCE_COLLECTIONS: "source collections",
  DEPARTMENTS: "departments",
  AGENT: "agent",
  SEQUENCES: "sequences",
  RATE_PLANS: "rate plans",
};
