import {
  GET_FOLDER_AGENTS,
  SET_FOLDER_AGENTS,
  DELETE_FOLDER_AGENTS,
  CLEAR_FOLDER_AGENTS,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import { takeLatest, put, select, call, cancel } from "redux-saga/effects";
import axios from "axios";
import { renderNameCell } from "../../Components/Grid/SenchaLockedGrid/renderers";
import { GROUP_COLLECTIONS } from "../../constants/collections";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";
const Ext = window["Ext"];

const FOLDERS_AGENTS_LIST = CONSTANTS.STORES.IDS.FOLDERS_AGENTS_LIST;
function prepareModel(data, store) {
  const model = store.config.model;

  model.setFields(data);

  return model;
}

function prepareGridColumns(data) {
  const renderers = {
    Name: {
      renderer: renderNameCell,
    },
  };

  return data.ViewFields.map((field) => {
    if (renderers[field.Name]) {
      return {
        dataIndex: field.Name,
        text: `<span title='${field.Name}'>${field.Name}</span>`,
        locked: field.IsSticky,
        hidden: field.IsHidden,
        width: field.ColumnWidth,
        cell: {
          height: 50,
          encodeHtml: true,
          xtype: "reactcell",
        },
        renderer: (value, record) =>
          renderers[field.Name].renderer(
            renderers[field.Name].mapper,
            value,
            record
          ),
      };
    }
    return {
      dataIndex: field.Name,
      text: `<span title='${field.Name}'>${field.Name}</span>`,
      locked: field.IsSticky,
      hidden: field.IsHidden,
      width: field.ColumnWidth,
      cell: {
        height: 50,
      },
    };
  });
}

function* createStore(storeId, data) {
  const store = Ext.getStore(storeId);
  if (store) {
    const model = prepareModel(data, store);

    const {
      CollectionID,
      FolderID,
      SearchText,
      SearchField,
      PageIndex,
      PageItemCount,
    } = data.CollectionState;

    store.removeAll(true);
    store.setModel(model);

    store.setPageSize(PageItemCount);

    store.loadPage(PageIndex, {
      params: {
        CollectionID,
        ViewID: data.ViewRow.ViewID,
        FolderID,
        SearchText,
        SearchField,
        AgentID: data.AgentID,
        Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
      },
    });
  }

  const columns = prepareGridColumns(data);

  yield put({
    type: SET_FOLDER_AGENTS,
    payload: {
      columns,
      sortedBy: data.ViewSorting,
      viewRow: data.ViewRow,
      collectionState: data.CollectionState,
      viewFields: data.ViewFields,
    },
  });
}

function* fetchFolderAgentData({ type: action, storeId, formParams = {} }) {
  const foldersStore = yield select((state) => state.folders);

  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETCOMPLETE,
    CollectionID: GROUP_COLLECTIONS.AGENTS,
    ViewID: foldersStore.folderAgentsViewRow?.viewId || "",
    ...formParams,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    yield call(createStore, storeId, data);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* deleteAgents({ selectedItems, FolderID, ViewID }) {
  try {
    const id = selectedItems.map((item) => item.id).join(",");
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.AGENT_DELETE,
      new URLSearchParams({
        AgentID: id,
        Command: CONSTANTS.COMMANDS.AGENT_DELETE,
      })
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    window["Ext"].getStore(FOLDERS_AGENTS_LIST).load({
      params: {
        Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
        CollectionID: GROUP_COLLECTIONS.AGENTS,
        ViewID: ViewID,
        FolderID: FolderID,
        BookmarkID: 0,
        JobID: -1,
        ColumnsToAlwaysInclude:
          "Config,Name,Description,Settings,Status,ErrorPostpone,CollectionID,LimitWebPageCredits,AgentGroup,Geolocation,BrowserType",
        AlwaysIncludeCustomFields: false,
        IncludeAdditionalCustomInterfaceColumns: true,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}
function* clearAgents({ formParams }) {
  const {
    storeId,
    selectedAgents,
    urlSearchParams,
    viewID,
    folderID,
  } = formParams;

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_EMPTY,
      urlSearchParams
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    selectedAgents.map(
      yield (agent) => {
        axios.post(
          CONSTANTS.ROUTES.AGENT_GETBOOKMARKS_COMBOBOX_OPTIONS,
          new URLSearchParams({
            MaxBookmarks: 1000,
            Command: CONSTANTS.COMMANDS.AGENT_GETBOOKMARKS_COMBOBOX_OPTIONS,
            AgentID: agent.id,
          })
        );
      }
    );
    window["Ext"].getStore(storeId).load({
      params: {
        Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
        CollectionID: GROUP_COLLECTIONS.AGENTS,
        ViewID: viewID,
        FolderID: folderID,
        BookmarkID: 0,
        JobID: -1,
        ColumnsToAlwaysInclude:
          "Config,Name,Description,Settings,Status,ErrorPostpone,CollectionID,LimitWebPageCredits,AgentGroup,Geolocation,BrowserType",
        AlwaysIncludeCustomFields: false,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}
export default function* folderAgentDataWatcher() {
  yield takeLatest(GET_FOLDER_AGENTS, fetchFolderAgentData);
  yield takeLatest(DELETE_FOLDER_AGENTS, deleteAgents);
  yield takeLatest(CLEAR_FOLDER_AGENTS, clearAgents);
}
