import { IconTypes } from "../Data/Enums/IconTypes";
import { ReactComponent as SuperAdminIcon } from "../assets/icons/mwc-superadmin.svg";
import { ReactComponent as AccountAdminIcon } from "../assets/icons/mwc-accountadmin.svg";
import { ReactComponent as UserEmailIcon } from "../assets/icons/mwc-useremail.svg";
import { ReactComponent as AgentSetIcon } from "../assets/icons/mwc-agentset.svg";
import { ReactComponent as AgentEditIcon } from "../assets/icons/mwc-agentedit.svg";
import { ReactComponent as SequenceIcon } from "../assets/icons/mwc-sequence.svg";
import { ReactComponent as ComboIcon } from "../assets/icons/mwc-combo.svg";
import { ReactComponent as CollectionNewIcon } from "../assets/icons/mwc-collectionew.svg";
import { ReactComponent as CollectionEditIcon } from "../assets/icons/mwc-collectionedit.svg";

export const USERS_STATUS = {
  DISABLED: {
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fad", "exclamation-triangle"],
    DESCRIPTION: "Disabled",
  },
  MOZENDA: {
    TYPE: IconTypes.CUSTOM,
    ICON: SuperAdminIcon,
    DESCRIPTION: "Mozenda system administrator",
  },
  INVITED: {
    TYPE: IconTypes.CUSTOM,
    ICON: UserEmailIcon,
    DESCRIPTION: "Invited user",
  },
  MASTERACCOUNTADMINISTRATOR: {
    TYPE: IconTypes.CUSTOM,
    ICON: AccountAdminIcon,
    DESCRIPTION: "Account administrator",
  },
  ACCOUNTADMINISTRATOR: {
    TYPE: IconTypes.CUSTOM,
    ICON: AccountAdminIcon,
  },
  ADMINISTRATOR: {
    TYPE: IconTypes.CUSTOM,
    ICON: AccountAdminIcon,
    DESCRIPTION: "Account administrator",
  },
  USER: {
    TYPE: IconTypes.CUSTOM,
    ICON: AccountAdminIcon,
    DESCRIPTION: "Standard user",
  },
};

export const USERS_AGENTS = {
  RUN: {
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fad", "play-circle"],
    DESCRIPTION: "Run - Run and schedule agents",
  },
  CONFIGURE: {
    TYPE: IconTypes.CUSTOM,
    ICON: AgentSetIcon,
    DESCRIPTION: "Configure - Modify agent settings and notifications",
  },
  AGENTBUILD: {
    TYPE: IconTypes.CUSTOM,
    ICON: AgentEditIcon,
    DESCRIPTION: "Build - Create, edit, and delete agents",
  },
};

export const USERS_ACCOUNT_PERMISSIONS = {
  USERS: {
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fad", "user"],
    DESCRIPTION: "Users - Add, edit, and delete users in this account",
  },
  BILLING: {
    TYPE: IconTypes.CUSTOM,
    ICON: SequenceIcon,
    DESCRIPTION: "Billing - View account billing information",
  },
  VIEW: {
    TYPE: IconTypes.CUSTOM,
    ICON: ComboIcon,
    DESCRIPTION: "View - View collection data",
  },
  CONFIDENTIAL: {
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fad", "eye"],
    DESCRIPTION: "Confidential - View confidential data in collections",
  },
  EXPORT: {
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fad", "cloud-download"],
    DESCRIPTION: "Export - Export and publish data from collections",
  },
  IMPORT: {
    TYPE: IconTypes.CUSTOM,
    ICON: CollectionNewIcon,
    DESCRIPTION: "Import - Import data into collections",
  },
  CLEAR: {
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fad", "empty-set"],
    DESCRIPTION: "Clear - Clear collection data, history and files",
  },
  COLLECTIONBUILD: {
    TYPE: IconTypes.CUSTOM,
    ICON: CollectionEditIcon,
    DESCRIPTION: "Build - Create, edit and delete collections",
  },
  SEQUENCES: {
    TYPE: IconTypes.CUSTOM,
    ICON: SequenceIcon,
    DESCRIPTION: "Build - Create, edit, run, and delete sequences",
  },
};
export const COUNTRIES = [
  ["", "USA"],
  ["USA", "United States"],
  ["AFG", "Afghanistan"],
  ["ALB", "Albania"],
  ["DZA", "Algeria"],
  ["ASM", "American Samoa"],
  ["AND", "Andorra"],
  ["AGO", "Angola"],
  ["AIA", "Anguilla"],
  ["ATA", "Antarctica"],
  ["ATG", "Antigua and Barbuda"],
  ["ARG", "Argentina"],
  ["ARM", "Armenia"],
  ["ABW", "Aruba"],
  ["AUS", "Australia"],
  ["AUT", "Austria"],
  ["AZE", "Azerbaijan"],
  ["BHS", "Bahamas, The"],
  ["BHR", "Bahrain"],
  ["BGD", "Bangladesh"],
  ["BRB", "Barbados"],
  ["BLR", "Belarus"],
  ["BEL", "Belgium"],
  ["BLZ", "Belize"],
  ["BEN", "Benin"],
  ["BMU", "Bermuda"],
  ["BTN", "Bhutan"],
  ["BOL", "Bolivia"],
  ["BIH", "Bosnia and Herzegovina"],
  ["BWA", "Botswana"],
  ["BVT", "Bouvet Island"],
  ["BRA", "Brazil"],
  ["IOT", "British Indian Ocean Territory"],
  ["VGB", "British Virgin Islands"],
  ["BRN", "Brunei"],
  ["BGR", "Bulgaria"],
  ["BFA", "Burkina Faso"],
  ["MMR", "Burma"],
  ["BDI", "Burundi"],
  ["KHM", "Cambodia"],
  ["CMR", "Cameroon"],
  ["CAN", "Canada"],
  ["CPV", "Cape Verde"],
  ["CYM", "Cayman Islands"],
  ["CAF", "Central African Republic"],
  ["TCD", "Chad"],
  ["CHL", "Chile"],
  ["CHN", "China"],
  ["CXR", "Christmas Island"],
  ["CCK", "Cocos (Keeling) Islands"],
  ["COL", "Colombia"],
  ["COM", "Comoros"],
  ["COD", "Congo, Democratic Republic of the"],
  ["COG", "Congo, Republic of the"],
  ["COK", "Cook Islands"],
  ["CRI", "Costa Rica"],
  ["CIV", "Cote d'Ivoire"],
  ["HRV", "Croatia"],
  ["CUB", "Cuba"],
  ["CYP", "Cyprus"],
  ["CZE", "Czech Republic"],
  ["DNK", "Denmark"],
  ["DJI", "Djibouti"],
  ["DMA", "Dominica"],
  ["DOM", "Dominican Republic"],
  ["ECU", "Ecuador"],
  ["EGY", "Egypt"],
  ["SLV", "El Salvador"],
  ["GNQ", "Equatorial Guinea"],
  ["ERI", "Eritrea"],
  ["EST", "Estonia"],
  ["EVR", "Evhret' Republic of Kekoa"],
  ["ETH", "Ethiopia"],
  ["FLK", "Falkland Islands (Islas Malvinas)"],
  ["FRO", "Faroe Islands"],
  ["FJI", "Fiji"],
  ["FIN", "Finland"],
  ["FRA", "France"],
  ["FXX", "France, Metropolitan"],
  ["GUF", "French Guiana"],
  ["PYF", "French Polynesia"],
  ["ATF", "French Southern and Antarctic Lands"],
  ["GAB", "Gabon"],
  ["GMB", "Gambia, The"],
  ["PSE", "Gaza Strip"],
  ["GEO", "Georgia"],
  ["DEU", "Germany"],
  ["GHA", "Ghana"],
  ["GIB", "Gibraltar"],
  ["GRC", "Greece"],
  ["GRL", "Greenland"],
  ["GRD", "Grenada"],
  ["GLP", "Guadeloupe"],
  ["GUM", "Guam"],
  ["GTM", "Guatemala"],
  ["GGY", "Guernsey"],
  ["GIN", "Guinea"],
  ["GNB", "Guinea-Bissau"],
  ["GUY", "Guyana"],
  ["HTI", "Haiti"],
  ["HMD", "Heard Island and McDonald Islands"],
  ["VAT", "Holy See (Vatican City)"],
  ["HND", "Honduras"],
  ["HKG", "Hong Kong"],
  ["HUN", "Hungary"],
  ["ISL", "Iceland"],
  ["IND", "India"],
  ["IDN", "Indonesia"],
  ["IRN", "Iran"],
  ["IRQ", "Iraq"],
  ["IRL", "Ireland"],
  ["IMN", "Isle of Man"],
  ["ISR", "Israel"],
  ["ITA", "Italy"],
  ["JAM", "Jamaica"],
  ["JPN", "Japan"],
  ["JEY", "Jersey"],
  ["JOR", "Jordan"],
  ["KAZ", "Kazakhstan"],
  ["KEN", "Kenya"],
  ["KIR", "Kiribati"],
  ["PRK", "Korea, North"],
  ["KOR", "Korea, South"],
  ["KWT", "Kuwait"],
  ["KGZ", "Kyrgyzstan"],
  ["LAO", "Laos"],
  ["LVA", "Latvia"],
  ["LBN", "Lebanon"],
  ["LSO", "Lesotho"],
  ["LBR", "Liberia"],
  ["LBY", "Libya"],
  ["LIE", "Liechtenstein"],
  ["LTU", "Lithuania"],
  ["LUX", "Luxembourg"],
  ["MAC", "Macau"],
  ["MKD", "Macedonia"],
  ["MDG", "Madagascar"],
  ["MWI", "Malawi"],
  ["MYS", "Malaysia"],
  ["MDV", "Maldives"],
  ["MLI", "Mali"],
  ["MLT", "Malta"],
  ["MHL", "Marshall Islands"],
  ["MTQ", "Martinique"],
  ["MRT", "Mauritania"],
  ["MUS", "Mauritius"],
  ["MYT", "Mayotte"],
  ["MEX", "Mexico"],
  ["FSM", "Micronesia, Federated States of"],
  ["MDA", "Moldova"],
  ["MCO", "Monaco"],
  ["MNG", "Mongolia"],
  ["MNE", "Montenegro"],
  ["MSR", "Montserrat"],
  ["MAR", "Morocco"],
  ["MOZ", "Mozambique"],
  ["NAM", "Namibia"],
  ["NRU", "Nauru"],
  ["NPL", "Nepal"],
  ["NLD", "Netherlands"],
  ["ANT", "Netherlands Antilles"],
  ["NCL", "New Caledonia"],
  ["NZL", "New Zealand"],
  ["NIC", "Nicaragua"],
  ["NER", "Niger"],
  ["NGA", "Nigeria"],
  ["NIU", "Niue"],
  ["NFK", "Norfolk Island"],
  ["MNP", "Northern Mariana Islands"],
  ["NOR", "Norway"],
  ["OMN", "Oman"],
  ["PAK", "Pakistan"],
  ["PLW", "Palau"],
  ["PAN", "Panama"],
  ["PNG", "Papua New Guinea"],
  ["PRY", "Paraguay"],
  ["PER", "Peru"],
  ["PHL", "Philippines"],
  ["PCN", "Pitcairn Islands"],
  ["POL", "Poland"],
  ["PRT", "Portugal"],
  ["PRI", "Puerto Rico"],
  ["QAT", "Qatar"],
  ["REU", "Reunion"],
  ["ROU", "Romania"],
  ["RUS", "Russia"],
  ["RWA", "Rwanda"],
  ["BLM", "Saint Barthelemy"],
  ["SHN", "Saint Helena"],
  ["KNA", "Saint Kitts and Nevis"],
  ["LCA", "Saint Lucia"],
  ["MAF", "Saint Martin"],
  ["SPM", "Saint Pierre and Miquelon"],
  ["VCT", "Saint Vincent and the Grenadines"],
  ["WSM", "Samoa"],
  ["SMR", "San Marino"],
  ["STP", "Sao Tome and Principe"],
  ["SAU", "Saudi Arabia"],
  ["SEN", "Senegal"],
  ["SRB", "Serbia"],
  ["SYC", "Seychelles"],
  ["SLE", "Sierra Leone"],
  ["SGP", "Singapore"],
  ["SVK", "Slovakia"],
  ["SVN", "Slovenia"],
  ["SLB", "Solomon Islands"],
  ["SOM", "Somalia"],
  ["ZAF", "South Africa"],
  ["SGS", "South Georgia and the Islands"],
  ["ESP", "Spain"],
  ["LKA", "Sri Lanka"],
  ["SDN", "Sudan"],
  ["SUR", "Suriname"],
  ["SJM", "Svalbard"],
  ["SWZ", "Swaziland"],
  ["SWE", "Sweden"],
  ["CHE", "Switzerland"],
  ["SYR", "Syria"],
  ["TWN", "Taiwan"],
  ["TJK", "Tajikistan"],
  ["TZA", "Tanzania"],
  ["THA", "Thailand"],
  ["TLS", "Timor-Leste"],
  ["TGO", "Togo"],
  ["TKL", "Tokelau"],
  ["TON", "Tonga"],
  ["TTO", "Trinidad and Tobago"],
  ["TUN", "Tunisia"],
  ["TUR", "Turkey"],
  ["TKM", "Turkmenistan"],
  ["TCA", "Turks and Caicos Islands"],
  ["TUV", "Tuvalu"],
  ["UGA", "Uganda"],
  ["UKR", "Ukraine"],
  ["ARE", "United Arab Emirates"],
  ["GBR", "United Kingdom"],
  ["UMI", "United States Minor Outlying Islands"],
  ["URY", "Uruguay"],
  ["UZB", "Uzbekistan"],
  ["VUT", "Vanuatu"],
  ["VEN", "Venezuela"],
  ["VNM", "Vietnam"],
  ["VIR", "Virgin Islands"],
  ["WLF", "Wallis and Futuna"],
  ["PSE", "West Bank"],
  ["ESH", "Western Sahara"],
  ["YEM", "Yemen"],
  ["ZMB", "Zambia"],
  ["ZWE", "Zimbabwe"],
];
