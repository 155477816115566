import { IconTypes } from "../Data/Enums/IconTypes";
import { ReactComponent as AgentIcon } from "../assets/icons/mwc-agent.svg";
import { ReactComponent as FilePublishIcon } from "../assets/icons/mwc-filepublish.svg";
import { ReactComponent as SequenceIcon } from "../assets/icons/mwc-sequence.svg";
import { ReactComponent as SequenceAddIcon } from "../assets/icons/mwc-sequenceadd.svg";

export const SEQUENCE_STEPS = {
  AGENTRUN: {
    DESCRIPTION: "Run agent",
    TYPE: IconTypes.CUSTOM,
    ICON: AgentIcon,
  },
  PUBLISHDATA: {
    DESCRIPTION: "Publish collection data",
    TYPE: IconTypes.CUSTOM,
    ICON: FilePublishIcon,
  },
  COLLECTIONCLEAR: {
    DESCRIPTION: "Clear collection data",
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fad", "ban"],
  },
  VIEWDELETEITEMS: {
    DESCRIPTION: "Delete view items",
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fad", "times-circle"],
  },
  SEQUENCESTART: {
    DESCRIPTION: "Start another sequence",
    TYPE: IconTypes.CUSTOM,
    ICON: SequenceAddIcon,
  },
  UPDATEFIELDVALUE: {
    DESCRIPTION: "Update a field value",
    TYPE: IconTypes.CUSTOM,
    ICON: AgentIcon,
  },
  SEQUENCERUN: {
    DESCRIPTION: "Run a sequence",
    TYPE: IconTypes.CUSTOM,
    ICON: SequenceIcon,
  },
};
