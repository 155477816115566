import { IconTypes } from "../Data/Enums/IconTypes";

export const NEW_VIEW_RSSFEED_COLUMNS = {
  LOCKED: {
    DESCRIPTION: "Locked column",
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fad", "starfighter-alt"],
  },
  HIDDEN: {
    DESCRIPTION: "Hidden column",
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fad", "starfighter-alt"],
  },
};

export const COMMON_VIEW_NAMES = {
  AllCollections: "All Collections",
  StandardCollections: "Standard Collections",
  CombinedCollections: "Combined Collections",
  AgentCollections: "Agent Collections",
  NonAgentCollections: "Non-Agent Collections",
  BillingSummaryAllSegments: "All Segments",
  StorageSummaryAllSegments: "All Segments",
  AgentStatisticsDefault: "Default",
  AgentJobs: "Agent Jobs",
  ActiveJobs: "Active Jobs",
};
