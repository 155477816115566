import { combineReducers } from "redux";
import * as agentsReducer from "./agents";
import * as agentBuilderReducer from "./agentBuilder";
import * as agentRunReducer from "./agentRun";
import * as agentGroupsReducer from "./agentGroups";
import * as sequencesReducer from "./sequences";
import * as departmentsReducer from "./departments";
import * as accountReducer from "./account";
import * as commonReducer from "./common";
import * as cookiesReducer from "./cookies";
import * as foldersReducer from "./folders";
import * as collectionsReducer from "./collections";
import * as viewsReducer from "./views";
import * as userReducer from "./user";
import * as publishingReducer from "./publishing";
import * as schedulerReducer from "./scheduler";
import * as systemBillingInfoReducer from "./systemBillingInfo";
import * as recentItemsReducer from "./recentItems";
import * as exportReducer from "./exports";
import * as systemReducer from "./system";

export default combineReducers(
  Object.assign(
    agentsReducer,
    agentBuilderReducer,
    agentRunReducer,
    agentGroupsReducer,
    sequencesReducer,
    departmentsReducer,
    accountReducer,
    commonReducer,
    cookiesReducer,
    foldersReducer,
    collectionsReducer,
    viewsReducer,
    userReducer,
    publishingReducer,
    systemBillingInfoReducer,
    recentItemsReducer,
    schedulerReducer,
    exportReducer,
    systemReducer
  )
);
