import {
  GET_COOKIES,
  SET_COOKIES,
  SET_COOKIES_SAVED_VIEWS,
  GET_COOKIES_SAVED_VIEWS,
  UPDATE_COOKIESTORE,
  CREATE_NEW_COOKIESTORE,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import { takeLatest, put, call, cancel } from "redux-saga/effects";
import { mapCollectionNameCell } from "../../Components/Grid/SenchaLockedGrid/mappers";
import { renderCookieNameCell } from "../../Components/Grid/SenchaLockedGrid/renderers";
import axios from "axios";
import { GROUP_COLLECTIONS } from "../../constants/collections";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";
const Ext = window["Ext"];

function prepareModel(data, store) {
  const model = store.config.model;

  model.setFields(data);

  return model;
}

function prepareGridColumns(data) {
  const renderers = {
    Name: {
      mapper: mapCollectionNameCell,
      renderer: renderCookieNameCell,
    },
  };

  return data.ViewFields.map((field) => {
    if (renderers[field.Name]) {
      return {
        dataIndex: field.Name,
        text: `<span title='${field.Name}'>${field.Name}</span>`,
        locked: field.IsSticky,
        hidden: field.IsHidden,
        width: field.ColumnWidth,
        cell: {
          height: 50,
          encodeHtml: true,
          xtype: "reactcell",
        },
        renderer: (value, record) =>
          renderers[field.Name].renderer(
            renderers[field.Name].mapper,
            value,
            record
          ),
      };
    }

    return {
      dataIndex: field.Name,
      text: `<span title='${field.Name}'>${field.Name}</span>`,
      locked: field.IsSticky,
      hidden: field.IsHidden,
      width: field.ColumnWidth,
      cell: {
        height: 50,
        encodeHtml: true,
      },
    };
  });
}

function* createStore(storeId, data, filteredData = {}) {
  const store = Ext.getStore(storeId);
  if (store) {
    const model = prepareModel(data, store);

    const { SearchText, SearchField, FolderID } = data.CollectionState;

    store.removeAll(true);
    store.setModel(model);
    const params = {
      Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
      SearchText,
      SearchField,
      CollectionID: data.CollectionID,
      ViewID: data.ViewRow.ViewID,
      FolderID,
      AgentID: data.AgentID,
      ...filteredData,
    };
    store.baseParams = params;

    store.getProxy().extraParams = store.baseParams;
    store.loadPage(1, {
      params,
    });
  }

  const columns = prepareGridColumns(data);

  yield put({
    type: SET_COOKIES,
    payload: {
      columns,
      sortedBy: data.ViewSorting,
      viewRow: data.ViewRow,
      collectionState: data.CollectionState,
      viewFields: data.ViewFields,
      refreshed: data.Collection.Refreshed,
    },
  });
}

function* createNewCookieStore({ params, history, closeModal }) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COOKIESTORE_ADD,
      new URLSearchParams(params)
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    closeModal();
    history(`/cookies/${data.CookieStoreID}`);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* fetchCookies({ type: action, storeId, formParams = {} }) {
  const params = {
    CollectionID: GROUP_COLLECTIONS.COOKIE_STORES,
    ViewID: "",
    Command: CONSTANTS.COMMANDS.COLLECTION_GETCOMPLETE,
    ...formParams,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    yield call(createStore, storeId, data);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getSavedViews({ type: action }) {
  const params = {
    AccountKey: "",
    CollectionID: GROUP_COLLECTIONS.COOKIE_STORES,
    Command: CONSTANTS.COMMANDS.COLLECTION_GETVIEWS,
  };

  try {
    const result = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETVIEWS,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (result.data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        result.data.JsonResult.Result,
        result.data.JsonResult.ErrorDescription,
        result.data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    const savedViews = [];

    result.data.ViewTable.forEach((view) => {
      let savedView = {};
      for (let key in view) {
        if (CONSTANTS.RESPONSE_CONTEXT.SAVED_VIEWS_CONTEXT.includes(key)) {
          savedView[key] = view[key];
        }
      }
      savedViews.push(savedView);
    });
    yield put({
      type: SET_COOKIES_SAVED_VIEWS,
      payload: {
        savedViews,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* updateCookieStore({ type: action, formParams = {} }) {
  const params = {
    Command: CONSTANTS.COMMANDS.COOKIESTORE_UPDATE,
    ...formParams,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COOKIESTORE_UPDATE,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

export default function* cookiesWatcher() {
  yield takeLatest(GET_COOKIES, fetchCookies);
  yield takeLatest(GET_COOKIES_SAVED_VIEWS, getSavedViews);
  yield takeLatest(UPDATE_COOKIESTORE, updateCookieStore);
  yield takeLatest(CREATE_NEW_COOKIESTORE, createNewCookieStore);
}
