import {
  GET_COOKIE_DETAILS,
  SET_COOKIE_DETAILS,
  GET_CURRENT_COOKIE,
  SET_CURRENT_COOKIE,
  DELETE_COOKIE,
  DELETE_COOKIE_STORE,
  COOKIE_STORE_ADD_UPDATE_COOKIE,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import { takeLatest, put, select, call, cancel } from "redux-saga/effects";
import { mapCollectionNameCell } from "../../Components/Grid/SenchaLockedGrid/mappers";
import { renderCookieDetailsNameCell } from "../../Components/Grid/SenchaLockedGrid/renderers";
import axios from "axios";
import { GROUP_COLLECTIONS } from "../../constants/collections";
import { COMMANDS } from "../../constants/commands";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";

const Ext = window["Ext"];
const COOKIE_DETAILS = CONSTANTS.STORES.IDS.COOKIE_DETAILS;

function prepareModel(data, store) {
  const model = store.config.model;

  model.setFields(data);

  return model;
}

function prepareGridColumns(data, cb) {
  const renderers = {
    Name: {
      mapper: mapCollectionNameCell,
      renderer: renderCookieDetailsNameCell,
    },
  };

  return data.ViewFields.map((field) => {
    if (renderers[field.Name]) {
      return {
        dataIndex: field.Name,
        text: `<span title='${field.Name}'>${field.Name}</span>`,
        locked: field.IsSticky,
        hidden: field.IsHidden,
        width: field.ColumnWidth,
        cell: {
          height: 50,
          encodeHtml: true,
          xtype: "reactcell",
        },
        renderer: (value, record) =>
          renderers[field.Name].renderer(
            renderers[field.Name].mapper,
            value,
            record,
            cb[field.Name]
          ),
      };
    }

    return {
      dataIndex: field.Name,
      text: `<span title='${field.Name}'>${field.Name}</span>`,
      locked: field.IsSticky,
      hidden: field.IsHidden,
      width: field.ColumnWidth,
      cell: {
        height: 50,
        encodeHtml: true,
      },
    };
  });
}

function* createStore(storeId, data, cb, extraParams = {}) {
  const store = Ext.getStore(storeId);
  if (store) {
    const model = prepareModel(data, store);
    store.removeAll(true);
    store.setModel(model);

    const baseParams = {
      Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
      ContextRestrictionFieldNames: "CookieStoreID",
      CollectionID: GROUP_COLLECTIONS.COOKIE_STORE_DETAILS,
      SearchText: data.CollectionState.SearchText,
      SearchField: data.CollectionState.SearchField,
      ...extraParams,
    };
    store.baseParams = baseParams;
    store.getProxy().extraParams = store.baseParams;
    store.loadPage(1, {
      params: baseParams,
    });
  }

  const columns = prepareGridColumns(data, cb);

  yield put({
    type: SET_COOKIE_DETAILS,
    payload: {
      columns,
      cookieDetailsSortedBy: data.ViewSorting,
      collectionState: data.CollectionState,
      viewFields: data.ViewFields,
      refreshed: data.Collection.Refreshed,
    },
  });
}

function* fetchCookieDetails({ type: action, storeId, cb, formParams = {} }) {
  const cookiesStore = yield select((state) => state.cookies);

  const params = {
    CollectionID: GROUP_COLLECTIONS.COOKIE_STORE_DETAILS,
    ViewID: cookiesStore.viewRow?.viewId || "",
    Command: CONSTANTS.COMMANDS.COLLECTION_GETCOMPLETE,
    ...formParams,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    const extraParams = {
      ViewID: data.ViewRow.ViewID,
      AgentID: data.AgentID,
      BookmarkID: data.Collection.BookmarkID,
      FolderID: data.CollectionState.FolderID,
      SearchText: data.CollectionState.SearchText,
      SearchField: data.CollectionState.SearchField,
      ContextRestrictionFieldValues: parseInt(
        params.ContextRestrictionFieldValues,
        10
      ),
    };

    yield call(createStore, storeId, data, cb, extraParams);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getCurrentCookie({ cookieId }) {
  const params = {
    CookieStoreID: cookieId,
    Command: "CookieStore.Get",
  };
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COOKIESTORE_GET,
      new URLSearchParams(params)
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    yield put({
      type: SET_CURRENT_COOKIE,
      currentCookie: data.CookieStore,
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* deleteCookie({ formParams, selectedItems }) {
  const { viewId } = formParams;
  try {
    const id = selectedItems.map((item) => item.id).join(",");
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_DELETE_ITEMS,
      new URLSearchParams({
        ItemID: id,
        CollectionID: GROUP_COLLECTIONS.COOKIE_STORE_DETAILS,
        Command: CONSTANTS.COMMANDS.COLLECTION_DELETE_ITEMS,
      })
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    window["Ext"].getStore(COOKIE_DETAILS).load({
      params: {
        Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
        CollectionID: GROUP_COLLECTIONS.COOKIE_STORE_DETAILS,
        ViewID: viewId,
        BookmarkID: 0,
        JobID: -1,
        ColumnsToAlwaysInclude: "Name",
        AlwaysIncludeCustomFields: true,
        ContextRestrictionFieldNames: "CookieStoreID",
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}
function* deleteCookieStore({ params, viewId, navigate, shouldNavigate }) {
  try {
    yield axios.post(CONSTANTS.ROUTES.COOKIESTORE_DELETE, params);
    if (shouldNavigate) {
      window["Ext"].getStore(CONSTANTS.STORES.IDS.COOKIE_DETAILS).load({
        params: {
          Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
          CollectionID: GROUP_COLLECTIONS.COOKIE_STORES,
          ViewID: viewId,
          BookmarkID: 0,
          JobID: -1,
          ColumnsToAlwaysInclude: "Name,Description",
          AlwaysIncludeCustomFields: false,
        },
      });
      navigate("/cookies");
    } else {
      window["Ext"].getStore(CONSTANTS.STORES.IDS.COOKIES_LIST).load({
        params: {
          Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
          CollectionID: GROUP_COLLECTIONS.COOKIE_STORES,
          ViewID: viewId,
          BookmarkID: 0,
          JobID: -1,
          ColumnsToAlwaysInclude: "Name,Description",
          AlwaysIncludeCustomFields: false,
        },
      });
    }
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}
function* addCookie(action) {
  const { params, closeModal, ViewID } = action;
  try {
    yield axios.post(
      CONSTANTS.ROUTES.COOKIE_STORE_ADD_UPDATE_COOKIE,
      new URLSearchParams({
        ...params,
      })
    );
    const STORE_ID = CONSTANTS.STORES.IDS.COOKIE_DETAILS;

    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams({
        CollectionID: GROUP_COLLECTIONS.COOKIE_STORE_DETAILS,
        Command: COMMANDS.COLLECTION_GETCOMPLETE,
        ViewID,
      })
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    window["Ext"].getStore(STORE_ID).load({
      params: {
        Command: COMMANDS.VIEW_GETITEMS,
        CollectionID: data.CollectionState.CollectionID,
        ViewID: data.CollectionState.ViewID,
        AgentID: data.AgentID,
        BookmarkID: data.Collection.BookmarkID,
        JobID: -1,
        ItemStatusesToInclude: "",
        ColumnsToAlwaysInclude: "Name",
        ForceViewSortable: "",
        AlwaysIncludeCustomFields: false,
        IncludeAdditionalCustomInterfaceColumns: "",
        SelectedIDs: params.selectedID,
        ShowHistoryColumn: false,
        ShowSelected: false,
        TemplateCollectionID: 0,
        SearchText: "",
        SearchField: data.CollectionState.SearchField,
        ShowMarkup: false,
        page: data.CollectionState.PageIndex,
        start: 0,
        limit: data.CollectionState.PageItemCount,
      },
    });
    closeModal();
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

export default function* cookieDetailsWatcher() {
  yield takeLatest(GET_COOKIE_DETAILS, fetchCookieDetails);
  yield takeLatest(DELETE_COOKIE, deleteCookie);
  yield takeLatest(DELETE_COOKIE_STORE, deleteCookieStore);
  yield takeLatest(GET_CURRENT_COOKIE, getCurrentCookie);
  yield takeLatest(COOKIE_STORE_ADD_UPDATE_COOKIE, addCookie);
}
