import {
  GET_IMAGES_AND_FILES,
  SET_IMAGES_AND_FILES,
  DELETE_PACKAGE_FILES,
  RE_PACKAGE_FILES,
  REPACKAGE_ERROR,
  EXPORT_AGENT_DOWNLOAD,
  RENAME_PACKAGE_FILE,
} from "../types";
import { takeLatest, put, call, cancel } from "redux-saga/effects";
import { CONSTANTS } from "../../constants/constants";
import fileDownload from "../../utils/js-file-download/fileDownload";
import axios from "axios";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";

const Ext = window["Ext"];

function getGridColumns() {
  return [
    {
      dataIndex: "Name",
      text: "Field",
      locked: true,
      menuDisabled: true,
      resizable: true,
      searchable: true,
      width: 220,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "SequenceNumber",
      text: "Seq. #",
      locked: false,
      menuDisabled: true,
      resizable: true,
      searchable: false,
      width: 50,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "NumberOfFiles",
      text: "# of Files",
      locked: false,
      menuDisabled: true,
      resizable: true,
      searchable: false,
      width: 50,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "FileSize",
      text: "Size",
      locked: false,
      menuDisabled: true,
      resizable: true,
      searchable: false,
      width: 70,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "DownloadDate",
      text: "Downloaded",
      locked: false,
      menuDisabled: true,
      resizable: true,
      searchable: false,
      width: 120,
      cell: {
        height: 50,
      },
    },
  ];
}

function* fetchImagesAndFiles(action) {
  try {
    const { collectionId, storeId } = action;
    const params = {
      Command: CONSTANTS.COMMANDS.COLLECTION_GETPACKAGEFILESLIST,
      CollectionID: collectionId,
    };

    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETPACKAGEFILESLIST,
      new URLSearchParams(params)
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    let store = Ext.getStore(storeId);
    if (store) {
      store.loadData(data.Rows);
      store.sorters.add(
        new Ext.util.Sorter({
          property: "id",
          direction: "DESC",
        })
      );

      store.sort();
      store.sort("id", "DESC");
    }
    const columns = getGridColumns();

    yield put({
      type: SET_IMAGES_AND_FILES,
      payload: {
        columns,
        hasFileFields: data.HasFileFields,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* exportAgent(action) {
  const { formParams } = action;

  const { data } = yield axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/export`,
    params: {
      ...formParams,
    },
    responseType: "blob",
  });
  const filename = formParams.UserFileName
    ? formParams.UserFileName
    : `${formParams.CollectionName}-${formParams.ViewName}${formParams.ignore}`;
  fileDownload(data, filename);
}

function* deletePackageFiles({ selectedItems, collectionId, storeId }) {
  const urlSearchParams = new URLSearchParams({
    Command: CONSTANTS.COMMANDS.COLLECTION_DELETEPACKAGEFILE,
    fileCount: selectedItems.length,
    CollectionID: collectionId,
  });

  selectedItems.forEach((item) => {
    urlSearchParams.append("fileNames", item.data.BaseFileName);
  });
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_DELETEPACKAGEFILE,
      urlSearchParams
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    yield call(fetchImagesAndFiles, { collectionId, storeId });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* rePackageFiles({ collectionId, storeId }) {
  const urlSearchParams = new URLSearchParams({
    CollectionID: collectionId,
    Command: CONSTANTS.COMMANDS.COLLECTION_REPACKAGEFILES,
  });
  yield call(fetchImagesAndFiles, { collectionId, storeId });
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_REPACKAGEFILES,
      urlSearchParams
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    if (data.ErrorDescription) {
      yield put({ type: REPACKAGE_ERROR, error: data.ErrorDescription });
    }
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* renamePackageFile({ collectionId, formParams }) {
  const urlSearchParams = new URLSearchParams({
    CollectionID: collectionId,
    Command: CONSTANTS.COMMANDS.COLLECTION_RENAMEPACKAGEFILE,
    ...formParams,
  });

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_RENAMEPACKAGEFILE,
      urlSearchParams
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    if (data.ErrorDescription) {
      yield put({ type: REPACKAGE_ERROR, error: data.ErrorDescription });
    }
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

export default function* exportWatcher() {
  yield takeLatest(GET_IMAGES_AND_FILES, fetchImagesAndFiles);
  yield takeLatest(DELETE_PACKAGE_FILES, deletePackageFiles);
  yield takeLatest(RE_PACKAGE_FILES, rePackageFiles);
  yield takeLatest(EXPORT_AGENT_DOWNLOAD, exportAgent);
  yield takeLatest(RENAME_PACKAGE_FILE, renamePackageFile);
}
