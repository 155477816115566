import React, { Suspense, lazy, useEffect, useState } from "react";
import "./assets/sass/app.scss";
import { Router, Redirect } from "@reach/router";
import ErrorBoundary from "./ErrorBoundary";
import ProgressBar from "./Components/Progress/ProgressBar";
import "react-shields-badge/dist/react-shields-badge.css";
import { connect } from "react-redux";
import "./Overrides/SenchaDragDropZone";
import "./Overrides/SenchaPagingToolbar";
import "./Overrides/SenchaReactCell";
import "./Overrides/SenchaGridSelectionModel";
import "./Overrides/SenchaGridColumn";
import "./Overrides/xhr.js";
import {
  GET_ACCOUNT,
  GET_ACCOUNT_AUTHORIZATIONURLS,
  GET_AGENTS_STATUS_OVERVIEW,
  GET_AGENT_GROUPS,
  GET_BETA_SETTINGS,
  GET_RECENT_ITEMS,
  GET_SYSTEM_BILLING_INFO,
  GET_USER,
  GET_WEB_LOCATIONS,
  ROUTER_LOCATION,
} from "./store/types";
import { notificationHandling } from "./EventHandling";
import { CONSTANTS } from "./constants/constants";
import Modal from "./Modal";
import { Box, makeStyles } from "@material-ui/core";
import NotificationAlert from "./Components/NotificationAlert/NotificationAlert";
import AuthorizationContext, { useAuthTable } from "./AuthorizationTable";
import {
  timeToDisplayByWordCount,
  randomIntFromInterval,
} from "./utils/number";
import Login from "./Login";
import { IntercomProvider } from "react-use-intercom";
const HomeLayout = lazy(() => import("./Home.layout"));
const Home = lazy(() => import("./Pages/Home"));
const HomeTrial = lazy(() => import("./Pages/Home/HomeTrial"));
const Agents = lazy(() => import("./Pages/Agents/Agents"));
const AgentDetails = lazy(() => import("./Pages/Agents/Details"));
const CollectionsCombined = lazy(() => import("./Pages/Collections/Combined"));
const CollectionsStandard = lazy(() => import("./Pages/Collections/Standard"));
const Collections = lazy(() => import("./Pages/Collections"));
const AgentGroupsDetails = lazy(() => import("./Pages/AgentGroups/Details"));
const Sequences = lazy(() => import("./Pages/Sequences"));
const SequenceSteps = lazy(() => import("./Pages/Sequences/Steps"));
const Departments = lazy(() => import("./Pages/Departments"));
const DepartmentDetails = lazy(() => import("./Pages/Departments/Details"));
const Storage = lazy(() => import("./Pages/Account/Storage/"));
const AccountDetails = lazy(() => import("./Pages/Account/Details"));
const Cookies = lazy(() => import("./Pages/Cookies"));
const CookieDetails = lazy(() => import("./Pages/Cookies/Details"));
const Folders = lazy(() => import("./Pages/Folders/Folders"));
const FolderDetails = lazy(() => import("./Pages/Folders/Details"));
const System = lazy(() => import("./Pages/System/System"));
const PublicLayout = lazy(() => import("./PublicLayout"));
const ErrorPage404A = lazy(() => import("./Pages/ErrorPage404A"));
const ErrorPage404B = lazy(() => import("./Pages/ErrorPage404B"));
const Maintenance = lazy(() => import("./Pages/Maintenance"));
const ErrorPage500 = lazy(() => import("./Pages/ErrorPage500"));

const useStyles = makeStyles({
  root: {
    width: 300,
  },
});

let notifications = [];
let updateEvent, removeNotifications;

notificationHandling.on(CONSTANTS.EVENTS.ERROR_EVENT, ({ msg, type }) => {
  if (updateEvent && typeof updateEvent === "function") updateEvent(msg, type);
  if (removeNotifications && typeof removeNotifications === "function")
    removeNotifications();
});

const Randomize404 = ({ primary, secondary }) => {
  let randomNumber = randomIntFromInterval(0, 1);
  if (randomNumber === 0) {
    return primary;
  } else {
    return secondary;
  }
};

const App = () => {
  // eslint-disable-next-line
  const [_eventEmmited, setEventEmmited] = useState();
  const classes = useStyles();

  updateEvent = function (msg, type) {
    const id = Math.random();
    notifications.push({
      type,
      msg,
      show: true,
      id,
      removeFromDOM() {
        setTimeout(() => {
          notifications = notifications.filter((el) => el.id !== id);
          removeAlert(id);
        }, timeToDisplayByWordCount(msg));
      },
      removeOnClose() {
        notifications = notifications.filter((el) => el.id !== id);
        removeAlert(id);
      },
    });
    setEventEmmited({ msg });
  };

  removeNotifications = () => {
    const updatedNotifications = [...notifications];

    updatedNotifications.forEach((notification) => {
      notification.removeFromDOM();
    });
  };
  function removeAlert(id) {
    const alertNotification = "alert-notification";
    if (
      document.body.contains(
        document.getElementById(`${alertNotification}-${id}`)
      )
    ) {
      const parentAlert = document.getElementById(`${alertNotification}-${id}`)
        .parentElement;
      if (document.getElementById(`${alertNotification}-${id}`) !== null) {
        parentAlert.classList.add("second");
        parentAlert.classList.add("fade-out");
        parentAlert.addEventListener("animationend", () => {
          document.querySelectorAll(".fade-out").forEach((alert) => {
            alert.classList.add("second");
            alert.classList.add("swipeup");
          });
        });
        setTimeout(() => {
          if (
            parentAlert.parentElement === null ||
            !parentAlert.parentElement.innerHTML ||
            !parentAlert.parentElement.textContent
          ) {
            return;
          } else {
            let isAlertThere;
            Array.from(parentAlert.parentElement.children).forEach((el) => {
              if (el === parentAlert) {
                isAlertThere = true;
              }
            });
            if (!isAlertThere) return;

            notifications = notifications.filter((el) => el.id !== id);
            parentAlert.parentElement.removeChild(parentAlert);
          }
        }, 800);
      }
    }
  }

  return (
    <div>
      <Modal>
        {notifications.map((notification, i) => {
          return notification.show ? (
            <Box className={classes.root} key={i} py={0.5}>
              <NotificationAlert
                id={`alert-notification-${notification.id}`}
                onClose={() => {
                  notification.removeOnClose();
                }}
                type={notification.type}
                alertMessage={notification.msg}
              />
            </Box>
          ) : null;
        })}
      </Modal>
      <Router primary={false}>
        <HomeLayout path="/">
          <Home path="/" />
          <HomeTrial path="/trial" />
          <Agents path="/agents" />
          <AgentDetails path="/agents/:id/*" />
          <AgentGroupsDetails path="/agentgroups/:id/*" />
          <Collections path="/collections" />
          <CollectionsCombined path="/collections/combined/:id/*" />
          <CollectionsStandard path="/collections/standard/:id/*" />
          <Sequences path="/sequences" />
          <Folders path="/folders" />
          <FolderDetails path="/folders/:id/*" />
          <SequenceSteps path="/sequences/:id" />
          <Departments path="/departments" />
          <DepartmentDetails path="/departments/:id/*" />
          <AccountDetails path="/account/:userId/*" />
          <Storage path="/account/:userId/*" />
          <Cookies path="/cookies" />
          <CookieDetails path="/cookies/:id" />
          <System path="/system/:id" />
          <Redirect noThrow from="*" to="/public/404" />
        </HomeLayout>
        <PublicLayout path="/public">
          <Randomize404
            path="/404"
            primary={<ErrorPage404A />}
            secondary={<ErrorPage404B />}
          />
          <Maintenance path="/maintenance" />
          <ErrorPage500 path="/500" />
          <Redirect noThrow from="*" to="/public/404" />
        </PublicLayout>
      </Router>
    </div>
  );
};

const AuthorizationProvider = (props) => {
  const authorizationTable = useAuthTable(props.account, props.user);
  return (
    <AuthorizationContext.Provider value={authorizationTable}>
      {props.children}
    </AuthorizationContext.Provider>
  );
};

function AppErrorBoundary(props) {
  const {
    getAccount,
    getAgentGroups,
    getUser,
    getWebConsoleBetaSettings,
    getSystemBillingInfo,
    getAccountAuthorizationUrls,
    getRecentItems,
    getWebLocations,
    getAgentsStatusOverview,
    sessionExpired,
  } = props;
  useEffect(() => {
    getAccount();
    getAgentGroups();
    getWebLocations();
    getUser();
    getWebConsoleBetaSettings();
    getSystemBillingInfo();
    getAccountAuthorizationUrls();
    getRecentItems();
    getAgentsStatusOverview();
  }, [
    getAccount,
    getAgentGroups,
    getUser,
    getSystemBillingInfo,
    getAccountAuthorizationUrls,
    getWebConsoleBetaSettings,
    getWebLocations,
    getRecentItems,
    getAgentsStatusOverview,
  ]);

  if (
    sessionExpired &&
    !props.account.accountInfo?.Account?.AccountKey &&
    !props.account.maintenanceMode
  ) {
    window.location.href = process.env.REACT_APP_LOGIN_URL;
    return null;
  }

  if (
    !props.account.accountInfo?.Account?.AccountKey &&
    !props.account.maintenanceMode
  ) {
    return null;
  }

  return (
    <ErrorBoundary>
      <Suspense fallback={<ProgressBar progressEnd={80} height={2} />}>
        <AuthorizationProvider
          account={props.account?.accountInfo}
          user={props.user?.user}
        >
          <IntercomProvider appId={CONSTANTS.INTERCOM_APP_ID}>
            <App {...props} />
            {sessionExpired && <Login />}
          </IntercomProvider>
        </AuthorizationProvider>
      </Suspense>
    </ErrorBoundary>
  );
}
const mapStateToProps = (state) => ({
  account: state.account,
  user: state.user,
  sessionExpired: state.account.sessionExpired,
});
const mapDispatchToProps = (dispatch) => ({
  getAccount: () => dispatch({ type: GET_ACCOUNT }),
  getWebLocations: () => dispatch({ type: GET_WEB_LOCATIONS }),
  getUser: () => dispatch({ type: GET_USER }),
  getAgentGroups: () => dispatch({ type: GET_AGENT_GROUPS }),
  getAccountAuthorizationUrls: () =>
    dispatch({ type: GET_ACCOUNT_AUTHORIZATIONURLS }),
  getWebConsoleBetaSettings: () => dispatch({ type: GET_BETA_SETTINGS }),
  setRouterLocation: (location) =>
    dispatch({ type: ROUTER_LOCATION, location }),
  getSystemBillingInfo: () => dispatch({ type: GET_SYSTEM_BILLING_INFO }),
  getRecentItems: () =>
    dispatch({
      type: GET_RECENT_ITEMS,
    }),
  getAgentsStatusOverview: () =>
    dispatch({
      type: GET_AGENTS_STATUS_OVERVIEW,
    }),
});
export default connect(mapStateToProps, mapDispatchToProps)(AppErrorBoundary);
