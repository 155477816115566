import {
  SET_USER,
  GET_USER,
  CHANGE_ACCOUNT,
  GET_TIMEZONES,
  SET_TIMEZONES,
  SET_COMMON_WEBLOCATIONS,
  GET_WEB_LOCATIONS,
  USER_GET_ADMIN_EMAILS,
  USER_SET_ADMIN_EMAILS,
  GET_UPDATE_INVOICE_EMAILS,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import { takeLatest, put, call, cancel, select } from "redux-saga/effects";
import axios from "axios";
import { INVITE_USERS, DELETE_USER } from "../../store/types";
import { GROUP_COLLECTIONS } from "../../constants/collections";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";

function* getWebLocations() {
  try {
    const params = {
      Command: "Common.GetWebLocations",
    };
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COMMON_GETWEBLOCATIONS,
      new URLSearchParams(params)
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    handleJsonResultError(
      data.JsonResult.Result,
      data.JsonResult.ErrorDescription,
      data.JsonResult.ErrorList,
      CONSTANTS.NOTIFICATIONS.SUCCESS
    );
    yield put({
      type: SET_COMMON_WEBLOCATIONS,
      payload: data,
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* fetchUser({ type: action, formParams = {} }) {
  const params = {
    Command: CONSTANTS.COMMANDS.USER_GET,
    ...formParams,
  };
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.USER_GET,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    handleJsonResultError(
      data.JsonResult.Result,
      data.JsonResult.ErrorDescription,
      data.JsonResult.ErrorList,
      CONSTANTS.NOTIFICATIONS.SUCCESS
    );
    yield put({
      type: SET_USER,
      payload: {
        userInfo: data,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* loginSystemAdmin(systemAdminUserKey, accountKey) {
  const reduxState = yield select();

  const webLocation = reduxState.user.webLocations.WebLocations.find(
    (loc) => loc.WebLocationName === "Accounts"
  ).WebLocation;

  let location;
  if (process.env.NODE_ENV === "development") {
    location =
      webLocation +
      "/login?AccountKey=" +
      accountKey +
      "&guid=" +
      systemAdminUserKey;
    window.location.href = location;
  } else {
    location =
      process.env.REACT_APP_LOGIN_URL +
      "/login?AccountKey=" +
      accountKey +
      "&guid=" +
      systemAdminUserKey;
  }
  window.location.href = location;
}

function* login(userWebConsoleKey) {
  const reduxState = yield select();

  const webLocation = reduxState.user.webLocations.WebLocations.find(
    (loc) => loc.WebLocationName === "Accounts"
  ).WebLocation;

  let location;
  if (process.env.NODE_ENV === "development") {
    location = webLocation + "/login?guid=" + userWebConsoleKey;
  } else {
    location =
      `${process.env.REACT_APP_LOGIN_URL}/login?guid=` + userWebConsoleKey;
  }
  window.location.href = location;
}

function* getUserWebConsoleKey(response, accountKey) {
  const reduxState = yield select();
  const userList = response.Rows;
  let userWebConsoleKey;

  for (let index = 0; index < userList.length; index++) {
    if (userList[index].Email === reduxState.user.user.User.Email) {
      userWebConsoleKey = userList[index].WebConsoleKey;
      break;
    }
  }
  if (userWebConsoleKey) yield call(login, userWebConsoleKey);
  else
    yield call(loginSystemAdmin, reduxState.user.user.User.UserKey, accountKey);
}

function* changeAccount({ account }) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.USER_GETLIST,
      new URLSearchParams({
        AccountKey: account.AccountKey,
        Command: CONSTANTS.COMMANDS.USER_GETLIST,
      })
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    yield call(getUserWebConsoleKey, data, account.AccountKey);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getTimezones() {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.GET_TIMEZONES,
      new URLSearchParams({
        Command: CONSTANTS.COMMANDS.GET_TIMEZONES,
      })
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    yield put({
      type: SET_TIMEZONES,
      payload: data.TimeZones,
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}
function* deleteUsers({ params, accountKey, setLoading, filterTextSearch }) {
  try {
    setLoading(true);
    const { data } = yield axios.post(CONSTANTS.ROUTES.USER_DELETE, params);
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    window["Ext"].getStore(CONSTANTS.STORES.IDS.ACCOUNT_USERS).load({
      params: {
        Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
        CollectionID: GROUP_COLLECTIONS.USERS,
        AccountKey: accountKey,
        BookmarkID: 0,
        JobID: -1,
        ColumnsToAlwaysInclude:
          "Email,NameFirst,NameLast,Status,UserKey,AccountPermissions,Settings,City,Company,Country,FaxNumber,Phone1,Phone2,Street1,Street2,State,Title,ZipCode",
        AlwaysIncludeCustomFields: false,
        ShowHistoryColumn: false,
        ShowSelected: false,
        TemplateCollectionID: 0,
        ShowMarkup: false,
        SearchText: filterTextSearch,
        SearchField: "AllViewFields",
      },
    });
    setLoading(false);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}
function* inviteUsers({ params, setLoading, toggleModal, accountKey }) {
  try {
    setLoading(true);
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.ACCOUNT_INVITE_USERS,
      params
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    window["Ext"].getStore(CONSTANTS.STORES.IDS.DEPARTMENT_USERS).load({
      params: {
        Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
        CollectionID: GROUP_COLLECTIONS.USERS,
        ViewID: 39,
        AccountKey: accountKey,
        BookmarkID: 0,
        JobID: -1,
        ColumnsToAlwaysInclude:
          "Email,NameFirst,NameLast,Status,UserKey,AccountPermissions,Settings,City,Company,Country,FaxNumber,Phone1,Phone2,Street1,Street2,State,Title,ZipCode",
        AlwaysIncludeCustomFields: false,
        ShowHistoryColumn: false,
        ShowSelected: false,
        TemplateCollectionID: 0,
        ShowMarkup: false,
      },
    });
    setLoading(false);
    toggleModal(false);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}
function* getAdminEmails() {
  const reduxState = yield select();
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.USER_GETADMINEMAILS,
      new URLSearchParams({
        AccountID: reduxState.account.accountInfo.Account.ItemID,
        Command: CONSTANTS.COMMANDS.USER_GETADMINEMAILS,
      })
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    yield put({
      type: USER_SET_ADMIN_EMAILS,
      payload: data,
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}
function* getUpdateInvoiceEmails({ params, closeModal }) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.ACCOUNT_UPDATEINVOICEEMAILS,
      new URLSearchParams(params)
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    closeModal();
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}
export default function* userWatcher() {
  yield takeLatest(GET_USER, fetchUser);
  yield takeLatest(DELETE_USER, deleteUsers);
  yield takeLatest(INVITE_USERS, inviteUsers);
  yield takeLatest(CHANGE_ACCOUNT, changeAccount);
  yield takeLatest(GET_TIMEZONES, getTimezones);
  yield takeLatest(GET_WEB_LOCATIONS, getWebLocations);
  yield takeLatest(USER_GET_ADMIN_EMAILS, getAdminEmails);
  yield takeLatest(GET_UPDATE_INVOICE_EMAILS, getUpdateInvoiceEmails);
}
