import {
  GET_DEPARTMENT_UTILIZATION_PERIOD,
  SET_DEPARTMENT_UTILIZATION_PERIOD,
  GET_DEPARTMENT_UTILIZATION_SAVED_VIEWS,
  SET_DEPARTMENT_UTILIZATION_SAVED_VIEWS,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import { takeLatest, put, call, cancel } from "redux-saga/effects";
import axios from "axios";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";

const Ext = window["Ext"];

function prepareModel(data, store) {
  const model = store.config.model;

  model.setFields(data);

  return model;
}

function prepareGridColumns(data) {
  return data.ViewFields.map((field) => {
    const title =
      field.HeaderText === "Processing Credits" ? field.HeaderText : field.Name;

    return {
      dataIndex: title,
      text: `<span title='${title}'>${title}</span>`,
      locked: field.IsSticky,
      hidden: field.IsHidden,
      width: field.ColumnWidth,
      cell: {
        height: 46,
      },
    };
  });
}

function* createStore(storeId, data, extraParams = {}) {
  const store = Ext.getStore(storeId);
  if (store) {
    const model = prepareModel(data, store);

    store.removeAll(true);
    store.setModel(model);

    store.load({
      params: {
        ViewID: data.ViewRow.ViewID,
        AgentID: data.AgentID,
        ColumnsToAlwaysInclude: "Refreshed",
        ...extraParams,
      },
    });
  }

  const columns = prepareGridColumns(data);

  yield put({
    type: SET_DEPARTMENT_UTILIZATION_PERIOD,
    payload: {
      columns,
      viewRow: data.ViewRow,
    },
  });
}

function* fetchDepartmentUtilizationPeriod({
  type: action,
  storeId,
  formParams = {},
}) {
  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETCOMPLETE,
    CollectionID: CONSTANTS.COLLECTIONS.GROUP.BILLING_SUMMARY,
    overrideViewName: "Month",
    HideSystemViewNames: "All Segments",
    ...formParams,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    const extraParams = {
      CollectionID: CONSTANTS.COLLECTIONS.GROUP.BILLING_SUMMARY,
      ...formParams,
      ViewID: data.ViewRow.ViewID,
    };
    yield call(createStore, storeId, data, extraParams);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getSavedViews({ type: action, formParams = {} }) {
  const params = {
    CollectionID: CONSTANTS.COLLECTIONS.GROUP.BILLING_SUMMARY,
    Command: CONSTANTS.COMMANDS.COLLECTION_GETVIEWS,
    ...formParams,
  };

  try {
    const result = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETVIEWS,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );
    if (result.data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        result.data.JsonResult.Result,
        result.data.JsonResult.ErrorDescription,
        result.data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    const savedViews = [];

    result.data.ViewTable.forEach((view) => {
      if (view.Name !== "All Segments") {
        let savedView = {};

        for (let key in view) {
          if (CONSTANTS.RESPONSE_CONTEXT.SAVED_VIEWS_CONTEXT.includes(key)) {
            savedView[key] = view[key];
          }
        }
        savedViews.push(savedView);
      }
    });

    yield put({
      type: SET_DEPARTMENT_UTILIZATION_SAVED_VIEWS,
      payload: {
        savedViews,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

export default function* departmentUtilizationWatcher() {
  yield takeLatest(
    GET_DEPARTMENT_UTILIZATION_PERIOD,
    fetchDepartmentUtilizationPeriod
  );
  yield takeLatest(GET_DEPARTMENT_UTILIZATION_SAVED_VIEWS, getSavedViews);
}
