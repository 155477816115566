import { GET_FOLDERS_MODAL, SET_FOLDERS_MODAL, UPDATE_FOLDERS } from "../types";
import { CONSTANTS } from "../../constants/constants";
import { takeLatest, put, takeEvery, cancel } from "redux-saga/effects";
import axios from "axios";
import { renderFolderListNameCellModal } from "../../Components/Grid/SenchaGrid/renderers";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";

const Ext = window["Ext"];

function getGridColumns() {
  return [
    {
      dataIndex: "Name",
      text: "<span title='Name'>Name</span>",
      menuDisabled: true,
      resizable: true,
      searchable: true,
      width: 220,
      cell: {
        height: 50,
        encodeHtml: true,
        xtype: "reactcell",
      },
      renderer: (value, record) =>
        renderFolderListNameCellModal(null, value, record),
    },
    {
      dataIndex: "Description",
      text: "<span title='Description'>Description</span>",
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 125,
      cell: {
        height: 50,
      },
    },
  ];
}

function* fetchFolders({ storeId, AgentIDList, CollectionID }) {
  const store = Ext.getStore(storeId);

  if (store) {
    store.load({
      params: {
        CollectionID: CollectionID,
        Items: [...AgentIDList],
        Command: CONSTANTS.COMMANDS.FOLDER_GETLIST,
      },
    });
    store.removeAll();
  }

  const columns = getGridColumns();

  yield put({
    type: SET_FOLDERS_MODAL,
    payload: {
      columns,
    },
  });
}

function* updateFolders({
  type: action,
  storeId,
  AgentIDList,
  CollectionID,
  formParams = {},
  originPageStoreId,
}) {
  const params = {
    CollectionID: CollectionID,
    Command: CONSTANTS.COMMANDS.UPDATE_FOLDERS,
    ...formParams,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.UPDATE_FOLDERS,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    yield put({ type: GET_FOLDERS_MODAL, storeId, AgentIDList, CollectionID });

    const originPageStore = Ext.getStore(originPageStoreId);
    if (originPageStore) {
      originPageStore.reload();
    }
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

export default function* folderModalWatcher() {
  yield takeEvery(GET_FOLDERS_MODAL, fetchFolders);
  yield takeLatest(UPDATE_FOLDERS, updateFolders);
}
