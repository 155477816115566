import {
  GET_NEW_VIEW_FIELDS,
  SET_NEW_VIEW_FIELDS,
  GET_NEW_VIEW_FILTERS,
  SET_NEW_VIEW_FILTERS,
  SET_SHARE_KEY,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import { takeEvery, put, call, select, cancel } from "redux-saga/effects";
import { mapNewViewNameCell } from "../../Components/Grid/SenchaGrid/mappers";
import { renderNewViewNameCell } from "../../Components/Grid/SenchaGrid/renderers";
import axios from "axios";
import { COMMANDS } from "../../constants/commands";
import initStore from "../../store/senchaStores";
import { errorHandlingEvent } from "../../EventHandling";

function viewPropertiesIsFieldSelectable(field) {
  // var isSystemAdministrator = UserHasSetting(Zen.Constants.UserSettings.SystemAdministrator);
  const isSystemAdministrator = false;
  const isHiddenSystem = field.IsHidden && field.IsSystem;
  if (isHiddenSystem && !isSystemAdministrator) return false;

  if (CONSTANTS.HIDE_FIELDS.ViewProperties.indexOf(field.Name) !== -1)
    return false;

  if (field.Format === "Section") return false;

  return true;
}

function loadStore(fieldsData, publisherItemData) {
  function viewPropertiesRssAddFields(inRss) {
    if (!fieldsData) return [];
    return fieldsData.reduce((acc, field) => {
      const isSelectable = viewPropertiesIsFieldSelectable(field);

      if (!isSelectable) return acc;
      const rssContent =
        (publisherItemData.Publisher &&
          publisherItemData.Publisher.RssContent.split(/\s*,\s*/)) ||
        null;
      const isInRss =
        (rssContent && rssContent.indexOf(field.FieldID.toString()) !== -1) ||
        false;
      if (isInRss !== inRss) return acc;
      return [...acc, [field.FieldID, field.Name, isInRss, field.IsSystem]];
    }, []);
  }

  let storeData = [];
  let inRssStoreData = viewPropertiesRssAddFields(true);

  storeData = viewPropertiesRssAddFields(false);

  return { storeData: [...inRssStoreData, ...storeData], inRssStoreData };
}

function getGridColumns() {
  return [
    // {
    //   dataIndex: "Included",
    //   width: 26,
    //   resizable: false,
    //   fixed: true,
    //   hideable: false,
    //   menuDisabled: true,
    //   text: "",
    //   headerCheckbox: true,
    //   xtype: "checkcolumn",
    //   listeners: {
    //     beforecheckchange: {
    //       fn: this.FieldGridBeforeCheckChange,
    //       scope: this,
    //     },
    //     checkchange: {
    //       fn: this.FieldGridCheckChange,
    //       scope: this,
    //     },
    //     beforeheadercheckchange: {
    //       fn: this.FieldGridBeforeCheckboxHeaderClick,
    //       scope: this,
    //     },
    //     afterrender: {
    //       fn: this.FieldGridCheckboxHeaderAfterRender,
    //       scope: this,
    //     },
    //   },
    //   sortable: false,
    // },
    {
      text: "SELECTED COLUMNS",
      dataIndex: "Name",
      width: 200,
      sortable: false,
      cell: {
        height: 50,
        encodeHtml: true,
        xtype: "reactcell",
      },
      renderer: (value, record) =>
        renderNewViewNameCell(mapNewViewNameCell, value, record),
      flex: 1,
    },
  ];
}

function setDefaultSelectValues(fieldsData) {
  let rssPublished = {
    name: "",
    value: null,
  };
  let rssLink = {
    name: "",
    value: null,
  };
  let rssTitle = {
    name: "",
    value: null,
  };

  if (!fieldsData) {
    return {
      rssPublished,
      rssLink,
      rssTitle,
    };
  }
  fieldsData.forEach((field) => {
    if (field.Name === "Created") {
      rssPublished.value = field.FieldID;
    } else if (field.Name === "LocationUrl") {
      rssLink.value = field.FieldID;
    } else if (
      rssTitle === null &&
      viewPropertiesIsFieldSelectable(field) &&
      !field.IsSystem
    ) {
      rssTitle.value = field.FieldID;
    }
  });

  return {
    rssPublished,
    rssLink,
    rssTitle,
  };
}

function prepareSelectInputsData(fieldsData, publisherItemData) {
  let { rssPublished, rssLink, rssTitle } = setDefaultSelectValues(fieldsData);

  if (publisherItemData.Publisher) {
    rssTitle.value = parseInt(publisherItemData.Publisher.RssTitle, 10);
    rssPublished.value = parseInt(publisherItemData.Publisher.RssPublished, 10);
    rssLink.value = parseInt(publisherItemData.Publisher.RssLink, 10);
  }
  let items = [];
  if (!fieldsData) {
    return {
      items,
      rssPublished,
      rssLink,
      rssTitle,
    };
  }

  fieldsData.forEach((field) => {
    if (field.FieldID === rssPublished.value) {
      rssPublished.name = field.Name;
    }
    if (field.FieldID === rssLink.value) {
      rssLink.name = field.Name;
    }
    if (field.FieldID === rssTitle.value) {
      rssTitle.name = field.Name;
    }

    items.push({
      name: field.Name,
      value: field.FieldID,
    });
  });

  return {
    items,
    rssPublished,
    rssLink,
    rssTitle,
  };
}

function* createStore(
  storeId,
  storeName,
  fieldsResult,
  publisherItemResult,
  action,
  actionMode
) {
  const columns = getGridColumns();
  initStore(
    storeName,
    storeId,
    { data: loadStore(fieldsResult, publisherItemResult).storeData },
    action
  );

  if (actionMode === "create") {
    yield put({
      type: SET_NEW_VIEW_FIELDS,
      payload: {
        columns,
        selectInputsData: {
          items: [],
          rssPublished: { name: "", value: "" },
          rssLink: { name: "", value: "" },
          rssTitle: { name: "", value: "" },
        },
        publisher: publisherItemResult.Publisher,
        inRssStoreData: [],
      },
    });
    return;
  }

  const selectInputsData = prepareSelectInputsData(
    fieldsResult,
    publisherItemResult
  );

  let shareKey;

  if (publisherItemResult.Publisher === null) {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COMMON_GENERATEGUID,
      new URLSearchParams({ Command: COMMANDS.COMMON_GENERATEGUID })
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    shareKey = data.Guid;
  } else {
    shareKey = publisherItemResult.Publisher.ShareKey;
  }

  yield put({
    type: SET_SHARE_KEY,
    payload: shareKey,
  });
  yield put({
    type: SET_NEW_VIEW_FIELDS,
    payload: {
      columns,
      selectInputsData,
      publisher: publisherItemResult.Publisher,
      inRssStoreData:
        actionMode === "create"
          ? []
          : loadStore(fieldsResult, publisherItemResult).inRssStoreData,
    },
  });
}

function* fetchFields({
  type: action,
  storeId,
  storeName,
  actionMode,
  viewId,
  collectionId,
}) {
  try {
    const reduxState = yield select();

    const params = {
      AccountKey:
        reduxState.account.accountInfo.Account.MasterAccountKey ||
        reduxState.account.accountInfo.Account.AccountKey,
      CollectionID: collectionId,
      Command: COMMANDS.COLLECTION_GETFIELDS,
    };

    const rssParams = {
      AccountKey:
        reduxState.account.accountInfo.Account.MasterAccountKey ||
        reduxState.account.accountInfo.Account.AccountKey,
      Command: "Publisher.GetRSSPublisherItem",
      PublishViewID: actionMode === "create" ? "" : viewId,
    };

    const [fieldsResult, publisherItemResult] = yield Promise.all([
      axios.post(
        CONSTANTS.ROUTES.COLLECTION_GETFIELDS,
        new URLSearchParams(params),
        { headers: { action } }
      ),
      axios.post(
        CONSTANTS.ROUTES.PUBLISHER_GETRSSPUBLISHERITEM,
        new URLSearchParams(rssParams),
        { headers: { action } }
      ),
    ]);

    yield call(
      createStore,
      storeId,
      storeName,
      fieldsResult.data.Fields,
      publisherItemResult.data,
      action,
      actionMode
    );
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* fetchFiltersFieldOperators() {
  const reduxState = yield select();

  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETCOMPLETE,
    AccountKey: reduxState.account.accountInfo.MasterAccountKey,
  };
  try {
    const result = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams(params),
      {
        headers: {},
      }
    );

    yield put({
      type: SET_NEW_VIEW_FILTERS,
      payload: {
        fieldOperators: result.data.FieldOperators.NetItem.Values,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

export default function* newViewWatcher() {
  yield takeEvery(GET_NEW_VIEW_FIELDS, fetchFields);
  yield takeEvery(GET_NEW_VIEW_FILTERS, fetchFiltersFieldOperators);
}
