import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "start",
    "& svg": {
      marginRight: "8px",
    },
  },
  label: {
    color: (props) => props.label.color,
    fontFamily: "inherit",
  },
});

export default function LabelWithIcon(props) {
  const classes = useStyles(props);

  return (
    <div className={classes.wrapper}>
      {props.icon}
      <Typography
        className={`${classes.label} ${props.className}`}
        variant={props.variant}
      >
        {props.label.value}
      </Typography>
    </div>
  );
}

LabelWithIcon.propTypes = {
  icon: PropTypes.object,
  label: PropTypes.shape({
    value: PropTypes.string.isRequired,
    color: PropTypes.string,
  }),
  variant: PropTypes.string,
};

LabelWithIcon.defaultProps = {
  variant: "body2",
};
