import {
  GET_ACCOUNT_BILLING_INVOICE,
  SET_ACCOUNT_BILLING_INVOICE,
  GET_EXPORT_PDF,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import { takeLatest, put, cancel } from "redux-saga/effects";
import axios from "axios";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";

function* fetchAccountBillingInvoice({ type: action, formParams = {} }) {
  const params = {
    Command: CONSTANTS.COMMANDS.BILLING_GETINVOICEHTML,
    ...formParams,
  };
  try {
    const { data } = yield axios.get(
      CONSTANTS.ROUTES.BILLING_GETINVOICEHTML,
      { params },
      {
        headers: {
          action,
        },
      }
    );

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    const html = data.Html;

    yield put({
      type: SET_ACCOUNT_BILLING_INVOICE,
      payload: {
        html,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* fetchExportPdf({ params }) {
  const { AccountKey, selectedItems } = params;

  const urlParams = new URLSearchParams({
    Command: CONSTANTS.COMMANDS.BILLING_DOWNLOADINVOICEPDF,
    AccountKey,
    Protocol: `https:`,
  });
  selectedItems.forEach((item) => {
    urlParams.append("InvoiceNumberList", item.id);
  });
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.BILLING_DOWNLOADINVOICEPDF,
      urlParams
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/export`,
      params: {
        BaseFileName: data.TempFile,
        UserFileName: data.FileName,
        Method: "SpecificFile",
      },
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf",
      },
    }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", data.FileName);
      document.body.appendChild(link);
      link.click();
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

export default function* accountBillingInvoiceWatcher() {
  yield takeLatest(GET_ACCOUNT_BILLING_INVOICE, fetchAccountBillingInvoice);
  yield takeLatest(GET_EXPORT_PDF, fetchExportPdf);
}
