export const RESPONSE_CONTEXT = {
  AGENT_INFO_CONTEXT: [
    "CollectionID",
    "Description",
    "ItemID",
    "LastRunResult",
    "LastRunTime",
    "Name",
    "Config",
    "Refreshed",
    "Status",
    "TemplateName",
    "BookmarkID",
    "CollectionType",
    "DefaultViewID",
  ],
  SAVED_VIEWS_CONTEXT: [
    "Tooltip",
    "Name",
    "IsSystem",
    "IsHidden",
    "IsDefault",
    "CollectionID",
    "IsTemplate",
    "ViewID",
  ],
  COLLECTION_INFO_CONTEXT: [
    "AgentID",
    "BookmarkID",
    "CollectionID",
    "CollectionType",
    "DefaultViewID",
    "Description",
    "FolderID",
    "ItemID",
    "Name",
    "Refreshed",
    "TrackHistory",
  ],
};
