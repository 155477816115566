import {
  SET_ACCOUNT_BILLING_DATA,
  SET_ACCOUNT_BILLING_INVOICE,
  SET_ACCOUNT_STORAGE,
  SET_ACCOUNT_INSIGHTS,
  SET_ACCOUNT_JOBS,
  SET_ACCOUNT_JOB_DETAILS,
  SET_ACCOUNT_JOB_LIST,
  SET_ACCOUNT_USERS,
  SET_ACCOUNT_UTILIZATION_USAGE,
  SET_ACCOUNT_UTILIZATION_PERIOD,
  SET_ACCOUNT_UTILIZATION_SAVED_VIEWS,
  SET_ACCOUNT_STORAGE_USAGE_DATA,
  SET_ACCOUNT_STORAGE_DATA,
  SET_ACCOUNT_STORAGE_SAVED_VIEWS,
  SET_ACCOUNT,
  SET_ACCOUNT_USERS_SAVED_VIEWS,
  UPDATE_USERS_STATE_SEARCH,
  UPDATE_ACCOUNT_JOBS_STATE_SEARCH,
  UPDATE_ACCOUNT_INSIGHTS_STATE_SEARCH,
  SET_ACCOUNT_AUTHORIZATIONURLS,
  SET_ACCOUNT_BILLING_DOWNLOAD_INVOICE_LIST,
  SET_ACCOUNT_STORAGE_SUMMARY_DOWNLOAD_COLLECTION,
  SET_ACCOUNT_STORAGE_DOWNLOAD_COLLECTION_DETAILS,
  SET_CURRENT_INVOICE_RECIPIENTS,
  UPDATE_BILLING_INVOICE_RECIPIENTS,
  BILLING_EMAILS_UPDATED,
  SET_ACCOUNTSETTINGS,
  SET_AGENTS_DEFAULT,
  ACCOUNT_USERS_UPDATEVIEWTABLE,
  SET_VIEW_NOTIFICATIONS,
  UPDATE_NOTIFICATION_STATE_SEARCH,
  BILLING_ERROR,
  BILLING_LOADING,
  BILLING_SUCCESS,
  SET_EXISTING_USERS,
  UPDATE_TIMEZONE,
  SET_ACTIVE_ACCOUNT,
  SET_ACCOUNT_INSIGHTS_SAVED_VIEWS,
  SET_SESSION_EXPIRED,
  MAINTENANCE_MODE,
  SET_ACCOUNT_SETTING,
} from "../types";
import createReducer from "../createReducer";

const initialState = {
  billingColumns: [],
  storageColumns: [],
  accountInfo: null,
  accountSettings: {},
  accountStorageSavedViews: null,
  accountBillingDownloadInvoiceList: null,
  accountStorageDownloadCollectionDetails: null,
  accountStorageViewRow: {},
  accountInsightsColumns: [],
  accountJobsSortedBy: [],
  accountJobDetails: null,
  accountJobList: null,
  accountUsersColumns: [],
  accountUsersSortedBy: [],
  accountUtilizationPeriodColumns: [],
  accountUtilizationSavedViews: [],
  accountUtilizationViewRow: {},
  accountUtilizationUsageColumns: [],
  accountBillingInvoiceHtml: null,
  userViewRow: {},
  userSavedViews: [],
  userCollectionState: {},
  userViewFields: null,
  accountJobsCollectionState: {},
  accountJobsViewFields: null,
  accountInsightsCollectionState: {},
  accountInsightsViewFields: null,
  accountInsightsViewRow: {},
  accountInsightsSavedViews: [],
  accountExportPdf: null,
  currentInvoiceRecipients: [],
  viewNotificationsColumns: [],

  viewNotificationsViewRow: {},
  viewNotificationsCollectionState: {},
  viewNotificationsSortedBy: null,
  viewNotificationsViewFields: [],

  existingUsersColumns: [],
  sessionExpired: false,
  maintenanceMode: false,
};

export const account = createReducer(initialState, {
  [SET_ACCOUNT](state, { payload }) {
    return {
      ...state,
      accountInfo: { ...state.accountInfo, ...payload.accountInfo },
    };
  },
  [SET_SESSION_EXPIRED](state, { payload }) {
    return {
      ...state,
      sessionExpired: payload.sessionExpired,
    };
  },
  [MAINTENANCE_MODE](state, { payload }) {
    return {
      ...state,
      maintenanceMode: payload.maintenanceMode,
    };
  },
  [SET_ACCOUNTSETTINGS](state, { payload }) {
    return {
      ...state,
      accountSettings: payload.accountSettings,
    };
  },
  [SET_ACCOUNT_AUTHORIZATIONURLS](state, { payload }) {
    return {
      ...state,
      accountInfo: { ...state.accountInfo, ...payload },
    };
  },
  [SET_ACCOUNT_BILLING_DATA](state, { payload }) {
    return {
      ...state,
      billingColumns: payload.columns,
    };
  },
  [SET_ACCOUNT_BILLING_DOWNLOAD_INVOICE_LIST](state, { payload }) {
    return {
      ...state,
      accountBillingDownloadInvoiceList: payload.data,
    };
  },
  [SET_ACCOUNT_BILLING_INVOICE](state, { payload }) {
    return {
      ...state,
      accountBillingInvoiceHtml: payload.html,
    };
  },

  [SET_ACCOUNT_STORAGE](state, { payload }) {
    return {
      ...state,
      storageColumns: payload.columns,
    };
  },
  [SET_CURRENT_INVOICE_RECIPIENTS](state, { payload }) {
    return {
      ...state,
      currentInvoiceRecipients: payload,
    };
  },
  [SET_ACCOUNT_INSIGHTS](state, { payload }) {
    return {
      ...state,
      accountInsightsColumns: payload.columns,
      accountInsightsCollectionState: payload.collectionState,
      accountInsightsViewRow: payload.viewRow,
      accountInsightsViewFields: payload.viewFields.map((viewField) => {
        return {
          name: viewField.Name,
          value: viewField.Name,
          fieldId: viewField.FieldID,
        };
      }),
    };
  },
  [SET_ACCOUNT_INSIGHTS_SAVED_VIEWS](state, { payload }) {
    return {
      ...state,
      accountInsightsSavedViews: payload.accountInsightsSavedViews,
    };
  },
  [SET_ACCOUNT_JOBS](state, { payload }) {
    return {
      ...state,
      accountJobsColumns: payload.columns,
      accountJobsSortedBy: payload.sortedBy,
      accountJobsCollectionState: payload.collectionState,
      accountJobsViewFields: payload.viewFields.map((viewField) => {
        return {
          name: viewField.Name,
          value: viewField.Name,
          fieldId: viewField.FieldID,
        };
      }),
    };
  },

  [SET_ACCOUNT_USERS](state, { payload }) {
    return {
      ...state,
      accountUsersColumns: payload.columns,
      accountUsersSortedBy: payload.sortedBy,
      userViewRow: payload.viewRow,
      userCollectionState: payload.collectionState,
      userViewFields: payload.viewFields.map((viewField) => {
        return {
          name: viewField.Name,
          value: viewField.Name,
          fieldId: viewField.FieldID,
        };
      }),
    };
  },
  [SET_ACCOUNT_JOB_DETAILS](state, { payload }) {
    return {
      ...state,
      accountJobDetails: payload.data,
    };
  },
  [SET_ACCOUNT_JOB_LIST](state, { payload }) {
    return {
      ...state,
      accountJobList: payload.data,
    };
  },
  [SET_ACCOUNT_UTILIZATION_PERIOD](state, { payload }) {
    return {
      ...state,
      accountUtilizationPeriodColumns: payload.columns,
      accountUtilizationViewRow: payload.viewRow,
    };
  },

  [SET_ACCOUNT_UTILIZATION_SAVED_VIEWS](state, { payload }) {
    return {
      ...state,
      accountUtilizationSavedViews: payload.savedViews,
    };
  },

  [SET_ACCOUNT_STORAGE_SUMMARY_DOWNLOAD_COLLECTION](state, { payload }) {
    return {
      ...state,
      accountStorageSummaryDownloadCollection: payload.data,
    };
  },
  [SET_ACCOUNT_STORAGE_DOWNLOAD_COLLECTION_DETAILS](state, { payload }) {
    return {
      ...state,
      accountStorageDownloadCollectionDetails: payload.data,
    };
  },
  [SET_ACCOUNT_UTILIZATION_USAGE](state, { payload }) {
    return {
      ...state,
      accountUtilizationUsageColumns: payload.columns,
    };
  },

  [SET_ACCOUNT_STORAGE_DATA](state, { payload }) {
    return {
      ...state,
      accountStorageColumns: payload.columns,
      accountStorageViewRow: payload.viewRow,
    };
  },

  [SET_ACCOUNT_STORAGE_USAGE_DATA](state, { payload }) {
    return {
      ...state,
      accountStorageUsageColumns: payload.columns,
    };
  },
  [SET_ACCOUNT_STORAGE_SAVED_VIEWS](state, { payload }) {
    return {
      ...state,
      accountStorageSavedViews: payload.savedViews,
    };
  },

  [SET_ACCOUNT_USERS_SAVED_VIEWS](state, { payload }) {
    return {
      ...state,
      userSavedViews: payload.savedViews,
    };
  },

  [UPDATE_USERS_STATE_SEARCH](state, { searchFilterValues }) {
    return {
      ...state,
      userCollectionState: {
        ...state.userCollectionState,
        SearchText: searchFilterValues.searchText,
        SearchField:
          searchFilterValues.searchField !== "View Fields"
            ? searchFilterValues.searchField
            : "AllViewFields",
      },
    };
  },
  [UPDATE_ACCOUNT_JOBS_STATE_SEARCH](state, { searchFilterValues }) {
    return {
      ...state,
      accountJobsCollectionState: {
        ...state.accountJobsCollectionState,
        SearchText: searchFilterValues.searchText,
        SearchField:
          searchFilterValues.searchField !== "View Fields"
            ? searchFilterValues.searchField
            : "AllViewFields",
      },
    };
  },
  [UPDATE_ACCOUNT_INSIGHTS_STATE_SEARCH](state, { searchFilterValues }) {
    return {
      ...state,
      accountInsightsCollectionState: {
        ...state.accountInsightsCollectionState,
        SearchText: searchFilterValues.searchText,
        SearchField:
          searchFilterValues.searchField !== "View Fields"
            ? searchFilterValues.searchField
            : "AllViewFields",
      },
    };
  },
  [SET_AGENTS_DEFAULT](state, { data }) {
    return {
      ...state,
      agentsDefault: data,
    };
  },
  [ACCOUNT_USERS_UPDATEVIEWTABLE](state, { newViews }) {
    return {
      ...state,
      userSavedViews: newViews,
    };
  },

  [SET_VIEW_NOTIFICATIONS](
    state,
    { columns, collectionState, viewRow, viewFields, sortedBy }
  ) {
    return {
      ...state,
      viewNotificationsColumns: columns,
      viewNotificationsCollectionState: collectionState,
      viewNotificationsViewRow: viewRow,
      viewNotificationsSortedBy: sortedBy,
      viewNotificationsViewFields: viewFields,
    };
  },
  [UPDATE_NOTIFICATION_STATE_SEARCH](state, { searchFilterValues }) {
    return {
      ...state,
      viewNotificationsCollectionState: {
        ...state.viewNotificationsCollectionState,
        SearchText: searchFilterValues.searchText,
        SearchField:
          searchFilterValues.searchField !== "View Fields"
            ? searchFilterValues.searchField
            : "AllViewFields",
      },
    };
  },
  [BILLING_ERROR](state, { payload }) {
    return {
      ...state,
      billingError: payload,
      billingLoading: false,
    };
  },
  [BILLING_LOADING](state) {
    return {
      ...state,
      billingLoading: true,
    };
  },

  [BILLING_SUCCESS](state) {
    return {
      ...state,
      billingError: undefined,
      billingLoading: false,
    };
  },
  [SET_EXISTING_USERS](state, { columns }) {
    return {
      ...state,
      existingUsersColumns: columns,
    };
  },

  [UPDATE_TIMEZONE](state, { selectedTimeZone }) {
    return {
      ...state,
      accountInfo: {
        ...state.accountInfo,
        Account: {
          ...state.accountInfo.Account,
          TimeZone: selectedTimeZone.Id,
        },
      },
    };
  },
  [UPDATE_BILLING_INVOICE_RECIPIENTS](state, { data }) {
    return {
      ...state,
      account: {
        ...state.account,
        accountInfo: data,
      },
    };
  },
  [BILLING_EMAILS_UPDATED](state) {
    return {
      ...state,
      billingEmailsLoaded: true,
    };
  },
  [SET_ACTIVE_ACCOUNT](state, { account }) {
    return {
      ...state,
      activeAccount: account,
    };
  },
  [SET_ACCOUNT_SETTING](state, { payload }) {
    return {
      ...state,
      parentAccountSettings: payload,
    };
  },
});
