import {
  GET_AGENTGROUPS_AGENTS,
  SET_AGENTGROUPS_AGENTS,
  AGENTGROUPS_AGENT_INFO_LOADED,
  SET_AGENT_GROUPS_SAVED_VIEWS,
  GET_AGENT_GROUPS_SAVED_VIEWS,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import {
  takeLatest,
  put,
  take,
  select,
  call,
  cancel,
} from "redux-saga/effects";
import {
  mapAgentConfigCell,
  mapLastRunResultCell,
} from "../../Components/Grid/SenchaLockedGrid/mappers";
import {
  renderAgentGroupAgentNameCell,
  renderConfigCell,
  renderLastRunResultCell,
  renderNextRunTimeCell,
} from "../../Components/Grid/SenchaLockedGrid/renderers";
import axios from "axios";
import { GROUP_COLLECTIONS } from "../../constants/collections";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";

const Ext = window["Ext"];

function prepareModel(data, store) {
  const model = store.config.model;

  model.setFields(data);

  return model;
}

function prepareColumns(data) {
  const renderers = {
    Name: {
      renderer: renderAgentGroupAgentNameCell,
    },
    Config: {
      mapper: mapAgentConfigCell,
      renderer: renderConfigCell,
    },
    NextRunTime: {
      renderer: renderNextRunTimeCell,
    },
    LastRunResult: {
      mapper: mapLastRunResultCell,
      renderer: renderLastRunResultCell,
    },
  };

  return data.ViewFields.map((field) => {
    if (renderers[field.Name]) {
      return {
        dataIndex: field.Name,
        text: `<span title='${field.Name}'>${field.Name}</span>`,
        locked: field.IsSticky,
        hidden: field.IsHidden,
        width: field.ColumnWidth,
        cell: {
          height: 50,
          encodeHtml: true,
          xtype: "reactcell",
        },
        renderer: (value, record) =>
          renderers[field.Name].renderer(
            renderers[field.Name].mapper,
            value,
            record
          ),
      };
    }
    return {
      dataIndex: field.Name,
      text: `<span title='${field.Name}'>${field.Name}</span>`,
      locked: field.IsSticky,
      hidden: field.IsHidden,
      width: field.ColumnWidth,
      cell: {
        height: 50,
      },
    };
  });
}

function* createStore(storeId, data, filteredData = {}) {
  const store = Ext.getStore(storeId);

  if (store) {
    const model = prepareModel(data, store);

    store.removeAll(true);
    store.setModel(model);

    const params = {
      Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
      CollectionID: CONSTANTS.COLLECTIONS.GROUP.AGENTS,
      OverrideViewName: "All Agents",
      AgentID: 0,
      ColumnsToAlwaysInclude:
        "Config,Name,Description,Settings,Status,ErrorPostpone,CollectionID,LimitWebPageCredits,AgentGroup,Geolocation,BrowserType",
      IncludeAdditionalCustomInterfaceColumns: true,
      TemplateCollectionID: 0,
      ContextRestrictionFieldNames: "AgentGroup",
      group: { property: "Group", direction: "ASC" },
      SearchText: data.CollectionState.SearchText,
      SearchField: data.CollectionState.SearchField,
      ...filteredData,
    };

    store.baseParams = params;
    store.getProxy().extraParams = store.baseParams;
    store.loadPage(1, { params });
  }

  const columns = prepareColumns(data);

  yield put({
    type: SET_AGENTGROUPS_AGENTS,
    payload: {
      columns,
      collectionState: data.CollectionState,
      viewRow: data.ViewRow,
      sortedBy: data.ViewSorting,
      viewFields: data.ViewFields,
    },
  });
}

function* fetchAgentGroupsAgents({
  type: action,
  agentGroupId,
  storeId,
  formParams = {},
}) {
  const agentGroupsState = yield select((state) => state.agentGroups);
  if (
    !agentGroupsState.agentInfo.ItemID ||
    agentGroupsState.agentInfo.ItemID !== Number(agentGroupId)
  ) {
    yield take(AGENTGROUPS_AGENT_INFO_LOADED);
  }

  const viewId = agentGroupsState.agentsViewRow.ViewID;
  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETCOMPLETE,
    AccountKey: null,
    OverrideViewName: "All Agents",
    CollectionID: CONSTANTS.COLLECTIONS.GROUP.AGENTS,
    ViewID: viewId || "",
    ...formParams,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    const storeParams = {
      ViewID: viewId || "",
      ContextRestrictionFieldValues: Number(agentGroupId),
      SearchText: data.CollectionState.SearchText,
      SearchField: data.CollectionState.SearchField,
    };

    yield call(createStore, storeId, data, storeParams);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* fetchSavedViews({ type: action }) {
  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETVIEWS,
    CollectionID: GROUP_COLLECTIONS.AGENTS,
    AccountKey: "",
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETVIEWS,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === "Login") {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    let savedViews = [];

    data.ViewTable.forEach((view) => {
      let savedView = {};
      for (let key in view) {
        if (CONSTANTS.RESPONSE_CONTEXT.SAVED_VIEWS_CONTEXT.includes(key)) {
          savedView[key] = view[key];
        }
      }
      savedViews.push(savedView);
    });

    yield put({
      type: SET_AGENT_GROUPS_SAVED_VIEWS,
      payload: {
        savedViews,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

export default function* agentGroupsAgentsWatcher() {
  yield takeLatest(GET_AGENTGROUPS_AGENTS, fetchAgentGroupsAgents);
  yield takeLatest(GET_AGENT_GROUPS_SAVED_VIEWS, fetchSavedViews);
}
