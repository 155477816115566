import {
  GET_ACCOUNT_STORAGE_USAGE_DATA,
  SET_ACCOUNT_STORAGE_USAGE_DATA,
  GET_ACCOUNT_STORAGE_SAVED_VIEWS,
  SET_ACCOUNT_STORAGE_SAVED_VIEWS,
  GET_ACCOUNT_STORAGE_DATA,
  SET_ACCOUNT_STORAGE_DATA,
  GET_ACCOUNT_STORAGE_SUMMARY_DOWNLOAD_COLLECTION,
  SET_ACCOUNT_STORAGE_SUMMARY_DOWNLOAD_COLLECTION,
  GET_ACCOUNT_STORAGE_DOWNLOAD_COLLECTION_DETAILS,
  SET_ACCOUNT_STORAGE_DOWNLOAD_COLLECTION_DETAILS,
  VIEW_NOTIFICATIONS,
  SET_VIEW_NOTIFICATIONS,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import { takeLatest, put, call, select, cancel } from "redux-saga/effects";
import axios from "axios";
import { COMMANDS } from "../../constants/commands";
import { COMMON_COLLECTIONS } from "../../constants/collections";
import fileDownload from "../../utils/js-file-download/fileDownload";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";

const Ext = window["Ext"];

function prepareGridDetailsColumns() {
  return [
    {
      header: "CollectionID",
      text: `<span title='CollectionID'>CollectionID</span>`,
      sortable: true,
      searchable: true,
      hideable: false,
      type: "int",
      dataIndex: "CollectionID",
      width: 107,
    },
    {
      header: "Name",
      text: `<span title='Name'>Name</span>`,
      sortable: true,
      searchable: true,
      hideable: false,
      dataIndex: "Name",
      width: 330,
    },
    {
      header: "Total (MB)",
      text: `<span title='Total (MB)'>Total (MB)</span>`,
      sortDir: "ASC",
      sortType: "asInt",
      sortable: true,
      type: "float",
      searchable: true,
      hideable: false,
      align: "right",
      dataIndex: "Total",
      width: 110,
      renderer: Ext.util.Format.numberRenderer("0,000.00"),
    },
    {
      header: "Database (MB)",
      text: `<span title='Database (MB)'>Database (MB)</span>`,
      sortDir: "ASC",
      sortType: "asInt",
      sortable: true,
      type: "float",
      searchable: true,
      hideable: false,
      align: "right",
      dataIndex: "Database",
      width: 144,
      renderer: Ext.util.Format.numberRenderer("0,000.00"),
    },
    {
      header: "AgentDefinitions (MB)",
      text: `<span title='AgentDefinitions (MB)'>AgentDefinitions (MB)</span>`,
      sortDir: "ASC",
      sortType: "asInt",
      sortable: true,
      type: "float",
      searchable: true,
      hideable: false,
      align: "right",
      dataIndex: "AgentDefinitions",
      width: 191,
      renderer: Ext.util.Format.numberRenderer("0,000.00"),
    },
    {
      header: "Files (MB)",
      text: `<span title='Files (MB)'>Files (MB)</span>`,
      sortDir: "ASC",
      sortType: "asInt",
      sortable: true,
      type: "float",
      searchable: true,
      hideable: false,
      align: "right",
      dataIndex: "Files",
      width: 115,
      renderer: Ext.util.Format.numberRenderer("0,000.00"),
    },
    {
      header: "Packages (MB)",
      text: `<span title='Packages (MB)'>Packages (MB)</span>`,
      sortDir: "ASC",
      sortType: "asInt",
      sortable: true,
      type: "float",
      searchable: true,
      hideable: false,
      align: "right",
      dataIndex: "FilePackages",
      width: 141,
      renderer: Ext.util.Format.numberRenderer("0,000.00"),
    },
  ];
}

function prepareGridColumns(data) {
  return data.ViewFields.map((field) => {
    let fieldText = field.HeaderText ? field.HeaderText : field.Name;
    let fieldIndex =
      field.HeaderText === "Size (GB)" || field.HeaderText === "Day"
        ? field.HeaderText
        : field.Name;
    return {
      dataIndex: fieldIndex,
      text: `<span title='${fieldText}'>${fieldText}</span>`,
      locked: field.IsSticky,
      hidden: field.IsHidden,
      width: field.ColumnWidth,
      cell: {
        height: 46,
        encodeHtml: true,
      },
    };
  });
}

function* createDetailsStore(storeId, params) {
  const {
    Command,
    overrideViewName,
    MaxDay,
    CollectionID,
    AccountKey,
  } = params;
  const store = Ext.getStore(storeId);

  if (store) {
    const model = Ext.create("StorageDetailsModel");
    store.removeAll(true);
    store.setModel(model);

    store.load({
      params: {
        AccountKey,
        CollectionID,
        MaxDay,
        overrideViewName,
        Command,
      },
    });
  }

  const columns = prepareGridDetailsColumns();

  yield put({
    type: SET_ACCOUNT_STORAGE_USAGE_DATA,
    payload: {
      columns,
    },
  });
}

function* fetchAccountStorageDetails({ storeId, formParams = {} }) {
  const params = {
    Command: CONSTANTS.COMMANDS.STORAGE_GETUSAGE,
    CollectionID: CONSTANTS.COLLECTIONS.GROUP.STORAGE_DETAILS,
    overrideViewName: "Month",
    ...formParams,
  };

  try {
    yield call(createDetailsStore, storeId, params);

    const columns = prepareGridDetailsColumns();
    yield put({
      type: SET_ACCOUNT_STORAGE_USAGE_DATA,
      payload: {
        columns,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function prepareModel(data, store) {
  const model = store.config.model;

  model.setFields(data);

  return model;
}

function* createStore(storeId, data, extraParams = {}) {
  const store = Ext.getStore(storeId);

  if (store) {
    const model = prepareModel(data, store);

    const {
      SearchText,
      SearchField,
      PageItemCount,
      PageIndex,
    } = data.CollectionState;

    store.removeAll(true);
    store.setModel(model);
    store.setPageSize(PageItemCount);
    store.clearFilter(true);

    store.loadPage(PageIndex, {
      params: {
        Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
        CollectionID: CONSTANTS.COLLECTIONS.GROUP.STORAGE_SUMMARY,
        ViewID: data.ViewRow.ViewID,
        SearchText,
        SearchField,
        ...extraParams,
      },
    });
  }

  const columns = prepareGridColumns(data);

  yield put({
    type: SET_ACCOUNT_STORAGE_DATA,
    payload: {
      columns,
      sortedBy: data.ViewSorting,
      viewRow: data.ViewRow,
      collectionState: data.CollectionState,
      viewFields: data.ViewFields,
      refreshed: data.Collection.Refreshed,
    },
  });
}

function* fetchAccountStorageData({ type: action, storeId, formParams = {} }) {
  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETCOMPLETE,
    CollectionID: CONSTANTS.COLLECTIONS.GROUP.STORAGE_SUMMARY,
    OverrideViewName: "Month",
    HideSystemViewNames: "All Segments",
    ...formParams,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    const extraParams = {
      OverrideViewName: "Month",
      HideSystemViewNames: "All Segments",
      ColumnsToAlwaysInclude: "Refreshed,MaxDay",
    };

    yield call(createStore, storeId, data, extraParams);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getSavedViews({ type: action }) {
  const params = {
    AccountKey: "",
    CollectionID: CONSTANTS.COLLECTIONS.GROUP.STORAGE_SUMMARY,
    Command: CONSTANTS.COMMANDS.COLLECTION_GETVIEWS,
  };

  try {
    const result = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETVIEWS,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (result.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        result.data.JsonResult.Result,
        result.data.JsonResult.ErrorDescription,
        result.data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    const savedViews = [];

    result.data.ViewTable.forEach((view) => {
      let savedView = {};
      for (let key in view) {
        if (CONSTANTS.RESPONSE_CONTEXT.SAVED_VIEWS_CONTEXT.includes(key)) {
          savedView[key] = view[key];
        }
      }
      savedViews.push(savedView);
    });

    yield put({
      type: SET_ACCOUNT_STORAGE_SAVED_VIEWS,
      payload: {
        savedViews,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* fetchStorageSummaryDownloadCollection({
  type: action,
  formParams,
  fileFormat,
}) {
  const reduxState = yield select();

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETVIEWS,
      new URLSearchParams({
        Command: CONSTANTS.COMMANDS.STORAGE_DOWNLOADCOLLECTIONSTORAGE,
        AccountKey: reduxState.account.accountInfo.Account.AccountKey,
        CameFromPublisher: false,
      })
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    const accountDownloadStorage = yield axios.post(
      CONSTANTS.ROUTES.STORAGE_DOWNLOADCOLLECTIONSTORAGE,
      new URLSearchParams(formParams),
      {
        headers: {
          action,
        },
      }
    );

    const exportResponse = yield axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/export`,
      params: {
        BaseFileName: accountDownloadStorage.data.TempFile,
        UserFileName: `${accountDownloadStorage.data.FileName}.${fileFormat}`,
        Method: "SpecificFile",
      },
      responseType: "blob",
    });

    const filename = `${accountDownloadStorage.data.FileName}.${fileFormat}`;
    fileDownload(exportResponse.data, filename);

    yield put({
      type: SET_ACCOUNT_STORAGE_SUMMARY_DOWNLOAD_COLLECTION,
      payload: {
        data,
        accountDownloadStorage,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* fetchStorageDownloadCollectionDetails({
  type: action,
  formParams,
  fileFormat,
}) {
  const reduxState = yield select();

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETVIEWS,
      new URLSearchParams({
        Command: CONSTANTS.COMMANDS.STORAGE_DOWNLOADCOLLECTIONSTORAGE,
        AccountKey: reduxState.account.accountInfo.Account.AccountKey,
        CameFromPublisher: false,
      })
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    const accountDownloadStorage = yield axios.post(
      CONSTANTS.ROUTES.STORAGE_DOWNLOADCOLLECTIONSTORAGE,
      new URLSearchParams(formParams),
      {
        headers: {
          action,
        },
      }
    );

    if (
      accountDownloadStorage.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG
    ) {
      yield cancel();
    }

    const exportResponse = yield axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/export`,
      params: {
        BaseFileName: accountDownloadStorage.data.TempFile,
        UserFileName: `${accountDownloadStorage.data.FileName}.${fileFormat}`,
        Method: "SpecificFile",
      },
      responseType: "blob",
    });

    const filename = `${accountDownloadStorage.data.FileName}.${fileFormat}`;
    fileDownload(exportResponse.data, filename);

    yield put({
      type: SET_ACCOUNT_STORAGE_DOWNLOAD_COLLECTION_DETAILS,
      payload: {
        data,
        accountDownloadStorage,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function prepareNotificationsColumns() {
  return [
    {
      dataIndex: "Created",
      text: "<span title='Created'>Created</span>",
      locked: true,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 75,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "TemplateName",
      text: "<span title='TemplateName'>TemplateName</span>",
      locked: true,
      menuDisabled: true,
      resizable: true,
      searchable: true,
      width: 220,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "Email",
      text: "<span title='Email'>Email</span>",
      locked: false,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 125,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "Subject",
      text: "<span title='Subject'>Subject</span>",
      locked: false,
      menuDisabled: true,
      resizable: true,
      searchable: true,
      width: 116,
      cell: {
        height: 50,
        encodeHtml: true,
        xtype: "reactcell",
      },
    },
    {
      dataIndex: "Body",
      text: "<span title='Body'>Body</span>",
      locked: false,
      menuDisabled: true,
      resizable: true,
      searchable: true,
      width: 387,
      cell: {
        height: 50,
        encodeHtml: true,
        xtype: "reactcell",
      },
    },
  ];
}

function* createViewNotificationsStore(storeId, data) {
  const reduxState = yield select();
  const params = {
    AddSummaryRow: "",
    Command: "View.GetItems",
    CollectionID: COMMON_COLLECTIONS.NOTIFICATIONS_LOGS,
    ViewID: data.ViewRow.ViewID,
    OverrideViewName: "All Storage Notifications",
    FolderID: "",
    AccountKey: reduxState.account.accountInfo.Account.AccountKey,
    AgentID: 0,
    BookmarkID: 0,
    JobID: -1,
    ColumnsToAlwaysInclude: "Name",
    SearchText: data.CollectionState.SearchText,
    SearchField: data.CollectionState.SearchField,
    ShowMarkup: false,
    page: data.CollectionState.PageIndex,
    start: 0,
    limit: data.CollectionState.PageItemCount,
  };

  const store = Ext.getStore(storeId);

  if (store) {
    store.removeAll(true);

    store.load({
      params,
    });
  }
}

function* viewNotifications({ storeId }) {
  const reduxState = yield select();
  const params = {
    CollectionID: COMMON_COLLECTIONS.NOTIFICATIONS_LOGS,
    FolderID: "",
    AccountKey: reduxState.account.accountInfo.Account.AccountKey,
    ViewID: "",
    OverrideViewName: "All Storage Notifications",
    HideSystemViewNames: "",
    Command: COMMANDS.COLLECTION_GETCOMPLETE,
  };

  const { data } = yield axios.post(
    CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
    new URLSearchParams(params)
  );

  if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
    yield cancel();
  }

  const columns = prepareNotificationsColumns();

  yield put({
    type: SET_VIEW_NOTIFICATIONS,
    columns,
    collectionState: data.CollectionState,
    viewRow: data.ViewRow,
    sortedBy: data.ViewSorting,
    viewFields: data.ViewFields,
  });

  yield call(createViewNotificationsStore, storeId, data);
}

export default function* accountStorageWatcher() {
  yield takeLatest(GET_ACCOUNT_STORAGE_DATA, fetchAccountStorageData);
  yield takeLatest(GET_ACCOUNT_STORAGE_USAGE_DATA, fetchAccountStorageDetails);
  yield takeLatest(GET_ACCOUNT_STORAGE_SAVED_VIEWS, getSavedViews);
  yield takeLatest(VIEW_NOTIFICATIONS, viewNotifications);
  yield takeLatest(
    GET_ACCOUNT_STORAGE_SUMMARY_DOWNLOAD_COLLECTION,
    fetchStorageSummaryDownloadCollection
  );
  yield takeLatest(
    GET_ACCOUNT_STORAGE_DOWNLOAD_COLLECTION_DETAILS,
    fetchStorageDownloadCollectionDetails
  );
}
