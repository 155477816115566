export const GET_ACCOUNT = "GET_ACCOUNT";
export const SET_ACCOUNT = "SET_ACCOUNT";
export const LOGIN = "LOGIN";
export const SESSION_EXPIRED = "SESSION_EXPIRED";
export const MAINTENANCE_MODE = "MAINTENANCE_MODE";
export const SET_SESSION_EXPIRED = "SET_SESSION_EXPIRED";
export const ACCOUNT_ADDREMOVEGROUPS = "ACCOUNT_ADDREMOVEGROUPS";
export const FIND_ACCOUNTBYEMAIL = "FIND_ACCOUNTBYEMAIL";
export const GET_AGENT_GROUPS = "GET_AGENT_GROUPS";
export const SET_AGENT_GROUPS = "SET_AGENT_GROUPS";
export const SET_ASSIGN_AGENTGROUP_VIEWS = "SET_ASSIGN_AGENTGROUP_VIEWS";
export const GET_ASSIGN_AGENTGROUP_VIEWS = "GET_ASSIGN_AGENTGROUP_VIEWS";
export const GET_BETA_SETTINGS = "GET_BETA_SETTINGS";
export const SET_BETA_SETTINGS = "SET_BETA_SETTINGS";
export const CHANGE_ACCOUNT = "CHANGE_ACCOUNT";
export const GET_TIMEZONES = "GET_TIMEZONES";
export const SET_TIMEZONES = "SET_TIMEZONES";
export const GET_WEB_LOCATIONS = "GET_WEB_LOCATIONS";
export const SET_COMMON_WEBLOCATIONS = "SET_COMMON_WEBLOCATIONS";
export const UPDATE_BILLING_INVOICE_RECIPIENTS =
  "UPDATE_BILLING_INVOICE_RECIPIENTS";
export const GET_UPDATE_BILLING_INVOICE_RECIPIENTS =
  "GET_UPDATE_BILLING_INVOICE_RECIPIENTS";
export const BILLING_EMAILS_UPDATED = "BILLING_EMAILS_UPDATED";
export const SYNC_JOB = "SYNC_JOB";
export const UPDATE_NOTIFICATION_STATE_SEARCH =
  "UPDATE_NOTIFICATION_STATE_SEARCH";
export const SET_EXISTING_USERS = "SET_EXISTING_USERS";
export const ADD_EXISTING_USER = "ADD_EXISTING_USER";

export const GET_RECENT_ITEMS = "GET_RECENT_ITEMS";
export const SET_RECENT_ITEMS = "SET_RECENT_ITEMS";
export const UPDATE_RECENT_ITEMS = "UPDATE_RECENT_ITEMS";

export const GET_AGENTS = "GET_AGENTS";
export const GET_ALL_AGENTS = "GET_ALL_AGENTS";
export const GET_AGENTS_STATUS_OVERVIEW = "GET_AGENTS_STATUS_OVERVIEW";
export const SET_STATUS_COUNT = "SET_STATUS_COUNT";
export const SET_ALL_AGENTS = "SET_ALL_AGENTS";

export const GET_AGENTS_LIST = "GET_AGENTS_LIST";
export const SET_AGENTS_LIST = "SET_AGENTS_LIST";
export const GET_AGENTS_CUSTOM_FIELDS = "GET_AGENTS_CUSTOM_FIELDS";
export const SET_AGENTS_CUSTOM_FIELDS = "SET_AGENTS_CUSTOM_FIELDS";
export const AGENT_UPDATE = "AGENT_UPDATE";
export const AGENT_UPDATE_STATUS = "AGENT_UPDATE_STATUS";
export const FILTER_AGENTS = "FILTER_AGENTS";
export const FILTER_AGENT_DATA = "FILTER_AGENT_DATA";
export const GET_AGENTS_FIELDS = "GET_AGENTS_FIELDS";
export const SET_AGENTS_FIELDS = "SET_AGENTS_FIELDS";
export const AGENT_DATA_UPDATEVIEWTABLE = "AGENT_DATA_UPDATEVIEWTABLE";
export const AGENTS_UPDATEVIEWTABLE = "AGENTS_UPDATEVIEWTABLE";
export const AGENT_JOBS_UPDATEVIEWTABLE = "AGENT_JOBS_UPDATEVIEWTABLE";
export const AGENT_HISTORY_UPDATEVIEWTABLE = "AGENT_HISTORY_UPDATEVIEWTABLE";
export const UPDATE_AGENTS_CUSTOM_FIELDS = "UPDATE_AGENTS_CUSTOM_FIELDS";
export const VIEW_NOTIFICATIONS = "VIEW_NOTIFICATIONS";
export const SET_VIEW_NOTIFICATIONS = "SET_VIEW_NOTIFICATIONS";

export const SET_AGENT_JOB_STATUS = "SET_AGENT_JOB_STATUS";

export const GET_AGENT_DATA = "GET_AGENT_DATA";
export const SET_AGENT_DATA = "SET_AGENT_DATA";
export const AGENT_INFO_LOADED = "AGENT_INFO_LOADED";
export const ITEM_FILTER_AGENT_DATA = "ITEM_FILTER_AGENT_DATA";
export const REMOVE_FILTERS = "REMOVE_FILTERS";
export const GET_AGENTS_SAVED_VIEWS = "GET_AGENTS_SAVED_VIEWS";
export const SET_AGENTS_SAVED_VIEWS = "SET_AGENTS_SAVED_VIEWS";
export const GET_AGENT_DATA_SAVED_VIEWS = "GET_AGENT_DATA_SAVED_VIEWS";
export const SET_AGENT_DATA_SAVED_VIEWS = "SET_AGENT_DATA_SAVED_VIEWS";
export const CLEANUP_AGENT_DATA = "CLEANUP_AGENT_DATA";
export const GET_AGENT_INFO = "GET_AGENT_INFO";
export const SET_AGENT_INFO = "SET_AGENT_INFO";
export const GET_AGENT_JOBS = "GET_AGENT_JOBS";
export const SET_AGENT_JOBS = "SET_AGENT_JOBS";
export const SET_AGENT_JOB_STATISTICS = "SET_AGENT_JOB_STATISTICS";
export const SET_IMAGES_AND_FILES = "SET_IMAGES_AND_FILES";
export const GET_IMAGES_AND_FILES = "GET_IMAGES_AND_FILES";
export const GET_JOB_STATISTICS = "GET_JOB_STATISTICS";
export const SET_AGENT_JOB_DETAILS = "SET_AGENT_JOB_DETAILS";
export const GET_AGENT_FIELDS = "GET_AGENT_FIELDS";
export const SET_AGENT_FIELDS = "SET_AGENT_FIELDS";
export const GET_AGENT_HISTORY = "GET_AGENT_HISTORY";
export const AGENT_GET_JOB_STATUS = "AGENT_GET_JOB_STATUS";
export const AGENT_SET_JOB_STATUS = "AGENT_SET_JOB_STATUS";
export const AGENT_SET_JOB_STATUS_SESSION_EXPIRED =
  "AGENT_SET_JOB_STATUS_SESSION_EXPIRED";
export const SET_AGENT_HISTORY = "SET_AGENT_HISTORY";
export const SET_AGENT_HISTORY_ITEMS = "SET_AGENT_HISTORY_ITEMS";
export const GET_ITEM_HISTORY = "GET_ITEM_HISTORY";
export const SET_ITEM_HISTORY = "SET_ITEM_HISTORY";
export const GET_AGENT_INSIGHTS = "GET_AGENT_INSIGHTS";
export const GET_JOB_VIEWS = "GET_JOB_VIEWS";
export const SET_AGENT_INSIGHTS = "SET_AGENT_INSIGHTS";
export const GET_AGENT_VIEW = "GET_AGENT_VIEW";
export const UPDATE_AGENTS_STATE_SEARCH = "UPDATE_AGENTS_STATE_SEARCH";
export const UPDATE_AGENTS_DATA_STATE_SEARCH =
  "UPDATE_AGENTS_DATA_STATE_SEARCH";
export const UPDATE_AGENTS_JOBS_STATE_SEARCH =
  "UPDATE_AGENTS_JOBS_STATE_SEARCH";
export const UPDATE_AGENTS_HISTORY_STATE_SEARCH =
  "UPDATE_AGENTS_HISTORY_STATE_SEARCH";
export const UPDATE_AGENTS_INSIGHTS_STATE_SEARCH =
  "UPDATE_AGENTS_INSIGHTS_STATE_SEARCH";
export const SAVED_VIEWS_AGENTS_CHANGED = "SAVED_VIEWS_AGENTS_CHANGED";
export const SAVED_VIEWS_AGENTS_DATA_CHANGED =
  "SAVED_VIEWS_AGENTS_DATA_CHANGED";
export const SAVED_VIEWS_AGENTS_JOBS_CHANGED =
  "SAVED_VIEWS_AGENTS_JOBS_CHANGED";
export const SET_AGENTS_JOBS_SAVED_VIEWS = "SET_AGENTS_JOBS_SAVED_VIEWS";
export const SAVED_VIEWS_AGENTS_HISTORY_CHANGED =
  "SAVED_VIEWS_AGENTS_HISTORY_CHANGED";
export const SET_AGENTS_HISTORY_SAVED_VIEWS = "SET_AGENTS_HISTORY_SAVED_VIEWS";
export const GET_AGENT_HISTORY_SAVED_VIEWS = "GET_AGENT_HISTORY_SAVED_VIEWS";
export const GET_AGENT_HISTORY_BOOKMARKS = "GET_AGENT_HISTORY_BOOKMARKS";
export const SET_AGENT_HISTORY_BOOKMARKS = "SET_AGENT_HISTORY_BOOKMARKS";
export const GET_AGENT_INSIGHTS_SAVED_VIEWS = "GET_AGENT_INSIGHTS_SAVED_VIEWS";
export const SET_AGENTS_INSIGHTS_SAVED_VIEWS =
  "SET_AGENTS_INSIGHTS_SAVED_VIEWS";
export const SAVED_VIEWS_AGENTS_INSIGHTS_CHANGED =
  "SAVED_VIEWS_AGENTS_INSIGHTS_CHANGED";
export const AGENT_INSIGHTS_UPDATE_STATEVIEW =
  "AGENT_INSIGHTS_UPDATE_STATEVIEW";
export const GET_ASSIGN_AGENTS_FIELDS = "GET_ASSIGN_AGENTS_FIELDS";
export const SAVED_VIEW_ASSIGN_AGENTGROUP_AGENTS =
  "SAVED_VIEW_ASSIGN_AGENTGROUP_AGENTS";
export const UPDATE_AGENT_DATA_BOOKMARK_ID = "UPDATE_AGENT_DATA_BOOKMARK_ID";
export const DELETE_AGENTS_SAVED_VIEW = "DELETE_AGENTS_SAVED_VIEW";
export const AGENTS_DEFAULTS_GET = "AGENTS_DEFAULTS_GET";
export const SET_AGENTS_DEFAULT = "SET_AGENTS_DEFAULT";

export const GET_AGENT_GROUPS_DATA_SAVED_VIEWS =
  "GET_AGENT_GROUPS_DATA_SAVED_VIEWS";
export const SAVED_VIEWS_AGENT_GROUPS_DATA_CHANGED =
  "SAVED_VIEWS_AGENT_GROUPS_DATA_CHANGED";
export const GET_AGENTGROUPS_AGENT_INFO = "GET_AGENTGROUPS_AGENT_INFO";
export const SET_AGENTGROUPS_AGENT_INFO = "SET_AGENTGROUPS_AGENT_INFO";
export const AGENTGROUPS_AGENT_INFO_LOADED = "AGENTGROUPS_AGENT_INFO_LOADED";
export const GET_AGENTGROUPS_AGENTS = "GET_AGENTGROUPS_AGENTS";
export const SET_AGENTGROUPS_AGENTS = "SET_AGENTGROUPS_AGENTS";
export const GET_AGENTGROUPS_DATA = "GET_AGENTGROUPS_DATA";
export const SET_AGENTGROUPS_DATA = "SET_AGENTGROUPS_DATA";
export const GET_AGENTGROUPS_FIELDS = "GET_AGENTGROUPS_FIELDS";
export const SET_AGENTGROUPS_FIELDS = "SET_AGENTGROUPS_FIELDS";
export const GET_AGENTGROUPS_SUMMARY = "GET_AGENTGROUPS_SUMMARY";
export const SET_AGENTGROUPS_SUMMARY = "SET_AGENTGROUPS_SUMMARY";
export const UPDATE_AGENT_GROUP_AGENTS_STATE_SEARCH =
  "UPDATE_AGENT_GROUP_AGENTS_STATE_SEARCH";
export const UPDATE_AGENTGROUPS_DATA_SEARCH = "UPDATE_AGENTGROUPS_DATA_SEARCH";
export const ITEM_FILTER_AGENT_GROUP_DATA = "ITEM_FILTER_AGENT_GROUP_DATA";
export const SET_AGENT_GROUPS_DATA_SAVED_VIEWS =
  "SET_AGENT_GROUPS_DATA_SAVED_VIEWS";
export const SET_AGENT_GROUPS_SAVED_VIEWS = "SET_AGENT_GROUPS_SAVED_VIEWS";
export const GET_AGENT_GROUPS_SAVED_VIEWS = "GET_AGENT_GROUPS_SAVED_VIEWS";
export const SAVED_VIEWS_AGENTS_GROUP_CHANGED =
  "SAVED_VIEWS_AGENTS_GROUP_CHANGED";
export const SET_ASSIGN_AGENTS_FIELDS = "SET_ASSIGN_AGENTS_FIELDS";
export const SET_ASSIGN_AGENTGROUP_AGENTS = "SET_ASSIGN_AGENTGROUP_AGENTS";
export const AGENTGROUP_DATA_UPDATEVIEWTABLE =
  "AGENTGROUP_DATA_UPDATEVIEWTABLE";
export const UPDATE_AGENTSGROUP_DATA_VIEW_ROW =
  "UPDATE_AGENTSGROUP_DATA_VIEW_ROW";
export const AGENTGROUPS_DATA_UPDATEVIEWTABLE =
  "AGENTGROUP_DATA_UPDATEVIEWTABLE";
export const DELETE_AGENTSGROUP_DATA_VIEW_ROW =
  "DELETE_AGENTSGROUP_DATA_VIEW_ROW";
export const AGENTGROUP_AGENTS_UPDATEVIEWTABLE =
  "AGENTGROUP_AGENTS_UPDATEVIEWTABLE";
export const NEW_AGENT_GROUP_FROM_SCRATCH = "NEW_AGENT_GROUP_FROM_SCRATCH";
export const SEQUENCE_GET = "SEQUENCE_GET";
export const GET_STEP_INFO = "GET_STEP_INFO";
export const SEQUENCE_GET_STEP_INFO = "SEQUENCE_GET_STEP_INFO";
export const GET_SEQUENCE_AGENT = "GET_SEQUENCE_AGENT";
export const SEQUENCE_UPDATE = "SEQUENCE_UPDATE";
export const GET_SEQUENCES_LIST = "GET_SEQUENCES_LIST";
export const SET_SEQUENCES_LIST = "SET_SEQUENCES_LIST";
export const GET_SEQUENCES_VIEWS = "GET_SEQUENCES_VIEWS";
export const SET_SEQUENCES_VIEWS = "SET_SEQUENCES_VIEWS";
export const GET_SEQUENCE_STEPS = "GET_SEQUENCE_STEPS";
export const SET_SEQUENCE_STEPS = "SET_SEQUENCE_STEPS";
export const GET_SEQUENCE_INFO = "GET_SEQUENCE_INFO";
export const SET_SEQUENCE_INFO = "SET_SEQUENCE_INFO";
export const FILTER_SEQUENCES = "FILTER_SEQUENCES";
export const FILTER_SEQUENCE_STEPS = "FILTER_SEQUENCE_STEPS";
export const CLEAR_SEQUENCE_VIEWID = "CLEAR_SEQUENCE_VIEWID";
export const CHANGED_SEQUENCES_VIEW = "CHANGED_SEQUENCES_VIEW";
export const UPDATE_SEQUENCES_STATE_SEARCH = "UPDATE_SEQUENCES_STATE_SEARCH";
export const UPDATE_SEQUENCES_STEPS_STATE_SEARCH =
  "UPDATE_SEQUENCES_STEPS_STATE_SEARCH";
export const GET_SEQUENCE_ERROR_CODES = "GET_SEQUENCE_ERROR_CODES";
export const SET_SEQUENCE_ERROR_CODES = "SET_SEQUENCE_ERROR_CODES";
export const SEQUENCE_UPDATE_ADD_STEP = "SEQUENCE_UPDATE_ADD_STEP";
export const GET_SEQUENCE_AGENT_INFO = "GET_SEQUENCE_AGENT_INFO";
export const SET_SEQUENCE_AGENT_INFO = "SET_SEQUENCE_AGENT_INFO";
export const GET_SEQUENCE_CLEAR_COLLECTION = "GET_SEQUENCE_CLEAR_COLLECTION";
export const SET_SEQUENCE_CLEAR_COLLECTION = "SET_SEQUENCE_CLEAR_COLLECTION";
export const GET_SEQUENCE_COLLECTION_VIEWS = "GET_SEQUENCE_COLLECTION_VIEWS";
export const SET_SEQUENCE_COLLECTIONS_SAVED_VIEWS =
  "SET_SEQUENCE_COLLECTIONS_SAVED_VIEWS";
export const SEQUENCE_UPDATE_ADD_STEP_CLEAR_COLLECTION =
  "SEQUENCE_UPDATE_ADD_STEP_CLEAR_COLLECTION";
export const GET_SEQUENCE_STATUS = "GET_SEQUENCE_STATUS";
export const SET_SEQUENCE_STATUS = "SET_SEQUENCE_STATUS";
export const RUN_SEQUENCE = "RUN_SEQUENCE";
export const RUN_SEQUENCE_ACTION = "RUN_SEQUENCE_ACTION";
export const SEQUENCE_RUN_ERROR = "SEQUENCE_RUN_ERROR";
export const PAUSE_SEQUENCE = "PAUSE_SEQUENCE";
export const RESUME_SEQUENCE = "RESUME_SEQUENCE";
export const CREATE_NEW_SEQUENCE = "CREATE_NEW_SEQUENCE";
export const CANCEL_SEQUENCE = "CANCEL_SEQUENCE";
export const SEQUENCE_RUN_LOADING = "SEQUENCE_RUN_LOADING";
export const SEQUENCE_RUN_FINISHED_LOADING = "SEQUENCE_RUN_FINISHED_LOADING";
export const REMOVE_SEQUENCE_LOADING = "REMOVE_SEQUENCE_LOADING";
export const REMOVE_SEQUENCE_ERROR = "REMOVE_SEQUENCE_ERROR";
export const SET_STEPS_TO_RUN_CONCURRENTLY = "SET_STEPS_TO_RUN_CONCURRENTLY";
export const ADD_STEP_TO_EXISTING_GROUP = "ADD_STEP_TO_EXISTING_GROUP";
export const RUN_SELECTED_STEPS_CONCURRENTLY =
  "RUN_SELECTED_STEPS_CONCURRENTLY";
export const REMOVE_SELECTED_STEPS_FROM_GROUP =
  "REMOVE_SELECTED_STEPS_FROM_GROUP";
export const START_AT_SELECTED_GROUP = "START_AT_SELECTED_GROUP";
export const GET_DELETE_VIEW_ITEMS = "GET_DELETE_VIEW_ITEMS";
export const SET_DELETE_VIEW_ITEMS = "SET_DELETE_VIEW_ITEMS";
export const DELETE_VIEW_ITEM = "DELETE_VIEW_ITEM";
export const GET_SEQUENCE_STEPS_FIELDS = "GET_SEQUENCE_STEPS_FIELDS";
export const SET_SEQUENCE_STEPS_FIELDS = "SET_SEQUENCE_STEPS_FIELDS";
export const UPDATE_FIELD_DATA = "UPDATE_FIELD_DATA";
export const GET_SEQUENCES_TO_SELECT = "GET_SEQUENCES_TO_SELECT";
export const SET_SEQUENCES_TO_SELECT = "SET_SEQUENCES_TO_SELECT";
export const SET_SEQUENCES_TO_SELECT_VIEWS = "SET_SEQUENCES_TO_SELECT_VIEWS";
export const GET_SEQUENCES_TO_SELECT_VIEWS = "GET_SEQUENCES_TO_SELECT_VIEWS";
export const UPDATE_SEQUENCES_TO_SELECT_SEARCH =
  "UPDATE_SEQUENCES_TO_SELECT_SEARCH";
export const RUN_SEQUENCE_STEP = "RUN_SEQUENCE_STEP";
export const GET_ALL_SEQUENCE_STEPS = "GET_ALL_SEQUENCE_STEPS";
export const ALL_SEQUENCES = "ALL__SEQUENCES";

export const GET_DEPARTMENTS = "GET_DEPARTMENTS";
export const SET_DEPARTMENTS_LIST = "SET_DEPARTMENTS_LIST";
export const DELETE_DEPARTMENTS = "DELETE_DEPARTMENTS";
export const GET_DEPARTMENT_BILLING_DATA = "GET_DEPARTMENT_BILLING_DATA";
export const SET_DEPARTMENT_BILLING_DATA = "SET_DEPARTMENT_BILLING_DATA";
export const GET_DEPARTMENT_BILLING_INVOICE = "GET_DEPARTMENT_BILLING_INVOICE";
export const GET_DEPARTMENT_BILLING_DOWNLOAD_INVOICE_LIST =
  "GET_DEPARTMENT_BILLING_DOWNLOAD_INVOICE_LIST";
export const SET_DEPARTMENT_BILLING_INVOICE = "SET_DEPARTMENT_BILLING_INVOICE";
export const SET_DEPARTMENT_BILLING_DOWNLOAD_INVOICE_LIST =
  "SET_DEPARTMENT_BILLING_DOWNLOAD_INVOICE_LIST";
export const GET_DEPARTMENT_STORAGE_DATA = "GET_DEPARTMENT_STORAGE_DATA";
export const SET_DEPARTMENT_STORAGE_DATA = "SET_DEPARTMENT_STORAGE_DATA";
export const GET_DEPARTMENT_STORAGE_SAVED_VIEWS =
  "GET_DEPARTMENT_STORAGE_SAVED_VIEWS";
export const SET_DEPARTMENT_STORAGE_SAVED_VIEWS =
  "SET_DEPARTMENT_STORAGE_SAVED_VIEWS";
export const GET_DEPARTMENT_STORAGE_DETAILS = "GET_DEPARTMENT_STORAGE_DETAILS";
export const SET_DEPARTMENT_STORAGE_DETAILS = "SET_DEPARTMENT_STORAGE_DETAILS";
export const GET_DEPARTMENT_STORAGE_USAGE_DATA =
  "GET_DEPARTMENT_STORAGE_USAGE_DATA";
export const SET_DEPARTMENT_STORAGE_USAGE_DATA =
  "SET_DEPARTMENT_STORAGE_USAGE_DATA";
export const GET_DEPARTMENT_STORAGE_DOWNLOAD_COLLECTION_STORAGE_SUMMARY =
  "GET_DEPARTMENT_STORAGE_DOWNLOAD_COLLECTION_STORAGE_SUMMARY";
export const SET_DEPARTMENT_STORAGE_DOWNLOAD_COLLECTION_STORAGE_SUMMARY =
  "SET_DEPARTMENT_STORAGE_DOWNLOAD_COLLECTION_STORAGE_SUMMARY";
export const GET_DEPARTMENT_STORAGE_DOWNLOAD_COLLECTION_STORAGE =
  "GET_DEPARTMENT_STORAGE_DOWNLOAD_COLLECTION_STORAGE";
export const SET_DEPARTMENT_STORAGE_DOWNLOAD_COLLECTION_STORAGE =
  "SET_DEPARTMENT_STORAGE_DOWNLOAD_COLLECTION_STORAGE";
export const GET_DEPARTMENT_INSIGHTS = "GET_DEPARTMENT_INSIGHTS";
export const SET_DEPARTMENT_INSIGHTS = "SET_DEPARTMENT_INSIGHTS";
export const GET_DEPARTMENT_INSIGHTS_SAVED_VIEWS =
  "GET_DEPARTMENT_INSIGHTS_SAVED_VIEWS";
export const SET_DEPARTMENT_INSIGHTS_SAVED_VIEWS =
  "SET_DEPARTMENT_INSIGHTS_SAVED_VIEWS";
export const DEPARTMENT_INSIGHTS_UPDATE_STATEVIEW =
  "DEPARTMENT_INSIGHTS_UPDATE_STATEVIEW";
export const GET_DEPARTMENT_JOBS = "GET_DEPARTMENT_JOBS";
export const SET_DEPARTMENT_JOBS = "SET_DEPARTMENT_JOBS";
export const GET_DEPARTMENT_JOB_DETAILS = "GET_DEPARTMENT_JOB_DETAILS";
export const SET_DEPARTMENT_JOB_DETAILS = "SET_DEPARTMENT_JOB_DETAILS";
export const GET_DEPARTMENT_JOB_LIST = "GET_DEPARTMENT_JOB_LIST";
export const SET_DEPARTMENT_JOB_LIST = "SET_DEPARTMENT_JOB_LIST";
export const GET_DEPARTMENT_USERS = "GET_DEPARTMENT_USERS";
export const SET_DEPARTMENT_USERS = "SET_DEPARTMENT_USERS";
export const USER_GET_ADMIN_EMAILS = "USER_GET_ADMIN_EMAILS";
export const USER_SET_ADMIN_EMAILS = "USER_SET_ADMIN_EMAILS";
export const GET_DEPARTMENT_UTILIZATION_PERIOD =
  "GET_DEPARTMENT_UTILIZATION_PERIOD";
export const SET_DEPARTMENT_UTILIZATION_PERIOD =
  "SET_DEPARTMENT_UTILIZATION_PERIOD";
export const GET_DEPARTMENT_UTILIZATION_SAVED_VIEWS =
  "GET_DEPARTMENT_UTILIZATION_SAVED_VIEWS";
export const SET_DEPARTMENT_UTILIZATION_SAVED_VIEWS =
  "SET_DEPARTMENT_UTILIZATION_SAVED_VIEWS";
export const GET_DEPARTMENT_UTILIZATION_USAGE =
  "GET_DEPARTMENT_UTILIZATION_USAGE";
export const SET_DEPARTMENT_UTILIZATION_USAGE =
  "SET_DEPARTMENT_UTILIZATION_USAGE";
export const GET_DEPARTMENTS_SAVED_VIEWS = "GET_DEPARTMENTS_SAVED_VIEWS";
export const SET_DEPARTMENTS_SAVED_VIEWS = "SET_DEPARTMENTS_SAVED_VIEWS";
export const GET_DEPARTMENT_USERS_SAVED_VIEWS =
  "GET_DEPARTMENT_USERS_SAVED_VIEWS";
export const SET_DEPARTMENT_USERS_SAVED_VIEWS =
  "SET_DEPARTMENT_USERS_SAVED_VIEWS";
export const SAVED_VIEW_DEPARTMENTS_CHANGED = "SAVED_VIEW_DEPARTMENTS_CHANGED";
export const DEPARTMENT_USERS_SAVED_VIEWS = "DEPARTMENT_USERS_SAVED_VIEWS";
export const UPDATE_DEPARTMENTS_STATE_SEARCH =
  "UPDATE_DEPARTMENTS_STATE_SEARCH";
export const UPDATE_DEPARTMENT_JOBS_STATE_SEARCH =
  "UPDATE_DEPARTMENT_JOBS_STATE_SEARCH";
export const UPDATE_DEPARTMENT_USERS_STATE_SEARCH =
  "UPDATE_DEPARTMENT_USERS_STATE_SEARCH";
export const UPDATE_DEPARTMENT_BILLING_STATE_SEARCH =
  "UPDATE_DEPARTMENT_BILLING_STATE_SEARCH";
export const UPDATE_DEPARTMENT_INSIGHTS_STATE_SEARCH =
  "UPDATE_DEPARTMENT_INSIGHTS_STATE_SEARCH";
export const DEPARTMENTS_USERS_UPDATEVIEWTABLE =
  "DEPARTMENTS_USERS_UPDATEVIEWTABLE";
export const UPDATE_DEPARTMENT_USERS = "UPDATE_DEPARTMENT_USERS";
export const GET_NEW_DEPARTMENT_USERS = "GET_NEW_DEPARTMENT_USERS";
export const SET_NEW_DEPARTMENT_USERS = "SET_NEW_DEPARTMENT_USERS";
export const GET_IMPORT_DEPARTMENT_EXISTING = "GET_IMPORT_DEPARTMENT_EXISTING";
export const SET_IMPORT_DEPARTMENT_EXISTING = "SET_IMPORT_DEPARTMENT_EXISTING";

export const GET_ACCOUNT_BILLING_DATA = "GET_ACCOUNT_BILLING_DATA";
export const SET_ACCOUNT_BILLING_DATA = "SET_ACCOUNT_BILLING_DATA";
export const GET_ACCOUNT_UTILIZATION_DOWNLOAD_AGENT_PAGES =
  "GET_ACCOUNT_UTILIZATION_DOWNLOAD_AGENT_PAGES";
export const SET_ACCOUNT_UTILIZATION_DOWNLOAD_AGENT_PAGES =
  "SET_ACCOUNT_UTILIZATION_DOWNLOAD_AGENT_PAGES";
export const GET_ACCOUNT_BILLING_DOWNLOAD_INVOICE_LIST =
  "GET_ACCOUNT_BILLING_DOWNLOAD_INVOICE_LIST";
export const SET_ACCOUNT_BILLING_DOWNLOAD_INVOICE_LIST =
  "SET_ACCOUNT_BILLING_DOWNLOAD_INVOICE_LIST";
export const GET_ACCOUNT_BILLING_INVOICE = "GET_ACCOUNT_BILLING_INVOICE";
export const SET_ACCOUNT_BILLING_INVOICE = "SET_ACCOUNT_BILLING_INVOICE";
export const GET_EXPORT_PDF = "GET_EXPORT_PDF";
export const GET_DEPARTMENT_EXPORT_PDF = "GET_DEPARTMENT_EXPORT_PDF";
export const GET_ACCOUNT_STORAGE = "GET_ACCOUNT_STORAGE";
export const SET_ACCOUNT_STORAGE = "SET_ACCOUNT_STORAGE";
export const GET_ACCOUNT_STORAGE_SAVED_VIEWS =
  "GET_ACCOUNT_STORAGE_SAVED_VIEWS";
export const SET_ACCOUNT_STORAGE_SAVED_VIEWS =
  "SET_ACCOUNT_STORAGE_SAVED_VIEWS";
export const SET_ACCOUNT_STORAGE_DOWNLOAD_COLLECTION_DETAILS =
  "SET_ACCOUNT_STORAGE_DOWNLOAD_COLLECTION_DETAILS";
export const SET_CURRENT_INVOICE_RECIPIENTS = "SET_CURRENT_INVOICE_RECIPIENTS";
export const GET_ACCOUNT_STORAGE_DOWNLOAD_COLLECTION_DETAILS =
  "GET_ACCOUNT_STORAGE_DOWNLOAD_COLLECTION_DETAILS";
export const GET_ACCOUNT_INSIGHTS = "GET_ACCOUNT_INSIGHTS";
export const SET_ACCOUNT_INSIGHTS = "SET_ACCOUNT_INSIGHTS";
export const GET_ACCOUNT_JOBS = "GET_ACCOUNT_JOBS";
export const SET_ACCOUNT_JOBS = "SET_ACCOUNT_JOBS";
export const GET_ACCOUNT_JOB_DETAILS = "GET_ACCOUNT_JOB_DETAILS";
export const SET_ACCOUNT_JOB_DETAILS = "SET_ACCOUNT_JOB_DETAILS";
export const GET_ACCOUNT_JOB_LIST = "GET_ACCOUNT_JOB_LIST";
export const SET_ACCOUNT_JOB_LIST = "SET_ACCOUNT_JOB_LIST";
export const GET_ACCOUNT_USERS = "GET_ACCOUNT_USERS";
export const SET_ACCOUNT_USERS = "SET_ACCOUNT_USERS";
export const UPDATE_USERS_STATE_SEARCH = "UPDATE_USERS_STATE_SEARCH";
export const GET_ACCOUNT_UTILIZATION_PERIOD = "GET_ACCOUNT_UTILIZATION_PERIOD";
export const SET_ACCOUNT_UTILIZATION_PERIOD = "SET_ACCOUNT_UTILIZATION_PERIOD";
export const GET_ACCOUNT_UTILIZATION_SAVED_VIEWS =
  "GET_ACCOUNT_UTILIZATION_SAVED_VIEWS";
export const SET_ACCOUNT_UTILIZATION_SAVED_VIEWS =
  "SET_ACCOUNT_UTILIZATION_SAVED_VIEWS";
export const GET_ACCOUNT_UTILIZATION_USAGE = "GET_ACCOUNT_UTILIZATION_USAGE";
export const SET_ACCOUNT_UTILIZATION_USAGE = "SET_ACCOUNT_UTILIZATION_USAGE";
export const GET_ACCOUNT_STORAGE_USAGE_DATA = "GET_ACCOUNT_STORAGE_USAGE_DATA";
export const SET_ACCOUNT_STORAGE_USAGE_DATA = "SET_ACCOUNT_STORAGE_USAGE_DATA";
export const GET_ACCOUNT_STORAGE_DATA = "GET_ACCOUNT_STORAGE_DATA";
export const SET_ACCOUNT_STORAGE_DATA = "SET_ACCOUNT_STORAGE_DATA";
export const ACCOUNT_USERS_SAVED_VIEWS = "ACCOUNT_USERS_SAVED_VIEWS";
export const GET_ACCOUNT_USERS_SAVED_VIEWS = "GET_ACCOUNT_USERS_SAVED_VIEWS";
export const SET_ACCOUNT_USERS_SAVED_VIEWS = "SET_ACCOUNT_USERS_SAVED_VIEWS";
export const UPDATE_ACCOUNT_JOBS_STATE_SEARCH =
  "UPDATE_ACCOUNT_JOBS_STATE_SEARCH";
export const UPDATE_ACCOUNT_INSIGHTS_STATE_SEARCH =
  "UPDATE_ACCOUNT_INSIGHTS_STATE_SEARCH";
export const GET_UPDATE_INVOICE_EMAILS = "GET_UPDATE_INVOICE_EMAILS";
export const UPDATE_ANONYMOUS_SETTINGS = "UPDATE_ANONYMOUS_SETTINGS";
export const ACCOUNT_UPDATE_ANONYMOUS_SETTINGS =
  "ACCOUNT_UPDATE_ANONYMOUS_SETTINGS";
export const ACCOUNT_USERS_UPDATEVIEWTABLE = "ACCOUNT_USERS_UPDATEVIEWTABLE";
export const ACCOUNT_CREATENEWGROUP = "ACCOUNT_CREATENEWGROUP";
export const GET_ACCOUNTSETTINGS = "GET_ACCOUNTSETTINGS";
export const SET_ACCOUNTSETTINGS = "SET_ACCOUNTSETTINGS";
export const ACCOUNT_UPDATESETTINGS = "ACCOUNT_UPDATESETTINGS";
export const ACCOUNT_UPDATECOMPANYNAME = "ACCOUNT_UPDATECOMPANYNAME";
export const GET_ACCOUNT_INSIGHTS_SAVED_VIEWS =
  "GET_ACCOUNT_INSIGHTS_SAVED_VIEWS";
export const SET_ACCOUNT_INSIGHTS_SAVED_VIEWS =
  "SET_ACCOUNT_INSIGHTS_SAVED_VIEWS";
export const ACCOUNT_INSIGHTS_UPDATE_STATEVIEW =
  "ACCOUNT_INSIGHTS_UPDATE_STATEVIEW";

export const GET_COOKIES = "GET_COOKIES";
export const SET_COOKIES = "SET_COOKIES";
export const GET_COOKIE_DETAILS = "GET_COOKIE_DETAILS";
export const SET_COOKIE_DETAILS = "SET_COOKIE_DETAILS";
export const SET_COOKIES_SAVED_VIEWS = "SET_COOKIES_SAVED_VIEWS";
export const GET_COOKIES_SAVED_VIEWS = "GET_COOKIES_SAVED_VIEWS";
export const CLEAR_COOKIES_VIEWID = "CLEAR_COOKIES_VIEWID";
export const SAVED_VIEWS_COOKIES_CHANGED = "SAVED_VIEWS_COOKIES_CHANGED";
export const UPDATE_COOKIES_STATE_SEARCH = "UPDATE_COOKIES_STATE_SEARCH";
export const UPDATE_COOKIE_DETAILS_STATE_SEARCH =
  "UPDATE_COOKIE_DETAILS_STATE_SEARCH";
export const GET_CURRENT_COOKIE = "GET_CURRENT_COOKIE";
export const SET_CURRENT_COOKIE = "SET_CURRENT_COOKIE";
export const UPDATE_COOKIESTORE = "UPDATE_COOKIESTORE";
export const DELETE_COOKIE = "DELETE_COOKIE";
export const DELETE_COOKIE_STORE = "DELETE_COOKIE_STORE";
export const DELETE_FOLDER = "DELETE_FOLDER";
export const DELETE_FOLDER_AGENTS = "DELETE_FOLDER_AGENTS";
export const CLEAR_FOLDER_AGENTS = "CLEAR_FOLDER_AGENTS";
export const DELETE_FOLDER_COLLECTION = "DELETE_FOLDER_COLLECTION";
export const CLEAR_FOLDER_COLLECTION = "CLEAR_FOLDER_COLLECTION";
export const COOKIE_STORE_ADD_UPDATE_COOKIE = "COOKIE_STORE_ADD_UPDATE_COOKIE";
export const CREATE_NEW_COOKIESTORE = "CREATE_NEW_COOKIESTORE";

export const GET_FOLDER = "GET_FOLDER";
export const SET_FOLDER = "SET_FOLDER";
export const GET_FOLDERS = "GET_FOLDERS";
export const SET_FOLDERS = "SET_FOLDERS";
export const GET_FOLDERS_MODAL = "GET_FOLDERS_MODAL";
export const SET_FOLDERS_MODAL = "SET_FOLDERS_MODAL";
export const FILTER_FOLDERS = "FILTER_FOLDERS";
export const UPDATE_FOLDERS = "UPDATE_FOLDERS";
export const UPDATE_FOLDER = "UPDATE_FOLDER";
export const UPDATE_FOLDER_ITEMS = "UPDATE_FOLDER_ITEMS";
export const GET_FOLDERITEMS = "GET_FOLDERITEMS";
export const SET_FOLDERITEMS = "SET_FOLDERITEMS";
export const SET_FOLDER_AGENTS = "SET_FOLDER_AGENTS";
export const GET_FOLDER_AGENTS = "GET_FOLDER_AGENTS";
export const FILTER_FOLDER_COLLECTIONS = "FILTER_FOLDER_COLLECTIONS";
export const FILTER_FOLDER_AGENTS = "FILTER_FOLDER_AGENTS";
export const SET_FOLDER_COLLECTIONS = "SET_FOLDER_COLLECTIONS";
export const GET_FOLDER_COLLECTIONS = "GET_FOLDER_COLLECTIONS";
export const UPDATE_FOLDER_AGENTS_STATE_SEARCH =
  "UPDATE_FOLDER_AGENTS_STATE_SEARCH";
export const UPDATE_FOLDER_COLLECTIONS_STATE_SEARCH =
  "UPDATE_FOLDER_COLLECTIONS_STATE_SEARCH";
export const ADD_FOLDER = "ADD_FOLDER";
export const GET_CURRENT_FOLDER = "GET_CURRENT_FOLDER";
export const SET_CURRENT_FOLDER = "SET_CURRENT_FOLDER";
export const GET_VIEWS_FOLDER = "GET_VIEWS_FOLDER";
export const SET_VIEWS_FOLDER = "SET_VIEWS_FOLDER";

export const GET_COLLECTIONS = "GET_COLLECTIONS";
export const SET_COLLECTIONS = "SET_COLLECTIONS";
export const CLEAR_COLLECTION = "CLEAR_COLLECTION";
export const CLEAR_COLLECTION_STATE = "CLEAR_COLLECTION_STATE";
export const CLEAR_NONAGENT_COLLECTION = "CLEAR_NONAGENT_COLLECTION";
export const CLEAR_AGENT_STANDARD_COLLECTION =
  "CLEAR_AGENT_STANDARD_COLLECTION";
export const DELETE_COLLECTION = "DELETE_COLLECTION";
export const DELETE_COLLECTION_DETAILS = "DELETE_COLLECTION_DETAILS";
export const SET_DELETE_COLLECTION_ERROR = "SET_DELETE_COLLECTION_ERROR";
export const FILTER_COLLECTIONS = "FILTER_COLLECTIONS";
export const GET_COMBINED_COLLECTIONS = "GET_COMBINED_COLLECTIONS";
export const SET_COMBINED_COLLECTIONS = "SET_COMBINED_COLLECTIONS";
export const GET_COMPLETE_COLLECTION = "GET_COMPLETE_COLLECTION";
export const SET_COMPLETE_COLLECTION = "SET_COMPLETE_COLLECTION";
export const GET_COLLECTION = "GET_COLLECTION";
export const SET_COLLECTION = "SET_COLLECTION";
export const GET_STANDARD_COLLECTIONS = "GET_STANDARD_COLLECTIONS";
export const SET_STANDARD_COLLECTIONS = "SET_STANDARD_COLLECTIONS";
export const GET_STANDARD_COLLECTIONS_STATE = "GET_STANDARD_COLLECTIONS_STATE";
export const SET_STANDARD_COLLECTIONS_STATE = "SET_STANDARD_COLLECTIONS_STATE";
export const GET_COMBINED_COLLECTIONS_STATE = "GET_COMBINED_COLLECTIONS_STATE";
export const SET_COMBINED_COLLECTIONS_STATE = "SET_COMBINED_COLLECTIONS_STATE";
export const GET_COMBINED_COLLECTIONS_FIELDS =
  "GET_COMBINED_COLLECTIONS_FIELDS";
export const REFRESH_COMBINED_COLLECTIONS_FIELDS =
  "REFRESH_COMBINED_COLLECTIONS_FIELDS";
export const GET_COMBINED_COLLECTIONS_UPDATED_SOURCE_FIELDS =
  "GET_COMBINED_COLLECTIONS_UPDATED_SOURCE_FIELDS";
export const GET_COMBINED_COLLECTIONS_EXISTING_FIELDS =
  "GET_COMBINED_COLLECTIONS_EXISTING_FIELDS";
export const SET_COMBINED_COLLECTIONS_EXISTING_FIELDS =
  "SET_COMBINED_COLLECTIONS_EXISTING_FIELDS";
export const SET_COMBINED_COLLECTIONS_FIELDS =
  "SET_COMBINED_COLLECTIONS_FIELDS";
export const SET_COLLECTIONS_SAVED_VIEWS = "GET_COLLECTIONS_SAVED_VIEWS";
export const GET_COLLECTIONS_SAVED_VIEWS = "SET_COLLECTIONS_SAVED_VIEWS";
export const SAVED_VIEW_COLLECTIONS_CHANGED = "SAVED_VIEW_COLLECTIONS_CHANGED";
export const GET_COMBINED_COLLECTIONS_SAVED_VIEWS =
  "GET_COMBINED_COLLECTIONS_SAVED_VIEWS";
export const SET_COMBINED_COLLECTIONS_SAVED_VIEWS =
  "SET_COMBINED_COLLECTIONS_SAVED_VIEWS";
export const SAVED_VIEW_COMBINED_COLLECTIONS_CHANGED =
  "SAVED_VIEW_COMBINED_COLLECTIONS_CHANGED";
export const SAVED_VIEW_STANDARD_COLLECTIONS_CHANGED =
  "SAVED_VIEW_STANDARD_COLLECTIONS_CHANGED";
export const UPDATE_COLLECTIONS_STATE_SEARCH =
  "UPDATE_COLLECTIONS_STATE_SEARCH";
export const UPDATE_COMBINED_COLLECTIONS_STATE_SEARCH =
  "UPDATE_COMBINED_COLLECTIONS_STATE_SEARCH";
export const UPDATE_STANDARD_COLLECTIONS_STATE_SEARCH =
  "UPDATE_STANDARD_COLLECTIONS_STATE_SEARCH";
export const UPDATE_COMBINED_COLLECTIONS_FIELDS__STATE_SEARCH =
  "UPDATE_COMBINED_COLLECTIONS_FIELDS__STATE_SEARCH";
export const UPDATE_COLLECTIONS_CURRENT_PAGE =
  "UPDATE_COLLECTIONS_CURRENT_PAGE";
export const SAVED_VIEWS_AGENTS_GROUPS_DATA_CHANGED =
  "SAVED_VIEWS_AGENTS_GROUPS_DATA_CHANGED";
export const GET_SELECTED_COLLECTION = "GET_SELECTED_COLLECTION";
export const COLLECTION_COMBINED_UPDATE_FIELDS =
  "COLLECTION_COMBINED_UPDATE_FIELDS";
export const UPDATE_EXISTING_FIELDS = "UPDATE_EXISTING_FIELDS";
export const GET_COMBINED_COLLECTIONS_EDIT_MODAL_FIELDS =
  "GET_COMBINED_COLLECTIONS_EDIT_MODAL_FIELDS";
export const SET_COMBINED_COLLECTIONS_EDIT_MODAL_FIELDS =
  "SET_COMBINED_COLLECTIONS_EDIT_MODAL_FIELDS";
export const GET_STANDARD_COLLECTIONS_FIELDS =
  "GET_STANDARD_COLLECTIONS_FIELDS";
export const SET_STANDARD_COLLECTIONS_FIELDS =
  "SET_STANDARD_COLLECTIONS_FIELDS";
export const SET_COMBINED_COLLECTIONS_SOURCE_FIELDS_LOADED =
  "SET_COMBINED_COLLECTIONS_SOURCE_FIELDS_LOADED";
export const GET_STANDARD_COLLECTIONS_SAVED_VIEWS =
  "GET_STANDARD_COLLECTIONS_SAVED_VIEWS";
export const SET_STANDARD_COLLECTIONS_SAVED_VIEWS =
  "SET_STANDARD_COLLECTIONS_SAVED_VIEWS";
export const CLEAR_VIEWID = "CLEAR_VIEWID";
export const CLEAR_STANDARD_COLLECTIONS_VIEWID =
  "CLEAR_STANDARD_COLLECTIONS_VIEWID";
export const CLEAR_COMBINED_COLLECTIONS_VIEWID =
  "CLEAR_COMBINED_COLLECTIONS_VIEWID";
export const CLEAR_COLLECTIONS_VIEWID = "CLEAR_COLLECTIONS_VIEWID";
export const UPDATE_SOURCE_COLLECTIONS_STATE_SEARCH =
  "UPDATE_SOURCE_COLLECTIONS_STATE_SEARCH";
export const GET_COLLECTIONS_FIELDS = "GET_COLLECTIONS_FIELDS";
export const SET_COLLECTIONS_FIELDS = "SET_COLLECTIONS_FIELDS";
export const COLLECTIONS_UPDATEVIEWTABLE = "COLLECTIONS_UPDATEVIEWTABLE";
export const COLLECTION_DELETEFIELDS = "COLLECTION_DELETEFIELDS";
export const CREATE_COLLECTION_FROM_SCRATCH = "CREATE_COLLECTION_FROM_SCRATCH";
export const GET_ADD_FROM_EXISTING_COLLECTIONS =
  "GET_ADD_FROM_EXISTING_COLLECTIONS";
export const SET_ADD_FROM_EXISTING_COLLECTIONS =
  "SET_ADD_FROM_EXISTING_COLLECTIONS";
export const SET_SAVED_VIEWS_FROM_EXISTING_COLLECTIONS =
  "SET_SAVED_VIEWS_FROM_EXISTING_COLLECTIONS";
export const UPDATE_FROM_EXISTING_COLLECTIONS_STATE_SEARCH =
  "UPDATE_FROM_EXISTING_COLLECTIONS_STATE_SEARCH";
export const GET_SAVED_VIEWS_FROM_EXISTING_COLLECTIONS =
  "GET_SAVED_VIEWS_FROM_EXISTING_COLLECTIONS";
export const GET_SOURCE_COLLECTIONS_COMBINED =
  "GET_SOURCE_COLLECTIONS_COMBINED";
export const GET_SOURCE_COLLECTIONS_COMBINED_VIEWS =
  "GET_SOURCE_COLLECTIONS_COMBINED_VIEWS";
export const SET_SOURCE_COLLECTIONS_COMBINED_VIEWS =
  "SET_SOURCE_COLLECTIONS_COMBINED_VIEWS";
export const GET_COLUMN_HEADINGS_FROM_FILE = "GET_COLUMN_HEADINGS_FROM_FILE";
export const COLLECTION_FROM_FILE = "COLLECTION_FROM_FILE";
export const SET_COLUMN_HEADINGS_FROM_FILE = "SET_COLUMN_HEADINGS_FROM_FILE";
export const SET_COLLECTION_FROM_FILE = "SET_COLLECTION_FROM_FILE";
export const COLLECTION_CREATE_NEW_FROM_FILE =
  "COLLECTION_CREATE_NEW_FROM_FILE";
export const SET_COLLECTION_FROM_FILE_FILECOLUMNS =
  "SET_COLLECTION_FROM_FILE_FILECOLUMNS";
export const COLLECTION_FROM_FILE_FILECOLUMNS =
  "COLLECTION_FROM_FILE_FILECOLUMNS";
export const COLLECTION_FROM_FILE_FIELDSGRID =
  "COLLECTION_FROM_FILE_FIELDSGRID";
export const SET_COLLECTION_FROM_FILE_FIELDSGRID =
  "SET_COLLECTION_FROM_FILE_FIELDSGRID";
export const COLLECTION_UPDATEITEMSFROMFILE = "COLLECTION_UPDATEITEMSFROMFILE";
export const CREATE_FOLDER_COLLECTION_FROM_SCRATCH =
  "CREATE_FOLDER_COLLECTION_FROM_SCRATCH";
export const COLLECTION_CREATE_NEW_FROM_EXISTING =
  "COLLECTION_CREATE_NEW_FROM_EXISTING";
export const GET_COMBINED_DATA_EXISTING_FIELDS =
  "GET_COMBINED_DATA_EXISTING_FIELDS";
export const SET_COLLECTION_DATA_FROM_EXISTING =
  "SET_COLLECTION_DATA_FROM_EXISTING";
export const SET_COMBINED_DATA_EXISTING_FIELDS =
  "SET_COMBINED_DATA_EXISTING_FIELDS";
export const SET_COMBINED_DATA_SOURCE_COLLECTIONS_INFO =
  "SET_COMBINED_DATA_SOURCE_COLLECTIONS_INFO";
export const COMBINED_DATA_SOURCE_COLLECTIONS_INFO_LOADED =
  "COMBINED_DATA_SOURCE_COLLECTIONS_INFO_LOADED";
export const SET_COMBINED_DATA_SOURCE_FIELDS =
  "SET_COMBINED_DATA_SOURCE_FIELDS";
export const SET_COMBINED_DATA_SHARED_FIELDS =
  "SET_COMBINED_DATA_SHARED_FIELDS";
export const SET_COMBINED_DATA_SOURCE_FIELDS_LOADED =
  "SET_COMBINED_DATA_SOURCE_FIELDS_LOADED";
export const FILTER_USED_COMBINED_DATA_SOURCE_FIELDS =
  "FILTER_USED_COMBINED_DATA_SOURCE_FIELDS";
export const FILTER_COMBINED_DATA_SAME_COLLECTION_SOURCE_FIELDS =
  "FILTER_COMBINED_DATA_SAME_COLLECTION_SOURCE_FIELDS";
export const FILTER_COMBINED_DATA_BY_FIELD_NAME =
  "FILTER_COMBINED_DATA_BY_FIELD_NAME";
export const GET_COMBINED_DATA_SOURCE_FIELDS =
  "GET_COMBINED_DATA_SOURCE_FIELDS";
export const GET_ALL_STANDARD_COLLECTIONS = "GET_ALL_STANDARD_COLLECTIONS";
export const SET_ALL_STANDARD_COLLECTIONS = "SET_ALL_STANDARD_COLLECTIONS";
export const GET_ALL_COMBINED_COLLECTIONS = "GET_ALL_COMBINED_COLLECTIONS";
export const SET_ALL_COMBINED_COLLECTIONS = "SET_ALL_COMBINED_COLLECTIONS";

export const GET_SEQUENCE_COLLECTION_INFO = "GET_SEQUENCE_COLLECTION_INFO";
export const SET_SEQUENCE_COLLECTION_INFO = "SET_SEQUENCE_COLLECTION_INFO";

export const UPDATE_COLLECTION_STATE = "UPDATE_COLLECTION_STATE";

export const GET_SAVED_VIEWS = "GET_SAVED_VIEWS";
export const SET_SAVED_VIEWS = "SET_SAVED_VIEWS";
export const UPDATE_STATEVIEW = "UPDATE_STATEVIEW";

export const GET_NEW_VIEW_FIELDS = "GET_NEW_VIEW_FIELDS";
export const SET_NEW_VIEW_FIELDS = "SET_NEW_VIEW_FIELDS";
export const GET_NEW_VIEW_FILTERS = "GET_NEW_VIEW_FILTERS";
export const SET_NEW_VIEW_FILTERS = "SET_NEW_VIEW_FILTERS";

export const GET_COMBINED_COLLECTIONS_SOURCE_FIELDS =
  "GET_COMBINED_COLLECTIONS_SOURCE_FIELDS";
export const SET_COMBINED_COLLECTIONS_SOURCE_FIELDS =
  "SET_COMBINED_COLLECTIONS_SOURCE_FIELDS";

export const GET_SOURCE_COLLECTIONS = "GET_SOURCE_COLLECTIONS";
export const SET_SOURCE_COLLECTIONS = "SET_SOURCE_COLLECTIONS";
export const GET_SOURCE_COLLECTIONS_VIEWS = "GET_SOURCE_COLLECTIONS_VIEWS";
export const SET_SOURCE_COLLECTIONS_VIEWS = "SET_SOURCE_COLLECTIONS_VIEWS";
export const FILTER_SOURCE_COLLECTIONS = "FILTER_SOURCE_COLLECTIONS";

export const FILTER_USED_COMBINED_COLLECTIONS_SOURCE_FIELDS =
  "FILTER_USED_COMBINED_COLLECTIONS_SOURCE_FIELDS";
export const FILTER_COMBINED_COLLECTIONS_SAME_COLLECTION_SOURCE_FIELDS =
  "FILTER_COMBINED_COLLECTIONS_SAME_COLLECTION_SOURCE_FIELDS";
export const FILTER_COMBINED_COLLECTIONS_BY_FIELD_NAME =
  "FILTER_COMBINED_COLLECTIONS_BY_FIELD_NAME";
export const COLLECTION_REBUILD = "COLLECTION_REBUILD";
export const SET_COMBINED_COLLECTIONS_SOURCE_COLLECTIONS_INFO =
  "SET_COMBINED_COLLECTIONS_SOURCE_COLLECTIONS_INFO";
export const UPDATE_COMBINED_COLLECTIONS_SOURCE_COLLECTIONS_INFO =
  "UPDATE_COMBINED_COLLECTIONS_SOURCE_COLLECTIONS_INFO";
export const COMBINED_COLLECTIONS_SOURCE_COLLECTIONS_INFO_LOADED =
  "COMBINED_COLLECTIONS_SOURCE_COLLECTIONS_INFO_LOADED";
export const RESET_COMBINED_COLLECTIONS_SOURCE_COLLECTIONS_INFO =
  "RESET_COMBINED_COLLECTIONS_SOURCE_COLLECTIONS_INFO";
export const SET_COMBINED_COLLECTIONS_SHARED_FIELDS =
  "SET_COMBINED_COLLECTIONS_SHARED_FIELDS";
export const SET_SOURCE_COLLECTIONS_COMBINED =
  "SET_SOURCE_COLLECTIONS_COMBINED";
export const REBUILD_COMBINE_COLLECTION = "REBUILD_COMBINE_COLLECTION";

export const GET_COLLECTIONS_CUSTOM_FIELDS = "GET_COLLECTIONS_CUSTOM_FIELDS";
export const SET_COLLECTIONS_CUSTOM_FIELDS = "SET_COLLECTIONS_CUSTOM_FIELDS";
export const COLLECTION_UPDATE = "COLLECTION_UPDATE";
export const COLLECTION_UPDATE_STATUS = "COLLECTION_UPDATE_STATUS";
export const GET_COLLECTION_INFO = "GET_COLLECTION_INFO";

export const VIEW_DELETE = "VIEW_DELETE";

export const VIEW_ADD_LAYOUT = "VIEW_ADD_LAYOUT";

export const COLLECTION_UPDATE_FIELDS = "COLLECTION_UPDATE_FIELDS";
export const UPDATE_FIELD_AGENT_GROUP = "UPDATE_FIELD_AGENT_GROUP";
export const COLLECTION_UPDATEITEM = "COLLECTION_UPDATEITEM";
export const COLLECTION_DELETEITEMS = "COLLECTION_DELETEITEMS";

export const DELETE_AGENTS_VIEW = "DELETE_AGENTS_VIEW";
export const UPDATE_AGENTS_VIEW_ROW = "UPDATE_AGENTS_VIEW_ROW";
export const UPDATE_COLLECTIONS_VIEW_ROW = "UPDATE_COLLECTIONS_VIEW_ROW";
export const DELETE_COLLECTIONS_VIEW = "DELETE_COLLECTIONS_VIEW";
export const UPDATE_AGENTS_DATA_VIEW_ROW = "UPDATE_AGENTS_DATA_VIEW_ROW";
export const DELETE_AGENTS_DATA_VIEW = "DELETE_AGENTS_DATA_VIEW";
export const DELETE_AGENTS_DATA_VIEW_ROW = "DELETE_AGENTS_DATA_VIEW_ROW";
export const DELETE_AGENT_JOBS_VIEW = "DELETE_AGENT_JOBS_VIEW";
export const UPDATE_AGENTS_JOBS_VIEW_ROW = "UPDATE_AGENTS_JOBS_VIEW_ROW";
export const DELETE_AGENTS_HISTORY_VIEW = "DELETE_AGENTS_HISTORY_VIEW";
export const UPDATE_AGENTS_HISTORY_VIEW_ROW = "UPDATE_AGENTS_HISTORY_VIEW_ROW";
export const DELETE_COOKIES_VIEW = "DELETE_COOKIES_VIEW";
export const UPDATE_COOKIES_VIEW_ROW = "UPDATE_COOKIES_VIEW_ROW";
export const UPDATE_AGENT_GROUPS_DATA_VIEW_ROW =
  "UPDATE_AGENT_GROUPS_DATA_VIEW_ROW";
export const DELETE_AGENT_GROUPS_DATA_VIEW = "DELETE_AGENT_GROUPS_DATA_VIEW";
export const DELETE_COMBINED_COLLECTIONS_VIEW =
  "DELETE_COMBINED_COLLECTIONS_VIEW";
export const UPDATE_COMBINED_COLLECTIONS_VIEW_ROW =
  "UPDATE_COMBINED_COLLECTIONS_VIEW_ROW";
export const DELETE_STANDARD_COLLECTIONS_VIEW =
  "DELETE_STANDARD_COLLECTIONS_VIEW";
export const UPDATE_STANDARD_COLLECTIONS_VIEW_ROW =
  "UPDATE_STANDARD_COLLECTIONS_VIEW_ROW";
export const DELETE_SEQUENCES_VIEW = "DELETE_SEQUENCES_VIEW";
export const UPDATE_SEQUENCES_VIEW_ROW = "UPDATE_SEQUENCES_VIEW_ROW";
export const DELETE_DEPARTMENTS_VIEW = "DELETE_DEPARTMENTS_VIEW";
export const UPDATE_DEPARTMENTS_VIEW_ROW = "UPDATE_DEPARTMENTS_VIEW_ROW";
export const DELETE_DEPARTMENT_USERS_VIEW = "DELETE_DEPARTMENT_USERS_VIEW";
export const UPDATE_DEPARTMENT_USERS_VIEW_ROW =
  "UPDATE_DEPARTMENT_USERS_VIEW_ROW";
export const DELETE_ACCOUNT_USERS_VIEW = "DELETE_ACCOUNT_USERS_VIEW";
export const UPDATE_ACCOUNT_USERS_VIEW_ROW = "UPDATE_ACCOUNT_USERS_VIEW_ROW";
export const DEPARTMENTS_UPDATEVIEWTABLE = "DEPARTMENTS_UPDATEVIEWTABLE";

export const TEMPLATE_GETAGENTCOMBOBOX = "TEMPLATE_GETAGENTCOMBOBOX";
export const SET_TEMPLATE_AGENTCOMBOBOX = "SET_TEMPLATE_AGENTCOMBOBOX";

//#region user constants
export const GET_USER = "GET_USER";
export const SET_USER = "SET_USER";
export const DELETE_USER = "DELETE_USER";
export const INVITE_USERS = "INVITE_USERS";
export const INVITE_ACCOUNT_USERS = "INVITE_ACCOUNT_USERS";
export const NEW_USER = "NEW_USER";
export const EDIT_USER = "EDIT_USER";
export const GENERATE_WEB_SERVICE_KEY = "GENERATE_WEB_SERVICE_KEY";
export const SET_WEB_SERVICE_KEY = "SET_WEB_SERVICE_KEY";
export const SET_NEW_USER_ERROR = "SET_NEW_USER_ERROR";
export const SET_EDIT_USER_ERROR = "SET_EDIT_USER_ERROR";
export const DELETE_DEPARTMENT_USERS = "DELETE_DEPARTMENT_USERS";
export const REMOVE_NEW_USER_ERROR = "REMOVE_NEW_USER_ERROR";
export const REMOVE_EDIT_USER_ERROR = "REMOVE_EDIT_USER_ERROR";

//#endregion

//#region agent builder constants
export const LAUNCH_AGENT_BUILDER = "LAUNCH_AGENT_BUILDER";
export const LAUNCH_AGENT_BUILDER_RESULT = "LAUNCH_AGENT_BUILDER_RESULT";
//#endregion

//#region agent settings
export const SET_AGENT_BUILTBYPREVIOUS = "SET_AGENT_BUILTBYPREVIOUS";
export const SET_AGENT_JOBSACTIVE = "SET_AGENT_JOBSACTIVE";
//#endregion

//#region data publishing constants
export const EMPTY_PUBLISH_STATE = "EMPTY_PUBLISH_STATE";
export const SET_EMPTY_PUBLISH_STATE = "SET_EMPTY_PUBLISH_STATE";
export const PUBLISH_INFO = "PUBLISH_INFO";
export const SET_PUBLISH_INFO = "SET_PUBLISH_INFO";
export const INITIALIZE_STATE = "INITIALIZE_STATE";
export const SET_INITIALIZE_STATE = "SET_INITIALIZE_STATE";
export const PUBLISHER_UPDATE = "PUBLISHER_UPDATE";
export const PUBLISHER_ADD = "PUBLISHER_ADD";
export const GET_PUBLISHERLIST = "GET_PUBLISHERLIST";
export const SET_PUBLISHERLIST = "SET_PUBLISHERLIST";
export const PUBLISH = "PUBLISH";
export const PUBLISHER_TEST = "PUBLISHER_TEST";
export const GET_PUBLISH_VIEW_STORE = "GET_PUBLISH_VIEW_STORE";
export const SET_PUBLISH_VIEW_STORE = "SET_PUBLISH_VIEW_STORE";
export const GET_ACCOUNT_AUTHORIZATIONURLS = "GET_ACCOUNT_AUTHORIZATIONURLS";
export const SET_ACCOUNT_AUTHORIZATIONURLS = "SET_ACCOUNT_AUTHORIZATIONURLS";
export const SET_ACCOUNT_STORAGE_SUMMARY_DOWNLOAD_COLLECTION =
  "SET_ACCOUNT_STORAGE_SUMMARY_DOWNLOAD_COLLECTION";
export const GET_ACCOUNT_STORAGE_SUMMARY_DOWNLOAD_COLLECTION =
  "GET_ACCOUNT_STORAGE_SUMMARY_DOWNLOAD_COLLECTION";
export const DEAUTHORIZE_ACCOUNT_DRIVES = "DEAUTHORIZE_ACCOUNT_DRIVES";
export const PUBLISHING_PARAMETERS_LOAD = "PUBLISHING_PARAMETERS_LOAD";
//#endregion

//#region scheduling constants
export const GET_PUBLISHING_SCHEDULE = "GET_PUBLISHING_SCHEDULE";
export const SET_PUBLISHING_SCHEDULE = "SET_PUBLISHING_SCHEDULE";
export const GET_SCHEDULER = "GET_SCHEDULER";
export const SET_SCHEDULER = "SET_SCHEDULER";
export const ADD_UPDATE_SCHEDULER = "ADD_UPDATE_SCHEDULER";
//#endregion

export const GET_SYSTEM_BILLING_INFO = "GET_SYSTEM_BILLING_INFO";
export const SET_SYSTEM_BILLING_INFO = "SET_SYSTEM_BILLING_INFO";
export const GET_SYSYTEM_RATE_PLANS = "GET_SYSYTEM_RATE_PLANS";
export const SET_SYSYTEM_RATE_PLANS = "SET_SYSYTEM_RATE_PLANS";
export const UPDATE_BOOKMARK_LIST = "UPDATE_BOOKMARK_LIST";
export const UPDATE_BOOKMARK_ON_RUNNING = "UPDATE_BOOKMARK_ON_RUNNING";
export const SET_BOOKMARK_LIST = "SET_BOOKMARK_LIST";
export const SYSTEM_RATE_PLANS_UPDATEVIEWTABLE =
  "SYSTEM_RATE_PLANS_UPDATEVIEWTABLE";

//#region Agent Run
export const AGENT_RUN = "AGENT_RUN";
export const AGENT_GET_JOB_STATUS_POLL = "AGENT_GET_JOB_STATUS_POLL";
export const AGENT_RESUME = "AGENT_RESUME";
export const AGENT_CANCEL = "AGENT_CANCEL";
export const AGENT_STOP = "AGENT_STOP";
export const ROUTER_LOCATION = "ROUTER_LOCATION";
export const SET_ROUTER_LOCATION = "SET_ROUTER_LOCATION";

export const EXPORT_AGENT_DOWNLOAD = "EXPORT_AGENT_DOWNLOAD";
export const DELETE_PACKAGE_FILES = "DELETE_PACKAGE_FILES";
export const RE_PACKAGE_FILES = "RE_PACKAGE_FILES";
export const REPACKAGE_ERROR = "REPACKAGE_ERROR";
export const DELETE_PACKAGE_FILES_ERROR = "DELETE_PACKAGE_FILES_ERROR";
export const UPDATE_EXPORT_DATA_VIEW_NAME = "UPDATE_EXPORT_DATA_VIEW_NAME";
export const RENAME_PACKAGE_FILE = "RENAME_PACKAGE_FILE";

export const SET_VIEW_NAME = "SET_VIEW_NAME";
export const SET_COLLECTIONS_VIEW_NAME = "SET_COLLECTIONS_VIEW_NAME";
export const SET_COLLECTION_VIEW_NAME = "SET_COLLECTION_VIEW_NAME";
export const SET_STANDARD_COLLECTION_VIEW_NAME =
  "SET_STANDARD_COLLECTION_VIEW_NAME";
export const SET_COMBINED_COLLECTION_VIEW_NAME =
  "SET_COMBINED_COLLECTION_VIEW_NAME";
export const SET_EXPORT_AGENT_DATA_VIEW_NAME =
  "SET_EXPORT_AGENT_DATA_VIEW_NAME";
export const UPDATE_SEQUENCE_COLLECTION_STATE_SEARCH =
  "UPDATE_SEQUENCE_COLLECTION_STATE_SEARCH";
export const GET_DEPARTMENT_UTILIZATION_DOWNLOAD_AGENT_PAGES =
  "GET_DEPARTMENT_UTILIZATION_DOWNLOAD_AGENT_PAGES";
export const SET_DEPARTMENT_UTILIZATION_DOWNLOAD_AGENT_PAGES =
  "SET_DEPARTMENT_UTILIZATION_DOWNLOAD_AGENT_PAGES";
export const SWITCH_COLLECTION_NEWCOLLECTION =
  "SWITCH_COLLECTION_NEWCOLLECTION";

//#endregion

export const EVENT_GETBYACTION = "EVENT_GETBYACTION";
export const SET_EVENT_GETBYACTION = "SET_EVENT_GETBYACTION";

export const ADD_CREDIT = "ADD_CREDIT";

export const CHANGE_INVOICE_DATE = "CHANGE_INVOICE_DATE";
export const ADD_TRANSACTION = "ADD_TRANSACTION";
export const BILLING_ERROR = "BILLING_ERROR";
export const BILLING_SUCCESS = "BILLING_SUCCESS";
export const BILLING_LOADING = "BILLING_LOADING";
export const GET_EXISTING_USER = "GET_EXISTING_USER";
export const CHANGE_TIME_ZONE = "CHANGE_TIME_ZONE";
export const UPDATE_TIMEZONE = "UPDATE_TIMEZONE";
export const GET_SYSTEM_RATE_PLANS_SAVED_VIEWS =
  "GET_SYSTEM_RATE_PLANS_SAVED_VIEWS";
export const SET_SYSTEM_RATE_PLANS_SAVED_VIEWS =
  "SET_SYSTEM_RATE_PLANS_SAVED_VIEWS";
export const UPDATE_SYSTEM_RATE_PLANS_VIEW_ROW =
  "UPDATE_SYSTEM_RATE_PLANS_VIEW_ROW";
export const DELETE_SYSTEM_RATE_PLANS_VIEW = "DELETE_SYSTEM_RATE_PLANS_VIEW";
export const DELETE_SYSTEM_RATE_PLANS_SAVED_VIEW =
  "DELETE_SYSTEM_RATE_PLANS_SAVED_VIEW";
export const DELETE_SYSTEM_RATE_PLAN_VIEW_ERROR =
  "DELETE_SYSTEM_RATE_PLAN_VIEW_ERROR";
export const SET_SYSTEM_RATE_PLAN_VIEW_ERROR =
  "DELETE_SYSTEM_RATE_PLAN_VIEW_ERROR";
export const GET_SYSTEM_SETTINGS = "GET_SYSTEM_SETTINGS";
export const SET_SYSTEM_SETTINGS = "SET_SYSTEM_SETTINGS";
export const GET_SYSTEM_SETTINGS_SAVED_VIEWS =
  "GET_SYSTEM_SETTINGS_SAVED_VIEWS";
export const SET_SYSTEM_SETTINGS_SAVED_VIEWS =
  "SET_SYSTEM_SETTINGS_SAVED_VIEWS";
export const SYSTEM_SETTINGS_SAVED_VIEWS_CHANGED =
  "SYSTEM_SETTINGS_SAVED_VIEWS_CHANGED";
export const SYSTEM_SETTINGS_UPDATE_STATE_SEARCH =
  "SYSTEM_SETTINGS_UPDATE_STATE_SEARCH";
export const SETTINGS_DELETE = "SETTINGS_DELETE";
export const SETTINGS_COPY = "SETTINGS_COPY";
export const NEW_SETTING = "NEW_SETTING";
export const UPDATE_SYSTEM_SETTINGS_VIEW_ROW =
  "UPDATE_SYSTEM_SETTINGS_VIEW_ROW";
export const GET_SYSTEM_INSIGHTS = "GET_SYSTEM_INSIGHTS";
export const SET_SYSTEM_INSIGHTS = "SET_SYSTEM_INSIGHTS";
export const UPDATE_SYSTEM_INSIGHTS_STATE_SEARCH =
  "UPDATE_SYSTEM_INSIGHTS_STATE_SEARCH";
export const GET_SYSTEM_INSIGHTS_SAVED_VIEWS =
  "GET_SYSTEM_INSIGHTS_SAVED_VIEWS";
export const SET_SYSTEM_INSIGHTS_SAVED_VIEWS =
  "SET_SYSTEM_INSIGHTS_SAVED_VIEWS";
export const SYSTEM_INSIGHTS_UPDATE_STATEVIEW =
  "SYSTEM_INSIGHTS_UPDATE_STATEVIEW";

export const GET_ACCOUNT_BILL_ELEMENTS = "GET_ACCOUNT_BILL_ELEMENTS";
export const SET_ACCOUNT_BILL_ELEMENTS = "SET_ACCOUNT_BILL_ELEMENTS";
export const GET_DOMAIN_LOOKUP_TOOL = "GET_DOMAIN_LOOKUP_TOOL";
export const SET_DOMAIN_LOOKUP_COLUMNS = "SET_DOMAIN_LOOKUP_COLUMNS";
export const GET_DOMAIN_LOOKUP_COLUMNS = "GET_DOMAIN_LOOKUP_COLUMNS";
export const GET_RATE_PLAN = "GET_RATE_PLAN";
export const SET_RATE_PLAN = "SET_RATE_PLAN";
export const CLONE_RATE_PLAN = "CLONE_RATE_PLAN";
export const SETTINGS_UPDATEVIEWTABLE = "SETTINGS_UPDATEVIEWTABLE";
export const UPDATE_SETTINGS_VIEW_ROW = "UPDATE_SETTINGS_VIEW_ROW";
export const DELETE_SETTINGS_VIEW = "DELETE_SETTINGS_VIEW";
export const SET_SETTINGS_VIEW_ERROR = "SET_SETTINGS_VIEW_ERROR";
export const DELETE_SETTINGS_SAVED_VIEW = "DELETE_SETTINGS_SAVED_VIEW";
export const GET_MOST_RECENT_STORAGE_INVOCE = "GET_MOST_RECENT_STORAGE_INVOCE";
export const SET_ACTIVE_ACCOUNT = "SET_ACTIVE_ACCOUNT";
export const SAVE_SUPER_ADMIN_UPDATE = "SAVE_SUPER_ADMIN_UPDATE";
export const MOVE_STEP_DRAG = "MOVE_STEP_DRAG";
export const ADD_RECENT_ITEMS = "ADD_RECENT_ITEMS";
export const SEQUENCES_UPDATEVIEWTABLE = "SEQUENCES_UPDATEVIEWTABLE";
export const SET_SHARE_KEY = "SET_SHARE_KEY";
export const GET_ACCOUNT_ON_DEPARTMENT_CHANGE =
  "GET_ACCOUNT_ON_DEPARTMENT_CHANGE";
export const SET_ACCOUNT_ON_DEPARTMENT_CHANGE =
  "SET_ACCOUNT_ON_DEPARTMENT_CHANGE";
export const GET_ALL_FOLDERS = "GET_ALL_FOLDERS";
export const SET_ALL_FOLDERS = "SET_ALL_FOLDERS";

export const UPDATE_LOCK_STATE = "UPDATE_LOCK_STATE";
export const UPDATE_VIEW_FIELD = "UPDATE_VIEW_FIELD";
export const SHOW_SELECTED_GRID = "SHOW_SELECTED_GRID";
export const COMBINED_UPDATEVIEWTABLE = "COMBINED_UPDATEVIEWTABLE";
export const STANDARD_UPDATEVIEWTABLE = "STANDARD_UPDATEVIEWTABLE";
export const GET_ACCOUNT_SETTING = "GET_ACCOUNT_SETTING";
export const SET_ACCOUNT_SETTING = "SET_ACCOUNT_SETTING";
export const UPDATE_ACCOUNT_SETTINGS = "UPDATE_ACCOUNT_SETTINGS";

export const SAVE_PAYMENT_INFO = "SAVE_PAYMENT_INFO";
