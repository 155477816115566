export const CLEAR_SETTINGS = {
  EMPTY_FILE_PACKAGES: { name: "EmptyFilePackages", value: "File packages" },
  EMPTY_FILES: { name: "EmptyFiles", value: "Images and files" },
  EMPTY_ITEM_HISTORY: {
    name: "EmptyItemHistory",
    value: "Item history and bookmarks",
  },
  EMPTY_ITEMS: { name: "EmptyItems", value: "Items" },
  EMPTY_JOB_HISTORY: { name: "EmptyJobHistory", value: "Job history" },
};
