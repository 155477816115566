import {
  GET_AGENT_GROUPS,
  SET_AGENT_GROUPS,
  GET_AGENTGROUPS_AGENT_INFO,
  SET_AGENTGROUPS_AGENT_INFO,
  AGENTGROUPS_AGENT_INFO_LOADED,
  NEW_AGENT_GROUP_FROM_SCRATCH,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import { takeLatest, put, cancel } from "redux-saga/effects";
import axios from "axios";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";

function* fetchAgentGroups({ type: action, formParams = {} }) {
  const params = {
    command: CONSTANTS.COMMANDS.TEMPLATE_GETLIST,
    ...formParams,
  };
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.TEMPLATE_GETLIST,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    const list = data.Rows.map((item) => ({
      ...item,
      path: `/agentgroups/${item.ItemID}`,
      title: item.Name,
    }));

    yield put({
      type: SET_AGENT_GROUPS,
      payload: {
        list,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getAgentInfo({ type: action, formParams = {} }) {
  const params = {
    Command: CONSTANTS.COMMANDS.AGENT_GET,
    ...formParams,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.AGENT_GET,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    let agentInfo = {};
    const { Agent, ...otherData } = data;

    for (let key in Agent) {
      agentInfo[key] = Agent[key];
    }

    yield put({
      type: SET_AGENTGROUPS_AGENT_INFO,
      payload: {
        agentInfo: {
          ...agentInfo,
          ...otherData,
        },
      },
    });
    yield put({
      type: AGENTGROUPS_AGENT_INFO_LOADED,
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}
function* newAgentGroupFromScratch({ urlSearchParams, navigate }) {
  const newTemplateData = yield axios.post(
    CONSTANTS.ROUTES.TEMPLATE_CREATENEWFROMSCRATCH,
    urlSearchParams
  );

  if (
    newTemplateData?.data?.JsonResult?.Result === CONSTANTS.LOGIN_RESPONSE_FLAG
  ) {
    yield cancel();
  }

  const newAgentGroupId = newTemplateData.data.AgentID;
  navigate(`/agentgroups/${newAgentGroupId}/agents`);
}
export default function* agentGroupsWatcher() {
  yield takeLatest(GET_AGENTGROUPS_AGENT_INFO, getAgentInfo);
  yield takeLatest(GET_AGENT_GROUPS, fetchAgentGroups);
  yield takeLatest(NEW_AGENT_GROUP_FROM_SCRATCH, newAgentGroupFromScratch);
}
