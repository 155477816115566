import {
  SET_ACCOUNT,
  GET_ACCOUNT,
  SESSION_EXPIRED,
  SET_SESSION_EXPIRED,
  GET_ACCOUNT_AUTHORIZATIONURLS,
  SET_ACCOUNT_AUTHORIZATIONURLS,
  DEAUTHORIZE_ACCOUNT_DRIVES,
  UPDATE_ANONYMOUS_SETTINGS,
  GET_ACCOUNTSETTINGS,
  SET_ACCOUNTSETTINGS,
  ACCOUNT_UPDATESETTINGS,
  ACCOUNT_UPDATE_ANONYMOUS_SETTINGS,
  AGENTS_DEFAULTS_GET,
  SET_AGENTS_DEFAULT,
  ADD_CREDIT,
  CHANGE_TIME_ZONE,
  UPDATE_TIMEZONE,
  GET_ACCOUNT_ON_DEPARTMENT_CHANGE,
  SET_ACCOUNT_SETTING,
  GET_ACCOUNT_SETTING,
  UPDATE_ACCOUNT_SETTINGS,
  SAVE_PAYMENT_INFO,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import { takeLatest, put, cancel, select } from "redux-saga/effects";
import axios from "axios";
import { COMMANDS } from "../../constants/commands";
import {
  errorHandlingEvent,
  handleJsonResultError,
  notificationHandling,
} from "../../EventHandling";

const Ext = window["Ext"];
function* fetchAccount({ type: action, formParams = {} }) {
  const params = {
    Command: CONSTANTS.COMMANDS.ACCOUNT_GET,
    ViewID: "",
    credentials: "include",
    ...formParams,
  };
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.ACCOUNT_GET,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    yield put({
      type: SET_ACCOUNT,
      payload: {
        accountInfo: data,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* fetchAccountAuthUrls() {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.ACCOUNT_AUTHORIZATIONURLS,
      new URLSearchParams({
        Command: CONSTANTS.COMMANDS.ACCOUNT_AUTHORIZATIONURLS,
      })
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (data.JsonResult.Result === "Success") {
      let payload = {
        DropboxAuthorizationUrl: data.DropboxAuthorizationUrl,
        GoogleAuthorizationUrl: data.GoogleAuthorizationUrl,
      };
      yield put({
        type: SET_ACCOUNT_AUTHORIZATIONURLS,
        payload: {
          ...payload,
        },
      });
    }
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* accountDeauthorize({ type: action, method }) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.DEAUTHORIZE_ACCOUNT_DRIVES,
      new URLSearchParams({
        Command: CONSTANTS.COMMANDS.DEAUTHORIZE_ACCOUNT_DRIVES,
        method,
      }),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    yield put({
      type: GET_ACCOUNT,
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getAccountSettings({ type: action, AccountKeys }) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.ACCOUNT_GETACCOUNTSETTINGS,
      new URLSearchParams({
        AccountKeys: [AccountKeys].toString(),
        Command: CONSTANTS.COMMANDS.ACCOUNT_GETACCOUNTSETTINGS,
      }),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    yield put({
      type: SET_ACCOUNTSETTINGS,
      payload: {
        accountSettings: data,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* updateAccountSettings({
  type: action,
  AccountKeys,
  formParams,
  storeId = "",
  setError,
  toggleModal,
}) {
  try {
    let params = formParams;

    let errorResponse = "";
    if (AccountKeys.length === 1) {
      const { data } = yield axios.post(
        CONSTANTS.ROUTES.ACCOUNT_UPDATECOMPANYNAME,
        new URLSearchParams({
          AccountKey: AccountKeys[0],
          Company: formParams.Company,
          Description: formParams.Description,
          Command: CONSTANTS.COMMANDS.ACCOUNT_UPDATECOMPANYNAME,
        }),
        {
          headers: {
            action,
          },
        }
      );

      if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
        yield cancel();
      }

      if (
        !handleJsonResultError(
          data.JsonResult.Result,
          data.JsonResult.ErrorDescription,
          data.JsonResult.ErrorList,
          CONSTANTS.NOTIFICATIONS.SUCCESS
        )
      )
        return;
    }

    if (!params.Company) {
      delete params["Company"];
    } else {
      params.Name = params["Company"];
      delete params["Company"];
    }
    if (!params.Description) delete params["Description"];

    const { data } = yield axios.post(
      CONSTANTS.ROUTES.ACCOUNT_UPDATESETTINGS,
      new URLSearchParams({
        UpdatingMaster: false,
        AccountKeys: AccountKeys,
        ...params,
        Command: CONSTANTS.COMMANDS.ACCOUNT_UPDATESETTINGS,
      }),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (data.JsonResult.Result !== "Success") {
      errorResponse = data.JsonResult.Result;
    }
    if (!errorResponse) {
      toggleModal(false);
    } else {
      setError(errorResponse);
    }

    const store = Ext.getStore(storeId);
    if (store) {
      store.reload();
    }
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* updateAnonymousSettings(action) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.ACCOUNT_UPDATE_ANONYMOUS_SETTING,
      new URLSearchParams(action.anonymousData)
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* updateAnonymousSetting({ params }) {
  const { data } = yield axios.post(
    CONSTANTS.ROUTES.ACCOUNT_UPDATE_ANONYMOUS_SETTING,
    new URLSearchParams(params)
  );

  if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
    yield cancel();
  }

  if (
    !handleJsonResultError(
      data.JsonResult.Result,
      data.JsonResult.ErrorDescription,
      data.JsonResult.ErrorList,
      CONSTANTS.NOTIFICATIONS.SUCCESS
    )
  )
    return;

  //  yield call(fetchAccount);
}

function* getAgentsDefault() {
  const reduxState = yield select();

  const params = {
    AccountKey: reduxState.account.accountInfo.Account.AccountKey,
    Command: COMMANDS.AGENTS_DEFAULTS_GET,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.AGENTS_DEFAULTS_GET,
      new URLSearchParams(params)
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    yield put({
      type: SET_AGENTS_DEFAULT,
      data,
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* addCredit({ params, toggleModal }) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.BILLING_TRANSFER_PAGE_CREDITS,
      new URLSearchParams(params)
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    toggleModal(false);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* changeTimeZone({ selectedTimeZone }) {
  const reduxState = yield select();
  const params = {
    TimeZone: selectedTimeZone.Id,
    AccountKey: reduxState.account.accountInfo.Account.AccountKey,
    Command: COMMANDS.ACCOUNT_UPDATE_TIMEZONE,
  };
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.ACCOUNT_UPDATE_TIMEZONE,
      new URLSearchParams(params)
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    yield put({
      type: UPDATE_TIMEZONE,
      selectedTimeZone,
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getAccountOnDepartmentChange({ accKey, loadingCb }) {
  const { data } = yield axios.post(
    CONSTANTS.ROUTES.ACCOUNT_GET,
    new URLSearchParams({
      AccountKey: accKey,
      Command: CONSTANTS.COMMANDS.ACCOUNT_GET,
    })
  );

  if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
    yield cancel();
  }

  loadingCb();

  yield put({
    type: SET_ACCOUNT,
    payload: {
      accountInfo: data,
    },
  });
}

function* sessionExpired() {
  yield put({
    type: SET_SESSION_EXPIRED,
    payload: {
      sessionExpired: true,
    },
  });
}

function* sessionCancel() {
  yield put({
    type: "SESSION_CANCEL",
    payload: {
      sessionExpired: true,
    },
  });
}

function* getAccountSetting({ accountKey }) {
  const { data } = yield axios.post(
    CONSTANTS.ROUTES.ACCOUNT_GETACCOUNTSETTINGS,
    new URLSearchParams({
      AccountKeys: accountKey,
      Command: COMMANDS.ACCOUNT_GETACCOUNTSETTINGS,
    })
  );

  yield put({
    type: SET_ACCOUNT_SETTING,
    payload: data,
  });
}

function* updateSettings({ params }) {
  yield axios.post(
    CONSTANTS.ROUTES.ACCOUNT_UPDATECOMPANYNAME,
    new URLSearchParams(params)
  );

  window.location.reload();
}

function* savePayment({ params, closeModal }) {
  const { data } = yield axios.post(
    CONSTANTS.ROUTES.ACCOUNT_UPDATE_CREDIT_CARD,
    new URLSearchParams(params)
  );

  if (data.JsonResult.ErrorDescription) {
    notificationHandling.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: data.JsonResult.ErrorDescription,
      type: "danger",
    });
    return;
  }

  closeModal();
}

export default function* accountWatcher() {
  yield takeLatest(GET_ACCOUNT, fetchAccount);
  yield takeLatest(GET_ACCOUNT_AUTHORIZATIONURLS, fetchAccountAuthUrls);
  yield takeLatest(DEAUTHORIZE_ACCOUNT_DRIVES, accountDeauthorize);
  yield takeLatest(UPDATE_ANONYMOUS_SETTINGS, updateAnonymousSettings);
  yield takeLatest(GET_ACCOUNTSETTINGS, getAccountSettings);
  yield takeLatest(ACCOUNT_UPDATESETTINGS, updateAccountSettings);
  yield takeLatest(ACCOUNT_UPDATE_ANONYMOUS_SETTINGS, updateAnonymousSetting);
  yield takeLatest(AGENTS_DEFAULTS_GET, getAgentsDefault);
  yield takeLatest(ADD_CREDIT, addCredit);
  yield takeLatest(CHANGE_TIME_ZONE, changeTimeZone);
  yield takeLatest(
    GET_ACCOUNT_ON_DEPARTMENT_CHANGE,
    getAccountOnDepartmentChange
  );
  yield takeLatest(SESSION_EXPIRED, sessionExpired);
  yield takeLatest("SESSION_CANCEL", sessionCancel);
  yield takeLatest(GET_ACCOUNT_SETTING, getAccountSetting);
  yield takeLatest(UPDATE_ACCOUNT_SETTINGS, updateSettings);
  yield takeLatest(SAVE_PAYMENT_INFO, savePayment);
}
