import { DEPARTMENT_CONFIG_ICONS } from "../../../constants/config";

export const mapConfigCell = (value) => {
  const splitValue = value.includes("-") ? value.split("-") : value;
  const iconName = DEPARTMENT_CONFIG_ICONS[splitValue[0].toUpperCase()];
  const DESCRIPTION = splitValue[1];

  return {
    ...iconName,
    DESCRIPTION,
  };
};
