export const COMMANDS = {
  ACCOUNT_GET: "Account.Get",
  ACCOUNT_GETACCOUNTSETTINGS: "Account.GetAccountSettings",
  ACCOUNT_UPDATESETTINGS: "Account.UpdateSettings",
  ACCOUNT_UPDATECOMPANYNAME: "Account.UpdateCompanyName",
  ACCOUNT_GETNAME: "Account.GetName",
  ACCOUNT_AUTHORIZATIONURLS: "Account.AuthorizationUrls",
  ACCOUNT_CREATENEWGROUP: "Account.CreateNewGroup",
  ACCOUNT_ADDREMOVEGROUPS: "Account.AddRemoveGroups",
  DEAUTHORIZE_ACCOUNT_DRIVES: "Account.Deauthorize",
  ACCOUNT_UPDATE_TIMEZONE: "Account.UpdateTimeZone",

  AGENT_GET: "Agent.Get",
  AGENT_GETJOBSTATUS: "Agent.GetJobStatus",
  AGENT_GETBOOKMARKS_COMBOBOX_OPTIONS: "Agent.GetBookmarksComboBoxOptions",
  AGENT_GETJOBSTATISTICS: "Agent.GetJobStatistics",
  AGENT_GETPUBLISHERID: "Agent.GetPublisherID",
  AGENT_UPDATE: "Agent.Update",
  AGENT_DELETE: "Agent.Delete",
  AGENT_CANCEL: "Agent.Cancel",
  AGENT_MOVE: "Agent.Move",
  AGENT_GETMOVESTATES: "Agent.GetMoveStates",
  AGENT_GETNOTIFICATIONEVENTS: "Agent.GetNotificationEvents",
  AGENT_UPDATENOTIFICATIONEVENTS: "Agent.UpdateNotificationEvents",
  AGENT_GETJOBSACTIVE: "Agent.GetJobsActive",
  AGENT_HASUNIQUEFIELDS: "Agent.HasUniqueFields",
  AGENT_HASFILEFIELDS: "Agent.HasFileFields",
  AGENT_RUN: "Agent.Run",
  AGENT_STOP: "Agent.Stop",
  AGENT_RESUME: "Agent.Resume",
  AGENT_RESUBMIT_JOBS: "Agent.ResubmitJobs",
  AGENT_SKIPCURRENTLISTITEM: "Agent.SkipCurrentListItem",
  AGENT_GETBOOKMARKS_COMBO_BOX_OPTIONS: "Agent.GetBookmarksComboBoxOptions",
  AGENT_GET_JOB_STATUS: "Agent.GetJobStatus",
  GET_TIMEZONES: "Common.GetTimeZones",
  AGENTS_DEFAULTS_GET: "Agent.DefaultsGet",
  AGENT_GET_STATUS_COUNTS: "Agent.GetStatusCounts",
  JOB_SYNC: "Job.Sync",
  USER_GET_PICKER_LIST: "User.GetPickerList",

  TEMPLATE_ASSIGN: "Template.Assign",
  TEMPLATE_UNASSIGN: "Template.Unassign",
  TEMPLATE_DELETE: "Template.Delete",
  TEMPLATE_GETSYNCHRONIZEINFO: "Template.GetSynchronizeInfo",
  TEMPLATE_SYNCHRONIZE: "Template.Synchronize",

  BILLING_GETINVOICE: "Billing.GetInvoice",
  BILLING_GETINVOICEHTML: "Billing.GetInvoiceHtml",
  BILLING_GETINVOICELIST: "Billing.GetInvoiceList",
  BILLING_TRANSFER_PAGE_CREDITS: "Billing.TransferPageCredits",
  BILLING_DOWNLOADINVOICELIST: "Billing.DownloadInvoiceList",
  BILLING_DOWNLOADINVOICEPDF: "Billing.DownloadInvoicePdf",
  AGENT_BUILTBYPREVIOUS: "Agent.BuiltByPrevious",

  COLLECTION_GETCOMPLETE: "Collection.GetComplete",
  COLLECTION_GETFIELDS: "Collection.GetFields",
  COLLECTION_GETVIEWS: "Collection.GetViews",
  COLLECTION_UPDATE: "Collection.Update",
  COLLECTION_UPDATESTATEPAGE: "Collection.UpdateStatePage",
  COLLECTION_UPDATESTATEVIEW: "Collection.UpdateStateView",
  COLLECTION_UPDATESTATESEARCH: "Collection.UpdateStateSearch",
  COLLECTION_GET: "Collection.Get",
  COLLECTION_UPDATEFIELDS: "Collection.UpdateFields",
  COLLECTION_SETUNIQUEFIELDS: "Collection.SetUniqueFields",
  COLLECTION_DEDUPECOUNT: "Collection.DeDupeCount",
  COLLECTION_DEDUPE: "Collection.DeDupe",
  COLLECTION_REBUILD: "Collection.Rebuild",
  COLLECTION_DELETEFIELDS: "Collection.DeleteFields",
  COLLECTION_DELETE: "Collection.Delete",
  COLLECTION_DELETE_ITEMS: "Collection.DeleteItems",
  COLLECTION_EMPTY: "Collection.Empty",
  COLLECTION_GETAGENTID: "Collection.GetAgentID",
  COLLECTION_DELETEPACKAGEFILE: "Collection.DeletePackageFile",
  COLLECTION_REPACKAGEFILE: "Collection.RePackageFile",
  COLLECTION_CREATE_NEW_FROM_SCRATCH: "Collection.CreateNewFromScratch",
  COLLECTION_CREATE_NEW_FROM_FILE: "Collection.CreateNewFromFile",
  COLLECTION_GET_COLUMN_HEADINGS_FROM_FILE:
    "Collection.GetColumnHeadingsFromFile",
  COLLECTION_UPDATEITEMSFROMFILE: "Collection.UpdateItemsFromFile",
  COLLECTION_CREATE_NEW_FROM_EXISTING: "Collection.CreateNewFromExisting",

  COMMON_GETSYSTEMBILLINGINFO: "Common.GetSystemBillingInfo",
  COMMON_GETWEBCONSOLEBETASETTINGS: "Common.GetWebConsoleBetaSettings",
  COLLECTION_UPDATEITEM: "Collection.UpdateItem",
  COLLECTION_DELETEITEMS: "Collection.DeleteItems",

  FOLDER_ADD: "Folder.Add",
  COOKIESTORE_GET: "CookieStore.Get",
  COOKIESTORE_UPDATE: "CookieStore.Update",
  COOKIESTORE_ADD: "CookieStore.Add",
  COOKIE_STORE_ADD_UPDATE_COOKIE: "CookieStore.AddUpdateCookie",
  COOKIESTORE_DELETE: "CookieStore.Delete",

  FOLDER_GET: "Folder.Get",
  FOLDER_GETLIST: "Folder.GetList",
  FOLDER_GETITEMS: "Folder.GetItems",
  UPDATE_FOLDERS: "Folder.UpdateFolders",
  UPDATE_FOLDER: "Folder.Update",
  UPDATE_FOLDER_ITEMS: "Folder.UpdateItems",

  JOB_GETDETAILS: "Job.GetDetails",

  FOLDER_GETCOMPLETE: "Folder.GetComplete",
  FOLDER_DELETE: "Folder.Delete",

  PUBLISHER_GETRSSPUBLISHERITEM: "Publisher.GetRssPublisherItem",

  SEQUENCE_GET: "Sequence.Get",
  SEQUENCE_GET_STEP_INFO: "Sequence.GetStepInfo",
  SEQUENCE_UPDATE: "Sequence.Update",
  SEQUENCE_GETNOTIFICATIONEVENTS: "Sequence.GetNotificationEvents",
  SEQUENCE_UPDATENOTIFICATIONEVENTS: "Sequence.UpdateNotificationEvents",
  STORAGE_GETUSAGE: "Storage.GetUsage",
  STORAGE_DOWNLOADCOLLECTIONSTORAGESUMMARY:
    "Storage.DownloadCollectionStorageSummary",
  STORAGE_DOWNLOADCOLLECTIONSTORAGE: "Storage.DownloadCollectionStorage",
  SEQUENCE_DELETE: "Sequence.Delete",
  SEQUENCE_DELETESTEP: "Sequence.DeleteStep",
  SEQUENCE_RUN: "Sequence.Run",
  SEQUENCE_PAUSE: "Sequence.Pause",
  SEQUENCE_RESUME: "Sequence.Resume",
  SEQUENCE_CANCEL: "Sequence.Cancel",
  SEQUENCE_GET_AGENT_INFO: "Sequence.GetAgentInfo",
  SEQUENCE_GET_ERROR_CODES: "Sequence.GetErrorCodes",
  SEQUENCE_UPDATE_ADD_STEP: "Sequence.UpdateAddStep",
  SEQUENCE_GET_STATUS: "Sequence.GetStatus",
  SEQUENCE_ADD: "Sequence.Add",
  SEQUENCE_SET_STEPS_TO_RUN_CONCURRENTLY: "Sequence.SetStepsToRunConcurrently",
  ADD_STEP_TO_EXISTING_GROUP: "Sequence.AddToConcurrencyGroup",
  REMOVE_STEPS_FROM_GROUP: "Sequence.RemoveStepsFromGroup",
  GET_DELETE_VIEW_ITEMS: "Collection.GetViews",

  TEMPLATE_GETLIST: "Template.GetList",
  TEMPLATE_GETSUMMARYSTATS: "Template.GetSummaryStats",
  TEMPLATE_GETAGENTCOMBOBOXOPTIONS: "Template.GetAgentComboBoxOptions",
  TEMPLATE_GETMERGEDEFAULTS: "Template.GetMergeDefaults",
  TEMPLATE_CREATENEWFROMMULTIPLE: "Template.CreateNewFromMultiple",
  TEMPLATE_CREATENEWFROMSCRATCH: "Template.CreateNewFromScratch",
  TEMPLATE_VALIDATENAME: "Template.ValidateName",

  UPDATE_STATEPAGE: "Collection.UpdateStatePage",
  UPDATE_STATESEARCH: "Collection.UpdateStateSearch",
  UPDATE_STATEVIEW: "Update.StateView",
  USER_GETACCOUNTS: "User.GetAccounts",
  USER_GETEXISTINGACCOUNTS: "User.GetExistingAccounts",
  USER_VALIDATE: "User.Validate",
  UTILIZATION_GETUSAGE: "Utilization.GetUsage",

  VIEW_ADDLAYOUT: "View.AddLayout",
  VIEW_GET: "View.Get",
  VIEW_GETFIELDS: "View.GetFields",
  VIEW_GETITEMS: "View.GetItems",
  VIEW_DELETE: "View.Delete",

  PUBLISHER_ADD: "Publisher.Add",
  COMMON_GENERATEGUID: "Common.GenerateGuid",
  PUBLISHER_UPDATE: "Publisher.Update",
  PUBLISH: "Publisher.Publish",
  GET_PUBLISHERLIST: "Agent.GetPublisherList",
  PUBLISHER_TEST: "Publisher.Test",

  USER_GET: "User.Get",
  USER_GETPICKERLIST: "User.GetPickerList",
  CLIENT_COMMANDRESULT: "Client.GetClientCommandReceivedResult",
  USER_GETLIST: "User.GetList",
  USER_GETADMINEMAILS: "User.GetAdminEmails",
  USER_DELETE: "User.Delete",
  ACCOUNT_INVITE_USERS: "Account.InviteUsers",
  USER_ADD: "User.Add",
  USER_UPDATE: "User.Update",
  USER_CHECK_EMAIL_ADDRESS: "User.CheckEmailAddress",
  USER_GENERATE_WEBSERVICE_KEY: "User.GenerateWebServiceKey",

  COLLECTION_GETPACKAGEFILESLIST: "Collection.GetPackageFilesList",
  COLLECTION_REPACKAGEFILES: "Collection.RePackageFiles",
  COLLECTION_RENAMEPACKAGEFILE: "Collection.RenamePackageFile",

  SCHEDULE_GET: "Schedule.Get",
  SCHEDULE_ADD: "Schedule.Add",
  SCHEDULE_UPDATE: "Schedule.Update",

  USER_GETRECENTITEMS: "User.GetRecentItems",
  USER_ADD_RECENT_ITEMS: "User.AddRecentItem",
  ACCOUNT_UPDATE_ANONYMOUS_SETTING: "Account.UpdateAnonymousSetting",
  ACCOUNT_UPDATEINVOICEEMAILS: "Account.UpdateInvoiceEmails",
  COMMON_GETWEBLOCATIONS: "Common.GetWebLocations",

  EVENT_GETBYACTION: "Event.GetByAction",
  UTILIZATION_DOWNLOADAGENTPAGES: "Utilization.DownloadAgentPages",

  ACCOUNT_CHANGE_INVOICE_DATE: "Account.ChangeInvoiceDate",
  BILLING_ADD_TRANSACTION: "Billing.AddTransaction",

  USER_ADD_EXISTING_USERS: "User.AddExistingUsers",
  USER_LOGIN: "User.Login",

  SETTING_DELETE: "Setting.Delete",
  SETTING_COPY: "Setting.Copy",
  SETTING_UPDATESINGLE: "Setting.UpdateSingle",

  BILLING_GET_BILL_ELEMENTS_FOR_RATE_PLAN: "Billing.GetBillElementsForRatePlan",
  DOMAIN_LOOKUP: "Domain.Lookup",
  RATE_PLAN_GET: "RatePlan.Get",
  CLONE_RATE_PLAN: "RatePlan.Clone",

  BILLING_GET_MOST_RECENT_STORAGE_INVOICE:
    "Billing.GetMostRecentStorageInvoice",
  ACCOUNT_UPDATE_ADMIN_EDIT: "Account.UpdateAdminEdit",
  MOVE_STEP_DRAG: "Sequence.MoveStepDrag",
  SEQUENCE_MOVE_STEP_DRAG: "Sequence.MoveStepDrag",

  GET_ITEM_HISTORYLIST: "Collection.GetItemHistoryList",
  GET_ITEM_HISTORYDIFFHTML: "Collection.GetItemHistoryDiffHtml",

  VIEW_UPDATE_FIELD_STATE: "View.UpdateFieldState",
  ACCOUNT_UPDATE_CREDIT_CARD: "Account.UpdateCreditCard",
};
