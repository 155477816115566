import {
  GET_AGENT_DATA,
  SET_AGENT_DATA,
  AGENT_INFO_LOADED,
  ITEM_FILTER_AGENT_DATA,
  SET_AGENT_DATA_SAVED_VIEWS,
  GET_AGENT_DATA_SAVED_VIEWS,
  FILTER_AGENT_DATA,
  REMOVE_FILTERS,
  UPDATE_STATEVIEW,
  UPDATE_BOOKMARK_LIST,
  SET_BOOKMARK_LIST,
  COLLECTION_UPDATEITEM,
  COLLECTION_DELETEITEMS,
  GET_ITEM_HISTORY,
  SET_ITEM_HISTORY,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import {
  takeLatest,
  take,
  put,
  select,
  call,
  cancel,
} from "redux-saga/effects";
import {
  renderItemHistoryCell,
  renderMarkupChangeCell,
} from "../../Components/Grid/SenchaEditableLockedGrid/renderers";
import axios from "axios";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";

const Ext = window["Ext"];

function prepareModel(data, store) {
  const model = store.config.model;

  model.setFields(data);
  return model;
}

function prepareGridColumns(
  data,
  storeData,
  ShowHistoryColumn,
  callback,
  ShowMarkup
) {
  if (storeData.length === 0) {
    return data.ViewFields.map((field) => {
      let text = `<span title='${field.Name}'> ${field.Name}</span>`;
      if (field.IsUnique) {
        text = `<span title='${field.Name}'><i class="fas fa-key"></i>${field.Name}</span>`;
      }
      if (field.IsWatched) {
        text = `<span title='${field.Name}'><i class="fas fa-eye"></i>${field.Name}</span>`;
      }
      return {
        dataIndex: field.Name,
        text,
        locked: field.IsSticky,
        hidden: field.IsHidden,
        width: field.ColumnWidth,
        editable: !field.IsReadOnly && !field.IsSystem,
        cell: {
          height: 50,
        },
      };
    });
  }

  let highlightClass = (field) => {
    const selectedItemIndex = missingAgentFieldNames.findIndex(
      (item) => item === field.Name
    );
    if (selectedItemIndex > -1) {
      return "not-a-template-field";
    } else {
      return "";
    }
  };

  let agentFields = Object.keys(storeData[0].data);
  let viewFields = data.ViewFields;
  let missingAgentFieldNames = [];

  for (let i = 0; i < viewFields.length; i++) {
    let viewFieldName = viewFields[i].Name.toLowerCase();
    let foundField = false;

    for (let index = 0; index < agentFields.length; index++) {
      let agentFieldName = agentFields[index].toLowerCase();
      if (agentFieldName === viewFieldName) {
        foundField = true;
        break;
      }
    }

    if (!foundField) missingAgentFieldNames.push(viewFields[i].Name);
  }

  let columnItems = [];
  if (ShowHistoryColumn) {
    columnItems.push({
      dataIndex: "history",
      locked: true,
      hidden: ShowHistoryColumn ? false : true,
      width: ShowHistoryColumn ? 50 : 1,
      editable: false,
      menuDisabled: true,
      resizable: false,
      sortable: false,
      cell: {
        encodeHtml: true,
        xtype: "reactcell",
      },
      renderer: (value, record) =>
        renderItemHistoryCell(value, record, callback),
    });
  } else {
    columnItems.push({
      dataIndex: "history",
      locked: true,
      hidden: true,
      width: 1,
      editable: false,
      menuDisabled: true,
      resizable: false,
      sortable: false,
    });
  }

  columnItems = [
    ...columnItems,
    ...data.ViewFields.map((field) => ({
      ...field,
      dataIndex: field.Name,
      text: `<span title='${field.Name}'>${field.Name}</span>`,
      locked: field.IsSticky,
      hidden: field.IsHidden,
      width: field.ColumnWidth,
      editable:
        !field.IsReadOnly &&
        !field.IsSystem &&
        !(ShowMarkup && field.IsWatched),
      cls: highlightClass(field),
      cell: {
        height: 50,
        xtype: "reactcell",
      },
      renderer: (value, record) =>
        renderMarkupChangeCell(
          record,
          field,
          data.Collection.CollectionID,
          callback,
          field.IsWatched
        ),
    })),
  ];

  return columnItems;
}

function getStoreData(store, params, PageIndex) {
  return new Promise((resolve) => {
    store.baseParams = {
      ...params,
    };

    store.getProxy().extraParams = store.baseParams;
    store.loadPage(PageIndex === 0 ? 1 : PageIndex, {
      params,
      callback: (records) => {
        resolve(records);
      },
    });
  });
}

function* createStore(storeId, data, collectionInfo, formParams, callback) {
  const store = Ext.getStore(storeId);
  let state = yield select();
  let ShowHistoryColumn =
    state.agents?.agentInfo?.Config?.includes("TrackHistory") &&
    formParams.BookmarkID !== -1;

  if (store) {
    const model = prepareModel(data, store);

    store.removeAll(true);
    store.setModel(model);

    const {
      SearchText,
      SearchField,
      CollectionID,
      PageIndex,
    } = data.CollectionState;

    const params = {
      CollectionID,
      ViewID: data.ViewRow.ViewID,
      FolderID: "",
      AccountKey: "",
      AgentID: data.AgentID,
      BookmarkID: formParams.BookmarkID,
      JobID: -1,
      ItemHistoryRangeStart: "",
      ItemHistoryRangeEnd: "",
      ItemStatusesToInclude:
        formParams.ItemStatusesToInclude || "Added, Changed, Unchanged",
      ColumnsToAlwaysInclude: "Name",
      ForceViewSortable: "",
      AlwaysIncludeCustomFields: false,
      IncludeAdditionalCustomInterfaceColumns: "",
      SelectedIDs: "",
      ShowHistoryColumn,
      ShowSelected: false,
      TemplateCollectionID: 0,
      SearchText,
      SearchField,
      ShowMarkup: formParams.ShowMarkup || false,
      Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
    };

    const storeData = yield call(getStoreData, store, params, PageIndex);
    const columns = prepareGridColumns(
      data,
      storeData,
      ShowHistoryColumn,
      callback,
      formParams.ShowMarkup || false
    );

    yield put({
      type: SET_AGENT_DATA,
      payload: {
        columns,
        collectionInfo: {
          ...collectionInfo,
          BookmarkID: formParams.BookmarkID,
        },
        collectionState: data.CollectionState,
        agentDataViewRow: data.ViewRow,
        viewFields: data.ViewFields,
      },
    });
  }
}

function* fetchAgentData({ type: action, storeId, formParams = {}, callback }) {
  const agentInfo = yield select((state) => state.agents.agentInfo);
  if (!agentInfo.ItemID || agentInfo.ItemID !== Number(formParams.AgentID)) {
    yield take(AGENT_INFO_LOADED);
  }

  const collectionId = yield select(
    (state) => state.agents.agentInfo.CollectionID
  );

  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETCOMPLETE,
    CollectionID: collectionId,
    AccountKey: "",
    ViewID: formParams.ViewID || "",
    OverrideViewName: "",
    HideSystemViewNames: "",
    ...formParams,
    BookmarkID: formParams.BookmarkID || 0,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    let collectionInfo = {};

    for (let key in data.Collection) {
      if (CONSTANTS.RESPONSE_CONTEXT.COLLECTION_INFO_CONTEXT.includes(key)) {
        collectionInfo[key] = data.Collection[key];
      }
    }

    yield call(
      createStore,
      storeId,
      data,
      collectionInfo,
      formParams,
      callback
    );
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* bookmarkFilterData({ type: action, storeId, formParams = {} }) {
  const collectionId = yield select(
    (state) => state.agents.agentInfo.CollectionID
  );

  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETCOMPLETE,
    CollectionID: collectionId || "",
    AccountKey: "",
    ViewID: formParams.viewId || "",
    OverrideViewName: "",
    HideSystemViewNames: "",
    ...formParams,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    let collectionInfo = {};

    for (let key in data.Collection) {
      if (CONSTANTS.RESPONSE_CONTEXT.COLLECTION_INFO_CONTEXT.includes(key)) {
        collectionInfo[key] = data.Collection[key];
      }
    }

    yield call(createStore, storeId, data, collectionInfo, formParams);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* fetchSavedViews({ type: action, formParams = {} }) {
  const agentInfo = yield select((state) => state.agents.agentInfo);
  if (!agentInfo.ItemID || agentInfo.ItemID !== Number(formParams.AgentID)) {
    yield take(AGENT_INFO_LOADED);
  }

  const collectionId = yield select(
    (state) => state.agents.agentInfo.CollectionID
  );

  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETVIEWS,
    CollectionID: collectionId,
    AccountKey: "",
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETVIEWS,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    let agentDataSavedViews = [];

    data.ViewTable.forEach((view) => {
      let savedView = {};
      for (let key in view) {
        if (CONSTANTS.RESPONSE_CONTEXT.SAVED_VIEWS_CONTEXT.includes(key)) {
          savedView[key] = view[key];
        }
      }
      agentDataSavedViews.push(savedView);
    });

    yield put({
      type: SET_AGENT_DATA_SAVED_VIEWS,
      payload: {
        agentDataSavedViews,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* filterAgentData({ storeId, filter, dropDownValue }) {
  const store = Ext.getStore(storeId);

  const CollectionState = yield select((state) => state.agents.collectionState);
  let searchField = dropDownValue;
  if (dropDownValue === "All Fields") {
    searchField = "";
  } else if (dropDownValue === "View Fields") {
    searchField = "AllViewFields";
  }

  store.load({
    params: {
      ...CollectionState,
      SearchText: filter,
      SearchField: searchField,
      Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
    },
  });
}

function* removeFilters({ type: action, storeId, formParams = {} }) {
  const store = Ext.getStore(storeId);

  const params = {
    Command: CONSTANTS.COMMANDS.UPDATE_STATESEARCH,
    AccountKey: "",
    ...formParams,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.UPDATE_STATESEARCH,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    store.load({
      params: {
        SearchText: formParams.SearchText,
        SearchField: formParams.SearchField,
        Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* updateStateView({ type: action, formParams = {} }) {
  const params = {
    Command: CONSTANTS.COMMANDS.UPDATE_STATEVIEW,
    AccountKey: "",
    ...formParams,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.UPDATE_STATEVIEW,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* updateBookmarkList(action) {
  yield put({
    type: SET_BOOKMARK_LIST,
    trackHistory: action.trackHistory,
  });
}

function* updateItem({ STORE_ID, params }) {
  try {
    const store = Ext.getStore(STORE_ID);
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_UPDATEITEM,
      new URLSearchParams(params),
      {
        headers: {},
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    store.load();
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* deleteItems({ STORE_ID, params }) {
  try {
    const store = Ext.getStore(STORE_ID);
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_DELETEITEMS,
      new URLSearchParams(params),
      {
        headers: {},
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    store.removeAll();
    store.load();
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getHistory({ type: action, formParams }) {
  try {
    const { data: itemHistoryList } = yield axios.post(
      CONSTANTS.ROUTES.GET_ITEM_HISTORYLIST,
      new URLSearchParams({
        BookmarkID: formParams.BookmarkID,
        ItemID: formParams.ItemID,
        FieldName: formParams.FieldName,
        CollectionID: formParams.CollectionID,
        Command: CONSTANTS.COMMANDS.GET_ITEM_HISTORYLIST,
      }),
      {
        headers: { action },
      }
    );

    if (itemHistoryList.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    let collectionItemIDs = itemHistoryList.CollectionItemIDs.sort();

    const { data } = yield axios.post(
      CONSTANTS.ROUTES.GET_ITEM_HISTORYDIFFHTML,
      new URLSearchParams({
        FieldName: formParams.FieldName,
        CollectionItemID1: collectionItemIDs[0],
        CollectionItemID2: collectionItemIDs[1],
        CollectionID: formParams.CollectionID,
        Command: CONSTANTS.COMMANDS.GET_ITEM_HISTORYDIFFHTML,
      }),
      {
        headers: { action },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    yield put({
      type: SET_ITEM_HISTORY,
      agentItemHistory: data,
    });
  } catch {
    //
  }
}

export default function* agentDataWatcher() {
  yield takeLatest(GET_AGENT_DATA, fetchAgentData);
  yield takeLatest(GET_AGENT_DATA_SAVED_VIEWS, fetchSavedViews);
  yield takeLatest(ITEM_FILTER_AGENT_DATA, bookmarkFilterData);
  yield takeLatest(FILTER_AGENT_DATA, filterAgentData);
  yield takeLatest(REMOVE_FILTERS, removeFilters);
  yield takeLatest(UPDATE_STATEVIEW, updateStateView);
  yield takeLatest(UPDATE_BOOKMARK_LIST, updateBookmarkList);
  yield takeLatest(COLLECTION_UPDATEITEM, updateItem);
  yield takeLatest(COLLECTION_DELETEITEMS, deleteItems);
  yield takeLatest(GET_ITEM_HISTORY, getHistory);
}
