import {
  DELETE_SYSTEM_RATE_PLANS_SAVED_VIEW,
  DELETE_SYSTEM_RATE_PLAN_VIEW_ERROR,
  SET_SYSTEM_RATE_PLANS_SAVED_VIEWS,
  SET_SYSYTEM_RATE_PLANS,
  SYSTEM_RATE_PLANS_UPDATEVIEWTABLE,
  UPDATE_SYSTEM_RATE_PLANS_VIEW_ROW,
  SET_SYSTEM_RATE_PLAN_VIEW_ERROR,
  SET_SYSTEM_SETTINGS,
  SET_SYSTEM_SETTINGS_SAVED_VIEWS,
  SYSTEM_SETTINGS_UPDATE_STATE_SEARCH,
  UPDATE_SYSTEM_SETTINGS_VIEW_ROW,
  SET_SYSTEM_INSIGHTS,
  UPDATE_SYSTEM_INSIGHTS_STATE_SEARCH,
  SET_DOMAIN_LOOKUP_COLUMNS,
  SET_RATE_PLAN,
  SETTINGS_UPDATEVIEWTABLE,
  UPDATE_SETTINGS_VIEW_ROW,
  SET_SETTINGS_VIEW_ERROR,
  DELETE_SETTINGS_SAVED_VIEW,
  SET_SYSTEM_INSIGHTS_SAVED_VIEWS,
} from "../types";
import createReducer from "../createReducer";

const initialState = {
  settingsColumns: [],
  settingsCollectionState: {},
  settingsCollectionInfo: {},
  settingsViewRow: {},
  settingsSortedBy: [],
  settingsViewFields: [],
  settingsSavedViews: [],
  columns: [],
  collectionState: {},
  viewRow: {},
  savedViews: [],
  viewFields: [],
  error: null,
  systemInsightsColumns: [],
  systemInsightsCollectionState: {},
  systemInsightsViewFields: null,
  systemInsightsViewRow: {},
  systemInsightsSavedViews: [],
};

export const system = createReducer(initialState, {
  [SET_SYSYTEM_RATE_PLANS](state, { payload }) {
    return {
      ...state,
      columns: payload.columns,
      viewRow: payload.viewRow,
      collectionState: payload.collectionState,
      viewFields: payload.viewFields.map((viewField) => {
        return {
          name: viewField.Name,
          value: viewField.Name,
          fieldId: viewField.FieldID,
        };
      }),
      refreshed: payload.refreshed,
    };
  },
  [SET_SYSTEM_SETTINGS](state, { payload }) {
    return {
      ...state,
      settingsColumns: payload.columns,
      settingsCollectionState: payload.collectionState,
      settingsCollectionInfo: payload.collectionInfo,
      settingsViewRow: payload.viewRow,
      settingsSortedBy: payload.sortedBy,
      settingsViewFields: payload.viewFields.map((viewField) => {
        return {
          name: viewField.Name,
          value: viewField.Name,
          fieldId: viewField.FieldID,
        };
      }),
      settingsFieldOperators: payload.fieldOperators,
    };
  },
  [SET_SYSTEM_RATE_PLANS_SAVED_VIEWS](state, { systemRatePlansSavedViews }) {
    return {
      ...state,
      savedViews: systemRatePlansSavedViews,
    };
  },
  [SET_SYSTEM_SETTINGS_SAVED_VIEWS](state, { payload }) {
    return {
      ...state,
      settingsSavedViews: payload.agentsSavedViews,
    };
  },
  [SYSTEM_SETTINGS_UPDATE_STATE_SEARCH](state, { searchFilterValues }) {
    return {
      ...state,
      settingsCollectionState: {
        ...state.settingsCollectionState,
        SearchText: searchFilterValues.searchText,
        SearchField:
          searchFilterValues.searchField !== "View Fields"
            ? searchFilterValues.searchField
            : "AllViewFields",
      },
    };
  },
  [UPDATE_SYSTEM_SETTINGS_VIEW_ROW](state, action) {
    return {
      ...state,
      settingsViewRow: {
        ...state.settingsViewRow,
        ViewID: action.viewId,
      },
    };
  },

  [SYSTEM_RATE_PLANS_UPDATEVIEWTABLE](state, { newViews }) {
    return {
      ...state,
      savedViews: newViews,
    };
  },
  [UPDATE_SYSTEM_RATE_PLANS_VIEW_ROW](state, action) {
    return {
      ...state,
      viewRow: {
        ...state.viewRow,
        ViewID: action.viewId,
      },
    };
  },
  [DELETE_SYSTEM_RATE_PLANS_SAVED_VIEW](state, action) {
    return {
      ...state,
      viewRow: {
        ...state.viewRow,
        ViewID: action.viewId,
      },
    };
  },
  [DELETE_SYSTEM_RATE_PLAN_VIEW_ERROR](state) {
    return {
      ...state,
      error: null,
    };
  },

  [DELETE_SETTINGS_SAVED_VIEW](state, action) {
    return {
      ...state,
      settingsViewRow: {
        ...state.settingsViewRow,
        ViewID: action.viewId,
      },
    };
  },
  [SET_SYSTEM_RATE_PLAN_VIEW_ERROR](state, { error }) {
    return {
      ...state,
      error,
    };
  },
  [SET_SYSTEM_INSIGHTS](state, { payload }) {
    return {
      ...state,
      systemInsightsColumns: payload.columns,
      systemInsightsCollectionState: payload.collectionState,
      systemInsightsViewRow: payload.viewRow,
      systemInsightsViewFields: payload.viewFields.map((viewField) => {
        return {
          name: viewField.Name,
          value: viewField.Name,
          fieldId: viewField.FieldID,
        };
      }),
    };
  },
  [UPDATE_SYSTEM_INSIGHTS_STATE_SEARCH](state, { searchFilterValues }) {
    return {
      ...state,
      systemInightsCollectionState: {
        ...state.systemInightsCollectionState,
        SearchText: searchFilterValues.searchText,
        SearchField:
          searchFilterValues.searchField !== "View Fields"
            ? searchFilterValues.searchField
            : "AllViewFields",
      },
    };
  },
  [SET_SYSTEM_INSIGHTS_SAVED_VIEWS](state, { payload }) {
    return {
      ...state,
      systemInsightsSavedViews: payload.systemInsightsSavedViews,
    };
  },
  [SET_SETTINGS_VIEW_ERROR](state, { error }) {
    return {
      ...state,
      error,
    };
  },

  [SET_DOMAIN_LOOKUP_COLUMNS](state, { columns }) {
    return {
      ...state,
      domainLookupColumns: columns,
    };
  },

  [SET_RATE_PLAN](state, { payload }) {
    return {
      ...state,
      cloneRatePlanInfo: payload,
    };
  },
  [SETTINGS_UPDATEVIEWTABLE](state, { newViews }) {
    return {
      ...state,
      settingsSavedViews: newViews,
    };
  },
  [UPDATE_SETTINGS_VIEW_ROW](state, action) {
    return {
      ...state,
      settingsViewRow: {
        ...state.settingsViewRow,
        ViewID: action.viewId,
      },
    };
  },
});
