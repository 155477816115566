import { COMMANDS } from "./commands";

const url = `${process.env.REACT_APP_API_URL}/json2`;

export const ROUTES = {
  PUBLIC_MAINTENANCE: "/public/maintenance",
  ACCOUNT_GET: `${url}/${COMMANDS.ACCOUNT_GET}`,
  ACCOUNT_GETACCOUNTSETTINGS: `${url}/${COMMANDS.ACCOUNT_GETACCOUNTSETTINGS}`,
  ACCOUNT_UPDATESETTINGS: `${url}/${COMMANDS.ACCOUNT_UPDATESETTINGS}`,
  ACCOUNT_UPDATECOMPANYNAME: `${url}/${COMMANDS.ACCOUNT_UPDATECOMPANYNAME}`,
  ACCOUNT_GETNAME: `${url}/${COMMANDS.ACCOUNT_GETNAME}`,
  ACCOUNT_CREATENEWGROUP: `${url}/${COMMANDS.ACCOUNT_CREATENEWGROUP}`,
  ACCOUNT_AUTHORIZATIONURLS: `${url}/${COMMANDS.ACCOUNT_AUTHORIZATIONURLS}`,
  ACCOUNT_ADDREMOVEGROUPS: `${url}/${COMMANDS.ACCOUNT_ADDREMOVEGROUPS}`,
  ACCOUNT_UPDATE_TIMEZONE: `${url}/${COMMANDS.ACCOUNT_UPDATE_TIMEZONE}`,

  USER_GET_PICKER_LIST: `${url}/${COMMANDS.USER_GET_PICKER_LIST}`,
  DEAUTHORIZE_ACCOUNT_DRIVES: `${url}/${COMMANDS.DEAUTHORIZE_ACCOUNT_DRIVES}`,
  AGENT_GET: `${url}/${COMMANDS.AGENT_GET}`,
  AGENT_GETBOOKMARKS_COMBOBOX_OPTIONS: `${url}/${COMMANDS.AGENT_GETBOOKMARKS_COMBOBOX_OPTIONS}`,
  AGENT_GETJOBSTATUS: `${url}/${COMMANDS.AGENT_GETJOBSTATUS}`,
  AGENT_GETJOBSTATISTICS: `${url}/${COMMANDS.AGENT_GETJOBSTATISTICS}`,
  AGENT_GETPUBLISHERID: `${url}/${COMMANDS.AGENT_GETPUBLISHERID}`,
  AGENT_UPDATE: `${url}/${COMMANDS.AGENT_UPDATE}`,
  AGENT_CANCEL: `${url}/${COMMANDS.AGENT_CANCEL}`,
  AGENT_MOVE: `${url}/${COMMANDS.AGENT_MOVE}`,
  AGENT_GETMOVESTATES: `${url}/${COMMANDS.AGENT_GETMOVESTATES}`,
  AGENT_DELETE: `${url}/${COMMANDS.AGENT_DELETE}`,
  AGENT_STOP: `${url}/${COMMANDS.AGENT_STOP}`,
  AGENT_RESUME: `${url}/${COMMANDS.AGENT_RESUME}`,
  AGENT_GETNOTIFICATIONEVENTS: `${url}/${COMMANDS.AGENT_GETNOTIFICATIONEVENTS}`,
  AGENT_UPDATENOTIFICATIONEVENTS: `${url}/${COMMANDS.AGENT_UPDATENOTIFICATIONEVENTS}`,
  AGENT_RUN: `${url}/${COMMANDS.AGENT_RUN}`,
  AGENT_RESUBMIT_JOBS: `${url}/${COMMANDS.AGENT_RESUBMIT_JOBS}`,
  AGENT_SKIPCURRENTLISTITEM: `${url}/${COMMANDS.AGENT_SKIPCURRENTLISTITEM}`,
  AGENT_GETBOOKMARKS_COMBO_BOX_OPTIONS: `${url}/${COMMANDS.AGENT_GETBOOKMARKS_COMBO_BOX_OPTIONS}`,
  GET_TIMEZONES: `${url}/${COMMANDS.GET_TIMEZONES}`,
  AGENTS_DEFAULTS_GET: `${url}/${COMMANDS.AGENTS_DEFAULTS_GET}`,
  JOB_SYNC: `${url}/${COMMANDS.JOB_SYNC}`,

  AGENT_GETJOBSACTIVE: `${url}/${COMMANDS.AGENT_GETJOBSACTIVE}`,
  AGENT_HASUNIQUEFIELDS: `${url}/${COMMANDS.AGENT_HASUNIQUEFIELDS}`,
  AGENT_HASFILEFIELDS: `${url}/${COMMANDS.AGENT_HASFILEFIELDS}`,
  AGENT_BUILTBYPREVIOUS: `${url}/${COMMANDS.AGENT_BUILTBYPREVIOUS}`,

  BILLING_GETINVOICE: `${url}/${COMMANDS.BILLING_GETINVOICE}`,
  BILLING_GETINVOICEHTML: `${process.env.REACT_APP_API_URL}/Invoice`,
  BILLING_GETINVOICELIST: `${url}/${COMMANDS.BILLING_GETINVOICELIST}`,
  BILLING_TRANSFER_PAGE_CREDITS: `${url}/${COMMANDS.BILLING_TRANSFER_PAGE_CREDITS}`,
  BILLING_DOWNLOADINVOICELIST: `${url}/${COMMANDS.BILLING_DOWNLOADINVOICELIST}`,
  BILLING_DOWNLOADINVOICEPDF: `${url}/${COMMANDS.BILLING_DOWNLOADINVOICEPDF}`,

  TEMPLATE_ASSIGN: `${url}/${COMMANDS.TEMPLATE_ASSIGN}`,
  TEMPLATE_UNASSIGN: `${url}/${COMMANDS.TEMPLATE_UNASSIGN}`,
  TEMPLATE_DELETE: `${url}/${COMMANDS.TEMPLATE_DELETE}`,
  TEMPLATE_GETSYNCHRONIZEINFO: `${url}/${COMMANDS.TEMPLATE_GETSYNCHRONIZEINFO}`,
  TEMPLATE_SYNCHRONIZE: `${url}/${COMMANDS.TEMPLATE_SYNCHRONIZE}`,

  COMMON_GETSYSTEMBILLINGINFO: `${url}/${COMMANDS.COMMON_GETSYSTEMBILLINGINFO}`,
  COMMON_GETWEBCONSOLEBETASETTINGS: `${url}/${COMMANDS.COMMON_GETWEBCONSOLEBETASETTINGS}`,
  COLLECTION_UPDATEITEM: `${url}/${COMMANDS.COLLECTION_UPDATEITEM}`,
  COLLECTION_DELETEITEMS: `${url}/${COMMANDS.COLLECTION_DELETEITEMS}`,

  COLLECTION_GETCOMPLETE: `${url}/${COMMANDS.COLLECTION_GETCOMPLETE}`,
  COLLECTION_GETFIELDS: `${url}/${COMMANDS.COLLECTION_GETFIELDS}`,
  COLLECTION_GETVIEWS: `${url}/${COMMANDS.COLLECTION_GETVIEWS}`,
  COLLECTION_UPDATESTATEPAGE: `${url}/${COMMANDS.COLLECTION_UPDATESTATEPAGE}`,
  COLLECTION_UPDATESTATEVIEW: `${url}/${COMMANDS.COLLECTION_UPDATESTATEVIEW}`,
  COLLECTION_UPDATESTATESEARCH: `${url}/${COMMANDS.COLLECTION_UPDATESTATESEARCH}`,
  COLLECTION_GET: `${url}/${COMMANDS.COLLECTION_GET}`,
  COLLECTION_UPDATE: `${url}/${COMMANDS.COLLECTION_UPDATE}`,
  COLLECTION_UPDATEFIELDS: `${url}/${COMMANDS.COLLECTION_UPDATEFIELDS}`,
  COLLECTION_SETUNIQUEFIELDS: `${url}/${COMMANDS.COLLECTION_SETUNIQUEFIELDS}`,
  COLLECTION_DEDUPECOUNT: `${url}/${COMMANDS.COLLECTION_DEDUPECOUNT}`,
  COLLECTION_DEDUPE: `${url}/${COMMANDS.COLLECTION_DEDUPE}`,
  COLLECTION_REBUILD: `${url}/${COMMANDS.COLLECTION_REBUILD}`,
  COLLECTION_DELETEFIELDS: `${url}/${COMMANDS.COLLECTION_DELETEFIELDS}`,
  COLLECTION_DELETEPACKAGEFILE: `${url}/${COMMANDS.COLLECTION_DELETEPACKAGEFILE}`,
  COLLECTION_DELETE: `${url}/${COMMANDS.COLLECTION_DELETE}`,
  COLLECTION_DELETE_ITEMS: `${url}/${COMMANDS.COLLECTION_DELETE_ITEMS}`,
  COLLECTION_EMPTY: `${url}/${COMMANDS.COLLECTION_EMPTY}`,
  COLLECTION_GETAGENTID: `${url}/${COMMANDS.COLLECTION_GETAGENTID}`,
  COLLECTION_CREATE_NEW_FROM_SCRATCH: `${url}/${COMMANDS.COLLECTION_CREATE_NEW_FROM_SCRATCH}`,
  COLLECTION_CREATE_NEW_FROM_FILE: `${url}/${COMMANDS.COLLECTION_CREATE_NEW_FROM_FILE}`,
  COLLECTION_GET_COLUMN_HEADINGS_FROM_FILE: `${url}/${COMMANDS.COLLECTION_GET_COLUMN_HEADINGS_FROM_FILE}`,
  COLLECTION_UPDATEITEMSFROMFILE: `${url}/${COMMANDS.COLLECTION_UPDATEITEMSFROMFILE}`,
  COLLECTION_CREATE_NEW_FROM_EXISTING: `${url}/${COMMANDS.COLLECTION_CREATE_NEW_FROM_EXISTING}`,

  FOLDER_ADD: `${url}/${COMMANDS.FOLDER_ADD}`,
  COOKIESTORE_GET: `${url}/${COMMANDS.COOKIESTORE_GET}`,
  COOKIESTORE_UPDATE: `${url}/${COMMANDS.COOKIESTORE_UPDATE}`,
  COOKIESTORE_DELETE: `${url}/${COMMANDS.COOKIESTORE_DELETE}`,
  COOKIESTORE_ADD: `${url}/${COMMANDS.COOKIESTORE_ADD}`,
  COOKIE_STORE_ADD_UPDATE_COOKIE: `${url}/${COMMANDS.COOKIE_STORE_ADD_UPDATE_COOKIE}`,
  TEMPLATE_CREATENEWFROMMULTIPLE: `${url}/${COMMANDS.TEMPLATE_CREATENEWFROMMULTIPLE}`,
  TEMPLATE_CREATENEWFROMSCRATCH: `${url}/${COMMANDS.TEMPLATE_CREATENEWFROMSCRATCH}`,
  TEMPLATE_VALIDATENAME: `${url}/${COMMANDS.TEMPLATE_VALIDATENAME}`,

  FOLDER_GET: `${url}/${COMMANDS.FOLDER_GET}`,
  FOLDER_GETLIST: `${url}/${COMMANDS.FOLDER_GETLIST}`,
  FOLDER_GETITEMS: `${url}/${COMMANDS.FOLDER_GETITEMS}`,
  UPDATE_FOLDERS: `${url}/${COMMANDS.UPDATE_FOLDERS}`,
  UPDATE_FOLDER: `${url}/${COMMANDS.UPDATE_FOLDER}`,
  UPDATE_FOLDER_ITEMS: `${url}/${COMMANDS.UPDATE_FOLDER_ITEMS}`,

  JOB_GETDETAILS: `${url}/${COMMANDS.JOB_GETDETAILS}`,

  PUBLISHER_GETRSSPUBLISHERITEM: `${url}/${COMMANDS.PUBLISHER_GETRSSPUBLISHERITEM}`,
  PREVIEW_RSS: `${process.env.REACT_APP_API_URL}/rss`,

  FOLDER_GETCOMPLETE: `${url}/${COMMANDS.FOLDER_GETCOMPLETE}`,
  FOLDER_DELETE: `${url}/${COMMANDS.FOLDER_DELETE}`,

  SEQUENCE_GET: `${url}/${COMMANDS.SEQUENCE_GET}`,
  SEQUENCE_UPDATE: `${url}/${COMMANDS.SEQUENCE_UPDATE}`,
  SEQUENCE_GETNOTIFICATIONEVENTS: `${url}/${COMMANDS.SEQUENCE_GETNOTIFICATIONEVENTS}`,
  SEQUENCE_UPDATENOTIFICATIONEVENTS: `${url}/${COMMANDS.SEQUENCE_UPDATENOTIFICATIONEVENTS}`,
  STORAGE_GETUSAGE: `${url}/${COMMANDS.STORAGE_GETUSAGE}`,
  STORAGE_DOWNLOADCOLLECTIONSTORAGESUMMARY: `${url}/${COMMANDS.STORAGE_DOWNLOADCOLLECTIONSTORAGESUMMARY}`,
  UTILIZATION_DOWNLOADAGENTPAGES: `${url}/${COMMANDS.UTILIZATION_DOWNLOADAGENTPAGES}`,
  STORAGE_DOWNLOADCOLLECTIONSTORAGE: `${url}/${COMMANDS.STORAGE_DOWNLOADCOLLECTIONSTORAGE}`,
  SEQUENCE_DELETE: `${url}/${COMMANDS.SEQUENCE_DELETE}`,
  SEQUENCE_DELETESTEP: `${url}/${COMMANDS.SEQUENCE_DELETESTEP}`,
  SEQUENCE_RUN: `${url}/${COMMANDS.SEQUENCE_RUN}`,
  SEQUENCE_PAUSE: `${url}/${COMMANDS.SEQUENCE_PAUSE}`,
  SEQUENCE_RESUME: `${url}/${COMMANDS.SEQUENCE_RESUME}`,
  SEQUENCE_CANCEL: `${url}/${COMMANDS.SEQUENCE_CANCEL}`,
  SEQUENCE_GET_AGENT_INFO: `${url}/${COMMANDS.SEQUENCE_GET_AGENT_INFO}`,
  SEQUENCE_GET_ERROR_CODES: `${url}/${COMMANDS.SEQUENCE_GET_ERROR_CODES}`,
  SEQUENCE_UPDATE_ADD_STEP: `${url}/${COMMANDS.SEQUENCE_UPDATE_ADD_STEP}`,
  SEQUENCE_GET_STATUS: `${url}/${COMMANDS.SEQUENCE_GET_STATUS}`,
  SEQUENCE_ADD: `${url}/${COMMANDS.SEQUENCE_ADD}`,
  SEQUENCE_SET_STEPS_TO_RUN_CONCURRENTLY: `${url}/${COMMANDS.SEQUENCE_SET_STEPS_TO_RUN_CONCURRENTLY}`,
  ADD_STEP_TO_EXISTING_GROUP: `${url}/${COMMANDS.ADD_STEP_TO_EXISTING_GROUP}`,
  REMOVE_SELECTED_STEPS_FROM_GROUP: `${url}/${COMMANDS.REMOVE_STEPS_FROM_GROUP}`,
  GET_DELETE_VIEW_ITEMS: `${url}/${COMMANDS.GET_DELETE_VIEW_ITEMS}`,

  COMMON_GETWEBLOCATIONS: `${url}/${COMMANDS.COMMON_GETWEBLOCATIONS}`,

  TEMPLATE_GETLIST: `${url}/${COMMANDS.TEMPLATE_GETLIST}`,
  TEMPLATE_GETSUMMARYSTATS: `${url}/${COMMANDS.TEMPLATE_GETSUMMARYSTATS}`,
  TEMPLATE_GETAGENTCOMBOBOXOPTIONS: `${url}/${COMMANDS.TEMPLATE_GETAGENTCOMBOBOXOPTIONS}`,
  TEMPLATE_GETMERGEDEFAULTS: `${url}/${COMMANDS.TEMPLATE_GETMERGEDEFAULTS}`,

  UPDATE_STATEPAGE: `${url}/${COMMANDS.UPDATE_STATEPAGE}`,
  UPDATE_STATESEARCH: `${url}/${COMMANDS.UPDATE_STATESEARCH}`,
  UPDATE_STATEVIEW: `${url}/${COMMANDS.UPDATE_STATEVIEW}`,
  USER_GETACCOUNTS: `${url}/${COMMANDS.USER_GETACCOUNTS}`,
  USER_VALIDATE: `${url}/${COMMANDS.USER_VALIDATE}`,
  USER_GETEXISTINGACCOUNTS: `${url}/${COMMANDS.USER_GETEXISTINGACCOUNTS}`,
  UTILIZATION_GETUSAGE: `${url}/${COMMANDS.UTILIZATION_GETUSAGE}`,

  VIEW_ADDLAYOUT: `${url}/${COMMANDS.VIEW_ADDLAYOUT}`,
  VIEW_GET: `${url}/${COMMANDS.VIEW_GET}`,
  VIEW_GETFIELDS: `${url}/${COMMANDS.VIEW_GETFIELDS}`,
  VIEW_GETITEMS: `${url}/${COMMANDS.VIEW_GETITEMS}`,
  VIEW_DELETE: `${url}/${COMMANDS.VIEW_DELETE}`,

  PUBLISH: `${url}/${COMMANDS.PUBLISH}`,
  PUBLISHER_ADD: `${url}/${COMMANDS.PUBLISHER_ADD}`,
  PUBLISHER_TEST: `${url}/${COMMANDS.PUBLISHER_TEST}`,
  COMMON_GENERATEGUID: `${url}/${COMMANDS.COMMON_GENERATEGUID}`,
  PUBLISHER_UPDATE: `${url}/${COMMANDS.PUBLISHER_UPDATE}`,
  GET_PUBLISHERLIST: `${url}/${COMMANDS.GET_PUBLISHERLIST}`,
  USER_GET: `${url}/${COMMANDS.USER_GET}`,
  USER_GETPICKERLIST: `${url}/${COMMANDS.USER_GETPICKERLIST}`,
  CLIENT_COMMANDRESULT: `${url}/${COMMANDS.CLIENT_COMMANDRESULT}`,

  COLLECTION_GETPACKAGEFILESLIST: `${url}/${COMMANDS.COLLECTION_GETPACKAGEFILESLIST}`,
  COLLECTION_REPACKAGEFILES: `${url}/${COMMANDS.COLLECTION_REPACKAGEFILES}`,
  COLLECTION_RENAMEPACKAGEFILE: `${url}/${COMMANDS.COLLECTION_RENAMEPACKAGEFILE}`,

  SCHEDULE_GET: `${url}/${COMMANDS.SCHEDULE_GET}`,
  SCHEDULE_ADD: `${url}/${COMMANDS.SCHEDULE_ADD}`,
  SCHEDULE_UPDATE: `${url}/${COMMANDS.SCHEDULE_UPDATE}`,

  USER_GETRECENTITEMS: `${url}/${COMMANDS.USER_GETRECENTITEMS}`,
  AGENT_GET_STATUS_COUNTS: `${url}/${COMMANDS.AGENT_GET_STATUS_COUNTS}`,
  USER_DELETE: `${url}/${COMMANDS.USER_DELETE}`,
  USER_GETLIST: `${url}/${COMMANDS.USER_GETLIST}`,
  ACCOUNT_UPDATE_ANONYMOUS_SETTING: `${url}/${COMMANDS.ACCOUNT_UPDATE_ANONYMOUS_SETTING}`,
  USER_ADD_RECENT_ITEM: `${url}/${COMMANDS.USER_ADD_RECENT_ITEMS}`,
  ACCOUNT_UPDATEINVOICEEMAILS: `${url}/${COMMANDS.ACCOUNT_UPDATEINVOICEEMAILS}`,
  USER_GETADMINEMAILS: `${url}/${COMMANDS.USER_GETADMINEMAILS}`,
  USER_LOGIN: `${url}/${COMMANDS.USER_LOGIN}`,

  ACCOUNT_INVITE_USERS: `${url}/${COMMANDS.ACCOUNT_INVITE_USERS}`,
  USER_ADD: `${url}/${COMMANDS.USER_ADD}`,
  USER_UPDATE: `${url}/${COMMANDS.USER_UPDATE}`,
  USER_CHECK_EMAIL_ADDRESS: `${url}/${COMMANDS.USER_CHECK_EMAIL_ADDRESS}`,
  USER_GENERATE_WEBSERVICE_KEY: `${url}/${COMMANDS.USER_GENERATE_WEBSERVICE_KEY}`,
  EVENT_GETBYACTION: `${url}/${COMMANDS.EVENT_GETBYACTION}`,

  ACCOUNT_CHANGE_INVOICE_DATE: `${url}/${COMMANDS.ACCOUNT_CHANGE_INVOICE_DATE}`,
  BILLING_ADD_TRANSACTION: `${url}/${COMMANDS.BILLING_ADD_TRANSACTION}`,

  USER_ADD_EXISTING_USERS: `${url}/${COMMANDS.USER_ADD_EXISTING_USERS}`,
  SETTING_DELETE: `${url}/${COMMANDS.SETTING_DELETE}`,
  SETTING_COPY: `${url}/${COMMANDS.SETTING_COPY}`,
  SETTING_UPDATESINGLE: `${url}/${COMMANDS.SETTING_UPDATESINGLE}`,
  BILLING_GET_BILL_ELEMENTS_FOR_RATE_PLAN: `${url}/${COMMANDS.BILLING_GET_BILL_ELEMENTS_FOR_RATE_PLAN}`,
  DOMAIN_LOOKUP: `${url}/${COMMANDS.DOMAIN_LOOKUP}`,
  RATE_PLAN_GET: `${url}/${COMMANDS.RATE_PLAN_GET}`,
  CLONE_RATE_PLAN: `${url}/${COMMANDS.CLONE_RATE_PLAN}`,

  BILLING_GET_MOST_RECENT_STORAGE_INVOICE: `${url}/${COMMANDS.BILLING_GET_MOST_RECENT_STORAGE_INVOICE}`,
  ACCOUNT_UPDATE_ADMIN_EDIT: `${url}/${COMMANDS.ACCOUNT_UPDATE_ADMIN_EDIT}`,
  MOVE_STEP_DRAG: `${url}/${COMMANDS.MOVE_STEP_DRAG}`,
  GET_ITEM_HISTORYLIST: `${url}/${COMMANDS.GET_ITEM_HISTORYLIST}`,
  GET_ITEM_HISTORYDIFFHTML: `${url}/${COMMANDS.GET_ITEM_HISTORYDIFFHTML}`,

  VIEW_UPDATE_FIELD_STATE: `${url}/${COMMANDS.VIEW_UPDATE_FIELD_STATE}`,
  SEQUENCE_GET_STEP_INFO: `${url}/${COMMANDS.SEQUENCE_GET_STEP_INFO}`,
  ACCOUNT_UPDATE_CREDIT_CARD: `${url}/${COMMANDS.ACCOUNT_UPDATE_CREDIT_CARD}`,
};
