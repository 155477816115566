import moment from "moment";
// This is a strict date parse for strings with a known length (Usually from Mozenda fields)
export const getDateFromString = (dateValue) => {
  if (typeof dateValue === "string") {
    const matches = dateValue.match(
      /(\d\d\d\d)-(\d\d)-(\d\d) (\d\d):(\d\d):(\d\d)/
    );
    if (matches && matches.length === 7) {
      const year = matches[1];
      const month = matches[2] - 1; // Month variable is 0 based.
      const day = matches[3];
      const hours = matches[4];
      const minutes = matches[5];
      const seconds = matches[6];
      const milliseconds = 0;

      const date = new Date(
        year,
        month,
        day,
        hours,
        minutes,
        seconds,
        milliseconds
      );
      return date;
    }
  }

  return null;
};

export const lastRefreshedFromNow = (date) => {
  return new Date(date).getTime()
    ? moment(moment(date).format("YYYYMMDD"), "YYYYMMDD").fromNow()
    : "";
};

export const getDateInMsFromFormat = (date, format) => {
  const formattedDate = moment(date).format(format);
  return formattedDate.valueOf();
};

export const formatTimeInZeroHours = (date) => {
  return moment(date).format("YYYY-MM-DD") + "T00:00:00.00";
};
