import { GET_AGENT_INFO, SET_AGENT_INFO, AGENT_INFO_LOADED } from "../types";
import { CONSTANTS } from "../../constants/constants";
import { takeEvery, put, cancel } from "redux-saga/effects";
import axios from "axios";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";

function* getAgentInfo({ type: action, formParams = {} }) {
  const params = {
    Command: CONSTANTS.COMMANDS.AGENT_GET,
    ...formParams,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.AGENT_GET,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    yield put({
      type: SET_AGENT_INFO,
      payload: {
        agentInfo: data?.Agent,
        trackHistory: data.CollectionItem?.TrackHistory,
        CollectionItem: data.CollectionItem,
      },
    });

    yield put({
      type: AGENT_INFO_LOADED,
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

export default function* agentDetailsWatcher() {
  yield takeEvery(GET_AGENT_INFO, getAgentInfo);
}
