import { SET_ACCOUNT_BILL_ELEMENTS, SET_SYSTEM_BILLING_INFO } from "../types";
import createReducer from "../createReducer";

const initialState = {
  systemBillingInfo: null,
  mappedGeolocations: null,
  accountBillColumns: [],
};

export const systemBillingInfoReducer = createReducer(initialState, {
  [SET_SYSTEM_BILLING_INFO](state, payload) {
    return {
      ...state,
      mappedGeolocations: payload.mappedGeolocations,
      systemBillingInfoData: payload.systemBillingInfoData,
    };
  },
  [SET_ACCOUNT_BILL_ELEMENTS](state, { columns }) {
    return {
      ...state,
      accountBillColumns: columns,
    };
  },
});
