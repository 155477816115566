export const getStringEllipsis = (name, length = 20) => {
  if (name?.length > length) {
    return ` ${name.substr(0, length)} ...  `;
  }
  return ` ${name} `;
};

export const capitalizeFirstLetter = (name) => {
  return `${name.charAt(0).toUpperCase()}${name.slice(1).toLowerCase()}`;
};

export const getRandomStringByLength = (length = 6) => {
  const charset = "abcdefghjkmnpqrstuvwxyz23456789";
  let text = " ";

  for (let i = 0; i < length; i++)
    text += charset.charAt(Math.floor(Math.random() * charset.length));

  return text.trim();
};
