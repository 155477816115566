import createSagaMiddleware from "redux-saga";
import { applyMiddleware, compose } from "redux";

const middlewares = [];

const createdSagaMiddleware = createSagaMiddleware();

middlewares.push(createdSagaMiddleware);

let storeEnhancer = compose(applyMiddleware(...middlewares));

export const sagaMiddleware = createdSagaMiddleware;

export default storeEnhancer;
