import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  IconButton,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import variables from "../../../assets/sass/_variables.scss";
import Breadcrumbs from "../../../Components/Breadcrumbs";

const HeaderSpace = withStyles({
  root: {
    height: "20px",
    minHeight: "20px",
    background: variables["color-spacecadet"],
  },
})(Box);

const StyledDialogTitle = withStyles({
  root: {
    background: variables["color-lightgray-25"],
    padding: `4px 8px 4px 16px`,
  },
})(DialogTitle);

const StyledDialogContent = withStyles({
  root: {
    padding: 0,
    justifyContent: "flex-start",
    background: variables["color-white"],
  },
  dividers: {
    borderColor: variables["color-midgray-60"],
  },
})(DialogContent);

const StyledDialogActions = withStyles({
  root: {
    justifyContent: "flex-start",
    background: variables["color-lightgray-25"],
    padding: "20px 8px 20px 16px",
  },
})(DialogActions);

export default function Modal({
  open,
  onClose,
  titleItems,
  fullScreen,
  actionsChildren,
  children,
  icon,
}) {
  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullScreen={fullScreen}
      maxWidth={false}
    >
      <HeaderSpace />

      <StyledDialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" justifyContent="left" alignItems="center">
            {icon && icon}
            {icon && <Box pr={1} />}
            <Breadcrumbs items={titleItems} />
          </Box>

          <Box mx={2} />
          <IconButton onClick={onClose}>
            <FontAwesomeIcon
              size="lg"
              icon={["fal", "times"]}
              color={variables["color-spacecadet"]}
            />
          </IconButton>
        </Box>
      </StyledDialogTitle>

      <StyledDialogContent dividers={true}>{children}</StyledDialogContent>

      <StyledDialogActions>{actionsChildren}</StyledDialogActions>
    </Dialog>
  );
}

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  /** event handler on click */
  onClose: PropTypes.func.isRequired,
  /** dialog titles */
  titleItems: PropTypes.array,
  fullScreen: PropTypes.bool,
  actionsChildren: PropTypes.element,
  children: PropTypes.node,
};

Modal.defaultProps = {
  titleItems: [],
  fullScreen: true,
};
