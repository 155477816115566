import {
  GET_STANDARD_COLLECTIONS,
  SET_STANDARD_COLLECTIONS,
  SET_STANDARD_COLLECTIONS_SAVED_VIEWS,
  GET_STANDARD_COLLECTIONS_SAVED_VIEWS,
  GET_STANDARD_COLLECTIONS_STATE,
  SET_STANDARD_COLLECTIONS_STATE,
  GET_ALL_STANDARD_COLLECTIONS,
  SET_ALL_STANDARD_COLLECTIONS,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import { takeLatest, put, call, cancel } from "redux-saga/effects";
import axios from "axios";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";
import { SYSTEM_COLLECTIONS } from "../../constants/collections";

const Ext = window["Ext"];

function prepareModel(data, store) {
  const model = store.config.model;

  model.setFields(data);

  return model;
}

function prepareGridColumns(data) {
  let columnItems = [];
  columnItems.push({
    dataIndex: "x-separator",
    text: "",
    locked: true,
    hidden: true,
    width: 1,
    editable: false,
    menuDisabled: true,
  });

  columnItems = [
    ...columnItems,
    ...data.ViewFields.map((field) => ({
      dataIndex: field.Name,
      text: `<span title='${field.Name}'>${field.Name}</span>`,
      locked: field.IsSticky,
      hidden: field.IsHidden,
      width: field.ColumnWidth,
      editable: !field.IsReadOnly && !field.IsSystem,
      cell: {
        height: 50,
        encodeHtml: true,
      },
    })),
  ];
  return columnItems;
}

function createStore(storeId, data, filteredData = {}) {
  const store = Ext.getStore(storeId);
  if (store) {
    const model = prepareModel(data, store);

    const { SearchText, SearchField, CollectionID } = data.CollectionState;

    store.removeAll(true);
    store.setModel(model);

    store.baseParams = {
      Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
      CollectionID,
      ViewID: data.ViewRow.ViewID,
      AgentID: data.AgentID,
      SearchText,
      SearchField,
      ...filteredData,
    };
    store.getProxy().extraParams = store.baseParams;
    store.loadPage(1, {
      params: {
        Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
        CollectionID,
        ViewID: data.ViewRow.ViewID,
        AgentID: data.AgentID,
        SearchText,
        SearchField,
        ...filteredData,
      },
    });
  }
}

function* fetchStandardCollections({ type: action, storeId, formParams = {} }) {
  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETCOMPLETE,
    ...formParams,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    const columns = prepareGridColumns(data);
    yield put({
      type: SET_STANDARD_COLLECTIONS,
      payload: {
        columns,
        collectionInfo: data.CollectionInfo,
        viewRow: data.ViewRow,
        collectionState: data.CollectionState,
        viewFields: data.ViewFields,
      },
    });

    yield call(createStore, storeId, data);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getAllStandardCollections() {
  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETCOMPLETE,
    CollectionID: SYSTEM_COLLECTIONS.COLLECTIONS,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams(params)
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    handleJsonResultError(
      data.JsonResult.Result,
      data.JsonResult.ErrorDescription,
      data.JsonResult.ErrorList,
      CONSTANTS.NOTIFICATIONS.SUCCESS
    );

    const { SearchText, SearchField, CollectionID } = data.CollectionState;
    const standardCollectionsResult = yield axios.post(
      CONSTANTS.ROUTES.VIEW_GETITEMS,
      new URLSearchParams({
        Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
        CollectionID,
        ViewID: data.ViewRow.ViewID,
        AgentID: data.AgentID,
        SearchText,
        SearchField,
      })
    );

    const allStandardCollections = standardCollectionsResult.data.Rows.filter(
      (el) => el.CollectionType === "Standard"
    );

    yield put({
      type: SET_ALL_STANDARD_COLLECTIONS,
      payload: allStandardCollections,
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* fetchStandardCollectionsState({ type: action, formParams = {} }) {
  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETCOMPLETE,
    ...formParams,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    const columns = prepareGridColumns(data);
    yield put({
      type: SET_STANDARD_COLLECTIONS_STATE,
      payload: {
        columns,
        viewRow: data.ViewRow,
        collectionState: data.CollectionState,
        viewFields: data.ViewFields,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getStandardCollectionsSavedViews({ type: action, collectionId }) {
  const params = {
    CollectionID: collectionId,
    Command: CONSTANTS.COMMANDS.COLLECTION_GETVIEWS,
  };

  try {
    const result = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETVIEWS,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );
    if (result.data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        result.data.JsonResult.Result,
        result.data.JsonResult.ErrorDescription,
        result.data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    const savedViews = [];

    result.data.ViewTable.forEach((view) => {
      let savedView = {};
      for (let key in view) {
        if (CONSTANTS.RESPONSE_CONTEXT.SAVED_VIEWS_CONTEXT.includes(key)) {
          savedView[key] = view[key];
        }
      }
      savedViews.push(savedView);
    });

    yield put({
      type: SET_STANDARD_COLLECTIONS_SAVED_VIEWS,
      payload: {
        savedViews,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

export default function* standardCollectionWatcher() {
  yield takeLatest(GET_STANDARD_COLLECTIONS, fetchStandardCollections);
  yield takeLatest(
    GET_STANDARD_COLLECTIONS_STATE,
    fetchStandardCollectionsState
  );
  yield takeLatest(
    GET_STANDARD_COLLECTIONS_SAVED_VIEWS,
    getStandardCollectionsSavedViews
  );
  yield takeLatest(GET_ALL_STANDARD_COLLECTIONS, getAllStandardCollections);
}
