import {
  GET_DOMAIN_LOOKUP_TOOL,
  GET_SYSYTEM_RATE_PLANS,
  SET_SYSYTEM_RATE_PLANS,
  GET_DOMAIN_LOOKUP_COLUMNS,
  GET_RATE_PLAN,
  CLONE_RATE_PLAN,
  GET_MOST_RECENT_STORAGE_INVOCE,
  SAVE_SUPER_ADMIN_UPDATE,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import { call, put, select, takeLatest, cancel } from "redux-saga/effects";
import axios from "axios";
import {
  renderSystemRatePlanNameCell,
  renderDomainLookupNameCell,
} from "../../Components/Grid/SenchaLockedGrid/renderers";
import {
  GROUP_COLLECTIONS,
  MASTER_COLLECTIONS,
} from "../../constants/collections";
import { COMMANDS } from "../../constants/commands";
import {
  GET_SYSTEM_RATE_PLANS_SAVED_VIEWS,
  SET_SYSTEM_RATE_PLANS_SAVED_VIEWS,
  DELETE_SYSTEM_RATE_PLANS_VIEW,
  SET_DOMAIN_LOOKUP_COLUMNS,
  SET_RATE_PLAN,
} from "../types";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";

const Ext = window["Ext"];

function prepareGridColumns(data, nameCellCallback) {
  const renderers = {
    Name: {
      renderer: renderSystemRatePlanNameCell,
      callback: nameCellCallback,
    },
  };

  return data.ViewFields.map((field) => {
    if (renderers[field.Name]) {
      return {
        dataIndex: field.Name,
        text: `<span title='${field.Name}'>${field.Name}</span>`,
        locked: field.IsSticky,
        hidden: field.IsHidden,
        width: field.ColumnWidth,
        cell: {
          height: 50,
          encodeHtml: true,
          xtype: "reactcell",
        },
        renderer: (value, record) =>
          renderers[field.Name].renderer(
            renderers[field.Name].mapper,
            value,
            record,
            nameCellCallback
          ),
      };
    }

    return {
      dataIndex: field.Name,
      text: `<span title='${field.Name}'>${field.Name}</span>`,
      locked: field.IsSticky,
      hidden: field.IsHidden,
      width: field.ColumnWidth,
      cell: {
        height: 50,
      },
    };
  });
}

function* getRatePlans({ storeId, editField, viewId }) {
  const reduxState = yield select();
  const params = {
    CollectionID: GROUP_COLLECTIONS.SYSTEM_RATE_PLANS,
    FolderID: "",
    AccountKey: reduxState.account.accountInfo.MasterAccountKey,
    ViewID: viewId,
    OverrideViewName: "",
    HideSystemViewNames: "",
    Command: COMMANDS.COLLECTION_GETCOMPLETE,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams(params)
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    const columns = prepareGridColumns(data, editField);

    yield put({
      type: SET_SYSYTEM_RATE_PLANS,
      payload: {
        columns,
        sortedBy: data.ViewSorting,
        viewRow: data.ViewRow,
        collectionState: data.CollectionState,
        viewFields: data.ViewFields,
        refreshed: data.Collection.Refreshed,
      },
    });

    const store = Ext.getStore(storeId);
    if (store) {
      const params = {
        AddSummaryRow: "",
        Command: COMMANDS.VIEW_GETITEMS,
        CollectionID: MASTER_COLLECTIONS.RATE_PLANS,
        ViewID: data.ViewRow.ViewID,
        OverrideViewName: "",
        FolderID: "",
        AccountKey: reduxState.account.accountInfo.MasterAccountKey,
        AgentID: 0,
        BookmarkID: 0,
        JobID: -1,
        ItemHistoryRangeStart: "",
        ItemHistoryRangeEnd: "",
        ItemStatusesToInclude: "",
        ColumnsToAlwaysInclude: "Name",
        ForceViewSortable: "",
        AlwaysIncludeCustomFields: false,
        IncludeAdditionalCustomInterfaceColumns: "",
        SelectedIDs: "",
        ShowHistoryColumn: false,
        ShowSelected: false,
        TemplateCollectionID: 0,
        ShowMarkup: false,
      };
      store.load({ params });
    }
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getRatePlansSavedViews() {
  try {
    const reduxState = yield select();
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETVIEWS,
      new URLSearchParams({
        AccountKey: reduxState.account.accountInfo.MasterAccountKey,
        CollectionID: MASTER_COLLECTIONS.RATE_PLANS,
        Command: COMMANDS.COLLECTION_GETVIEWS,
      })
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    let systemRatePlansSavedViews = [];

    data.ViewTable.forEach((view) => {
      let savedView = {};
      for (let key in view) {
        if (CONSTANTS.RESPONSE_CONTEXT.SAVED_VIEWS_CONTEXT.includes(key)) {
          savedView[key] = view[key];
        }
      }
      systemRatePlansSavedViews.push(savedView);
    });

    yield put({
      type: SET_SYSTEM_RATE_PLANS_SAVED_VIEWS,
      systemRatePlansSavedViews,
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* deleteSystemRatePlanView({ params, viewId }) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.VIEW_DELETE,
      new URLSearchParams(params)
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function prepareDomainLookupColumns() {
  const renderers = {
    DomainName: {
      renderer: renderDomainLookupNameCell,
    },
  };
  return [
    {
      dataIndex: "DomainName",
      text: "<span title='DomainName'>DomainName</span>",
      locked: true,
      menuDisabled: true,
      resizable: true,
      searchable: true,
      width: 220,
      cell: {
        height: 50,
        encodeHtml: true,
        xtype: "reactcell",
      },
      renderer: (value) => renderers["DomainName"].renderer(value),
    },
    {
      dataIndex: "Harvesting",
      text: "<span title='Harvesting'>Harvesting</span>",
      locked: false,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 125,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "Geolocation",
      text: "<span title='Geolocation'>Geolocation</span>",
      locked: false,
      menuDisabled: true,
      resizable: true,
      searchable: true,
      width: 116,
      cell: {
        height: 50,
        encodeHtml: true,
        xtype: "reactcell",
      },
    },
    {
      dataIndex: "PremiumHarvestingCapacity",
      text: "<span title='Capacity'>Capacity</span>",
      locked: false,
      menuDisabled: true,
      resizable: true,
      searchable: true,
      width: 387,
      cell: {
        height: 50,
        encodeHtml: true,
        xtype: "reactcell",
      },
    },
    {
      dataIndex: "JobRestrictionMax",
      text: "<span title='JobRestrictionMax'>JobRestrictionMax</span>",
      locked: true,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 75,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "ListEmail",
      text: "<span title='ListEmail'>ListEmail</span>",
      locked: true,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 75,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "RankUs",
      text: "<span title='RankUs'>RankUs</span>",
      locked: true,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 75,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "RankWorld",
      text: "<span title='RankWorld'>RankWorld</span>",
      locked: true,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 75,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "DailyPageViewsThreeMonthAverage",
      text: "<span title='Page Views'>Page Views</span>",
      locked: true,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 75,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "DailyPageViewsOneMonthAverage",
      text: "<span title='Page Views (1 mo)'>Page Views (1 mo)</span>",
      locked: true,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 75,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "DailyPageViewsOneWeekAverage",
      text: "<span title='Page Views (1 wk)'>Page Views (1 wk)</span>",
      locked: true,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 75,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "DailyPageViewsOneDayAverage",
      text: "<span title='Page Views (1 day)'>Page Views (1 day)</span>",
      locked: true,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 75,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "Title",
      text: "<span title='Title'>Title</span>",
      locked: true,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 75,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "AdultContent",
      text: "<span title='Adult'>Adult</span>",
      locked: true,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 75,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "OnlineStart",
      text: "<span title='OnlineStart'>OnlineStart</span>",
      locked: true,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 75,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "AlexaUpdateFirst",
      text: "<span title='AlexaUpdateFirst'>AlexaUpdateFirst</span>",
      locked: true,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 75,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "AlexaUpdateLast",
      text: "<span title='AlexaUpdateLast'>AlexaUpdateLast</span>",
      locked: true,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 75,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "PhoneNumber",
      text: "<span title='PhoneNumber'>PhoneNumber</span>",
      locked: true,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 75,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "OwnerName",
      text: "<span title='OwnerName'>OwnerName</span>",
      locked: true,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 75,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "Email",
      text: "<span title='Email'>Email</span>",
      locked: true,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 75,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "State",
      text: "<span title='State'>State</span>",
      locked: true,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 75,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "Country",
      text: "<span title='Country'>Country</span>",
      locked: true,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 75,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "StockTickers",
      text: "<span title='StockTickers'>StockTickers</span>",
      locked: true,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 75,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "Locale",
      text: "<span title='Locale'>Locale</span>",
      locked: true,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 75,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "LoadTimeMedian",
      text: "<span title='LoadTimeMedian'>LoadTimeMedian</span>",
      locked: true,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 75,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "LoadTimePercentile",
      text: "<span title='LoadTimePercentile'>LoadTimePercentile</span>",
      locked: true,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 75,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "LinksInCount",
      text: "<span title='LinksInCount'>LinksInCount</span>",
      locked: true,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 75,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "Description",
      text: "<span title='Description'>Description</span>",
      locked: true,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 75,
      cell: {
        height: 50,
      },
    },
  ];
}

function* getDomainLookupTool({ domain, storeId }) {
  const params = {
    Command: COMMANDS.DOMAIN_LOOKUP,
    Domain: domain,
  };
  const { data } = yield axios.post(
    CONSTANTS.ROUTES.DOMAIN_LOOKUP,
    new URLSearchParams(params)
  );
  if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
    yield cancel();
  }
  const columns = prepareDomainLookupColumns();

  yield put({
    type: SET_DOMAIN_LOOKUP_COLUMNS,
    columns,
  });
  const store = window["Ext"].getStore(storeId);
  store.loadData(data.Rows);
}

function* getDomainLookupColumns() {
  const columns = prepareDomainLookupColumns();
  yield put({
    type: SET_DOMAIN_LOOKUP_COLUMNS,
    columns,
  });
}

function* getRatePlan({ itemId }) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.RATE_PLAN_GET,
      new URLSearchParams({
        itemID: itemId,
        Command: COMMANDS.RATE_PLAN_GET,
      })
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    yield put({
      type: SET_RATE_PLAN,
      payload: data,
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* cloneRate({ params, closeModal, viewId, storeId, editField }) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.CLONE_RATE_PLAN,
      new URLSearchParams(params)
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    yield call(getRatePlans, { storeId, editField, viewId });
    closeModal();
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getMostRecentStorageInvoice() {
  const reduxState = yield select();
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.BILLING_GET_MOST_RECENT_STORAGE_INVOICE,
      new URLSearchParams({
        AccountID: reduxState.account.accountInfo.Account.BillParentAccountID,
        Command: COMMANDS.BILLING_GET_MOST_RECENT_STORAGE_INVOICE,
      })
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* saveSuperAdminUpdate({ params, toggleModal, getAccount }) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.ACCOUNT_UPDATE_ADMIN_EDIT,
      new URLSearchParams(params)
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    toggleModal();
    getAccount();
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

export default function* SystemWatcher() {
  yield takeLatest(GET_SYSYTEM_RATE_PLANS, getRatePlans);
  yield takeLatest(GET_SYSTEM_RATE_PLANS_SAVED_VIEWS, getRatePlansSavedViews);
  yield takeLatest(DELETE_SYSTEM_RATE_PLANS_VIEW, deleteSystemRatePlanView);
  yield takeLatest(GET_DOMAIN_LOOKUP_TOOL, getDomainLookupTool);
  yield takeLatest(GET_DOMAIN_LOOKUP_COLUMNS, getDomainLookupColumns);
  yield takeLatest(GET_RATE_PLAN, getRatePlan);
  yield takeLatest(CLONE_RATE_PLAN, cloneRate);
  yield takeLatest(GET_MOST_RECENT_STORAGE_INVOCE, getMostRecentStorageInvoice);
  yield takeLatest(SAVE_SUPER_ADMIN_UPDATE, saveSuperAdminUpdate);
}
