const Ext = window["Ext"];

Ext.require(["Ext.grid.plugin.RowDragDrop"]);

Ext.define("Override.grid.GridDragZone", {
  override: "Ext.grid.GridDragZone",

  onDragStart: function (info) {
    var me = this,
      scroller = me.getViewScrollable(),
      data = info.data.dragData;

    if (scroller && scroller.isVirtualScroller) {
      scroller.setDisabled(true);
    }

    if (me.containerScroll) {
      Ext.dd.ScrollManager.scrollTowardsPointer.apply(me, [me.view]);
    }

    let targetNode = Ext.dd.Manager.getTargetComp(info);

    if (!targetNode) return;

    data.records = [targetNode.getRecord()];
    targetNode.isDragging = true;
    targetNode.draggingRecordId = targetNode.id;
    targetNode.dragMarkerCls = this.dragMarkerCls;

    me.rows = [targetNode];
    me.toggleDragMarker(me.rows, true);
  },
});

Ext.define("Override.grid.GridDropZone", {
  override: "Ext.grid.GridDropZone",

  onDragMove: function (info) {
    var me = this,
      ddManager = Ext.dd.Manager,
      targetCmp = ddManager.getTargetComp(info),
      ddRecords,
      isSameGroup,
      position,
      store,
      targetRecord,
      isValidTarget,
      positionCls;

    position = ddManager.getPosition(info, targetCmp);

    positionCls = me.dropMarkerCls + "-" + position;

    if (!targetCmp || targetCmp.hasCls(positionCls)) return;

    if (targetCmp.getRecord) {
      targetRecord = targetCmp.getRecord();
    }

    isSameGroup = Ext.Array.intersect(me.getGroups(), info.source.getGroups())
      .length;

    if (!targetRecord || !isSameGroup) {
      if (targetCmp.getStore) {
        store = targetCmp.getStore();
      }

      if (!store || (store && store.getCount() > 0)) return;
    }

    ddRecords = info.data.dragData.records;
    isValidTarget = ddRecords.indexOf(targetRecord) === -1;

    if (me.ddEl) {
      me.removeDropMarker();
    }

    var validDropLocation = me.fireEvent(
      "onnodeover",
      info,
      targetRecord,
      position
    );
    if (validDropLocation === false) return me.removeDropMarker();

    if (isValidTarget) {
      me.addDropMarker(targetCmp, [me.dropIndicator, positionCls]);
      me.ddEl = targetCmp;
    }
  },
});

Ext.define("Extend.grid.plugin.RowDragDrop", {
  extend: "Ext.grid.plugin.RowDragDrop",
  alias: "plugin.customgridrowdragdrop",

  init: function (view) {
    var me = this;

    if (view.isLockedGrid) {
      me.addDragIndicator(view);
    }

    view.on("initialize", me.onViewInitialize, me);
  },

  destroy: function () {
    var me = this;

    Ext.destroy(me.dragZone, me.dropZone);
  },

  onViewInitialize: function (view) {
    let me = this,
      dragZone = {};

    if (me.enableDrag) {
      if (me.proxy) {
        dragZone.proxy = me.proxy;
      }

      if (me.activateOnLongPress) {
        dragZone.activateOnLongPress = me.activateOnLongPress;
      }

      me.dragZone = new Ext.grid.GridDragZone(
        Ext.apply(
          {
            element: view.bodyElement,
            view: view,
            dragText: me.dragText,
            handle: me.handle,
            groups: me.groups,
            scrollAmount: me.scrollAmount,
            containerScroll: me.containerScroll,
            constrain: Ext.getBody(),
          },
          dragZone
        )
      );
    }

    if (me.enableDrop) {
      me.dropZone = new Ext.grid.GridDropZone({
        view: view,
        element: view.bodyElement,
        groups: me.groups,
        dropIndicator: me.dropIndicator,
        overCls: me.overCls,
        copy: me.copy,
        listeners: {
          onnodeover: {
            fn: me.onNodeOver,
          },
        },
      });
    }

    if (!view.isLockedGrid) {
      me.addDragIndicator(view);
    }
  },
});

Ext.define("Override.grid.header.DropZone", {
  override: "Ext.grid.HeaderDropZone",

  isValidDrag: function (targetCmp, sourceCmp) {
    const fromName = sourceCmp.Name;

    if (
      targetCmp.getConfig("grid")?.regionKey !==
      sourceCmp.getConfig("grid")?.regionKey
    )
      return false;
    if (fromName === "Name") return false;

    var info = this.info,
      cursor,
      prevSibling,
      nextSibling,
      box,
      diff;

    cursor = info.cursor.current;
    prevSibling = sourceCmp.previousSibling();
    nextSibling = sourceCmp.nextSibling();

    if (targetCmp === prevSibling) {
      box = prevSibling.element.getBox();
      diff = (cursor.x - box.left) / box.width;

      if (diff > 0.5) {
        return false;
      }
    } else if (targetCmp === nextSibling) {
      box = nextSibling.element.getBox();
      diff = (cursor.x - box.left) / box.width;

      if (diff <= 0.5) {
        return false;
      }
    }

    return true;
  },
});
