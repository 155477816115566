import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Breadcrumbs, Typography, Box } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import variables from "../../assets/sass/_variables.scss";

const BadgeItem = withStyles(({ spacing }) => ({
  item: {
    fontSize: "1.125rem",
    color: variables["color-darkgray-80"],
  },
  badge: {
    marginLeft: spacing(0.5),
    fontSize: "0.65rem",
    borderRadius: "10px",
    backgroundColor: variables["color-mozorange"],
    color: variables["color-white"],
    padding: `2px ${spacing(1)}px`,
    transform: "translateY(-5px)",
  },
}))(({ classes, badgeCount, label, labelClass }) => {
  return (
    <Box display="flex" alignItems="center">
      <Typography className={labelClass}>{label}</Typography>
      <div className={classes.badge}>{badgeCount}</div>
    </Box>
  );
});

const useStyles = makeStyles({
  item: {
    fontSize: "1.125rem",
    color: variables["color-darkgray-80"],
  },
  lastItem: {
    fontSize: "1.125rem",
    color: variables["color-spacecadet"],
    fontWeight: 500,
  },
});

const StyledBreadcrumbs = ({ items, maxItems }) => {
  const classes = useStyles();
  return (
    <Breadcrumbs
      separator={
        <FontAwesomeIcon
          icon={["far", "chevron-right"]}
          color={variables["color-darkgray-80"]}
        />
      }
      maxItems={maxItems}
    >
      {items.map((item, index) => {
        const itemClass =
          index === items.length - 1 ? classes.lastItem : classes.item;

        if (item.badgeCount) {
          return (
            <BadgeItem
              key={`breadcrumb-list-item-${index}`}
              badgeCount={item.badgeCount}
              label={item.label}
              labelClass={itemClass}
            />
          );
        }

        return (
          <Typography
            key={`breadcrumb-list-item-${index}`}
            className={itemClass}
          >
            {item.label}
          </Typography>
        );
      })}
    </Breadcrumbs>
  );
};

StyledBreadcrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      badgeCount: PropTypes.number,
    })
  ),
  maxItems: PropTypes.number,
};

StyledBreadcrumbs.defaultProps = {
  items: [],
  maxItems: 8,
};

export default StyledBreadcrumbs;
