import {
  SET_ROUTER_LOCATION,
  AGENT_GET_JOB_STATUS_POLL,
  AGENT_SET_JOB_STATUS,
  AGENT_SET_JOB_STATUS_SESSION_EXPIRED,
} from "../types";
import createReducer from "../createReducer";

const initialState = {
  routerLocation: {},
  agentConfig: {},
  agentGetJobStatusPoll: "",
  agentJobStatus: null,
  agentJobStatusSessionExpired: false,
};

export const agentRun = createReducer(initialState, {
  [SET_ROUTER_LOCATION](state, { payload }) {
    return {
      ...state,
      routerLocation: payload.location,
    };
  },
  [AGENT_SET_JOB_STATUS_SESSION_EXPIRED](state, { payload }) {
    return {
      ...state,
      agentJobStatusSessionExpired: payload.agentJobStatusSessionExpired,
    };
  },
  [AGENT_GET_JOB_STATUS_POLL](state, { payload }) {
    return {
      ...state,
      agentGetJobStatusPoll: payload?.agentGetJobStatusPoll,
    };
  },
  [AGENT_SET_JOB_STATUS](state, { payload }) {
    return {
      ...state,
      agentJobStatus: payload.agentJobStatus,
    };
  },
});
