import { CONSTANTS } from "../constants/constants";

export const required = (message = "Required") => (value) =>
  value ? undefined : message;

export const customFieldNameValidator = (value) => {
  return value.match(CONSTANTS.REGEX.REGEX_FIELD_NAME)
    ? undefined
    : "Field names may contain up to 50 alphanumeric, space, and hyphen (-) characters";
};

export const customDescriptionValidator = (value) => {
  return value.match(CONSTANTS.REGEX.REGEX_DESCRIPTION)
    ? undefined
    : "Descriptions may contain up to 100 characters";
};

export const customDefaultValueTextValidator = (value) => {
  const regex = /^.{0,50}$/;
  return value === undefined || value.match(regex)
    ? undefined
    : "Default value may contain up to 50 characters";
};
export const composeValidators = (...validators) => (value) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export const validateWithRegex = (value, regex, errorText) => {
  return value.match(regex) ? undefined : errorText;
};

export const validateEmailList = (providedEmail) => {
  let emailAddress = "";
  let badEmailAddresses = "";
  let rawEmailAddress = providedEmail;
  let errorMessage = "";
  let formattedEmailAddress = providedEmail;

  if (rawEmailAddress.length !== 0) {
    rawEmailAddress.replace(/,/g, " ");
    const emailAddressList = rawEmailAddress.split(/\s+/);
    const basicEmailRegex = new RegExp("^[^@]+@[^@]+[.][^@]+$");

    for (let index = 0; index < emailAddressList.length; index++) {
      const email = emailAddressList[index];
      if (basicEmailRegex.test(email)) {
        emailAddress = `${emailAddress} ${email}`;
      } else if (email.length !== 0) {
        badEmailAddresses = `${badEmailAddresses}${email}, `;
      }
    }
    emailAddress = emailAddress.replace(/^\s+|\s+$/gm, "");
    formattedEmailAddress = emailAddress;
  }

  if (emailAddress.length === 0 && rawEmailAddress.length !== 0)
    errorMessage = "Please provide at least one valid email address.";

  if (emailAddress.length === 0)
    errorMessage = "Please provide a valid email address.";

  if (badEmailAddresses.length !== 0 && emailAddress.length !== 0)
    errorMessage =
      "Please correct the following invalid email addresses:<br>" +
      badEmailAddresses.slice(0, -2);

  return {
    email: formattedEmailAddress,
    error: errorMessage !== "",
    errorMessage,
  };
};

export const fieldFlagToArray = (flagString, splitter) => {
  if (!flagString || flagString === null || flagString === "") return [];

  if (!splitter) splitter = /,[ ]*/;

  let flagArray = flagString.split(splitter);

  return flagArray.map((item) => item.trim());
};

export const fieldFlag = (flagString, flagName, splitter) => {
  if (typeof flagString !== "string" || typeof flagName !== "string")
    return false;

  const trimmedFlagName = flagName.trim();
  const flagArray = fieldFlagToArray(flagString, splitter);

  for (let index = 0; index < flagArray.length; index++) {
    if (flagArray[index] === trimmedFlagName) return true;
  }
  return false;
};

export const userHasSetting = (settingName, userItem) => {
  if (!userItem) return false;

  return fieldFlag(userItem.Settings, settingName);
};

export const userGetUserState = (stateName, defaultValue, userInfo) => {
  if (userInfo.UserState === null) return defaultValue;

  let stateValue = null;

  for (var index = 0; index < userInfo.UserState.length; index++) {
    var state = userInfo.UserState[index];
    if (state.Name === stateName) {
      if (state.Value.toLowerCase() === "true") stateValue = true;
      else if (state.Value.toLowerCase() === "false") stateValue = false;
      else if (isNaN(state.Value) || isNaN(parseInt(state.Value)))
        stateValue = state.Value;
      else stateValue = parseInt(state.Value, 10);
    }
  }

  if (stateValue === null && defaultValue) stateValue = defaultValue;
};

export const accountHasFeature = (featureName, account, providedSplitter) => {
  if (account && "Features" in account) {
    const hasFeature = fieldFlag(
      account.Features,
      featureName,
      providedSplitter
    );

    return hasFeature;
  } else return false;
};

export const accountHasSetting = (settingName, accountItem) => {
  if (!accountItem) return false;

  return fieldFlag(accountItem.Settings, settingName);
};

export const userHasAccountPermission = (requiredPermission, userItem) => {
  const isAccountAdministrator = userHasSetting(
    "AccountAdministrator",
    userItem
  );
  if (isAccountAdministrator) return true;

  var isSystemAdministrator = userHasSetting("SystemAdministrator", userItem);
  if (isSystemAdministrator) return true;

  if (!userItem) return false;

  const hasPermissions = fieldFlag(
    userItem.AccountPermissions,
    requiredPermission
  );

  return hasPermissions;
};

const JobTypes = [
  {
    JobType: "AccountDelete",
    CancelAllow: true,
    ResumeAllow: true,
    StopAllow: true,
    ResumeDelay: 0,
  },
  {
    JobType: "AccountMaintain",
    CancelAllow: true,
    ResumeAllow: true,
    StopAllow: true,
    ResumeDelay: 0,
  },
  {
    JobType: "AgentCopy",
    CancelAllow: true,
    ResumeAllow: true,
    StopAllow: true,
    ResumeDelay: 0,
  },
  {
    JobType: "AgentCopyFinalize",
    CancelAllow: true,
    ResumeAllow: true,
    StopAllow: true,
    ResumeDelay: 0,
  },
  {
    JobType: "AgentCopyJobs",
    CancelAllow: true,
    ResumeAllow: true,
    StopAllow: true,
    ResumeDelay: 0,
  },
  {
    CancelAllow: true,
    JobType: "AgentRebuildItemStatistics",
    ResumeAllow: true,
    ResumeDelay: 0,
    StopAllow: true,
  },
  {
    JobType: "AgentRestart",
    CancelAllow: true,
    ResumeAllow: true,
    StopAllow: true,
    ResumeDelay: 0,
  },
  {
    JobType: "AmazonS3Publish",
    CancelAllow: true,
    ResumeAllow: true,
    StopAllow: true,
    ResumeDelay: 0,
  },
  {
    JobType: "AmazonS3PublishFolder",
    CancelAllow: true,
    ResumeAllow: true,
    ResumeDelay: 0,
    StopAllow: true,
  },
  {
    JobType: "AzureStoragePublish",
    CancelAllow: true,
    ResumeAllow: true,
    StopAllow: true,
    ResumeDelay: 0,
  },
  {
    JobType: "AzureStoragePublishFolder",
    CancelAllow: true,
    ResumeAllow: true,
    StopAllow: true,
    ResumeDelay: 0,
  },
  {
    JobType: "CollectionClear",
    CancelAllow: true,
    ResumeAllow: true,
    StopAllow: true,
    ResumeDelay: 0,
  },
  {
    JobType: "CollectionCombinedRebuild",
    CancelAllow: true,
    ResumeAllow: true,
    StopAllow: true,
    ResumeDelay: 0,
  },
  {
    JobType: "CollectionCopy",
    CancelAllow: true,
    ResumeAllow: true,
    StopAllow: true,
    ResumeDelay: 0,
  },
  {
    JobType: "CollectionUniquesRebuild",
    CancelAllow: true,
    ResumeAllow: true,
    StopAllow: true,
    ResumeDelay: 0,
  },
  {
    JobType: "CollectionUpdateFieldValue",
    CancelAllow: true,
    ResumeAllow: true,
    StopAllow: true,
    ResumeDelay: 0,
  },
  {
    JobType: "DatabaseRestore",
    CancelAllow: true,
    ResumeAllow: true,
    StopAllow: true,
    ResumeDelay: 0,
  },
  {
    JobType: "Default",
    CancelAllow: true,
    ResumeAllow: true,
    StopAllow: true,
    ResumeDelay: 0,
  },
  {
    JobType: "DifferentialDatabaseBackup",
    CancelAllow: true,
    ResumeAllow: true,
    StopAllow: true,
    ResumeDelay: 0,
  },
  {
    JobType: "DropboxPublish",
    CancelAllow: true,
    ResumeAllow: true,
    StopAllow: true,
    ResumeDelay: 0,
  },
  {
    JobType: "DropboxPublishFolder",
    CancelAllow: true,
    ResumeAllow: true,
    StopAllow: true,
    ResumeDelay: 0,
  },
  {
    JobType: "EmailPublish",
    CancelAllow: true,
    ResumeAllow: true,
    StopAllow: true,
    ResumeDelay: 0,
  },
  {
    JobType: "FilePackage",
    CancelAllow: true,
    ResumeAllow: true,
    StopAllow: true,
    ResumeDelay: 0,
  },
  {
    JobType: "FilePackageForPublish",
    CancelAllow: true,
    ResumeAllow: true,
    StopAllow: true,
    ResumeDelay: 0,
  },
  {
    JobType: "FilePackageRePackage",
    CancelAllow: true,
    ResumeAllow: true,
    StopAllow: true,
    ResumeDelay: 0,
  },
  {
    JobType: "FilePublish",
    CancelAllow: true,
    ResumeAllow: true,
    StopAllow: true,
    ResumeDelay: 0,
  },
  {
    JobType: "FolderBackup",
    CancelAllow: true,
    ResumeAllow: true,
    StopAllow: true,
    ResumeDelay: 0,
  },
  {
    JobType: "FolderRestore",
    CancelAllow: true,
    ResumeAllow: true,
    StopAllow: true,
    ResumeDelay: 0,
  },
  {
    JobType: "FtpPublish",
    CancelAllow: true,
    ResumeAllow: true,
    StopAllow: true,
    ResumeDelay: 0,
  },
  {
    JobType: "FtpPublishFolder",
    CancelAllow: true,
    ResumeAllow: true,
    StopAllow: true,
    ResumeDelay: 0,
  },
  {
    JobType: "FullDatabaseBackup",
    CancelAllow: true,
    ResumeAllow: true,
    StopAllow: true,
    ResumeDelay: 0,
  },
  {
    JobType: "GooglePublish",
    CancelAllow: true,
    ResumeAllow: true,
    StopAllow: true,
    ResumeDelay: 0,
  },
  {
    JobType: "GooglePublishFolder",
    CancelAllow: true,
    ResumeAllow: true,
    StopAllow: true,
    ResumeDelay: 0,
  },
  {
    JobType: "ReportRefreshData",
    CancelAllow: true,
    ResumeAllow: true,
    StopAllow: true,
    ResumeDelay: 0,
  },
  {
    JobType: "SequenceAdvance",
    CancelAllow: true,
    ResumeAllow: true,
    StopAllow: true,
    ResumeDelay: 0,
  },
  {
    JobType: "SequenceRun",
    CancelAllow: true,
    ResumeAllow: true,
    StopAllow: true,
    ResumeDelay: 0,
  },
  {
    JobType: "SequenceStart",
    CancelAllow: true,
    ResumeAllow: true,
    StopAllow: true,
    ResumeDelay: 0,
  },
  {
    JobType: "TransactionLogBackup",
    CancelAllow: true,
    ResumeAllow: true,
    StopAllow: true,
    ResumeDelay: 0,
  },
  {
    JobType: "ViewDeleteItems",
    CancelAllow: true,
    ResumeAllow: true,
    StopAllow: true,
    ResumeDelay: 0,
  },
  {
    JobType: "WebFileHarvest",
    CancelAllow: true,
    ResumeAllow: true,
    StopAllow: true,
    ResumeDelay: 0,
  },
  {
    JobType: "WebPageHarvest",
    CancelAllow: false,
    ResumeAllow: false,
    StopAllow: false,
    ResumeDelay: 0,
  },
  {
    JobType: "WranglePublish",
    CancelAllow: true,
    ResumeAllow: true,
    StopAllow: true,
    ResumeDelay: 0,
  },
];

export const jobCanResume = (jobType, jobTypes = JobTypes) => {
  if (!jobTypes) return false;

  const foundJobType = jobTypes.find((job) => job.JobType === jobType);

  if (foundJobType) return foundJobType.ResumeAllow;

  return false;
};

export const jobCanPause = (jobType, jobTypes = JobTypes) => {
  if (!jobTypes) return false;

  const foundJobType = jobTypes.find((job) => job.JobType === jobType);

  if (foundJobType) return foundJobType.StopAllow;

  return false;
};

export const jobCanCancel = (jobType, jobTypes = JobTypes) => {
  if (!jobTypes) return false;

  const foundJobType = jobTypes.find((job) => job.JobType === jobType);

  if (foundJobType) return foundJobType.CancelAllow;

  return false;
};
