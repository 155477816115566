export const USER_SETTINGS = {
  ACCOUNT_ADMINISTRATOR: "AccountAdministrator",
  ANONYMOUS_SILENT: "AnonymousSilent",
  BETA_CLIENT: "BetaClient",
  BETA_WEBCONSOLE_OPTED_IN: "BetaWebConsole-OptedIn",
  BETA_WEBCONSOLE_OPTED_OUT: "BetaWebConsole-OptedOut",
  CONTACT_INFO_ESTABLISHED: "ContactInfoEstablished",
  EMAIL_VERIFIED: "EmailVerified",
  FORUMS: "Forums",
  INVITED: "Invited",
  LISTING: "Listing",
  NEWSLETTER: "Newsletter",
  OPEN: "Open",
  PASSWORD_ESTABLISHED: "PasswordEstablished",
  PROMOTIONS: "Promotions",
  SERVICE_USER: "ServiceUser",
  SETTINGS_ADMINISTRATOR: "SettingsAdministrator",
  SYSTEM_ADMINISTRATOR: "SystemAdministrator",
  SUPER_ADMINISTRATOR: "SuperAdministrator",
  TEST: "Test",
  TRAINING_REQUESTED: "TrainingRequested",
};

export const AGENT_SETTINGS = {
  AUTO_BLOCK: "AutoBlock",
  CLOSE_JOB_ON_ERROR: "CloseJobOnError",
  DISABLE_ACTIVEX: "DisableActiveX",
  DISABLE_JAVASCRIPT: "DisableJavaScript",
  END_LIST_ON_DUPLICATE: "EndListOnDuplicate",
  IGNORE_WEBSITE_ERRORS: "IgnoreWebsiteErrors",
  ITEM_STATISTICS_REBUILD: "ItemStatisticsRebuild",
  NO_IMAGE_PACKAGES: "NoImagePackages",
  SHOW_IMAGES: "ShowImages",
  SINGLE_BROWSER: "SingleBrowser",
  STOP_ON_WEBSITE_ERROR: "StopOnWebsiteError",
  STOP_ON_HARVESTING_ERROR: "StopOnHarvestingError",
  USE_ANONYMIZER: "UseAnonymizer",
  AGENT_GROUP: "AgentGroup",
  MAXIMIZE_BROWSER_WINDOW: "MaximizeBrowserWindow",
  BETA: "Beta",
  NO_VALUE_ROLL_FORWARD: "NoValueRollForward",
};

export const ACCOUNT_SETTINGS = {
  ALLOW_CREATE_ACCOUNT_AT_LOGIN: "AllowCreateAccountAtLogin",
  API_DO_NOT_CONVERT_SYSTEM_DATES: "ApiDoNotConvertSystemDates",
  OPEN: "Open",
  FULL_DATABASE_BACKUP: "FullDatabaseBackup",
  TIMEZONE_ESTABLISHED: "TimeZoneEstablished",
  TUTORIAL_DONE: "TutorialDone",
  ANONYMOUS_NEW: "AnonymousNew",
  ANONYMOUS_ALL: "AnonymousAll",
  BETA_HARVESTING: "BetaHarvesting",
  BETA_CLIENT: "BetaClient",
  BETA_WEBCONSOLE_OPTED_IN: "BetaWebConsole-OptIn",
  CUSTOM_URI_ENABLED: "CustomUriEnabled",
};
export const PUBLISHER_SETTINGS = {
  INCLUDE_IMAGES: "IncludeImages",
};
export const RATE_PLAN_SETTINGS = {
  UPDATE_AMOUNT_AND_PAGES: "UpdateAmountAndPages",
  HIDE_BILLING: "HideBilling",
  NONE: "None",
};
