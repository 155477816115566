import {
  SET_SEQUENCES_LIST,
  SET_SEQUENCE_STEPS,
  FILTER_SEQUENCES,
  FILTER_SEQUENCE_STEPS,
  SET_SEQUENCES_VIEWS,
  CLEAR_SEQUENCE_VIEWID,
  SET_SEQUENCE_INFO,
  UPDATE_SEQUENCES_STATE_SEARCH,
  UPDATE_SEQUENCES_STEPS_STATE_SEARCH,
  DELETE_SEQUENCES_VIEW,
  UPDATE_SEQUENCES_VIEW_ROW,
  SET_SEQUENCE_ERROR_CODES,
  SET_SEQUENCE_AGENT_INFO,
  SET_SEQUENCE_CLEAR_COLLECTION,
  SET_SEQUENCE_COLLECTIONS_SAVED_VIEWS,
  UPDATE_SEQUENCE_COLLECTION_STATE_SEARCH,
  SET_SEQUENCE_COLLECTION_INFO,
  SET_SEQUENCE_STATUS,
  SEQUENCE_RUN_LOADING,
  SEQUENCE_RUN_FINISHED_LOADING,
  SEQUENCE_RUN_ERROR,
  REMOVE_SEQUENCE_ERROR,
  SET_DELETE_VIEW_ITEMS,
  SET_SEQUENCE_STEPS_FIELDS,
  SET_SEQUENCES_TO_SELECT,
  SET_SEQUENCES_TO_SELECT_VIEWS,
  UPDATE_SEQUENCES_TO_SELECT_SEARCH,
  SEQUENCES_UPDATEVIEWTABLE,
  ALL_SEQUENCES,
} from "../types";
import createReducer from "../createReducer";

const initialState = {
  sequencesListColumns: [],
  sortedBy: [],
  fieldsFilterItems: [],
  sequenceListViews: [],
  sequenceInfo: null,
  sequenceStepsColumns: [],
  viewRow: {},
  collectionState: {},
  viewFields: null,
  sequenceStepesCollectionState: {},
  sequenceAgentInfo: null,
  sequenceStatus: null,
  sequenceRunLoading: false,
  sequenceRunError: null,
  deleteViewItems: [],
  sequenceStepsFields: {},
  sequencesToSelectColumns: [],
  sequencesToSelectViews: [],
  allSequences: [],
};

export const sequences = createReducer(initialState, {
  [SET_SEQUENCES_LIST](state, { payload }) {
    const updatedState = {
      ...state,
      sequencesListColumns: payload.columns,
      sortedBy: payload.sortedBy,
      fieldsFilterItems: payload.fieldsFilterItems,
      viewRow: payload.viewRow,
      collectionState: payload.collectionState,
      viewFields: payload.viewFields.map((viewField) => {
        return {
          name: viewField.Name,
          value: viewField.Name,
          fieldId: viewField.FieldID,
        };
      }),
    };
    return updatedState;
  },
  [FILTER_SEQUENCES](state, { filter }) {
    state.sequencesListStore.filter("Name", filter);
    return {
      ...state,
    };
  },
  [ALL_SEQUENCES](state, { payload }) {
    return {
      ...state,
      allSequences: payload,
    };
  },

  [SET_SEQUENCE_AGENT_INFO](state, { payload }) {
    return {
      ...state,
      sequenceAgentInfo: payload.Agent,
    };
  },

  [SET_SEQUENCES_VIEWS](state, { payload }) {
    return {
      ...state,
      sequenceListViews: payload.sequenceListViews,
    };
  },
  [SET_SEQUENCE_INFO](state, { payload }) {
    return {
      ...state,
      sequenceInfo: payload.info,
    };
  },
  [SET_SEQUENCE_STEPS](state, { payload }) {
    return {
      ...state,
      sequenceStepsColumns: payload.columns,
      sequenceStepesCollectionState: payload.collectionState,
    };
  },
  [FILTER_SEQUENCE_STEPS](state, { filter }) {
    state.sequenceStepsStore.filter("Step", filter);
    return {
      ...state,
    };
  },
  [CLEAR_SEQUENCE_VIEWID](state) {
    return {
      ...state,
      viewRow: {},
    };
  },
  [SET_SEQUENCE_ERROR_CODES](state, payload) {
    return {
      ...state,
      sequenceErrorCodesColumns: payload.columns,
    };
  },

  [DELETE_SEQUENCES_VIEW](state, { viewId }) {
    return {
      ...state,
      sequenceListViews: state.sequenceListViews.filter((savedView) => {
        return savedView.ViewID !== viewId;
      }),
    };
  },

  [UPDATE_SEQUENCES_VIEW_ROW](state, { viewId }) {
    return {
      ...state,
      viewRow: {
        ...state.viewRow,
        ViewID: viewId,
      },
    };
  },
  [UPDATE_SEQUENCES_STATE_SEARCH](state, { searchFilterValues }) {
    return {
      ...state,
      collectionState: {
        ...state.collectionState,
        SearchText: searchFilterValues.searchText,
        SearchField:
          searchFilterValues.searchField !== "View Fields"
            ? searchFilterValues.searchField
            : "AllViewFields",
      },
    };
  },
  [UPDATE_SEQUENCES_STEPS_STATE_SEARCH](state, { searchFilterValues }) {
    return {
      ...state,
      sequenceStepesCollectionState: {
        ...state.sequenceStepesCollectionState,
        SearchText: searchFilterValues.searchText,
        SearchField:
          searchFilterValues.searchField !== "View Fields"
            ? searchFilterValues.searchField
            : "AllViewFields",
      },
    };
  },
  [SET_SEQUENCE_CLEAR_COLLECTION](state, { payload }) {
    return {
      ...state,
      sequencesCollectionsListColumns: payload.columns,
      sequenceCollectionsSortedBy: payload.sortedBy,
      sequenceCollectionsViewRow: payload.viewRow,
      sequenceCollectionsCollectionState: payload.collectionState,
      sequenceCollectionsViewFields: payload.viewFields.map((viewField) => {
        return {
          name: viewField.Name,
          value: viewField.Name,
          fieldId: viewField.FieldID,
        };
      }),
      sequenceCollectionsRefreshed: payload.refreshed,
    };
  },
  [SET_SEQUENCE_COLLECTIONS_SAVED_VIEWS](state, { payload }) {
    return {
      ...state,
      sequenceCollectionsSavedViews: payload.savedViews,
    };
  },
  [SET_SEQUENCE_COLLECTION_INFO](state, { payload }) {
    return {
      ...state,
      sequenceCollectionInfo: payload,
    };
  },
  [UPDATE_SEQUENCE_COLLECTION_STATE_SEARCH](state, { searchFilterValues }) {
    return {
      ...state,
      sequenceCollectionsCollectionState: {
        ...state.sequenceCollectionsCollectionState,
        SearchText: searchFilterValues.searchText,
        SearchField:
          searchFilterValues.searchField !== "View Fields"
            ? searchFilterValues.searchField
            : "AllViewFields",
      },
    };
  },
  [SET_SEQUENCE_STATUS](state, { payload }) {
    if (!state.sequenceStatus) {
      return {
        ...state,
        sequenceStatus: payload,
      };
    }
    if (
      state.sequenceStatus &&
      state.sequenceStatus.Status !== payload.Status
    ) {
      return {
        ...state,
        sequenceStatus: payload,
      };
    }
    return state;
  },
  [SEQUENCE_RUN_LOADING](state) {
    return {
      ...state,
      sequenceRunLoading: true,
    };
  },
  [SEQUENCE_RUN_FINISHED_LOADING](state) {
    return {
      ...state,
      sequenceRunLoading: false,
    };
  },

  [SEQUENCE_RUN_ERROR](state, { payload }) {
    return {
      ...state,
      sequenceRunError: payload,
    };
  },
  [REMOVE_SEQUENCE_ERROR](state) {
    return {
      ...state,
      sequenceRunError: null,
    };
  },
  [SET_DELETE_VIEW_ITEMS](state, { payload }) {
    return {
      ...state,
      deleteViewItems: payload,
    };
  },
  [SET_SEQUENCE_STEPS_FIELDS](state, { payload }) {
    return {
      ...state,
      sequenceStepsFields: payload,
    };
  },
  [SET_SEQUENCES_TO_SELECT](state, { payload }) {
    return {
      ...state,
      sequencesToSelectColumns: payload.columns,
      sequencesToSelectSortedBy: payload.sortedBy,
      sequencesToSelectViewRow: payload.viewRow,
      sequencesToSelectCollectionState: payload.collectionState,
      sequencesToSelectViewFields: payload.viewFields.map((viewField) => {
        return {
          name: viewField.Name,
          value: viewField.Name,
          fieldId: viewField.FieldID,
        };
      }),
    };
  },
  [SET_SEQUENCES_TO_SELECT_VIEWS](state, { payload }) {
    return {
      ...state,
      sequencesToSelectViews: payload.savedViews,
    };
  },
  [SEQUENCES_UPDATEVIEWTABLE](state, { newViews }) {
    return {
      ...state,
      sequenceListViews: newViews,
    };
  },

  [UPDATE_SEQUENCES_TO_SELECT_SEARCH](state, { searchFilterValues }) {
    return {
      ...state,
      sequencesToSelectCollectionState: {
        ...state.sequencesToSelectCollectionState,
        SearchText: searchFilterValues.searchText,
        SearchField:
          searchFilterValues.searchField !== "View Fields"
            ? searchFilterValues.searchField
            : "AllViewFields",
      },
    };
  },
});
