export const FEATURES = {
  ACCOUNTS: "Accounts",
  AGENT_GROUPS: "AgentGroups",
  COMBINED_COLLECTIONS: "CombinedCollections",
  DATA_COMPARE: "DataCompare",
  FILES: "Files",
  FOLDERS: "Folders",
  GEOLOCATIONS: "Geolocations",
  ITEM_HISTORY: "ItemHistory",
  MASTER: "Master",
  NOTIFICATIONS: "Notifications",
  NOTIFY_ACTIONS: "NotifyActions",
  PUBLISHING: "Publishing",
  SCHEDULING: "Scheduling",
  SEQUENCES: "Sequences",
  VIEWS: "Views",
  API: "API",
  WRANGLE: "Wrangle",
};
