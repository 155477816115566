import ReactDOM from "react-dom";

const Ext = window["Ext"];

Ext.define("Ext.XReactCell", {
  override: "Ext.ReactCell",
  setValue: function setValue(value) {
    var me = this,
      context = me.refreshContext,
      column = context.column,
      needsSizing = false,
      scope = column.getScope(),
      markup,
      renderer,
      result;

    if (context.summary) {
      renderer = me.getSummaryRenderer() || column.getSummaryRenderer();
    }

    renderer = renderer || me.getRenderer() || column.getRenderer();

    if (renderer && typeof renderer === "function") {
      markup = renderer.call(
        scope,
        value,
        context.record,
        context.dataIndex,
        me,
        column
      );

      if (typeof markup === "object") {
        result = ReactDOM.render(markup, me.bodyElement.dom);

        if (result) {
          if (result.cmp !== undefined) {
            result.cmp.setRenderTo(me.bodyElement.dom);
          }

          if (result.isWidget) {
            needsSizing = result !== me.widget;
            me.widget = result;
          }
        }
      } else {
        if (markup == null) {
          markup = "";
        }

        Ext.dom.Helper.overwrite(
          me.bodyElement,
          Ext.htmlEncode(markup.toString())
        );
        me.widget = null;
      }

      if (needsSizing && me.getForceWidth()) {
        me.setWidgetWidth(me.getWidth());
      }
    }

    return me;
  },
  doDestroy: function doDestroy() {
    ReactDOM.unmountComponentAtNode(this.bodyElement.dom);
    this.setColumn(null);
    this.setRecord(null);
    var el = document.getElementById(this.id);
    if (el) {
      el.parentNode.removeChild(el);
    }
  },
});
