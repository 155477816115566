import {
  GET_AGENTS,
  SET_AGENTS_LIST,
  FILTER_AGENTS,
  UPDATE_COLLECTION_STATE,
  GET_AGENTS_SAVED_VIEWS,
  SET_AGENTS_SAVED_VIEWS,
  GET_AGENTS_FIELDS,
  SET_AGENTS_FIELDS,
  UPDATE_AGENTS_CUSTOM_FIELDS,
  DELETE_AGENTS_VIEW,
  DELETE_AGENTS_SAVED_VIEW,
  GET_AGENTS_LIST,
  GET_AGENTS_STATUS_OVERVIEW,
  SET_STATUS_COUNT,
  GET_ALL_AGENTS,
  SET_ALL_AGENTS,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import {
  mapFieldConfigCell,
  mapFieldDescriptionCell,
} from "../../Components/Grid/SenchaLockedGrid/mappers";
import {
  renderFieldConfigCell,
  renderFieldDescriptionCell,
} from "../../Components/Grid/SenchaLockedGrid/renderers";
import { put, takeLatest, select, cancel } from "redux-saga/effects";
import {
  mapAgentConfigCell,
  mapLastRunResultCell,
  mapAgentListStatusCell,
} from "../../Components/Grid/SenchaLockedGrid/mappers";
import {
  renderNameCell,
  renderConfigCell,
  renderLastRunResultCell,
  renderAgentStatusCell,
} from "../../Components/Grid/SenchaLockedGrid/renderers";
import axios from "axios";
import { GROUP_COLLECTIONS } from "../../constants/collections";
import { COMMANDS } from "../../constants/commands";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";

const Ext = window["Ext"];

function prepareGridColumns(data, accountInfo) {
  const renderers = {
    Name: {
      renderer: renderNameCell,
    },
    Config: {
      mapper: mapAgentConfigCell,
      renderer: renderConfigCell,
    },
    LastRunResult: {
      mapper: mapLastRunResultCell,
      renderer: renderLastRunResultCell,
    },
    Status: {
      mapper: mapAgentListStatusCell,
      renderer: (...params) => renderAgentStatusCell(...params, accountInfo),
    },
  };

  return data.ViewFields.map((field) => {
    if (renderers[field.Name]) {
      return {
        ...field,
        enableColumnMove: false,
        dataIndex: field.Name,
        text: `<span title='${field.Name}'> ${field.Name}</span>`,
        locked: field.IsSticky,
        hidden: field.IsHidden,
        width: field.ColumnWidth,

        cell: {
          height: 50,
          encodeHtml: true,
          xtype: "reactcell",
        },

        renderer: (value, record) =>
          renderers[field.Name].renderer(
            renderers[field.Name].mapper,
            value,
            record,
            accountInfo.Account.Features
          ),
      };
    }

    return {
      ...field,
      dataIndex: field.Name,
      text: `<span title='${field.Name}'>${field.Name}</span>`,
      locked: field.IsSticky,
      hidden: field.IsHidden,
      width: field.ColumnWidth,
      cell: {
        height: 50,
      },
    };
  });
}

function* fetchAgents({ storeId, viewId }) {
  const accountInfo = yield select((state) => state.account.accountInfo);

  const params = {
    CollectionID: CONSTANTS.COLLECTIONS.GROUP.AGENTS,
    FolderID: "",
    AccountKey: "",
    ViewID: viewId,
    OverrideViewName: "",
    HideSystemViewNames: "",
    Command: CONSTANTS.COMMANDS.COLLECTION_GETCOMPLETE,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams(params)
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    const store = Ext.getStore(storeId);

    const columns = prepareGridColumns(data, accountInfo);
    yield put({
      type: SET_AGENTS_LIST,
      payload: {
        columns,
        collectionState: data.CollectionState,
        collectionInfo: data.CollectionInfo,
        viewRow: data.ViewRow,
        sortedBy: data.ViewSorting,
        viewFields: data.ViewFields,
      },
    });
    const storeParams = {
      AddSummaryRow: "",
      Command: COMMANDS.VIEW_GETITEMS,
      CollectionID: CONSTANTS.COLLECTIONS.GROUP.AGENTS,
      ViewID: data.ViewRow.ViewID,
      OverrideViewName: "",
      FolderID: "",
      AccountKey: "",
      AgentID: 0,
      BookmarkID: 0,
      JobID: -1,
      ItemHistoryRangeStart: "",
      ItemHistoryRangeEnd: "",
      ItemStatusesToInclude: "",
      ColumnsToAlwaysInclude:
        "Config,Name,Description,Settings,Status,ErrorPostpone,CollectionID,LimitWebPageCredits,AgentGroup,Geolocation,ProxyPool,BrowserType",
      ForceViewSortable: "",
      AlwaysIncludeCustomFields: false,
      IncludeAdditionalCustomInterfaceColumns: true,
      SelectedIDs: "",
      ShowHistoryColumn: false,
      ShowSelected: false,
      TemplateCollectionID: 0,
      SearchText: data.CollectionState.SearchText,
      SearchField: data.CollectionState.SearchField,
      ShowMarkup: false,
      group: { property: "Group", direction: "ASC" },
    };

    store.baseParams = storeParams;
    store.getProxy().extraParams = store.baseParams;
    store.loadPage(
      data.CollectionState.PageIndex === 0 ? 1 : data.CollectionState.PageIndex,
      {
        params: { ...storeParams, page: 1, start: 0 },
      }
    );
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getAllAgents() {
  try {
    const params = {
      CollectionID: CONSTANTS.COLLECTIONS.GROUP.AGENTS,
      FolderID: "",
      AccountKey: "",
      OverrideViewName: "",
      HideSystemViewNames: "",
      Command: CONSTANTS.COMMANDS.COLLECTION_GETCOMPLETE,
    };

    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams(params)
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    handleJsonResultError(
      data.JsonResult.Result,
      data.JsonResult.ErrorDescription,
      data.JsonResult.ErrorList,
      CONSTANTS.NOTIFICATIONS.SUCCESS
    );

    const viewGetItemsParams = {
      AddSummaryRow: "",
      Command: COMMANDS.VIEW_GETITEMS,
      CollectionID: CONSTANTS.COLLECTIONS.GROUP.AGENTS,
      ViewID: data.ViewRow.ViewID,
      OverrideViewName: "",
      FolderID: "",
      AccountKey: "",
      AgentID: 0,
      BookmarkID: 0,
      JobID: -1,
      ItemHistoryRangeStart: "",
      ItemHistoryRangeEnd: "",
      ItemStatusesToInclude: "",
      ColumnsToAlwaysInclude:
        "Config,Name,Description,Settings,Status,ErrorPostpone,CollectionID,LimitWebPageCredits,AgentGroup,Geolocation,ProxyPool,BrowserType",
      ForceViewSortable: "",
      AlwaysIncludeCustomFields: false,
      IncludeAdditionalCustomInterfaceColumns: true,
      SelectedIDs: "",
      ShowHistoryColumn: false,
      ShowSelected: false,
      TemplateCollectionID: 0,
      SearchText: data.CollectionState.SearchText,
      SearchField: data.CollectionState.SearchField,
      ShowMarkup: false,
      group: { property: "Group", direction: "ASC" },
    };

    const agentsResult = yield axios.post(
      CONSTANTS.ROUTES.VIEW_GETITEMS,
      new URLSearchParams(viewGetItemsParams)
    );

    yield put({
      type: SET_ALL_AGENTS,
      payload: agentsResult.data.Rows,
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getViews({ type: action, formParams = {} }) {
  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETVIEWS,
    CollectionID: CONSTANTS.COLLECTIONS.GROUP.AGENTS,
    AccountKey: null,
    ...formParams,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETVIEWS,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    yield put({
      type: SET_AGENTS_SAVED_VIEWS,
      payload: {
        agentsSavedViews: data.ViewTable || [],
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* filterAgents({ type, dropDownValue, filter }) {
  function _getSearchField(value) {
    if (value === "All Fields") {
      return "";
    }
    if (value === "View Fields") {
      return "AllViewFields";
    }

    return value;
  }

  const { collectionState, agentsListStore } = yield select(
    (state) => state.agents
  );

  const searchField = _getSearchField(dropDownValue);

  const searchParams = {
    SearchText: filter,
    SearchField: searchField,
  };

  const params = {
    Command: CONSTANTS.COMMANDS.UPDATE_STATESEARCH,
    CollectionID: CONSTANTS.COLLECTIONS.GROUP.AGENTS,
    FolderID: collectionState.FolderID,
    AccountKey: "",
    ...searchParams,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.UPDATE_STATESEARCH,
      new URLSearchParams(params),
      {
        headers: {
          action: type,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    const updatedCollectionState = {
      ...collectionState,
      ...searchParams,
    };

    if (!data.JsonResult.ErrorCode) {
      agentsListStore.load({
        params: {
          Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
          ...updatedCollectionState,
        },
        scope: this,
      });
    }

    yield put({
      type: UPDATE_COLLECTION_STATE,
      payload: {
        collectionState: updatedCollectionState,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function getAgentFieldsGridColumns() {
  return [
    {
      dataIndex: "FieldID",
      text: "<span title='FieldID'>FieldID</span>",
      locked: true,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 75,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "Name",
      text: "<span title='Field Name'>Field Name</span>",
      locked: true,
      menuDisabled: true,
      resizable: true,
      searchable: true,
      width: 220,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "Format",
      text: "<span title='Format'>Format</span>",
      locked: false,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 125,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "Config",
      text: "<span title='Config'>Config</span>",
      locked: false,
      menuDisabled: true,
      resizable: true,
      searchable: true,
      width: 116,
      cell: {
        height: 50,
        encodeHtml: true,
        xtype: "reactcell",
      },
      renderer: (value, record) =>
        renderFieldConfigCell(mapFieldConfigCell, value, record),
    },
    {
      dataIndex: "Description",
      text: "<span title='Description'>Description</span>",
      locked: false,
      menuDisabled: true,
      resizable: true,
      searchable: true,
      width: 387,
      cell: {
        height: 50,
        encodeHtml: true,
        xtype: "reactcell",
      },
      renderer: (value, record) =>
        renderFieldDescriptionCell(mapFieldDescriptionCell, value, record),
    },
  ];
}

function* getAgentListFields({ storeId }) {
  const columns = getAgentFieldsGridColumns();
  const store = Ext.getStore(storeId);

  if (store) {
    const params = {
      CollectionID: GROUP_COLLECTIONS.AGENTS,
      Command: CONSTANTS.COMMANDS.COLLECTION_GETFIELDS,
      IncludeAgentFields: "",
      IncludeFieldMap: true,
      ExcludeSystemFields: true,
      ExcludeSeparators: true,
    };

    store.load({ params });

    yield put({
      type: SET_AGENTS_FIELDS,
      columns,
    });
  }
}

function* updateAgentsCustomFields({ params }) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_UPDATEFIELDS,
      new URLSearchParams(params)
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    const STORE_ID = CONSTANTS.STORES.IDS.AGENT_LIST_CUSTOM_FIELDS;
    const store = Ext.getStore(STORE_ID);

    store.load({
      params: {
        CollectionID: GROUP_COLLECTIONS.AGENTS,
        IncludeAgentFields: "",
        ExcludeSystemFields: true,
        ExcludeSeparators: true,
        Command: CONSTANTS.COMMANDS.COLLECTION_GETFIELDS,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* deleteAgentsView({ params, viewId }) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.VIEW_DELETE,
      new URLSearchParams(params)
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    yield put({
      type: DELETE_AGENTS_SAVED_VIEW,
      viewId,
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

async function getAgentsList({ setAgents }) {
  setAgents(
    await axios.post(
      CONSTANTS.ROUTES.VIEW_GETITEMS,
      new URLSearchParams({
        AddSummaryRow: "",
        Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
        CollectionID: GROUP_COLLECTIONS.AGENTS,
        ViewID: 12,
        AgentID: 0,
        BookmarkID: 0,
        JobID: -1,
        SearchText: "Schedule",
        SearchField: "Config",
        ColumnsToAlwaysInclude:
          " Config,Name,Description,Settings,Status,ErrorPostpone,CollectionID",
        AlwaysIncludeCustomFields: false,
        IncludeAdditionalCustomInterfaceColumns: true,
        ShowHistoryColumn: false,
        ShowSelected: false,
        TemplateCollectionID: 0,
      })
    )
  );
}
function* getAgentsStatusOverview() {
  const { data } = yield axios.post(
    CONSTANTS.ROUTES.AGENT_GET_STATUS_COUNTS,
    new URLSearchParams({
      Command: CONSTANTS.COMMANDS.AGENT_GET_STATUS_COUNTS,
    })
  );

  if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
    yield cancel();
  }

  yield put({
    type: SET_STATUS_COUNT,
    payload: data,
  });
}
export default function* agentsWatcher() {
  yield takeLatest(GET_AGENTS, fetchAgents);
  yield takeLatest(FILTER_AGENTS, filterAgents);
  yield takeLatest(GET_AGENTS_FIELDS, getAgentListFields);
  yield takeLatest(GET_AGENTS_SAVED_VIEWS, getViews);
  yield takeLatest(UPDATE_AGENTS_CUSTOM_FIELDS, updateAgentsCustomFields);
  yield takeLatest(DELETE_AGENTS_VIEW, deleteAgentsView);
  yield takeLatest(GET_AGENTS_LIST, getAgentsList);
  yield takeLatest(GET_AGENTS_STATUS_OVERVIEW, getAgentsStatusOverview);
  yield takeLatest(GET_ALL_AGENTS, getAllAgents);
}
