import React from "react";
import { Link } from "@reach/router";
import { withStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import IconGroup from "../../IconGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import variables from "../../../assets/sass/_variables.scss";
import ButtonLink from "../../ButtonLink";
import LinkWithIcon from "../../LinkWithIcon";
import LabelWithIcon from "../../LabelWithIcon";

const _generateIcon = ({ TYPE, ICON, DESCRIPTION, COLOR, visible }) => {
  if (TYPE === "fa") {
    return (
      <FontAwesomeIcon
        size="lg"
        icon={ICON}
        title={visible !== false ? DESCRIPTION : ""}
        opacity={visible === false ? 0 : 1}
        key={`grid-render-icon-${DESCRIPTION || TYPE}`}
        color={COLOR || variables["color-spacecadet"]}
      />
    );
  }

  return (
    <ICON
      key={`grid-render-icon-${DESCRIPTION || TYPE}`}
      title={visible !== false ? DESCRIPTION : ""}
      opacity={visible === false ? 0 : 1}
      width={variables["size-lg"]}
      fill={COLOR || variables["color-spacecadet"]}
    />
  );
};

export const renderAgentGroupFieldNameCell = (
  mapper,
  value,
  record,
  callback
) => (
  <ButtonLink
    label={record.data.Name}
    onClick={() => {
      if (typeof callback === "function") callback(record);
    }}
  />
);

export const renderAgentGroupFieldConfigCell = (mapper, value, record) => {
  const data = mapper(value, record);

  const icons = data.map(_generateIcon);
  return <IconGroup icons={icons} />;
};

export const renderAgentGroupFieldDescriptionCell = (mapper, value, record) =>
  mapper(value, record);

export const renderFolderListNameCell = (mapper, value, record) => {
  return (
    <Link
      to={`/folders/${record.data.FolderID}/agents`}
      state={{ folderId: record.data.FolderID }}
    >
      {value}
    </Link>
  );
};

export const renderFolderListNameCellModal = (mapper, value, record) => {
  if (record.data?.SelectedItemCount >= 1) {
    return (
      <LinkWithIcon
        path={`/folders/${record.data.FolderID}/agents`}
        icon={<FontAwesomeIcon size="lg" icon={["fad", "check"]} />}
        text={value}
      />
    );
  } else {
    return (
      <Link
        to={`/folders/${record.data.FolderID}/agents`}
        state={{ folderId: record.data.FolderID }}
      >
        {value}
      </Link>
    );
  }
};

export const renderBillingInvoiceAmountCell = (mapper, value, record) =>
  mapper(value, record);

export const renderNewViewNameCell = (mapper, value, record) => {
  const data = mapper(value, record);

  const OptionText = withStyles(({ spacing }) => ({
    root: {
      color: variables["color-midgray"],
      marginLeft: spacing(0.5),
    },
  }))(Typography);

  return (
    <Box display="flex">
      <Typography>{data.name}</Typography>
      {data.isSystem && <OptionText> (System)</OptionText>}
      {data.isLinkColumn && <OptionText> (Link Column)</OptionText>}
      {data.stickyIcon && _generateIcon(data.stickyIcon)}
      {data.hiddenIcon && _generateIcon(data.hiddenIcon)}
    </Box>
  );
};

export const renderSourceFieldsGridNameCell = (mapper, value, record) => {
  const data = mapper(value, record);

  let IconComponent = null;

  if (data.icon) {
    IconComponent = _generateIcon(data.icon);
  }

  return <LabelWithIcon icon={IconComponent} label={data.label} />;
};

export const renderSourceFieldsGridCollectionsCell = (
  mapper,
  value,
  record
) => {
  const { name, tooltip } = mapper(value, record);

  if (!tooltip) return <span>{name}</span>;

  return <span title={tooltip}>{value} collections</span>;
};
