import {
  SWITCH_COLLECTION_NEWCOLLECTION,
  COLLECTION_CREATE_NEW_FROM_FILE,
  COLLECTION_FROM_FILE,
  SET_COLLECTION_FROM_FILE,
  COLLECTION_FROM_FILE_FILECOLUMNS,
  SET_COLLECTION_FROM_FILE_FILECOLUMNS,
  COLLECTION_FROM_FILE_FIELDSGRID,
  SET_COLLECTION_FROM_FILE_FIELDSGRID,
  GET_COLUMN_HEADINGS_FROM_FILE,
  SET_COLUMN_HEADINGS_FROM_FILE,
  COLLECTION_UPDATEITEMSFROMFILE,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import { takeLatest, select, put, cancel } from "redux-saga/effects";
import axios from "axios";

function* ImportSampleDataSwitch({ checked, storeId }) {
  try {
    let store = window["Ext"].getStore(storeId);
    const reduxStore = yield select();

    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GET_COLUMN_HEADINGS_FROM_FILE,
      reduxStore.collections.columnHeadingsFromFileParameters
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    let newData = checked
      ? ImportSampleDataCreateEx(true, data.FileColumnNames, data.SampleRows)
      : ImportSampleDataCreateEx(false, data.FileColumnNames, data.SampleRows);
    yield store.loadData(newData);
  } catch (e) {
    console.log(e);
  }
}

function ImportSampleDataCreateEx(hasFieldNames, FileColumnNames, SampleRows) {
  let sampleColumnNames = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];
  let sampleColumnIndex = 0;

  let columnNames = FileColumnNames;
  let columnData = [];

  for (let columnIndex = 0; columnIndex < columnNames.length; columnIndex++) {
    let fieldName = "";
    let fileColumnName = "";
    if (hasFieldNames) {
      fieldName = Collections_Import_CleanupFieldName(columnNames[columnIndex]);
      fileColumnName =
        columnNames[columnIndex] + " (col " + (columnIndex + 1) + ")";
    } else {
      let firstCharacter = Math.floor(sampleColumnIndex / 26) - 1;
      if (firstCharacter > -1)
        fileColumnName += sampleColumnNames[firstCharacter];

      let secondCharacter = sampleColumnIndex % 26;
      fileColumnName += sampleColumnNames[secondCharacter];

      fieldName = fileColumnName;
      fileColumnName += " (col " + (columnIndex + 1) + ")";

      sampleColumnIndex++;
    }

    let sampleData = "";
    if (SampleRows.length > 0) {
      for (
        let sampleIndex = hasFieldNames ? 1 : 0;
        sampleIndex < SampleRows.length;
        sampleIndex++
      ) {
        sampleData = SampleRows[sampleIndex][columnIndex];
        if (sampleData !== "") break;
      }
    }

    columnData[columnIndex] = [
      true,
      fileColumnName,
      fieldName,
      false,
      columnIndex,
      sampleData,
    ];
  }

  return columnData;
}

function Collections_Import_CleanupFieldName(originalFieldName) {
  if (!originalFieldName) return "";

  let fieldName = originalFieldName;
  //eslint-disable-next-line
  fieldName = fieldName.replace(/[^a-zA-Z0-9 \-]+/g, "");
  fieldName = fieldName.trim().substring(0, 49);

  return fieldName;
}

function* createCollectionFromFile({
  storeId,
  FirstRowIsHeader,
  OriginalFileName,
  Name,
  Description,
  handleResponseMessage,
}) {
  try {
    let store = window["Ext"].getStore(storeId);
    const reduxStore = yield select();
    let gridItems = store?.data?.items;
    let gridItemParams = gridItems.map((item, index) => {
      return {
        Included: item.data.Included,
        FileColumnName: item.data.FileColumnName,
        Name: item.data.Name,
        IsUnique: item.data.IsUnique,
        OriginalIndex: item.data.OriginalIndex,
        _originalOrder: index,
        SampleData: item.data.SampleData,
      };
    });

    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GET_COLUMN_HEADINGS_FROM_FILE,
      reduxStore.collections.columnHeadingsFromFileParameters
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    const importResponse = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_CREATE_NEW_FROM_FILE,
      new URLSearchParams({
        CollectionID: 0,
        UploadFileName: data.FileName,
        OriginalFileName,
        Name,
        Description,
        Fields: JSON.stringify(gridItemParams),
        FirstRowIsHeader,
        Command: CONSTANTS.COMMANDS.COLLECTION_CREATE_NEW_FROM_FILE,
      })
    );
    if (importResponse.data.JsonResult.Result === "Error") {
      if (importResponse.data.JsonResult.ErrorDescription) {
        handleResponseMessage(importResponse.data.JsonResult.ErrorDescription);
      } else {
        let errorList = [];
        importResponse.data.JsonResult.ErrorList.forEach((element) => {
          errorList.push(element.ErrorDescription);
        });
        handleResponseMessage(errorList.toString());
      }
    }
    if (importResponse.data.JsonResult.Result === "Success") {
      handleResponseMessage("Success");
    }
  } catch (error) {
    console.log(error);
  }
}

const getCollectionFromFileColumns = () => {
  let validatingEditor = {
    type: "textfield",
    regex: /^[a-zA-Z0-9 -]{1,50}$/,
    regexText:
      "Field names may contain up to 50 alphanumeric, space, and hyphen characters.",
    msgTarget: "side",
    autoFitErrors: false,
  };

  return [
    {
      xtype: "checkcolumn",
      text: "<span title='Included'>Included</span>",
      dataIndex: "Included",
      width: 100,
      sortable: false,
      menuDisabled: true,
    },
    {
      width: 150,
      text: "File Column Name",
      dataIndex: "FileColumnName",
    },
    {
      width: 150,
      text: "Field Name",
      dataIndex: "Name",
      editor: validatingEditor,
    },
    {
      xtype: "checkcolumn",
      text: "<span title='Is Unique'>Is Unique</span>",
      dataIndex: "IsUnique",
      width: 100,
      sortable: false,
      menuDisabled: true,
    },
    {
      width: 255,
      text: "Sample Data",
      dataIndex: "SampleData",
    },
  ];
};

function* collectionFromFile() {
  const reduxStore = yield select();

  let store = window["Ext"].getStore(
    CONSTANTS.STORES.IDS.GET_COLUMN_HEADING_FILE
  );

  const { data } = yield axios.post(
    CONSTANTS.ROUTES.COLLECTION_GET_COLUMN_HEADINGS_FROM_FILE,
    reduxStore.collections.columnHeadingsFromFileParameters
  );

  if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
    yield cancel();
  }

  const columns = getCollectionFromFileColumns();
  yield put({
    type: SET_COLLECTION_FROM_FILE,
    columns,
  });

  const importWithHeaders = ImportSampleDataCreateEx(
    true,
    data.FileColumnNames,
    data.SampleRows
  );

  store.loadData(importWithHeaders);
}

function* fileColumnsGrid({ storeId }) {
  const reduxStore = yield select();

  let store = window["Ext"].getStore(storeId);

  const { data } = yield axios.post(
    CONSTANTS.ROUTES.COLLECTION_GET_COLUMN_HEADINGS_FROM_FILE,
    reduxStore.collections.columnHeadingsFromFileParameters
  );

  if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
    yield cancel();
  }

  let collectionFieldsColData = collectionFieldsColumnData(data);
  const columnsIncluded = collectionFieldsColData.map((element) => {
    return element[0];
  });

  let columnNames = data.FileColumnNames;
  let columnData = [];
  for (let i = 0; i < columnNames.length; i++) {
    if (!columnsIncluded.includes(columnNames[i]))
      columnData[i] = [columnNames[i]];
  }

  const columns = [
    {
      header: "Unmatched File Column",
      text: "<span title='Unmatched File Column'>Unmatched File Column</span>",
      dataIndex: "FileColumn",
      width: 195,
      sortable: false,
      menuDisabled: true,
    },
  ];

  yield put({
    type: SET_COLLECTION_FROM_FILE_FILECOLUMNS,
    columns,
  });

  store.loadData(columnData);
}

function collectionFieldsColumnData(data) {
  let columnData = [];

  for (
    let collectionFieldIndex = 0;
    collectionFieldIndex < data.CollectionFields.length;
    collectionFieldIndex++
  ) {
    let collectionField = data.CollectionFields[collectionFieldIndex];

    // See if there is a import column match.
    let autoSelectedFileColumnName = "";
    for (
      let fileColumnIndex = 0;
      fileColumnIndex < data.FileColumnNames.length;
      fileColumnIndex++
    ) {
      let fileColumnName = data.FileColumnNames[fileColumnIndex];
      let fieldMapColumnName = collectionField.Name;
      if (collectionField.FileColumn != null)
        fieldMapColumnName = collectionField.FileColumn;

      if (fieldMapColumnName.toLowerCase() === fileColumnName.toLowerCase()) {
        autoSelectedFileColumnName = fileColumnName;
        data.FileColumnNames.splice(fileColumnIndex, 1);
        break;
      }
    }

    columnData.push([
      autoSelectedFileColumnName,
      collectionField.IsUnique,
      collectionField.Name,
      collectionField.Description,
      collectionField.FieldID,
      collectionField.ImportColumn,
    ]);
  }

  return columnData;
}
function* collectionFieldsGrid({ storeId }) {
  const reduxStore = yield select();

  let store = window["Ext"].getStore(storeId);

  const { data } = yield axios.post(
    CONSTANTS.ROUTES.COLLECTION_GET_COLUMN_HEADINGS_FROM_FILE,
    reduxStore.collections.columnHeadingsFromFileParameters
  );

  if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
    yield cancel();
  }

  let columnData = collectionFieldsColumnData(data);

  let columns = [
    {
      header: "Matched File Column",
      text: "Matched File Column",
      dataIndex: "FileColumn",
      width: 190,
      sortable: false,
      menuDisabled: true,
      cls: "filecolumn-target",
    },
    {
      header: "Is Unique",
      text: "Is Unique",
      dataIndex: "IsUnique",
      resizable: false,
      width: 100,
      sortable: false,
      menuDisabled: true,
    },
    {
      header: "Field Name",
      text: "Field Name",
      dataIndex: "Name",
      width: 150,
      sortable: false,
      menuDisabled: true,
    },
    {
      header: "Description",
      text: "Description",
      dataIndex: "Description",
      minWidth: 150,
      flex: 1,
      sortable: false,
      menuDisabled: true,
    },
  ];
  yield put({
    type: SET_COLLECTION_FROM_FILE_FIELDSGRID,
    columns,
  });

  store.loadData(columnData);
}

function* getColumnHeadingsFromFile({
  params,
  setOpenedNewCollectionFromFile,
}) {
  yield put({
    type: SET_COLUMN_HEADINGS_FROM_FILE,
    params,
  });
  setOpenedNewCollectionFromFile(true);
}

function* updateItemsFromFile({
  CollectionID,
  storeId,
  FirstRowIsHeader,
  OriginalFileName,
  handleResponseMessage,
}) {
  try {
    let store = window["Ext"].getStore(storeId);
    const reduxStore = yield select();

    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GET_COLUMN_HEADINGS_FROM_FILE,
      reduxStore.collections.columnHeadingsFromFileParameters
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    let gridItems = store?.data?.items;
    let gridItemParams = gridItems.map((item, index) => {
      return {
        FileColumn: item.data.FileColumn,
        IsUnique: item.data.IsUnique,
        Name: item.data.Name,
        Description: item.data.Description,
        FieldID: item.data.FieldID,
        ImportColumn: item.data.ImportColumn,
        _originalOrder: index,
      };
    });

    const importResponse = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_UPDATEITEMSFROMFILE,
      new URLSearchParams({
        CollectionID,
        UploadFileName: data.FileName,
        OriginalFileName,
        Fields: JSON.stringify(gridItemParams),
        FirstRowIsHeader,
        Command: CONSTANTS.COMMANDS.COLLECTION_UPDATEITEMSFROMFILE,
      })
    );

    if (importResponse.data.JsonResult.Result === "Error") {
      if (importResponse.data.JsonResult.ErrorDescription) {
        handleResponseMessage(importResponse.data.JsonResult.ErrorDescription);
      } else {
        let errorList = [];
        importResponse.data.JsonResult.ErrorList.forEach((element) => {
          errorList.push(element.ErrorDescription);
        });
        handleResponseMessage(errorList.toString());
      }
    }
    if (importResponse.data.JsonResult.Result === "Success") {
      handleResponseMessage("Success");
    }
  } catch (e) {
    console.log(e);
  }
}

export default function* combinedCollectionWatcher() {
  yield takeLatest(SWITCH_COLLECTION_NEWCOLLECTION, ImportSampleDataSwitch);
  yield takeLatest(COLLECTION_CREATE_NEW_FROM_FILE, createCollectionFromFile);
  yield takeLatest(COLLECTION_FROM_FILE_FILECOLUMNS, fileColumnsGrid);
  yield takeLatest(COLLECTION_FROM_FILE_FIELDSGRID, collectionFieldsGrid);
  yield takeLatest(COLLECTION_FROM_FILE, collectionFromFile);
  yield takeLatest(GET_COLUMN_HEADINGS_FROM_FILE, getColumnHeadingsFromFile);
  yield takeLatest(COLLECTION_UPDATEITEMSFROMFILE, updateItemsFromFile);
}
