import { GET_SCHEDULER, SET_SCHEDULER, ADD_UPDATE_SCHEDULER } from "../types";
import { CONSTANTS } from "../../constants/constants";
import axios from "axios";
import { takeLatest, put, cancel } from "redux-saga/effects";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";
const Ext = window["Ext"];

function* getScheduler({ type: action, formParams = {} }) {
  const params = {
    ScheduleItemID: formParams.ScheduleItemID,
    ScheduleItemType: formParams.ScheduleItemType,
    Command: CONSTANTS.COMMANDS.SCHEDULE_GET,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.SCHEDULE_GET,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    yield put({
      type: SET_SCHEDULER,
      payload: {
        ...data,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* addUpdateScheduler({
  type: action,
  update = false,
  ScheduleItemType,
  ScheduleMethod,
  ItemIDs,
  formParams = {},
  storeId,
}) {
  const [Route, Command] = (() => {
    if (update) {
      return [
        CONSTANTS.ROUTES.SCHEDULE_UPDATE,
        CONSTANTS.COMMANDS.SCHEDULE_UPDATE,
      ];
    } else {
      return [CONSTANTS.ROUTES.SCHEDULE_ADD, CONSTANTS.COMMANDS.SCHEDULE_ADD];
    }
  })();

  let filteredFormParams = {};
  for (const [key, value] of Object.entries(formParams)) {
    if (value !== "") {
      filteredFormParams[key] = value;
    }
  }

  const params = {
    ...filteredFormParams,
    ScheduleItemID: [...ItemIDs],
    ScheduleItemType,
    ScheduleMethod,
    Command,
  };

  try {
    const { data } = yield axios.post(Route, new URLSearchParams(params), {
      headers: {
        action,
      },
    });
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    yield put({
      type: SET_SCHEDULER,
      payload: {
        ...data,
      },
    });
    if (storeId) Ext.getStore(storeId).load();
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

export default function* publishWatcher() {
  yield takeLatest(GET_SCHEDULER, getScheduler);
  yield takeLatest(ADD_UPDATE_SCHEDULER, addUpdateScheduler);
}
