import {
  GET_IMPORT_DEPARTMENT_EXISTING,
  SET_IMPORT_DEPARTMENT_EXISTING,
  ACCOUNT_ADDREMOVEGROUPS,
  FIND_ACCOUNTBYEMAIL,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import { put, takeEvery, cancel } from "redux-saga/effects";
import axios from "axios";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";

const Ext = window["Ext"];

function getGridColumns() {
  return [
    {
      dataIndex: "Name",
      text: "<span title=''>Assigned to Account</span>",
      menuDisabled: true,
      resizable: true,
      searchable: true,
      width: 200,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "MasterAccount",
      text: "<span title='Assigned to Account'>Assigned to Account</span>",
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 200,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "Email",
      text: "<span title='Other Email'>Other Email</span>",
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 200,
      cell: {
        height: 50,
      },
    },
  ];
}

function* fetchExistingDepartments({
  type: action,
  STORE_ID,
  OtherAccountAdmins,
}) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.USER_GETEXISTINGACCOUNTS,
      new URLSearchParams({
        Command: CONSTANTS.COMMANDS.USER_GETEXISTINGACCOUNTS,
        OtherAccountAdmins: OtherAccountAdmins
          ? JSON.stringify(OtherAccountAdmins)
          : "",
      }),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    const columns = getGridColumns();
    const store = Ext.getStore(STORE_ID);
    if (store) {
      store.loadData(data.Rows);
    }

    yield put({
      type: SET_IMPORT_DEPARTMENT_EXISTING,
      payload: {
        columns,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* accountAddRemoveGroups({
  type: action,
  accounts,
  errorResponseCallback,
  successResponseCallback,
  setLoading,
}) {
  try {
    setLoading(true);
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.ACCOUNT_ADDREMOVEGROUPS,
      new URLSearchParams({
        addGroups: accounts.map((account) => account.AccountKey),
        Command: CONSTANTS.COMMANDS.ACCOUNT_ADDREMOVEGROUPS,
      }),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (data.JsonResult.Result === "Error") {
      errorResponseCallback(data.JsonResult.ErrorDescription);
    }

    if (data.JsonResult.Result === "Success" && data.NewAccountKey) {
      successResponseCallback(data.NewAccountKey);
    }
    setLoading(false);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* findAccountByEmail({
  type: action,
  storeId,
  formParams,
  successResponseCallback,
  errorResponseCallback,
  setLoading,
}) {
  try {
    setLoading(true);
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.USER_VALIDATE,
      new URLSearchParams({
        ValidateEmail: formParams.ValidateEmail,
        ValidatePassword: formParams.ValidatePassword,
        Command: CONSTANTS.COMMANDS.USER_VALIDATE,
      }),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (data.JsonResult.Result === "Error") {
      errorResponseCallback(data.JsonResult.ErrorDescription);
      setLoading(false);
    }

    const OtherAccountAdmins = [
      {
        Email: formParams.ValidateEmail,
        Password: formParams.ValidatePassword,
      },
    ];

    if (data.JsonResult.Result === "Success") {
      setLoading(false);
      successResponseCallback();
      yield fetchExistingDepartments({
        type: CONSTANTS.COMMANDS.USER_VALIDATE,
        STORE_ID: storeId,
        OtherAccountAdmins,
      });
    }
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

export default function* departmentImportWatcher() {
  yield takeEvery(GET_IMPORT_DEPARTMENT_EXISTING, fetchExistingDepartments);
  yield takeEvery(ACCOUNT_ADDREMOVEGROUPS, accountAddRemoveGroups);
  yield takeEvery(FIND_ACCOUNTBYEMAIL, findAccountByEmail);
}
