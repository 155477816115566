import { CONSTANTS } from "../src/constants/constants";

const EventEmitter = require("events");

class EventHandlingEmitter extends EventEmitter {}
class ErrorHandlingEmitter extends EventEmitter {}

export const notificationHandling = new EventHandlingEmitter();
export const eventHandling = new EventHandlingEmitter();
export const errorHandlingEvent = new ErrorHandlingEmitter();
export const successHandlingEvent = new EventHandlingEmitter();

export const handleJsonResultError = (
  jsonResult,
  errorDescription,
  errorList,
  successMessage
) => {
  if (errorDescription !== "" && typeof errorDescription === "string") {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: errorDescription,
    });
    return false;
  }

  if (Array.isArray(errorList) && errorList.length > 0) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: errorList[0].ErrorDescription,
    });
    return false;
  }

  if (jsonResult === "Success") {
    successHandlingEvent.emit(CONSTANTS.EVENTS.SUCCESS_EVENT, {
      msg: successMessage,
    });
    return true;
  }
};
