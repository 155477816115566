export const USER_TYPES = {
  MASTER_ACCOUNT_ADMINISTRATOR: "MasterAccountAdministrator",
  MOZENDA: "Mozenda",
  ADMINISTRATOR: "Administrator",
  USER: "User",
  DISABLED: "Disabled",
};

export const ACCOUNT_PERMISSIONS = {
  ADMIN: "Admin",
  RUN: "Run",
  CONFIGURE: "Configure",
  AGENT_BUILD: "AgentBuild",
  VIEW: "View",
  CONFIDENTIAL: "Confidential",
  EXPORT: "Export",
  IMPORT: "Import",
  CLEAR: "Clear",
  COLLECTION_BUILD: "CollectionBuild",
  USERS: "Users",
  BILLING: "Billing",
  API: "API",
  SEQUENCES: "Sequences",
};
