import React from "react";
import { Link } from "@reach/router";
import IconGroup from "../../IconGroup";
import LabelWithIcon from "../../LabelWithIcon";
import LinkWithIcon from "../../LinkWithIcon";
import ButtonLink from "../../ButtonLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@material-ui/core";
import variables from "../../../assets/sass/_variables.scss";
import { JOB_STATUS } from "../../../constants/jobs";

const _generateIcon = ({ TYPE, ICON, DESCRIPTION, COLOR, visible }) => {
  if (TYPE === "fa") {
    return (
      <FontAwesomeIcon
        size="lg"
        icon={ICON}
        title={visible !== false ? DESCRIPTION : ""}
        opacity={visible === false ? 0 : 1}
        key={`grid-render-icon-${DESCRIPTION || TYPE}`}
        color={COLOR || variables["color-spacecadet"]}
      />
    );
  }

  return (
    <ICON
      key={`grid-render-icon-${DESCRIPTION || TYPE}`}
      title={visible !== false ? DESCRIPTION : ""}
      opacity={visible === false ? 0 : 1}
      width={variables["size-lg"]}
      fill={COLOR || variables["color-spacecadet"]}
    />
  );
};

export const renderConfigCell = (mapper, value, record) => {
  const data = mapper(value, record);

  if (data.length === 0) return null;

  let icons = data.map(_generateIcon);

  return <IconGroup icons={icons} />;
};

export const renderItemHistoryCell = (value, record) => {
  const iconState = [
    {
      id: "Added",
      icon: ["fad", "plus-circle"],
      iconColor: `${variables["color-success"]}`,
    },
    {
      id: "Changed",
      icon: ["fad", "not-equal"],
      iconColor: `${variables["color-warning"]}`,
    },
    {
      id: "ShowMarkup",
      icon: ["fas", "check"],
      iconColor: `${variables["color-spacecadet"]}`,
    },
    {
      id: "Unchanged",
      icon: ["fad", "equals"],
      iconColor: `${variables["color-info"]}`,
    },
    {
      id: "Removed",
      icon: ["fas", "minus-circle"],
      iconColor: `${variables["color-danger"]}`,
    },
  ];

  let icon = iconState.filter((x) => x.id === record.data?.ItemStatus);

  return icon.length > 0 ? (
    <FontAwesomeIcon
      size="lg"
      title={icon[0].id}
      icon={icon[0].icon}
      color={icon[0].iconColor}
    />
  ) : (
    <br />
  );
};

export const renderMarkupChangeCell = (
  record,
  field,
  CollectionID,
  callback,
  isWatchField,
  BookmarkID = 0
) => {
  function createMarkup(el) {
    return { __html: el };
  }
  const noop = () => {};

  if (
    record?.data?.[field?.Name] &&
    isWatchField &&
    record?.data?.[field?.Name].startsWith("≠:")
  ) {
    let el = document.createElement("html");
    let parsedEl = record?.data?.[field?.Name]?.replace("≠:", "");
    el.innerHTML = parsedEl;

    let ins = document.getElementsByTagName("ins");
    for (let i = 0; i < ins.length; i++) {
      ins[i].style.backgroundColor = "#e6ffe6";
    }

    let del = document.getElementsByTagName("del");
    for (let i = 0; i < del.length; i++) {
      del[i].style.backgroundColor = "#ffe6e6";
    }

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
        }}
      >
        <IconButton
          size="small"
          onClick={(e) => {
            e.preventDefault();
            typeof callback === "function"
              ? callback({
                  state: true,
                  BookmarkID,
                  ItemID: record.data.ItemID,
                  FieldName: field?.Name,
                  CollectionID,
                })
              : noop();
          }}
        >
          <FontAwesomeIcon
            size="xs"
            title="ShowMarkup"
            icon={["fad", "not-equal"]}
            color={variables["color-warning"]}
          />
        </IconButton>
        <div
          dangerouslySetInnerHTML={createMarkup(parsedEl)}
          style={{ marginLeft: "5px" }}
        />
      </div>
    );
  } else {
    return (
      <span title={record.data[field.Name]}>{record.data[field.Name]}</span>
    );
  }
};

export const renderNameCell = (mapper, value, record) => {
  return <Link to={`/agents/${record.id}`}>{value}</Link>;
};

export const renderFieldNameCell = (mapper, value, record, callback) => {
  return (
    <ButtonLink
      label={record.data.Name}
      onClick={() => {
        if (typeof callback === "function") callback(record);
      }}
    />
  );
};

export const renderFieldConfigCell = (mapper, value, record) => {
  const data = mapper(value, record);
  const icons = data.map(_generateIcon);
  return <IconGroup icons={icons} />;
};

export const renderFieldDescriptionCell = (mapper, value, record) =>
  mapper(value, record);

export const renderAgentGroupAgentNameCell = (mapper, value, record) => {
  const agentGroupId = record.get("AgentGroup-Raw");
  return (
    <Link
      to={`/agents/${record.id}/data`}
      state={{
        agentGroupId: true,
        previousLink: `/agentgroups/${agentGroupId}/agents`,
      }}
    >
      {value}
    </Link>
  );
};

export const renderCookieNameCell = (mapper, value, record) => {
  return <Link to={`/cookies/${record.id}`}>{value}</Link>;
};

export const renderLastRunResultCell = (mapper, value) => {
  if (value) {
    const { icon, label } = mapper(value);

    const IconComponent = _generateIcon(icon);

    return <LabelWithIcon icon={IconComponent} label={label} />;
  }
  return null;
};

export const renderNextRunTimeCell = (mapper, value) => {
  return value !== "" ? value : "Unscheduled";
};

export const renderCollectionNameCell = (mapper, value, record) => {
  const data = mapper(value, record);
  const icon = _generateIcon(data.type);

  let path =
    data.collectionType === "Agent"
      ? "/agents"
      : `/collections/${data.collectionType.toLowerCase()}`;

  return (
    <LinkWithIcon path={`${path}/${data.id}`} icon={icon} text={data.name} />
  );
};

export const renderSequencesNameCell = (mapper, value, record) => {
  return <Link to={`/sequences/${record.id}`}>{value}</Link>;
};

export const renderFolderNameCell = (mapper, value, record) => {
  return (
    <Link
      to={`/agents/${record.id}/data`}
      state={{ folderId: true, previousLink: `/folders/${record.id}/agents` }}
    >
      {value}
    </Link>
  );
};

export const renderFolderCollectionNameCell = (mapper, value, record) => {
  //combined or standard
  const collectionType = record.data.CollectionType.toLowerCase();
  let link = (
    <Link
      to={`/collections/${collectionType}/${record.id}/data/`}
      state={{
        folderId: true,
        previousLink: `/folders/${record.id}/collections`,
      }}
    >
      {value}
    </Link>
  );

  if (collectionType === "agent") {
    link = (
      <Link
        to={`/agents/${record.id}/data`}
        state={{
          folderId: true,
          previousLink: `/folders/${record.id}/collections`,
        }}
      >
        {value}
      </Link>
    );
  }

  return link;
};

export const renderSequenceStepCell = (mapper, value, record, callback) => {
  const data = mapper(value, record);
  const icon = _generateIcon(data);

  return (
    <ButtonLink
      label={value}
      startIcon={icon}
      onClick={() => {
        if (typeof callback === "function") callback(record);
      }}
    />
  );
};

export const renderSequenceStepStatusCell = (mapper, value, record) => {
  const data = mapper(value, record);

  if (record.get("StepType") === "AgentRun") {
    // const hasPermissionAgents = UserHasAgentPermission();
    // const hasCollectionPermission = UserHasCollectionPermission();

    // if (!hasPermissionAgents && !hasCollectionPermission) {
    // Ext.MessageBox.show({
    // 	title: 'Permission Needed',
    // 	msg: 'Your user profile does not have permission to view an Agent.',
    // 	width: 400,
    // 	buttons: Ext.MessageBox.OK
    //// });
    // return;
    // }
    return (
      <Link to={`/agents/${record.get("StepItemID")}`}>{data.STATUS}</Link>
    );
  }

  if (!data.ICON || data.ICON === "") {
    return (
      <LabelWithIcon
        label={{
          value: data.STATUS,
          color: variables["color-spacecadet"],
        }}
      />
    );
  } else if (data.TYPE === "fa") {
    const spin = data.STATUS === JOB_STATUS.RUNNING.STATUS;

    return (
      <LabelWithIcon
        icon={
          <FontAwesomeIcon
            size="lg"
            icon={data.ICON}
            color={data.COLOR || variables["color-spacecadet"]}
            spin={spin}
          />
        }
        label={{
          value: data.STATUS,
          color: variables["color-spacecadet"],
        }}
      />
    );
  }

  const Icon = data.ICON;
  return (
    <LabelWithIcon
      height={variables["size-lg"]}
      icon={<Icon fill={data.COLOR || variables["color-spacecadet"]} />}
      label={{
        value: data.STATUS,
        color: variables["color-spacecadet"],
      }}
    />
  );
};

export const renderSequenceStepProgressCell = (mapper, value, record) => {
  return mapper(value, record);
};

export const renderDepartmentUsersStatusCell = (mapper, value, record) => {
  const data = mapper(value, record);

  const icons = data.map(_generateIcon);

  return <IconGroup icons={icons} />;
};

export const renderDepartmentUsersAgentsCell = (mapper, value, record) => {
  const data = mapper(value, record);

  const icons = data.map(_generateIcon);

  return <IconGroup icons={icons} />;
};

export const renderDepartmentUsersCollectionsCell = (mapper, value, record) => {
  const data = mapper(value, record);

  const icons = data.map(_generateIcon);

  return <IconGroup icons={icons} />;
};

export const renderDepartmentUsersSequencesCell = (mapper, value, record) => {
  const data = mapper(value, record);

  const icons = data.map(_generateIcon);

  return <IconGroup icons={icons} />;
};

export const renderDepartmentUsersAccountCell = (mapper, value, record) => {
  const data = mapper(value, record);

  const icons = data.map(_generateIcon);

  return <IconGroup icons={icons} />;
};

export const renderDepartmentUsersNameFullCell = (mapper, value, record) =>
  mapper(value, record);

export const renderDepartmentUsersLastSeenCell = (mapper, value, record) =>
  mapper(value, record);

export const renderDepartmentUsersInvitedLastCell = (mapper, value, record) =>
  mapper(value, record);

export const renderCookieDetailsNameCell = (
  mapper,
  value,
  record,
  callback
) => {
  return (
    <ButtonLink
      label={value}
      onClick={() => {
        if (typeof callback === "function") callback(record);
      }}
    />
  );
};
