import { SET_RECENT_ITEMS, UPDATE_RECENT_ITEMS } from "../types";
import createReducer from "../createReducer";

const initialState = {
  recentItems: [],
};

export const recentItems = createReducer(initialState, {
  [SET_RECENT_ITEMS](state, { payload }) {
    return {
      ...state,
      recentItems: payload,
    };
  },
  [UPDATE_RECENT_ITEMS](state, { payload }) {
    return {
      ...state,
      recentItems: payload,
    };
  },
});
