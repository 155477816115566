import {
  GET_AGENT_INSIGHTS,
  SET_AGENT_INSIGHTS,
  SET_AGENTS_INSIGHTS_SAVED_VIEWS,
  GET_AGENT_INSIGHTS_SAVED_VIEWS,
  AGENT_INSIGHTS_UPDATE_STATEVIEW,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import { takeLatest, put, call, cancel } from "redux-saga/effects";
import axios from "axios";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";

const Ext = window["Ext"];

function prepareModel(data, store) {
  const model = store.config.model;
  model.setFields(data);
  return model;
}

function prepareColumns(data) {
  const blankCategoryDelimeter = "<blank-";
  const blankCategoryText = "&zwnj;";
  let columnsToDisplay = [];

  for (let i = 0; i < data.ViewFields.length; i++) {
    let column = data.ViewFields[i];

    if (column.IsSticky && column.IsSticky === true) {
      columnsToDisplay.push({
        dataIndex: column.HeaderText ? column.HeaderText : column.Name,
        text: column.HeaderText
          ? `<span title='${column.HeaderText}'>${column.HeaderText}</span>`
          : `<span title='${column.Name}'>${column.Name}</span>`,
        locked: column.IsSticky,
        hidden: column.IsHidden,
        width: column.ColumnWidth,
        menuDisabled: true,
      });
      continue;
    }

    if (column.Category && column.Category.split("|").length === 0) {
      columnsToDisplay.push(column);
      continue;
    }

    let cellClasses = "";
    let columnArrayContext = columnsToDisplay;

    for (let categoryName of column.Category.split("|")) {
      let categoryPosition = columnArrayContext.findIndex(
        (column) =>
          column.text === categoryName ||
          (column.categoryName && column.categoryName === categoryName)
      );

      if (categoryPosition < 0) {
        const disabled = categoryName.startsWith(blankCategoryDelimeter);

        columnArrayContext.push({
          text: !disabled ? categoryName : blankCategoryText,
          dataIndex: column.Name,
          isCategory: true,
          categoryName: categoryName,
          categoryDisabled: disabled,
          menuDisabled: disabled || true,
          cls: !disabled ? "" : "m-grid-category-disabled",
          defaults: {
            cls: "rotate",
            menuDisabled: true,
            sortable: false,
          },
          componentCls: "",
          tdCls: "",
          columns: [],
        });

        categoryPosition = columnArrayContext.length - 1;
      }

      cellClasses = columnArrayContext[categoryPosition].tdCls || cellClasses;
      columnArrayContext = columnArrayContext[categoryPosition].columns;
    }

    column.tdCls = cellClasses;

    columnArrayContext.push({
      text: `<span title='${column.Name}'>${column.Name}</span>`,
      dataIndex: column.Name,
      width: column.ColumnWidth,
    });
  }

  return columnsToDisplay;
}

function* createStore(storeId, data, filteredData = {}) {
  const store = Ext.getStore(storeId);

  if (store) {
    const model = prepareModel(data, store);
    store.removeAll(true);
    store.setModel(model);
    store.baseParams = {
      AddSummaryRow: "",
      Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
      CollectionID: CONSTANTS.COLLECTIONS.INSIGHTS.INSIGHTS_HISTORY,
      ViewID: data.ViewRow.ViewID ?? "",
      OverrideViewName: "All Jobs",
      FolderID: "",
      AccountKey: filteredData.AccountKey ?? "",
      AgentID: 0,
      BookmarkID: 0,
      JobID: -1,
      ItemHistoryRangeStart: "",
      ItemHistoryRangeEnd: "",
      ItemStatusesToInclude: "",
      ColumnsToAlwaysInclude: "Name,AgentID",
      ForceViewSortable: "",
      AlwaysIncludeCustomFields: true,
      IncludeAdditionalCustomInterfaceColumns: true,
      SelectedIDs: "",
      ShowHistoryColumn: false,
      ShowSelected: false,
      TemplateCollectionID: 0,
      ContextRestrictionFieldNames: "AgentID",
      ContextRestrictionFieldValues: filteredData.AgentID,
      SearchText: "",
      SearchField: "",
      ShowMarkup: false,
    };

    store.getProxy().extraParams = store.baseParams;
    store.loadPage(1, { params: store.baseParams });
  }

  const columns = prepareColumns(data);

  yield put({
    type: SET_AGENT_INSIGHTS,
    payload: {
      columns,
      collectionState: data.CollectionState,
      viewRow: data.ViewRow,
      sortedBy: data.ViewSorting,
      viewFields: data.ViewFields,
    },
  });
}

function* fetchAgentInsights({ type: action, storeId, formParams = {} }) {
  const params = {
    CollectionID: CONSTANTS.COLLECTIONS.INSIGHTS.INSIGHTS_HISTORY,
    Command: CONSTANTS.COMMANDS.COLLECTION_GETCOMPLETE,
    FolderID: formParams.FolderID ?? "",
    AccountKey: formParams.AccountKey ?? "",
    ViewID: formParams.ViewID ?? 0,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    const filteredData = {
      ViewID: data.ViewRow.ViewID,
      AgentID: data.AgentID,
      BookmarkID: data.Collection.BookmarkID,
      FolderID: data.CollectionState.FolderID,
      ...formParams,
    };

    yield call(createStore, storeId, data, filteredData);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getSavedViews({ type: action, formParams = {} }) {
  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETVIEWS,
    CollectionID: CONSTANTS.COLLECTIONS.INSIGHTS.INSIGHTS_HISTORY,
    AccountKey: formParams.AccountKey ?? "",
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETVIEWS,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    yield put({
      type: SET_AGENTS_INSIGHTS_SAVED_VIEWS,
      payload: {
        savedViews:
          data.ViewTable.filter((item) => item.Name !== "Default") || [],
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* updateStateView({ type: action, storeId, formParams = {} }) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_UPDATESTATEVIEW,
      new URLSearchParams({
        CollectionID: CONSTANTS.COLLECTIONS.INSIGHTS.INSIGHTS_HISTORY,
        FolderID: formParams.FolderID || "",
        AccountKey: formParams.AccountKey,
        ViewID: formParams.ViewID,
        Command: CONSTANTS.COMMANDS.COLLECTION_UPDATESTATEVIEW,
      }),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    yield fetchAgentInsights({ type: action, storeId, formParams });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

export default function* agentInsightsWatcher() {
  yield takeLatest(GET_AGENT_INSIGHTS, fetchAgentInsights);
  yield takeLatest(GET_AGENT_INSIGHTS_SAVED_VIEWS, getSavedViews);
  yield takeLatest(AGENT_INSIGHTS_UPDATE_STATEVIEW, updateStateView);
}
