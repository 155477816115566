import axios from "axios";
import { CONSTANTS } from "../constants/constants";
import { ROUTES } from "../constants/routes";
import { notificationHandling } from "../EventHandling";

axios.interceptors.request.use(
  (config) => {
    if (process.env.REACT_APP_ENV !== "mock" && config.headers.action)
      delete config.headers.action;
    if (!config.headers["Content-Type"]) {
      config.headers["Content-Type"] =
        "application/x-www-form-urlencoded; charset=UTF-8";
    }
    if (!config.withCredentials) {
      config.withCredentials = true;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  function axiosSuccessInterceptor(jsonResponse) {
    if (jsonResponse.status === 500) {
      notificationHandling.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
        msg:
          "Internal Server Error! Something went wrong!!! Try again later...",
        type: "danger",
      });
      return;
    }

    if (jsonResponse.data.JsonResult?.Result === "Success") {
      const errorDescription = jsonResponse.data.JsonResult.ErrorDescription;
      const errorList = jsonResponse.data.JsonResult.ErrorList;
      if (
        errorDescription ===
        "Exception has been thrown by the target of an invocation."
      ) {
        notificationHandling.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
          msg: `Please contact Mozenda support at 801-995-4550 or email us at support@mozenda.com for additional information.
          Error code: TargetInvocationException/View.GetItems
          Description: Exception has been thrown by the target of an invocation.`,
          type: "danger",
        });
        return;
      }

      if (errorDescription !== "" && typeof errorDescription === "string") {
        notificationHandling.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
          msg: errorDescription,
          type: "danger",
        });
      }

      if (Array.isArray(errorList) && errorList.length > 0) {
        notificationHandling.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
          msg: errorList[0].ErrorDescription,
          type: "danger",
        });
      }
    }
    return jsonResponse;
  },
  (error) => Promise.reject(error)
);

const interceptor = (store) =>
  axios.interceptors.response.use(
    function axiosSuccessInterceptor(jsonResponse) {
      const maintenanceUrlPath = ROUTES.PUBLIC_MAINTENANCE;
      const maintenanceStatus = "SystemUnavailable";

      if (jsonResponse.data.JsonResult?.ErrorCode === maintenanceStatus) {
        store.dispatch({
          type: "MAINTENANCE_MODE",
          payload: { maintenanceMode: true },
        });
        if (window.location.pathname !== maintenanceUrlPath) {
          window.location = maintenanceUrlPath;
        }
      }

      if (
        jsonResponse.data.JsonResult?.Result === CONSTANTS.LOGIN_RESPONSE_FLAG
      ) {
        store.dispatch({ type: "SESSION_EXPIRED" });
      }

      return jsonResponse;
    },
    (error) => Promise.reject(error)
  );

export default {
  interceptor,
};
