import { createMuiTheme } from "@material-ui/core/styles";
import variables from "./assets/sass/_variables.scss";

const theme = createMuiTheme({
  typography: {
    fontFamily: variables["fontfamily-default"],
  },
});

export default theme;
