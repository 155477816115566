import {
  SET_AGENTS_LIST,
  SET_AGENTS_CUSTOM_FIELDS,
  SET_AGENTS_SAVED_VIEWS,
  SET_AGENT_DATA,
  SET_AGENT_JOBS,
  SET_AGENT_FIELDS,
  SET_AGENT_HISTORY,
  SET_AGENT_HISTORY_ITEMS,
  SET_AGENT_INSIGHTS,
  SET_AGENT_JOB_STATUS,
  UPDATE_COLLECTION_STATE,
  SET_AGENT_INFO,
  SET_AGENTS_HISTORY_SAVED_VIEWS,
  SET_AGENT_DATA_SAVED_VIEWS,
  CLEANUP_AGENT_DATA,
  AGENT_UPDATE_STATUS,
  UPDATE_AGENTS_STATE_SEARCH,
  UPDATE_AGENTS_DATA_STATE_SEARCH,
  UPDATE_AGENTS_HISTORY_STATE_SEARCH,
  UPDATE_AGENTS_JOBS_STATE_SEARCH,
  SET_AGENTS_JOBS_SAVED_VIEWS,
  UPDATE_AGENTS_INSIGHTS_STATE_SEARCH,
  SET_AGENTS_INSIGHTS_SAVED_VIEWS,
  UPDATE_AGENTS_VIEW_ROW,
  DELETE_AGENTS_DATA_VIEW_ROW,
  UPDATE_AGENTS_DATA_VIEW_ROW,
  DELETE_AGENT_JOBS_VIEW,
  UPDATE_AGENTS_JOBS_VIEW_ROW,
  DELETE_AGENTS_HISTORY_VIEW,
  UPDATE_AGENTS_HISTORY_VIEW_ROW,
  SET_AGENTS_FIELDS,
  AGENT_DATA_UPDATEVIEWTABLE,
  AGENTS_UPDATEVIEWTABLE,
  AGENT_JOBS_UPDATEVIEWTABLE,
  AGENT_HISTORY_UPDATEVIEWTABLE,
  SET_AGENT_BUILTBYPREVIOUS,
  SET_AGENT_JOBSACTIVE,
  SET_VIEW_NAME,
  UPDATE_EXPORT_DATA_VIEW_NAME,
  UPDATE_AGENT_DATA_BOOKMARK_ID,
  SET_BOOKMARK_LIST,
  SET_AGENT_JOB_STATISTICS,
  UPDATE_BOOKMARK_ON_RUNNING,
  SET_EXPORT_AGENT_DATA_VIEW_NAME,
  DELETE_AGENTS_SAVED_VIEW,
  SET_STATUS_COUNT,
  SET_AGENT_HISTORY_BOOKMARKS,
  SET_ITEM_HISTORY,
  SET_ALL_AGENTS,
} from "../types";
import createReducer from "../createReducer";

const initialState = {
  collectionState: {},
  collectionInfo: {},
  agentInfo: {},
  CollectionItem: {},
  viewRow: {},
  agentDataViewRow: {},
  agentDataCollectionState: {},
  agentDataViewFields: null,
  agentJobsViewRow: {},
  agentJobStatistics: null,
  sortedBy: [],
  agentJobsTrackHistory: false,
  agentJobStatus: null,
  agentUpdateStatus: null,
  agentDataSavedViews: [],
  agentsListColumns: [],

  agentsCustomFieldsColumns: [],
  agentsSavedViews: [],
  agentDataColumns: [],
  agentJobsColumns: [],
  agentFieldsColumns: [],
  agentHistoryColumns: [],
  agentInsightsColumns: [],
  agentJobsCollectionState: {},
  agentJobsViewFields: null,
  agentHistoryCollectionState: {},
  agentInsightsCollectionState: {},
  agentHistoryViewRow: {},
  agentInsightsViewRow: {},
  agentHistorySavedViews: [],
  agentInsightsSavedViews: [],
  agentListFields: [],
  agentBuildByPreviousVersion: null,
  agentHasJobsActive: null,
  currentAgentTrackHistory: null,
  agentHistoryItems: [],
  agentItemHistory: {},
  agentHistoryBookMarks: [],
  agentStatusCount: [],
  allAgents: [],
};

export const agents = createReducer(initialState, {
  [SET_AGENTS_LIST](state, { payload }) {
    return {
      ...state,
      agentsListColumns: payload.columns,
      collectionState: payload.collectionState,
      collectionInfo: payload.collectionInfo,
      viewRow: payload.viewRow,
      sortedBy: payload.sortedBy,
      viewFields: payload.viewFields.map((viewField) => {
        return {
          name: viewField.Name,
          value: viewField.Name,
          fieldId: viewField.FieldID,
        };
      }),
      fieldOperators: payload.fieldOperators,
    };
  },
  [SET_ALL_AGENTS](state, { payload }) {
    return {
      ...state,
      allAgents: payload,
    };
  },

  [SET_AGENTS_CUSTOM_FIELDS](state, { payload }) {
    return {
      ...state,
      agentsCustomFieldsColumns: payload.columns,
    };
  },
  [AGENT_UPDATE_STATUS](state, { payload }) {
    return {
      ...state,
      agentUpdateStatus: payload.status,
    };
  },
  [SET_AGENTS_SAVED_VIEWS](state, { payload }) {
    return {
      ...state,
      agentsSavedViews: payload.agentsSavedViews,
    };
  },
  [UPDATE_AGENT_DATA_BOOKMARK_ID](state, { bookmarkId }) {
    return {
      ...state,
      collectionInfo: {
        ...state.collectionInfo,
        BookmarkID: bookmarkId,
      },
    };
  },

  [UPDATE_EXPORT_DATA_VIEW_NAME](state, { view }) {
    return {
      ...state,
      agentDataViewRow: {
        ...state.agentDataViewRow,
        Name: view.Name,
        Value: view.Name,
        ViewID: view.ViewID,
      },
    };
  },
  [SET_EXPORT_AGENT_DATA_VIEW_NAME](state, { params }) {
    return {
      ...state,
      agentDataViewRow: {
        ...state.agentDataViewRow,
        Name: params.name,
        Value: params.name,
        ViewID: params.ViewID,
      },
    };
  },

  [DELETE_AGENTS_SAVED_VIEW](state, { viewId }) {
    return {
      ...state,
      agentsSavedViews: state.agentsSavedViews.filter((savedView) => {
        return savedView.ViewID !== viewId;
      }),
    };
  },

  [DELETE_AGENT_JOBS_VIEW](state, { viewId }) {
    return {
      ...state,
      agentJobsSavedViews: state.agentJobsSavedViews.filter((savedView) => {
        return savedView.ViewID !== viewId;
      }),
    };
  },

  [DELETE_AGENTS_DATA_VIEW_ROW](state, { viewId }) {
    return {
      ...state,
      agentDataSavedViews: state.agentDataSavedViews.filter((savedView) => {
        return savedView.ViewID !== viewId;
      }),
    };
  },

  [DELETE_AGENTS_HISTORY_VIEW](state, { viewId }) {
    return {
      ...state,
      agentHistorySavedViews: state.agentHistorySavedViews.filter(
        (savedView) => {
          return savedView.ViewID !== viewId;
        }
      ),
    };
  },

  [SET_BOOKMARK_LIST](state, payload) {
    return {
      ...state,
      collectionInfo: {
        ...state.collectionInfo,
        TrackHistory: payload.trackHistory,
      },
    };
  },
  [UPDATE_BOOKMARK_ON_RUNNING](state) {
    return {
      ...state,
      collectionInfo: {
        ...state.collectionInfo,
        BookmarkID: -1,
      },
    };
  },

  [SET_AGENT_DATA](state, { payload }) {
    return {
      ...state,
      agentDataColumns: payload.columns,
      collectionInfo: payload.collectionInfo,
      agentDataViewRow: payload.agentDataViewRow,
      agentDataCollectionState: payload.collectionState,
      agentDataViewFields: payload.viewFields.map((viewField) => {
        return {
          name: viewField.Name,
          value: viewField.Name,
          fieldId: viewField.FieldID,
        };
      }),
    };
  },

  [SET_AGENT_JOBS](state, { payload }) {
    return {
      ...state,
      agentJobsColumns: payload.columns,
      agentJobsTrackHistory: payload.trackHistory,
      agentJobsCollectionState: payload.collectionState,
      agentJobsViewFields: payload.viewFields.map((viewField) => {
        return {
          name: viewField.Name,
          value: viewField.Name,
          fieldId: viewField.FieldID,
        };
      }),
      agentJobsViewRow: payload.viewRow,
    };
  },
  [UPDATE_AGENTS_VIEW_ROW](state, action) {
    return {
      ...state,
      viewRow: {
        ...state.viewRow,
        ViewID: action.viewId,
      },
    };
  },

  [UPDATE_AGENTS_DATA_VIEW_ROW](state, { viewId }) {
    return {
      ...state,
      agentDataViewRow: {
        ...state.agentDataViewRow,
        ViewID: viewId,
      },
    };
  },
  [UPDATE_AGENTS_JOBS_VIEW_ROW](state, { viewId, name }) {
    return {
      ...state,
      agentJobsViewRow: {
        ...state.agentJobsViewRow,
        ViewID: viewId,
        name,
        value: name,
      },
    };
  },

  [UPDATE_AGENTS_HISTORY_VIEW_ROW](state, { viewId }) {
    return {
      ...state,
      agentHistoryViewRow: {
        ...state.agentHistoryViewRow,
        ViewID: viewId,
      },
    };
  },

  [SET_AGENTS_JOBS_SAVED_VIEWS](state, { payload }) {
    return {
      ...state,
      agentJobsSavedViews: payload.savedViews,
    };
  },
  [SET_AGENT_JOB_STATUS](state, { payload }) {
    return {
      ...state,
      agentJobStatus: payload.agentJobStatus,
    };
  },
  [AGENTS_UPDATEVIEWTABLE](state, { newViews }) {
    return {
      ...state,
      agentsSavedViews: newViews,
    };
  },
  [AGENT_JOBS_UPDATEVIEWTABLE](state, { newViews }) {
    return {
      ...state,
      agentJobsSavedViews: newViews,
    };
  },
  [AGENT_HISTORY_UPDATEVIEWTABLE](state, { newViews }) {
    return {
      ...state,
      agentHistorySavedViews: newViews,
    };
  },

  [SET_AGENT_INFO](state, { payload }) {
    return {
      ...state,
      agentInfo: payload.agentInfo,
      currentAgentTrackHistory: payload.trackHistory,
      CollectionItem: payload.CollectionItem,
    };
  },
  [SET_AGENT_DATA_SAVED_VIEWS](state, { payload }) {
    return {
      ...state,
      agentDataSavedViews: payload.agentDataSavedViews,
    };
  },
  [AGENT_DATA_UPDATEVIEWTABLE](state, { newViews }) {
    return {
      ...state,
      agentDataSavedViews: newViews,
    };
  },
  [SET_AGENTS_HISTORY_SAVED_VIEWS](state, { payload }) {
    return {
      ...state,
      agentHistorySavedViews: payload.savedViews,
    };
  },
  [SET_AGENTS_INSIGHTS_SAVED_VIEWS](state, { payload }) {
    return {
      ...state,
      agentInsightsSavedViews: payload.savedViews,
    };
  },

  [UPDATE_COLLECTION_STATE](state, { payload }) {
    return {
      ...state,
      collectionState: payload.collectionState,
    };
  },
  [SET_AGENT_FIELDS](state, { payload }) {
    return {
      ...state,
      agentFieldsColumns: payload.columns,
    };
  },
  [SET_AGENT_HISTORY](state, { payload }) {
    return {
      ...state,
      agentHistoryColumns: payload.columns,
      agentHistoryCollectionState: payload.collectionState,
      agentHistoryViewFields: payload.viewFields.map((viewField) => {
        return {
          name: viewField.Name,
          value: viewField.Name,
          fieldId: viewField.FieldID,
        };
      }),
      agentHistoryViewRow: payload.viewRow,
    };
  },
  [SET_AGENT_HISTORY_ITEMS](state, { menuItems }) {
    return {
      ...state,
      agentHistoryItems: menuItems,
    };
  },
  [SET_ITEM_HISTORY](state, { agentItemHistory }) {
    return {
      ...state,
      agentItemHistory: agentItemHistory,
    };
  },
  [SET_AGENT_HISTORY_BOOKMARKS](state, { payload }) {
    return {
      ...state,
      agentHistoryBookMarks: payload.agentHistoryBookMarks,
    };
  },
  [SET_AGENT_INSIGHTS](state, { payload }) {
    return {
      ...state,
      agentInsightsColumns: payload.columns,
      agentInsightsCollectionState: payload.collectionState,
      agentInsightsViewFields: payload.viewFields.map((viewField) => {
        return {
          name: viewField.Name,
          value: viewField.Name,
          fieldId: viewField.FieldID,
        };
      }),
      agentInsightsViewRow: payload.viewRow,
    };
  },
  [CLEANUP_AGENT_DATA](state) {
    return {
      ...state,
      agentDataSavedViews: [],
      agentDataViewRow: {},
    };
  },
  [UPDATE_AGENTS_STATE_SEARCH](state, { searchFilterValues }) {
    return {
      ...state,
      collectionState: {
        ...state.collectionState,
        SearchText: searchFilterValues.searchText,
        SearchField:
          searchFilterValues.searchField !== "View Fields"
            ? searchFilterValues.searchField
            : "AllViewFields",
      },
    };
  },
  [UPDATE_AGENTS_DATA_STATE_SEARCH](state, { searchFilterValues }) {
    return {
      ...state,
      agentDataCollectionState: {
        ...state.agentDataCollectionState,
        SearchText: searchFilterValues.searchText,
        SearchField:
          searchFilterValues.searchField !== "View Fields"
            ? searchFilterValues.searchField
            : "AllViewFields",
      },
    };
  },
  [UPDATE_AGENTS_JOBS_STATE_SEARCH](state, { searchFilterValues }) {
    return {
      ...state,
      agentJobsCollectionState: {
        ...state.agentJobsCollectionState,
        SearchText: searchFilterValues.searchText,
        SearchField:
          searchFilterValues.searchField !== "View Fields"
            ? searchFilterValues.searchField
            : "AllViewFields",
      },
    };
  },
  [UPDATE_AGENTS_HISTORY_STATE_SEARCH](state, { searchFilterValues }) {
    return {
      ...state,
      agentHistoryCollectionState: {
        ...state.agentHistoryCollectionState,
        SearchText: searchFilterValues.searchText,
        SearchField:
          searchFilterValues.searchField !== "View Fields"
            ? searchFilterValues.searchField
            : "AllViewFields",
      },
    };
  },
  [UPDATE_AGENTS_INSIGHTS_STATE_SEARCH](state, { searchFilterValues }) {
    return {
      ...state,
      agentInsightsCollectionState: {
        ...state.agentInsightsCollectionState,
        SearchText: searchFilterValues.searchText,
        SearchField:
          searchFilterValues.searchField !== "View Fields"
            ? searchFilterValues.searchField
            : "AllViewFields",
      },
    };
  },
  [SET_VIEW_NAME](state, payload) {
    return {
      ...state,
      viewRow: {
        ...state.viewRow,
        ViewID: payload.params.ViewID,
        Name:
          payload.params.name && payload.params.name === "SearchResults"
            ? payload.params.ViewName
            : payload.params.Name,
      },
    };
  },

  [SET_AGENTS_FIELDS](state, payload) {
    return {
      ...state,
      agentListFieldsColumns: payload.columns,
    };
  },
  [SET_AGENT_BUILTBYPREVIOUS](state, { payload }) {
    return {
      ...state,
      agentBuildByPreviousVersion: payload.columns,
    };
  },
  [SET_AGENT_JOBSACTIVE](state, { payload }) {
    return {
      ...state,
      agentHasJobsActive: payload.status,
    };
  },
  [SET_AGENT_JOB_STATISTICS](state, action) {
    return {
      ...state,
      agentJobStatistics: action.data,
    };
  },
  [SET_STATUS_COUNT](state, { payload }) {
    return {
      ...state,
      agentStatusCount: payload.StatusCounts,
    };
  },
});
