import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import variables from "../../../assets/sass/_variables.scss";

const useStyles = makeStyles({
  root: {
    height: (props) => (props.height ? props.height : 10),
    backgroundColor: (props) =>
      props.backgroundColor
        ? props.backgroundColor
        : variables["color-deepcerulean-20"],
  },
  bar: {
    borderRadius: (props) => (props.borderRadius ? props.borderRadius : 2),
    backgroundColor: (props) =>
      props.barColor ? props.barColor : variables["color-deepcerulean"],
  },
});

export default function ProgressBar(props) {
  const classes = useStyles(props);
  const { progressEnd } = props;

  const [progress, setProgress] = useState(0);
  const [buffer, setBuffer] = useState(10);

  const progressRef = useRef(() => {});
  useEffect(() => {
    let mounted = true;
    progressRef.current = () => {
      if (mounted && progress <= progressEnd) {
        const newProgress = progress + 10;
        setProgress(newProgress);
        if (mounted && newProgress <= progressEnd) {
          setBuffer(progress + 10);
        }
      }
    };

    return () => (mounted = false);
  });

  useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <LinearProgress
      classes={{
        root: classes.root,
        bar: classes.bar,
      }}
      variant="determinate"
      value={progress}
      valueBuffer={buffer}
    />
  );
}

ProgressBar.propTypes = {
  /** loading bar upper limit proportion [0-100] */
  progressEnd: PropTypes.number,
  /** height of the bar */
  height: PropTypes.number,
  /** background color of the bar */
  backgroundColor: PropTypes.string,
  /** border radius of the bar */
  borderRadius: PropTypes.number,
  /** fill color of the bar */
  barColor: PropTypes.string,
};

ProgressBar.defaultProps = {
  progressEnd: 100,
};
