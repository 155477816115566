import {
  GET_ACCOUNT_JOBS,
  SET_ACCOUNT_JOBS,
  GET_ACCOUNT_JOB_DETAILS,
  SET_ACCOUNT_JOB_DETAILS,
  SYNC_JOB,
  SET_ACCOUNT_JOB_LIST,
  GET_ACCOUNT_JOB_LIST,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import { takeLatest, put, call, cancel, select } from "redux-saga/effects";
import axios from "axios";
import { GROUP_COLLECTIONS } from "../../constants/collections";
import { COMMANDS } from "../../constants/commands";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";

const Ext = window["Ext"];

function prepareModel(data, store) {
  const model = store.config.model;

  model.setFields(data);

  return model;
}

function prepareGridColumns(data) {
  return data.ViewFields.map((field) => ({
    dataIndex: field.Name,
    text: `<span title='${field.Name}'>${field.Name}</span>`,
    locked: field.IsSticky,
    hidden: field.IsHidden,
    width: field.ColumnWidth,
    cell: {
      height: 50,
    },
  }));
}

function* createStore(storeId, data, filteredData = {}) {
  const store = Ext.getStore(storeId);
  if (store) {
    const model = prepareModel(data, store);

    const { SearchText, SearchField } = data.CollectionState;

    store.removeAll(true);
    store.setModel(model);

    const params = {
      Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
      CollectionID: CONSTANTS.COLLECTIONS.GROUP.JOBS,
      ViewID: data.ViewRow.ViewID,
      AgentID: data.AgentID,
      SearchText,
      SearchField,
      ...filteredData,
    };
    store.baseParams = params;

    store.getProxy().extraParams = store.baseParams;
    store.loadPage(1, {
      params,
    });
  }

  const columns = prepareGridColumns(data);
  yield put({
    type: SET_ACCOUNT_JOBS,
    payload: {
      columns,
      sortedBy: data.ViewSorting,
      viewRow: data.ViewRow,
      collectionState: data.CollectionState,
      viewFields: data.ViewFields,
      refreshed: data.Collection.Refreshed,
    },
  });
}

function* fetchAccountJobs({ type: action, storeId, formParams = {} }) {
  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETCOMPLETE,
    CollectionID: CONSTANTS.COLLECTIONS.GROUP.JOBS,
    ...formParams,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    yield call(createStore, storeId, data);

    const columns = prepareGridColumns(data);
    yield put({
      type: SET_ACCOUNT_JOBS,
      payload: {
        columns,
        sortedBy: data.ViewSorting,
        viewRow: data.ViewRow,
        collectionState: data.CollectionState,
        viewFields: data.ViewFields,
        refreshed: data.Collection.Refreshed,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

const reducer = (accumulator, value) => {
  const groupIndex = accumulator.findIndex(
    (item) => item.Group === value.Group
  );

  if (groupIndex !== -1) {
    accumulator[groupIndex].items.push(value);
  } else {
    accumulator.push({
      Group: value.Group,
      items: [value],
    });
  }
  return accumulator;
};

function* fetchJobDetails({ type: action, formParams = {} }) {
  const params = {
    Command: CONSTANTS.COMMANDS.JOB_GETDETAILS,
    ...formParams,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.JOB_GETDETAILS,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    const reducedData = data.Rows.reduce(reducer, []);
    let transformedData = JSON.parse(
      JSON.stringify(reducedData).replace("DetailName", "Name")
    );

    yield put({
      type: SET_ACCOUNT_JOB_DETAILS,
      payload: {
        data: transformedData,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* syncJob({ jobIds, storeId, syncCb }) {
  const reduxState = yield select();
  try {
    syncCb(true);
    yield axios.post(
      CONSTANTS.ROUTES.JOB_SYNC,
      new URLSearchParams({
        AccountKey: reduxState.account.accountInfo.Account.AccountKey,
        JobIDs: jobIds,
        Command: COMMANDS.JOB_SYNC,
      })
    );

    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams({
        CollectionID: CONSTANTS.COLLECTIONS.GROUP.JOBS,
        FolderID: "",
        AccountKey: reduxState.account.accountInfo.Account.AccountKey,
        ViewID: "",
        OverrideViewName: "Active Jobs",
        HideSystemViewNames: "Agent Jobs",
        Command: COMMANDS.COLLECTION_GETCOMPLETE,
      })
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    const { PageItemCount, PageIndex } = data.CollectionState;

    window["Ext"].getStore(storeId).load({
      params: {
        AddSummaryRow: "",
        Command: COMMANDS.VIEW_GETITEMS,
        CollectionID: CONSTANTS.COLLECTIONS.GROUP.JOBS,
        OverrideViewName: "Active Jobs",
        FolderID: "",
        ViewID: data.ViewRow.ViewID,
        AccountKey: reduxState.account.accountInfo.Account.AccountKey,
        AgentID: 0,
        BookmarkID: 0,
        JobID: -1,
        ColumnsToAlwaysInclude:
          "Status,BatchID,SequenceID,JobType,ScheduleItemID",
        SelectedIDs: jobIds,
        page: PageIndex,
        start: 0,
        limit: PageItemCount,
      },
      callback: () => syncCb(false),
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
    syncJob(false);
  }
}

function* fetchJobList({ type: action, AccountKey }) {
  const reduxState = yield select();
  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETVIEWS,
    AccountKey: reduxState.account.accountInfo.Account.AccountKey,
    CollectionId: GROUP_COLLECTIONS.JOBS,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETVIEWS,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    yield put({
      type: SET_ACCOUNT_JOB_LIST,
      payload: {
        data,
        AccountKey,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

export default function* accountJobsWatcher() {
  yield takeLatest(GET_ACCOUNT_JOBS, fetchAccountJobs);
  yield takeLatest(GET_ACCOUNT_JOB_DETAILS, fetchJobDetails);
  yield takeLatest(SYNC_JOB, syncJob);
  yield takeLatest(GET_ACCOUNT_JOB_LIST, fetchJobList);
}
