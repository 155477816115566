const Ext = window["Ext"];

export const arrayStoreConfig = (config) => {
  return Ext.create("Ext.data.ArrayStore", {
    ...config,
  });
};

export default function (config, action) {
  let headers = {};
  if (process.env.REACT_APP_ENV === "mock") {
    headers.action = action;
  }

  const { proxy, ...otherParams } = config;

  let storeConfig = {
    pageSize: 10,
    proxy: {
      type: "ajax",
      actionMethods: {
        create: "POST",
        read: "POST",
        update: "POST",
        destroy: "POST",
      },
      reader: {
        type: "json",
        rootProperty: "Rows",
        totalProperty: "TotalCount",
      },
      timeout: 600000,
      withCredentials: true,
      ...proxy,
    },
    remoteSort: false,
    ...otherParams,
  };

  return Ext.create("Ext.data.Store", storeConfig);
}
