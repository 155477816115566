import {
  SET_IMPORT_DEPARTMENT_EXISTING,
  SET_NEW_DEPARTMENT_USERS,
  SET_DEPARTMENTS_LIST,
  SET_DEPARTMENT_BILLING_DATA,
  SET_DEPARTMENT_BILLING_INVOICE,
  SET_DEPARTMENT_BILLING_DOWNLOAD_INVOICE_LIST,
  SET_DEPARTMENT_STORAGE_DATA,
  SET_DEPARTMENT_STORAGE_SAVED_VIEWS,
  SET_DEPARTMENT_UTILIZATION_SAVED_VIEWS,
  SET_DEPARTMENT_STORAGE_DETAILS,
  SET_DEPARTMENT_INSIGHTS,
  SET_DEPARTMENT_JOBS,
  SET_DEPARTMENT_JOB_DETAILS,
  SET_DEPARTMENT_JOB_LIST,
  SET_DEPARTMENT_USERS,
  SET_DEPARTMENT_UTILIZATION_PERIOD,
  SET_DEPARTMENT_UTILIZATION_USAGE,
  SET_DEPARTMENTS_SAVED_VIEWS,
  SET_DEPARTMENT_USERS_SAVED_VIEWS,
  UPDATE_DEPARTMENT_INSIGHTS_STATE_SEARCH,
  UPDATE_DEPARTMENTS_STATE_SEARCH,
  UPDATE_DEPARTMENT_JOBS_STATE_SEARCH,
  UPDATE_DEPARTMENT_USERS_STATE_SEARCH,
  DELETE_DEPARTMENTS_VIEW,
  UPDATE_DEPARTMENTS_VIEW_ROW,
  DELETE_DEPARTMENT_USERS_VIEW,
  UPDATE_DEPARTMENT_USERS_VIEW_ROW,
  SET_DEPARTMENT_STORAGE_DOWNLOAD_COLLECTION_STORAGE_SUMMARY,
  SET_DEPARTMENT_STORAGE_DOWNLOAD_COLLECTION_STORAGE,
  DEPARTMENTS_UPDATEVIEWTABLE,
  DEPARTMENTS_USERS_UPDATEVIEWTABLE,
  SET_DEPARTMENT_INSIGHTS_SAVED_VIEWS,
} from "../types";

import createReducer from "../createReducer";

const initialState = {
  sortedBy: [],
  savedViews: [],
  viewRow: {},
  departmentBillingInvoiceHtml: null,
  departmentBillingDownloadInvoiceList: null,
  departmentBillingColumns: [],
  departmentsListColumns: [],
  departmentStorageColumns: [],
  departmentStorageSavedViews: null,
  departmentStorageViewRow: {},
  departmentStorageUsageColumns: [],
  departmentStorageDownloadCollectionStorageSummary: null,
  departmentStorageDownloadCollectionStorage: null,
  departmentJobsSortedBy: [],
  departmentJobDetails: null,
  departmentJobList: null,
  departmentUsersColumns: [],
  departmentUsersSortedBy: [],
  departmentUsersSavedViews: [],
  departmentsUsersViewRow: {},
  departmentUtilizationPeriodColumns: [],
  departmentUtilizationSavedViews: [],
  departmentUtilizationViewRow: {},
  departmentUtilizationUsageColumns: [],
  departmentsCollectionState: {},
  departmentsViewFields: null,
  departmentJobsCollectionState: {},
  departmentJobsViewFields: null,
  departmentUsersCollectionState: {},
  departmentUsersViewFields: null,
  departmentBillingCollectionState: {},
  departmentInsightsColumns: [],
  departmentInsightsCollectionState: {},
  departmentInsightsViewFields: null,
  departmentInsightsViewRow: {},
  departmentInsightsSavedViews: [],
  departmentImportGridColumns: [],
  newDepartmentUsersColumns: [],
  uniqueUserAccounts: [],
};

export const departments = createReducer(initialState, {
  [SET_IMPORT_DEPARTMENT_EXISTING](state, { payload }) {
    return {
      ...state,
      departmentImportGridColumns: payload.columns,
    };
  },
  [SET_NEW_DEPARTMENT_USERS](state, { payload }) {
    return {
      ...state,
      newDepartmentUsersColumns: payload.columns,
      uniqueUserAccounts: payload.uniqueUserAccounts,
    };
  },
  [SET_DEPARTMENTS_LIST](state, { payload }) {
    return {
      ...state,
      departmentsListColumns: payload.columns,
      sortedBy: payload.sortedBy,
      viewRow: payload.viewRow,
      departmentsCollectionState: payload.collectionState,
      departmentsViewFields: payload.viewFields.map((viewField) => {
        return {
          name: viewField.Name,
          value: viewField.Name,
          fieldId: viewField.FieldID,
        };
      }),
    };
  },

  [SET_DEPARTMENT_BILLING_DATA](state, { payload }) {
    return {
      ...state,
      departmentBillingColumns: payload.columns,
      departmentBillingCollectionState: payload.collectionState,
    };
  },

  [SET_DEPARTMENT_BILLING_INVOICE](state, { payload }) {
    return {
      ...state,
      departmentBillingInvoiceHtml: payload.html,
    };
  },

  [SET_DEPARTMENT_BILLING_DOWNLOAD_INVOICE_LIST](state, { payload }) {
    return {
      ...state,
      departmentBillingDownloadInvoiceList: payload.data,
    };
  },

  [SET_DEPARTMENT_STORAGE_DOWNLOAD_COLLECTION_STORAGE_SUMMARY](
    state,
    { payload }
  ) {
    return {
      ...state,
      departmentStorageDownloadCollectionStorageSummary: payload.data,
    };
  },

  [SET_DEPARTMENT_STORAGE_DOWNLOAD_COLLECTION_STORAGE](state, { payload }) {
    return {
      ...state,
      departmentStorageDownloadCollectionStorage: payload.data,
    };
  },

  [SET_DEPARTMENT_STORAGE_DATA](state, { payload }) {
    return {
      ...state,
      departmentStorageColumns: payload.columns,
      departmentStorageViewRow: payload.viewRow,
    };
  },

  [SET_DEPARTMENT_STORAGE_DETAILS](state, { payload }) {
    return {
      ...state,
      departmentStorageUsageColumns: payload.columns,
    };
  },

  [SET_DEPARTMENT_STORAGE_SAVED_VIEWS](state, { payload }) {
    return {
      ...state,
      departmentStorageSavedViews: payload.savedViews,
    };
  },

  [SET_DEPARTMENT_INSIGHTS](state, { payload }) {
    return {
      ...state,
      departmentInsightsColumns: payload.columns,
      departmentInsightsCollectionState: payload.collectionState,
      departmentInsightsViewRow: payload.viewRow,
      departmentInsightsViewFields: payload.viewFields.map((viewField) => {
        return {
          name: viewField.Name,
          value: viewField.Name,
          fieldId: viewField.FieldID,
        };
      }),
    };
  },
  [SET_DEPARTMENT_INSIGHTS_SAVED_VIEWS](state, { payload }) {
    return {
      ...state,
      departmentInsightsSavedViews: payload.departmentInsightsSavedViews,
    };
  },

  [SET_DEPARTMENT_JOBS](state, { payload }) {
    return {
      ...state,
      departmentJobsColumns: payload.columns,
      departmentJobsSortedBy: payload.sortedBy,
      departmentJobsCollectionState: payload.collectionState,
      departmentJobsViewFields: payload.viewFields.map((viewField) => {
        return {
          name: viewField.Name,
          value: viewField.Name,
          fieldId: viewField.FieldID,
        };
      }),
    };
  },

  [SET_DEPARTMENT_JOB_DETAILS](state, { payload }) {
    return {
      ...state,
      departmentJobDetails: payload.data,
    };
  },
  [SET_DEPARTMENT_JOB_LIST](state, { payload }) {
    return {
      ...state,
      departmentJobList: payload.data,
    };
  },

  [SET_DEPARTMENT_USERS](state, { payload }) {
    return {
      ...state,
      departmentUsersColumns: payload.columns,
      departmentUsersSortedBy: payload.sortedBy,
      departmentsUsersViewRow: payload.viewRow,
      departmentUsersCollectionState: payload.collectionState,
      departmentUsersViewFields: payload.viewFields.map((viewField) => {
        return {
          name: viewField.Name,
          value: viewField.Name,
          fieldId: viewField.FieldID,
        };
      }),
    };
  },

  [SET_DEPARTMENT_UTILIZATION_PERIOD](state, { payload }) {
    return {
      ...state,
      departmentUtilizationPeriodColumns: payload.columns,
      departmentUtilizationViewRow: payload.viewRow,
    };
  },

  [SET_DEPARTMENT_UTILIZATION_SAVED_VIEWS](state, { payload }) {
    return {
      ...state,
      departmentUtilizationSavedViews: payload.savedViews,
    };
  },

  [DELETE_DEPARTMENTS_VIEW](state, { viewId }) {
    return {
      ...state,
      savedViews: state.savedViews.filter((savedView) => {
        return savedView.ViewID !== viewId;
      }),
    };
  },

  [UPDATE_DEPARTMENTS_VIEW_ROW](state, { viewId }) {
    return {
      ...state,
      viewRow: {
        ...state.viewRow,
        ViewID: viewId,
      },
    };
  },
  [DELETE_DEPARTMENT_USERS_VIEW](state, { viewId }) {
    return {
      ...state,
      departmentUsersSavedViews: state.departmentUsersSavedViews.filter(
        (savedView) => {
          return savedView.ViewID !== viewId;
        }
      ),
    };
  },

  [UPDATE_DEPARTMENT_USERS_VIEW_ROW](state, { viewId }) {
    return {
      ...state,
      departmentsUsersViewRow: {
        ...state.departmentsUsersViewRow,
        ViewID: viewId,
      },
    };
  },

  [SET_DEPARTMENT_UTILIZATION_USAGE](state, { payload }) {
    return {
      ...state,
      departmentUtilizationUsageColumns: payload.columns,
    };
  },

  [SET_DEPARTMENTS_SAVED_VIEWS](state, { payload }) {
    return {
      ...state,
      savedViews: payload.savedViews,
    };
  },
  [SET_DEPARTMENT_USERS_SAVED_VIEWS](state, { payload }) {
    return {
      ...state,
      departmentUsersSavedViews: payload.savedViews,
    };
  },
  [UPDATE_DEPARTMENTS_STATE_SEARCH](state, { searchFilterValues }) {
    return {
      ...state,
      departmentsCollectionState: {
        ...state.departmentsCollectionState,
        SearchText: searchFilterValues.searchText,
        SearchField:
          searchFilterValues.searchField !== "View Fields"
            ? searchFilterValues.searchField
            : "AllViewFields",
      },
    };
  },

  [UPDATE_DEPARTMENT_JOBS_STATE_SEARCH](state, { searchFilterValues }) {
    return {
      ...state,
      departmentJobsCollectionState: {
        ...state.departmentJobsCollectionState,
        SearchText: searchFilterValues.searchText,
        SearchField:
          searchFilterValues.searchField !== "View Fields"
            ? searchFilterValues.searchField
            : "AllViewFields",
      },
    };
  },
  [UPDATE_DEPARTMENT_USERS_STATE_SEARCH](state, { searchFilterValues }) {
    return {
      ...state,
      departmentUsersCollectionState: {
        ...state.departmentUsersCollectionState,
        SearchText: searchFilterValues.searchText,
        SearchField:
          searchFilterValues.searchField !== "View Fields"
            ? searchFilterValues.searchField
            : "AllViewFields",
      },
    };
  },
  [UPDATE_DEPARTMENT_INSIGHTS_STATE_SEARCH](state, { searchFilterValues }) {
    return {
      ...state,
      departmentInightsCollectionState: {
        ...state.departmentInightsCollectionState,
        SearchText: searchFilterValues.searchText,
        SearchField:
          searchFilterValues.searchField !== "View Fields"
            ? searchFilterValues.searchField
            : "AllViewFields",
      },
    };
  },
  [DEPARTMENTS_UPDATEVIEWTABLE](state, { newViews }) {
    return {
      ...state,
      savedViews: newViews,
    };
  },

  [DEPARTMENTS_USERS_UPDATEVIEWTABLE](state, { newViews }) {
    return {
      ...state,
      departmentUsersSavedViews: newViews,
    };
  },
});
