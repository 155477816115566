import React from "react";
import { Link } from "@reach/router";
import IconGroup from "../../IconGroup";
import LabelWithIcon from "../../LabelWithIcon";
import ButtonLink from "../../ButtonLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import variables from "../../../assets/sass/_variables.scss";
import { JOB_STATUS } from "../../../constants/jobs";
import { CONFIG_ICONS } from "../../../constants/config";

const _generateIcon = ({ TYPE, ICON, DESCRIPTION, COLOR, visible }) => {
  if (TYPE === "fa") {
    return (
      <FontAwesomeIcon
        size="lg"
        icon={ICON}
        title={visible !== false ? DESCRIPTION : ""}
        opacity={visible === false ? 0 : 1}
        key={`grid-render-icon-${DESCRIPTION || TYPE}`}
        color={COLOR || variables["color-spacecadet"]}
      />
    );
  }

  return (
    <ICON
      key={`grid-render-icon-${DESCRIPTION || TYPE}`}
      title={visible !== false ? DESCRIPTION : ""}
      opacity={visible === false ? 0 : 1}
      width="1em"
      fill={COLOR || variables["color-spacecadet"]}
    />
  );
};

export const renderConfigCell = (mapper, value, record, accountFeatures) => {
  const data = mapper(value, record);

  let historyIconIndex = data.findIndex(
    (element) => element.DESCRIPTION === CONFIG_ICONS.TRACKHISTORY.DESCRIPTION
  );

  if (data.length === 0) return null;
  if (
    typeof accountFeatures === "string" &&
    !accountFeatures.includes("ItemHistory")
  )
    data[historyIconIndex].visible = false;

  let icons = data.map(_generateIcon);

  return <IconGroup icons={icons} />;
};

export const renderNameCell = (mapper, value, record) => {
  return <Link to={`/agents/${record.id}`}>{value}</Link>;
};

export const renderSystemRatePlanNameCell = (
  mapper,
  value,
  record,
  nameCellCallback
) => {
  return (
    <ButtonLink
      label={value}
      onClick={() => {
        if (typeof nameCellCallback === "function") nameCellCallback(record);
      }}
    />
  );
};

export const renderDomainLookupNameCell = (value) => {
  return (
    <a
      rel="noopener noreferrer"
      target="_blank"
      href={`https://www.alexa.com/siteinfo/${value}`}
    >
      {value}
    </a>
  );
};

export const renderFieldNameCell = (mapper, value, record, callback) => (
  <ButtonLink
    label={record.data.Name}
    onClick={() => {
      if (typeof callback === "function") callback(record);
    }}
  />
);

export const renderFieldConfigCell = (mapper, value, record) => {
  const data = mapper(value, record);
  const icons = data.map(_generateIcon);
  return <IconGroup icons={icons} />;
};

export const renderFieldDescriptionCell = (mapper, value, record) =>
  mapper(value, record);

export const renderAgentGroupAgentNameCell = (mapper, value, record) => {
  const agentGroupId = record.get("AgentGroup-Raw");
  return (
    <Link
      to={`/agents/${record.id}/data`}
      state={{
        agentGroupId: true,
        previousLink: `/agentgroups/${agentGroupId}/agents`,
      }}
    >
      {value}
    </Link>
  );
};

export const renderCookieNameCell = (mapper, value, record) => {
  return <Link to={`/cookies/${record.id}`}>{value}</Link>;
};

export const renderLastRunResultCell = (mapper, value) => {
  if (value) {
    const { icon, label } = mapper(value);

    const IconComponent = _generateIcon(icon);

    return <LabelWithIcon icon={IconComponent} label={label} />;
  }
  return null;
};

export const renderNextRunTimeCell = (mapper, value) => {
  return value !== "" ? value : "Unscheduled";
};

export const renderCollectionNameCell = (mapper, value, record, callback) => {
  const data = mapper(value, record);
  const icon = _generateIcon(data.type);

  return (
    <ButtonLink
      label={data.name}
      startIcon={icon}
      onClick={() => {
        if (typeof callback === "function") callback(record, data);
      }}
    />
  );
};

export const renderSequencesNameCell = (mapper, value, record) => {
  return <Link to={`/sequences/${record.id}`}>{value}</Link>;
};

export const renderFolderNameCell = (mapper, value, record) => {
  return (
    <Link
      to={`/agents/${record.id}/data`}
      state={{ folderId: true, previousLink: `/folders/${record.id}/agents` }}
    >
      {value}
    </Link>
  );
};

export const renderFolderCollectionNameCell = (mapper, value, record) => {
  const collectionType = record.data.CollectionType.toLowerCase();
  let link = (
    <Link
      to={`/collections/${collectionType}/${record.id}/data/`}
      state={{
        folderId: true,
        previousLink: `/folders/${record.id}/collections`,
      }}
    >
      {value}
    </Link>
  );

  if (collectionType === "agent") {
    link = (
      <Link
        to={`/agents/${record.id}/data`}
        state={{
          folderId: true,
          previousLink: `/folders/${record.id}/collections`,
        }}
      >
        {value}
      </Link>
    );
  }

  return link;
};

export const renderSequenceStepCell = (mapper, value, record, callback) => {
  const data = mapper(value, record);
  const icon = _generateIcon(data);

  return (
    <ButtonLink
      label={value}
      startIcon={icon}
      onClick={() => {
        if (typeof callback === "function") callback(record);
      }}
    />
  );
};

export const renderSequenceStepStatusCell = (mapper, value, record) => {
  const data = mapper(value, record);
  if (!data) return;

  if (record.get("StepType") === "AgentRun") {
    return (
      <Link to={`/agents/${record.get("StepItemID")}`}>{data.STATUS}</Link>
    );
  }
  if (record.data.Status === "Ready" && data.label) {
    data.label.color = variables["color-spacecadet"];
  }

  if (!data.ICON || data.ICON === "") {
    return (
      <LabelWithIcon
        label={{
          value: data.STATUS,
          color: data.label?.color || variables["color-spacecadet"],
        }}
      />
    );
  } else if (data.TYPE === "fa") {
    const spin = data.STATUS === JOB_STATUS.RUNNING.STATUS;

    return (
      <LabelWithIcon
        icon={
          <FontAwesomeIcon
            size="lg"
            icon={data.ICON}
            color={data.COLOR || variables["color-spacecadet"]}
            spin={spin}
          />
        }
        label={{
          value: data.STATUS,
          color: data.label?.color || variables["color-spacecadet"],
        }}
      />
    );
  }

  const Icon = data.ICON;
  return (
    <LabelWithIcon
      height={variables["size-lg"]}
      icon={<Icon fill={data.COLOR || variables["color-spacecadet"]} />}
      label={{
        value: data.STATUS,
        color: variables["color-spacecadet"],
      }}
    />
  );
};

export const renderSequenceStepProgressCell = (mapper, value, record) => {
  return mapper(value, record);
};

export const renderDepartmentUsersStatusCell = (mapper, value, record) => {
  const data = mapper(value, record);

  const icons = data.map(_generateIcon);

  return <IconGroup icons={icons} />;
};

export const renderDepartmentUsersAgentsCell = (mapper, value, record) => {
  const data = mapper(value, record);

  const icons = data.map(_generateIcon);

  return <IconGroup icons={icons} />;
};

export const renderDepartmentUsersCollectionsCell = (mapper, value, record) => {
  const data = mapper(value, record);

  const icons = data.map(_generateIcon);

  return <IconGroup icons={icons} />;
};

export const renderDepartmentUsersSequencesCell = (mapper, value, record) => {
  const data = mapper(value, record);

  const icons = data.map(_generateIcon);

  return <IconGroup icons={icons} />;
};

export const renderDepartmentUsersAccountCell = (mapper, value, record) => {
  const data = mapper(value, record);

  const icons = data.map(_generateIcon);

  return <IconGroup icons={icons} />;
};

export const renderDepartmentUsersNameFullCell = (mapper, value, record) =>
  mapper(value, record);

export const renderDepartmentUsersLastSeenCell = (mapper, value, record) =>
  mapper(value, record);

export const renderDepartmentUsersInvitedLastCell = (mapper, value, record) =>
  mapper(value, record);

export const renderCookieDetailsNameCell = (
  mapper,
  value,
  record,
  callback
) => {
  return (
    <ButtonLink
      label={value}
      onClick={() => {
        if (typeof callback === "function") callback(record);
      }}
    />
  );
};

export const renderAgentStatusCell = (mapper, value, record) => {
  const data = mapper(value, record);

  if (record.data.Status === "Ready") {
    data.label.color = variables["color-spacecadet"];
  }
  const icon = data.icon ? <FontAwesomeIcon size="lg" {...data.icon} /> : null;
  return (
    <LabelWithIcon
      icon={icon}
      label={data.label}
      variant={data.variant || "caption"}
    />
  );
};
