import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = ({ spacing }) => ({
  root: {
    margin: spacing(1),
    padding: spacing(3),
    maxWidth: 400,
  },
  form: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-evenly",
  },
});

export default withStyles(styles)(
  class ErrorBoundary extends Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
    static getDerivedStateFromError(e) {
      return { hasError: true };
    }
    componentDidCatch(error, info) {
      console.error("ErrorBoundary caught an error", error, info);
    }
    render() {
      if (this.state.hasError) {
        try {
          if (process.env.NODE_ENV !== "development") {
            window.location = "/public/500";
          } else {
            return (
              <h1>
                An error occurred. Please check developer tools...{" "}
                <a href="/">Click here</a> to go home.
              </h1>
            );
          }
        } catch (ex) {
          console.log(ex);
        }
      } else {
        return this.props.children;
      }
    }
  }
);
