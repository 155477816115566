import {
  GET_DEPARTMENT_STORAGE_DETAILS,
  SET_DEPARTMENT_STORAGE_DETAILS,
  GET_DEPARTMENT_STORAGE_SAVED_VIEWS,
  SET_DEPARTMENT_STORAGE_SAVED_VIEWS,
  GET_DEPARTMENT_STORAGE_DATA,
  SET_DEPARTMENT_STORAGE_DATA,
  GET_DEPARTMENT_STORAGE_DOWNLOAD_COLLECTION_STORAGE_SUMMARY,
  GET_DEPARTMENT_STORAGE_DOWNLOAD_COLLECTION_STORAGE,
  SET_DEPARTMENT_STORAGE_DOWNLOAD_COLLECTION_STORAGE_SUMMARY,
  SET_DEPARTMENT_STORAGE_DOWNLOAD_COLLECTION_STORAGE,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import { takeLatest, put, call, select, cancel } from "redux-saga/effects";
import axios from "axios";
import fileDownload from "../../utils/js-file-download/fileDownload";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";

const Ext = window["Ext"];

function prepareGridDetailsColumns() {
  return [
    {
      header: "CollectionID",
      text: `<span title='CollectionID'>CollectionID</span>`,
      sortable: true,
      searchable: true,
      hideable: false,
      type: "int",
      dataIndex: "CollectionID",
      width: 107,
      //renderer: this.agentNameRenderer,
    },
    {
      header: "Name",
      text: `<span title='Name'>Name</span>`,
      sortable: true,
      searchable: true,
      hideable: false,
      dataIndex: "Name",
      width: 330,
      //renderer: this.nameRenderer,
    },
    {
      header: "Total (MB)",
      text: `<span title='Total (MB)'>Total (MB)</span>`,
      sortDir: "ASC",
      sortType: "asInt",
      sortable: true,
      type: "float",
      searchable: true,
      hideable: false,
      align: "right",
      dataIndex: "Total",
      width: 110,
      renderer: Ext.util.Format.numberRenderer("0,000.00"),
    },
    {
      header: "Database (MB)",
      text: `<span title='Database (MB)'>Database (MB)</span>`,
      sortDir: "ASC",
      sortType: "asInt",
      sortable: true,
      type: "float",
      searchable: true,
      hideable: false,
      align: "right",
      dataIndex: "Database",
      width: 144,
      renderer: Ext.util.Format.numberRenderer("0,000.00"),
    },
    {
      header: "AgentDefinitions (MB)",
      text: `<span title='AgentDefinitions (MB)'>AgentDefinitions (MB)</span>`,
      sortDir: "ASC",
      sortType: "asInt",
      sortable: true,
      type: "float",
      searchable: true,
      hideable: false,
      align: "right",
      dataIndex: "AgentDefinitions",
      width: 191,
      renderer: Ext.util.Format.numberRenderer("0,000.00"),
    },
    {
      header: "Files (MB)",
      text: `<span title='Files (MB)'>Files (MB)</span>`,
      sortDir: "ASC",
      sortType: "asInt",
      sortable: true,
      type: "float",
      searchable: true,
      hideable: false,
      align: "right",
      dataIndex: "Files",
      width: 115,
      renderer: Ext.util.Format.numberRenderer("0,000.00"),
    },
    {
      header: "Packages (MB)",
      text: `<span title='Packages (MB)'>Packages (MB)</span>`,
      sortDir: "ASC",
      sortType: "asInt",
      sortable: true,
      type: "float",
      searchable: true,
      hideable: false,
      align: "right",
      dataIndex: "FilePackages",
      width: 141,
      renderer: Ext.util.Format.numberRenderer("0,000.00"),
    },
  ];
}

function prepareGridColumns(data) {
  return data.ViewFields.map((field) => {
    let fieldText = field.HeaderText ? field.HeaderText : field.Name;
    let fieldIndex =
      field.HeaderText === "Size (GB)" || field.HeaderText === "Day"
        ? field.HeaderText
        : field.Name;
    return {
      dataIndex: fieldIndex,
      text: `<span title='${fieldText}'>${fieldText}</span>`,
      locked: field.IsSticky,
      hidden: field.IsHidden,
      width: field.ColumnWidth,
      cell: {
        height: 46,
        encodeHtml: true,
      },
    };
  });
}

function* createDetailsStore(storeId, params) {
  const {
    Command,
    overrideViewName,
    MaxDay,
    CollectionID,
    AccountKey,
  } = params;
  const store = Ext.getStore(storeId);
  if (store) {
    const model = Ext.create("StorageDetailsModel");
    store.removeAll(true);
    store.setModel(model);

    store.load({
      params: {
        AccountKey,
        CollectionID,
        MaxDay,
        overrideViewName,
        Command,
      },
    });
  }

  const columns = prepareGridDetailsColumns();
  yield put({
    type: SET_DEPARTMENT_STORAGE_DETAILS,
    payload: {
      columns,
    },
  });
}

function* fetchDepartmentStorageDetails({ storeId, formParams = {} }) {
  const params = {
    Command: CONSTANTS.COMMANDS.STORAGE_GETUSAGE,
    CollectionID: CONSTANTS.COLLECTIONS.GROUP.STORAGE_DETAILS,
    overrideViewName: "Month",
    ...formParams,
  };

  try {
    yield call(createDetailsStore, storeId, params);

    const columns = prepareGridDetailsColumns();
    yield put({
      type: SET_DEPARTMENT_STORAGE_DETAILS,
      payload: {
        columns,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function prepareModel(data, store) {
  const model = store.config.model;

  model.setFields(data);

  return model;
}

function* createStore(storeId, data, extraParams = {}) {
  const store = Ext.getStore(storeId);

  if (store) {
    const model = prepareModel(data, store);

    const {
      SearchText,
      SearchField,
      PageItemCount,
      PageIndex,
    } = data.CollectionState;

    store.removeAll(true);
    store.setModel(model);
    store.setPageSize(PageItemCount);
    store.clearFilter(true);

    store.loadPage(PageIndex, {
      params: {
        Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
        CollectionID: CONSTANTS.COLLECTIONS.GROUP.STORAGE_SUMMARY,
        ViewID: data.ViewRow.ViewID,
        SearchText,
        SearchField,
        ...extraParams,
      },
    });
  }

  const columns = prepareGridColumns(data);

  yield put({
    type: SET_DEPARTMENT_STORAGE_DATA,
    payload: {
      columns,
      sortedBy: data.ViewSorting,
      viewRow: data.ViewRow,
      collectionState: data.CollectionState,
      viewFields: data.ViewFields,
      refreshed: data.Collection.Refreshed,
    },
  });
}

function* fetchDepartmentStorageData({
  type: action,
  storeId,
  formParams = {},
}) {
  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETCOMPLETE,
    CollectionID: CONSTANTS.COLLECTIONS.GROUP.STORAGE_SUMMARY,
    OverrideViewName: "Month",
    HideSystemViewNames: "All Segments",
    ...formParams,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    const extraParams = {
      OverrideViewName: "Month",
      HideSystemViewNames: "All Segments",
      ColumnsToAlwaysInclude: "Refreshed,MaxDay",
    };

    yield call(createStore, storeId, data, extraParams);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getSavedViews({ type: action }) {
  const params = {
    AccountKey: "",
    CollectionID: CONSTANTS.COLLECTIONS.GROUP.STORAGE_SUMMARY,
    Command: CONSTANTS.COMMANDS.COLLECTION_GETVIEWS,
  };

  try {
    const result = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETVIEWS,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );
    if (result.data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        result.data.JsonResult.Result,
        result.data.JsonResult.ErrorDescription,
        result.data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    const savedViews = [];

    result.data.ViewTable.forEach((view) => {
      let savedView = {};
      for (let key in view) {
        if (CONSTANTS.RESPONSE_CONTEXT.SAVED_VIEWS_CONTEXT.includes(key)) {
          savedView[key] = view[key];
        }
      }
      savedViews.push(savedView);
    });

    yield put({
      type: SET_DEPARTMENT_STORAGE_SAVED_VIEWS,
      payload: {
        savedViews,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* fetchStorageDownloadCollectionStorageSummary({
  type: action,
  formParams,
  fileFormat,
}) {
  const reduxState = yield select();

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETVIEWS,
      new URLSearchParams({
        Command: CONSTANTS.COMMANDS.STORAGE_DOWNLOADCOLLECTIONSTORAGESUMMARY,
        AccountKey: reduxState.account.accountInfo.Account.AccountKey,
        CameFromPublisher: false,
      })
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    const departmentDownloadStorage = yield axios.post(
      CONSTANTS.ROUTES.STORAGE_DOWNLOADCOLLECTIONSTORAGESUMMARY,
      new URLSearchParams(formParams),
      {
        headers: {
          action,
        },
      }
    );
    const exportResponse = yield axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/export`,
      params: {
        BaseFileName: departmentDownloadStorage.data.TempFile,
        UserFileName: `${departmentDownloadStorage.data.FileName}.${fileFormat}`,
        Method: "SpecificFile",
      },
      responseType: "blob",
    });

    const filename = `${departmentDownloadStorage.data.FileName}.${fileFormat}`;
    fileDownload(exportResponse.data, filename);

    yield put({
      type: SET_DEPARTMENT_STORAGE_DOWNLOAD_COLLECTION_STORAGE_SUMMARY,
      payload: {
        data,
        departmentDownloadStorage,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* fetchStorageDownloadCollectionStorage({
  type: action,
  formParams,
  fileFormat,
}) {
  const reduxState = yield select();

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETVIEWS,
      new URLSearchParams({
        Command: CONSTANTS.COMMANDS.STORAGE_DOWNLOADCOLLECTIONSTORAGE,
        AccountKey: reduxState.account.accountInfo.Account.AccountKey,
        CameFromPublisher: false,
      })
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    const departmentDownloadStorage = yield axios.post(
      CONSTANTS.ROUTES.STORAGE_DOWNLOADCOLLECTIONSTORAGE,
      new URLSearchParams(formParams),
      {
        headers: {
          action,
        },
      }
    );
    const exportResponse = yield axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/export`,
      params: {
        BaseFileName: departmentDownloadStorage.data.TempFile,
        UserFileName: `${departmentDownloadStorage.data.FileName}.${fileFormat}`,
        Method: "SpecificFile",
      },
      responseType: "blob",
    });

    const filename = `${departmentDownloadStorage.data.FileName}.${fileFormat}`;
    fileDownload(exportResponse.data, filename);

    yield put({
      type: SET_DEPARTMENT_STORAGE_DOWNLOAD_COLLECTION_STORAGE,
      payload: {
        data,
        departmentDownloadStorage,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}
export default function* departmentStorageWatcher() {
  yield takeLatest(GET_DEPARTMENT_STORAGE_DATA, fetchDepartmentStorageData);
  yield takeLatest(
    GET_DEPARTMENT_STORAGE_DETAILS,
    fetchDepartmentStorageDetails
  );
  yield takeLatest(GET_DEPARTMENT_STORAGE_SAVED_VIEWS, getSavedViews);
  yield takeLatest(
    GET_DEPARTMENT_STORAGE_DOWNLOAD_COLLECTION_STORAGE_SUMMARY,
    fetchStorageDownloadCollectionStorageSummary
  );
  yield takeLatest(
    GET_DEPARTMENT_STORAGE_DOWNLOAD_COLLECTION_STORAGE,
    fetchStorageDownloadCollectionStorage
  );
}
