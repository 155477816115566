const Ext = window["Ext"];

if (!Ext.ClassManager.isCreated("DynamicDataModel")) {
  Ext.define("DynamicDataModel", {
    extend: "Ext.data.Model",
    idProperty: "ItemID",
    setFields: function (data) {
      const modelFields = data.ViewFields.map((field) => ({
        id: field.ViewFieldID,
        name: field.Name,
      }));

      if (data.ViewFieldStates.length === 0) {
        this.fields = modelFields;
      } else {
        const filteredModelFields = modelFields.filter((item) =>
          data.ViewFieldStates.find((i) => i.ViewFieldID === item.id)
        );
        this.fields = filteredModelFields;
      }
    },
  });
}
