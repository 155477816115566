export const STORE_NAMES = {
  STORE_WITH_ITEM_ID: "StoreWithItemID",
  STORE_WITH_EDITABLE_DATA: "StoreWithEditableData",
  STORE_WITH_FIELD_ID: "StoreWithFieldID",
  STORE_WITH_INVOICE_NUMBER: "StoreWithInvoiceNumber",
  STORE_WITH_FOLDER_ID: "StoreWithFolderID",
  STORE_FOR_USAGE: "StoreForUsage",
  STORE_FOR_SOURCE_FIELDS: "StoreForSourceFields",
  STORE_FOR_RSS_FIELD: "StoreForRssField",
  STORE_WITH_SEQUENCE_ERROR_CODES: "StoreWithSequenceErrorCodes",
  STORE_WITH_PACKAGES_FILES: "StoreWithPackagesFiles",
  STORE_WITH_COLUMN_HADING_FILE: "StoreWithGetColumnHeadingFile",
  STORE_WITH_FILE_COLUMN: "StoreWithGetFileColumn",
  STORE_WITH_IMPORT_COLUMN: "StoreWithGetImportColumn",
  STORE_WITH_VIEW_NOTIFICATIONS: "StoreWithViewNotificationsStore",
  STORE_WITH_EXISTING_USER_LIST: "StoreWithExistingUser",
};

export const STORE_IDS = {
  AGENT_LIST: "AgentListStore",
  AGENT_LIST_CUSTOM_FIELDS: "AgentListCustomFieldsStore",
  EXPORT_IMAGES_AND_FILES: "ExportImagesAndFiles",

  ASSIGN_AGENTGROUP_AGENTS: "AssignAgentGroupAgent",
  ASSIGN_FOLDER_AGENTS: "AssignFolderAgent",

  AGENT_DATA: "AgentDataStore",
  AGENT_JOBS: "AgentJobStore",
  AGENT_FIELDS: "AgentFieldsStore",
  AGENT_CUSTOM_FIELDS: "AgentCustomFieldsStore",
  AGENT_HISTORY: "AgentHistoryStore",
  AGENT_INSIGHTS: "AgentInsightsStore",

  AGENT_GROUPS_AGENTS: "AgentGroupsAgentsStore",
  AGENT_GROUPS_DATA: "AgentGroupsDataStore",
  AGENT_GROUPS_FIELDS: "AgentGroupsFieldsStore",

  ACCOUNT_BILLING: "AccountBillingStore",
  ACCOUNT_INSIGHTS: "AccountInsightsStore",
  ACCOUNT_JOBS: "AccountJobsStore",
  ACCOUNT_STORAGE: "AccountStorage",
  ACCOUNT_STORAGE_DETAILS: "AccountStorageDetails",
  ACCOUNT_USERS: "AccountUsersStore",
  ACCOUNT_UTILIZATION_PERIOD: "AccountUtilizationPeriodStore",
  ACCOUNT_UTILIZATION_USAGE: "AccountUtilizationUsageStore",
  EXISTING_USERS: "ExistingUsersStore",

  COLLECTION_LIST: "CollectionListStore",
  COLLECTION_LIST_CUSTOM_FIELDS: "CollectionListCustomFieldsStore",
  COLLECTION_STANDARD_DATA: "CollectionStandardDataStore",
  COLLECTION_STANDARD_FIELDS: "CollectionStandardFieldsStore",
  COLLECTION_COMBINED_DATA: "CollectionCombinedDataStore",
  COLLECTION_COMBINED_FIELDS: "CollectionCombinedFieldsStore",
  COLLECTION_COMBINED_EXISTING_FIELDS: "CollectionCombinedExistingFieldsStore",
  COLLECTION_COMBINED_DATA_EXISTING_FIELDS:
    "CollectionCombinedDataExistingFieldsStore",
  COLLECTION_COMBINED_SOURCE_FIELDS: "CollectionCombinedSourceFieldsStore",
  COLLECTION_COMBINED_SOURCE_FIELDS_EDIT:
    "CollectionCombinedSourceFieldsEditStore",
  COLLECTION_COMBINED_SOURCE_COLLECTIONS:
    "CollectionCombinedSourceCollectionsStore",
  COLLECTION_FROM_EXISTING_MODAL: "CollectionFromExistingModal",
  COLLECTION_CUSTOM_FIELDS: "CollectionCustomFields",
  COLLECTIONS_SOURCE_COLLECTIONS: "CollectionsSourceCollectionsStore",
  COMBINED_DATA_SOURCE_FIELDS: "CombinedDataSourceFieldsStore",

  COOKIES_LIST: "CookiesListStore",
  COOKIE_DETAILS: "CookieDetailsStore",

  DEPARTMENT_LIST: "DepartmentListStore",
  DEPARTMENT_NEW_USERS: "DepartmentNewUsersStore",
  DEPARTMENT_IMPORT: "DepartmentImport",
  DEPARTMENT_BILLING: "DepartmentBillingStore",
  DEPARTMENT_INSIGHTS: "DepartmentInsightsStore",
  DEPARTMENT_JOBS: "DepartmentJobsStore",
  DEPARTMENT_STORAGE: "DepartmentStorage",
  DEPARTMENT_STORAGE_DETAILS: "DepartmentStorageDetails",
  DEPARTMENT_USERS: "DepartmentUsersStore",
  DEPARTMENT_UTILIZATION_PERIOD: "DepartmentUtilizationPeriodStore",
  DEPARTMENT_UTILIZATION_USAGE: "DepartmentUtilizationUsageStore",
  DEPARTMENT_IMPORT_MODAL: "DepartmentImportModalStore",
  DEPARTMENT_SUMMARY: "DepartmentSummaryStore",

  FOLDERS_LIST: "FoldersListStore",
  FOLDERS_LIST_MODAL: "FoldersListModalStore",
  FOLDERS_AGENTS_LIST: "FolderAgentsListStore",
  FOLDERS_COLLECTIONS_LIST: "FolderCollectionsListStore",

  SEQUENCES_LIST: "SequencesListStore",
  SEQUENCES_STEPS: "SequencesStepsStore",
  SEQUENCES_STEPS_CLEAR_COLLECTION: "SequencesStepsClearCollectionStore",
  RSS_FIELD: "RssFieldStore",
  SEQUENCE_ERROR_CODES: "SequenceErrorCodesStore",
  STORE_WITH_VIEW_NOTIFICATIONS: "ViewNotificationsStore",
  GET_COLUMN_HEADING_FILE: "GetColumnHeadingFileStore",
  GET_FILE_COLUMN: "GetFileColumnStore",
  GET_FILE_IMPORTCOLUMN: "GetImportColumnStore",
  SEQUENCE_CLEAR_COLLECTION: "SEQUENCE_CLEAR_COLLECTION",
  SEQUENCES_TO_SELECT: "SequencesToSelectStore",
  SYSTEM_RATEPLANS: "SystemRatePlans",
  SYSTEM_SETTINGS: "SystemSettings",

  ACCOUNT_BILL_ELEMENTS: "AccountBillElements",
  SYSTEM_INSIGHTS: "SystemInsightsStore",
};

export const STORE_FILTER_ID = {
  USED_FIELDS: "usedFieldsFilter",
  SAME_COLLECTION_FIELDS: "sameCollectionFieldsFilter",
  FILTER_FIELDS_BY_FIELD_NAME: "FieldsByFieldNameFilter",
};
