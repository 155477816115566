import {
  GET_AGENT_FIELDS,
  SET_AGENT_FIELDS,
  AGENT_INFO_LOADED,
} from "../types";
import { takeLatest, put, select, take } from "redux-saga/effects";
import { CONSTANTS } from "../../constants/constants";
import {
  mapFieldConfigCell,
  mapFieldDescriptionCell,
} from "../../Components/Grid/SenchaLockedGrid/mappers";
import {
  renderFieldNameCell,
  renderFieldConfigCell,
  renderFieldDescriptionCell,
} from "../../Components/Grid/SenchaLockedGrid/renderers";

const Ext = window["Ext"];

function getGridColumns(nameCellCallback) {
  return [
    {
      dataIndex: "FieldID",
      text: "<span title='FieldID'>FieldID</span>",
      locked: true,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 75,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "Name",
      text: "<span title='Field Name'>Field Name</span>",
      locked: true,
      menuDisabled: true,
      resizable: true,
      searchable: true,
      width: 220,
      cell: {
        height: 50,
        encodeHtml: true,
        xtype: "reactcell",
      },
      renderer: (value, record) =>
        renderFieldNameCell(null, value, record, nameCellCallback),
    },
    {
      dataIndex: "Format",
      text: "<span title='Format'>Format</span>",
      locked: false,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 125,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "Config",
      text: "<span title='Config'>Config</span>",
      locked: false,
      menuDisabled: true,
      resizable: true,
      searchable: true,
      width: 116,
      cell: {
        height: 50,
        encodeHtml: true,
        xtype: "reactcell",
      },
      renderer: (value, record) =>
        renderFieldConfigCell(mapFieldConfigCell, value, record),
    },
    {
      dataIndex: "Description",
      text: "<span title='Description'>Description</span>",
      locked: false,
      menuDisabled: true,
      resizable: true,
      searchable: true,
      width: 387,
      cell: {
        height: 50,
        encodeHtml: true,
        xtype: "reactcell",
      },
      renderer: (value, record) =>
        renderFieldDescriptionCell(mapFieldDescriptionCell, value, record),
    },
  ];
}

function* fetchAgentFields({
  storeId,
  nameCellCallback,
  agentId,
  formParams = {},
}) {
  const agentInfo = yield select((state) => state.agents.agentInfo);
  if (!agentInfo.ItemID || agentInfo.ItemID !== Number(agentId)) {
    yield take(AGENT_INFO_LOADED);
  }

  const collectionId = yield select(
    (state) => state.agents.agentInfo.CollectionID
  );

  const store = Ext.getStore(storeId);
  if (store) {
    const params = {
      Command: CONSTANTS.COMMANDS.COLLECTION_GETFIELDS,
      CollectionID: collectionId,
      IncludeAgentFields: true,
      ExcludeSystemFields: true,
      ExcludeSeparators: true,
      ...formParams,
    };
    store.baseParams = {
      ...params,
    };

    store.getProxy().extraParams = store.baseParams;

    store.loadPage(1, { params });
  }

  const columns = getGridColumns(nameCellCallback);

  yield put({
    type: SET_AGENT_FIELDS,
    payload: {
      columns,
    },
  });
}

export default function* agentFieldsWatcher() {
  yield takeLatest(GET_AGENT_FIELDS, fetchAgentFields);
}
