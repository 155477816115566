import {
  GET_AGENT_JOBS,
  SET_AGENT_JOBS,
  GET_JOB_STATISTICS,
  GET_JOB_VIEWS,
  SET_AGENTS_JOBS_SAVED_VIEWS,
  SET_AGENT_JOB_STATISTICS,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import { takeLatest, put, call, cancel } from "redux-saga/effects";
import axios from "axios";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";

const Ext = window["Ext"];

function prepareModel(data, store) {
  const model = store.config.model;

  model.setFields(data);

  return model;
}

function prepareGridColumns(data) {
  return data.ViewFields.map((field) => ({
    ...field,
    dataIndex: field.Name,
    text: `<span title='${field.Name}'>${field.Name}</span>`,
    locked: field.IsSticky,
    hidden: field.IsHidden,
    width: field.ColumnWidth,
    cell: {
      height: 50,
    },
  }));
}

function* createStore(storeId, data, agentId) {
  const store = Ext.getStore(storeId);

  if (store) {
    const model = prepareModel(data, store);

    store.removeAll(true);
    store.setModel(model);

    const params = {
      AddSummaryRow: "",
      CollectionID: data.Collection.CollectionID,
      ViewID: data.CollectionState.ViewID,
      OverrideViewName: "Agent Jobs",
      FolderID: "",
      AccountKey: "",
      AgentID: data.AgentID,
      BookmarkID: data.Collection.BookmarkID,
      JobID: -1,
      ColumnsToAlwaysInclude:
        "Status,Command,Settings,ErrorCode,ErrorDetail,BookmarkID,SequenceID,Description,Name",
      ContextRestrictionFieldNames: " ScheduleItemID,JobType",
      ContextRestrictionFieldValues: `${agentId},WebPageHarvest`,
      SearchText: data.CollectionState.SearchText,
      SearchField: data.CollectionState.SearchField,
      page: data.CollectionState.PageIndex,
      limit: data.CollectionState.PageItemCount,
      Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
    };

    store.baseParams = {
      ...params,
    };

    store.getProxy().extraParams = store.baseParams;
    store.loadPage(
      data.CollectionState.PageIndex === 0 ? 1 : data.CollectionState.PageIndex,
      {
        params,
      }
    );
  }

  const columns = prepareGridColumns(data);

  yield put({
    type: SET_AGENT_JOBS,
    payload: {
      columns,
      trackHistory: data.Collection.TrackHistory,
      collectionState: data.CollectionState,
      viewRow: data.ViewRow,
      sortedBy: data.ViewSorting,
      viewFields: data.ViewFields,
    },
  });
}

function* fetchAgentJobs({ type: action, storeId, agentId, ViewID }) {
  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETCOMPLETE,
    CollectionID: CONSTANTS.COLLECTIONS.COMMON.JOBS,
    FolderID: "",
    AccountKey: "",
    OverrideViewName: "Agent Jobs",
    HideSystemViewNames: "Active Jobs",
    ViewID: ViewID || "",
  };
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    yield call(createStore, storeId, data, agentId);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* fetchJobStatistics(action) {
  const params = {
    Command: CONSTANTS.COMMANDS.AGENT_GETJOBSTATISTICS,
    ...action.formParams,
  };

  const reducer = (accumulator, value) => {
    const groupIndex = accumulator.findIndex(
      (item) => item.Group === value.Group
    );

    if (groupIndex !== -1) {
      accumulator[groupIndex].items.push(value);
    } else {
      accumulator.push({
        Group: value.Group,
        items: [value],
      });
    }
    return accumulator;
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.AGENT_GETJOBSTATISTICS,
      new URLSearchParams(params)
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    const reducedData = data.Rows.reduce(reducer, []);

    yield put({
      type: SET_AGENT_JOB_STATISTICS,
      data: reducedData,
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getSavedViews({ type: action, formParams = {} }) {
  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETVIEWS,
    CollectionID: CONSTANTS.COLLECTIONS.COMMON.JOBS,
    AccountKey: null,
    ...formParams,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETVIEWS,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    yield put({
      type: SET_AGENTS_JOBS_SAVED_VIEWS,
      payload: {
        savedViews: data.ViewTable || [],
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

export default function* agentJobsWatcher() {
  yield takeLatest(GET_AGENT_JOBS, fetchAgentJobs);
  yield takeLatest(GET_JOB_STATISTICS, fetchJobStatistics);
  yield takeLatest(GET_JOB_VIEWS, getSavedViews);
}
