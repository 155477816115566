import {
  CREATE_FOLDER_COLLECTION_FROM_SCRATCH,
  GET_FOLDER_COLLECTIONS,
  SET_FOLDER_COLLECTIONS,
  UPDATE_RECENT_ITEMS,
  DELETE_FOLDER_COLLECTION,
  CLEAR_FOLDER_COLLECTION,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import { takeLatest, put, select, call, cancel } from "redux-saga/effects";
import axios from "axios";
import { renderNameCell } from "../../Components/Grid/SenchaLockedGrid/renderers";
import { COMMANDS } from "../../constants/commands";
import { SYSTEM_COLLECTIONS } from "../../constants/collections";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";
const FOLDERS_COLLECTION_LIST = CONSTANTS.STORES.IDS.FOLDERS_COLLECTIONS_LIST;

const Ext = window["Ext"];

function prepareModel(data, store) {
  const model = store.config.model;

  model.setFields(data);

  return model;
}

function prepareGridColumns(data) {
  const renderers = {
    Name: {
      renderer: renderNameCell,
    },
  };
  return data.ViewFields.map((field) => {
    if (renderers[field.Name]) {
      return {
        dataIndex: field.Name,
        text: `<span title='${field.Name}'>${field.Name}</span>`,
        locked: field.IsSticky,
        hidden: field.IsHidden,
        width: field.ColumnWidth,
        cell: {
          height: 50,
          encodeHtml: true,
          xtype: "reactcell",
        },
        renderer: (value, record) =>
          renderers[field.Name].renderer(
            renderers[field.Name].mapper,
            value,
            record
          ),
      };
    }
    return {
      dataIndex: field.Name,
      text: `<span title='${field.Name}'>${field.Name}</span>`,
      locked: field.IsSticky,
      hidden: field.IsHidden,
      width: field.ColumnWidth,
      cell: {
        height: 50,
      },
    };
  });
}

function* createStore(storeId, data) {
  const store = Ext.getStore(storeId);
  if (store) {
    const model = prepareModel(data, store);

    const {
      CollectionID,
      FolderID,
      SearchText,
      SearchField,
      PageIndex,
      PageItemCount,
    } = data.CollectionState;

    store.removeAll(true);
    store.setModel(model);

    store.setPageSize(PageItemCount);

    store.loadPage(PageIndex, {
      params: {
        CollectionID,
        ViewID: data.ViewRow.ViewID,
        FolderID,
        SearchText,
        SearchField,
        AgentID: data.AgentID,
        Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
      },
    });
  }

  const columns = prepareGridColumns(data);

  yield put({
    type: SET_FOLDER_COLLECTIONS,
    payload: {
      columns,
      sortedBy: data.ViewSorting,
      viewRow: data.ViewRow,
      collectionState: data.CollectionState,
      viewFields: data.ViewFields,
    },
  });
}

function* fetchFolderCollectionsData({
  type: action,
  storeId,
  formParams = {},
}) {
  const foldersStore = yield select((state) => state.folders);
  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETCOMPLETE,
    CollectionID: CONSTANTS.COLLECTIONS.SYSTEM.COLLECTIONS,
    ViewID: foldersStore.folderCollectionsViewRow?.viewId || "",
    ...formParams,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    yield call(createStore, storeId, data);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}
function* deleteCollection({ selectedItems, FolderID, ViewID }) {
  try {
    const id = selectedItems.map((item) => item.id).join(",");
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_DELETE,
      new URLSearchParams({
        CollectionID: id,
        Command: CONSTANTS.COMMANDS.COLLECTION_DELETE,
      })
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    window["Ext"].getStore(FOLDERS_COLLECTION_LIST).load({
      params: {
        Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
        CollectionID: CONSTANTS.COLLECTIONS.SYSTEM.COLLECTIONS,
        ViewID: ViewID,
        FolderID: FolderID,
        BookmarkID: 0,
        JobID: -1,
        ColumnsToAlwaysInclude:
          "Name,CollectionID,BookmarkID,CollectionType,Publisher",
        AlwaysIncludeCustomFields: true,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}
function* clearCollection({ formParams }) {
  const {
    storeId,
    selectedItems,
    urlSearchParams,
    viewId,
    folderId,
  } = formParams;

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_EMPTY,
      urlSearchParams
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    window["Ext"].getStore(storeId).load({
      params: {
        Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
        CollectionID: CONSTANTS.COLLECTIONS.SYSTEM.COLLECTIONS,
        ViewID: viewId,
        BookmarkID: 0,
        JobID: -1,
        FolderID: folderId,
        ColumnsToAlwaysInclude:
          "Name,CollectionID,BookmarkID,CollectionType,Publisher",
        AlwaysIncludeCustomFields: false,
        SelectedIDs: selectedItems.map((item) => item.id).join(","),
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}
function* createCollection({ params, history, closeModal }) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_CREATE_NEW_FROM_SCRATCH,
      new URLSearchParams(params)
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    const recentItemsResponse = yield axios.post(
      CONSTANTS.ROUTES.USER_ADD_RECENT_ITEM,
      new URLSearchParams({
        CollectionID: SYSTEM_COLLECTIONS.COLLECTIONS,
        CollectionIcon: "collection-standard",
        ItemID: data.CollectionID,
        ItemText: params.Name,
        Command: COMMANDS.USER_ADD_RECENT_ITEMS,
      })
    );

    yield put({
      type: UPDATE_RECENT_ITEMS,
      payload: recentItemsResponse,
    });
    closeModal();
    history(`/collections/standard/${data.CollectionID}/data`);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

export default function* folderCollectionWatcher() {
  yield takeLatest(GET_FOLDER_COLLECTIONS, fetchFolderCollectionsData);
  yield takeLatest(DELETE_FOLDER_COLLECTION, deleteCollection);
  yield takeLatest(CLEAR_FOLDER_COLLECTION, clearCollection);
  yield takeLatest(CREATE_FOLDER_COLLECTION_FROM_SCRATCH, createCollection);
}
