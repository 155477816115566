import {
  GET_ACCOUNT_BILL_ELEMENTS,
  GET_SYSTEM_BILLING_INFO,
  SET_SYSTEM_BILLING_INFO,
  SET_ACCOUNT_BILL_ELEMENTS,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import { takeLatest, put, select, cancel } from "redux-saga/effects";
import axios from "axios";
import { COMMANDS } from "../../constants/commands";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";

function* getSystemBillingInfo() {
  const params = {
    Command: CONSTANTS.COMMANDS.COMMON_GETSYSTEMBILLINGINFO,
  };
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COMMON_GETSYSTEMBILLINGINFO,
      new URLSearchParams(params)
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    const mappedGeolocations = data.Geolocations.map((geolocation) => {
      return {
        ...geolocation,
        name: geolocation.Name,
        value: geolocation.Name,
      };
    });

    yield put({
      type: SET_SYSTEM_BILLING_INFO,
      mappedGeolocations,
      systemBillingInfoData: data,
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

const prepareAccountBillElementsColumns = () => {
  return [
    {
      dataIndex: "Name",
      text: "<span title='Name'>Name</span>",
      locked: true,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 75,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "Description",
      text: "<span title='Description'>Description</span>",
      locked: true,
      menuDisabled: true,
      resizable: true,
      searchable: true,
      width: 220,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "BillElementType",
      text: "<span title='BillElementType'>BillElementType</span>",
      locked: false,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 125,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "PeriodLength",
      text: "<span title='PeriodLength'>PeriodLength</span>",
      locked: false,
      menuDisabled: true,
      resizable: true,
      searchable: true,
      width: 116,
      cell: {
        height: 50,
        encodeHtml: true,
        xtype: "reactcell",
      },
    },
    {
      dataIndex: "UserTierType",
      text: "<span title='UserTierType'>UserTierType</span>",
      locked: false,
      menuDisabled: true,
      resizable: true,
      searchable: true,
      width: 387,
      cell: {
        height: 50,
        encodeHtml: true,
        xtype: "reactcell",
      },
    },
    {
      dataIndex: "UsageLimit",
      text: "<span title='UsageLimit'>UsageLimit</span>",
      locked: true,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 75,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "InitalQuantity",
      text: "<span title='InitalQuantity'>InitalQuantity</span>",
      locked: true,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 75,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "Rates",
      text: "<span title='Rates'>Rates</span>",
      locked: true,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 75,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "Quantities",
      text: "<span title='Quantities'>Quantities</span>",
      locked: true,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 75,
      cell: {
        height: 50,
      },
    },
  ];
};

function* getAccountBillElements({ storeId }) {
  const reduxState = yield select();
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.BILLING_GET_BILL_ELEMENTS_FOR_RATE_PLAN,
      new URLSearchParams({
        RatePlanName: reduxState.account.accountInfo.Account.BillRatePlan,
        Command: COMMANDS.BILLING_GET_BILL_ELEMENTS_FOR_RATE_PLAN,
      })
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    const columns = prepareAccountBillElementsColumns();

    yield put({
      type: SET_ACCOUNT_BILL_ELEMENTS,
      columns,
    });
    const store = window["Ext"].getStore(storeId);
    store.loadData(data.BillElements);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

export default function* standardCollectionFieldsWatcher() {
  yield takeLatest(GET_SYSTEM_BILLING_INFO, getSystemBillingInfo);
  yield takeLatest(GET_ACCOUNT_BILL_ELEMENTS, getAccountBillElements);
}
