import * as types from "../types";
import createReducer from "../createReducer";

const initialState = {
  Amazon: {},
  AzureStorage: {},
  Dropbox: {},
  Email: {},
  Ftp: {},
  Google: {},
  Wrangle: {},
  Config: {},
  publisherList: {},
  eventList: {},
  Info: {},
  ViewStore: {},
  Schedule: {},
};

export const publishing = createReducer(initialState, {
  [types.INITIALIZE_STATE](state, { payload }) {
    return {
      ...state,
      ...payload,
    };
  },
  [types.EMPTY_PUBLISH_STATE](state, { payload }) {
    return {
      ...state,
      Amazon: {},
      AzureStorage: {},
      Dropbox: {},
      Email: {},
      Ftp: {},
      Google: {},
      Wrangle: {},
      Config: {},
      publisherList: {},
      eventList: {},
      Info: {},
      ViewStore: {},
      Schedule: {},
    };
  },

  [types.SET_PUBLISHERLIST](state, { payload }) {
    return {
      ...state,
      publisherList: { ...state.publisherList, ...payload },
    };
  },
  [types.PUBLISH_INFO](state, { payload }) {
    return {
      ...state,
      Info: { ...state.Info, ...payload },
    };
  },
  [types.SET_PUBLISH_VIEW_STORE](state, { payload }) {
    return {
      ...state,
      ViewStore: { ...state.ViewStore, ...payload },
    };
  },
  [types.SET_PUBLISHING_SCHEDULE](state, { payload }) {
    return {
      ...state,
      Schedule: payload,
    };
  },
  [types.SET_EVENT_GETBYACTION](state, { payload }) {
    return {
      ...state,
      eventList: payload,
    };
  },
});
