const Ext = window["Ext"];

if (!Ext.ClassManager.isCreated("DynamicModel")) {
  Ext.define("DynamicModel", {
    extend: "Ext.data.Model",
    idProperty: "ItemID",
    setFields: function (data) {
      const modelFields = data.ViewFields.map((field) => ({
        id: field.ViewFieldID,
        name: field.Name,
      }));

      if (data.ViewFieldStates.length === 0) {
        this.fields = modelFields;
      } else {
        const filteredModelFields = modelFields.filter((item) =>
          data.ViewFieldStates.find((i) => i.ViewFieldID === item.id)
        );
        this.fields = filteredModelFields;
      }
    },
  });
}
if (!Ext.ClassManager.isCreated("FieldsModel")) {
  Ext.define("FieldsModel", {
    extend: "Ext.data.Model",
    idProperty: "FieldID",
    fields: ["FieldID", "Name", "Format", "Config", "Description"],
  });
}
if (!Ext.ClassManager.isCreated("InvoiceListModel")) {
  Ext.define("InvoiceListModel", {
    extend: "Ext.data.Model",
    idProperty: "InvoiceNumber",
    fields: ["InvoiceNumber", "InvoiceDateTime", "InvoiceAmount"],
  });
}
if (!Ext.ClassManager.isCreated("UsageModel")) {
  Ext.define("UsageModel", {
    extend: "Ext.data.Model",
    fields: [
      { name: "Description", type: "string" },
      { name: "TotalPages", type: "int" },
      { name: "WebImageDownload", type: "int" },
      { name: "WebPageDownload", type: "int" },
      { name: "WebPageAnonymous", type: "int" },
      { name: "WebPageCredit", type: "int" },
      { name: "WebPageStandard", type: "int" },
      { name: "WebPagePremium", type: "int" },
      { name: "WebFileDownload", type: "int" },
      { name: "WebNotifyUrl", type: "int" },
      { name: "WebNotifyEmail", type: "int" },
    ],
  });
}
if (!Ext.ClassManager.isCreated("FolderModel")) {
  Ext.define("FolderModel", {
    extend: "Ext.data.Model",
    idProperty: "FolderID",
    fields: [
      { name: "FolderID", type: "string" },
      { name: "Name", type: "string" },
      { name: "Description", type: "string" },
      { name: "SelectedItemCount", type: "int" },
      { name: "IsFavorite", type: "bool" },
      {
        name: "FavoriteCss",
        type: "string",
        mapping: "IsFavorite",
        convert: () => "m-tools-delete-agent",
      },
      {
        name: "FavoriteTip",
        type: "string",
        mapping: "IsFavorite",
        convert: function (v, record) {
          return Number(record.IsFavorite) === 1
            ? "Remove Favorite"
            : "Favorite";
        },
      },
    ],
  });
}
if (!Ext.ClassManager.isCreated("SourceFieldsModel")) {
  Ext.define("SourceFieldsModel", {
    extend: "Ext.data.Model",
    fields: [
      { name: "Name", type: "string" },
      { name: "CollectionCount", type: "auto" },
      { name: "Fields", type: "auto" },
      { name: "CollectionIDs", type: "auto" },
      { name: "CollectionNames", type: "auto" },
      { name: "AvailableCollectionIDs", type: "auto" },
      { name: "SelectedCollectionIDs", type: "auto" },
      { name: "IncludedFieldIDs", type: "auto" },
      { name: "Description", type: "string" },
      { name: "DefaultValue", type: "string" },
      { name: "Format", type: "string" },
      { name: "IsRequired", type: "bool" },
      { name: "IsConfidential", type: "bool" },
    ],
    idProperty: "Name",
  });
}
if (!Ext.ClassManager.isCreated("SequenceErrorCodes")) {
  Ext.define("SequenceErrorCodes", {
    extend: "Ext.data.Model",
    fields: [
      { name: "Name", type: "string" },
      { name: "Description", type: "string" },
    ],
    idProperty: "Name",
  });
}

if (!Ext.ClassManager.isCreated("ViewNotificationsModel")) {
  Ext.define("ViewNotificationsModel", {
    extend: "Ext.data.Model",
    idProperty: "ItemID",
    setFields: function (data) {
      const modelFields = data.ViewFields.map((field) => ({
        id: field.ViewFieldID,
        name: field.Name,
      }));

      if (data.ViewFieldStates.length === 0) {
        this.fields = modelFields;
      } else {
        const filteredModelFields = modelFields.filter((item) =>
          data.ViewFieldStates.find((i) => i.ViewFieldID === item.id)
        );
        this.fields = filteredModelFields;
      }
    },
    fields: [
      { name: "Created", type: "string" },
      { name: "TemplateName", type: "string" },
      { name: "Email", type: "string" },
      { name: "Subject", type: "string" },
      { name: "Body", type: "string" },
    ],
  });
}
