import * as types from "../types";
import createReducer from "../createReducer";

const initialState = {
  sortedBy: [],
  collectionsListColumns: [],
  collectionInfo: {},
  collectionState: {},
  standardCollectionState: {},
  combinedCollectionState: {},
  viewRow: {},
  savedViews: [],
  viewFields: null,
  collection: null,
  combinedCollectionsListColumns: [],
  combinedCollectionsViewRow: {},
  combinedCollectionsSavedViews: [],
  combinedCollectionsViewFields: null,
  combinedCollectionsFieldsListColumns: [],
  combinedCollectionsSourceCollections: {
    ids: [],
    fields: [],
    selectedIds: [],
  },

  combinedDataSourceCollections: {
    ids: [],
    fields: [],
    selectedIds: [],
  },

  sourceCollectionsCombinedGridColumns: [],
  sourceCollectionsCombinedSortedBy: [],
  sourceCollectionsCombinedViewRow: null,
  sourceCollectionsCombinedCollectionState: null,
  sourceCollectionsCombinedViewFields: [],

  combinedCollectionsFieldsCollectionState: {},
  combinedCollectionsSourceFieldsGridColumns: [],
  combinedCollectionsSourceFieldsLoaded: false,
  combinedCollectionsExistingFieldsListColumns: [],
  combinedCollectionsSharedFields: [],
  combinedCollectionsEditModalFields: [],

  standardCollectionsListColumns: [],
  standardCollectionsFieldsListColumns: [],
  standardCollectionsViewRow: {},
  standardCollectionsSavedViews: [],
  standardCollectionsViewFields: null,
  sourceCollectionsCombinedViews: [],

  sourceCollectionsGridColumns: [],
  sourceCollectionsSortedBy: [],
  sourceCollectionsViews: [],
  sourceCollectionsViewRow: null,
  sourceCollectionsCollectionState: null,
  collectionsCustomFieldsColumns: [],
  collectionUpdateStatus: null,
  errorMessage: null,
  mappedItems: null,
  addFromExistingColumns: [],
  collectionFromFileColumns: [],
  collectionFromFileColumnsFc: [],
  collectionFromFileColumnsFieldsGrid: [],
  sourceCollectionsColumn: [],
  createdCollectionDataFromExisting: null,
  combinedDataExistingFieldsListColumns: [],
  allStandardCollections: [],
  allCombinedCollections: [],
};

export const collections = createReducer(initialState, {
  [types.SET_COLLECTIONS](state, { payload }) {
    return {
      ...state,
      collectionsListColumns: payload.columns,
      sortedBy: payload.sortedBy,
      viewRow: payload.viewRow,
      collectionState: payload.collectionState,
      viewFields: payload.viewFields.map((viewField) => {
        return {
          name: viewField.Name,
          value: viewField.Name,
          fieldId: viewField.FieldID,
        };
      }),
      refreshed: payload.refreshed,
    };
  },
  [types.SET_ALL_STANDARD_COLLECTIONS](state, { payload }) {
    return {
      ...state,
      allStandardCollections: payload,
    };
  },
  [types.SET_ALL_COMBINED_COLLECTIONS](state, { payload }) {
    return {
      ...state,
      allCombinedCollections: payload,
    };
  },

  [types.FILTER_COLLECTIONS](state, { filter }) {
    state.collectionsListStore.filter("Name", filter);
    return {
      ...state,
    };
  },
  [types.SET_COMBINED_COLLECTIONS](state, { payload }) {
    return {
      ...state,
      combinedCollectionsListColumns: payload.columns,
      collectionInfo: payload.collectionInfo,
      combinedCollectionState: payload.collectionState,
      combinedCollectionsViewRow: payload.viewRow,
      combinedCollectionsViewFields: payload.viewFields.map((viewField) => {
        return {
          name: viewField.Name,
          value: viewField.Name,
          fieldId: viewField.FieldID,
        };
      }),
    };
  },
  [types.COMBINED_UPDATEVIEWTABLE](state, { newViews }) {
    return {
      ...state,
      combinedCollectionsSavedViews: newViews,
    };
  },
  [types.STANDARD_UPDATEVIEWTABLE](state, { newViews }) {
    return {
      ...state,
      standardCollectionsSavedViews: newViews,
    };
  },

  [types.SET_COMBINED_COLLECTIONS_STATE](state, { payload }) {
    return {
      ...state,
      combinedCollectionsListColumns: payload.columns,
      collectionInfo: payload.collectionInfo,
      combinedCollectionState: payload.collectionState,
      combinedCollectionsViewRow: payload.viewRow,
      combinedCollectionsViewFields: payload.viewFields.map((viewField) => {
        return {
          name: viewField.Name,
          value: viewField.Name,
          fieldId: viewField.FieldID,
        };
      }),
    };
  },

  [types.DELETE_COMBINED_COLLECTIONS_VIEW](state, { viewId }) {
    return {
      ...state,
      combinedCollectionsSavedViews: state.combinedCollectionsSavedViews.filter(
        (savedView) => {
          return savedView.ViewID !== viewId;
        }
      ),
    };
  },

  [types.UPDATE_COMBINED_COLLECTIONS_VIEW_ROW](state, { viewId }) {
    return {
      ...state,
      combinedCollectionsViewRow: {
        ...state.combinedCollectionsViewRow,
        ViewID: viewId,
      },
    };
  },

  [types.DELETE_STANDARD_COLLECTIONS_VIEW](state, { viewId }) {
    return {
      ...state,
      standardCollectionsSavedViews: state.standardCollectionsSavedViews.filter(
        (savedView) => {
          return savedView.ViewID !== viewId;
        }
      ),
    };
  },

  [types.UPDATE_STANDARD_COLLECTIONS_VIEW_ROW](state, { viewId }) {
    return {
      ...state,
      standardCollectionsViewRow: {
        ...state.standardCollectionsViewRow,
        ViewID: viewId,
      },
    };
  },

  [types.UPDATE_COLLECTIONS_VIEW_ROW](state, { viewId }) {
    return {
      ...state,
      viewRow: {
        ...state.viewRow,
        ViewID: viewId,
      },
    };
  },

  [types.SET_COLLECTIONS_VIEW_NAME](state, payload) {
    return {
      ...state,
      viewRow: {
        ...state.viewRow,
        ViewID: payload.params.ViewID,
        Name:
          payload.params.name && payload.params.name === "SearchResults"
            ? payload.params.ViewName
            : payload.params.Name,
      },
    };
  },

  [types.SET_STANDARD_COLLECTION_VIEW_NAME](state, payload) {
    return {
      ...state,
      viewRow: {
        ...state.viewRow,
        ViewID: payload.params.ViewID,
        Name:
          payload.params.name && payload.params.name === "SearchResults"
            ? payload.params.ViewName
            : payload.params.Name,
      },
    };
  },

  [types.SET_COMBINED_COLLECTION_VIEW_NAME](state, payload) {
    return {
      ...state,
      viewRow: {
        ...state.viewRow,
        ViewID: payload.params.ViewID,
        Name:
          payload.params.name && payload.params.name === "SearchResults"
            ? payload.params.ViewName
            : payload.params.Name,
      },
    };
  },

  [types.SET_COLLECTION_VIEW_NAME](state, payload) {
    return {
      ...state,
      viewRow: {
        ...state.viewRow,
        ViewID: payload.params.ViewID,
        Name: payload.params.Name,
      },
    };
  },

  [types.DELETE_COLLECTIONS_VIEW](state, { viewId }) {
    return {
      ...state,
      savedViews: state.savedViews.filter((savedView) => {
        return savedView.ViewID !== viewId;
      }),
    };
  },
  [types.SET_COMBINED_COLLECTIONS_FIELDS](state, { payload }) {
    return {
      ...state,
      combinedCollectionsFieldsListColumns: payload.columns,
      combinedCollectionsFieldsCollectionState: payload.collectionState,
    };
  },
  [types.SET_COMBINED_COLLECTIONS_EXISTING_FIELDS](state, { payload }) {
    return {
      ...state,
      combinedCollectionsExistingFieldsListColumns: payload.columns,
    };
  },
  [types.SET_COMBINED_DATA_EXISTING_FIELDS](state, { payload }) {
    return {
      ...state,
      combinedDataExistingFieldsListColumns: payload.columns,
    };
  },

  [types.SET_COMBINED_COLLECTIONS_SOURCE_COLLECTIONS_INFO](
    state,
    { payload: { ids, fields, selectedIds } }
  ) {
    let newCombinedCollectionsSourceCollections = {
      ...state.combinedCollectionsSourceCollections,
      ids,
      fields,
    };

    if (selectedIds) {
      newCombinedCollectionsSourceCollections.selectedIds = selectedIds;
    }

    return {
      ...state,
      combinedCollectionsSourceCollections: {
        ...newCombinedCollectionsSourceCollections,
      },
    };
  },

  [types.SET_COMBINED_DATA_SOURCE_COLLECTIONS_INFO](
    state,
    { payload: { ids, fields, selectedIds } }
  ) {
    let newCombinedDataSourceCollections = {
      ...state.combinedDataSourceCollections,
      ids,
      fields,
    };

    if (selectedIds) {
      newCombinedDataSourceCollections.selectedIds = selectedIds;
    }

    return {
      ...state,
      combinedDataSourceCollections: {
        ...newCombinedDataSourceCollections,
      },
    };
  },

  [types.UPDATE_COMBINED_COLLECTIONS_SOURCE_COLLECTIONS_INFO](
    state,
    { selectedIds }
  ) {
    return {
      ...state,
      combinedCollectionsSourceCollections: {
        ...state.combinedCollectionsSourceCollections,
        selectedIds,
      },
    };
  },
  [types.RESET_COMBINED_COLLECTIONS_SOURCE_COLLECTIONS_INFO](state) {
    return {
      ...state,
      combinedCollectionsSourceCollections: {
        ids: [],
        fields: [],
        selectedIds: [],
      },
    };
  },
  [types.SET_SOURCE_COLLECTIONS](state, { payload }) {
    return {
      ...state,
      sourceCollectionsGridColumns: payload.columns,
      sourceCollectionsSortedBy: payload.sortedBy,
      sourceCollectionsViewRow: payload.viewRow,
      sourceCollectionsCollectionState: payload.collectionState,
      sourceCollectionsViewFields: payload.viewFields.map((viewField) => {
        return {
          name: viewField.Name,
          value: viewField.Name,
          fieldId: viewField.FieldID,
        };
      }),
    };
  },
  [types.SET_SOURCE_COLLECTIONS_COMBINED](state, { payload }) {
    return {
      ...state,
      sourceCollectionsCombinedGridColumns: payload.columns,
      sourceCollectionsCombinedSortedBy: payload.sortedBy,
      sourceCollectionsCombinedViewRow: payload.viewRow,
      sourceCollectionsCombinedCollectionState: payload.collectionState,
      sourceCollectionsCombinedViewFields: payload.viewFields.map(
        (viewField) => {
          return {
            name: viewField.Name,
            value: viewField.Name,
            fieldId: viewField.FieldID,
          };
        }
      ),
    };
  },
  [types.UPDATE_SOURCE_COLLECTIONS_STATE_SEARCH](
    state,
    { searchFilterValues }
  ) {
    return {
      ...state,
      sourceCollectionsCollectionState: {
        ...state.sourceCollectionsCollectionState,
        SearchText: searchFilterValues.searchText,
        SearchField:
          searchFilterValues.searchField !== "View Fields"
            ? searchFilterValues.searchField
            : "AllViewFields",
      },
    };
  },
  [types.SET_SOURCE_COLLECTIONS_VIEWS](state, { payload }) {
    return {
      ...state,
      sourceCollectionsViews: payload.views.map((viewField) => {
        return {
          ...viewField,
          name: viewField.Name,
          value: viewField.Name,
          fieldId: viewField.FieldID,
        };
      }),
    };
  },
  [types.SET_SOURCE_COLLECTIONS_COMBINED_VIEWS](state, { payload }) {
    return {
      ...state,
      sourceCollectionsCombinedViews: payload.views.map((viewField) => {
        return {
          ...viewField,
          name: viewField.Name,
          value: viewField.Name,
          fieldId: viewField.FieldID,
        };
      }),
    };
  },
  [types.FILTER_SOURCE_COLLECTIONS](state, { filter }) {
    state.sourceCollectionsGridStore.filter("Name", filter);
    return {
      ...state,
    };
  },
  [types.SET_STANDARD_COLLECTIONS](state, { payload }) {
    return {
      ...state,
      standardCollectionsListColumns: payload.columns,
      collectionInfo: payload.collectionInfo,
      standardCollectionState: payload.collectionState,
      standardCollectionsViewRow: payload.viewRow,
      standardCollectionsViewFields: payload.viewFields.map((viewField) => {
        return {
          name: viewField.Name,
          value: viewField.Name,
          fieldId: viewField.FieldID,
        };
      }),
    };
  },
  [types.SET_STANDARD_COLLECTIONS_STATE](state, { payload }) {
    return {
      ...state,
      standardCollectionsListColumns: payload.columns,
      collectionInfo: payload.collectionInfo,
      standardCollectionState: payload.collectionState,
      standardCollectionsViewRow: payload.viewRow,
      standardCollectionsViewFields: payload.viewFields.map((viewField) => {
        return {
          name: viewField.Name,
          value: viewField.Name,
          fieldId: viewField.FieldID,
        };
      }),
    };
  },
  [types.SET_STANDARD_COLLECTIONS_FIELDS](state, { payload }) {
    return {
      ...state,
      standardCollectionsFieldsListColumns: payload.columns,
    };
  },

  [types.SET_COLLECTIONS_SAVED_VIEWS](state, { payload }) {
    return {
      ...state,
      savedViews: payload.savedViews,
    };
  },
  [types.SET_COMBINED_COLLECTIONS_SAVED_VIEWS](state, { payload }) {
    return {
      ...state,
      combinedCollectionsSavedViews: payload.savedViews,
    };
  },
  [types.SET_STANDARD_COLLECTIONS_SAVED_VIEWS](state, { payload }) {
    return {
      ...state,
      standardCollectionsSavedViews: payload.savedViews,
    };
  },
  [types.CLEAR_STANDARD_COLLECTIONS_VIEWID](state) {
    return {
      ...state,
      standardCollectionsViewRow: {},
    };
  },
  [types.CLEAR_COMBINED_COLLECTIONS_VIEWID](state) {
    return {
      ...state,
      combinedCollectionsViewRow: {},
    };
  },
  [types.UPDATE_COLLECTIONS_STATE_SEARCH](state, { searchFilterValues }) {
    return {
      ...state,
      collectionState: {
        ...state.collectionState,
        SearchText: searchFilterValues.searchText,
        SearchField:
          searchFilterValues.searchField !== "View Fields"
            ? searchFilterValues.searchField
            : "AllViewFields",
      },
    };
  },
  [types.UPDATE_COMBINED_COLLECTIONS_STATE_SEARCH](
    state,
    { searchFilterValues }
  ) {
    return {
      ...state,
      combinedCollectionState: {
        ...state.combinedCollectionState,
        SearchText: searchFilterValues.searchText,
        SearchField:
          searchFilterValues.searchField !== "View Fields"
            ? searchFilterValues.searchField
            : "AllViewFields",
      },
    };
  },
  [types.UPDATE_STANDARD_COLLECTIONS_STATE_SEARCH](
    state,
    { searchFilterValues }
  ) {
    return {
      ...state,
      standardCollectionState: {
        ...state.standardCollectionState,
        SearchText: searchFilterValues.searchText,
        SearchField:
          searchFilterValues.searchField !== "View Fields"
            ? searchFilterValues.searchField
            : "AllViewFields",
      },
    };
  },
  [types.UPDATE_COMBINED_COLLECTIONS_FIELDS__STATE_SEARCH](
    state,
    { searchFilterValues }
  ) {
    return {
      ...state,
      combinedCollectionsFieldsCollectionState: {
        ...state.combinedCollectionsFieldsCollectionState,
        SearchText: searchFilterValues.searchText,
        SearchField:
          searchFilterValues.searchField !== "View Fields"
            ? searchFilterValues.searchField
            : "AllViewFields",
      },
    };
  },
  [types.SET_COMBINED_COLLECTIONS_EDIT_MODAL_FIELDS](state, { payload }) {
    return {
      ...state,
      combinedCollectionsEditModalFields: payload.fields,
    };
  },
  [types.SET_COLLECTIONS_CUSTOM_FIELDS](state, { payload }) {
    return {
      ...state,
      collectionsCustomFieldsColumns: payload.columns,
    };
  },
  [types.COLLECTION_UPDATE_STATUS](state, { payload }) {
    return {
      ...state,
      collectionUpdateStatus: payload.status,
    };
  },
  [types.SET_DELETE_COLLECTION_ERROR](state, { payload }) {
    return {
      ...state,
      errorMessage: payload.error,
    };
  },
  [types.CLEAR_COLLECTION_STATE](state) {
    return {
      ...state,
      errorMessage: null,
    };
  },
  [types.SET_COMPLETE_COLLECTION](state, { payload }) {
    return {
      ...state,
      mappedItems: payload,
    };
  },
  [types.SET_COLLECTIONS_FIELDS](state, { columns }) {
    return {
      ...state,
      collectionFieldsColumns: columns,
    };
  },
  [types.COLLECTIONS_UPDATEVIEWTABLE](state, { newViews }) {
    return {
      ...state,
      savedViews: newViews,
    };
  },

  [types.SET_COLLECTION](state, payload) {
    return {
      ...state,
      collection: payload.collection,
    };
  },

  [types.SET_COMBINED_COLLECTIONS_SOURCE_FIELDS](state, { payload }) {
    return {
      ...state,
      combinedCollectionsSourceFieldsGridColumns: payload.columns,
    };
  },

  [types.SET_COMBINED_DATA_SOURCE_FIELDS](state, { payload }) {
    return {
      ...state,
      combinedDataSourceFieldsGridColumns: payload.columns,
    };
  },

  [types.SET_ADD_FROM_EXISTING_COLLECTIONS](state, { payload }) {
    return {
      ...state,
      addFromExistingColumns: payload.columns,
      addFromExistingSortedBy: payload.sortedBy,
      addFromExistingViewRow: payload.viewRow,
      addFromExistingCollectionState: payload.collectionState,
      addFromExistingViewFields: payload.viewFields.map((viewField) => {
        return {
          name: viewField.Name,
          value: viewField.Name,
          fieldId: viewField.FieldID,
        };
      }),
    };
  },
  [types.SET_SAVED_VIEWS_FROM_EXISTING_COLLECTIONS](state, { payload }) {
    return {
      ...state,
      addFromExistingColumnsSavedViews: payload.savedViews,
    };
  },
  [types.UPDATE_FROM_EXISTING_COLLECTIONS_STATE_SEARCH](
    state,
    { searchFilterValues }
  ) {
    return {
      ...state,
      addFromExistingCollectionState: {
        ...state.addFromExistingCollectionState,
        SearchText: searchFilterValues.searchText,
        SearchField:
          searchFilterValues.searchField !== "View Fields"
            ? searchFilterValues.searchField
            : "AllViewFields",
      },
    };
  },
  [types.SET_COLLECTION_FROM_FILE](state, { columns }) {
    return {
      ...state,
      collectionFromFileColumns: columns,
    };
  },
  [types.SET_COLLECTION_FROM_FILE_FILECOLUMNS](state, { columns }) {
    return {
      ...state,
      collectionFromFileColumnsFc: columns,
    };
  },
  [types.SET_COLLECTION_FROM_FILE_FIELDSGRID](state, { columns }) {
    return {
      ...state,
      collectionFromFileColumnsFieldsGrid: columns,
    };
  },
  [types.SET_COLUMN_HEADINGS_FROM_FILE](state, { params }) {
    return {
      ...state,
      columnHeadingsFromFileParameters: params,
    };
  },
  [types.SET_COLLECTION_DATA_FROM_EXISTING](state, { payload }) {
    return {
      ...state,
      createdCollectionDataFromExisting: payload,
    };
  },
});
