import {
  GET_AGENTGROUPS_DATA,
  SET_AGENTGROUPS_DATA,
  AGENTGROUPS_AGENT_INFO_LOADED,
  ITEM_FILTER_AGENT_GROUP_DATA,
  SET_AGENT_GROUPS_DATA_SAVED_VIEWS,
  GET_AGENT_GROUPS_DATA_SAVED_VIEWS,
  TEMPLATE_GETAGENTCOMBOBOX,
  SET_TEMPLATE_AGENTCOMBOBOX,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import {
  takeLatest,
  put,
  take,
  select,
  call,
  cancel,
} from "redux-saga/effects";
import axios from "axios";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";

const Ext = window["Ext"];

function prepareModel(data, store) {
  const model = store.config.model;

  model.setFields(data);

  return model;
}

function prepareColumns(data) {
  return data.ViewFields.map((field) => ({
    dataIndex: field.Name,
    text: `<span title='${field.Name}'>${field.Name}</span>`,
    locked: field.IsSticky,
    hidden: field.IsHidden,
    width: field.ColumnWidth,
    cell: {
      height: 50,
    },
  }));
}

function* createStore(storeId, data, agentFilterItems, filteredData = {}) {
  const store = Ext.getStore(storeId);
  if (store) {
    const model = prepareModel(data, store);

    store.removeAll(true);
    store.setModel(model);

    const params = {
      Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
      AddSummaryRow: null,
      CollectionID: CONSTANTS.COLLECTIONS.GROUP.AGENTS,
      OverrideViewName: "",
      AgentID: 0,
      BookmarkID: filteredData.BookmarkID || 0,
      FolderID: "",
      AccountKey: "",
      JobID: -1,
      ItemHistoryRangeStart: "",
      ItemHistoryRangeEnd: "",
      ItemStatusesToInclude: "Added,Changed,Unchanged",
      ColumnsToAlwaysInclude: "Name",
      ForceViewSortable: "",
      AlwaysIncludeCustomFields: false,
      IncludeAdditionalCustomInterfaceColumns: "",
      SelectedIDs: "",
      ShowSelected: false,
      ShowMarkup: false,
      ...filteredData,
    };

    store.baseParams = params;
    store.getProxy().extraParams = store.baseParams;

    store.loadPage(1, { params });
  }

  const columns = prepareColumns(data);
  yield put({
    type: SET_AGENTGROUPS_DATA,
    payload: {
      columns,
      collectionState: data.CollectionState,
      collectionInfo: data.Collection,
      viewFields: data.ViewFields,
      viewRow: data.ViewRow,
      sortedBy: data.ViewSorting,
      agentFilterItems,
    },
  });
}

function* fetchAgentGroupsData({
  type: action,
  agentGroupId,
  storeId,
  formParams = {},
  BookmarkID,
  ViewID,
}) {
  const agentGroupsState = yield select((state) => state.agentGroups);

  if (
    !agentGroupsState.agentInfo.ItemID ||
    agentGroupsState.agentInfo.ItemID !== Number(agentGroupId)
  ) {
    yield take(AGENTGROUPS_AGENT_INFO_LOADED);
  }

  const { CollectionID } = yield select((state) => {
    return state.agentGroups.agentInfo;
  });

  if (CollectionID) {
    const collectionGetCompleteParams = {
      Command: CONSTANTS.COMMANDS.COLLECTION_GETCOMPLETE,
      CollectionID,
      AccountKey: "",
      OverrideViewName: "",
      ...formParams,
      ViewID: ViewID || "",
      BookmarkID,
    };

    const agentComboBoxParams = {
      Command: CONSTANTS.COMMANDS.TEMPLATE_GETAGENTCOMBOBOXOPTIONS,
      TemplateID: agentGroupId,
    };

    try {
      const [
        collectionGetCompleteResults,
        agentComboBoxResults,
      ] = yield axios.all([
        axios.post(
          CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
          new URLSearchParams(collectionGetCompleteParams),
          {
            headers: {
              action,
            },
          }
        ),
        axios.post(
          CONSTANTS.ROUTES.TEMPLATE_GETAGENTCOMBOBOXOPTIONS,
          new URLSearchParams(agentComboBoxParams),
          {
            headers: {
              action: CONSTANTS.COMMANDS.TEMPLATE_GETAGENTCOMBOBOXOPTIONS,
            },
          }
        ),
      ]);

      const firstAgentComboBoxResult =
        (agentComboBoxResults.data.Rows.length > 0 &&
          agentComboBoxResults.data.Rows[0]) ||
        {};

      const storeParams = {
        AgentID: firstAgentComboBoxResult.ItemID,
        CollectionID: firstAgentComboBoxResult.CollectionID,
        ViewID: collectionGetCompleteResults.data.ViewRow?.ViewID,
        TemplateCollectionID: CollectionID,
        SearchText: "",
        SearchField: "",
        ShowHistoryColumn: false,
        BookmarkID,
      };

      yield call(
        createStore,
        storeId,
        collectionGetCompleteResults.data,
        agentComboBoxResults.data.Rows,
        storeParams
      );
    } catch (error) {
      errorHandlingEvent.emit(CONSTANTS.ERROR_EVENT, {
        msg: error.message,
      });
    }
  } else {
    //
  }
}

function* bookmarkFilterData({ type: action, storeId, formParams = {} }) {
  const { CollectionID } = yield select((state) => {
    return state.agentGroups.agentInfo;
  });

  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETCOMPLETE,
    CollectionID: CollectionID,
    AccountKey: "",
    ViewID: formParams.ViewID || "",
    OverrideViewName: "",
    HideSystemViewNames: "",
    ...formParams,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    let collectionInfo = {};

    for (let key in data.Collection) {
      if (CONSTANTS.RESPONSE_CONTEXT.COLLECTION_INFO_CONTEXT.includes(key)) {
        collectionInfo[key] = data.Collection[key];
      }
    }

    yield call(createStore, storeId, data, collectionInfo, formParams);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* fetchSavedViews({ type: action, agentId }) {
  const agentGroupsState = yield select((state) => state.agentGroups);

  if (
    !agentGroupsState.agentInfo.ItemID ||
    agentGroupsState.agentInfo.ItemID !== Number(agentId)
  ) {
    yield take(AGENTGROUPS_AGENT_INFO_LOADED);
  }

  const { CollectionID } = yield select((state) => {
    return state.agentGroups.agentInfo;
  });

  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETVIEWS,
    CollectionID: CollectionID,
    AccountKey: "",
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETVIEWS,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    let savedViews = [];

    data.ViewTable.forEach((view) => {
      let savedView = {};
      for (let key in view) {
        if (CONSTANTS.RESPONSE_CONTEXT.SAVED_VIEWS_CONTEXT.includes(key)) {
          savedView[key] = view[key];
        }
      }
      savedViews.push(savedView);
    });

    yield put({
      type: SET_AGENT_GROUPS_DATA_SAVED_VIEWS,
      payload: {
        savedViews,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getAgentComboBoxOptions({ templateId }) {
  const params = {
    Command: "Template.GetAgentComboBoxOptions",
    TemplateID: templateId,
  };
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.TEMPLATE_GETAGENTCOMBOBOXOPTIONS,
      new URLSearchParams(params),
      {
        headers: {},
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    yield put({
      type: SET_TEMPLATE_AGENTCOMBOBOX,
      payload: {
        agentGroupDataList: data.Rows,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

export default function* agentGroupsDataWatcher() {
  yield takeLatest(ITEM_FILTER_AGENT_GROUP_DATA, bookmarkFilterData);
  yield takeLatest(GET_AGENTGROUPS_DATA, fetchAgentGroupsData);
  yield takeLatest(GET_AGENT_GROUPS_DATA_SAVED_VIEWS, fetchSavedViews);
  yield takeLatest(TEMPLATE_GETAGENTCOMBOBOX, getAgentComboBoxOptions);
}
