import {
  GET_BETA_SETTINGS,
  SET_BETA_SETTINGS,
  SHOW_SELECTED_GRID,
  UPDATE_VIEW_FIELD,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import { takeLatest, put, select, cancel } from "redux-saga/effects";
import axios from "axios";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";
import { COMMANDS } from "../../constants/commands";

function* fetchBetaSettings() {
  const params = {
    Command: CONSTANTS.COMMANDS.COMMON_GETWEBCONSOLEBETASETTINGS,
  };
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COMMON_GETWEBCONSOLEBETASETTINGS,
      new URLSearchParams(params)
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    yield put({
      type: SET_BETA_SETTINGS,
      payload: {
        betaSettings: {
          betaWebConsoleUrl: data.BetaWebConsoleUrl,
          betaWebConsoleEnabled: data.BetaWebConsoleEnabled,
          betaEnabled: data.BetaEnabled,
          betaHarvestingEnabled: data.BetaHarvestingEnabled,
          betaWebConsoleRequireBetaHarvesting:
            data.BetaWebConsoleRequiresBetaHarvesting,
        },
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* updateViewField({ params }) {
  try {
    yield axios.post(
      CONSTANTS.ROUTES.VIEW_UPDATE_FIELD_STATE,
      new URLSearchParams(params)
    );
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* showSelectedGrid({
  params,
  selected,
  setIsSelected,
  store,
  selectedItems,
  gridType,
  section,
}) {
  const { data } = yield axios.post(
    CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
    new URLSearchParams(params)
  );

  if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
    yield cancel();
  }

  const reduxState = yield select();
  const accountKey = reduxState.account.accountInfo.Account.AccountKey;
  let storeParams;

  if (section === "settings") {
    storeParams = {
      AddSummaryRow: "",
      Command: COMMANDS.VIEW_GETITEMS,
      CollectionID: params.CollectionID,
      ViewID: params.viewId,
      OverrideViewName: "",
      FolderID: "",
      AccountKey: reduxState.account.accountInfo.MasterAccountKey,
      AgentID: 0,
      BookmarkID: 0,
      JobID: -1,
      ItemHistoryRangeStart: "",
      ItemHistoryRangeEnd: "",
      ItemStatusesToInclude: "",
      ColumnsToAlwaysInclude: "SettingName",
      ForceViewSortable: "",
      AlwaysIncludeCustomFields: false,
      IncludeAdditionalCustomInterfaceColumns: "",
      SelectedIDs: selectedItems.map((el) => el.id).join(","),
      ShowHistoryColumn: false,
      ShowSelected: selected,
      TemplateCollectionID: 0,
      ShowMarkup: false,
    };
  } else if (section === "account" && gridType === "users") {
    storeParams = {
      AddSummaryRow: "",
      Command: COMMANDS.VIEW_GETITEMS,
      CollectionID: params.CollectionID,
      ViewID: data.ViewRow.ViewID,
      OverrideViewName: "",
      FolderID: "",
      AccountKey: accountKey,
      AgentID: 0,
      BookmarkID: 0,
      JobID: -1,
      ItemHistoryRangeStart: "",
      ItemHistoryRangeEnd: "",
      ItemStatusesToInclude: "",
      ColumnsToAlwaysInclude: `Email,NameFirst,NameLast,Status,UserKey,AccountPermissions,Settings,City,Company,Country,FaxNumber,Phone1,Phone2,Street1,Street2,State,Title,ZipCode`,
      ForceViewSortable: "",
      AlwaysIncludeCustomFields: false,
      IncludeAdditionalCustomInterfaceColumns: "",
      SelectedIDs: selectedItems.map((el) => el.data.UserKey),
      ShowHistoryColumn: false,
      ShowSelected: selected,
      TemplateCollectionID: 0,
      ShowMarkup: false,
    };
  } else if (gridType !== "departments") {
    storeParams = {
      AddSummaryRow: "",
      Command: COMMANDS.VIEW_GETITEMS,
      CollectionID: params.CollectionID,
      ViewID: data.ViewRow.ViewID,
      OverrideViewName: "",
      FolderID: "",
      AccountKey: accountKey,
      AgentID: 0,
      BookmarkID: 0,
      JobID: -1,
      ItemHistoryRangeStart: "",
      ItemHistoryRangeEnd: "",
      ItemStatusesToInclude: "",
      ColumnsToAlwaysInclude:
        "Config,Name,Description,Settings,Status,ErrorPostpone,CollectionID,LimitWebPageCredits,AgentGroup,Geolocation,ProxyPool,BrowserType",
      ForceViewSortable: "",
      AlwaysIncludeCustomFields: false,
      IncludeAdditionalCustomInterfaceColumns: true,
      SelectedIDs: selectedItems.map((el) => el.data.ItemID),
      ShowHistoryColumn: false,
      ShowSelected: selected,
      TemplateCollectionID: 0,
      SearchText: data.CollectionState.SearchText,
      SearchField: data.CollectionState.SearchField,
      ShowMarkup: false,
      group: { property: "Group", direction: "ASC" },
    };
  } else {
    storeParams = {
      AddSummaryRow: true,
      Command: COMMANDS.VIEW_GETITEMS,
      CollectionID: params.CollectionID,
      ViewID: data.ViewRow.ViewID,
      OverrideViewName: "",
      FolderID: "",
      AccountKey: accountKey,
      AgentID: 0,
      BookmarkID: 0,
      JobID: -1,
      ItemHistoryRangeStart: "",
      ItemHistoryRangeEnd: "",
      ItemStatusesToInclude: "",
      ColumnsToAlwaysInclude:
        "LimitAgents,LimitStorage,LimitJobs,LimitUsers,TotalAgents,TotalStorage,TotalUsers,Description,Agents,Storage,Users,PageCredits,Company",
      ForceViewSortable: "",
      AlwaysIncludeCustomFields: false,
      IncludeAdditionalCustomInterfaceColumns: "",
      SelectedIDs: selectedItems
        .map((el) => {
          return el.data.AccountKey;
        })
        .join(","),
      ShowHistoryColumn: false,
      ShowSelected: selected,
      TemplateCollectionID: 0,
      ShowMarkup: false,

      group: { property: "Group", direction: "ASC" },
    };
  }

  store.load({
    params: storeParams,
    callback: () => setIsSelected((prevState) => !prevState),
  });
}

export default function* commonWatcher() {
  yield takeLatest(GET_BETA_SETTINGS, fetchBetaSettings);
  yield takeLatest(UPDATE_VIEW_FIELD, updateViewField);
  yield takeLatest(SHOW_SELECTED_GRID, showSelectedGrid);
}
