import { IconTypes } from "../Data/Enums/IconTypes";
import variables from "../assets/sass/_variables.scss";

export const SYSTEM_FIELDS = {
  NAMES: ["ItemID", "Created", "CreatedBy", "Modified", "ModifiedBy"],
};

export const HIDE_FIELDS = {
  ViewProperties: ["JobID", "System Details"],
  FieldList: ["ItemID", "JobID", "System Details"],
};

export const CUSTOM_FIELD_STATUS = {
  USED_FIELD: {
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["far", "check"],
    COLOR: variables["color-success"],
  },
};
