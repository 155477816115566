import { createStore } from "redux";
import rootReducer from "./reducers";
import rootSaga from "./sagas";
import storeEnhancer, { sagaMiddleware } from "./middleware";

export default function configureStore() {
  const store = createStore(rootReducer, storeEnhancer);

  sagaMiddleware.run(rootSaga);

  return store;
}
