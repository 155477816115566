import {
  GET_AGENTS_CUSTOM_FIELDS,
  SET_AGENTS_CUSTOM_FIELDS,
  AGENT_UPDATE,
  AGENT_UPDATE_STATUS,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import { put, takeLatest, select, cancel } from "redux-saga/effects";
import axios from "axios";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";

const Ext = window["Ext"];

function getGridColumns() {
  return [
    {
      dataIndex: "Name",
      text: "<span title='Field Name'>Field Name</span>",
      locked: true,
      menuDisabled: true,
      resizable: true,
      searchable: true,
      width: 200,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "DefaultValue",
      text: "<span title='DefaultValue'>DefaultValue</span>",
      locked: true,
      menuDisabled: true,
      resizable: true,
      searchable: true,
      editable: true,
      width: 200,
      cell: {
        height: 50,
      },
    },
  ];
}

function* fetchAgentCustomFields({ storeId, formParams }) {
  const params = {
    CollectionID: CONSTANTS.COLLECTIONS.GROUP.AGENTS,
    ExcludeSystemFields: true,
    ExcludeSeparators: true,
    Command: CONSTANTS.COMMANDS.COLLECTION_GETFIELDS,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETFIELDS,
      new URLSearchParams(params)
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    const state = yield select();
    const agentInfo = state.agents.agentInfo;
    let customFields = data.Fields;

    for (let i = 0; i < customFields.length; i++) {
      let currentField = customFields[i];
      currentField.DefaultValue = agentInfo[currentField.Name];
    }

    const store = Ext.getStore(storeId);
    if (store) {
      store.loadData(customFields);
    }

    const columns = getGridColumns();

    const { BuiltByPrevious } = yield axios.post(
      CONSTANTS.ROUTES.AGENT_BUILTBYPREVIOUS,
      new URLSearchParams({
        AgentID: [...formParams?.AgentIDList],
        Command: CONSTANTS.COMMANDS.AGENT_BUILTBYPREVIOUS,
      })
    );

    yield put({
      type: SET_AGENTS_CUSTOM_FIELDS,
      payload: {
        agentBuildByPreviousVersion: BuiltByPrevious > 0 ? true : false,
      },
    });

    yield put({
      type: SET_AGENTS_CUSTOM_FIELDS,
      payload: {
        columns,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* agentUpdate({ formParams }) {
  const { AgentIDList, storeId, agentName, agentDescription } = formParams;
  const store = Ext.getStore(storeId);
  const storeData = store.data.items.map((item) => item.data);
  let agentCustomFieldsObj = {};
  for (let item of storeData) {
    if (item.DefaultValue) agentCustomFieldsObj[item.Name] = item.DefaultValue;
    else agentCustomFieldsObj[item.Name] = "";
  }

  let params = {};
  let urlParams = {};

  if (AgentIDList.length === 1) {
    params = {
      AgentIDList: `${AgentIDList[0]}`,
      Name: `${agentName}`,
      Description: `${agentDescription}`,
      Command: CONSTANTS.COMMANDS.AGENT_UPDATE,
      ...agentCustomFieldsObj,
    };

    urlParams = new URLSearchParams(params);
  } else if (AgentIDList.length > 1) {
    params = {
      Command: CONSTANTS.COMMANDS.AGENT_UPDATE,
      ...agentCustomFieldsObj,
    };

    urlParams = new URLSearchParams(params);
    AgentIDList.map((agentId) => urlParams.append("AgentIDList", agentId));
  }

  try {
    yield put({
      type: AGENT_UPDATE_STATUS,
      payload: {
        status: "pending",
      },
    });

    const result = yield axios.post(
      CONSTANTS.ROUTES.AGENT_UPDATE,
      urlParams,
      {}
    );
    if (result.data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    yield put({
      type: AGENT_UPDATE_STATUS,
      payload: {
        status: result.data.JsonResult.Result.toLowerCase(),
      },
    });

    const agentListStore = Ext.getStore(CONSTANTS.STORES.IDS.AGENT_LIST);

    //#region agent list refresh to reflect newly changed data
    const agentGroupAgentsListStore = Ext.getStore(
      CONSTANTS.STORES.IDS.AGENT_GROUPS_AGENTS
    );
    if (agentListStore) {
      agentListStore.load();
    }
    if (agentGroupAgentsListStore) {
      agentGroupAgentsListStore.load();
    }
    //#endregion
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

export default function* agentsCustomFieldsWatcher() {
  yield takeLatest(GET_AGENTS_CUSTOM_FIELDS, fetchAgentCustomFields);
  yield takeLatest(AGENT_UPDATE, agentUpdate);
}
