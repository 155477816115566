import {
  GET_COLLECTIONS_CUSTOM_FIELDS,
  SET_COLLECTIONS_CUSTOM_FIELDS,
  COLLECTION_UPDATE,
  COLLECTION_UPDATE_STATUS,
  SET_COLLECTION,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import { put, takeLatest, select, call, cancel } from "redux-saga/effects";
import axios from "axios";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";

const Ext = window["Ext"];

function getGridColumns() {
  return [
    {
      dataIndex: "Name",
      text: "<span title='Field Name'>Field Name</span>",
      locked: true,
      menuDisabled: true,
      resizable: true,
      searchable: true,
      width: 200,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "DefaultValue",
      text: "<span title='DefaultValue'>DefaultValue</span>",
      locked: true,
      menuDisabled: true,
      resizable: true,
      searchable: true,
      editable: true,
      width: 200,
      cell: {
        height: 50,
      },
    },
  ];
}

function* getCollection(action, formParams) {
  try {
    const params = {
      Command: CONSTANTS.COMMANDS.COLLECTION_GET,
      ...formParams,
    };
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GET,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    yield put({
      type: SET_COLLECTION,
      collection: { ...data.Collection, agentId: data.AgentID },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* fetchCollectionCustomFields({ type: action, storeId, formParams }) {
  const params = {
    CollectionID: 1,
    ExcludeSystemFields: true,
    ExcludeSeparators: true,
    Command: CONSTANTS.COMMANDS.COLLECTION_GETFIELDS,
  };

  try {
    yield call(getCollection, action, {
      collectionID: formParams.collectionID,
    });

    const columns = getGridColumns();

    yield put({
      type: SET_COLLECTIONS_CUSTOM_FIELDS,
      payload: {
        columns,
      },
    });

    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETFIELDS,
      new URLSearchParams(params),
      {}
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    const state = yield select();

    let collectionInfo = state.collections.collection;
    let collectionCustomFields = data.Fields;
    let collectionCustomFieldsWithValues = collectionCustomFields.map(
      (element) => {
        return {
          Name: element.Name,
          DefaultValue: collectionInfo[element.Name],
        };
      }
    );
    const store = Ext.getStore(storeId);
    if (store) {
      store.loadData(collectionCustomFieldsWithValues);
    }
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* collectionUpdate({ formParams }) {
  const {
    CollectionIDList,
    storeId,
    collectionName,
    collectionDescription,
  } = formParams;

  const store = Ext.getStore(storeId);
  const storeData = store.data.items.map((item) => item.data);
  let collectionCustomFieldsObj = {};
  for (let item of storeData) {
    if (item.DefaultValue)
      collectionCustomFieldsObj[item.Name] = item.DefaultValue;
  }

  let params = {};
  let urlParams = {};

  if (CollectionIDList.length === 1) {
    params = {
      CollectionID: `${CollectionIDList[0]}`,
      Name: `${collectionName}`,
      Description: `${collectionDescription}`,
      Command: CONSTANTS.COMMANDS.COLLECTION_UPDATE,
      ...collectionCustomFieldsObj,
    };

    urlParams = new URLSearchParams(params);
  } else if (CollectionIDList.length > 1) {
    params = {
      Command: CONSTANTS.COMMANDS.COLLECTION_UPDATE,
      ...collectionCustomFieldsObj,
    };

    urlParams = new URLSearchParams(params);
    CollectionIDList.map((agentId) =>
      urlParams.append("CollectionIDList", agentId)
    );
  }

  try {
    yield put({
      type: COLLECTION_UPDATE_STATUS,
      payload: {
        status: "pending",
      },
    });

    const result = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_UPDATE,
      urlParams,
      {}
    );

    if (result.data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        result.data.JsonResult.Result,
        result.data.JsonResult.ErrorDescription,
        result.data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    yield put({
      type: COLLECTION_UPDATE_STATUS,
      payload: {
        status: result.data.JsonResult.Result.toLowerCase(),
      },
    });

    //#region refresh the caller agent/collection list
    const agentGroupAgentsListStore = Ext.getStore(
      CONSTANTS.STORES.IDS.AGENT_GROUPS_AGENTS
    );
    const agentListStore = Ext.getStore(CONSTANTS.STORES.IDS.AGENT_LIST);
    const collectionListStore = Ext.getStore(
      CONSTANTS.STORES.IDS.COLLECTION_LIST
    );

    if (agentListStore) {
      agentListStore.load();
    }
    if (agentGroupAgentsListStore) {
      agentGroupAgentsListStore.load();
    }
    if (collectionListStore) {
      collectionListStore.load();
    }
    //#endregion
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

export default function* collectionCustomFieldsWatcher() {
  yield takeLatest(GET_COLLECTIONS_CUSTOM_FIELDS, fetchCollectionCustomFields);
  yield takeLatest(COLLECTION_UPDATE, collectionUpdate);
}
