import {
  GET_ACCOUNT_USERS,
  SET_ACCOUNT_USERS,
  GET_ACCOUNT_USERS_SAVED_VIEWS,
  SET_ACCOUNT_USERS_SAVED_VIEWS,
  GET_EXISTING_USER,
  SET_EXISTING_USERS,
  ADD_EXISTING_USER,
  NEW_USER,
  INVITE_ACCOUNT_USERS,
  SET_NEW_USER_ERROR,
  REMOVE_NEW_USER_ERROR,
  EDIT_USER,
  REMOVE_EDIT_USER_ERROR,
  SET_EDIT_USER_ERROR,
  GENERATE_WEB_SERVICE_KEY,
  SET_WEB_SERVICE_KEY,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import { takeLatest, put, call, select, cancel } from "redux-saga/effects";
import {
  renderDepartmentUsersStatusCell,
  renderDepartmentUsersAgentsCell,
  renderDepartmentUsersCollectionsCell,
  renderDepartmentUsersSequencesCell,
  renderDepartmentUsersAccountCell,
  renderDepartmentUsersNameFullCell,
  renderDepartmentUsersLastSeenCell,
  renderDepartmentUsersInvitedLastCell,
} from "../../Components/Grid/SenchaLockedGrid/renderers";
import {
  mapDepartmentUsersStatusCell,
  mapDepartmentUsersAgentsCell,
  mapDepartmentUsersCollectionsCell,
  mapDepartmentUsersSequencesCell,
  mapDepartmentUsersAccountCell,
  mapDepartmentUsersNameFullCell,
  mapDepartmentUsersLastSeenCell,
  mapDepartmentUsersInvitedLastCell,
} from "../../Components/Grid/SenchaLockedGrid/mappers";
import axios from "axios";
import { MASTER_COLLECTIONS } from "../../constants/collections";
import { COMMANDS } from "../../constants/commands";
import { GROUP_COLLECTIONS } from "../../constants/collections";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";

const Ext = window["Ext"];

function prepareGridColumns(data, accountFeatures) {
  const renderers = {
    Status: {
      renderer: renderDepartmentUsersStatusCell,
      mapper: mapDepartmentUsersStatusCell,
    },
    Agents: {
      renderer: renderDepartmentUsersAgentsCell,
      mapper: mapDepartmentUsersAgentsCell,
    },
    Collections: {
      renderer: renderDepartmentUsersCollectionsCell,
      mapper: mapDepartmentUsersCollectionsCell,
    },
    Sequences: {
      renderer: renderDepartmentUsersSequencesCell,
      mapper: mapDepartmentUsersSequencesCell,
    },
    Account: {
      renderer: renderDepartmentUsersAccountCell,
      mapper: mapDepartmentUsersAccountCell,
    },
    NameFull: {
      renderer: renderDepartmentUsersNameFullCell,
      mapper: mapDepartmentUsersNameFullCell,
    },
    WebConsoleOnlineLast: {
      renderer: renderDepartmentUsersLastSeenCell,
      mapper: mapDepartmentUsersLastSeenCell,
    },
    InvitedLast: {
      renderer: renderDepartmentUsersInvitedLastCell,
      mapper: mapDepartmentUsersInvitedLastCell,
    },
  };

  return data.ViewFields.map((field) => {
    if (renderers[field.Name]) {
      return {
        dataIndex: field.Name,
        text: `<span title='${field.Name}'>${field.Name}</span>`,
        locked: field.IsSticky,
        hidden:
          field.IsHidden ||
          (field.Name === "Sequences" &&
            !accountFeatures.includes("Sequences")),
        cell: {
          height: 50,
          encodeHtml: true,
          xtype: "reactcell",
        },
        renderer: (value, record) =>
          renderers[field.Name].renderer(
            renderers[field.Name].mapper,
            value,
            record
          ),
      };
    }

    return {
      dataIndex: field.Name,
      text: `<span title='${field.Name}'>${field.Name}</span>`,
      locked: field.IsSticky,
      hidden: field.IsHidden,
      width: field.ColumnWidth,
      cell: {
        height: 50,
        encodeHtml: true,
      },
    };
  });
}

function prepareModel(data, store) {
  const model = store.config.model;

  model.setFields(data);

  return model;
}

function* createStore(storeId, data, extraParams = {}) {
  const store = Ext.getStore(storeId);
  const state = yield select();
  let accountFeatures = state.account.accountInfo?.Account?.Features;
  if (store) {
    const model = prepareModel(data, store);
    const {
      SearchText,
      SearchField,
      PageItemCount,
      PageIndex,
    } = data.CollectionState;

    store.removeAll(true);
    store.setModel(model);

    store.setPageSize(PageItemCount);

    store.loadPage(PageIndex, {
      params: {
        Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
        CollectionID: CONSTANTS.COLLECTIONS.GROUP.USERS,
        ViewID: data.ViewRow.ViewID,
        SearchText,
        SearchField,
        ...extraParams,
      },
    });
  }

  const columns = prepareGridColumns(data, accountFeatures);

  yield put({
    type: SET_ACCOUNT_USERS,
    payload: {
      columns,
      sortedBy: data.ViewSorting,
      viewRow: data.ViewRow,
      collectionState: data.CollectionState,
      viewFields: data.ViewFields,
    },
  });
}

function* fetchAccountUsers({ type: action, storeId, formParams = {} }) {
  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETCOMPLETE,
    CollectionID: MASTER_COLLECTIONS.USERS,
    ColumnsToAlwaysInclude:
      "Email,NameFirst,NameLast,Status,UserKey,AccountPermissions,Settings,City,Company,Country,FaxNumber,Phone1,Phone2,Street1,Street2,State,Title,ZipCode",
    ...formParams,
  };

  const extraParams = {
    ColumnsToAlwaysInclude:
      "Email,NameFirst,NameLast,Status,UserKey,AccountPermissions,Settings,City,Company,Country,FaxNumber,Phone1,Phone2,Street1,Street2,State,Title,ZipCode",
    ...formParams,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    yield call(createStore, storeId, data, extraParams);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getSavedViews({ type: action }) {
  const reduxState = yield select();
  const params = {
    AccountKey: reduxState.account.accountInfo.Account.AccountKey,
    CollectionID: CONSTANTS.COLLECTIONS.GROUP.USERS,
    Command: CONSTANTS.COMMANDS.COLLECTION_GETVIEWS,
  };

  try {
    const result = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETVIEWS,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (result.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        result.data.JsonResult.Result,
        result.data.JsonResult.ErrorDescription,
        result.data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    const savedViews = [];

    result.data.ViewTable.forEach((view) => {
      let savedView = {};
      for (let key in view) {
        if (CONSTANTS.RESPONSE_CONTEXT.SAVED_VIEWS_CONTEXT.includes(key)) {
          savedView[key] = view[key];
        }
      }
      savedViews.push(savedView);
    });

    yield put({
      type: SET_ACCOUNT_USERS_SAVED_VIEWS,
      payload: {
        savedViews,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}
function* newUser({ params, email, accountKey, viewId, toggleModal, storeId }) {
  try {
    const data = yield axios.post(
      CONSTANTS.ROUTES.USER_CHECK_EMAIL_ADDRESS,
      new URLSearchParams({
        EmailAddress: email,
        OldEmail: "",
        Mode: "WebConsoleAdd",
        Command: CONSTANTS.COMMANDS.USER_CHECK_EMAIL_ADDRESS,
      })
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    if (data.data.ErrorMessageText !== "") {
      yield put({
        type: SET_NEW_USER_ERROR,
        payload: data.data.ErrorMessageText,
      });
      return;
    } else {
      yield put({
        type: REMOVE_NEW_USER_ERROR,
      });
      yield axios.post(CONSTANTS.ROUTES.USER_ADD, params);
    }
    window["Ext"].getStore(storeId).load({
      params: {
        Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
        CollectionID: GROUP_COLLECTIONS.USERS,
        ViewID: viewId,
        AccountKey: accountKey,
        ColumnsToAlwaysInclude:
          "Email,NameFirst,NameLast,Status,UserKey,AccountPermissions,Settings,City,Company,Country,FaxNumber,Phone1,Phone2,Street1,Street2,State,Title,ZipCode",
        AlwaysIncludeCustomFields: false,
        ShowHistoryColumn: false,
        ShowSelected: false,
      },
    });
    toggleModal(false);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}
function prepareExistingUsersColumns() {
  return [
    {
      dataIndex: "Email",
      text: "<span title='Email'>Email</span>",
      locked: true,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 75,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "MasterAdministrator",
      text: "<span title='MasterAdministrator'>MasterAdministrator</span>",
      locked: true,
      menuDisabled: true,
      resizable: true,
      searchable: true,
      width: 220,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "NameFirst",
      text: "<span title='FirstName'>FirstName</span>",
      locked: true,
      menuDisabled: true,
      resizable: true,
      searchable: true,
      width: 220,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "NameLast",
      text: "<span title='Last Name'>Last Name</span>",
      locked: false,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 125,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "Title",
      text: "<span title='Title'>Title</span>",
      locked: false,
      menuDisabled: true,
      resizable: true,
      searchable: true,
      width: 116,
      cell: {
        height: 50,
        encodeHtml: true,
        xtype: "reactcell",
      },
    },
  ];
}
function* addExistingUser({ selectedItems, refetchAccountUsers, closeModal }) {
  const reduxState = yield select();

  const urlParams = new URLSearchParams({
    AccountKey: reduxState.account.accountInfo.Account.AccountKey,
    Command: "User.AddExistingUsers",
  });
  selectedItems.forEach((item) => {
    urlParams.append("EmailAddresses", item.data.Email);
  });
  yield axios.post(CONSTANTS.ROUTES.USER_ADD_EXISTING_USERS, urlParams);
  refetchAccountUsers();
  closeModal();
}
function* getExistingUser({ storeId }) {
  const reduxState = yield select();
  const params = {
    ExcludeSelf: true,
    AccountKey: reduxState.account.accountInfo.Account.AccountKey,
    Command: COMMANDS.USER_GET_PICKER_LIST,
  };
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.USER_GET_PICKER_LIST,
      new URLSearchParams(params)
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    setTimeout(() => {
      const store = window["Ext"].getStore(storeId);
      const uniqueData = [];
      data.Rows.forEach((el) => {
        if (!uniqueData.some((data) => data.Email === el.Email)) {
          uniqueData.push(el);
        }
      });
      if (store) {
        store.loadData(uniqueData);
      }
    });

    const columns = prepareExistingUsersColumns();
    yield put({
      type: SET_EXISTING_USERS,
      columns,
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}
function* editUser({
  params,
  email,
  accountKey,
  viewId,
  oldEmail,
  toggleModal,
  storeId,
  setActionBarItemsState,
}) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.USER_CHECK_EMAIL_ADDRESS,
      new URLSearchParams({
        EmailAddress: email,
        OldEmail: oldEmail,
        Mode: "WebConsoleAdd",
        Command: CONSTANTS.COMMANDS.USER_CHECK_EMAIL_ADDRESS,
      })
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    if (data.ErrorMessageText !== "") {
      yield put({
        type: SET_EDIT_USER_ERROR,
        payload: data.ErrorMessageText,
      });
    } else {
      yield put({
        type: REMOVE_EDIT_USER_ERROR,
      });
      yield axios.post(CONSTANTS.ROUTES.USER_UPDATE, params);
      window["Ext"].getStore(storeId).load({
        params: {
          Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
          CollectionID: GROUP_COLLECTIONS.USERS,
          ViewID: viewId,
          AccountKey: accountKey,
          ColumnsToAlwaysInclude:
            "Email,NameFirst,NameLast,Status,UserKey,AccountPermissions,Settings,City,Company,Country,FaxNumber,Phone1,Phone2,Street1,Street2,State,Title,ZipCode",
          AlwaysIncludeCustomFields: false,
          ShowHistoryColumn: false,
          ShowSelected: false,
        },
      });
      toggleModal(false);
      setActionBarItemsState();
    }
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}
function* generateWebServiceKey({ UpdateUserKey, generateNew }) {
  try {
    const key = yield axios.post(
      CONSTANTS.ROUTES.USER_GENERATE_WEBSERVICE_KEY,
      new URLSearchParams({
        GenerateNew: generateNew,
        UpdateUserKey: UpdateUserKey,
        Command: CONSTANTS.COMMANDS.USER_GENERATE_WEBSERVICE_KEY,
      })
    );

    if (key.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    yield put({
      type: SET_WEB_SERVICE_KEY,
      payload: key.data.UserWebServiceKey,
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* inviteAccountUsers({
  params,
  setLoading,
  toggleModal,
  accountKey,
  viewId,
}) {
  try {
    setLoading(true);
    yield axios.post(CONSTANTS.ROUTES.ACCOUNT_INVITE_USERS, params);

    window["Ext"].getStore(CONSTANTS.STORES.IDS.ACCOUNT_USERS).load({
      params: {
        Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
        CollectionID: GROUP_COLLECTIONS.USERS,
        ViewID: viewId,
        AccountKey: accountKey,
        BookmarkID: 0,
        JobID: -1,
        ColumnsToAlwaysInclude:
          "Email,NameFirst,NameLast,Status,UserKey,AccountPermissions,Settings,City,Company,Country,FaxNumber,Phone1,Phone2,Street1,Street2,State,Title,ZipCode",
        AlwaysIncludeCustomFields: false,
        ShowHistoryColumn: false,
        ShowSelected: false,
        TemplateCollectionID: 0,
        ShowMarkup: false,
      },
    });
    setLoading(false);
    toggleModal(false);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

export default function* accountUsersWatcher() {
  yield takeLatest(GET_ACCOUNT_USERS, fetchAccountUsers);
  yield takeLatest(NEW_USER, newUser);
  yield takeLatest(EDIT_USER, editUser);
  yield takeLatest(GENERATE_WEB_SERVICE_KEY, generateWebServiceKey);
  yield takeLatest(INVITE_ACCOUNT_USERS, inviteAccountUsers);
  yield takeLatest(GET_ACCOUNT_USERS_SAVED_VIEWS, getSavedViews);
  yield takeLatest(GET_EXISTING_USER, getExistingUser);
  yield takeLatest(ADD_EXISTING_USER, addExistingUser);
}
