import { IconTypes } from "../Data/Enums/IconTypes";
import { ReactComponent as CollectionCogIcon } from "../assets/icons/mwc-collectioncog.svg";
import { ReactComponent as ComboIcon } from "../assets/icons/mwc-combo.svg";

export const COLLECTION_TYPE = {
  STANDARD: {
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fad", "layer-group"],
    DESCRIPTION: "Standard collection",
    NAME: "Standard",
  },
  AGENT: {
    TYPE: IconTypes.CUSTOM,
    ICON: CollectionCogIcon,
    DESCRIPTION: "Agent collection",
    NAME: "Agent",
  },
  COMBINED: {
    TYPE: IconTypes.CUSTOM,
    ICON: ComboIcon,
    DESCRIPTION: "Combined collection",
    NAME: "Combined",
  },
  SYSTEM: {
    DESCRIPTION: "System collection",
    NAME: "System",
  },
};

export const SYSTEM_COLLECTIONS = {
  COLLECTIONS: 1,
};

export const MASTER_COLLECTIONS = {
  GROUPS: 101,
  GROUP_DETAILS: 103,
  USERS: 105,
  SETTINGS: 111,
  IP_DETAILS: 113,
  RATE_PLANS: 121,
  BILL_ELEMENTS: 123,
};

export const COMMON_COLLECTIONS = {
  LOGS: 201,
  STATISTICS: 203,
  JOBS: 211,
  JOB_DETAILS: 213,
  SCHEDULES: 221,
  SCHEDULE_DETAILS: 223,
  EVENTS: 231,
  NOTIFICATIONS: 241,
  NOTIFICATIONS_LOGS: 243,
};

export const INSIGHTS_COLLECTIONS = {
  ACCOUNT_INSIGHTS: 319,
  INSIGHTS_HISTORY: 317,
  SYSTEM_INSIGHTS: 321,
};

export const GROUP_COLLECTIONS = {
  BILLING: 301,
  BILLING_SUMMARY: 303,
  AGENTS: 305,
  FOLDERS: 3,
  PUBLISHERS: 331,
  SEQUENCES: 351,
  SEQUENCE_DETAILS: 353,
  COOKIE_STORES: 361,
  COOKIE_STORE_DETAILS: 363,
  STORAGE_DETAILS: 371,
  STORAGE_SUMMARY: 373,
  DEPARTMENTS: 101,
  DEPARTMENT_INSIGHTS: 319,
  USERS: 105,
  JOBS: 211,
  ACCOUNT_STATISTICS: 319,
  SYSTEM_RATE_PLANS: 121,
};
