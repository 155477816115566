import {
  GET_NEW_DEPARTMENT_USERS,
  SET_NEW_DEPARTMENT_USERS,
  ACCOUNT_CREATENEWGROUP,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import { put, takeEvery, select, cancel } from "redux-saga/effects";
import axios from "axios";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";

const Ext = window["Ext"];

function getGridColumns() {
  return [
    {
      dataIndex: "Email",
      text: "<span title='Email'>Email</span>",
      menuDisabled: true,
      resizable: true,
      searchable: true,
      width: 200,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "NameFirst",
      text: "<span title='First Name'>First Name</span>",
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 125,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "NameLast",
      text: "<span title='Last Name'>Last Name</span>",
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 125,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "Title",
      text: "<span title='Title'>Title</span>",
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 125,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "MasterAdministrator",
      text: "MasterAdministrator",
      hidden: true,
      menuDisabled: true,
      resizable: false,
      searchable: true,
    },
  ];
}

function* fetchNewDepartmentUsers({ type: action, STORE_ID }) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.USER_GETPICKERLIST,
      new URLSearchParams({ Command: CONSTANTS.COMMANDS.USER_GETPICKERLIST }),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    const uniqueUserAccounts = Array.from(
      new Set(data.Rows.map((item) => item.Email))
    ).map((Email) => {
      return data.Rows.find((a) => a.Email === Email);
    });

    const columns = getGridColumns();
    const store = Ext.getStore(STORE_ID);
    if (store) {
      store.loadData(uniqueUserAccounts);
    }

    yield put({
      type: SET_NEW_DEPARTMENT_USERS,
      payload: {
        columns,
        uniqueUserAccounts,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* createNewDepartment({
  type: action,
  formParams,
  errorResponseCallback,
  successResponseCallback,
  setLoading,
}) {
  const state = yield select();
  try {
    setLoading(true);
    const selectedUsers = formParams.selectedUsers;
    const masterAdmins = state.departments.uniqueUserAccounts?.filter(
      (user) => user.MasterAdministrator === "True"
    );

    const newDepartmentUsers = [...selectedUsers, ...masterAdmins];
    const newDepartmentUniqueUsers = Array.from(
      new Set(newDepartmentUsers.map((user) => user.Email))
    ).map((Email) => {
      return newDepartmentUsers.find((user) => user.Email === Email);
    });

    const { data } = yield axios.post(
      CONSTANTS.ROUTES.ACCOUNT_CREATENEWGROUP,
      new URLSearchParams({
        Name: formParams.Name,
        Description: formParams.Description,
        EmailAddresses: [newDepartmentUniqueUsers.map((user) => user.Email)],
        UserKeys: [
          newDepartmentUniqueUsers
            .filter((user) => user.UserKeys)
            .map((user) => user.UserKeys),
        ],
        Command: CONSTANTS.COMMANDS.ACCOUNT_CREATENEWGROUP,
      }),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (data.JsonResult.Result === "Error") {
      errorResponseCallback(data.JsonResult.ErrorDescription);
    }

    if (data.JsonResult.Result === "Success" && data.NewAccountKey) {
      successResponseCallback(data.NewAccountKey);
    }
    setLoading(false);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

export default function* newDepartmentWatcher() {
  yield takeEvery(GET_NEW_DEPARTMENT_USERS, fetchNewDepartmentUsers);
  yield takeEvery(ACCOUNT_CREATENEWGROUP, createNewDepartment);
}
