import { IconTypes } from "../Data/Enums/IconTypes";
import variables from "../assets/sass/_variables.scss";
import { ReactComponent as SequenceIcon } from "../assets/icons/mwc-sequence.svg";
import { ReactComponent as SequenceAddIcon } from "../assets/icons/mwc-sequenceadd.svg";
import { ReactComponent as FieldUniqueIcon } from "../assets/icons/mwc-fieldunique.svg";
import { ReactComponent as FieldRequiredIcon } from "../assets/icons/mwc-fieldrequired.svg";
import { ReactComponent as FieldWatchedIcon } from "../assets/icons/mwc-fieldwatched.svg";
import { ReactComponent as FieldConfidentialIcon } from "../assets/icons/mwc-fieldconfidential.svg";
import { ReactComponent as IconAzure } from "../assets/icons/mwc-azure.svg";
import { ReactComponent as IconAgent } from "../assets/icons/mwc-agent.svg";
import { ReactComponent as IconFtp } from "../assets/icons/mwc-ftp.svg";
import { ReactComponent as IconCombined } from "../assets/icons/mwc-combo.svg";
import { ReactComponent as TrackHistory } from "../assets/icons/history-duotone.svg";
import { ReactComponent as SoucesCombined } from "../assets/icons/sourcescombined.svg";
import { ReactComponent as NotificationIcon } from "../assets/icons/notifications.svg";
import { ReactComponent as Publishing } from "../assets/icons/publishing.svg";

export const CONFIG_ICONS = {
  CHROMIUM: {
    DESCRIPTION: "Uses Chromium",
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fab", "chrome"],
  },
  IE: {
    DESCRIPTION: "Uses Internet Explorer",
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fab", "internet-explorer"],
  },
  PREMIUMHARVESTING: {
    DESCRIPTION: "Uses premium harvesting",
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fad", "gem"],
  },
  OUTOFSYNC_SCHEDULE: {
    DESCRIPTION: "Schedule out of sync with agent group schedule",
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fad", "calendar-day"],
  },
  SCHEDULE: {
    DESCRIPTION: "Runs on a schedule",
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fad", "calendar-day"],
  },

  PUBLISHFTP_OUTOFSYNC_NONE: {
    DESCRIPTION: "Associated agent group does not have publish settings",
    TYPE: IconTypes.CUSTOM,
    ICON: IconFtp,
    // ICON: PublishFtp-OutOfSync-None.png
  },
  PUBLISHFTP_OUTOFSYNC: {
    DESCRIPTION:
      "FTP publisher for this agent is out of sync with agent group publish",
    TYPE: IconTypes.CUSTOM,
    ICON: IconFtp,
    // ICON: PublishFtp-OutOfSync.png
  },
  PUBLISHFTP: {
    DESCRIPTION: "Publishes to FTP",
    TYPE: IconTypes.CUSTOM,
    ICON: IconFtp,
    // ICON: PublishFtp.png
  },

  PUBLISHEMAIL_OUTOFSYNC_NONE: {
    DESCRIPTION: "Associated agent group does not have publish settings",
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fad", "envelope"],
    // ICON: PublishEmail-OutOfSync-None.png
  },
  PUBLISHEMAIL_OUTOFSYNC: {
    DESCRIPTION:
      "Email publisher for this agent is out of sync with agent group publish",
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fad", "envelope"],
    // ICON: PublishEmail-OutOfSync.png
  },
  PUBLISHEMAIL: {
    DESCRIPTION: "Publishes to email",
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fad", "envelope"],
    // ICON: PublishEmail.png
  },

  PUBLISHAMAZON_OUTOFSYNC_NONE: {
    DESCRIPTION: "Associated agent group does not have publish settings",
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fab", "amazon"],
    // ICON: PublishAmazon-OutOfSync-None.png
  },
  PUBLISHAMAZON_OUTOFSYNC: {
    DESCRIPTION:
      "Amazon S3 publisher for this agent is out of sync with agent group publish",
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fab", "amazon"],
    // ICON: PublishAmazon-OutOfSync.png
  },
  PUBLISHAMAZON: {
    DESCRIPTION: "Publishes to Amazon S3",
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fab", "amazon"],
  },

  PUBLISHAZURESTORAGE_OUTOFSYNC_NONE: {
    DESCRIPTION: "Associated agent group does not have publish settings",
    TYPE: IconTypes.CUSTOM,
    ICON: IconAzure,
    // ICON: PublishAzureStorage-OutOfSync-None.png
  },
  PUBLISHAZURESTORAGE_OUTOFSYNC: {
    DESCRIPTION:
      "Microsoft Azure Storage publisher for this agent is out of sync with agent group publish",
    TYPE: IconTypes.CUSTOM,
    ICON: IconAzure,
    // ICON: PublishAzureStorage-OutOfSync.png
  },
  PUBLISHAZURESTORAGE: {
    DESCRIPTION: "Publishes to Microsoft Azure Storage",
    TYPE: IconTypes.CUSTOM,
    ICON: IconAzure,
    // ICON: PublishAzureStorage.png
  },

  PUBLISHDROPBOX_OUTOFSYNC_NONE: {
    DESCRIPTION: "Associated agent group does not have publish settings",
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fab", "dropbox"],
    // ICON: PublishDropbox-OutOfSync-None.png
  },
  PUBLISHDROPBOX_OUTOFSYNC: {
    DESCRIPTION:
      "Dropbox publisher for this agent is out of sync with agent group publish",
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fab", "dropbox"],
    // ICON: PublishDropbox-OutOfSync.png
  },
  PUBLISHDROPBOX: {
    DESCRIPTION: "Publishes to Dropbox",
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fab", "dropbox"],
  },

  PUBLISHGOOGLE_OUTOFSYNC_NONE: {
    DESCRIPTION: "Associated agent group does not have publish settings",
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fab", "google-drive"],
    // ICON: PublishGoogle-OutOfSync-None.png
  },
  PUBLISHGOOGLE_OUTOFSYNC: {
    DESCRIPTION:
      "Google Drive publisher for this agent is out of sync with agent group publish",
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fab", "google-drive"],
    // ICON: PublishGoogle-OutOfSync.png
  },
  PUBLISHGOOGLE: {
    DESCRIPTION: "Publishes to Google Drive",
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fab", "google-drive"],
  },

  PUBLISHWRANGLE_OUTOFSYNC_NONE: {
    DESCRIPTION: "Associated agent group does not have publish settings",
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fad", "starfighter-alt"],
    // ICON: PublishWrangle-OutOfSync-None.png
  },
  PUBLISHWRANGLE_OUTOFSYNC: {
    DESCRIPTION:
      "Data Wrangler publisher for this agent is out of sync with agent group publish",
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fad", "starfighter-alt"],
    // ICON: PublishWrangle-OutOfSync.png
  },
  PUBLISHWRANGLE: {
    DESCRIPTION: "Publishes to Data Wrangling",
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fad", "starfighter-alt"],
    // ICON: PublishWrangle.png
  },

  PUBLISH_OUTOFSYNC_NONE: {
    DESCRIPTION: "Associated agent group does not have publish settings",
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fad", "cloud-upload-alt"],
    // ICON: PublishFtp-OutOfSync-None.png
  },
  PUBLISH_OUTOFSYNC: {
    DESCRIPTION: "Configured to publish",
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fad", "cloud-upload-alt"],
    // ICON: PublishFtp-OutOfSync.png
  },
  PUBLISH: {
    DESCRIPTION: "Configured to publish",
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fad", "cloud-upload-alt"],
    // ICON: PublishFtp.png
  },
  OUTOFSYNC: {
    DESCRIPTION:
      "Agent publish settings are out of sync with agent group publish settings",
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fad", "starfighter-alt"],
    // ICON: PublishFtp-OutOfSync.png
  },

  NOTIFICATION_OUTOFSYNC: {
    DESCRIPTION:
      "Notification settings for this agent are out of sync with agent group notifications",
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fad", "bell"],
    // ICON: Notification-OutOfSync.png
  },
  NOTIFICATION: {
    DESCRIPTION: "Notifications set on this item",
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fad", "bell"],
    // ICON: Notification.png"
  },
  SETTINGS_OUTOFSYNC: {
    DESCRIPTION:
      "Settings in this agent are out of sync with associated agent group settings",
    TYPE: IconTypes.CUSTOM,
    ICON: IconAgent,
    // ICON: Settings-OutOfSync.png
  },
  SOURCESCOMBINED: {
    DESCRIPTION: "Sources a combined collection",
    TYPE: IconTypes.CUSTOM,
    ICON: IconCombined,
    // ICON: SourcesCombined.png"
  },
  TRACKHISTORY: {
    DESCRIPTION: "Agent stores item history",
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["far", "history"],
  },
  AGENTBUILDERCOMPATIBILITY: {
    DESCRIPTION: "Last saved using an older version of the Agent Builder",
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fad", "exclamation-triangle"],
  },
  AUTOBLOCKDOMAINLIST: {
    DESCRIPTION: "Automatically blocks navigation requests",
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fad", "shield-check"],
  },
  PUBLISHRSS: {
    DESCRIPTION: "Publishes to RSS feed",
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fad", "rss-square"],
    // ICON: PublishRss.png"
  },
  SOURCESDISTRIBUTED: {
    DESCRIPTION: "Sources a distributed collection",
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fad", "starfighter-alt"],
    // ICON: SourcesDistributed.png"
  },
};

export const FIELDS_CONFIG_ICONS = {
  ISUNIQUE: {
    DESCRIPTION: "Unique field",
    TYPE: IconTypes.CUSTOM,
    ICON: FieldUniqueIcon,
    COLOR: variables["color-warning"],
  },
  ISREQUIRED: {
    DESCRIPTION: "Required field",
    TYPE: IconTypes.CUSTOM,
    ICON: FieldRequiredIcon,
    COLOR: variables["color-danger"],
  },
  ISWATCHED: {
    DESCRIPTION: "Watched field",
    TYPE: IconTypes.CUSTOM,
    ICON: FieldWatchedIcon,
    COLOR: variables["color-spacecadet"],
  },
  ISCONFIDENTIAL: {
    DESCRIPTION: "Confidential field",
    TYPE: IconTypes.CUSTOM,
    ICON: FieldConfidentialIcon,
    COLOR: variables["color-danger"],
  },
  DEFAULTVALUE: {
    DESCRIPTION: "Default value: ",
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["far", "text"],
    COLOR: variables["color-spacecadet"],
  },
};

export const SEQUENCE_CONFIG_ICONS = {
  SCHEDULE: {
    DESCRIPTION: "Runs on a schedule",
    TYPE: IconTypes.CUSTOM,
    ICON: SequenceAddIcon,
  },
  AGENTRUN: {
    DESCRIPTION: "Runs agents",
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fad", "play-circle"],
  },
  PUBLISHDATA: {
    DESCRIPTION: "Publishes data",
    TYPE: IconTypes.CUSTOM,
    ICON: SequenceAddIcon,
  },
  COLLECTIONCLEAR: {
    DESCRIPTION: "Clears collection data",
    TYPE: IconTypes.CUSTOM,
    ICON: SequenceAddIcon,
  },
  UPDATEFIELDVALUE: {
    DESCRIPTION: "Updates field values",
    TYPE: IconTypes.CUSTOM,
    ICON: SequenceAddIcon,
  },
  VIEWDELETEITEMS: {
    DESCRIPTION: "Deletes view items",
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fad", "times-circle"],
  },
  SEQUENCERUN: {
    DESCRIPTION: "Run another sequence",
    TYPE: IconTypes.CUSTOM,
    ICON: SequenceAddIcon,
  },
  TRACKHISTORY: {
    DESCRIPTION: "Agent stores item history",
    TYPE: IconTypes.CUSTOM,
    ICON: TrackHistory,
  },
  SOURCESCOMBINED: {
    DESCRIPTION: "Sources a combined collection",
    TYPE: IconTypes.CUSTOM,
    ICON: SoucesCombined,
  },
  NOTIFICATIONS: {
    DESCRIPTION:
      "Notifications settings for this agent are out of sync with agent group notifications",
    TYPE: IconTypes.CUSTOM,
    ICON: NotificationIcon,
  },
  PUBLISHEMAIL: {
    DESCRIPTION: "Publishes to email",
    TYPE: IconTypes.CUSTOM,
    ICON: Publishing,
  },
};

export const SEQUENCE_STEPS_AGENTRUN_CONFIG = {
  RESUMEERRORS: {
    DESCRIPTION: "Resumes jobs on error",
    TYPE: IconTypes.CUSTOM,
    ICON: SequenceIcon,
  },
  CONCURRENTJOBS: {
    DESCRIPTION: "Resumes concurrent jobs",
    TYPE: IconTypes.CUSTOM,
    ICON: SequenceIcon,
  },
  ERRORCODES: {
    DESCRIPTION: "Resumes specific error codes",
    TYPE: IconTypes.CUSTOM,
    ICON: SequenceIcon,
  },
  PARAMETERS: {
    DESCRIPTION: "Has step parameters",
    TYPE: IconTypes.CUSTOM,
    ICON: SequenceIcon,
  },
};

export const SEQUENCE_STEPS_UPDATEFIELDVALUE_CONFIG = {
  LIMIT: {
    DESCRIPTION: "Updates a maximum number of items",
    TYPE: IconTypes.CUSTOM,
    ICON: SequenceIcon,
  },
  PUBLISHEMAIL: { ...CONFIG_ICONS.PUBLISHEMAIL },
};

export const SEQUENCE_STEPS_PUBLISHDATA_CONFIG = {
  PUBLISHFTP: { ...CONFIG_ICONS.PUBLISHFTP },
  PUBLISHEMAIL: { ...CONFIG_ICONS.PUBLISHEMAIL },
  PUBLISHAMAZON: { ...CONFIG_ICONS.PUBLISHAMAZON },
  PUBLISHAZURESTORAGE: { ...CONFIG_ICONS.PUBLISHAZURESTORAGE },
  PUBLISHDROPBOX: { ...CONFIG_ICONS.PUBLISHDROPBOX },
  PUBLISHGOOGLE: { ...CONFIG_ICONS.PUBLISHGOOGLE },
  PUBLISHWRANGLE: { ...CONFIG_ICONS.PUBLISHWRANGLE },
  PUBLISH: { ...CONFIG_ICONS.PUBLISH },
};

export const DEPARTMENT_CONFIG_ICONS = {
  WEBPAGECREDITBYALLOCATION: {
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fas", "exclamation-triangle"],
    COLOR: variables["color-warning"],
  },
  WEBPAGECREDITBYDROP: {
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fas", "exclamation-triangle"],
    COLOR: variables["color-warning"],
  },
};
