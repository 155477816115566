import {
  SET_AGENT_GROUPS,
  SET_AGENTGROUPS_AGENTS,
  SET_AGENTGROUPS_DATA,
  SET_AGENTGROUPS_FIELDS,
  SET_AGENTGROUPS_SUMMARY,
  SET_AGENTGROUPS_AGENT_INFO,
  UPDATE_AGENT_GROUP_AGENTS_STATE_SEARCH,
  UPDATE_AGENTGROUPS_DATA_SEARCH,
  SET_AGENT_GROUPS_DATA_SAVED_VIEWS,
  SET_AGENT_GROUPS_SAVED_VIEWS,
  DELETE_AGENT_GROUPS_DATA_VIEW,
  UPDATE_AGENT_GROUPS_DATA_VIEW_ROW,
  SET_TEMPLATE_AGENTCOMBOBOX,
  SET_ASSIGN_AGENTGROUP_AGENTS,
  SET_ASSIGN_AGENTGROUP_VIEWS,
  AGENTGROUP_DATA_UPDATEVIEWTABLE,
  UPDATE_AGENTSGROUP_DATA_VIEW_ROW,
  DELETE_AGENTSGROUP_DATA_VIEW_ROW,
  AGENTGROUP_AGENTS_UPDATEVIEWTABLE,
} from "../types";
import createReducer from "../createReducer";

const initialState = {
  agentGroupsList: [],
  agentInfo: {},
  agentsColumns: [],
  agentsCollectionState: {},
  agentsViewRow: {},
  agentsSortedBy: [],
  agentsViewFields: [],
  dataColumns: [],
  dataCollectionState: {},
  dataViewRow: {},
  dataSortedBy: [],
  agentFilterItems: [],
  agentGroupsFieldsColumns: [],
  agentGroupsSummaryStats: [],
  dataCollectionInfo: null,
  dataSavedViews: [],
  agentsSavedViews: null,
  dataList: null,
  assignAgentsColumns: [],
  assignAgentsCollectionState: {},
  assignAgentsViewRow: {},
  assignAgentsSortedBy: [],
  assignAgentsViewFields: [],
  assignAgentsSavedViews: null,
};

export const agentGroups = createReducer(initialState, {
  [SET_AGENT_GROUPS](state, { payload }) {
    return {
      ...state,
      agentGroupsList: payload.list,
    };
  },
  [UPDATE_AGENTSGROUP_DATA_VIEW_ROW](state, { viewId }) {
    return {
      ...state,
      dataViewRow: {
        ...state.dataViewRow,
        ViewID: viewId,
      },
    };
  },
  [SET_ASSIGN_AGENTGROUP_AGENTS](state, { payload }) {
    return {
      ...state,
      assignAgentsColumns: payload.columns,
      assignAgentsCollectionState: payload.collectionState,
      assignAgentsViewRow: payload.viewRow,
      assignAgentsSortedBy: payload.sortedBy,
      assignAgentsViewFields: payload.viewFields,
    };
  },
  [SET_ASSIGN_AGENTGROUP_VIEWS](state, { payload }) {
    return {
      ...state,
      assignAgentsSavedViews: payload.savedViews,
    };
  },
  [SET_AGENTGROUPS_AGENT_INFO](state, { payload }) {
    return {
      ...state,
      agentInfo: payload.agentInfo,
    };
  },

  [DELETE_AGENTSGROUP_DATA_VIEW_ROW](state, { viewId }) {
    return {
      ...state,
      dataSavedViews: state.dataSavedViews.filter((savedView) => {
        return savedView.ViewID !== viewId;
      }),
    };
  },
  [SET_AGENTGROUPS_AGENTS](state, { payload }) {
    return {
      ...state,
      agentsColumns: payload.columns,
      agentsCollectionState: payload.collectionState,
      agentsViewRow: payload.viewRow,
      agentsSortedBy: payload.sortedBy,
      agentsViewFields: payload.viewFields.map((viewField) => {
        return {
          name: viewField.Name,
          value: viewField.Name,
          fieldId: viewField.FieldID,
        };
      }),
    };
  },
  [AGENTGROUP_DATA_UPDATEVIEWTABLE](state, { newViews }) {
    return {
      ...state,
      dataSavedViews: newViews,
    };
  },
  [AGENTGROUP_AGENTS_UPDATEVIEWTABLE](state, { newViews }) {
    return {
      ...state,
      agentsSavedViews: newViews,
    };
  },

  [SET_AGENTGROUPS_DATA](state, { payload }) {
    return {
      ...state,
      dataColumns: payload.columns,
      dataCollectionState: payload.collectionState,
      dataCollectionInfo: payload.collectionInfo,
      dataViewRow: payload.viewRow,
      dataSortedBy: payload.sortedBy,
      agentFilterItems: payload.agentFilterItems,
      dataViewFields: payload.viewFields.map((viewField) => {
        return {
          name: viewField.Name,
          value: viewField.Name,
          fieldId: viewField.FieldID,
        };
      }),
    };
  },
  [SET_TEMPLATE_AGENTCOMBOBOX](state, { payload }) {
    return {
      ...state,
      dataList: payload.agentGroupDataList,
    };
  },

  [SET_AGENT_GROUPS_DATA_SAVED_VIEWS](state, { payload }) {
    return {
      ...state,
      dataSavedViews: payload.savedViews,
    };
  },
  [SET_AGENT_GROUPS_SAVED_VIEWS](state, { payload }) {
    return {
      ...state,
      agentsSavedViews: payload.savedViews.map((el) => {
        return {
          ...el,
          name: el.Name,
          value: el.Name,
        };
      }),
    };
  },

  [DELETE_AGENT_GROUPS_DATA_VIEW](state, { viewId }) {
    return {
      ...state,
      dataSavedViews: state.dataSavedViews.filter((savedView) => {
        return savedView.ViewID !== viewId;
      }),
    };
  },

  [UPDATE_AGENT_GROUPS_DATA_VIEW_ROW](state, { viewId }) {
    return {
      ...state,
      dataViewRow: {
        ...state.dataViewRow,
        ViewID: viewId,
      },
    };
  },

  [SET_AGENTGROUPS_FIELDS](state, { payload }) {
    return {
      ...state,
      agentGroupsFieldsColumns: payload.columns,
    };
  },
  [SET_AGENTGROUPS_SUMMARY](state, { payload }) {
    return {
      ...state,
      agentGroupsSummaryStats: payload.stats,
    };
  },
  [UPDATE_AGENT_GROUP_AGENTS_STATE_SEARCH](state, { searchFilterValues }) {
    return {
      ...state,
      agentsCollectionState: {
        ...state.agentsCollectionState,
        SearchText: searchFilterValues.searchText,
        SearchField:
          searchFilterValues.searchField !== "View Fields"
            ? searchFilterValues.searchField
            : "AllViewFields",
      },
    };
  },
  [UPDATE_AGENTGROUPS_DATA_SEARCH](state, { searchFilterValues }) {
    return {
      ...state,
      dataCollectionState: {
        ...state.dataCollectionState,
        SearchText: searchFilterValues.searchText,
        SearchField:
          searchFilterValues.searchField !== "View Fields"
            ? searchFilterValues.searchField
            : "AllViewFields",
      },
    };
  },
});
