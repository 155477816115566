export const REGEX = {
  REGEX_NAME: /^[a-zA-Z0-9-]{1,40}$/,
  REGEX_FIELD_NAME: /^\s*[a-zA-Z0-9-][a-zA-Z0-9 -]{0,49}\s*$/,
  REGEX_DESCRIPTION: /^(.|\s){0,100}$/,
  REGEX_VIEW_NAME: /^(.|\s){0,40}$/,
  REGEX_COMPANY: /^[a-zA-Z0-9-]{1,50}$/,
  REGEX_PHONE: /^(\+){0,1}[a-zA-Z0-9-]{7,20}$/,
  REGEX_EMAIL: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
  REGEX_FAX: /^(\+){0,1}[a-zA-Z0-9-]{7,20}$/,
  REGEX_ZIP: /^[a-zA-Z0-9-]{5,11}$/,
  REGEX_STATE: /^[a-zA-Z]{2}$/,
  REGEX_FILE_NAME: /^[^?*:|"<>]*$/,
  REGEX_URL_HAS_PROTOCOL: /^http/i,
  REGEX_VALID_DOMAIN: /.{1,500}\.[a-zA-Z]{2,63}$/,
  REGEX_COPY_TARGET: /<\/?[a-zA-Z]+\/?>/g,
  REGEX_IMPORT_FILE_EXTENSIONS_ALLOWED: /\.(csv|tsv|txt)$/i,
  REGEX_CREDIT_CARD: /^[0-9]{13,16}$/,
  REGEX_CARD_NAME: /\w+\s+\w+/,
};
