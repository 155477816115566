import { ROUTES } from "./routes";
import { FEATURES } from "./features";
import {
  JOB_STATUS,
  AGENT_JOB_STATUS,
  JOB_STATUS_POLLING_INTERVAL,
} from "./jobs";
import {
  USER_SETTINGS,
  AGENT_SETTINGS,
  ACCOUNT_SETTINGS,
  PUBLISHER_SETTINGS,
  RATE_PLAN_SETTINGS,
} from "./settings";
import {
  COLLECTION_TYPE,
  SYSTEM_COLLECTIONS,
  MASTER_COLLECTIONS,
  COMMON_COLLECTIONS,
  INSIGHTS_COLLECTIONS,
  GROUP_COLLECTIONS,
} from "./collections";
import { SYSTEM_FIELDS, HIDE_FIELDS, CUSTOM_FIELD_STATUS } from "./fields";
import { NEW_VIEW_RSSFEED_COLUMNS, COMMON_VIEW_NAMES } from "./views";
import { USER_TYPES, ACCOUNT_PERMISSIONS } from "./permissions";
import { REGEX } from "./regex";
import {
  CONFIG_ICONS,
  FIELDS_CONFIG_ICONS,
  SEQUENCE_CONFIG_ICONS,
  SEQUENCE_STEPS_AGENTRUN_CONFIG,
  SEQUENCE_STEPS_UPDATEFIELDVALUE_CONFIG,
  SEQUENCE_STEPS_PUBLISHDATA_CONFIG,
} from "./config";
import {
  USERS_STATUS,
  USERS_AGENTS,
  USERS_ACCOUNT_PERMISSIONS,
} from "./accounts";
import { TABS } from "./tabs";
import { RESPONSE_CONTEXT } from "./responseContext";
import { COMMANDS } from "./commands";
import { STORE_NAMES, STORE_IDS, STORE_FILTER_ID } from "./senchaStores";
import { COUNTRIES } from "./countries";
import { EVENTS } from "./events";
import { NOTIFICATIONS } from "./notifications";
import { GRID_TYPES } from "./gridType";
import { SECTION } from "./section";
import { CLEAR_SETTINGS } from "./clearSettings";
import { CLEAR_COLLECTION } from "./clearCollection";

export const CONSTANTS = {
  INTERCOM_APP_ID: "g37v7n3o",
  LOGIN_RESPONSE_FLAG: "Login",
  ROUTES,
  COMMANDS,
  COUNTRIES,
  FEATURES,
  NOTIFICATIONS,
  SETTINGS: {
    USER: USER_SETTINGS,
    AGENT: AGENT_SETTINGS,
    ACCOUNT: ACCOUNT_SETTINGS,
    PUBLISHER: PUBLISHER_SETTINGS,
    RATE_PLAN: RATE_PLAN_SETTINGS,
  },
  COLLECTIONS: {
    TYPE: COLLECTION_TYPE,
    SYSTEM: SYSTEM_COLLECTIONS,
    MASTER: MASTER_COLLECTIONS,
    COMMON: COMMON_COLLECTIONS,
    INSIGHTS: INSIGHTS_COLLECTIONS,
    GROUP: GROUP_COLLECTIONS,
  },
  FIELDS: SYSTEM_FIELDS,
  HIDE_FIELDS,
  CUSTOM_FIELD_STATUS,
  PERMISSIONS: {
    USER_TYPES,
    ACCOUNT_PERMISSIONS,
  },
  REGEX,
  JOB_STATUS,
  AGENT_JOB_STATUS,
  POLLING: {
    JOB_STATUS_POLLING_INTERVAL,
  },
  CONFIG_CELL_ICONS: {
    DEFAULT: CONFIG_ICONS,
    FIELDS: FIELDS_CONFIG_ICONS,
    SEQUENCE: SEQUENCE_CONFIG_ICONS,
    SEQUENCE_STEPS: {
      AGENT_RUN: SEQUENCE_STEPS_AGENTRUN_CONFIG,
      UPDATEFIELDVALUE: SEQUENCE_STEPS_UPDATEFIELDVALUE_CONFIG,
      PUBLISHDATA: SEQUENCE_STEPS_PUBLISHDATA_CONFIG,
    },
  },
  ACCOUNTS: {
    USERS_STATUS,
    USERS_AGENTS,
    USERS_ACCOUNT_PERMISSIONS,
  },
  TABS,
  RESPONSE_CONTEXT,
  VIEWS: {
    RSSFEED_COLUMNS: NEW_VIEW_RSSFEED_COLUMNS,
    COMMON_NAMES: COMMON_VIEW_NAMES,
  },
  STORES: {
    NAMES: STORE_NAMES,
    IDS: STORE_IDS,
    FILTERS: STORE_FILTER_ID,
  },
  ANIMATION: {
    MAXIMUM_TRANSITION_TIME: "390",
  },
  JOBS_LIMIT_MAX: 200,
  MAX_CONCURRENT_JOBS_DEFAULT: 20,
  CLEAR_SETTINGS,
  CLEAR_COLLECTION,
  EVENTS: EVENTS,
  GRID_TYPES,
  SECTION: SECTION,
};
