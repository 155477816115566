import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import variables from "../../assets/sass/_variables.scss";

const styles = {
  rootPrimary: {
    borderColor: variables["color-mozorange"],
    fontFamily: variables["fontfamily-default"],
    "& $label": {
      color: variables["color-mozorange"],
    },
    "& $startIcon": {
      color: variables["color-mozorange"],
    },
    "& $endIcon": {
      color: variables["color-mozorange"],
    },
  },
  rootSecondary: {
    borderColor: variables["color-spacecadet"],
    fontFamily: variables["fontfamily-default"],
    "& $label": {
      color: variables["color-spacecadet"],
    },
    "& $startIcon": {
      color: variables["color-spacecadet"],
    },
    "& $endIcon": {
      color: variables["color-spacecadet"],
    },
  },
  disabled: {
    color: variables["color-lightgray"],
  },
  label: {},
  startIcon: {},
  endIcon: {},
};

const ButtonOutlined = withStyles(styles)((props) => {
  const {
    type,
    size,
    label,
    disabled,
    onClick,
    startIcon,
    endIcon,
    classes,
    buttonType,
  } = props;

  return (
    <Button
      type={buttonType}
      variant="outlined"
      size={size}
      disableElevation
      disabled={disabled}
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      classes={{
        root: disabled
          ? classes.disabled
          : type === "secondary"
          ? classes.rootSecondary
          : classes.rootPrimary,
        label: classes.label,
        startIcon: classes.startIcon,
        endIcon: classes.endIcon,
      }}
    >
      {label}
    </Button>
  );
});

ButtonOutlined.propTypes = {
  /** event handler on element click or tap */
  onClick: PropTypes.func,
  /** disables button if true */
  disabled: PropTypes.bool,
  /** SVG or font icon  */
  startIcon: PropTypes.object,
  /** SVG or font icon */
  endIcon: PropTypes.object,
  /** button text */
  label: PropTypes.string,
  /** button size */
  size: PropTypes.oneOf(["small", "medium", "large"]),
  /** button type */
  type: PropTypes.oneOf(["primary", "secondary"]),
};

ButtonOutlined.defaultProps = {
  disabled: false,
  size: "medium",
  type: "primary",
  buttonType: "button",
};

export default ButtonOutlined;
