import {
  GET_AGENT_HISTORY,
  SET_AGENT_HISTORY,
  AGENT_INFO_LOADED,
  SET_AGENTS_HISTORY_SAVED_VIEWS,
  GET_AGENT_HISTORY_SAVED_VIEWS,
  GET_AGENT_HISTORY_BOOKMARKS,
  SET_AGENT_HISTORY_BOOKMARKS,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import {
  takeLatest,
  put,
  call,
  select,
  take,
  cancel,
} from "redux-saga/effects";
import {
  renderItemHistoryCell,
  renderMarkupChangeCell,
} from "../../Components/Grid/SenchaEditableLockedGrid/renderers";
import axios from "axios";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";

const Ext = window["Ext"];

function prepareModel(data, store) {
  const model = store.config.model;

  model.setFields(data);

  return model;
}

function getStoreData(store, params, PageIndex) {
  return new Promise((resolve) => {
    store.baseParams = {
      ...params,
    };

    store.getProxy().extraParams = store.baseParams;
    store.loadPage(PageIndex === 0 ? 1 : PageIndex, {
      params,
      callback: (records) => {
        resolve(records);
      },
    });
  });
}

function prepareGridColumns(data, storeData, callback, ShowMarkup) {
  if (storeData.length === 0) {
    return data.ViewFields.map((field) => ({
      dataIndex: field.Name,
      text: `<span title='${field.Name}'>${field.Name}</span>`,
      locked: field.IsSticky,
      hidden: field.IsHidden,
      width: field.ColumnWidth,
      editable: !field.IsReadOnly && !field.IsSystem,
      cell: {
        height: 50,
      },
    }));
  }

  function highlightClass(field, fieldNames) {
    const selectedItemIndex = fieldNames.findIndex(
      (item) => item === field.Name
    );
    return selectedItemIndex > -1 ? "not-a-template-field" : "";
  }

  const agentFields = Object.keys(storeData[0].data);
  const viewFields = data.ViewFields;
  let missingAgentFieldNames = [];

  for (let i = 0; i < viewFields.length; i++) {
    const viewFieldName = viewFields[i].Name.toLowerCase();
    let foundField = false;

    for (let index = 0; index < agentFields.length; index++) {
      const agentFieldName = agentFields[index].toLowerCase();
      if (agentFieldName === viewFieldName) {
        foundField = true;
        break;
      }
    }

    if (!foundField) missingAgentFieldNames.push(viewFields[i].Name);
  }

  let columnItems = [];
  columnItems.push({
    dataIndex: "history",
    locked: true,
    width: 50,
    editable: false,
    menuDisabled: true,
    resizable: false,
    sortable: false,
    cell: {
      encodeHtml: true,
      xtype: "reactcell",
    },
    renderer: (value, record) => renderItemHistoryCell(value, record, callback),
  });

  columnItems = [
    ...columnItems,
    ...data.ViewFields.map((field) => ({
      dataIndex: field.Name,
      text: `<span title='${field.Name}'>${field.Name}</span>`,
      locked: field.IsSticky,
      hidden: field.IsHidden,
      width: field.ColumnWidth,
      editable:
        !field.IsReadOnly &&
        !field.IsSystem &&
        !(ShowMarkup && field.IsWatched),
      cls: highlightClass(field, missingAgentFieldNames),
      cell: {
        height: 50,
        xtype: "reactcell",
      },
      renderer: (value, record) =>
        renderMarkupChangeCell(
          record,
          field,
          data.Collection.CollectionID,
          callback,
          field.IsWatched
        ),
    })),
  ];

  return columnItems;
}

function* createStore(storeId, data, formParams = {}, callback) {
  const store = Ext.getStore(storeId);

  if (store) {
    const model = prepareModel(data, store);

    store.removeAll(true);
    store.setModel(model);

    const {
      SearchText,
      SearchField,
      CollectionID,
      PageIndex,
    } = data.CollectionState;

    const params = {
      CollectionID,
      ViewID: data.ViewRow.ViewID,
      FolderID: "",
      AccountKey: "",
      AgentID: data.AgentID,
      BookmarkID: formParams.BookmarkID,
      JobID: -1,
      ItemHistoryRangeStart: "",
      ItemHistoryRangeEnd: "",
      ItemStatusesToInclude:
        formParams.ItemStatusesToInclude || "Added, Changed, Unchanged",
      ColumnsToAlwaysInclude: "Name",
      ForceViewSortable: "",
      AlwaysIncludeCustomFields: false,
      IncludeAdditionalCustomInterfaceColumns: "",
      SelectedIDs: "",
      ShowHistoryColumn: true,
      ShowSelected: false,
      TemplateCollectionID: 0,
      SearchText,
      SearchField,
      ShowMarkup: formParams.ShowMarkup || false,
      Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
    };

    const storeData = yield call(getStoreData, store, params, PageIndex);
    const columns = prepareGridColumns(
      data,
      storeData,
      callback,
      formParams.ShowMarkup || false
    );

    yield put({
      type: SET_AGENT_HISTORY,
      payload: {
        columns,
        collectionState: data.CollectionState,
        agentDataViewRow: data.ViewRow,
        viewFields: data.ViewFields,
        viewRow: data.ViewRow,
      },
    });
  }
}

function* fetchAgentHistory({
  type: action,
  storeId,
  agentId,
  formParams = {},
  callback,
}) {
  const agentInfo = yield select((state) => state.agents.agentInfo);
  if (!agentInfo.ItemID || agentInfo.ItemID !== Number(agentId)) {
    yield take(AGENT_INFO_LOADED);
  }

  const collectionId = yield select(
    (state) => state.agents.agentInfo.CollectionID
  );

  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETCOMPLETE,
    CollectionID: collectionId,
    AccountKey: "",
    ...formParams,
    ViewID: formParams.ViewID || "",
    BookmarkID: formParams.BookmarkID || 0,
  };
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams(params),
      {
        headers: { action },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    yield call(createStore, storeId, data, params, callback);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getSavedViews({ type: action, agentId }) {
  const agentInfo = yield select((state) => state.agents.agentInfo);

  if (!agentInfo.ItemID || agentInfo.ItemID !== Number(agentId)) {
    yield take(AGENT_INFO_LOADED);
  }

  const collectionId = yield select(
    ({ agents }) => agents.agentInfo.CollectionID
  );

  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETVIEWS,
    CollectionID: collectionId,
    AccountKey: "",
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETVIEWS,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    yield put({
      type: SET_AGENTS_HISTORY_SAVED_VIEWS,
      payload: {
        savedViews: data.ViewTable || [],
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getHistoryBookmarks({ type: action, AgentID }) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.AGENT_GETBOOKMARKS_COMBOBOX_OPTIONS,
      new URLSearchParams({
        MaxBookmarks: 1000,
        AgentID,
        Command: CONSTANTS.COMMANDS.AGENT_GETBOOKMARKS_COMBOBOX_OPTIONS,
      }),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    yield put({
      type: SET_AGENT_HISTORY_BOOKMARKS,
      payload: {
        agentHistoryBookMarks: data.Rows,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

export default function* agentHistoryWatcher() {
  yield takeLatest(GET_AGENT_HISTORY, fetchAgentHistory);
  yield takeLatest(GET_AGENT_HISTORY_SAVED_VIEWS, getSavedViews);
  yield takeLatest(GET_AGENT_HISTORY_BOOKMARKS, getHistoryBookmarks);
}
