import {
  GET_SEQUENCE_STEPS,
  SET_SEQUENCE_STEPS,
  GET_SEQUENCE_INFO,
  SET_SEQUENCE_INFO,
  SET_STEPS_TO_RUN_CONCURRENTLY,
  ADD_STEP_TO_EXISTING_GROUP,
  RUN_SELECTED_STEPS_CONCURRENTLY,
  REMOVE_SELECTED_STEPS_FROM_GROUP,
  START_AT_SELECTED_GROUP,
  GET_DELETE_VIEW_ITEMS,
  SET_DELETE_VIEW_ITEMS,
  DELETE_VIEW_ITEM,
  GET_SEQUENCE_STEPS_FIELDS,
  SET_SEQUENCE_STEPS_FIELDS,
  UPDATE_FIELD_DATA,
  GET_SEQUENCES_TO_SELECT,
  SET_SEQUENCES_TO_SELECT,
  SET_SEQUENCES_TO_SELECT_VIEWS,
  GET_SEQUENCES_TO_SELECT_VIEWS,
  RUN_SEQUENCE_STEP,
  MOVE_STEP_DRAG,
  GET_SEQUENCE_AGENT,
  GET_STEP_INFO,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import { takeLatest, put, call, cancel } from "redux-saga/effects";
import {
  mapSequenceStepCell,
  mapSequenceStepConfigCell,
  mapSequenceStepStatusCell,
  mapSequenceStepProgressCell,
} from "../../Components/Grid/SenchaLockedGrid/mappers";
import {
  renderSequenceStepCell,
  renderConfigCell,
  renderSequenceStepStatusCell,
  renderSequenceStepProgressCell,
} from "../../Components/Grid/SenchaLockedGrid/renderers";
import axios from "axios";
import { GROUP_COLLECTIONS } from "../../constants/collections";
import { COMMANDS } from "../../constants/commands";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";

const Ext = window["Ext"];

function prepareModel(data, store) {
  const model = store.config.model;

  model.setFields(data);

  return model;
}

function prepareGridColumns(data, stepCellCb) {
  const headerTexts = {
    StepNumberID: "#",
  };

  const renderers = {
    Step: {
      mapper: mapSequenceStepCell,
      renderer: renderSequenceStepCell,
      callback: stepCellCb,
    },
    Config: {
      mapper: mapSequenceStepConfigCell,
      renderer: renderConfigCell,
    },
    Status: {
      mapper: mapSequenceStepStatusCell,
      renderer: renderSequenceStepStatusCell,
    },
    Progress: {
      mapper: mapSequenceStepProgressCell,
      renderer: renderSequenceStepProgressCell,
    },
  };

  return data.ViewFields.map((field) => {
    const headerText = headerTexts[field.Name] || field.Name;

    if (renderers[field.Name]) {
      return {
        dataIndex: headerText,
        text: `<span title='${headerText}'>${headerText}</span>`,
        locked: field.IsSticky,
        hidden: field.IsHidden,
        width: field.ColumnWidth,
        sortable: false,
        cell: {
          height: 50,
          encodeHtml: true,
          xtype: "reactcell",
        },
        renderer: (value, record) =>
          renderers[field.Name].renderer(
            renderers[field.Name].mapper,
            value,
            record,
            renderers[field.Name].callback
          ),
      };
    }

    return {
      dataIndex: headerText,
      text: `<span title='${headerText}'>${headerText}</span>`,
      locked: field.IsSticky,
      hidden: field.IsHidden,
      width: field.ColumnWidth,
      sortable: false,
      cell: {
        height: 50,
      },
    };
  });
}

function* createStore(
  storeId,
  data,
  stepCellCb,
  sequenceId,
  filteredData = {}
) {
  const store = Ext.getStore(storeId);
  if (store) {
    const model = prepareModel(data, store);

    const { SearchText } = data.CollectionState;

    store.removeAll(true);
    store.setModel(model);

    store.load({
      params: {
        Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
        CollectionID: CONSTANTS.COLLECTIONS.GROUP.SEQUENCE_DETAILS,
        ViewID: data.ViewRow.ViewID,
        AgentID: data.AgentID,
        ContextRestrictionFieldNames: "SequenceID",
        ContextRestrictionFieldValues: sequenceId,
        ColumnsToAlwaysInclude:
          "Settings,ResumeErrorCodes,ResumeErrorCount,ConcurrentJobs,Group,StepType,StepItemID,Step,StepNumberID,AdditionalStepItemID,Limit",
        SearchText,
        SearchField: "AllViewFields",
        ...filteredData,
      },
    });
  }

  const columns = prepareGridColumns(data, stepCellCb);

  yield put({
    type: SET_SEQUENCE_STEPS,
    payload: {
      columns,
      collectionState: data.CollectionState,
    },
  });
}

function* fetchSequenceSteps({
  type: action,
  stepCellCb,
  storeId,
  sequenceId,
  formParams = {},
}) {
  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETCOMPLETE,
    CollectionID: CONSTANTS.COLLECTIONS.GROUP.SEQUENCE_DETAILS,
    ...formParams,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    yield call(createStore, storeId, data, stepCellCb, sequenceId);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* fetchSequenceInfo({ type: action, sequenceId }) {
  const params = {
    Command: CONSTANTS.COMMANDS.SEQUENCE_GET,
    SequenceID: sequenceId,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.SEQUENCE_GET,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    yield put({
      type: SET_SEQUENCE_INFO,
      payload: {
        info: data.Sequence || {},
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function refreshGrid(params) {
  window["Ext"].getStore(CONSTANTS.STORES.IDS.SEQUENCES_STEPS).load({
    params: {
      CollectionID: GROUP_COLLECTIONS.SEQUENCE_DETAILS,
      ContextRestrictionFieldNames: "SequenceID",
      ContextRestrictionFieldValues: params.SequenceID,
      ColumnsToAlwaysInclude:
        "Settings,ResumeErrorCodes,ResumeErrorCount,ConcurrentJobs,Group,StepType,StepItemID,Step,StepNumberID,AdditionalStepItemID,Limit",
    },
  });
}

function* runStepsConcurrently({ params }) {
  try {
    yield axios.post(
      CONSTANTS.ROUTES.SEQUENCE_SET_STEPS_TO_RUN_CONCURRENTLY,
      new URLSearchParams(params)
    );

    yield call(refreshGrid);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* addStepToExistingGroup({ params }) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.ADD_STEP_TO_EXISTING_GROUP,
      new URLSearchParams(params)
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    yield call(refreshGrid, params);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* runSelectedStepsConcurrently({ params }) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.SEQUENCE_SET_STEPS_TO_RUN_CONCURRENTLY,
      new URLSearchParams(params)
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    yield call(refreshGrid, params);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* removeSelectedStepsFromGroup({ params }) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.REMOVE_SELECTED_STEPS_FROM_GROUP,
      new URLSearchParams(params)
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    yield call(refreshGrid, params);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* startAtSelectedGroup({ params }) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.SEQUENCE_RUN,
      new URLSearchParams(params)
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    yield call(refreshGrid, params);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getDeleteViewItems({ params }) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.GET_DELETE_VIEW_ITEMS,
      new URLSearchParams(params)
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    yield put({
      type: SET_DELETE_VIEW_ITEMS,
      payload: data.ViewTable,
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* deleteViewItem({ params, closeModal }) {
  try {
    yield axios.post(
      CONSTANTS.ROUTES.SEQUENCE_UPDATE_ADD_STEP,
      new URLSearchParams(params)
    );
    yield call(refreshGrid, params);
    closeModal();
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getSequenceStepsFields({ params }) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETFIELDS,
      new URLSearchParams(params)
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    yield put({
      type: SET_SEQUENCE_STEPS_FIELDS,
      payload: data,
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* updateFieldData({ params, closeModal }) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.SEQUENCE_UPDATE_ADD_STEP,
      new URLSearchParams(params)
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    yield call(refreshGrid, params);
    closeModal();
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function prepareSequencesToSelectGridColumns(data) {
  return data.ViewFields.map((field) => {
    const headerText = field.Name;

    return {
      dataIndex: headerText,
      text: `<span title='${headerText}'>${headerText}</span>`,
      locked: field.IsSticky,
      hidden: field.IsHidden,
      width: field.ColumnWidth,
      sortable: false,
      cell: {
        height: 50,
      },
    };
  });
}

function* createSequencesToSelectStore(
  storeId,
  data,
  sequenceId,
  filteredData = {}
) {
  const store = Ext.getStore(storeId);
  if (store) {
    const model = prepareModel(data, store);

    const { SearchText } = data.CollectionState;

    store.removeAll(true);
    store.setModel(model);

    store.load({
      params: {
        Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
        CollectionID: CONSTANTS.COLLECTIONS.GROUP.SEQUENCE_DETAILS,
        ViewID: data.ViewRow.ViewID,
        AgentID: data.AgentID,
        ContextRestrictionFieldNames: "SequenceID",
        ContextRestrictionFieldValues: sequenceId,
        ColumnsToAlwaysInclude:
          "Settings,ResumeErrorCodes,ResumeErrorCount,ConcurrentJobs,Group,StepType,StepItemID,Step,StepNumberID,AdditionalStepItemID,Limit",
        SearchText,
        SearchField: "AllViewFields",
        ...data.CollectionState,
        ...filteredData,
      },
    });
  }

  const columns = prepareSequencesToSelectGridColumns(data);

  yield put({
    type: SET_SEQUENCES_TO_SELECT,
    payload: {
      columns,
      collectionState: data.CollectionState,
      sortedBy: data.ViewSorting,
      viewRow: data.ViewRow,
      viewFields: data.ViewFields,
    },
  });
}

function* getSequencesToSelect({ storeId, sequenceId }) {
  const params = {
    CollectionID: GROUP_COLLECTIONS.SEQUENCES,
    FolderID: "",
    AccountKey: "",
    ViewID: "",
    OverrideViewName: "",
    HideSystemViewNames: "",
    Command: COMMANDS.COLLECTION_GETCOMPLETE,
  };
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams(params)
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    yield call(createSequencesToSelectStore, storeId, data, sequenceId);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getSequencesToSelectViews() {
  const params = {
    AccountKey: "",
    CollectionID: GROUP_COLLECTIONS.SEQUENCES,
    Command: COMMANDS.COLLECTION_GETVIEWS,
  };
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETVIEWS,
      new URLSearchParams(params)
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    const savedViews = [];

    data.ViewTable.forEach((view) => {
      let savedView = {};
      for (let key in view) {
        if (CONSTANTS.RESPONSE_CONTEXT.SAVED_VIEWS_CONTEXT.includes(key)) {
          savedView[key] = view[key];
        }
      }
      savedViews.push(savedView);
    });

    yield put({
      type: SET_SEQUENCES_TO_SELECT_VIEWS,
      payload: {
        savedViews,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}
function* updateSequenceGrid(id) {
  const urlParams = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETCOMPLETE,
    CollectionID: CONSTANTS.COLLECTIONS.GROUP.SEQUENCE_DETAILS,
  };
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams(urlParams)
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    window["Ext"].getStore(CONSTANTS.STORES.IDS.SEQUENCES_STEPS).load({
      params: {
        Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
        CollectionID: CONSTANTS.COLLECTIONS.GROUP.SEQUENCE_DETAILS,
        ViewID: data.ViewRow.ViewID,
        AgentID: data.AgentID,
        ContextRestrictionFieldNames: "SequenceID",
        ContextRestrictionFieldValues: id,
        ColumnsToAlwaysInclude:
          "Settings,ResumeErrorCodes,ResumeErrorCount,ConcurrentJobs,Group,StepType,StepItemID,Step,StepNumberID,AdditionalStepItemID,Limit",
        SearchText: data.CollectionState.SearchText,
        SearchField: data.CollectionState.SearchField,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* runSequenceStep({ params, closeModal }) {
  try {
    yield axios.post(
      CONSTANTS.ROUTES.SEQUENCE_UPDATE_ADD_STEP,
      new URLSearchParams(params)
    );
    yield call(updateSequenceGrid, params.SequenceID);
    closeModal();
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* moveStepDrag({ params, setError, refreshGrid }) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.MOVE_STEP_DRAG,
      new URLSearchParams(params)
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    refreshGrid();
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getSequenceAgentInfo({ params, setSequenceAgentInfo }) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.SEQUENCE_GET_AGENT_INFO,
      new URLSearchParams(params)
    );

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    setSequenceAgentInfo(data.Agent);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getStepInfo({ params, setStepInfo }) {
  const { data } = yield axios.post(
    CONSTANTS.ROUTES.SEQUENCE_GET_STEP_INFO,
    new URLSearchParams(params)
  );

  setStepInfo({
    stepInfo: data.StepInfo,
    stepParameters: data.StepParameters,
  });
}

export default function* sequenceStepsWatcher() {
  yield takeLatest(GET_SEQUENCE_INFO, fetchSequenceInfo);
  yield takeLatest(GET_SEQUENCE_STEPS, fetchSequenceSteps);
  yield takeLatest(SET_STEPS_TO_RUN_CONCURRENTLY, runStepsConcurrently);
  yield takeLatest(ADD_STEP_TO_EXISTING_GROUP, addStepToExistingGroup);
  yield takeLatest(
    RUN_SELECTED_STEPS_CONCURRENTLY,
    runSelectedStepsConcurrently
  );
  yield takeLatest(
    REMOVE_SELECTED_STEPS_FROM_GROUP,
    removeSelectedStepsFromGroup
  );

  yield takeLatest(START_AT_SELECTED_GROUP, startAtSelectedGroup);
  yield takeLatest(GET_DELETE_VIEW_ITEMS, getDeleteViewItems);
  yield takeLatest(DELETE_VIEW_ITEM, deleteViewItem);
  yield takeLatest(GET_SEQUENCE_STEPS_FIELDS, getSequenceStepsFields);
  yield takeLatest(UPDATE_FIELD_DATA, updateFieldData);
  yield takeLatest(GET_SEQUENCES_TO_SELECT, getSequencesToSelect);
  yield takeLatest(GET_SEQUENCES_TO_SELECT_VIEWS, getSequencesToSelectViews);
  yield takeLatest(RUN_SEQUENCE_STEP, runSequenceStep);
  yield takeLatest(MOVE_STEP_DRAG, moveStepDrag);
  yield takeLatest(GET_SEQUENCE_AGENT, getSequenceAgentInfo);
  yield takeLatest(GET_STEP_INFO, getStepInfo);
}
