import React from "react";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from "@material-ui/core/styles";
import variables from "../../assets/sass/_variables.scss";
const useStyles = makeStyles({
  root: {
    width: 320,
    letterSpacing: 1,
    fontFamily: variables["fontfamily-default"],
    fontSize: "0.875rem",
  },
  default: {
    color: variables["color-white"],
    backgroundColor: variables["color-spacecadet"],
    fontFamily: variables["fontfamily-default"],
    "& .MuiAlert-icon": {
      color: variables["color-white"],
    },
  },
  danger: {
    color: variables["color-white"],
    backgroundColor: variables["color-danger"],
    fontFamily: variables["fontfamily-default"],
    "& .MuiAlert-icon": {
      color: variables["color-white"],
    },
  },
  success: {
    color: variables["color-white"],
    backgroundColor: variables["color-success"],
    fontFamily: variables["fontfamily-default"],
    "& .MuiAlert-icon": {
      color: variables["color-white"],
    },
  },
  info: {
    color: variables["color-white"],
    backgroundColor: variables["color-info"],
    fontFamily: variables["fontfamily-default"],
    "& .MuiAlert-icon": {
      color: variables["color-white"],
    },
  },
  warning: {
    color: variables["color-white"],
    backgroundColor: variables["color-warning"],
    fontFamily: variables["fontfamily-default"],
    "& .MuiAlert-icon": {
      color: variables["color-white"],
    },
  },
});
export default function NotificationAlert(props) {
  const classes = useStyles();
  let [style, icon] = ["", []];
  switch (props.type) {
    case "default":
      style = "default";
      icon = ["far", "exclamation-circle"];
      break;
    case "danger":
      style = "danger";
      icon = ["far", "exclamation-circle"];
      break;
    case "success":
      style = "success";
      icon = ["fas", "check-circle"];
      break;
    case "info":
      style = "info";
      icon = ["fas", "info-circle"];
      break;
    case "warning":
      style = "warning";
      icon = ["fas", "exclamation-triangle"];
      break;
    default:
      break;
  }
  const createMarkup = () => {
    return { __html: props.alertMessage };
  };
  return (
    <Alert
      id={props.id}
      className={classes[style]}
      icon={style === "default" ? false : <FontAwesomeIcon icon={icon} />}
      onClose={props.onClose}
    >
      <div dangerouslySetInnerHTML={createMarkup()} />
    </Alert>
  );
}
NotificationAlert.propTypes = {
  /** id */
  id: PropTypes.string,
  /** message */
  alertMessage: PropTypes.string,
  /** show or hide alert icon */
  icon: PropTypes.bool,
  /** type of alert */
  type: PropTypes.oneOf(["default", "danger", "success", "info", "warning"]),
  /** event handler on close */
  onClose: PropTypes.func,
};
NotificationAlert.propTypes = {
  /** id */
  id: PropTypes.string,
  /** message */
  alertMessage: PropTypes.string,
  /** show or hide alert icon */
  icon: PropTypes.bool,
  /** display message as html or text */
  isHtml: PropTypes.bool,
  /** type of alert */
  type: PropTypes.oneOf(["default", "danger", "success", "info", "warning"]),
  /** event handler on close */
  onClose: PropTypes.func,
};
