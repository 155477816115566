import { LAUNCH_AGENT_BUILDER, LAUNCH_AGENT_BUILDER_RESULT } from "../types";
import { takeLatest, select, put, call, cancel } from "redux-saga/effects";
import { CONSTANTS } from "../../constants/constants";
import axios from "axios";
import { errorHandlingEvent } from "../../EventHandling";

function GetGuid() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function* clientCommandResult(action) {
  const clientRequestKey = GetGuid();
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.CLIENT_COMMANDRESULT,
      new URLSearchParams({
        ClientRequestKey: clientRequestKey,
        ClientCommand: "LaunchBuilder",
        Command: CONSTANTS.COMMANDS.CLIENT_COMMANDRESULT,
      }),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === "Success") {
      return;
    } else if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    } else if (data.JsonResult.Result === "Error") {
      let result = "Error";
      yield put({
        type: LAUNCH_AGENT_BUILDER_RESULT,
        payload: {
          result,
        },
      });
    }
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* launchAgentBuilder({ type: action, window, formParams = {} }) {
  const reduxState = yield select();

  const accountKey = reduxState.account?.account?.Account?.AccountKey;
  const agentBuilderKey = reduxState?.user?.user.User.AgentBuilderKey;
  const webConsoleKey = reduxState?.user?.user.User.WebConsoleKey;
  const clientRequestKey = GetGuid();

  let hrefParam = `mozenda: /LaunchBuilder:true /AgentBuilderKey:${agentBuilderKey} /AccountKey:${
    accountKey || formParams.AccountKey || ""
  }  /LoggedInAsAccountName: /AdminEmail: /WebConsoleKey:${webConsoleKey} /ClientRequestKey:${clientRequestKey}`;
  if (formParams.AgentID) hrefParam += ` /AgentID:${formParams?.AgentID}`;
  if (formParams.JobID) hrefParam += ` /JobID:${formParams?.JobID}`;
  if (formParams.TemplateID)
    hrefParam += ` /TemplateID:${formParams?.TemplateID}`;
  try {
    yield (window.location.href = hrefParam);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.ERROR_EVENT, {
      msg: error.message,
    });
  }

  yield call(clientCommandResult, action);
}

export default function* agentBuilderWatcher() {
  yield takeLatest(LAUNCH_AGENT_BUILDER, launchAgentBuilder);
}
