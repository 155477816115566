import {
  SET_TIMEZONES,
  SET_USER,
  SET_COMMON_WEBLOCATIONS,
  SET_NEW_USER_ERROR,
  USER_SET_ADMIN_EMAILS,
  CLEAR_COLLECTION_STATE,
  REMOVE_NEW_USER_ERROR,
  REMOVE_EDIT_USER_ERROR,
  SET_EDIT_USER_ERROR,
  SET_WEB_SERVICE_KEY,
} from "../types";
import createReducer from "../createReducer";

const initialState = {
  user: {},
  timezones: null,
  adminEmails: null,
  errorMessage: null,
};

export const user = createReducer(initialState, {
  [SET_USER](state, { payload }) {
    return {
      ...state,
      user: payload.userInfo,
    };
  },
  [SET_TIMEZONES](state, { payload }) {
    return {
      ...state,
      timezones: payload,
    };
  },
  [USER_SET_ADMIN_EMAILS](state, { payload }) {
    return {
      ...state,
      adminEmails: payload,
    };
  },
  [SET_COMMON_WEBLOCATIONS](state, { payload }) {
    return {
      ...state,
      webLocations: payload,
    };
  },
  [SET_NEW_USER_ERROR](state, { payload }) {
    return {
      ...state,
      errorMessage: payload,
    };
  },
  [REMOVE_NEW_USER_ERROR](state) {
    return {
      ...state,
      errorMessage: null,
    };
  },
  [SET_EDIT_USER_ERROR](state, { payload }) {
    return {
      ...state,
      errorMessage: payload,
    };
  },
  [SET_WEB_SERVICE_KEY](state, { payload }) {
    return {
      ...state,
      webserviceKey: payload,
    };
  },
  [REMOVE_EDIT_USER_ERROR](state) {
    return {
      ...state,
      errorMessage: null,
    };
  },
  [CLEAR_COLLECTION_STATE](state) {
    return {
      ...state,
      errorMessage: null,
    };
  },
});
