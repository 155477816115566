import { FIELDS_CONFIG_ICONS } from "../../../constants/config";
import { CONSTANTS } from "../../../constants/constants";
import { stringToArray } from "../../../utils/array";

export const mapAgentGroupFieldConfigCell = (value, record) => {
  const iconNames = [
    "IsUnique",
    "IsRequired",
    "IsWatched",
    "IsConfidential",
    "DefaultValue",
  ];

  return iconNames.map((name) => {
    if (name === "DefaultValue") {
      return {
        ...FIELDS_CONFIG_ICONS[name.toUpperCase()],
        DESCRIPTION: `${FIELDS_CONFIG_ICONS[name.toUpperCase()].DESCRIPTION} ${
          record.data.DefaultValue
        }`,
        visible: record.data.DefaultValue !== "",
      };
    }
    return {
      ...FIELDS_CONFIG_ICONS[name.toUpperCase()],
      visible: record.data[name],
    };
  });
};

export const mapAgentGroupFieldDescriptionCell = (value, record) => {
  const isTemplateField = record.get("IsTemplate");
  if (isTemplateField) return value;

  const agentIDsString = record.get("AgentIDs");
  if (!agentIDsString) return value;

  const agentNamesString = record.get("AgentNames");

  const agentIDs = stringToArray(agentIDsString);
  const agentNames = stringToArray(agentNamesString);

  if (agentIDs.length === 1) {
    return agentNames[0];
  }

  return `${agentIDs.length} agents`;
};

export const mapBillingInvoiceAmountCell = (value) => {
  if (!value) return "$0.00";

  const numericalValue = +value;

  let stringValue = `$${Math.abs(numericalValue).toFixed(2)}`;
  if (numericalValue < 0) stringValue = `(${stringValue})`;

  return stringValue;
};

export const mapNewViewNameCell = (value, record) => {
  const name = record.get("HeaderText") || value;
  const isLinkColumn = record.get("IsLinkColumn");
  const isSystem = record.get("IsSystem");
  const stickyIcon = record.get("IsSticky")
    ? CONSTANTS.VIEWS.RSSFEED_COLUMNS.LOCKED
    : null;
  const hiddenIcon = record.get("IsHidden")
    ? CONSTANTS.VIEWS.RSSFEED_COLUMNS.HIDDEN
    : null;

  return {
    name,
    isSystem,
    isLinkColumn,
    stickyIcon,
    hiddenIcon,
  };
};

export const mapSourceFieldsGridNameCell = (value, record) => {
  const result = {
    label: {
      value,
    },
  };

  if (record.get("isUsed")) {
    result.icon = CONSTANTS.CUSTOM_FIELD_STATUS.USED_FIELD;
    return result;
  }

  return result;
};

export const mapSourceFieldsGridCollectionsCell = (value, record) => {
  const collectionIDs = record.get("CollectionIDs");
  const collectionNames = record.get("CollectionNames");

  if (value === 1) {
    return {
      name: collectionNames[collectionIDs[0]],
    };
  }

  if (value > 1) {
    const tooltipNames = collectionIDs.map(
      (id) => `${collectionNames[id]} / ${id}`
    );

    return {
      name: collectionNames[collectionIDs[0]],
      tooltip: tooltipNames.join(", "),
    };
  }

  return {
    name: "",
  };
};
