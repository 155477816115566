const Ext = window["Ext"];

Ext.define(null, {
  override: "Ext.grid.column.Column",
  onResize: function () {
    if (!this.isHidden(true)) {
      // Update the resizability of this column based on *how* it's just been sized.
      // If we are shrinkwrapping, we are not drag-resizable.
      this.updateResizable(this.getResizable());

      // Computed with needs to be exact so that sub-pixel changes are
      // not rejected by the config system because scrollbars may
      // depend upon the *exact* width of the cells in the view.
      this.measureWidth();

      // Sync row height for all grid regions on resize columns
      // this.syncRowHeight();
    }
  },
  privates: {
    sort: function (direction, mode) {
      var me = this,
        sorter = me.pickSorter(),
        grid = me.getGrid(),
        store = grid.getStore(),
        sorters = store.getSorters();

      if (!me.isSortable()) {
        return;
      }

      // This is the "group by" column - we have to set the grouper and tell it to
      // recalculate. AbstractStore#group just calls its Collection's updateGrouper
      // if passed a Grouper because *something* in the grouper might have changed,
      // but the config system would reject that as not a change.
      if (sorter.isGrouper) {
        if (sorter.getDirection() !== direction) {
          sorter.toggle();
          store.group(sorter);
        }
      }
      // We are moving to a sorted state
      else if (direction) {
        // We have a sorter - set its direction.
        if (sorter) {
          // Not the primary. We will make it so.
          // If it's already the primary, SorterCollection#addSort will toggle it
          if (sorters.indexOf(sorter) !== 0) {
            sorter.setDirection(direction);
          }
        }
        // First time in, create a sorter with required direction
        else {
          me.setSorter({
            property: me.getSortParam(),
            direction: "ASC",
          });

          sorter = me.getSorter(); // not pickSorter
        }

        // If the grid is NOT configured with multi column sorting, then specify
        // "replace". Only if we are doing multi column sorting do we insert it as
        // one of a multi set.
        store.sort(
          sorter,
          mode || grid.getMultiColumnSort() ? "multi" : "replace"
        );
      }
      // We're moving to an unsorted state
      else {
        if (sorter) {
          sorters.remove(sorter);

          // A locally sorted store will not refresh in response to having a
          // sorter removed, so we must sync the column header arrows now.
          // AbstractStore#onSorterEndUpdate will however always fire the sort
          // event which is what Grid uses to trigger a HeaderContainer sort
          // state sync
          if (!store.getRemoteSort()) {
            me.getRootHeaderCt().setSortState();
          }
        }
      }
    },
  },
});
