let oldXHROpen = window.XMLHttpRequest.prototype.open;
window.XMLHttpRequest.prototype.open = function () {
  this.addEventListener("load", function () {
    const jsonResult = this.response.JsonResult;
    if (this.responseType === "json" && jsonResult?.Result === "Exception") {
      // LOG THE ERROR THROUGH A LIBRARY IN THE FUTURE
    }
  });

  return oldXHROpen.apply(this, arguments);
};
