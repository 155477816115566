import { LAUNCH_AGENT_BUILDER_RESULT } from "../types";
import createReducer from "../createReducer";

const initialState = {
  launchAgentBuilderCommandResult: "",
};

export const agentBuilder = createReducer(initialState, {
  [LAUNCH_AGENT_BUILDER_RESULT](state, { payload }) {
    return {
      ...state,
      launchAgentBuilderCommandResult: payload.result,
    };
  },
});
