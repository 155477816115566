import {
  ADD_TRANSACTION,
  CHANGE_INVOICE_DATE,
  GET_ACCOUNT_BILLING_DATA,
  SET_ACCOUNT_BILLING_DATA,
  BILLING_LOADING,
  SET_ACCOUNT_BILLING_DOWNLOAD_INVOICE_LIST,
  GET_ACCOUNT_BILLING_DOWNLOAD_INVOICE_LIST,
  GET_UPDATE_BILLING_INVOICE_RECIPIENTS,
  UPDATE_BILLING_INVOICE_RECIPIENTS,
} from "../types";

import { CONSTANTS } from "../../constants/constants";
import { takeLatest, put, cancel, select } from "redux-saga/effects";
import { renderBillingInvoiceAmountCell } from "../../Components/Grid/SenchaGrid/renderers";
import { mapBillingInvoiceAmountCell } from "../../Components/Grid/SenchaGrid/mappers";
import { COMMANDS } from "../../constants/commands";
import moment from "moment";
import axios from "axios";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";

const Ext = window["Ext"];

function prepareGridColumns() {
  return [
    {
      text: "Invoice #",
      sortable: true,
      dataIndex: "InvoiceNumber",
      width: 95,
      hidden: false,
      cell: {
        height: 50,
      },
    },
    {
      text: "Date",
      sortable: true,
      dataIndex: "InvoiceDateTime",
      width: 215,
      renderer: (value) => moment(value).format("MMMM Do YYYY, h:mm A"),
      flex: 3,
      cell: {
        height: 50,
      },
    },
    {
      text: "Invoice Amount",
      align: "right",
      sortDir: "ASC",
      width: 128,
      sortType: "asInt",
      sortable: true,
      dataIndex: "InvoiceAmount",
      cell: {
        height: 50,
        encodeHtml: true,
        xtype: "reactcell",
      },
      renderer: (value, record) =>
        renderBillingInvoiceAmountCell(
          mapBillingInvoiceAmountCell,
          value,
          record
        ),
      flex: 2,
    },
  ];
}

function* fetchAccountBillingData({ storeId, formParams = {} }) {
  const store = Ext.getStore(storeId);

  if (store) {
    store.removeAll(true);
    store.clearFilter(true);

    store.load({
      params: {
        Command: CONSTANTS.COMMANDS.BILLING_GETINVOICELIST,
        ...formParams,
      },
    });

    const columns = prepareGridColumns();

    yield put({
      type: SET_ACCOUNT_BILLING_DATA,
      payload: {
        columns,
      },
    });
  }
}

function* changeInvoiceDate({ params, toggleModal }) {
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.ACCOUNT_CHANGE_INVOICE_DATE,
      new URLSearchParams(params)
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    toggleModal(false);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* fetchDepartmentBillingDownloadInvoiceList({ type: action }) {
  const reduxState = yield select();
  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETVIEWS,
    AccountKey: reduxState.account.accountInfo.Account.AccountKey,
    Protocol: `https:`,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETVIEWS,
      new URLSearchParams({
        Command: CONSTANTS.COMMANDS.BILLING_DOWNLOADINVOICELIST,
        AccountKey: reduxState.account.accountInfo.Account.AccountKey,
        CameFromPublisher: false,
      })
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    const billingData = yield axios.post(
      CONSTANTS.ROUTES.BILLING_DOWNLOADINVOICELIST,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    yield put({
      type: SET_ACCOUNT_BILLING_DOWNLOAD_INVOICE_LIST,
      payload: {
        data,
        billingData,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* updateBillingInoviceRecipients() {
  const reduxState = yield select();
  const { data } = yield axios.post(
    CONSTANTS.ROUTES.ACCOUNT_GET,
    new URLSearchParams({
      Command: COMMANDS.ACCOUNT_GET,
      AccountKey: reduxState.account.accountInfo.Account.AccountKey,
    })
  );

  if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
    yield cancel();
  }

  if (
    !handleJsonResultError(
      data.JsonResult.Result,
      data.JsonResult.ErrorDescription,
      data.JsonResult.ErrorList,
      CONSTANTS.NOTIFICATIONS.SUCCESS
    )
  )
    return;
  yield put({
    type: UPDATE_BILLING_INVOICE_RECIPIENTS,
    data,
  });
}

function* addTransaction({ params, closeModal }) {
  yield put({
    type: BILLING_LOADING,
  });
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.BILLING_ADD_TRANSACTION,
      new URLSearchParams(params)
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    closeModal();
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

export default function* accountBillingWatcher() {
  yield takeLatest(GET_ACCOUNT_BILLING_DATA, fetchAccountBillingData);
  yield takeLatest(CHANGE_INVOICE_DATE, changeInvoiceDate);
  yield takeLatest(ADD_TRANSACTION, addTransaction);
  yield takeLatest(
    GET_ACCOUNT_BILLING_DOWNLOAD_INVOICE_LIST,
    fetchDepartmentBillingDownloadInvoiceList
  );

  yield takeLatest(
    GET_UPDATE_BILLING_INVOICE_RECIPIENTS,
    updateBillingInoviceRecipients
  );
}
