import React from "react";
import {
  AGENT_RUN,
  AGENT_STOP,
  AGENT_RESUME,
  AGENT_CANCEL,
  AGENT_GET_JOB_STATUS,
  AGENT_SET_JOB_STATUS,
  AGENT_SET_JOB_STATUS_SESSION_EXPIRED,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import { takeEvery, select, put, call, cancel } from "redux-saga/effects";
import axios from "axios";
import { getDateInMsFromFormat } from "../../utils/date";
import { accountHasFeature } from "../../utils/validators";
import { Link } from "@reach/router";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";

export const STATUS = {
  Default: () => "<div></div>",
  Canceled: (PrettyStartedAgo, PrettyElapsed) =>
    `<div>Started ${PrettyStartedAgo} and ran for ${PrettyElapsed}</div>`,
  Done: (PrettyStartedAgo, PrettyElapsed) =>
    `<div>Started ${PrettyStartedAgo} and ran for ${PrettyElapsed}</div>`,
  Error: function displayText(PrettyErrorLastAgo, PrettyErrorMessage) {
    return (
      <div>
        Stopped {PrettyErrorLastAgo} because {PrettyErrorMessage}
      </div>
    );
  },
  LimitReached: function displayText() {
    return <div>Buy processing credits to continue this agent</div>;
  },
  "LimitReached-Group": function displayText() {
    return (
      <div>
        Contact your account administrator to get more processing credits
      </div>
    );
  },
  "LimitReached-default": function displayText() {
    return <div></div>;
  },

  "LimitReached-NoPagePurchase": function displayText() {
    return (
      <div>
        <b>Processing limit reached</b>
      </div>
    );
  },
  "LimitReached-PeriodPlan": function displayText() {
    return (
      <div>
        <b>Period processing limit reached</b>
      </div>
    );
  },
  "LimitReached-Trial": function displayText(PageLimitReached) {
    return (
      <div>
        <b>
          Trial processing limit of ${PageLimitReached} processing credits
          reached
        </b>
        - <Link to="https://webaccounts-dev.mozenda.com/login">Sign up</Link> to
        continue this agent
      </div>
    );
  },
  Multiple: function displayText() {
    return <div>There is more than one active job for this agent.</div>;
  },
  RefreshingData: function displayText() {
    return <div>The current items for the agent are being refreshed</div>;
  },
  Postponed: function displayText(PrettyStartAgo) {
    return (
      <div>
        A website error caused the job to be postponed until {PrettyStartAgo}
      </div>
    );
  },
  Queued: function displayText(PrettyModifiedSince) {
    return <div>In the queue {PrettyModifiedSince}</div>;
  },
  Pausing: function displayText() {
    return (
      <div>Please wait- It could take a few minutes for this agent to stop</div>
    );
  },
  Paused: function displayText(PrettyModifiedAgo) {
    return <div> Paused {PrettyModifiedAgo}</div>;
  },
  Running: function displayText(PrettyStartedAgo, HandlerComputerName) {
    return (
      <div>
        Started {PrettyStartedAgo} {/*on <b>${HandlerComputerName}</b>*/}
      </div>
    );
  },
  "Ready-Done": function displayText(PrettyStartedAgo, PrettyElapsed) {
    return (
      <div>
        Ran successfully {PrettyStartedAgo} for {PrettyElapsed}
      </div>
    );
  },
  "Ready-Canceled": function displayText(PrettyEndedAgo) {
    return <div>Canceled {PrettyEndedAgo}</div>;
  },
  "Ready-None": () => "<div></div>",
};

function* getStatusInfo(response) {
  let info = {
    TemplateDescription: STATUS["Default"],
    Status: response.Status,
    DoneWithErrors: false,
  };

  if (response.CollectionIsUnderMaintenance) {
    info.Status = response.IsRestarting ? "Restarting" : "Unavailable";
    return info;
  } else if (response.HasMultipleJobsInQueue) {
    info.Status = "Multiple Jobs";
    info.TemplateDescription = STATUS["Multiple"];
    return info;
  } else if (response.HasReportRefreshDataJob) {
    info.Status = "Refreshing Data";
    info.TemplateDescription = STATUS["RefreshingData"];
    return info;
  }

  const accountInfo = yield select(
    (state) => state.account.accountInfo.Account
  );

  if (info.Status === "Postponed") {
    const now = new Date();
    let formattedDateTime = getDateInMsFromFormat(
      response.Start,
      "yyyy-MM-dd HH:mm:ss"
    );

    const clientUtcOffset = now.getTimezoneOffset();
    const accountUtcOffset = accountInfo.TimeZoneUTCOffset;
    formattedDateTime =
      formattedDateTime - (clientUtcOffset - accountUtcOffset) * 60 * 1000;

    if (formattedDateTime) {
      const dateTime = new Date(formattedDateTime);
      if (response.Start !== undefined && dateTime <= now)
        info.Status = "Queued";
    }
  }

  if (info.Status === "LimitReached") {
    info.Status = "Limit Reached";
    const isTrial = accountInfo.BillRatePlan.indexOf("Trial") === 0;
    const hasFeatureMaster = accountHasFeature("Master", accountInfo);
    const isChildAccount =
      accountInfo.MasterAccountName && accountInfo.MasterAccountName !== "";

    if (isTrial) {
      info.TemplateDescription = STATUS["LimitReached-Trial"];
    } else if (accountInfo.PeriodPagesUsedExceeded) {
      info.Template = STATUS["LimitReached-PeriodPlan"];
    } else if (
      accountInfo.BillPaymentMethod === "External" ||
      hasFeatureMaster
    ) {
      info.TemplateDescription = STATUS["LimitReached-NoPagePurchase"];
    } else if (isChildAccount) {
      info.TemplateDescription = STATUS["LimitReached-Group"];
    } else if (accountInfo.AllowPagePurchases) {
      info.TemplateDescription = STATUS["LimitReached"];
    } else {
      info.TemplateDescription = STATUS["LimitReached-default"];
    }
  }

  switch (info.Status) {
    case "Cycled":
    case "Cycling":
    case "Recovered":
    case "Rejected":
    case "Restarted":
    case "Restarting":
    case "Running":
    case "Starting":
    case "Warning":
      info.TemplateDescription = STATUS["Running"];

      break;

    case "Queued":
      info.TemplateDescription = STATUS["Queued"];

      break;

    case "Postponed":
      info.TemplateDescription = STATUS["Postponed"];
      break;

    case "None":
    case "Ready":
      info.TemplateDescription =
        STATUS[response.HasRun ? `Ready-${response.State}` : "Ready-None"];
      info.Status = "Ready";
      break;

    case "Done":
      info.TemplateDescription = STATUS["Done"];
      info.DoneWithErrors = response.ErrorCount + response.WarningCount > 0;
      break;

    case "Canceled":
      info.DoneWithErrors = response.ErrorCount + response.WarningCount > 0;
      info.LastStatus = "Canceled";
      info.TemplateDescription = STATUS["Canceled"];
      break;

    case "Paused":
      info.TemplateDescription = STATUS["Paused"];
      break;

    case "Error":
      info.TemplateDescription = STATUS["Error"];
      break;

    case "Canceling":
      info.TemplateDescription = STATUS["Pausing"];
      break;

    case "Pausing":
      info.TemplateDescription = STATUS["Pausing"];
      break;
    default:
      break;
  }

  return info;
}

function* getAgentJobStatus({ formParams }) {
  const params = {
    Command: CONSTANTS.COMMANDS.AGENT_GETJOBSTATUS,
    JobID: "-1",
    ...formParams,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.AGENT_GETJOBSTATUS,
      new URLSearchParams(params)
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield put({
        type: AGENT_SET_JOB_STATUS_SESSION_EXPIRED,
        payload: {
          agentJobStatusSessionExpired: true,
        },
      });
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    const agentJobStatus = yield select(
      (state) => state.agentRun.agentJobStatus
    );
    const info = yield call(getStatusInfo, data);

    if (!agentJobStatus || info.Status !== agentJobStatus.statusInfo.Status) {
      yield put({
        type: AGENT_SET_JOB_STATUS,
        payload: {
          agentJobStatus: {
            ...data,
            statusInfo: info,
          },
        },
      });
    }
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

export function* runAgent({ formParams, successCallback, failureCallback }) {
  const params = {
    Command: CONSTANTS.COMMANDS.AGENT_RUN,
    ...formParams,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.AGENT_RUN,
      new URLSearchParams(params)
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      data.JsonResult.ErrorDescription &&
      data.JsonResult.ErrorDescription.length > 0 &&
      failureCallback
    ) {
      failureCallback(data.JsonResult.ErrorDescription);
    } else {
      successCallback(data);
    }
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
    if (failureCallback) failureCallback(error);
  }
}

export function* stopAgent({ formParams, callback }) {
  const params = {
    Command: CONSTANTS.COMMANDS.AGENT_STOP,
    ...formParams,
  };
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.AGENT_STOP,
      new URLSearchParams(params)
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    callback(data);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

export function* resumeAgent({ formParams, callback }) {
  const params = {
    Command: CONSTANTS.COMMANDS.AGENT_RESUME,
    ...formParams,
  };
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.AGENT_RESUME,
      new URLSearchParams(params)
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    callback(data);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

export function* cancelAgent({ formParams, callback }) {
  const params = {
    Command: CONSTANTS.COMMANDS.AGENT_CANCEL,
    ...formParams,
  };
  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.AGENT_CANCEL,
      new URLSearchParams(params)
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    callback(data);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

export default function* agentRunWatcher() {
  yield takeEvery(AGENT_RUN, runAgent);
  yield takeEvery(AGENT_STOP, stopAgent);
  yield takeEvery(AGENT_RESUME, resumeAgent);
  yield takeEvery(AGENT_CANCEL, cancelAgent);
  yield takeEvery(AGENT_GET_JOB_STATUS, getAgentJobStatus);
}
