import { IconTypes } from "../Data/Enums/IconTypes";
import variables from "../assets/sass/_variables.scss";
import { ReactComponent as MultipleIcon } from "../assets/icons/mwc-multiple.svg";
import { ReactComponent as RefreshingIcon } from "../assets/icons/mwc-refreshing.svg";

export const AGENT_JOB_STATUS = {
  Ready: variables["color-success"],
  Paused: variables["color-neoncarrot"],
  RefreshingData: variables["color-fadedjade"],
  Done: variables["color-midgray"],
  Running: variables["color-spacecadet"],
  Cancelled: variables["color-danger"],
  Queued: variables["color-success"],
  Error: variables["color-danger"],
};

export const JOB_STATUS = {
  CANCELED: {
    STATUS: "Canceled",
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["far", "ban"],
    COLOR: variables["color-danger"],
  },
  DONE: {
    STATUS: "Done",
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["far", "check"],
    COLOR: variables["color-success"],
  },
  ERROR: {
    STATUS: "Error",
    NAME: "Error",
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["far", "times-circle"],
    COLOR: variables["color-ruby"],
  },
  DEFAULT: "Default",
  NONE: "",
  WAITING: "Waiting",
  STARTING: "Starting",
  REJECTED: "Rejected",
  RUNNING: {
    STATUS: "Running",
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["far", "circle-notch"],
    COLOR: variables["color-info"],
    NAME: "Running",
  },
  RESTARTING: "Restarting",
  RESTARTED: "Restarted",
  CYCLING: "Cycling",
  CYCLED: "Cycled",
  QUEUED: {
    STATUS: "Queued",
    TYPE: IconTypes.FONTAWESOME,
    ICON: ["fad", "check"],
    COLOR: variables["color-skyblue"],
    NAME: "Queued",
  },
  RECOVERED: "Recovered",
  STOPPING: "Stopping",
  STOPPED: "Stopped",
  POSTPONED: { NAME: "Postponed", COLOR: variables["color-bumblebee"] },
  CANCELING: "Canceling",
  LIMITREACHED: {
    NAME: "Limit Reached",
    COLOR: variables["color-mozorange"],
  },
  WARNING: "Warning",
  PAUSED: {
    COLOR: variables["color-warning"],
    NAME: "Paused",
  },
  PAUSING: {
    COLOR: variables["color-butter"],
    NAME: "Pausing",
  },
  UPCOMING: "Upcoming",
  UPCOMING_REFRESH: "UpcomingRefresh",
  READY: {
    COLOR: variables["color-success"],
    NAME: "Ready",
  },
  MULTIPLE: {
    TYPE: IconTypes.CUSTOM,
    ICON: MultipleIcon,
    COLOR: variables["color-sapphire"],
    NAME: "Multiple Jobs",
  },
  REFRESHINGDATA: {
    COLOR: variables["color-deepcerulean"],
    NAME: "Refreshing Data",
    TYPE: IconTypes.CUSTOM,
    ICON: RefreshingIcon,
  },
};

export const JOB_STATUS_POLLING_INTERVAL = 3000;
