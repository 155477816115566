import * as types from "../types";
import createReducer from "../createReducer";

const initialState = {
  Schedule: {},
};

export const scheduler = createReducer(initialState, {
  [types.SET_SCHEDULER](state, { payload }) {
    return {
      ...state,
      Schedule: payload,
    };
  },
});
