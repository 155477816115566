import {
  GET_DEPARTMENTS,
  SET_DEPARTMENTS_LIST,
  SET_DEPARTMENTS_SAVED_VIEWS,
  GET_DEPARTMENTS_SAVED_VIEWS,
  DELETE_DEPARTMENTS,
  DELETE_DEPARTMENT_USERS,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import { put, select, takeEvery, call, cancel } from "redux-saga/effects";
import {
  renderDepartmentNameCell,
  renderConfigCell,
} from "../../Components/Grid/SenchaSummaryRowGrid/renderers";
import { mapConfigCell } from "../../Components/Grid/SenchaSummaryRowGrid/mappers";
import axios from "axios";
import { GROUP_COLLECTIONS } from "../../constants/collections";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";
const Ext = window["Ext"];

function prepareModel(data, store) {
  const model = store.config.model;

  model.setFields(data);

  return model;
}

function* createStore(storeId, data, filteredData = {}) {
  const store = Ext.getStore(storeId);
  if (store) {
    const model = prepareModel(data, store);

    const { CollectionID, SearchText, SearchField } = data.CollectionState;

    store.removeAll(true);
    store.setModel(model);

    store.load({
      params: {
        CollectionID,
        ViewID: data.ViewRow.ViewID,
        SearchText,
        SearchField,
        AddSummaryRow: true,
        ColumnsToAlwaysInclude:
          "LimitAgents,LimitStorage,LimitJobs,LimitUsers,TotalAgents,TotalStorage,TotalUsers,Description,Agents,Storage,Users,PageCredits,Company",
        ...filteredData,
        Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
      },
    });
  }

  const columns = prepareGridColumns(data);
  yield put({
    type: SET_DEPARTMENTS_LIST,
    payload: {
      columns,
      sortedBy: data.ViewSorting,
      viewRow: data.ViewRow,
      collectionState: data.CollectionState,
      viewFields: data.ViewFields,
    },
  });
}

function prepareGridColumns(data) {
  const headerTexts = {
    Company: "Department",
  };

  const renderers = {
    Department: { renderer: renderDepartmentNameCell },
    Config: {
      renderer: renderConfigCell,
      mapper: mapConfigCell,
    },
  };

  let columnItems = [];
  columnItems.push({
    dataIndex: "x-separator",
    text: "",
    locked: true,
    hidden: true,
    width: 1,
    editable: false,
    menuDisabled: true,
  });

  let singleColumns = false;
  if (data.ViewFields.length === 1) {
    singleColumns = true;
  } else {
    singleColumns = false;
  }

  columnItems = [
    ...columnItems,
    ...data.ViewFields.map((field) => {
      const headerText = (headerTexts && headerTexts[field.Name]) || field.Name;
      if (renderers[headerText]) {
        return {
          dataIndex: headerText,
          text: `<span title='${headerText}'>${headerText}</span>`,
          locked: singleColumns ? false : field.IsSticky,
          hidden: field.IsHidden,
          width: field.ColumnWidth,
          scope: this,
          cell: {
            height: 50,
            encodeHtml: true,
            xtype: "reactcell",
          },
          sorter: {
            sorterFn: function (record1, record2) {
              let name1 = record1.data[headerText]?.toLowerCase();
              let name2 = record2.data[headerText]?.toLowerCase();
              if (
                record1.data.SystemItemRowNumber?.toString() === "-100" ||
                record2.data.SystemItemRowNumber?.toString() === "-100"
              ) {
                return 0;
              } else if (
                record1.data.SystemItemRowNumber?.toString() === "0" ||
                record2.data.SystemItemRowNumber?.toString() === "0"
              ) {
                return 0;
              } else {
                return name1 > name2 ? 1 : name1 === name2 ? 0 : -1;
              }
            },
            direction: "DESC",
          },
          renderer: (value, record) =>
            renderers[headerText].renderer(
              renderers[headerText].mapper,
              value,
              record,
              renderers[headerText].callback
            ),
        };
      }

      return {
        dataIndex: headerText,
        text: `<span title='${headerText}'>${headerText}</span>`,
        locked: field.IsSticky,
        hidden: field.IsHidden,
        width: field.ColumnWidth,
        cell: {
          height: 50,
        },
        sorter: {
          sorterFn: function (record1, record2) {
            let name1 = record1.data[headerText]?.toLowerCase();
            let name2 = record2.data[headerText]?.toLowerCase();
            if (
              record1.data.SystemItemRowNumber?.toString() === "-100" ||
              record2.data.SystemItemRowNumber?.toString() === "-100"
            ) {
              return 0;
            } else if (
              record1.data.SystemItemRowNumber?.toString() === "0" ||
              record2.data.SystemItemRowNumber?.toString() === "0"
            ) {
              return 0;
            } else {
              return name1 > name2 ? 1 : name1 === name2 ? 0 : -1;
            }
          },
          direction: "DESC",
        },
      };
    }),
  ];

  return columnItems;
}

function* fetchDepartments({ type: action, storeId, formParams = {} }) {
  const accountID = yield select()?.accountInfo?.Account?.AccountKey;
  const viewId = yield select((state) => state?.departments?.viewRow?.ViewID);
  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETCOMPLETE,
    CollectionID: CONSTANTS.COLLECTIONS.GROUP.DEPARTMENTS,
    ViewID: viewId || "",
    AccountKey: accountID || "",
    ...formParams,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    const extraParams = {
      SearchField: data.CollectionState.SearchField,
      SearchText: data.CollectionState.SearchText,
    };

    yield call(createStore, storeId, data, extraParams);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getSavedViews({ type: action }) {
  const params = {
    AccountKey: "",
    CollectionID: CONSTANTS.COLLECTIONS.GROUP.DEPARTMENTS,
    Command: CONSTANTS.COMMANDS.COLLECTION_GETVIEWS,
  };

  try {
    const result = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETVIEWS,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (result.data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        result.data.JsonResult.Result,
        result.data.JsonResult.ErrorDescription,
        result.data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    const savedViews = [];

    result.data.ViewTable.forEach((view) => {
      let savedView = {};
      for (let key in view) {
        if (CONSTANTS.RESPONSE_CONTEXT.SAVED_VIEWS_CONTEXT.includes(key)) {
          savedView[key] = view[key];
        }
      }
      savedViews.push(savedView);
    });

    yield put({
      type: SET_DEPARTMENTS_SAVED_VIEWS,
      payload: {
        savedViews,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* deleteDepartments({
  params,
  viewId,
  navigate,
  shouldNavigate,
  transfer,
  transferParams,
  setLoading,
  toggleModal,
}) {
  try {
    setLoading(true);
    if (transfer) {
      yield axios.post(
        CONSTANTS.ROUTES.BILLING_TRANSFER_PAGE_CREDITS,
        transferParams
      );
      const { data } = yield axios.post(
        CONSTANTS.ROUTES.ACCOUNT_ADDREMOVEGROUPS,
        params
      );

      if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
        yield cancel();
      }

      if (
        !handleJsonResultError(
          data.JsonResult.Result,
          data.JsonResult.ErrorDescription,
          data.JsonResult.ErrorList,
          CONSTANTS.NOTIFICATIONS.SUCCESS
        )
      )
        return;
      if (shouldNavigate) {
        yield axios.post(
          CONSTANTS.ROUTES.ACCOUNT_GET,
          new URLSearchParams({
            Command: CONSTANTS.COMMANDS.ACCOUNT_GET,
          })
        );
        navigate("/departments");
      } else {
        window["Ext"].getStore(CONSTANTS.STORES.IDS.DEPARTMENT_LIST).load({
          params: {
            AddSummaryRow: true,
            Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
            CollectionID: GROUP_COLLECTIONS.DEPARTMENTS,
            ViewID: viewId,
            BookmarkID: 0,
            JobID: -1,
            ColumnsToAlwaysInclude:
              "LimitAgents,LimitStorage,LimitJobs,LimitUsers,TotalAgents,TotalStorage,TotalUsers,Description,Agents,Storage,Users,PageCredits,Company",
            AlwaysIncludeCustomFields: false,
          },
        });
      }
    }
    yield axios.post(CONSTANTS.ROUTES.ACCOUNT_ADDREMOVEGROUPS, params);
    if (shouldNavigate) {
      yield axios.post(
        CONSTANTS.ROUTES.ACCOUNT_GET,
        new URLSearchParams({
          Command: CONSTANTS.COMMANDS.ACCOUNT_GET,
        })
      );
      navigate("/departments");
    } else {
      window["Ext"].getStore(CONSTANTS.STORES.IDS.DEPARTMENT_LIST).load({
        params: {
          AddSummaryRow: true,
          Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
          CollectionID: GROUP_COLLECTIONS.DEPARTMENTS,
          ViewID: viewId,
          BookmarkID: 0,
          JobID: -1,
          ColumnsToAlwaysInclude:
            "LimitAgents,LimitStorage,LimitJobs,LimitUsers,TotalAgents,TotalStorage,TotalUsers,Description,Agents,Storage,Users,PageCredits,Company",
          AlwaysIncludeCustomFields: false,
        },
      });
    }
    setLoading(false);
    toggleModal(false);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* deleteUsers({ params, accountKey }) {
  try {
    const { data } = yield axios.post(CONSTANTS.ROUTES.USER_DELETE, params);
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    window["Ext"].getStore(CONSTANTS.STORES.IDS.DEPARTMENT_USERS).load({
      params: {
        Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
        CollectionID: GROUP_COLLECTIONS.USERS,
        AccountKey: accountKey,
        BookmarkID: 0,
        JobID: -1,
        ColumnsToAlwaysInclude:
          "Email,NameFirst,NameLast,Status,UserKey,AccountPermissions,Settings,City,Company,Country,FaxNumber,Phone1,Phone2,Street1,Street2,State,Title,ZipCode",
        AlwaysIncludeCustomFields: false,
        ShowHistoryColumn: false,
        ShowSelected: false,
        TemplateCollectionID: 0,
        ShowMarkup: false,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}
export default function* departmentsWatcher() {
  yield takeEvery(GET_DEPARTMENTS, fetchDepartments);
  yield takeEvery(DELETE_DEPARTMENTS, deleteDepartments);
  yield takeEvery(DELETE_DEPARTMENT_USERS, deleteUsers);
  yield takeEvery(GET_DEPARTMENTS_SAVED_VIEWS, getSavedViews);
}
