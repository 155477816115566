import {
  SET_IMAGES_AND_FILES,
  REPACKAGE_ERROR,
  DELETE_PACKAGE_FILES_ERROR,
} from "../types";

import createReducer from "../createReducer";

const initialState = {
  columns: [],
  hasFileFields: false,
};

export const exports = createReducer(initialState, {
  [SET_IMAGES_AND_FILES](state, { payload }) {
    return {
      ...state,
      columns: payload.columns,
      hasFileFields: payload.hasFileFields,
    };
  },
  [REPACKAGE_ERROR](state, { error }) {
    return {
      ...state,
      error,
    };
  },

  [DELETE_PACKAGE_FILES_ERROR](state) {
    return {
      ...state,
      error: null,
    };
  },
});
