import storeConfig, { arrayStoreConfig } from "./senchaStoreConfig";
import { CONSTANTS } from "../constants/constants";

const Ext = window["Ext"];

const stores = {
  getStoreWithItemID(storeId, properties, actionHeader) {
    return storeConfig(
      {
        storeId,
        model: Ext.create("DynamicModel"),
        proxy: {
          url: CONSTANTS.ROUTES.VIEW_GETITEMS,
          extraParams: {
            Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
          },
        },
        autoLoad: false,
        ...properties,
      },
      actionHeader
    );
  },
  getStoreWithEditableData(storeId, properties, actionHeader) {
    return storeConfig(
      {
        storeId,
        model: Ext.create("DynamicDataModel"),
        proxy: {
          url: CONSTANTS.ROUTES.VIEW_GETITEMS,
          extraParams: {
            Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
          },
        },
        autoLoad: false,
        ...properties,
      },
      actionHeader
    );
  },
  getStoreWithFieldID(storeId, properties, actionHeader) {
    return storeConfig(
      {
        storeId,
        model: Ext.create("FieldsModel"),
        proxy: {
          url: CONSTANTS.ROUTES.COLLECTION_GETFIELDS,
          reader: {
            type: "json",
            rootProperty: "Fields",
          },
          extraParams: {
            Command: CONSTANTS.COMMANDS.COLLECTION_GETFIELDS,
          },
        },
        autoLoad: false,
        ...properties,
      },
      actionHeader
    );
  },
  getStoreWithPackagesFiles(storeId, properties, actionHeader) {
    return storeConfig(
      {
        storeId,
        model: Ext.create("PackagesFilesModel"),
        proxy: {
          url: CONSTANTS.ROUTES.COLLECTION_GETPACKAGEFILESLIST,
          reader: {
            type: "json",
            rootProperty: "Rows",
          },
          extraParams: {
            Command: CONSTANTS.COMMANDS.COLLECTION_GETPACKAGEFILESLIST,
          },
        },
        autoLoad: false,
        groupField: "PackageID",
        ...properties,
      },
      actionHeader
    );
  },
  getStoreWithInvoiceNumber(storeId, properties, actionHeader) {
    return storeConfig(
      {
        storeId,
        model: Ext.create("InvoiceListModel"),
        proxy: {
          url: CONSTANTS.ROUTES.BILLING_GETINVOICELIST,
          extraParams: {
            Command: CONSTANTS.COMMANDS.BILLING_GETINVOICELIST,
          },
          reader: {
            type: "json",
            rootProperty: "InvoiceTable",
          },
        },
        autoLoad: false,
        ...properties,
      },
      actionHeader
    );
  },
  getStoreWithSequenceErrorCodes(storeId, properties, actionHeader) {
    return storeConfig(
      {
        storeId,
        model: Ext.create("SequenceErrorCodesModel"),
        proxy: {
          url: CONSTANTS.ROUTES.SEQUENCE_GET_ERROR_CODES,
          extraParams: {
            Command: CONSTANTS.COMMANDS.SEQUENCE_GET_ERROR_CODES,
          },
          reader: {
            type: "json",
            rootProperty: "Rows",
          },
        },
        autoLoad: false,
        ...properties,
      },
      actionHeader
    );
  },

  getStoreWithGetColumnHeadingFile(storeId, properties, actionHeader) {
    return arrayStoreConfig(
      {
        storeId,
        model: Ext.create("GetColumnHeadingFileModel"),
        proxy: {
          url: CONSTANTS.ROUTES.COLLECTION_GET_COLUMN_HEADINGS_FROM_FILE,
          extraParams: {
            Command:
              CONSTANTS.COMMANDS.COLLECTION_GET_COLUMN_HEADINGS_FROM_FILE,
          },
          reader: {
            type: "json",
            rootProperty: "SampleRows",
          },
        },
        autoLoad: false,
        ...properties,
      },
      actionHeader
    );
  },

  getStoreWithGetFileColumn(storeId, properties, actionHeader) {
    return arrayStoreConfig(
      {
        storeId,
        model: Ext.create("GetFileColumnModel"),
        proxy: {
          url: CONSTANTS.ROUTES.COLLECTION_GET_COLUMN_HEADINGS_FROM_FILE,
          extraParams: {
            Command:
              CONSTANTS.COMMANDS.COLLECTION_GET_COLUMN_HEADINGS_FROM_FILE,
          },
          reader: {
            type: "json",
            rootProperty: "FileColumnNames",
          },
        },
        autoLoad: false,
        ...properties,
      },
      actionHeader
    );
  },
  getStoreWithGetImportColumn(storeId, properties, actionHeader) {
    return arrayStoreConfig(
      {
        storeId,
        model: Ext.create("GetImportColumnModel"),
        proxy: {
          url: CONSTANTS.ROUTES.COLLECTION_GET_COLUMN_HEADINGS_FROM_FILE,
          extraParams: {
            Command:
              CONSTANTS.COMMANDS.COLLECTION_GET_COLUMN_HEADINGS_FROM_FILE,
          },
          reader: {
            type: "json",
          },
        },
        autoLoad: false,
        ...properties,
      },
      actionHeader
    );
  },

  getStoreWithFolderID(storeId, properties, actionHeader) {
    return storeConfig(
      {
        storeId,
        model: Ext.create("FolderModel"),
        proxy: {
          url: CONSTANTS.ROUTES.FOLDER_GETLIST,
          extraParams: {
            Command: CONSTANTS.COMMANDS.FOLDER_GETLIST,
          },
        },
        autoLoad: false,
        ...properties,
      },
      actionHeader
    );
  },
  getStoreForSourceFields(storeId, properties) {
    return arrayStoreConfig({
      storeId,
      model: Ext.create("SourceFieldsModel"),
      ...properties,
      autoLoad: false,
    });
  },

  getStoreForUsage(storeId, properties, actionHeader) {
    return storeConfig(
      {
        storeId,
        model: Ext.create("UsageModel"),
        proxy: {
          url: CONSTANTS.ROUTES.UTILIZATION_GETUSAGE,
          extraParams: {
            Command: CONSTANTS.COMMANDS.UTILIZATION_GETUSAGE,
          },
        },
        autoLoad: false,
        ...properties,
      },
      actionHeader
    );
  },

  getStoreForRssField(storeId, properties) {
    return arrayStoreConfig({
      storeId,
      model: Ext.create("ViewRssFieldModel"),
      ...properties,
    });
  },
};

function initStore(name, id, properties, actionHeader) {
  try {
    let store = Ext.getStore(id);

    if (!store) {
      const storeFn = stores[`get${name}`];
      if (storeFn) {
        return storeFn(id, properties, actionHeader);
      }

      return null;
    } else {
      const listenerNames = Object.keys(properties.listeners);
      if (listenerNames.length > 0) {
        listenerNames.forEach((eventName) => {
          if (!store.hasListener(eventName)) {
            store.addListener(eventName, properties.listeners[eventName]);
          }
        });
      }

      return store;
    }
  } catch (err) {
    //
  }
}

export default initStore;
