import "../../Components/Grid/SenchaLockedGrid/models";
import "../../Components/Grid/SenchaEditableLockedGrid/models";
import "../../Components/Grid/SenchaGrid/models";
import { all, call } from "redux-saga/effects";
import authenticationWatcher from "./authentication.saga";
import accountWatcher from "./account.saga";
import commonWatcher from "./common.saga";
import accountBillingWatcher from "./accountBilling.saga";
import accountBillingInvoiceWatcher from "./accountBillingInvoice.saga";
import accountUsersWatcher from "./accountUsers.saga";
import accountStorageWatcher from "./accountStorage.saga";
import accountJobsWatcher from "./accountJobs.saga";
import accountInsightsWatcher from "./accountInsights.saga";
import accountUtilizationWatcher from "./accountUtilization.saga";
import accountUtilizationUsageWatcher from "./accountUtilizationUsage.saga";
import agentBuilderWatcher from "./agentBuilder.saga";
import agentsWatcher from "./agents.saga";
import agentsCustomFieldsWatcher from "./agentSettings.saga";
import agentDetailsWatcher from "./agentDetails.saga";
import agentRunWatcher from "./agentRun.saga";
import agentDataWatcher from "./agentData.saga";
import agentJobsWatcher from "./agentJobs.saga";
import agentFieldsWatcher from "./agentFields.saga";
import agentHistoryWatcher from "./agentHistory.saga";
import agentInsightsWatcher from "./agentInsights.saga";
import agentGroupsWatcher from "./agentGroups.saga";
import agentGroupsAgentsWatcher from "./agentGroupsAgents.saga";
import agentGroupsDataWatcher from "./agentGroupsData.saga";
import agentGroupsFieldsWatcher from "./agentGroupsFields.saga";
import agentGroupsSummaryWatcher from "./agentGroupsSummary.saga";
import sequencesWatcher from "./sequences.saga";
import sequenceStepsWatcher from "./sequenceSteps.saga";
import departmentsWatcher from "./departments.saga";
import newDepartmentWatcher from "./departmentNew.saga";
import departmentImportWatcher from "./departmentImport.saga";
import departmentBillingWatcher from "./departmentBilling.saga";
import departmentBillingInvoiceWatcher from "./departmentBillingInvoice.saga";

import departmentStorageWatcher from "./departmentStorage.saga";
import departmentInsightsWatcher from "./departmentInsights.saga";
import departmentJobsWatcher from "./departmentJobs.saga";
import departmentUsersWatcher from "./departmentUsers.saga";
import departmentUtilizationWatcher from "./departmentUtilization.saga";
import departmentUtilizationUsageWatcher from "./departmentUtilizationUsage.saga";
import cookiesWatcher from "./cookies.saga";
import cookieDetailsWatcher from "./cookieDetails.saga";
import folderCollectionWatcher from "./folderCollections.saga";
import combinedCollectionWatcher from "./combinedCollection.saga";
import standardCollectionWatcher from "./standardCollections.saga";
import combinedCollectionFieldsWatcher from "./combinedCollectionsFields.saga";
import combinedCollectionCustomFieldsWatcher from "./combinedCollectionsCustomFields.saga";
import standardCollectionFieldsWatcher from "./standardCollectionsFields.saga";
import collectionWatcher from "./collections.saga";
import collectionImport from "./collectionImport.saga";
import collectionSettingsWatcher from "./collectionSettings.saga";
import folderDataWatcher from "./folders.saga";
import folderAgentDataWatcher from "./folderAgents.saga";
import folderModalWatcher from "./foldersModal.saga";
import newViewWatcher from "./newView.saga";
import userWatcher from "./user.saga";
import publishWatcher from "./publishing.saga";
import schedulingWatcher from "./scheduling.saga";
import assignAgentGroupAgents from "./assignAgentGroupAgents.saga";
import exportWatcher from "./export.saga";
import getSystemBillingInfo from "./systemBillingInfo.saga";
import recentItemsWatcher from "./recentItems.saga";
import systemWatcher from "./system.saga";
import systemSettingsWatcher from "./systemSettings.saga";
import systemInsightsWatcher from "./systemInsights.saga";

export default function* rootSaga() {
  const sagas = [
    accountWatcher,
    authenticationWatcher,
    commonWatcher,
    accountBillingWatcher,
    accountBillingInvoiceWatcher,
    accountUsersWatcher,
    accountStorageWatcher,
    accountJobsWatcher,
    accountInsightsWatcher,
    accountUtilizationWatcher,
    accountUtilizationUsageWatcher,
    agentBuilderWatcher,
    agentsWatcher,
    agentDetailsWatcher,
    agentsCustomFieldsWatcher,
    agentRunWatcher,
    agentDataWatcher,
    agentJobsWatcher,
    agentFieldsWatcher,
    agentHistoryWatcher,
    agentInsightsWatcher,
    agentGroupsWatcher,
    agentGroupsAgentsWatcher,
    agentGroupsDataWatcher,
    agentGroupsFieldsWatcher,
    agentGroupsSummaryWatcher,
    sequencesWatcher,
    sequenceStepsWatcher,
    departmentsWatcher,
    newDepartmentWatcher,
    departmentImportWatcher,
    departmentBillingWatcher,
    departmentBillingInvoiceWatcher,
    departmentStorageWatcher,
    departmentInsightsWatcher,
    departmentJobsWatcher,
    departmentUsersWatcher,
    departmentUtilizationWatcher,
    departmentUtilizationUsageWatcher,
    cookiesWatcher,
    cookieDetailsWatcher,
    folderCollectionWatcher,
    combinedCollectionWatcher,
    standardCollectionWatcher,
    combinedCollectionFieldsWatcher,
    combinedCollectionCustomFieldsWatcher,
    standardCollectionFieldsWatcher,
    collectionWatcher,
    collectionImport,
    collectionSettingsWatcher,
    folderDataWatcher,
    folderAgentDataWatcher,
    folderModalWatcher,
    newViewWatcher,
    userWatcher,
    publishWatcher,
    schedulingWatcher,
    assignAgentGroupAgents,
    getSystemBillingInfo,
    recentItemsWatcher,
    exportWatcher,
    systemWatcher,
    systemSettingsWatcher,
    systemInsightsWatcher,
  ];

  yield all(sagas.map((saga) => call(saga)));
}
