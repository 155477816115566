import * as types from "../types";
import createReducer from "../createReducer";

const initialState = {
  sortedBy: [],
  cookiesListColumns: [],
  cookieDetailsListColumns: [],
  cookieDetailsSortedBy: [],
  viewRow: null,
  savedViews: [],
  cookiesCollectionState: null,
  cookieDetailsCollectionState: {},
  cookieDetailsViewFields: null,
};

export const cookies = createReducer(initialState, {
  [types.SET_COOKIES](state, { payload }) {
    return {
      ...state,
      cookiesListColumns: payload.columns,
      sortedBy: payload.sortedBy,
      viewRow: payload.viewRow,
      cookiesCollectionState: payload.collectionState,
      viewFields: payload.viewFields.map((viewField) => {
        return {
          name: viewField.Name,
          value: viewField.Name,
          fieldId: viewField.FieldID,
        };
      }),
      refreshed: payload.refreshed,
    };
  },
  [types.SET_COOKIE_DETAILS](state, { payload }) {
    return {
      ...state,
      cookieDetailsListColumns: payload.columns,
      cookieDetailsSortedBy: payload.cookieDetailsSortedBy,
      cookieDetailsCollectionState: payload.collectionState,
      cookieDetailsViewFields: payload.viewFields.map((viewField) => {
        return {
          name: viewField.Name,
          value: viewField.Name,
          fieldId: viewField.FieldID,
        };
      }),
      cookieDetailsRefreshed: payload.refreshed,
    };
  },

  [types.DELETE_COOKIES_VIEW](state, { viewId }) {
    return {
      ...state,
      savedViews: state.savedViews.filter((savedView) => {
        return savedView.ViewID !== viewId;
      }),
    };
  },
  [types.UPDATE_COOKIES_VIEW_ROW](state, { viewId }) {
    return {
      ...state,
      viewRow: {
        ...state.viewRow,
        ViewID: viewId,
      },
    };
  },

  [types.SET_CURRENT_COOKIE](state, { currentCookie }) {
    return {
      ...state,
      currentCookie,
    };
  },

  [types.SET_COOKIES_SAVED_VIEWS](state, { payload }) {
    return {
      ...state,
      savedViews: payload.savedViews,
    };
  },
  [types.CLEAR_COOKIES_VIEWID](state) {
    return {
      ...state,
      viewRow: null,
    };
  },
  [types.UPDATE_COOKIES_STATE_SEARCH](state, { searchFilterValues }) {
    return {
      ...state,
      cookiesCollectionState: {
        ...state.cookiesCollectionState,
        SearchText: searchFilterValues.searchText,
        SearchField:
          searchFilterValues.searchField !== "View Fields"
            ? searchFilterValues.searchField
            : "AllViewFields",
      },
    };
  },
  [types.UPDATE_COOKIE_DETAILS_STATE_SEARCH](state, { searchFilterValues }) {
    return {
      ...state,
      cookieDetailsCollectionState: {
        ...state.cookieDetailsCollectionState,
        SearchText: searchFilterValues.searchText,
        SearchField:
          searchFilterValues.searchField !== "View Fields"
            ? searchFilterValues.searchField
            : "AllViewFields",
      },
    };
  },
});
