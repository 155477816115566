import {
  GET_COMBINED_COLLECTIONS_SOURCE_FIELDS,
  SET_COMBINED_COLLECTIONS_SOURCE_FIELDS,
  GET_SOURCE_COLLECTIONS,
  SET_SOURCE_COLLECTIONS,
  GET_SOURCE_COLLECTIONS_VIEWS,
  SET_SOURCE_COLLECTIONS_VIEWS,
  FILTER_USED_COMBINED_COLLECTIONS_SOURCE_FIELDS,
  FILTER_COMBINED_COLLECTIONS_SAME_COLLECTION_SOURCE_FIELDS,
  FILTER_COMBINED_COLLECTIONS_BY_FIELD_NAME,
  GET_COMBINED_COLLECTIONS_EXISTING_FIELDS,
  SET_COMBINED_COLLECTIONS_EXISTING_FIELDS,
  SET_COMBINED_COLLECTIONS_SOURCE_COLLECTIONS_INFO,
  COMBINED_COLLECTIONS_SOURCE_COLLECTIONS_INFO_LOADED,
  SET_COMBINED_COLLECTIONS_SHARED_FIELDS,
  UPDATE_EXISTING_FIELDS,
  GET_COMBINED_COLLECTIONS_EDIT_MODAL_FIELDS,
  SET_COMBINED_COLLECTIONS_EDIT_MODAL_FIELDS,
  SET_COMBINED_COLLECTIONS_SOURCE_FIELDS_LOADED,
  GET_SOURCE_COLLECTIONS_COMBINED,
  GET_SOURCE_COLLECTIONS_COMBINED_VIEWS,
  SET_SOURCE_COLLECTIONS_COMBINED,
  SET_SOURCE_COLLECTIONS_COMBINED_VIEWS,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import {
  takeLatest,
  put,
  select,
  call,
  take,
  cancel,
} from "redux-saga/effects";
import {
  mapFieldConfigCell,
  mapFieldDescriptionCell,
  mapSequencesConfigCell,
} from "../../Components/Grid/SenchaLockedGrid/mappers";
import {
  renderConfigCell,
  renderFieldConfigCell,
  renderFieldDescriptionCell,
} from "../../Components/Grid/SenchaLockedGrid/renderers";
import {
  mapSourceFieldsGridNameCell,
  mapSourceFieldsGridCollectionsCell,
} from "../../Components/Grid/SenchaGrid/mappers";
import {
  renderSourceFieldsGridNameCell,
  renderSourceFieldsGridCollectionsCell,
} from "../../Components/Grid/SenchaGrid/renderers";

import axios from "axios";
import { SYSTEM_COLLECTIONS } from "../../constants/collections";
import { COMMANDS } from "../../constants/commands";
import { GROUP_COLLECTIONS } from "../../constants/collections";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";

const Ext = window["Ext"];

function isFilterApplied(filters, id) {
  return filters.map && filters.map[id] ? true : false;
}

function getUsedFieldsFilter() {
  return new Ext.util.Filter({
    id: CONSTANTS.STORES.FILTERS.USED_FIELDS,
    filterFn: (item) => !item.get("isUsed"),
  });
}

function getSameCollectionFieldsFilter(selectedItems) {
  function isSameCollection(item, selectedItemsCollectionIds) {
    const itemCollectionsIDs = item.get("CollectionIDs");
    return itemCollectionsIDs.find((collectionId) =>
      selectedItemsCollectionIds.includes(collectionId)
    );
  }

  let selectedItemsCollectionIds = [];
  selectedItems.forEach((item) => {
    selectedItemsCollectionIds = [
      ...selectedItemsCollectionIds,
      ...item.data.CollectionIDs,
    ];
  });

  return new Ext.util.Filter({
    id: CONSTANTS.STORES.FILTERS.SAME_COLLECTION_FIELDS,
    filterFn: (item) => {
      const itemName = item.get("Name");
      const isSelectedItem = selectedItems.find(
        (selectedItem) => selectedItem.data.Name === itemName
      );
      if (isSelectedItem) {
        return true;
      }

      return !isSameCollection(item, selectedItemsCollectionIds);
    },
  });
}

function getFilterByFieldName(searchText) {
  return new Ext.util.Filter({
    id: CONSTANTS.STORES.FILTERS.FILTER_FIELDS_BY_FIELD_NAME,
    filterFn: (item) =>
      item
        .get("Name")
        .toString()
        .toLowerCase()
        .includes(searchText.toString().toLowerCase()),
  });
}

function applyUsedFieldsFilter(filters) {
  const usedFieldsFilter = getUsedFieldsFilter();

  filters.add(usedFieldsFilter);
}

function applySameCollectionFieldsFilter(filters, selectedItems) {
  const sameCollectionFieldsFilter = getSameCollectionFieldsFilter(
    selectedItems
  );

  filters.add(sameCollectionFieldsFilter);
}

function applyFilterByFieldName(filters, searchText) {
  const filterByFieldName = getFilterByFieldName(searchText);

  filters.add(filterByFieldName);
}

function getSourceCollectionInfo(records) {
  let existingFieldsMap = [];
  records.forEach((record) => {
    const fieldMaps = "data" in record ? record.data.FieldMap : record.FieldMap;

    existingFieldsMap = [...existingFieldsMap, ...fieldMaps];
  });

  const collectionIds = existingFieldsMap.map(
    (fieldMap) => fieldMap.CollectionCollectionID
  );

  return {
    ids: [...new Set(collectionIds)],
    existingFields: existingFieldsMap,
  };
}

function* setSourceCollectionsInfo(records) {
  const { ids, existingFields } = getSourceCollectionInfo(records);
  yield put({
    type: SET_COMBINED_COLLECTIONS_SOURCE_COLLECTIONS_INFO,
    payload: {
      ids,
      fields: existingFields,
      selectedIds: ids,
    },
  });

  yield put({
    type: COMBINED_COLLECTIONS_SOURCE_COLLECTIONS_INFO_LOADED,
  });
}

function* updateSourceCollectionsInfo(records) {
  const { ids, existingFields } = getSourceCollectionInfo(records);

  yield put({
    type: SET_COMBINED_COLLECTIONS_SOURCE_COLLECTIONS_INFO,
    payload: {
      ids,
      fields: existingFields,
    },
  });

  yield put({
    type: COMBINED_COLLECTIONS_SOURCE_COLLECTIONS_INFO_LOADED,
  });
}

//#region Existing fields
function getExistingFieldsGridColumns() {
  return [
    {
      dataIndex: "FieldID",
      text: "<span title='FieldID'>FieldID</span>",
      locked: true,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 75,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "Name",
      text: "<span title='Field Name'>Field Name</span>",
      locked: true,
      menuDisabled: true,
      resizable: true,
      searchable: true,
      width: 220,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "Format",
      text: "<span title='Format'>Format</span>",
      locked: false,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 125,
      cell: {
        height: 50,
      },
    },
    {
      dataIndex: "Config",
      text: "<span title='Config'>Config</span>",
      locked: false,
      menuDisabled: true,
      resizable: true,
      searchable: true,
      width: 116,
      cell: {
        height: 50,
        encodeHtml: true,
        xtype: "reactcell",
      },
      renderer: (value, record) =>
        renderFieldConfigCell(mapFieldConfigCell, value, record),
    },
    {
      dataIndex: "Description",
      text: "<span title='Description'>Description</span>",
      locked: false,
      menuDisabled: true,
      resizable: true,
      searchable: true,
      width: 387,
      cell: {
        height: 50,
        encodeHtml: true,
        xtype: "reactcell",
      },
      renderer: (value, record) =>
        renderFieldDescriptionCell(mapFieldDescriptionCell, value, record),
    },
  ];
}

function* fetchExistingFields({ storeId, formParams = {} }) {
  function getStoreData(store, params) {
    return new Promise((resolve) => {
      store.load({
        params,
        callback: (records) => resolve(records),
      });
    });
  }

  const columns = getExistingFieldsGridColumns();

  yield put({
    type: SET_COMBINED_COLLECTIONS_EXISTING_FIELDS,
    payload: {
      columns,
    },
  });

  const store = Ext.getStore(storeId);

  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETFIELDS,
    IncludeAgentFields: "",
    IncludeFieldMap: true,
    ExcludeSystemFields: true,
    ExcludeSeparators: true,
    ...formParams,
  };

  const storeData = yield getStoreData(store, params);

  yield call(setSourceCollectionsInfo, storeData);
}

function* updateExistingFields({ storeId, CollectionID }) {
  function getStoreData(store, params) {
    return new Promise((resolve) => {
      store.load({
        params,
        callback: (records) => resolve(records),
      });
    });
  }
  const store = Ext.getStore(storeId);

  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETFIELDS,
    IncludeAgentFields: "",
    CollectionID,
    IncludeFieldMap: true,
    ExcludeSystemFields: true,
    ExcludeSeparators: true,
  };

  const storeData = yield getStoreData(store, params);

  yield call(updateSourceCollectionsInfo, storeData);
}
// #endregion

//#region Source collections fields
function filterByFieldName({ storeId, searchText }) {
  const store = Ext.getStore(storeId);
  if (store) {
    const filters = store.getFilters();
    store.removeFilter(CONSTANTS.STORES.FILTERS.SAME_COLLECTION_FIELDS);
    applyFilterByFieldName(filters, searchText);
  }
}

function filterSameCollectionItems({ storeId, selectedItems }) {
  const store = Ext.getStore(storeId);
  const filters = store.getFilters();

  const hideUsedFields = isFilterApplied(
    filters,
    CONSTANTS.STORES.FILTERS.USED_FIELDS
  );

  if (selectedItems.length === 0) {
    store.removeFilter(CONSTANTS.STORES.FILTERS.SAME_COLLECTION_FIELDS);
  } else if (hideUsedFields) {
    applySameCollectionFieldsFilter(filters, selectedItems);
  }
}

function filterUsedFields({ storeId, showAllFields, selectedItems }) {
  const store = Ext.getStore(storeId);
  const filters = store.getFilters();
  const isSameCollectionFieldsFilterApplied = isFilterApplied(
    filters,
    CONSTANTS.STORES.FILTERS.SAME_COLLECTION_FIELDS
  );

  if (showAllFields) {
    if (isSameCollectionFieldsFilterApplied) {
      store.removeFilter(CONSTANTS.STORES.FILTERS.SAME_COLLECTION_FIELDS);
    }
    store.removeFilter(CONSTANTS.STORES.FILTERS.USED_FIELDS);
  } else {
    applyUsedFieldsFilter(filters);

    if (!isSameCollectionFieldsFilterApplied) {
      const unUsedSelectedItems = selectedItems.filter(
        (item) => !item.get("isUsed")
      );
      if (unUsedSelectedItems.length > 0) {
        applySameCollectionFieldsFilter(filters, unUsedSelectedItems);
      }
    }
  }
}

function* loadSourceFieldsStore(storeId, result, existingFields, selectedIds) {
  function createEmptyRecord(collectionField) {
    const isUsed =
      existingFields.find(
        (field) => field.CollectionFieldID === collectionField.FieldID
      ) !== undefined;

    return Ext.create("SourceFieldsModel", {
      Name: collectionField.Name,
      Format: collectionField.Format,
      Description: collectionField.Description,
      CollectionCount: 1,
      Fields: [collectionField],
      CollectionIDs: [collectionField.CollectionID],
      CollectionNames: {
        [collectionField.CollectionID]: collectionField.CollectionName,
      },
      isUsed,
      isUsedInitialState: isUsed,
    });
  }

  function updateRecord(consolidatedSourceFieldRecord, collectionField) {
    let collectionCount = consolidatedSourceFieldRecord.get("CollectionCount");
    let fields = consolidatedSourceFieldRecord.get("Fields");
    let collectionIDs = consolidatedSourceFieldRecord.get("CollectionIDs");
    let collectionNames = consolidatedSourceFieldRecord.get("CollectionNames");
    let isUsed = consolidatedSourceFieldRecord.get("isUsed");

    // Update the current values.
    collectionCount++;
    fields.push(collectionField);
    collectionIDs.push(collectionField.CollectionID);
    collectionNames[collectionField.CollectionID] =
      collectionField.CollectionName;

    // Set these values back on the record.
    consolidatedSourceFieldRecord.set("CollectionCount", collectionCount);
    consolidatedSourceFieldRecord.set("Fields", fields);
    consolidatedSourceFieldRecord.set("CollectionIDs", collectionIDs);
    consolidatedSourceFieldRecord.set("CollectionNames", collectionNames);

    if (
      !isUsed &&
      existingFields.find(
        (field) => field.CollectionFieldID === collectionField.FieldID
      ) !== undefined
    ) {
      consolidatedSourceFieldRecord.set("isUsed", true);
      consolidatedSourceFieldRecord.set("isUsedInitialState", true);
    }
  }

  function loadStoreWithData(store, data) {
    return new Promise((resolve) => {
      if (store.isLoaded()) {
        store.loadData(data);
        resolve();
      } else {
        store.load({
          callback: () => {
            store.loadData(data);
            resolve();
          },
        });
      }
    });
  }

  const store = Ext.getStore(storeId);
  store.removeAll(true);

  let data = [];
  for (let index = 0; index < result.length; index++) {
    const field = result[index];
    let consolidatedSourceField = data.find(
      (row) => row.data.Name === field.Name
    );

    if (!consolidatedSourceField) {
      data.push(createEmptyRecord(field));
    } else {
      updateRecord(consolidatedSourceField, field);
    }
  }

  const sharedFields = data.filter(
    (record) => record.get("CollectionIDs").length === selectedIds.length
  );

  yield put({
    type: SET_COMBINED_COLLECTIONS_SHARED_FIELDS,
    payload: {
      sharedFields,
    },
  });

  yield call(loadStoreWithData, store, data);
  yield put({
    type: SET_COMBINED_COLLECTIONS_SOURCE_FIELDS_LOADED,
    payload: {
      loaded: true,
    },
  });
}

function getSourceFieldsGridColumns() {
  return [
    {
      dataIndex: "Name",
      text: "<span title='Field Name'>Field Name</span>",
      locked: true,
      menuDisabled: true,
      resizable: false,
      searchable: true,
      width: 150,
      cell: {
        height: 50,
        encodeHtml: true,
        xtype: "reactcell",
      },
      renderer: (value, record) =>
        renderSourceFieldsGridNameCell(
          mapSourceFieldsGridNameCell,
          value,
          record
        ),
    },
    {
      dataIndex: "CollectionCount",
      text: "<span title='Collections'>Collections</span>",
      minWidth: 50,
      flex: 1,
      cell: {
        height: 50,
        encodeHtml: true,
        xtype: "reactcell",
      },
      renderer: (value, record) =>
        renderSourceFieldsGridCollectionsCell(
          mapSourceFieldsGridCollectionsCell,
          value,
          record
        ),
    },
  ];
}

function* fetchSourceCollectionsFields({
  type: action,
  storeId,
  refreshData,
  formParams = {},
}) {
  if (!refreshData) {
    yield take(COMBINED_COLLECTIONS_SOURCE_COLLECTIONS_INFO_LOADED);
  }
  const sourceCollections = yield select(
    ({ collections }) => collections.combinedCollectionsSourceCollections
  );

  const {
    selectedIds: CollectionIDs,
    fields: existingFields,
  } = sourceCollections;

  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETFIELDS,
    ExcludeSystemFields: "Yes",
    CollectionIDs,
    ...formParams,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETFIELDS,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    const columns = getSourceFieldsGridColumns();

    yield put({
      type: SET_COMBINED_COLLECTIONS_SOURCE_FIELDS,
      payload: {
        columns,
      },
    });

    yield call(
      loadSourceFieldsStore,
      storeId,
      data.Fields,
      existingFields,
      CollectionIDs
    );
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}
//#endregion

//#region Source collections select modal
function prepareSourceCollectionsModel(data, store) {
  const model = store.config.model;

  model.setFields(data);

  return model;
}

function getSourceCollectionsGridColumns(data) {
  const renderers = {
    Config: {
      mapper: mapSequencesConfigCell,
      renderer: renderConfigCell,
    },
  };
  return data.ViewFields.map((field) => {
    if (renderers[field.Name]) {
      return {
        dataIndex: field.Name,
        text: `<span title='${field.Name}'>${field.Name}</span>`,
        locked: field.IsSticky,
        hidden: field.IsHidden,
        width: field.ColumnWidth,
        cell: {
          height: 50,
          encodeHtml: true,
          xtype: "reactcell",
        },
        renderer: (value, record) =>
          renderers[field.Name].renderer(
            renderers[field.Name].mapper,
            value,
            record
          ),
      };
    } else {
      const headerText =
        (field.HeaderText !== "" && field.HeaderText) || field.Name;
      return {
        dataIndex: headerText,
        text: `<span title='${headerText}'>${headerText}</span>`,
        locked: field.IsSticky,
        hidden: field.IsHidden,
        width: field.ColumnWidth,
        cell: {
          height: 50,
        },
      };
    }
  });
}

function* createSourceCollectionsStore(storeId, data) {
  const store = Ext.getStore(storeId);

  const model = prepareSourceCollectionsModel(data, store);
  store.removeAll(true);
  store.setModel(model);

  const params = {
    AddSummaryRow: "",
    Command: "View.GetItems",
    CollectionID: GROUP_COLLECTIONS.AGENTS,
    ViewID: data.ViewRow.ViewID,
    OverrideViewName: "",
    FolderID: "",
    AccountKey: "",
    AgentID: data.Collection.AgentID,
    BookmarkID: data.Collection.BookmarkID,
    JobID: -1,
    ItemHistoryRangeStart: "",
    ItemHistoryRangeEnd: "",
    ItemStatusesToInclude: "",
    ColumnsToAlwaysInclude:
      "Config,Name,Description,Settings,Status,ErrorPostpone,CollectionID,LimitWebPageCredits,AgentGroup,Geolocation,BrowserType",
    ForceViewSortable: "",
    AlwaysIncludeCustomFields: false,
    IncludeAdditionalCustomInterfaceColumns: true,
    SelectedIDs: "",
    ShowHistoryColumn: false,
    ShowSelected: false,
    TemplateCollectionID: 0,
    SearchText: data.CollectionState.SearchText,
    SearchField: data.CollectionState.SearchField,
    ShowMarkup: false,
    page: data.CollectionState.PageIndex,
    limit: data.CollectionState.PageItemCount,
    group: { property: "Group", direction: "ASC" },
  };
  store.baseParams = params;
  store.getProxy().extraParams = store.baseParams;
  store.loadPage(1, {
    params,
  });
  store.baseParams = {
    ...params,
  };

  const columns = getSourceCollectionsGridColumns(data);

  yield put({
    type: SET_SOURCE_COLLECTIONS,
    payload: {
      columns,
      sortedBy: data.ViewSorting,
      viewRow: data.ViewRow,
      collectionState: data.CollectionState,
      viewFields: data.ViewFields,
      collectionInfo: data.CollectionInfo,
    },
  });
}

function* createSourceCollectionsCombinedStore(storeId, data) {
  const store = Ext.getStore(storeId);

  const model = prepareSourceCollectionsModel(data, store);
  store.removeAll(true);
  store.setModel(model);

  const params = {
    AddSummaryRow: "",
    Command: COMMANDS.VIEW_GETITEMS,
    CollectionID: SYSTEM_COLLECTIONS.COLLECTIONS,
    ViewID: data.ViewRow.ViewID,
    OverrideViewName: "",
    FolderID: "",
    AccountKey: "",
    AgentID: 0,
    BookmarkID: 0,
    JobID: -1,
    ItemHistoryRangeStart: "",
    ItemHistoryRangeEnd: "",
    ItemStatusesToInclude: "",
    ColumnsToAlwaysInclude:
      "Name,CollectionID,BookmarkID,CollectionType,Publisher",
    ForceViewSortable: "",
    AlwaysIncludeCustomFields: true,
    IncludeAdditionalCustomInterfaceColumns: "",
    SelectedIDs: "",
    ShowHistoryColumn: false,
    ShowSelected: false,
    TemplateCollectionID: 0,
    ContextRestrictionFieldNames: "CollectionType",
    ContextRestrictionFieldValues: "Combined",
    ContextRestrictionFieldOperators: "DoesNotEqual",
    SearchText: data.CollectionState.SearchText,
    SearchField: data.CollectionState.SearchField,
    ShowMarkup: false,
  };

  store.load({
    params,
  });
  store.baseParams = {
    ...params,
  };

  const columns = getSourceCollectionsGridColumns(data);

  yield put({
    type: SET_SOURCE_COLLECTIONS_COMBINED,
    payload: {
      columns,
      sortedBy: data.ViewSorting,
      viewRow: data.ViewRow,
      collectionState: data.CollectionState,
      viewFields: data.ViewFields,
      collectionInfo: data.CollectionInfo,
    },
  });
}

function* fetchSourceCollectionsCombined({ type: action, storeId }) {
  try {
    const params = {
      CollectionID: SYSTEM_COLLECTIONS.COLLECTIONS,
      FolderID: "",
      AccountKey: "",
      ViewID: "",
      OverrideViewName: "",
      HideSystemViewNames: "Combined Collections",
      Command: COMMANDS.COLLECTION_GETCOMPLETE,
    };

    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    yield call(createSourceCollectionsCombinedStore, storeId, data);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}
function* fetchSourceCollections({ type: action, storeId, formParams = {} }) {
  try {
    const params = {
      Command: CONSTANTS.COMMANDS.COLLECTION_GETCOMPLETE,
      HideSystemViewNames: "",
      AccountKey: "",
      FolderID: "",
      OverrideViewName: "",
      CollectionID: GROUP_COLLECTIONS.AGENTS,
      ...formParams,
    };

    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    yield call(createSourceCollectionsStore, storeId, data);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* fetchSourceCollectionsCombinedViews({ type: action }) {
  const params = {
    AccountKey: "",
    CollectionID: SYSTEM_COLLECTIONS.COLLECTIONS,
    Command: CONSTANTS.COMMANDS.COLLECTION_GETVIEWS,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETVIEWS,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    const views = [];

    const hideSystemViewName = "Combined Collections";

    data.ViewTable.forEach((view) => {
      if (view.Name !== hideSystemViewName) {
        let savedView = {};
        for (let key in view) {
          if (CONSTANTS.RESPONSE_CONTEXT.SAVED_VIEWS_CONTEXT.includes(key)) {
            savedView[key] = view[key];
          }
        }
        views.push(savedView);
      }
    });

    yield put({
      type: SET_SOURCE_COLLECTIONS_COMBINED_VIEWS,
      payload: {
        views,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* fetchSourceCollectionsViews({ type: action }) {
  const params = {
    AccountKey: "",
    CollectionID: GROUP_COLLECTIONS.AGENTS,
    Command: CONSTANTS.COMMANDS.COLLECTION_GETVIEWS,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETVIEWS,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    const views = [];

    const hideSystemViewName = "Combined Collections";

    data.ViewTable.forEach((view) => {
      if (view.Name !== hideSystemViewName) {
        let savedView = {};
        for (let key in view) {
          if (CONSTANTS.RESPONSE_CONTEXT.SAVED_VIEWS_CONTEXT.includes(key)) {
            savedView[key] = view[key];
          }
        }
        views.push(savedView);
      }
    });

    yield put({
      type: SET_SOURCE_COLLECTIONS_VIEWS,
      payload: {
        views,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}
//#endregion

//#region Edit modal
function* getCollectionFields({ formParams = {} }) {
  try {
    const params = {
      Command: CONSTANTS.COMMANDS.COLLECTION_GETFIELDS,
      IncludeAgentFields: "",
      IncludeFieldMap: true,
      ExcludeSystemFields: true,
      ExcludeSeparators: true,
      ...formParams,
    };

    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETFIELDS,
      new URLSearchParams(params)
    );

    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }

    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    yield put({
      type: SET_COMBINED_COLLECTIONS_EDIT_MODAL_FIELDS,
      payload: {
        fields: data.Fields,
      },
    });

    yield call(setSourceCollectionsInfo, data.Fields);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}
//#endregion

export default function* combinedCollectionFieldsWatcher() {
  yield takeLatest(
    GET_COMBINED_COLLECTIONS_EXISTING_FIELDS,
    fetchExistingFields
  );

  yield takeLatest(
    GET_COMBINED_COLLECTIONS_SOURCE_FIELDS,
    fetchSourceCollectionsFields
  );

  yield takeLatest(GET_SOURCE_COLLECTIONS, fetchSourceCollections);
  yield takeLatest(GET_SOURCE_COLLECTIONS_VIEWS, fetchSourceCollectionsViews);

  yield takeLatest(
    FILTER_USED_COMBINED_COLLECTIONS_SOURCE_FIELDS,
    filterUsedFields
  );
  yield takeLatest(
    FILTER_COMBINED_COLLECTIONS_SAME_COLLECTION_SOURCE_FIELDS,
    filterSameCollectionItems
  );
  yield takeLatest(
    FILTER_COMBINED_COLLECTIONS_BY_FIELD_NAME,
    filterByFieldName
  );

  yield takeLatest(UPDATE_EXISTING_FIELDS, updateExistingFields);

  yield takeLatest(
    GET_COMBINED_COLLECTIONS_EDIT_MODAL_FIELDS,
    getCollectionFields
  );

  yield takeLatest(
    GET_SOURCE_COLLECTIONS_COMBINED,
    fetchSourceCollectionsCombined
  );

  yield takeLatest(
    GET_SOURCE_COLLECTIONS_COMBINED_VIEWS,
    fetchSourceCollectionsCombinedViews
  );
}
