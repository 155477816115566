import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import variables from "../../assets/sass/_variables.scss";

const styles = {
  root: {
    padding: 0,
    minWidth: "auto",
    textTransform: "initial",
    color: variables["color-deepcerulean"],
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
    "&:active": {
      color: variables["color-spacecadet-70"],
    },
  },
};

const StyledButton = withStyles(styles)(Button);

function ButtonLink(props) {
  const { size, label, disabled, onClick, startIcon } = props;

  return (
    <StyledButton
      size={size}
      onClick={onClick}
      disabled={disabled}
      startIcon={startIcon}
      disableElevation
      disableRipple
    >
      {label}
    </StyledButton>
  );
}

ButtonLink.propTypes = {
  /** event handler on element click or tap */
  onClick: PropTypes.func,
  /** disables button if true */
  disabled: PropTypes.bool,
  /** button text */
  label: PropTypes.string,
  /** button size */
  size: PropTypes.oneOf(["small", "medium", "large"]),
  startIcon: PropTypes.object,
};

ButtonLink.defaultProps = {
  disabled: false,
  size: "medium",
};

export default ButtonLink;
