export const convertToNumber = (number) => {
  const convertedNumber = parseInt(number, 10);
  return isNaN(convertedNumber) ? 0 : convertedNumber;
};

export const timeToDisplayByWordCount = (msg) => {
  const wordCount = msg.split(" ").length;
  let timeToDisplay = 5000;

  if (wordCount > 0) {
    let secondsPerWord = Math.round(wordCount / 3);
    if (secondsPerWord > 5) {
      secondsPerWord = 5;
    }
    timeToDisplay = secondsPerWord * 1000;
  }

  return timeToDisplay;
};

export const randomIntFromInterval = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};
