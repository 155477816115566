import { LOGIN } from "../types";
import { CONSTANTS } from "../../constants/constants";
import { takeLatest, cancel } from "redux-saga/effects";
import axios from "axios";

function* login({ email, password, errorCallback }) {
  const { data } = yield axios.post(
    CONSTANTS.ROUTES.USER_LOGIN,
    new URLSearchParams({
      email,
      password,
      Command: CONSTANTS.COMMANDS.USER_LOGIN,
    })
  );

  if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
    yield cancel();
  }

  if (data.JsonResult.Result === "Success") {
    let webConsoleKey = data.JsonResult.WebConsoleKey;
    document.cookie = `Mozenda.Session.WebConsoleKey=${webConsoleKey};max-age=63113904000;domain=mozenda.com`;
    errorCallback("");
  }

  if (data.JsonResult.Result === "Error") {
    errorCallback(
      data.JsonResult.ErrorDescription || data.JsonResult.ErrorCode || "Error"
    );
  }
}

export default function* accountWatcher() {
  yield takeLatest(LOGIN, login);
}
