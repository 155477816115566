import {
  GET_DEPARTMENT_USERS,
  SET_DEPARTMENT_USERS,
  SET_DEPARTMENT_USERS_SAVED_VIEWS,
  GET_DEPARTMENT_USERS_SAVED_VIEWS,
  UPDATE_DEPARTMENT_USERS,
} from "../types";
import { CONSTANTS } from "../../constants/constants";
import { takeLatest, put, call, cancel, select } from "redux-saga/effects";
import {
  renderDepartmentUsersStatusCell,
  renderDepartmentUsersAgentsCell,
  renderDepartmentUsersCollectionsCell,
  renderDepartmentUsersSequencesCell,
  renderDepartmentUsersAccountCell,
  renderDepartmentUsersNameFullCell,
  renderDepartmentUsersLastSeenCell,
  renderDepartmentUsersInvitedLastCell,
} from "../../Components/Grid/SenchaLockedGrid/renderers";
import {
  mapDepartmentUsersStatusCell,
  mapDepartmentUsersAgentsCell,
  mapDepartmentUsersCollectionsCell,
  mapDepartmentUsersSequencesCell,
  mapDepartmentUsersAccountCell,
  mapDepartmentUsersNameFullCell,
  mapDepartmentUsersLastSeenCell,
  mapDepartmentUsersInvitedLastCell,
} from "../../Components/Grid/SenchaLockedGrid/mappers";
import axios from "axios";
import { errorHandlingEvent, handleJsonResultError } from "../../EventHandling";

const Ext = window["Ext"];

function prepareGridColumns(data, accountInfo, accountFeatures) {
  const renderers = {
    Status: {
      renderer: renderDepartmentUsersStatusCell,
      mapper: mapDepartmentUsersStatusCell,
    },
    Agents: {
      renderer: renderDepartmentUsersAgentsCell,
      mapper: mapDepartmentUsersAgentsCell,
    },
    Collections: {
      renderer: renderDepartmentUsersCollectionsCell,
      mapper: mapDepartmentUsersCollectionsCell,
    },
    Sequences: {
      renderer: renderDepartmentUsersSequencesCell,
      mapper: mapDepartmentUsersSequencesCell,
    },
    Account: {
      renderer: renderDepartmentUsersAccountCell,
      mapper: mapDepartmentUsersAccountCell,
    },
    NameFull: {
      renderer: renderDepartmentUsersNameFullCell,
      mapper: mapDepartmentUsersNameFullCell,
    },
    WebConsoleOnlineLast: {
      renderer: renderDepartmentUsersLastSeenCell,
      mapper: mapDepartmentUsersLastSeenCell,
    },
    InvitedLast: {
      renderer: renderDepartmentUsersInvitedLastCell,
      mapper: mapDepartmentUsersInvitedLastCell,
    },
  };

  return data.ViewFields.map((field) => {
    if (renderers[field.Name]) {
      return {
        dataIndex: field.Name,
        text: `<span title='${field.Name}'>${field.Name}</span>`,
        locked: field.IsSticky,
        hidden:
          field.IsHidden ||
          (field.Name === "Sequences" &&
            !accountFeatures.includes("Sequences")),
        cell: {
          height: 50,
          encodeHtml: true,
          xtype: "reactcell",
        },
        renderer: (value, record) =>
          renderers[field.Name].renderer(
            renderers[field.Name].mapper,
            value,
            record,
            accountInfo
          ),
      };
    }

    return {
      dataIndex: field.Name,
      text: `<span title='${field.Name}'>${field.Name}</span>`,
      locked: field.IsSticky,
      hidden: field.IsHidden,
      width: field.ColumnWidth,
      cell: {
        height: 50,
        encodeHtml: true,
      },
    };
  });
}

function prepareModel(data, store) {
  const model = store.config.model;

  model.setFields(data);

  return model;
}

function* createStore(storeId, data, extraParams = {}) {
  const store = Ext.getStore(storeId);
  const state = yield select();
  let accountFeatures = state.account.accountInfo?.Account?.Features;
  if (store) {
    const model = prepareModel(data, store);

    const {
      SearchText,
      SearchField,
      PageItemCount,
      PageIndex,
    } = data.CollectionState;

    store.removeAll(true);
    store.setModel(model);

    store.setPageSize(PageItemCount);

    store.loadPage(PageIndex, {
      params: {
        Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
        CollectionID: CONSTANTS.COLLECTIONS.GROUP.USERS,
        ViewID: data.ViewRow.ViewID,
        SearchText,
        SearchField,
        ...extraParams,
      },
    });
  }

  const columns = prepareGridColumns(data, accountFeatures);

  yield put({
    type: SET_DEPARTMENT_USERS,
    payload: {
      columns,
      sortedBy: data.ViewSorting,
      viewRow: data.ViewRow,
      collectionState: data.CollectionState,
      viewFields: data.ViewFields,
    },
  });
}

function* fetchDepartmentUsers({ type: action, storeId, formParams = {} }) {
  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETCOMPLETE,
    CollectionID: CONSTANTS.COLLECTIONS.GROUP.USERS,
    ...formParams,
  };

  const extraParams = {
    ColumnsToAlwaysInclude:
      "Email,NameFirst,NameLast,Status,UserKey,AccountPermissions,Settings,City,Company,Country,FaxNumber,Phone1,Phone2,Street1,Street2,State,Title,ZipCode",
    ...formParams,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    yield call(createStore, storeId, data, extraParams);
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* getSavedViews({ type: action }) {
  const params = {
    AccountKey: "",
    CollectionID: CONSTANTS.COLLECTIONS.GROUP.USERS,
    Command: CONSTANTS.COMMANDS.COLLECTION_GETVIEWS,
  };

  try {
    const result = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETVIEWS,
      new URLSearchParams(params),
      {
        headers: {
          action,
        },
      }
    );
    if (result.data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        result.data.JsonResult.Result,
        result.data.JsonResult.ErrorDescription,
        result.data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;

    const savedViews = [];

    result.data.ViewTable.forEach((view) => {
      let savedView = {};
      for (let key in view) {
        if (CONSTANTS.RESPONSE_CONTEXT.SAVED_VIEWS_CONTEXT.includes(key)) {
          savedView[key] = view[key];
        }
      }
      savedViews.push(savedView);
    });

    yield put({
      type: SET_DEPARTMENT_USERS_SAVED_VIEWS,
      payload: {
        savedViews,
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

function* updateDepartmentUsers({ accountKey, viewId, storeId }) {
  const params = {
    Command: CONSTANTS.COMMANDS.COLLECTION_GETCOMPLETE,
    CollectionID: CONSTANTS.COLLECTIONS.GROUP.USERS,
    AccountKey: accountKey,
    ViewID: viewId,
  };

  try {
    const { data } = yield axios.post(
      CONSTANTS.ROUTES.COLLECTION_GETCOMPLETE,
      new URLSearchParams(params)
    );
    if (data.JsonResult.Result === CONSTANTS.LOGIN_RESPONSE_FLAG) {
      yield cancel();
    }
    if (
      !handleJsonResultError(
        data.JsonResult.Result,
        data.JsonResult.ErrorDescription,
        data.JsonResult.ErrorList,
        CONSTANTS.NOTIFICATIONS.SUCCESS
      )
    )
      return;
    window["Ext"].getStore(storeId).load({
      params: {
        AddSummaryRow: "",
        Command: CONSTANTS.COMMANDS.VIEW_GETITEMS,
        CollectionID: CONSTANTS.COLLECTIONS.GROUP.USERS,
        ViewID: viewId,
        OverrideViewName: "",
        FolderID: "",
        AccountKey: accountKey,
        AgentID: 0,
        BookmarkID: 0,
        JobID: -1,
        ItemHistoryRangeStart: "",
        ItemHistoryRangeEnd: "",
        ItemStatusesToInclude: "",
        ColumnsToAlwaysInclude:
          "Email,NameFirst,NameLast,Status,UserKey,AccountPermissions,Settings,City,Company,Country,FaxNumber,Phone1,Phone2,Street1,Street2,State,Title,ZipCode",
      },
    });
  } catch (error) {
    errorHandlingEvent.emit(CONSTANTS.EVENTS.ERROR_EVENT, {
      msg: error.message,
    });
  }
}

export default function* departmentUsersWatcher() {
  yield takeLatest(GET_DEPARTMENT_USERS, fetchDepartmentUsers);
  yield takeLatest(GET_DEPARTMENT_USERS_SAVED_VIEWS, getSavedViews);
  yield takeLatest(UPDATE_DEPARTMENT_USERS, updateDepartmentUsers);
}
